import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { SettingsService } from '@bend/store';

import { ModalSelected, SelectItem } from '../../../../../../../shared-widgets/src/lib/types';

@Component({
  selector: 'app-modal-select-radio',
  templateUrl: './modal-select-radio.component.html',
  styleUrls: ['./modal-select-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSelectRadioComponent {
  items: SelectItem<any>[];
  bg$: Observable<string>;
  indexSelected: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { items: SelectItem<any>[]; indexSelected: number },
    private _settings: SettingsService,
    private _dialogRef: MatDialogRef<ModalSelectRadioComponent, ModalSelected<any>>,
  ) {
    this.items = this._data.items;
    this.indexSelected = this._data.indexSelected;
    this.bg$ = this._settings.colors.primary;
  }

  select(item: SelectItem<any>, index: number): void {
    this._dialogRef.close({ ...item, index });
  }
}
