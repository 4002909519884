<div class="panels-wrapper">
  <div class="first-panel">
    <div class="main-row">
      <div class="title">
        <span>{{ 'STUDIO.SIDEBAR.CATALOGS' | translate }}</span>
      </div>
      <div class="buttons">
        <app-button (clicked)="newCatalog()" color="primary" class="m-5">
          <span>{{ 'STUDIO.CATALOGS.NEW_CATALOG' | translate }}</span>
        </app-button>
        <app-button (clicked)="importProductsFromCSV()" color="primary" class="m-5">
          <span>{{ 'STUDIO.CATALOGS.IMPORT_PRODUCTS_FROM_CSV' | translate }}</span>
        </app-button>
        <app-button (clicked)="import()" color="primary" class="m-5">
          <span>{{ 'STUDIO.CATALOGS.IMPORT_FROM_POS' | translate }}</span>
        </app-button>
      </div>
    </div>
    <ng-container *ngIf="locations$ | async as locations">
      <div (click)="selectCatalog(catalog)" *ngFor="let catalog of catalogs">
        <app-catalog-header [appSlug]="appSlug" [catalog]="catalog" [locations]="locations"></app-catalog-header>
      </div>
    </ng-container>
  </div>

  <div class="second-panel">
    <router-outlet></router-outlet>
  </div>
</div>
