import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';

import { SnackBarService, SuccessCode } from '@designer-shared';
import { pageActions } from '@designer-store/page';
import { DesignerRouterService, selectPageIdParam } from '@designer-store/router';

import { widgetActions } from './widget.actions';
import { selectWidget, selectWidgets } from './widget.selectors';
import { WidgetHttpService } from './widget-http.service';

@Injectable()
export class WidgetEffects {
  getWidgetsEffects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(widgetActions.getWidgets),
      concatLatestFrom(() => this._designerRouter.pageId),
      exhaustMap(([, pageId]) =>
        this._widgetHttp.getWidgets(pageId).pipe(
          map(widgets => widgetActions.getWidgetsSuccess({ widgets })),
          catchError(error => of(widgetActions.getWidgetsError({ error }))),
        ),
      ),
    ),
  );

  deleteWidgetEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(widgetActions.deleteWidget),
      concatLatestFrom(({ widgetId }) => [
        this._store$.select(selectPageIdParam),
        this._store$.select(selectWidget(widgetId)),
      ]),
      exhaustMap(([{ widgetId }, pageId, widget]) =>
        this._widgetHttp.deleteWidget(pageId, widgetId).pipe(
          tap({
            next: () => this._snackBar.success(SuccessCode.DELETED),
            error: () => this._snackBar.error(),
          }),
          mergeMap(() => [
            widgetActions.deleteWidgetSuccess({ widgetId }),
            ...(widget.published ? [pageActions.refreshPreview()] : []),
          ]),
          catchError(error => of(widgetActions.deleteWidgetError({ widgetId, error }))),
        ),
      ),
    ),
  );

  duplicateWidgetEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(widgetActions.duplicateWidget),
      concatLatestFrom(({ widgetId }) => [this._designerRouter.pageId, this._store$.select(selectWidget(widgetId))]),
      exhaustMap(([{ widgetId }, pageId, widget]) =>
        this._widgetHttp.duplicateWidget(pageId, widget).pipe(
          tap({
            next: () => this._snackBar.success(SuccessCode.COPIED),
            error: () => this._snackBar.error(),
          }),
          map(duplicatedWidget => widgetActions.duplicateWidgetSuccess({ widgetId, widget: duplicatedWidget })),
          catchError(error => of(widgetActions.duplicateWidgetError({ widgetId, error }))),
        ),
      ),
    ),
  );

  publishWidgetEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(widgetActions.publishWidget),
      concatLatestFrom(() => this._designerRouter.pageId),
      exhaustMap(([{ widgetId, published }, pageId]) =>
        this._widgetHttp.publishWidget(pageId, widgetId, published).pipe(
          tap({
            next: () => this._snackBar.success(SuccessCode.UPDATED),
            error: () => this._snackBar.error(),
          }),
          mergeMap(() => [widgetActions.publishWidgetSuccess({ widgetId, published }), pageActions.refreshPreview()]),
          catchError(error => of(widgetActions.publishWidgetError({ widgetId, error }))),
        ),
      ),
    ),
  );

  changeWidgetOrderEffect = createEffect(() =>
    this._actions$.pipe(
      ofType(widgetActions.changeWidgetOrder),
      concatLatestFrom(() => [this._store$.select(selectPageIdParam), this._store$.select(selectWidgets)]),
      exhaustMap(([{ previousIndex, currentIndex }, pageId, widgets]) => {
        const widgetsClone = widgets.slice().map(widget => ({ ...widget }));
        moveItemInArray(widgetsClone, previousIndex, currentIndex);

        widgetsClone.forEach((widget, index) => {
          widget.order = index + 1;
        });

        return this._widgetHttp.changeOrderWidgets(pageId, widgetsClone).pipe(
          tap({
            next: () => this._snackBar.success(SuccessCode.UPDATED),
            error: () => this._snackBar.error(),
          }),
          mergeMap(updatedWidgets => [
            widgetActions.changeWidgetOrderSuccess({ widgets: updatedWidgets }),
            pageActions.refreshPreview(),
          ]),
          /**
           * if an error occurs, return the state before the change
           */
          catchError(error => of(widgetActions.changeWidgetOrderError({ widgets, error }))),
        );
      }),
    ),
  );

  constructor(
    private readonly _store$: Store,
    private readonly _actions$: Actions,
    private readonly _designerRouter: DesignerRouterService,
    private readonly _widgetHttp: WidgetHttpService,
    private readonly _snackBar: SnackBarService,
  ) {}
}
