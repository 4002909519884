<button
  (click)="onClick()"
  [disabled]="disabled || isLoading"
  [type]="type"
  [style.color]="fgPrimary$ | async"
  class="button"
>
  <span>
    <ng-content></ng-content>
  </span>
  <app-spinner *ngIf="isLoading" @toggleX [stroke]="fgPrimary$ | async" class="button__loading"></app-spinner>
</button>
