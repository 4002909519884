import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Widget } from '../../models';
import { WidgetFormService } from '../../services';

@Component({
  selector: 'pop-widget-edit-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetComponent implements OnInit, OnDestroy {
  @Input() widget: Widget;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<void>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSaveAndPublish = new EventEmitter<void>();

  tabs = ['STUDIO.SETTINGS.MENU.GENERAL', 'STUDIO.SETTINGS.MENU.CONDITIONAL'];
  tabPosition = 0;

  private _subscription: Subscription;

  constructor(private _widgetFormService: WidgetFormService) {}

  ngOnInit(): void {
    this._conditionalSet(!!this.widget.validators.length);

    this._subscription = this._widgetFormService.getAllControls.valueChanges.subscribe(value => {
      this._conditionalSet('validators' in value && !!value.validators.length);
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  changeTab(tabPosition: number): void {
    this.tabPosition = tabPosition;
  }

  private _conditionalSet(set: boolean): void {
    this.tabs[1] = `STUDIO.SETTINGS.MENU.CONDITIONAL${set ? ' (SET)' : ''}`;
  }
}
