export interface Users {
  items: User[];
  total: number;
  limit: number;
  offset: number;
}

export interface User {
  id: number;
  email: string;
  phone: string;
  name: string;
  credit: number;
  pendingTickets: number;
  allowNewsletters: boolean;
}
