import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './pos-settings.config';
import { PosSettingsEffects } from './pos-settings.effects';
import { PosSettingsHttpService } from './pos-settings.http.service';
import { reducer } from './pos-settings.reducer';
import { PosSettingsService } from './pos-settings.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([PosSettingsEffects])],
  providers: [PosSettingsHttpService, PosSettingsService],
})
export class PosSettingsStoreModule {}
