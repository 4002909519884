import { PopSettings } from '../widget';

export interface WidgetReview {
  id: string;
  reviewCount: number;
  reviewSum: number;
  mainColor: string;
  isEditMode: boolean;
  labels: ReviewLabels;
  pop: PopSettings;
}

export interface Review {
  reviewCount: number;
  reviewSum: number;
}

export interface ReviewResponse {
  attributes: Review;
}

export interface MyReview {
  review: number;
}

export class ReviewLabels {
  AVERAGE = '';
  TITLE = '';
}
