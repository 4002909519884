import { PopSettings } from '../widget';

export interface WidgetPoll {
  questions: PollQuestion[];
  id: string;
  mainColor: string;
  isEditMode: boolean;
  labels: PollLabels;
  pop: PopSettings;
  saveContact: boolean;
  showResultsToUser: boolean;
}

export interface PollQuestion {
  _id: string;
  question: string;
  required: boolean;
  responses: PollResponse[];
}

export interface PollResponse {
  _id: string;
  text: string;
  count: number;
}

export interface HasVotedResponse {
  hasVoted: boolean;
}

export interface VoteResponse {
  attributes: WidgetPoll;
  err: string;
}

export class PollLabels {
  VOTE = '';
  MESSAGE = '';
}

export type PollResponsePayload = { questionId: string; responseId: string }[];
