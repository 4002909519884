export interface CapacityManagement {
  enabled: boolean;
  capacity: number;
  velocity: number;
}

export interface CapacityPreview<T = Date> {
  time: T;
  locationName: string;
}
