export interface WidgetTitle {
  title: string;
  style: {
    height: string; // accepted values: 'default' or number
    backgroundColor: string;
    width: string; // accepted values: 'default', 'full' or number
    margin: WidgetTitleMargin;
    fontSize: string;
    textAlign: TextAlignType;
    textColor: string;
  };
  isEditMode: boolean;
}

export class WidgetTitleMargin {
  top = 10;
  bottom = 10;
}

export enum TextAlignType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
