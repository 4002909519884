import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { SettingsService } from '@bend/store';

import { QrCodeReaderComponent } from '../qr-code-reader/qr-code-reader.component';

@Component({
  selector: 'app-qr-code-button',
  templateUrl: './qr-code-button.component.html',
  styleUrls: ['./qr-code-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeButtonComponent {
  color$: Observable<string>;
  fgPrimary$: Observable<string>;

  constructor(private _dialog: MatDialog, private _settings: SettingsService) {
    this.color$ = this._settings.colors.primary;
    this.fgPrimary$ = this._settings.colors.primaryForeground;
  }

  openScannerDialog(): void {
    this._dialog.open(QrCodeReaderComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'zxing',
    });
  }
}
