import { Pipe, PipeTransform } from '@angular/core';

import { PromoCodeType } from '@bend/store-shared';

@Pipe({
  name: 'promoCodesType',
})
export class PromoCodesTypePipe implements PipeTransform {
  transform(type: PromoCodeType): string {
    return type === PromoCodeType.Pos ? 'eurodata' : type;
  }
}
