import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderService } from '@bend/order';
import { Order, OrderStatus } from '@bend/store-shared';

@Injectable()
export class OrderHistoryService {
  constructor(private _order: OrderService) {}

  get orders(): Observable<Order[]> {
    this._order.getOrders();

    return this._order.orders.pipe(
      map(orders => orders.filter(({ status }) => status !== OrderStatus.New)),
      map(orders => orders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())),
    );
  }
}
