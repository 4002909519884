import { ChangeDetectionStrategy, Component, Inject, OnInit, Type, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PopinContentDirective } from './popin-content.directive';

@Component({
  selector: 'designer-popin',
  templateUrl: './popin.component.html',
  styleUrls: ['./popin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopinComponent implements OnInit {
  @ViewChild(PopinContentDirective, { static: true }) private _popinContent!: PopinContentDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { component: Type<unknown>; title: string },
    private readonly _dialogRef: MatDialogRef<PopinComponent>,
  ) {}

  ngOnInit(): void {
    const viewContainerRef = this._popinContent.viewContainerRef;
    viewContainerRef.clear();

    const { title, component, ...rest } = this.data;
    const componentRef = viewContainerRef.createComponent(component);

    for (const key in rest) {
      componentRef[key] = rest[key];
    }
  }

  onCloseClicked(): void {
    this._dialogRef.close();
  }
}
