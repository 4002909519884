import { Action, createReducer, on } from '@ngrx/store';

import { actions } from './resolver.actions';
import { State } from './resolver.type';

export const initialState: State = {
  isLoading: false,
};

const settingsReducer = createReducer(
  initialState,
  on(actions.setLoading, (state, { loading }) => ({ ...state, isLoading: loading })),
);

export function reducer(state: State | undefined, action: Action): State {
  return settingsReducer(state, action);
}
