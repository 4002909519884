import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EnvModule } from '@bend/env';

import { storeKey } from './tickets.config';
import { TicketsEffects } from './tickets.effects';
import { TicketsHttpService } from './tickets.http.service';
import { reducer } from './tickets.reducer';
import { TicketsService } from './tickets.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([TicketsEffects]), EnvModule],
  providers: [TicketsHttpService, TicketsService],
})
export class TicketsStoreModule {}
