import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { ParamsService } from '../params';
import { ApiVisitorService, PlaceRecommendationGroupHttp, RecommendationGroupHttp } from '../shared';

@Injectable()
export class RecommendationGroupHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService, private _params: ParamsService) {}

  getRecommendationGroups(productId: number): Observable<RecommendationGroupHttp[]> {
    return this._api.itemRecommendations('v1', productId).pipe(
      withLatestFrom(this._params.queryParams(['place'])),
      switchMap(([api, place]) => this._http.get<RecommendationGroupHttp[]>(api, { params: { place } })),
    );
  }

  getRecommendationGroupsByPlace(place: string): Observable<PlaceRecommendationGroupHttp[]> {
    return this._api
      .recommendationGroupsByPlace('v1')
      .pipe(switchMap(api => this._http.get<PlaceRecommendationGroupHttp[]>(api, { params: { place } })));
  }
}
