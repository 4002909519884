import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

declare let window: any;

@Component({
  selector: 'app-facebook-post',
  templateUrl: './facebook-post.component.html',
  styleUrls: ['./facebook-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPostComponent implements AfterViewInit {
  @Input() url: string;
  @ViewChild('facebook') facebook: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    window.FB.XFBML.parse(this.facebook.nativeElement);
  }
}
