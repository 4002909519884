import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '../shared';
import { GetUserTransactions, GetUserTransactionsSuccess } from './credit-history.type';

@Injectable()
export class CreditHistoryHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getUsersTransactions({ limit, offset, userId }: GetUserTransactions): Observable<GetUserTransactionsSuccess> {
    return this._api.userTransactions('v1').pipe(
      switchMap(api =>
        this._http.get<GetUserTransactionsSuccess>(api, {
          params: {
            limit: limit.toString(),
            offset: offset.toString(),
            userId: userId.toString(),
          },
        }),
      ),
    );
  }
}
