export interface Tag {
  id: number;
  name: string;
  imageUrl: string;
  applicationId: number;
}

export enum TagParams {
  TagId = 'tagId',
}
