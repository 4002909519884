import { createAction, props } from '@ngrx/store';

import { createType, CustomerInfo, Order, OrderDetails, OrderUpdated } from '../shared';
import { storeKey } from './order.config';

const type = createType(storeKey);

class Actions {
  getOrders = createAction(type('GET all'));
  getOrdersSuccess = createAction(type('GET all S'), props<{ orders: Order[] }>());
  getOrdersError = createAction(type('GET all E'), props<{ errorCode: string }>());

  getOrder = createAction(type('GET'));
  getOrderIsLoaded = createAction(type('GET is loaded'));
  getOrderSuccess = createAction(type('GET S'), props<{ order: Order }>());
  getOrderError = createAction(type('GET E'), props<{ errorCode: string }>());

  updateOrder = createAction(type('UPD'), props<OrderUpdated>());
  updateOrderIsUpdated = createAction(type('UPD is updated'), props<{ id: Order['id'] }>());
  updateOrderSuccess = createAction(type('UPD S'), props<{ id: Order['id']; changes: Order }>());
  updateOrderError = createAction(type('UPD E'), props<{ id: Order['id']; changes: { errorCode: string } }>());

  updateOrderItem = createAction(type('UPD item'), props<{ id: Order['id']; itemId: number; quantity: number }>());
  updateOrderItemSuccess = createAction(type('UPD item S'), props<{ id: Order['id']; changes: { updatedAt: Date } }>());
  updateOrderItemError = createAction(type('UPD item E'), props<{ id: Order['id']; changes: { errorCode: string } }>());

  removeOrderItem = createAction(type('RM item'), props<{ id: Order['id']; itemId: number; orderUserId: number }>());
  removeOrderItemSuccess = createAction(type('RM item S'), props<{ id: Order['id']; changes: OrderUpdated }>());
  removeOrderItemError = createAction(type('RM item E'), props<{ id: Order['id']; changes: { errorCode: string } }>());

  updateOrderDetails = createAction(type('UPD details'), props<{ id: Order['id']; details: OrderDetails }>());
  updateOrderDetailsSuccess = createAction(
    type('UPD details S'),
    props<{ id: Order['id']; changes: { details: OrderDetails } }>(),
  );

  updateOrderDetailsError = createAction(
    type('UPD details E'),
    props<{ id: Order['id']; changes: { errorCode: string } }>(),
  );

  updateOrderUserDetails = createAction(type('UPD user'), props<{ id: Order['id']; customerInfo: CustomerInfo }>());
  updateOrderUserDetailsSuccess = createAction(
    type('UPD user S'),
    props<{ id: Order['id']; changes: { customerInfo: CustomerInfo } }>(),
  );

  updateOrderUserDetailsError = createAction(
    type('UPD user E'),
    props<{ id: Order['id']; changes: { errorCode: string } }>(),
  );

  newOrder = createAction(type('new'));
  newOrderError = createAction(type('new E'), props<{ errorCode: string }>());

  reset = createAction(type('reset'));
}

export const actions = new Actions();
