import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { CkeditorConfigService } from '../../shared/services/ckeditor-config.service';
import { WidgetService } from '../widget.service';
import { DocumentViewerWidget } from './document-viewer';
import { DocumentViewerService } from './document-viewer.service';

@Component({
  selector: 'pop-widget-edit-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditDocumentViewerComponent extends WidgetBaseComponent<DocumentViewerWidget> implements OnInit {
  static widgetName = 'document_viewer';
  @Input() appSlug: string;
  options;

  protected editor = this.ckeditorConfigService.Editor;
  protected editorConfig = this.ckeditorConfigService.EditorConfig;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
    private _documentService: DocumentViewerService,
    private ckeditorConfigService: CkeditorConfigService,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    this.options = {
      forcePasteAsPlainText: true,
    };
  }

  onFileChange(event: FileUploadDetails, formControl: AbstractControl): void {
    formControl.setValue(event.url);
    this._documentService.hashDocument(event.url).subscribe(hash => this.uuid.setValue(hash.uuid));
  }

  get text(): UntypedFormControl {
    return this.form.get('text') as UntypedFormControl;
  }

  get imageUrl(): UntypedFormControl {
    return this.form.get('imageUrl') as UntypedFormControl;
  }

  get uuid(): UntypedFormControl {
    return this.form.get('uuid') as UntypedFormControl;
  }

  get documentUrl(): UntypedFormControl {
    return this.form.get('documentUrl') as UntypedFormControl;
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        text: [this.widget.attributes.text],
        documentUrl: [this.widget.attributes.documentUrl],
        imageUrl: [this.widget.attributes.imageUrl],
        imageAlt: [this.widget.attributes.imageAlt],
        maxHitsPerDay: [this.widget.attributes.maxHitsPerDay],
        uuid: [this.widget.attributes.uuid],
        style: this._formBuilder.group({
          margin: this._formBuilder.group({
            bottom: [this.widget.attributes.style.margin.bottom],
            top: [this.widget.attributes.style.margin.top],
          }),
          borderRadius: [this.widget.attributes.style.borderRadius],
          fullWidth: [this.widget.attributes.style.fullWidth],
          backgroundColor: [this.widget.attributes.style.backgroundColor],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
