import { Observable, pipe, UnaryFunction } from 'rxjs';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';

export const distinctUntilChangedSource: <T>(
  obs: Observable<string | number>,
) => UnaryFunction<Observable<T>, Observable<T>> = obs => {
  return pipe(
    withLatestFrom(obs),
    distinctUntilChanged(([, first], [, last]) => first === last),
    map(([source]) => source),
  );
};
