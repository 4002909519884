import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CategoryStoreModule } from '@bend/store/src/lib/category';

import { SharedComponentsModule } from '../shared-components';
import { NavBarComponent, NavBarItemCatalogComponent, NavBarItemCategoryComponent } from './components';

@NgModule({
  declarations: [NavBarItemCategoryComponent, NavBarItemCatalogComponent, NavBarComponent],
  exports: [NavBarItemCategoryComponent, NavBarItemCatalogComponent, NavBarComponent],
  imports: [CommonModule, RouterModule, SharedComponentsModule, CategoryStoreModule],
})
export class ShopComponentsModule {}
