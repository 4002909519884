import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this._checkLogin(url);
  }

  private _checkLogin(url: string): boolean {
    if (this._authService.isLoggedIn()) {
      return true;
    }

    this._authService.redirectUrl = url;
    this._router.navigate(['/login']);

    return false;
  }
}
