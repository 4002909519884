import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { WidgetBaseComponent } from '../../shared/components';
import { Page } from '../../shared/interfaces';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { ContactInfoWidget } from './types';

@Component({
  selector: 'pop-widget-edit-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent extends WidgetBaseComponent<ContactInfoWidget> implements OnInit, OnDestroy {
  static widgetName = 'contact_info';

  pages: Page[] = [];
  host: string;

  private _subscription: Subscription;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
    this._subscription = new Subscription();
    this.host = widgetService.appHost;
    this._subscription.add(
      widgetService
        .getPages()
        .pipe(
          tap(({ pages }) => {
            this.pages = pages.single;
            this._cdr.detectChanges();
          }),
        )
        .subscribe(),
    );
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(this._getForm());
    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _getForm(): UntypedFormGroup {
    const { labels, style, ...rest } = this.widget.attributes;

    return this._formBuilder.group({
      style: this._formBuilder.group({
        margin: this._formBuilder.group({
          bottom: style.margin.bottom,
          top: style.margin.top,
        }),
        borderRadius: style.borderRadius,
        showBackground: style.showBackground,
      }),
      labels: this._formBuilder.group(labels),
      ...rest,
    });
  }

  get redirectUrl(): UntypedFormControl {
    return this.form.get('redirectUrl') as UntypedFormControl;
  }
}
