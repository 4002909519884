import { WeekDay } from './week-day.type';

export interface WorkingHoursInterval<T = Date> {
  open: T;
  close: T;
  id?: number;
}

export const enum WorkingScheduleType {
  AlwaysOpen = 'alwaysOpen',
  Custom = 'custom',
}

export interface WorkingHours<T = Date, U = WorkingHoursInterval<T>[]> {
  scheduleType: WorkingScheduleType;
  redirectUrl: string;
  closeMessage: string;
  aboutToCloseMessage: string;
  intervals: U extends DictionaryWorkingHoursIntervals<T> ? U : WorkingHoursInterval<T>[];
}

export type DictionaryWorkingHoursIntervals<T = string> = Record<WeekDay, WorkingHoursInterval<T>[]>;
