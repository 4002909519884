import { createAction, props } from '@ngrx/store';

import { createType, PaymentConfig } from '../shared';
import { storeKey } from './payment.config';

const type = createType(storeKey);

export const actions = {
  getPaymentConfigList: createAction(type('get payment config  list'), props<{ appSlug: string }>()),
  getPaymentConfigListIsLoaded: createAction(type('get payment config  list is loaded')),
  getPaymentConfigListSuccess: createAction(
    type('get payment config  list success'),
    props<{
      paymentConfigList: PaymentConfig[];
      appSlug: string;
    }>(),
  ),
  getPaymentConfigListError: createAction(type('get payment config  list error'), props<{ errorCode: string }>()),

  getPaymentConfig: createAction(type('get payment config '), props<{ id: number }>()),
  getPaymentConfigIsLoaded: createAction(type('get payment config  is loaded')),
  getPaymentConfigSuccess: createAction(type('get payment config  success'), props<{ paymentConfig: PaymentConfig }>()),
  getPaymentConfigError: createAction(type('get payment config  error'), props<{ errorCode: string }>()),

  addPaymentConfig: createAction(type('add payment config '), props<{ paymentConfig: PaymentConfig }>()),
  addPaymentConfigSuccess: createAction(type('add payment config  success'), props<{ paymentConfig: PaymentConfig }>()),
  addPaymentConfigError: createAction(type('add payment config  error'), props<{ errorCode: string }>()),

  updatePaymentConfig: createAction(type('update payment config '), props<{ paymentConfig: PaymentConfig }>()),
  updatePaymentConfigSuccess: createAction(
    type('update payment config  success'),
    props<{ paymentConfig: PaymentConfig }>(),
  ),
  updatePaymentConfigError: createAction(type('update payment config  error'), props<{ errorCode: string }>()),

  deletePaymentConfig: createAction(type('delete payment config '), props<{ id: number }>()),
  deletePaymentConfigSuccess: createAction(type('delete payment config  success'), props<{ id: number }>()),
  deletePaymentConfigError: createAction(type('delete payment config  error'), props<{ errorCode: string }>()),
};
