import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StoreService, Ticket, User } from '../shared';
import { actions } from './tickets.actions';
import * as selectors from './tickets.selectors';
import { State } from './tickets.type';

@Injectable()
export class TicketsService extends StoreService<State> {
  constructor(protected _store: Store<State>, protected _actions: Actions) {
    super(_store, _actions, selectors);
  }

  get tickets(): Observable<Ticket[]> {
    return this._store.pipe(select(selectors.tickets));
  }

  get total(): Observable<number> {
    return this._store.pipe(select(selectors.total));
  }

  get allState(): Observable<any> {
    return this._store.pipe(select(selectors.all));
  }

  get userId(): Observable<User['id']> {
    return this._store.pipe(select(selectors.userId));
  }

  getTickets(limit: number, offset: number, userId: number): Observable<boolean> {
    this._store.dispatch(actions.getTickets({ userId, limit, offset }));

    return this._finishedAction(actions.getTicketsSuccess);
  }

  updateTicket(id: number, ticket: Partial<Ticket>): Observable<boolean> {
    this._store.dispatch(actions.editTicket({ id, ticket }));

    return this._finishedAction(actions.editTicketSuccess, actions.editTicketError);
  }
}
