import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { toggleY } from '@bend/animations';

import { InputGeneric } from '../../../helpers';

@Component({
  selector: 'app-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleY],
})
export class InputErrorsComponent extends InputGeneric implements OnInit {
  @Input() ngControl?: NgControl;
  @Input() showError?: boolean;

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.isExistControl) {
      this.ngControl.control.statusChanges.subscribe(() => this._cdr.detectChanges());
    }
  }

  get isShowRequiredError(): boolean {
    return this.ngControl.hasError('required');
  }

  get isShowEmailError(): boolean {
    return this.ngControl.hasError('email');
  }

  get isShowMinError(): boolean {
    return this.ngControl.hasError('min');
  }

  get isShowMaxError(): boolean {
    return this.ngControl.hasError('max');
  }

  get isShowMinLengthError(): boolean {
    return this.ngControl.hasError('minlength');
  }

  get isShowCapitalLetterError(): boolean {
    return this.ngControl.hasError('hasCapitalLetter');
  }

  get isShowNoCharacterError(): boolean {
    return this.ngControl.hasError('noCharacter');
  }

  get isShowMaxLengthError(): boolean {
    return this.ngControl.hasError('maxlength');
  }

  get isShowDuplicateError(): boolean {
    return this.ngControl.hasError('duplicate');
  }

  get isNotIntegerError(): boolean {
    return this.ngControl.hasError('isNotInteger');
  }

  get isNotIpAddressError(): boolean {
    return this.ngControl.hasError('wrongIp');
  }

  get isNotPortError(): boolean {
    return this.ngControl.hasError('wrongPort');
  }

  get isNotUrlError(): boolean {
    return this.ngControl.hasError('isNotUrl');
  }

  get isInvalidSelection(): boolean {
    return this.ngControl.hasError('invalidSelection');
  }
}
