import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { Icons } from '@bend/icon';
import { SettingsService } from '@bend/store';

import { InputGeneric } from '../../../../../../../shared-widgets/src/lib/helpers';

@Component({
  selector: 'app-input-date',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent extends InputGeneric implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() placeHolder = '';
  @Input() name = '';
  @Input() type = 'text';
  @Input() icon?: Icons;
  @Input() focused: boolean;
  @Input() min: number;
  @Input() max: number;
  @ViewChild('input') input: ElementRef;
  color$: Observable<string>;
  isDisabled: boolean;

  control: UntypedFormControl;

  private _value: string;
  private _checkValidation: boolean;
  private _subscription: Subscription;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _cdr: ChangeDetectorRef,
    private _settings: SettingsService,
  ) {
    super();

    if (this.ngControl) this.ngControl.valueAccessor = this;

    this.control = new UntypedFormControl();

    this.color$ = this._settings.colors.primary;

    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    const controlSubscription = this.control.valueChanges.subscribe(value => this._setValue(value));
    this._subscription.add(controlSubscription);

    if (this.focused) {
      this.input.nativeElement.focus();
    }

    if (this.isExistControl) {
      const subscriptionValueChanges = this.ngControl.control.valueChanges.subscribe(() => this._cdr.detectChanges());

      this._subscription.add(subscriptionValueChanges);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  enable(): void {
    if (this.isExistControl) this.ngControl.control.enable();
  }

  writeValue(value: string): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.notExistControl) return;

    // to validate an input, it must be removed from disabled.
    // When it becomes disabled comes a false event  and you have to skip this event
    if (!isDisabled && this._checkValidation) return;

    if (isDisabled) {
      this._disableState();
    } else {
      this._enableState();
    }

    this._cdr.detectChanges();
  }

  private _setValue(value: string): void {
    if (value === undefined || this._value === value) return;

    this._value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  private _enableState(): void {
    this.control.enable({ emitEvent: false });

    // focuses only when the previous state was true
    if (this.isDisabled) {
      this.input.nativeElement.focus();
    }

    this.isDisabled = false;
  }

  private _disableState(): void {
    if (this._checkValidation) {
      this._checkValidation = false;
      return;
    }

    // https://github.com/angular/angular/issues/11447
    // enable for check validation
    this._checkValidation = true;
    this.ngControl.control.enable({ emitEvent: false });

    const { invalid } = this.ngControl.control;

    if (invalid) {
      // if the item is invalid make input enabled
      this.ngControl.control.reset();
    } else {
      this.ngControl.control.disable({ emitEvent: false });
      this.control.disable({ emitEvent: false });

      this.isDisabled = true;
    }
  }
}
