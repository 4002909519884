import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';

@Injectable({ providedIn: 'root' })
export class ApiImageService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api-test.smilein.io/image/:version/:userType/:appSlug/files?imageSubset=me
   */
  images(version: string, app: string): Observable<string> {
    return this.image(version, app).pipe(this._patch('/files?imagesSubset=app'));
  }

  /**
   * @returns https://api-test.smilein.io/image/:version/:userType/:appSlug/files-count?imageSubset=me
   */
  applications(version: string): Observable<string> {
    return this.image(version).pipe(this._patch('/files-count?imagesSubset=me'));
  }

  /**
   * @returns https://api-test.smilein.io/image/:version/:userType/:appSlug/upload
   */
  uploadImage(version: string): Observable<string> {
    return this.image(version).pipe(this._patch('/upload'));
  }

  /**
   * @returns https://api-test.smilein.io/image/:version/:userType/:appSlug/upload-by-url
   */
  uploadImageByUrl(version: string): Observable<string> {
    return this.image(version).pipe(this._patch('/upload-by-url'));
  }

  /**
   * @returns https://api-test.smilein.io/image/v1/:userType/:appSlug/files/id
   */
  deleteImage(id: number): Observable<string> {
    return this.image('v1').pipe(this._patch(`/files/${id}`));
  }

  image(version: string, app?: string): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/image/${version}/${this._env.userType}/${app || appSlug}`),
    );
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
