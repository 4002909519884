import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { PopProduct } from '../product-catalog';
import { WidgetProductCatalogHelperService } from '../product-catalog-helper.service';
import { RecommendationMenuBackdropService } from '../services';

@Injectable()
export class RecommendationsMenuService {
  private detailsProduct$ = new BehaviorSubject<PopProduct>(null);

  private addedRecommendations: PopProduct[] = [];

  // For removing items
  private addedRecommendationsStack: PopProduct[] = [];

  /* From projects/widgets-old/src/lib/product-catalog/product-details-dialog/product-details-dialog.component.ts */
  showErrors = false;
  isLoading = false;
  errorMessages: number[] = [];
  optionGroupQuantityError: number[] = [];

  get getAddedRecommendations(): PopProduct[] {
    return this.addedRecommendations;
  }

  constructor(
    private menuBackdrop: RecommendationMenuBackdropService,
    private productCatalogHelper: WidgetProductCatalogHelperService,
  ) {}

  addRecommendationClick(product: PopProduct): void {
    if (product.id === this.detailsProduct$.value?.id) return;

    if (!this.hasDetails(product)) {
      this.hideMenu();
      this.saveSimpleRecommendation(product);
    } else {
      this.detailsProduct$.next(product);
    }
  }

  removeRecommendationClick(product: PopProduct): void {
    this.removeProduct(this.findProductToRemove(product));
  }

  private removeProduct({
    product,
    stackIndex,
    index,
  }: {
    product: PopProduct;
    index: number;
    stackIndex: number;
  }): void {
    if (product.quantityMultiplier > 1) {
      product.quantityMultiplier--;
    } else {
      this.addedRecommendations.splice(index, 1);
    }

    this.addedRecommendationsStack.splice(stackIndex, 1);
  }

  private findProductToRemove(product: PopProduct): { product: PopProduct; index: number; stackIndex: number } {
    // Get last added product in stack
    const lastAddedIndex = this.addedRecommendationsStack.map(stackProduct => stackProduct.id).lastIndexOf(product.id);
    const lastAdded =
      this.addedRecommendationsStack[
        this.addedRecommendationsStack.map(stackProduct => stackProduct.id).lastIndexOf(product.id)
      ];

    if (!this.hasDetails(product)) {
      return {
        product: this.addedRecommendations.find(rec => rec.id === lastAdded.id),
        index: this.addedRecommendations.findIndex(rec => rec.id === lastAdded.id),
        stackIndex: lastAddedIndex,
      };
    } else {
      const index = this.addedRecommendations.findIndex(rec => this.compareProducts(rec, lastAdded));
      return { product: this.addedRecommendations[index], index, stackIndex: lastAddedIndex };
    }
  }

  /* From projects/widgets-old/src/lib/product-catalog/product-details-dialog/product-details-dialog.component.ts */
  pick(product: PopProduct): void {
    this.showErrors = false;
    if (this.isLoading) {
      return;
    }

    this.errorMessages = this.productCatalogHelper.getAllRequiredOptionsNotSelected(product);
    if (this.errorMessages.length) {
      return;
    }

    if (product.children?.length && !this.productCatalogHelper.isSelectedSubProduct(product)) {
      this.showErrors = true;
      return;
    }

    this.optionGroupQuantityError = this.productCatalogHelper.getOptionGroupsWithQuantityError(product);
    if (this.optionGroupQuantityError.length) {
      this.showErrors = true;
      return;
    }

    const productClone = JSON.parse(JSON.stringify(product)) as PopProduct;

    this.saveRecommendation(productClone);
    this.addedRecommendationsStack.push(productClone);
    this.hideMenu();
  }

  saveRecommendation(product: PopProduct): void {
    if (!this.addedRecommendations.find(({ id }) => id === product.id)) {
      this.addedRecommendations.push(product);
      return;
    }

    const sameProducts = this.addedRecommendations.filter(({ id }) => id === product.id);

    for (const sp of sameProducts) {
      if (this.compareProducts(sp, product)) {
        // sp.quantity++;
        sp.quantityMultiplier++;
        return;
      }
    }

    this.addedRecommendations.push(product);
  }

  private saveSimpleRecommendation(product: PopProduct): void {
    const savedRec = this.addedRecommendations.find(({ id }) => id === product.id);

    if (!savedRec) {
      this.addedRecommendations.push({ ...product, quantityMultiplier: 1 });
    } else {
      savedRec.quantityMultiplier++;
    }

    this.addedRecommendationsStack.push({ ...product, quantityMultiplier: 1 });
  }

  hasDetails(product: PopProduct): boolean {
    // recommendation have details if it has children or itemOptionGroups
    return (
      product &&
      ((!!product.children && !!product.children.length) ||
        (!!product.itemOptionGroups && !!product.itemOptionGroups.length))
    );
  }

  showMenu(product: PopProduct): Observable<boolean> {
    return this.detailsProduct$.pipe(map(detailsProduct => !!detailsProduct && detailsProduct.id === product.id));
  }

  hideMenu(): void {
    this.menuBackdrop.removeBackdrop();
    this.detailsProduct$.next(null);
  }

  // Temporarily for clear data after dialog close
  reset(): void {
    this.addedRecommendations = [];
    this.addedRecommendationsStack = [];
    this.showErrors = false;
    this.isLoading = false;
    this.errorMessages = [];
    this.optionGroupQuantityError = [];
  }

  private compareProducts(sp: PopProduct, product: PopProduct): boolean {
    if (sp.id !== product.id) {
      return false;
    }

    const isSameChildSelected =
      product.children && product.children.length
        ? product.children.every((p, i) => p.isSelected === sp.children[i].isSelected)
        : true;

    const isSameOptionsSelected = product.itemOptionGroups.every((p, i) =>
      p.optionGroup.optionGroupSubitems.every(
        (s, j) =>
          s.item.isSelected === sp.itemOptionGroups[i].optionGroup.optionGroupSubitems[j].item.isSelected &&
          s.item.quantity === sp.itemOptionGroups[i].optionGroup.optionGroupSubitems[j].item.quantity,
      ),
    );

    return isSameChildSelected && isSameOptionsSelected;
  }
}
