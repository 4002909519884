export interface GenericNotification {
  applicationId: number;
  body: string;
  businessLocationId: number;
  id: number;
  isActive: boolean;
  subject: string;
  transportType: string;
  type: NotificationsRoutes;
}

export interface Notification {
  sms?: SmsNotification[];
  email?: EmailNotification[];
  email_pos_pdf?: EmailNotification[];
  system?: SystemNotification[];
}

export interface NotificationEntity extends Notification {
  appSlug: string;
  type: NotificationsRoutes;
}

export const enum NotificationsRoutes {
  AfterFullRefund = 'after_full_refund',
  AfterPartialRefund = 'after_partial_refund',
  AfterSuccessPayment = 'after_success_payment',
  AfterDelivered = 'order_delivered',
  PickUpFullyReady = 'pick_up_fully_ready',
  PickUpPartiallyReady = 'pick_up_partially_ready',
  AfterSuccessPaymentForAdmin = 'after_success_payment_for_admin',
  UserPhoneChange = 'user_phone_change',
  UserSignIn = 'user_sign_in',
  UserSignUp = 'user_sign_up',
  SentToKitchen = 'order_sent_to_kitchen',
  Labels = 'labels',
}

export interface Notifications {
  [NotificationsRoutes.AfterFullRefund]: Notification;
  [NotificationsRoutes.AfterPartialRefund]: Notification;
  [NotificationsRoutes.AfterSuccessPayment]: Notification;
  [NotificationsRoutes.PickUpFullyReady]: Notification;
  [NotificationsRoutes.PickUpPartiallyReady]: Notification;
  [NotificationsRoutes.AfterSuccessPaymentForAdmin]: Notification;
  [NotificationsRoutes.UserPhoneChange]: Notification;
  [NotificationsRoutes.UserSignIn]: Notification;
  [NotificationsRoutes.UserSignUp]: Notification;
  [NotificationsRoutes.SentToKitchen]: Notification;
  [NotificationsRoutes.AfterDelivered]: Notification;
}

export interface SmsNotification extends GenericNotification {
  notificationSettings: {
    fromSms: string;
    orderNotifications?: OrderNotifications;
  };
}

export interface EmailNotification extends GenericNotification {
  notificationSettings: {
    from: string;
    sendOnlyLastNotification: boolean;
    templateUrl: string;
    orderNotifications?: OrderNotifications;
  };
}

export interface SystemNotification extends GenericNotification {
  notificationSettings: {
    level?: string;
    orderNotifications?: OrderNotifications;
  };
}

export interface NotificationSettings {
  from: string;
  fromSms: string;
  templateUrl: string;
  level: string;
  orderNotifications: OrderNotifications;
  labels: NotificationSettingsLabels;
}

export interface NotificationSettingsLabels {
  default: {
    totalHt: string;
    totalTtc: string;
    totalPaid: string;
  };
  items: {
    promo: string;
    tips: string;
    delivery_fee: string;
  };
}

export interface OrderNotifications {
  emailRecipients: string;
  smsRecipients: string;
}

export interface CustomNotificationConfig {
  businessLocationId: number;
  transportType: TransportType;
}

export const enum TransportType {
  Sms = 'sms',
  Email = 'email',
  EmailPosPdf = 'email_pos_pdf',
  EmailPosPdfComponent = 'email POS pdf',
}

export type TypeNotification = SmsNotification | EmailNotification;
