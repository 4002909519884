import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ITimeRuleValidator } from '../../models';

@Component({
  selector: 'pop-widget-edit-time-validator',
  templateUrl: './time-validator.component.html',
  styleUrls: ['./time-validator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeValidatorComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  weekdays = [
    { weekday: 1, dayName: 'STUDIO.WEEKDAYS.MONDAY', value: false },
    { weekday: 2, dayName: 'STUDIO.WEEKDAYS.TUESDAY', value: false },
    { weekday: 3, dayName: 'STUDIO.WEEKDAYS.WEDNESDAY', value: false },
    { weekday: 4, dayName: 'STUDIO.WEEKDAYS.THURSDAY', value: false },
    { weekday: 5, dayName: 'STUDIO.WEEKDAYS.FRIDAY', value: false },
    { weekday: 6, dayName: 'STUDIO.WEEKDAYS.SATURDAY', value: false },
    { weekday: 0, dayName: 'STUDIO.WEEKDAYS.SUNDAY', value: false },
  ];

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    const rules: ITimeRuleValidator[] = this.form.value.rules;
    rules.forEach(rule => this._setWeekdayValue(rule.weekday, true));
  }

  changeWeekdayValue(weekday: number): void {
    this._setWeekdayValue(weekday, !this._getWeekdayValue(weekday));

    if (this._getWeekdayValue(weekday)) {
      this._addWeekday(weekday);
    } else {
      this._removeWeekday(weekday);
    }
  }

  addIntervalTime(weekday: number, index: number): void {
    this._addIntervalTime(index + 1, weekday);
  }

  removeIntervalTime(index: number): void {
    this._getRules().removeAt(index);
  }

  showIntervalButtonRemove(weekday: number): boolean {
    return (
      this.form.get('rules').value.filter((intervalTime: ITimeRuleValidator) => intervalTime.weekday === weekday)
        .length > 1
    );
  }

  private _getRules(): UntypedFormArray {
    return this.form.get('rules') as UntypedFormArray;
  }

  private _getRulesValue(): ITimeRuleValidator[] {
    return this._getRules().value;
  }

  private _addWeekday(weekday: number): void {
    const index =
      weekday === 0
        ? this._getRulesValue().length
        : this._getRulesValue().findIndex(timeInterval => timeInterval.weekday > weekday);

    this._addIntervalTime(index, weekday);
  }

  private _removeWeekday(weekday: number): void {
    const removeIndex = this._getRulesValue().reduce(
      (acc, rule, index) => [...acc, ...(rule.weekday === weekday ? [index] : [])],
      [],
    );

    removeIndex.forEach((ruleIndex, index) => this._getRules().removeAt(ruleIndex - index));
  }

  private _setWeekdayValue(weekday: number, value: boolean): void {
    this.weekdays.find(day => day.weekday === weekday).value = value;
  }

  private _getWeekdayValue(weekday: number): boolean {
    return this.weekdays.find(day => day.weekday === weekday).value;
  }

  private _addIntervalTime(index: number, weekday: number): void {
    this._getRules().insert(index, this._createIntervalTime(weekday, '', ''));
  }

  private _createIntervalTime(weekday: number, from: string, to: string): UntypedFormGroup {
    return this._formBuilder.group({
      weekday,
      from: [from, [this._isWorkingHoursIntervalValid()]],
      to: [to, [this._isWorkingHoursIntervalValid()]],
    });
  }

  private _isWorkingHoursIntervalValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = !!control.value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) || control.value === '24:00';

      return result ? null : { timeInvalid: true };
    };
  }
}
