import { PopCurrency } from '@designer-shared/types';

export const currenciesConfig: PopCurrency[] = [
  {
    name: 'Euro',
    code: 'eur',
    symbol: '€',
  },
  {
    name: 'US Dollar',
    code: 'usd',
    symbol: '$',
  },
  {
    name: 'Pound Sterling',
    code: 'gbp',
    symbol: '£',
  },
  {
    name: 'Australian Dollar',
    code: 'aud',
    symbol: 'a$',
  },
  {
    name: 'Canadian Dollar',
    code: 'cad',
    symbol: 'c$',
  },
  {
    name: 'Danish Krone',
    code: 'dkk',
    symbol: 'Kr.',
  },
  {
    name: 'Swiss Franc',
    code: 'chf',
    symbol: 'Fr',
  },
  {
    name: 'Swedish Krona',
    code: 'sek',
    symbol: 'kr',
  },
];
