import { Page } from './';

export interface PagesList {
  single: Page[];
}

export interface PageWithSettings extends Page {
  isLive: boolean;
  settings: {
    backgroundColor: string;
    pageFont: {
      name: string;
      url: string;
    };
    leftButtonType: string;
    actionCall: {
      display: boolean;
      title: string;
      popupHeader: string;
      icon: string;
      actionButtons: Action[];
    };
  };
}

export interface Action {
  type: ActionType;
  title: string;
  icon: string;
  description?: string;
  actionMessage?: string;
  telephone?: string;
  sms?: string;
  email?: string;
  addTableName?: boolean;
  body?: string;
}

export enum ActionType {
  BREAD = 'bread',
  WATER = 'water',
  CHECK = 'check',
  WAITER = 'waiter',
  CUSTOM = 'custom',
  TELEPHONE = 'telephone',
  SMS = 'sms',
  EMAIL = 'email',
  FALLBACK = '',
}

export enum LEFT_BUTTON_TYPE {
  BACK = 'back',
  HOME = 'home',
}

export const enum PageType {
  NORMAL = 'normal',
  DIRECT_LINK = 'direct_link',
}

export interface Font {
  name: string;
  url?: string;
}

export const enum PageGroup {
  SINGLE = 'STUDIO.PAGES.SINGLE_PAGES',
}
