import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AllowedPagesService {
  private readonly _allowedPages: Set<string> = new Set();
  private readonly _allowedPages$ = new BehaviorSubject<Set<string>>(this._allowedPages);

  get pages$(): Observable<Set<string>> {
    return this._allowedPages$.asObservable();
  }

  add(page: string): void {
    this._allowedPages.add(page);
    this._allowedPages$.next(this._allowedPages);
  }
}
