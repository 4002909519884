import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

@Injectable()
export class WidgetActionCallService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  callAction(pageId: string, widgetId: string, actionButtonId: string, placeSlug: string): Observable<any> {
    return this._api
      .actionCall('v1', pageId)
      .pipe(switchMap(api => this._http.post(api, { widgetId, actionButtonId, placeSlug })));
  }
}
