import { Injectable } from '@angular/core';
import { map, Observable, share, switchMap, timer } from 'rxjs';

import { HappyHoursInterval, SettingsService } from '@bend/store';

@Injectable({ providedIn: 'root' })
export class HappyHoursService {
  constructor(private _settings: SettingsService) {}

  get isHappyHours(): Observable<boolean> {
    return timer(0, 1000).pipe(
      switchMap(() => this.isInIntervals(new Date())),
      share(),
    );
  }

  isInIntervals(time: Date): Observable<boolean> {
    return this._settings.happyHours.pipe(
      map(({ enabled, intervals }) => {
        if (!enabled) return false;

        return this._isInIntervals(time, intervals);
      }),
    );
  }

  private _isInIntervals(time: Date, intervals: HappyHoursInterval[]): boolean {
    return intervals.some(this._isInInterval(time));
  }

  private _isInInterval(time: Date): ({ open, close }: { open: Date; close: Date }) => boolean {
    return ({ open, close }) => open.getTime() <= time.getTime() && close.getTime() >= time.getTime();
  }
}
