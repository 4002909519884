import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { WidgetModel } from './widget.models';

export const widgetActions = createActionGroup({
  source: 'widget',
  events: {
    'Get Widgets': emptyProps(),
    'Get Widgets Success': props<{ widgets: WidgetModel[] }>(),
    'Get Widgets Error': props<{ error: string }>(),

    'Delete Widget': props<{ widgetId: string }>(),
    'Delete Widget Success': props<{ widgetId: string }>(),
    'Delete Widget Error': props<{ widgetId: string; error: string }>(),

    'Duplicate Widget': props<{ widgetId: string }>(),
    'Duplicate Widget Success': props<{ widgetId: string; widget: WidgetModel }>(),
    'Duplicate Widget Error': props<{ error: string; widgetId: string }>(),

    'Publish Widget': props<{ widgetId: string; published: boolean }>(),
    'Publish Widget Success': props<{ widgetId: string; published: boolean }>(),
    'Publish Widget Error': props<{ widgetId: string; error: string }>(),

    'Change Widget Order': props<{
      previousIndex: number;
      currentIndex: number;
    }>(),
    'Change Widget Order Success': props<{ widgets: WidgetModel[] }>(),
    'Change Widget Order Error': props<{ widgets: WidgetModel[]; error: string }>(),

    'Reset': emptyProps(),
  },
});
