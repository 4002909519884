import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { actions } from './users.actions';
import { UsersHttpService } from './users.http.service';

@Injectable()
export class UsersEffects {
  constructor(private _actions$: Actions, private _userHttpService: UsersHttpService) {}

  getListOfUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getUsersByOffsetAndLimit),
      mergeMap(({ limit, offset, onlyPendingTickets, search }) =>
        this._userHttpService.getUsers({ limit, offset, onlyPendingTickets, search }).pipe(
          map(response => actions.getUsersByOffsetAndLimitSuccess(response)),
          catchError(({ errorCode }) => of(actions.getUsersByOffsetAndLimitError(errorCode))),
        ),
      ),
    ),
  );

  getListOfUsersBySearch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getUsersBySearch),
      mergeMap(({ limit, onlyPendingTickets, search }) =>
        this._userHttpService.getUsers({ limit, onlyPendingTickets, search, offset: 0 }).pipe(
          map(response => actions.getUsersBySearchSuccess(response)),
          catchError(({ errorCode }) => of(actions.getUsersBySearchError(errorCode))),
        ),
      ),
    ),
  );

  getUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getUser),
      mergeMap(({ userId }) =>
        this._userHttpService.getUserInfoById(userId).pipe(
          map(user => actions.getUserSuccess({ user })),
          catchError(({ errorCode }) => of(actions.getUserError(errorCode))),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateUser),
      mergeMap(({ user: { id, ...rest } }) =>
        this._userHttpService.updateUserInfo(id, rest).pipe(
          map(updatedUser => actions.updateUserSuccess({ user: updatedUser })),
          catchError(errorCode => of(actions.updateUserError(errorCode))),
        ),
      ),
    ),
  );

  addCredits$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addCredits),
      mergeMap(({ user: { id }, add: { credit, reason, paymentMethod } }) =>
        this._userHttpService.addCredit(id, credit, reason, paymentMethod).pipe(
          map(response => actions.addCreditsSuccess({ user: { id, changes: { ...response, isLoading: false } } })),
          catchError(({ errorCode }) =>
            of(actions.addCreditsError({ errorCode, user: { id, changes: { isLoading: false } } })),
          ),
        ),
      ),
    ),
  );
}
