<div class="header">
  <lib-icon (click)="onClose()" class="header__back-icon" icon="back"></lib-icon>
  <div class="header__title">{{ 'CART.PHONE_NUMBER_POPUP_TITLE' | translate }}</div>
</div>

<div class="search">
  <lib-icon [style.fill]="(color$ | async).primary" class="search__icon" icon="search"></lib-icon>
  <input [formControl]="searchControl" class="search__input" />
</div>

<ul class="country-list">
  <button (click)="onSelectCountry(country)" *ngFor="let country of countries$ | async" class="country-button">
    <li class="country">
      <img [src]="'/assets/images/flags/' + country.code + '.svg'" alt="" class="country__flag" />
      <span class="country__name">{{ country.name }}</span>
      <span class="country__prefix">{{ country.prefix }}</span>
    </li>
  </button>
</ul>
