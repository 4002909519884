import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnDestroy {
  @Input() index: number | null = 0;
  @Input() tabs: {
    actions: (string | number)[];
    labels: string[];
  };

  @Input() allowToDelete = false;
  @Output() selected: EventEmitter<string | number> = new EventEmitter();
  @Output() deleted: EventEmitter<string | number> = new EventEmitter();

  private _subscription = new Subscription();

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  select(index: number): void {
    this.index = index;
    this.selected.emit(this.tabs.actions[index]);
  }

  delete(index: number, event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.deleted.emit(this.tabs.actions[index]);
  }
}
