import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';

import { OrderTypeService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { SettingsService } from '@bend/store';

import { PopProduct } from '../product-catalog/product-catalog';
import { WidgetProductCatalogService } from '../product-catalog/product-catalog.service';
import { WidgetProductCatalogDetailsDialogComponent } from '../product-catalog/product-details-dialog/product-details-dialog.component';
import { PricesService } from '../product-catalog/services/prices.service';
import { SessionService } from '../session.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopCurrency, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { ProductTeaserLabels, VerticalAlignType, WidgetProductTeaser } from './product-teaser';

@Component({
  selector: 'pop-widget-product-teaser',
  templateUrl: './product-teaser.component.html',
  styleUrls: ['./product-teaser.component.scss'],
})
export class WidgetProductTeaserComponent implements OnInit, OnDestroy, WidgetComponent {
  static widgetName = 'product_teaser';
  @Input() attributes: WidgetProductTeaser;
  text: SafeHtml;
  noValidMessage: string;
  product: PopProduct;
  imageUrl: string;
  isValid: boolean;
  isReadOnly: boolean;
  isConsultingMode: boolean;
  textTop: number;
  textTransform: string;
  isPopValidated: boolean;
  productCatalogStyle: number;

  private _subscription: Subscription;

  constructor(
    private _warningService: WarningService,
    private _sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _productCatalogService: WidgetProductCatalogService,
    private _dialog: MatDialog,
    private _sessionService: SessionService,
    private _cdr: ChangeDetectorRef,
    private _orderType: OrderTypeService,
    private pricesService: PricesService,
    private readonly _settings: SettingsService,
  ) {
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    if (
      !this.attributes ||
      this.attributes.useProductFromUrl === undefined ||
      (!this.attributes.useProductFromUrl && !this.attributes.productId)
    ) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetProductTeaserComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this.isReadOnly = true;
    this.imageUrl = this.attributes.imageUrl;
    this.text = this.attributes.text ? this._sanitizer.bypassSecurityTrustHtml(this.attributes.text) : '';
    this._addMissingAttributes(this.attributes);
    this.isPopValidated = this.attributes.pop.validationStatus !== PopValidationStatus.FAILED;
    this.textTop = this._getTextTop(this.attributes.style.text.verticalAlign);
    this.textTransform = `translate(0%, -${this.textTop}%)`;
    this.isValid = true;
    // This if code block is temporary, to be removed after authorize admin user to access shop-api
    if (this.attributes.isEditMode) {
      return;
    }

    this._initSubscriptions();
    this._downloadProduct();

    this._subscription.add(
      this._orderType.isReadOnly
        .pipe(
          tap(isReadOnly => (this.isReadOnly = isReadOnly)),
          tap(() => this._cdr.detectChanges()),
        )
        .subscribe(),
    );

    this._subscription.add(this._isConsultingModeSubscription);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  showProduct(): void {
    if (this.attributes.isEditMode || !this.product || !this.isPopValidated) {
      return;
    }

    this.updatedProduct(this.product)
      .pipe(take(1))
      .subscribe(productClone => {
        this._dialog.open(WidgetProductCatalogDetailsDialogComponent, {
          maxWidth: 'none',
          width: '100vw',
          height: '100%',
          data: {
            product: productClone,
            attributes: {
              isReadOnly: this.isReadOnly,
              isConsultingMode: this.isConsultingMode,
              allowOrderForOthers: this.attributes.allowOrderForOthers,
              itemUnavailabilityDisplay: this.attributes.itemUnavailableDisplay,
              mainColor: this.attributes.mainColor,
              allowCommonPot: this.attributes.allowCommonPot,
              showKitchenComment: this.attributes.showKitchenComment,
              isCommentToKitchenRequired: this.attributes.isCommentToKitchenRequired,
              labels: this.attributes.labels,
              currency: this.attributes.currency,
              styleConfig: this.attributes.styleConfig,
            },
            pageAnalytics: this.attributes.page,
            isMenu: false,
          },
          panelClass: 'product-details-dialog-overlay',
        });
      });
  }

  private _downloadProduct(): void {
    if (this.attributes.isEditMode) {
      return;
    }

    const productId = this._getProductId();
    if (!productId) {
      return;
    }

    this._productCatalogService
      .getProduct(productId)
      .pipe(switchMap(product => this.updatedProduct(product)))
      .subscribe(product => {
        if (!product) {
          return;
        }

        this.product = product;
        this.imageUrl = this.imageUrl || product.imageUrl;
        this._cdr.detectChanges();
      });
  }

  private _getProductId(): string {
    if (this.attributes.useProductFromUrl) {
      return this._route.snapshot.queryParamMap.get('productId');
    }
    if (this.attributes.productId) {
      return this.attributes.productId.toString();
    }
    return null;
  }

  private _handleSessionClosed(): void {
    this.isReadOnly = true;
    this._cdr.detectChanges();
  }

  private _handleSessionCreated(): void {
    if (this.attributes.isReadOnly || !this.isPopValidated) {
      return;
    }

    this.isReadOnly = false;
    this._cdr.detectChanges();
  }

  private _handleDisabledAddingItemsToCartSubscription(): void {
    this.isReadOnly = true;
    this._cdr.detectChanges();
  }

  private _getTextTop(align: VerticalAlignType): number {
    switch (align) {
      case VerticalAlignType.TOP:
        return 0;
      case VerticalAlignType.BOTTOM:
        return 100;
      case VerticalAlignType.CENTER:
      default:
        return 50;
    }
  }

  private _addMissingAttributes(attributes: WidgetProductTeaser): void {
    if (!attributes.style.text) {
      attributes.style.text = {
        margin: 0,
        verticalAlign: VerticalAlignType.CENTER,
        backgroundColor: '',
      };
    }

    if (!attributes.labels) {
      attributes.labels = new ProductTeaserLabels();
    }

    if (!attributes.currency) {
      attributes.currency = new PopCurrency();
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }
  }

  private _initSubscriptions(): void {
    this._subscription.add(this._sessionService.onSessionClosed().subscribe(() => this._handleSessionClosed()));

    this._subscription.add(this._sessionService.onSessionCreated().subscribe(() => this._handleSessionCreated()));

    this._subscription.add(
      this._sessionService
        .onDisabledAddingItemsToCart()
        .subscribe(() => this._handleDisabledAddingItemsToCartSubscription()),
    );
  }

  private get _isConsultingModeSubscription(): Subscription {
    return this._settings.widgets
      .pipe(
        map(widgets => widgets.catalog.isReadOnly),
        tap(isConsultingMode => {
          this.isConsultingMode = isConsultingMode;
        }),
      )
      .subscribe();
  }

  private updatedProduct(product: PopProduct): Observable<PopProduct> {
    return combineLatest([of(product), this.pricesService.flow]).pipe(
      take(1),
      map(([product, flow]) => {
        let productClone = JSON.parse(JSON.stringify(product)) as PopProduct;
        productClone = {
          ...this.pricesService.getUpdatedProductByFlow<PopProduct>(productClone, flow),
          // update prices for itemOptionGroups
          itemOptionGroups: this.pricesService.updatedItemOptionGroups(productClone.itemOptionGroups, flow),
          // update prices for children
          children: productClone.children.map(child => this.pricesService.getUpdatedProductByFlow(child, flow)),
        };

        // Modify price for options
        productClone.itemOptionGroups.forEach(group => {
          group.optionGroup.optionGroupSubitems.forEach(subItem => {
            subItem.item = this.pricesService.getUpdatedProductByFlow(subItem.item, flow);
          });
        });

        return productClone;
      }),
    );
  }
}
