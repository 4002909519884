import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatIconRegistryModule } from '@designer-shared/icons/material-icon-registry.module';

import { SnackBarBuilderService } from './builder';
import { SnackBarComponent, SnackbarContainerComponent } from './components';

@NgModule({
  declarations: [SnackBarComponent, SnackbarContainerComponent],
  imports: [CommonModule, MatSnackBarModule, MatIconModule, MatButtonModule, MatIconRegistryModule],
  providers: [SnackBarBuilderService],
})
export class SnackbarModule {}
