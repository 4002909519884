import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, tap } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { SnackBarService, SuccessCode } from '@designer-shared';
import { selectAppSlugParam, selectPageIdParam } from '@designer-store/router';

import { pageActions } from './page.actions';
import { PageHttpService } from './page-http.service';

@Injectable()
export class PageEffects {
  getPagesEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(pageActions.getPages),
      exhaustMap(() =>
        this._pageHttp.getPages().pipe(
          map(({ single: pages }) => pageActions.getPagesSuccess({ pages })),
          catchError(error => of(pageActions.getPagesError({ error }))),
        ),
      ),
    ),
  );

  getPageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(pageActions.getPage),
      concatLatestFrom(() => this._store$.select(selectPageIdParam)),
      exhaustMap(([, pageId]) =>
        this._pageHttp.getPage(pageId).pipe(
          map(page => pageActions.getPageSuccess({ page })),
          catchError(error => of(pageActions.getPageError({ error }))),
        ),
      ),
    ),
  );

  createPageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(pageActions.createPage),
      concatLatestFrom(() => this._store$.select(selectAppSlugParam)),
      exhaustMap(([{ page }, appSlug]) =>
        this._pageHttp.createPage(page).pipe(
          tap({
            next: createdPage => {
              this._snackBar.success(SuccessCode.ADDED);
              this._router.navigate([`/dashboard/${appSlug}/pages/${createdPage._id}`]);
            },
            error: () => this._snackBar.error(),
          }),
          map(createdPage => pageActions.createPageSuccess({ page: createdPage })),
          catchError(error => of(pageActions.createPageError({ error }))),
        ),
      ),
    ),
  );

  updatePageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(pageActions.updatePage),
      concatLatestFrom(() => this._store$.select(selectPageIdParam)),
      exhaustMap(([{ page }, pageId]) =>
        this._pageHttp.updatePage(pageId, page).pipe(
          tap({
            next: () => this._snackBar.success(SuccessCode.UPDATED),
            error: () => this._snackBar.error(),
          }),
          map(page => pageActions.updatePageSuccess({ page })),
          catchError(error => of(pageActions.updatePageError({ error }))),
        ),
      ),
    ),
  );

  deletePageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(pageActions.deletePage),
      exhaustMap(({ pageId }) =>
        this._pageHttp.deletePage(pageId).pipe(
          tap({
            next: () => this._snackBar.success(SuccessCode.DELETED),
            error: () => this._snackBar.error(),
          }),
          map(() => pageActions.deletePageSuccess({ pageId })),
          catchError(error => of(pageActions.deletePageError({ pageId, error }))),
        ),
      ),
    ),
  );

  duplicatePageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(pageActions.duplicatePage),
      concatLatestFrom(() => [this._store$.select(selectPageIdParam), this._store$.select(selectAppSlugParam)]),
      exhaustMap(([{ name }, pageId, appSlug]) =>
        this._pageHttp.duplicatePage(pageId, name).pipe(
          tap({
            next: page => {
              this._snackBar.success(SuccessCode.COPIED);
              this._router.navigate([`/dashboard/${appSlug}/pages/${page._id}`]);
            },
            error: () => this._snackBar.error(),
          }),
          map(page => pageActions.duplicatePageSuccess({ page })),
          catchError(error => of(pageActions.duplicatePageError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly _store$: Store,
    private readonly _actions$: Actions,
    private readonly _pageHttp: PageHttpService,
    private readonly _router: Router,
    private readonly _snackBar: SnackBarService,
  ) {}
}
