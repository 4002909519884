import { MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { DialogConfigComponent } from '../types';

export class DialogGeneric<T> {
  title$: Observable<SafeHtml | undefined>;
  description$: Observable<SafeHtml>;
  showCloseButton: boolean;

  private _timeout: NodeJS.Timer;

  constructor(private _data: DialogConfigComponent, private _dialogRef: MatDialogRef<T>) {
    const { title, description, timeToClose, showCloseButton = true } = this._data;

    this.title$ = title;
    this.description$ = description;
    this.showCloseButton = showCloseButton;

    if (timeToClose) {
      this._timeout = setTimeout(() => this._dialogRef.close(), timeToClose);
    }
  }

  close<R>(state?: R): void {
    if (this._timeout) clearTimeout(this._timeout);

    if (state) this._dialogRef.close(state);
    else this._dialogRef.close();

    this._data.close(this._dialogRef.id);
  }
}
