import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import {
  BottomBar,
  CapacityManagement,
  DictionaryWorkingHoursIntervals,
  distinctUntilChangedSource,
  filterUndefined,
  PartialNullify,
  SettingsArea,
  SettingsBehavior,
  SettingsCart,
  SettingsCatalog,
  SettingsGeneralArea,
  SettingsGoogleAnalytics,
  SettingsHotjarAnalytics,
  SettingsPop,
  SettingsTips,
  SmartWifiSettings,
  StoreService,
  TopBar,
  WorkingHours,
} from '../shared';
import { DictionaryHappyHoursIntervals, HappyHours } from '../shared/types/happy-hours.type';
import { actions } from './settings.actions';
import * as selectors from './settings.selectors';
import { State } from './settings.type';

@Injectable()
export class AreaSettingsService extends StoreService<State> {
  constructor(
    protected override _store: Store<State>,
    protected override _actions: Actions,
    protected _designerRouter: DesignerRouterService,
  ) {
    super(_store, _actions, selectors);
    this._getSettings.subscribe();
  }

  get isLoaded(): Observable<boolean> {
    return combineLatest([this._store.select(selectors.isLoadedBy), this._store.select(selectors.selectedId)]).pipe(
      map(([loadedIds, selectedId]) => loadedIds.includes(selectedId)),
    );
  }

  get general(): Observable<PartialNullify<SettingsGeneralArea>> {
    return this._store
      .select(selectors.general)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get behavior(): Observable<PartialNullify<SettingsBehavior>> {
    return this._store
      .select(selectors.behavior)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get pop(): Observable<PartialNullify<SettingsPop>> {
    return this._store
      .select(selectors.pop)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get tips(): Observable<PartialNullify<SettingsTips>> {
    return this._store
      .select(selectors.tips)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get sw(): Observable<PartialNullify<SmartWifiSettings>> {
    return this._store
      .select(selectors.sw)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get workingHours(): Observable<PartialNullify<WorkingHours<string, DictionaryWorkingHoursIntervals>> | null> {
    return this._store
      .select(selectors.workingHours)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get happyHours(): Observable<PartialNullify<HappyHours<string, DictionaryHappyHoursIntervals>> | null> {
    return this._store
      .select(selectors.happyHours)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get googleAnalytics(): Observable<PartialNullify<SettingsGoogleAnalytics>> {
    return this._store
      .select(selectors.googleAnalytics)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get hotjarAnalytics(): Observable<PartialNullify<SettingsHotjarAnalytics>> {
    return this._store
      .select(selectors.hotjarAnalytics)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get topBar(): Observable<PartialNullify<TopBar>> {
    return this._store
      .select(selectors.topBar)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get bottomBar(): Observable<PartialNullify<BottomBar>> {
    return this._store
      .select(selectors.bottomBar)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get cart(): Observable<PartialNullify<SettingsCart>> {
    return this._store
      .select(selectors.cart)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get productCatalog(): Observable<PartialNullify<SettingsCatalog>> {
    return this._store
      .select(selectors.productCatalog)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get capacity(): Observable<PartialNullify<CapacityManagement>> {
    return this._store
      .select(selectors.capacity)
      .pipe(filterUndefined(), distinctUntilChangedSource(this._store.select(selectors.selectedId)));
  }

  get error(): Observable<string> {
    return this._store.select(selectors.error);
  }

  saveSettings(settings: Partial<SettingsArea>): Observable<boolean> {
    this._areaAndLocationIds
      .pipe(
        tap(([areaId, locationId]) =>
          this._store.dispatch(
            actions.patchAreaSettings({
              locationId,
              settings,
              by: areaId,
            }),
          ),
        ),
      )
      .subscribe();

    return this._finishedAction(actions.patchAreaSettingsSuccess, actions.patchAreaSettingsError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.error.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  private get _areaAndLocationIds(): Observable<[number, number]> {
    return combineLatest([this._designerRouter.areaId, this._designerRouter.locationId]).pipe(
      map(([areaId, locationId]) => [+areaId, +locationId]),
    );
  }

  private get _getSettings(): Observable<any> {
    return combineLatest([this._designerRouter.areaIdChanges, this._designerRouter.locationIdChanges]).pipe(
      tap(
        ([areaId, locationId]) =>
          areaId &&
          locationId &&
          this._store.dispatch(actions.getAreaSettings({ by: Number(areaId), locationId: Number(locationId) })),
      ),
    );
  }
}
