import { Margin } from '../../shared/interfaces';
import { Widget } from '../../shared/models';

export interface ButtonsWidget extends Widget {
  attributes: {
    title: string;
    buttons: Button[];
    style: {
      margin: Margin;
      button: ButtonsWidgetStyleButton;
      displayType: ButtonsDisplayType;
      showBackground: boolean;
    };
  };
}

export class ButtonsWidgetStyleButton {
  height: 'default';
  backgroundColor: '';
  borderColor: '';
  borderWidth: 0;
  borderRadius: 10;
  fontSize: 'default';
  textColor: '';
}

export interface Button {
  icon: string;
  link: string;
  text: string;
  openInNewTab: boolean;
}

export enum ButtonsDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
  STYLE3 = 'style3',
}
