import { createAction, props } from '@ngrx/store';

import { createType, GenericNotification, NotificationEntity, NotificationsRoutes, TypeNotification } from '../shared';
import { storeKey } from './notifications.config';

const type = createType(storeKey);

export const actions = {
  updateNotification: createAction(
    type('update notification'),
    props<{
      notificationId: number;
      settings: GenericNotification;
    }>(),
  ),
  updateNotificationSuccess: createAction(
    type('update notification success'),
    props<{
      notification: TypeNotification;
      currentAppSlug: string;
    }>(),
  ),
  updateNotificationError: createAction(type('update notification error'), props<{ errorCode: string }>()),

  getNotificationByType: createAction(
    type('get notification type'),
    props<{
      currentType: NotificationsRoutes;
      appSlug: string;
    }>(),
  ),
  getNotificationByTypeIsLoaded: createAction(
    type('get notification type is loaded'),
    props<{
      currentType: NotificationsRoutes;
      appSlug: string;
    }>(),
  ),
  getNotificationByTypeSuccess: createAction(
    type('get notification type success'),
    props<{ settings: NotificationEntity }>(),
  ),
  getNotificationByTypeError: createAction(type('get notification type error'), props<{ errorCode: string }>()),

  addCustomNotification: createAction(
    type('add custom notification'),
    props<{ settings: Partial<GenericNotification> }>(),
  ),
  addCustomNotificationSuccess: createAction(
    type('add custom notification success'),
    props<{
      notification: TypeNotification;
      appSlug: string;
    }>(),
  ),
  addCustomNotificationError: createAction(type('add custom notification error'), props<{ errorCode: string }>()),

  deleteCustomNotification: createAction(
    type('delete custom notification'),
    props<{ notification: Partial<GenericNotification> }>(),
  ),
  deleteCustomNotificationSuccess: createAction(
    type('delete custom notification success'),
    props<{
      notification: Partial<GenericNotification>;
      appSlug: string;
    }>(),
  ),
  deleteCustomNotificationError: createAction(type('delete notification error'), props<{ errorCode: string }>()),
};
