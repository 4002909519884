import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UsersState } from '@designer-store/users/users.state';

export const DESIGNER_USERS_STATE_KEY = 'usersApp';

const selectUsersState = createFeatureSelector<UsersState>(DESIGNER_USERS_STATE_KEY);

export const all = createSelector(selectUsersState, state => state);
export const users = createSelector(selectUsersState, state => state.users);
export const isLoading = createSelector(selectUsersState, state => state.isLoading);

export const totalCount = createSelector(selectUsersState, state => state.totalCount);
export const errorCode = createSelector(selectUsersState, state => state.error);
export const userById = (id: string) =>
  createSelector(selectUsersState, state => state.users.find(user => user._id === id));
