import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { FileType, ImageGalleryService } from '@bend/store/src/lib/image-gallery';
import { Application, Image } from '@bend/store/src/lib/shared/types/image-gallery.type';

import { DesignerRouterService } from '@designer-store/router';

import { CropperDialogComponent, GalleryUploadDialogComponent, GalleryUrlUploadDialogComponent } from '../index';

@Component({
  selector: 'designer-gallery-container',
  templateUrl: './gallery-container.component.html',
  styleUrls: ['./gallery-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryContainerComponent implements OnInit {
  selectedImageId: number;
  currentApp: string;

  isLoaded$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  applicationsList$: Observable<Application[]>;
  applicationsImages$: Observable<Image[]>;
  appSlug$: Observable<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { fileTypes: FileType[] },
    public dialogRef: MatDialogRef<GalleryContainerComponent>,
    private _imageGalleryService: ImageGalleryService,
    private _dialog: MatDialog,
    private _designerRouter: DesignerRouterService,
  ) {
    this.appSlug$ = this._designerRouter.appSlug;

    this._designerRouter.appSlug
      .pipe(
        tap(app => {
          this.currentApp = app;
          this._imageGalleryService.getApplications(app, this.data.fileTypes);
        }),
      )
      .subscribe();
    this._imageGalleryService.getImagesByApp(this.data.fileTypes);

    this.applicationsList$ = this._imageGalleryService.apps;
    this.isLoading$ = this._imageGalleryService.isLoading;
    this.applicationsImages$ = this._imageGalleryService.applicationImages;
    this.isLoaded$ = this._imageGalleryService.isAppsLoaded;
  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });
  }

  submit(): void {
    this._imageGalleryService
      .getImageById(this.selectedImageId)
      .pipe(
        first(),
        tap(image => {
          this.dialogRef.close(image.domain + image.relativePath);
        }),
      )
      .subscribe();
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  openUrlDialog(): void {
    this._dialog.open(GalleryUrlUploadDialogComponent, {
      width: '380px',
      height: '525px',
    });
  }

  openCropperDialog(): void {
    if (!this.selectedImageId) return;

    this._imageGalleryService
      .getImageById(this.selectedImageId)
      .pipe(
        first(),
        tap(image => {
          this._dialog.open(CropperDialogComponent, {
            data: { image },
            minWidth: '310px',
            minHeight: '154px',
          });
        }),
      )
      .subscribe();
  }

  changeSelectedApp($event: string): void {
    this.currentApp = $event;
    this._imageGalleryService.changeApp($event);
    this._imageGalleryService.getImagesByApp(this.data.fileTypes);
  }

  onFileChange(event: Event): void {
    if (!(<HTMLInputElement>event.target).files.length) return;

    this._dialog.open(GalleryUploadDialogComponent, {
      data: { files: (<HTMLInputElement>event.target).files, fileTypes: this.data.fileTypes },
      width: '750px',
      maxHeight: '85vh',
    });
  }
}
