import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-info',
  templateUrl: './tooltip-info.component.html',
  styleUrls: ['./tooltip-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipInfoComponent {
  @Input() tooltip = '';
}
