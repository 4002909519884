import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, Observable, OperatorFunction } from 'rxjs';

import { PosType } from '@designer-fsd-shared/types/pos';
import { DesignerRouterService } from '@designer-store/router';

import {
  CashpadPosSettings,
  DeliverectPosSettings,
  EurodataPosSettings,
  GenericPosSettings,
  HubrisePosSettings,
  IkentooPosSettings,
  MicrosPosSettings,
  MicrosV2PosSettings,
  PosPaymentMethod,
  PosSettings,
  StoreService,
  ZeltyPosSettings,
} from '../shared';
import { actions } from './pos-settings.actions';
import { PosSettingsHttpService } from './pos-settings.http.service';
import * as selectors from './pos-settings.selectors';
import { State } from './pos-settings.type';

@Injectable()
export class PosSettingsService extends StoreService<State> {
  readonly posSettings: Observable<PosSettings[]>;
  readonly currentPosSettings: Observable<PosSettings>;
  readonly cashpad: Observable<CashpadPosSettings>;
  readonly ikentoo: Observable<IkentooPosSettings>;
  readonly hubrise: Observable<HubrisePosSettings>;
  readonly deliverect: Observable<DeliverectPosSettings>;
  readonly eurodata: Observable<EurodataPosSettings>;
  readonly zelty: Observable<ZeltyPosSettings>;
  readonly micros: Observable<MicrosPosSettings>;
  readonly microsV2: Observable<MicrosV2PosSettings>;
  readonly generic: Observable<GenericPosSettings>;
  readonly lastAdded: Observable<number | string>;
  readonly isLoaded: Observable<boolean>;

  constructor(
    protected override _store: Store<State>,
    protected override _actions: Actions,
    protected _designerRouter: DesignerRouterService,
    private _httpService: PosSettingsHttpService,
  ) {
    super(_store, _actions, selectors);

    this.posSettings = this._posSettings;
    this.currentPosSettings = this._currentPosSettings;
    this.cashpad = this._cashpad;
    this.ikentoo = this._ikentoo;
    this.hubrise = this._hubrise;
    this.deliverect = this._deliverect;
    this.eurodata = this._eurodata;
    this.zelty = this._zelty;
    this.micros = this._micros;
    this.microsV2 = this._microsV2;
    this.generic = this._generic;
    this.lastAdded = this._lastAdded;
    this.isLoaded = this._isLoaded;
  }

  getPosSettingsList(appSlug: string): Observable<boolean> {
    this._store.dispatch(actions.getPosSettingsList({ appSlug }));

    return this._finishedActionStatus(this._error, actions.getPosSettingsListSuccess, actions.getPosSettingsListError);
  }

  getPosSettings(posSettingsId: number): Observable<boolean> {
    this._store.dispatch(actions.getPosSettings({ posSettingsId }));

    return this._finishedActionStatus(this._error, actions.getPosSettingsSuccess, actions.getPosSettingsError);
  }

  addPosSettings(posSettings: PosSettings): Observable<boolean> {
    this._store.dispatch(actions.addPosSettings({ posSettings }));

    return this._finishedActionStatus(this._error, actions.addPosSettingsSuccess, actions.addPosSettingsError);
  }

  deletePosSettings(posSettingsId: number): Observable<boolean> {
    this._store.dispatch(actions.deletePosSettings({ posSettingsId }));

    return this._finishedActionStatus(this._error, actions.deletePosSettingsSuccess, actions.deletePosSettingsError);
  }

  updatePosSettings(posSettings: PosSettings): Observable<boolean> {
    this._store.dispatch(actions.updatePosSettings({ posSettings }));

    return this._finishedActionStatus(this._error, actions.updatePosSettingsSuccess, actions.updatePosSettingsError);
  }

  removePosSettingsId(posSettingsId: number): void {
    this._store.dispatch(actions.removePosSettingsId({ posSettingsId }));
  }

  posLocations(posSettingsId: number): Observable<{ id: string; name: string }[]> {
    return this._httpService.posLocations(posSettingsId);
  }

  posPaymentMethods(posSettingsId: number): Observable<PosPaymentMethod[]> {
    return this._httpService.posPaymentMethods(posSettingsId);
  }

  posConnection(posSettingsId: number): Observable<{ url: string }> {
    return this._httpService.posConnection(posSettingsId);
  }

  private get _posSettings(): Observable<PosSettings[]> {
    return this._store.select(selectors.total);
  }

  private get _currentPosSettings(): Observable<PosSettings> {
    return this._store.select(selectors.currentPosSettings).pipe(filter<PosSettings>(Boolean));
  }

  private get _cashpad(): Observable<CashpadPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Cashpad));
  }

  private get _ikentoo(): Observable<IkentooPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Ikentoo));
  }

  private get _hubrise(): Observable<HubrisePosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Hubrise));
  }

  private get _deliverect(): Observable<DeliverectPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Deliverect));
  }

  private get _eurodata(): Observable<EurodataPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Eurodata));
  }

  private get _zelty(): Observable<ZeltyPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Zelty));
  }

  private get _micros(): Observable<MicrosPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Micros));
  }

  private get _microsV2(): Observable<MicrosV2PosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Micros_V2));
  }

  private get _generic(): Observable<GenericPosSettings> {
    return this._currentPosSettings.pipe(this._filterByType(PosType.Generic));
  }

  private get _lastAdded(): Observable<number | string> {
    return this._store.select(selectors.lastAdded);
  }

  private get _isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoaded);
  }

  private get _error(): Observable<string> {
    return this._store.select(selectors.errorCode);
  }

  private _filterByType<P extends { type: PosType }, T extends P>(posType: PosType): OperatorFunction<P, T> {
    return filter((posSettings: P): posSettings is T => posSettings.type === posType);
  }
}
