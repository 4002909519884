import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SmileHistoryItem } from '../shared';
import { actions } from './smile-history.actions';
import * as selectors from './smile-history.selectors';
import { State } from './smile-history.type';

@Injectable()
export class SmileHistoryService {
  constructor(private _store: Store<State>) {}

  get byPage(): Observable<SmileHistoryItem[]> {
    return this._store.pipe(select(selectors.all));
  }

  get total(): Observable<number> {
    return this._store.pipe(select(selectors.total));
  }

  get isLoading(): Observable<boolean> {
    return this._store.pipe(select(selectors.isLoading));
  }

  geSmileHistory(limit: number, skip: number, smileId: string): void {
    this._store.dispatch(actions.getSmileHistory({ smileId, limit, skip }));
  }
}
