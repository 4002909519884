import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubItem } from '../shared';
import * as selectors from './sub-item.selectors';
import { State } from './sub-item.type';

@Injectable({ providedIn: 'root' })
export class SubItemService {
  constructor(private _store: Store<State>) {}

  subItemsByItem(itemId: number): Observable<SubItem[]> {
    return this._store.select(selectors.all).pipe(map(subItems => subItems.filter(item => item.parentId === itemId)));
  }
}
