import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pop-widget-edit-action-call-telephone-form',
  templateUrl: './telephone-form.component.html',
  styleUrls: ['../action-call.component.scss'],
})
export class PopWidgetEditActionCallTelephoneFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() arrayItem: number;
  @Input() actionButtonArray: string;
}
