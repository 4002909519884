<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <pop-widget-edit-file-upload
        [title]="'WIDGETS.DOCUMENT_VIEWER.DOCUMENT_TO_VIEW' | translate"
        [fileUrl]="documentUrl.value"
        (fileChangeEmitter)="onFileChange($event, documentUrl)"
      ></pop-widget-edit-file-upload>
      <div class="alt-group">
        <label for="maxHitsPerDay" class="form-label">{{ 'WIDGETS.DOCUMENT_VIEWER.MAX_HITS' | translate }}</label>
        <input
          id="maxHitsPerDay"
          class="form-input form-input--alt"
          type="number"
          [placeholder]="'WIDGETS.DOCUMENT_VIEWER.MAX_HITS' | translate"
          formControlName="maxHitsPerDay"
        />
      </div>
      <span class="form-field-info">{{ 'WIDGETS.DOCUMENT_VIEWER.UNLIMITED' | translate }}</span>
    </div>
    <div class="form-row">
      <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
      <designer-image-gallery formControlName="imageUrl"></designer-image-gallery>
      <div class="alt-group">
        <label for="altInput" class="form-label">{{ 'WIDGETS.SHARED.IMAGE_ALT' | translate }}</label>
        <input
          id="altInput"
          class="form-input form-input--alt"
          type="text"
          [placeholder]="'WIDGETS.SHARED.IMAGE_ALT_TEXT' | translate"
          formControlName="imageAlt"
        />
      </div>
    </div>
    <div class="form-row" id="ckEditor">
      <label for="ckeditorInput" class="form-label">Text</label>
      <div
        *ngIf="text.invalid && (((text.dirty || text.touched) && text.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.TEXT_ENTER' | translate }}
      </div>
      <ckeditor id="ckeditorInput" [editor]="editor" [config]="editorConfig" formControlName="text"></ckeditor>
    </div>

    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="buttonBackgroundColorInput" class="form-label">
              {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
            </label>
            <span
              id="buttonBackgroundColorInput"
              [(colorPicker)]="backgroundColorValue"
              [style.backgroundColor]="backgroundColorValue"
              class="form-colorpicker"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form_row">
      <div class="form-row-groups-wrapper" formGroupName="style">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <input id="fullWidth" type="checkbox" formControlName="fullWidth" />
            <label for="fullWidth" class="form-label form-label--checkbox">
              {{ 'WIDGETS.STYLE.FULL_WIDTH' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
