import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { CategoryService } from '@bend/store/src/lib/category';
import { ParamsService } from '@bend/store/src/lib/params';
import { CatalogParams } from '@bend/store-shared';

import { xCenterScrollTo } from '../../../../../../../shared-widgets/src/lib/helpers';
import { StylesService } from '../../../catalog-services';

@Component({
  selector: 'app-nav-bar-item-catalog',
  templateUrl: './nav-bar-item-catalog.component.html',
  styleUrls: ['./nav-bar-item-catalog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarItemCatalogComponent implements OnInit, OnDestroy {
  @Input() tabId: string;

  itemStyle$: Observable<object>;
  isLoading$: Observable<boolean>;
  route: ActivatedRoute;
  defaultCategoryId$: Observable<number>;

  private _subscription: Subscription;

  constructor(
    public elementRef: ElementRef,
    private _styles: StylesService,
    private _category: CategoryService,
    private _route: ActivatedRoute,
    private _params: ParamsService,
  ) {
    this.route = this._route.parent;
    this.itemStyle$ = this._itemStyle;
  }

  ngOnInit(): void {
    this.isLoading$ = this._category.loading(this.tabId);
    this._subscription = this._scrollToThisElement();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private get _itemStyle(): Observable<object> {
    return this._params
      .paramsChanges([CatalogParams.TabId])
      .pipe(switchMap(([tabId]) => (tabId === this.tabId ? this._selectedStyle : this._unselectedStyle)));
  }

  private get _selectedStyle(): Observable<object> {
    return combineLatest([this._styles.categoryPrimaryBg$, this._styles.categoryPrimaryFg$]).pipe(
      map(([primary, secondary]) => ({
        color: secondary,
        backgroundColor: primary,
        borderRadius: '20px',
        textShadow: '.1px 0px .1px,-.1px 0px .1px',
      })),
    );
  }

  private get _unselectedStyle(): Observable<object> {
    return of({
      color: '#000',
      backgroundColor: '#fff',
      borderRadius: 'unset',
      textShadow: 'unset',
    });
  }

  private _scrollToThisElement(): Subscription {
    return this._params.tabIdChanges
      .pipe(
        distinctUntilChanged(),
        tap(tabId => {
          if (!tabId) return;

          if (tabId === this.tabId) {
            xCenterScrollTo(this.elementRef.nativeElement);
          }
        }),
      )
      .subscribe();
  }
}
