import { Recommendation } from '@bend/store-shared';

import { PopMenu, PopProduct } from '../product-catalog';

export const isTemporarilyUnavailable: (product: PopProduct | PopMenu | Recommendation) => boolean = ({
  snoozeBeginsAt,
  snoozeEndsAt,
}) => {
  if (!snoozeBeginsAt && !snoozeEndsAt) return false;

  const current = new Date().getTime();
  const begin = new Date(snoozeBeginsAt).getTime();
  const end = new Date(snoozeEndsAt).getTime();

  return begin < current && end > current;
};
