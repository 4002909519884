import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { featureName } from './widget.config';
import { WidgetEffects } from './widget.effects';
import { reducer } from './widget.reducer';
import { WidgetHttpService } from './widget-http.service';

@NgModule({
  imports: [StoreModule.forFeature(featureName, reducer), EffectsModule.forFeature([WidgetEffects])],
  providers: [WidgetHttpService],
})
export class DesignerWidgetStoreModule {}
