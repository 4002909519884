export type Icon = {
  [key: string]: {
    viewBox: string;
    symbol: string;
  };
};

export type Icons =
  | 'pin'
  | 'pencil'
  | 'delete'
  | 'visa'
  | 'mastercard'
  | 'americanexpress'
  | 'user'
  | 'phoneNumber'
  | 'email'
  | 'creditCard'
  | 'checkCircle'
  | 'circleMinus'
  | 'circlePlus'
  | 'circleX'
  | 'promoCode'
  | 'x'
  | 'lock'
  | 'slim-arrow-right'
  | 'arrow-up'
  | 'burger-menu'
  | 'close-icon'
  | 'duplicate'
  | 'check-mark'
  | 'spoon-loading'
  | 'item-selected'
  | 'item-unselected'
  | 'info-icon'
  | 'preparing'
  | 'error'
  | 'check'
  | 'search'
  | 'back'
  | 'backV2'
  | 'me'
  | 'cart'
  | 'dialogError'
  | 'dialogInfo'
  | 'dialogSuccess'
  | 'dialogProgress'
  | 'dialogProgressCutlery'
  | 'dialogBg'
  | 'trash'
  | 'waiter'
  | 'phone'
  | 'googlePay'
  | 'applePay'
  | 'restaurantTickets'
  | 'home'
  | 'empty'
  | 'smile'
  | 'qrCode'
  | 'menuUser'
  | 'menuUserFill'
  | 'edit'
  | 'remoteOrder'
  | 'onlineOrder'
  | 'add'
  | 'userHeader'
  | 'logoutHeader'
  | 'arrowHeader'
  | 'changePasswordApps'
  | 'changePasswordMain'
  | 'logoutMain'
  | 'dragDrop'
  | 'message'
  | 'arrowLeft'
  | 'smileEmoji'
  | 'winkEmoji'
  | 'success'
  | 'dashboardPages'
  | 'dashboardLinks'
  | 'dashboardSettings'
  | 'dashboardCatalogs'
  | 'dashboardOrders'
  | 'dashboardLocations'
  | 'dashboardKitchens'
  | 'dashboardTags'
  | 'dashboardTaxes'
  | 'dashboardCarousels'
  | 'dashboardUsers'
  | 'dashboardTeam'
  | 'dashboardResults'
  | 'dashboardPromoCode'
  | 'dashboardSmile'
  | 'dashboardInsight'
  | 'dashboardArrow'
  | 'boardArrowRight'
  | 'scrollIndicator'
  | 'addedSquare'
  | 'amex'
  | 'warning'
  | 'languageCheck'
  | 'languageError'
  | 'smileinLogo';
