import { Injectable } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiAppService {
  constructor(
    private _params: RouterService,
    private _env: EnvService,
  ) {}

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/active-admins
   */
  activeAdmins(version: Version): Observable<string> {
    return this._api(version, '/active-admins');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/admins
   */
  admins(version: Version, id: string = null): Observable<string> {
    return id ? this._adminsApi(version).pipe(this._patch(`/${id}`)) : this._adminsApi(version);
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/export-catalog
   */
  exportCatalog(version: Version): Observable<string> {
    return this._apiApp(version, '/export-catalog');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/widget-store/:type
   */
  widgetStoreType(version: Version, type: string): Observable<string> {
    return this.widgetStore(version).pipe(this._patch(`/${type}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/widget-store
   */
  widgetStore(version: Version): Observable<string> {
    return this._apiApp(version, '/widget-store');
  }

  /**
   * @returns https://api.smilein.io/designer/api/v?/:userType/:appSlug/links
   */
  links(version: Version): Observable<string> {
    return this._apiApp(version, '/links');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/reports/url/:reportId
   */
  report(version: Version, reportId: string): Observable<string> {
    return this.reports(version).pipe(this._patch(`/url/${reportId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/reports
   */
  reports(version: Version): Observable<string> {
    return this._apiApp(version, '/reports');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/reports/:reportId
   */
  adminReport(version: Version, reportId: string): Observable<string> {
    return this.adminReports(version).pipe(this._patch(`/${reportId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/reports
   */
  adminReports(version: Version): Observable<string> {
    return this._api(version, '/reports');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages/duplicate/:pageId
   */
  duplicatePage(version: Version, pageId: string): Observable<string> {
    return this._apiApp(version, `/pages/duplicate/${pageId}`);
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages/:pageId/widgets/:widgetId
   */
  pageWidget(version: Version, pageId: string, widgetId: string): Observable<string> {
    return this.pageWidgets(version, pageId).pipe(map(api => `${api}/${widgetId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages/:pageId/:widgets/order
   */
  widgetsOrder(version: Version, pageId: string): Observable<string> {
    return this.pageWidgets(version, pageId).pipe(map(api => `${api}/order`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages/:pageId/widgets
   */
  pageWidgets(version: Version, pageId: string): Observable<string> {
    return this.page(version, pageId).pipe(map(api => `${api}/widgets`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages/:pageId/catalogs-with-categories
   */
  catalogsByPageId(version: Version, pageId: string): Observable<string> {
    return this.page(version, pageId).pipe(map(api => `${api}/catalogs-with-categories`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages/:pageId
   */
  page(version: Version, pageId: string): Observable<string> {
    return this.pages(version).pipe(this._patch(`/${pageId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/pages
   */
  pages(version: Version): Observable<string> {
    return this._apiApp(version, '/pages');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/hashify
   */
  hashify(version: Version): Observable<string> {
    return this._apiApp(version, '/hashify');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/analytics/pages/:pageId/:widgetId/poll/json
   * */
  pollResultsInRange(version: Version, pageId: string, widgetId: string): Observable<string> {
    return this.analyticsPage(version, pageId).pipe(this._patch(`/${widgetId}/poll/json`));
  }

  exportPollResults(version: Version, pageId: string, widgetId: string): Observable<string> {
    return this.analyticsPage(version, pageId).pipe(this._patch(`/${widgetId}/poll/csv`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/analytics/pages/:pageId
   */
  analyticsPage(version: Version, pageId: string): Observable<string> {
    return this.analyticsPages(version).pipe(this._patch(`/${pageId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/analytics/pages
   */
  analyticsPages(version: Version): Observable<string> {
    return this._apiApp(version, '/analytics/pages');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/login
   */
  loginDesigner(version: Version): Observable<string> {
    return this._api(version, '/login');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/logout
   */
  logout(version: Version): Observable<string> {
    return this._api(version, '/logout');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/change-password
   */
  changePassword(version: Version): Observable<string> {
    return this._api(version, '/change-password');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/refresh-login-token
   */
  refreshToken(version: Version): Observable<string> {
    return this._api(version, '/refresh-login-token');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/app-types
   */
  appTypes(version: Version): Observable<string> {
    return this._api(version, '/app-types');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/appSlug
   */
  application(version: Version): Observable<string> {
    return this._apiApp(version, '');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps
   */
  apps(version: Version): Observable<string> {
    return this._api(version, '/apps');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/userType/:appSlug/me/resend-code
   */
  meResendCode(version: Version): Observable<string> {
    return this.me(version).pipe(this._patch('/resend-code'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/userType/:appSlug/me/confirm
   */
  meConfirmPhone(version: Version): Observable<string> {
    return this.me(version).pipe(this._patch('/confirm'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/userType/:appSlug/me
   */
  me(version: Version): Observable<string> {
    return this._apiApp(version, '/me');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/gdpr
   */
  gdpr(version: Version): Observable<string> {
    return this._apiApp(version, '/gdpr');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/settings
   */
  settings(version: Version): Observable<string> {
    return this._apiApp(version, '/settings');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/upload
   */
  upload(version: Version): Observable<string> {
    return this._apiApp(version, '/upload');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps/carousels/:carouselId
   */
  carousel(version: Version, carouselId: string): Observable<string> {
    return this.carousels(version).pipe(this._patch(`/${carouselId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/carousels
   */
  carousels(version: Version): Observable<string> {
    return this._apiApp(version, '/carousels');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps/login/pos
   */
  loginPos(version: Version): Observable<string> {
    return this.login(version).pipe(this._patch('/pos'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps/login/wifi
   */
  loginWifi(version: Version): Observable<string> {
    return this.login(version).pipe(this._patch('/wifi'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps/login/gps
   */
  loginGps(version: Version): Observable<string> {
    return this.login(version).pipe(this._patch('/gps'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps/login
   */
  login(version: Version): Observable<string> {
    return this._apiApp(version, '/login');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps/manifest/
   */
  manifest(version: Version): Observable<string> {
    return this._apiApp(version, '/manifest');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/appSlug
   */
  user(version: Version): Observable<string> {
    return this._apiApp(version, '');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/document-viewer/:widgetId
   */
  documentViewer(version: Version, widgetId: string): Observable<string> {
    return this._interactive(version).pipe(this._patch(`/document-viewer/${widgetId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages/pageId/action-call
   */
  actionCall(version: Version, pageId: string): Observable<string> {
    return this._page(version, pageId).pipe(this._patch('/action-call'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages/pageId/feedback
   */
  feedback(version: Version, pageId: string): Observable<string> {
    return this._page(version, pageId).pipe(this._patch('/feedback'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages/pageId/questions
   */
  questions(version: Version, pageId: string): Observable<string> {
    return this._page(version, pageId).pipe(this._patch('/questions'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages/pageId/contact
   */
  contact(version: Version, pageId: string): Observable<string> {
    return this._page(version, pageId).pipe(this._patch('/contact'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages/pageId/contact-info
   */
  contactInfo(version: Version, pageId: string): Observable<string> {
    return this._page(version, pageId).pipe(this._patch('/contact-info'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/review/reviewId/my
   */
  myReview(version: Version, reviewId: string): Observable<string> {
    return this.review(version, reviewId).pipe(this._patch('/my'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/review/reviewId
   */
  review(version: Version, reviewId: string): Observable<string> {
    return this._interactive(version).pipe(this._patch(`/review/${reviewId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/poll/pollId/has-voted
   */
  hasVote(version: Version, pollId: string): Observable<string> {
    return this._vote(version, pollId).pipe(this._patch('/has-voted'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/poll/pollId/vote
   */
  vote(version: Version, pollId: string): Observable<string> {
    return this._vote(version, pollId).pipe(this._patch('/vote'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/versions
   */
  versions(version: Version): Observable<string> {
    return this._api(version, '/versions');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/sign-in/confirm
   */
  confirmPhone(version: Version): Observable<string> {
    return this.signIn(version).pipe(this._patch('/confirm'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/sign-in/resend-code
   */
  resendCode(version: Version): Observable<string> {
    return this.signIn(version).pipe(this._patch('/resend-code'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/sign-in
   */
  signIn(version: Version): Observable<string> {
    return this._apiApp(version, '/sign-in');
  }

  token(version: Version): Observable<string> {
    return this._token(version);
  }

  revokeToken(version: Version, tokenId: string): Observable<string> {
    return this._token(version).pipe(this._patch(`/${tokenId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/poll/:pollId
   */
  private _vote(version: Version, pollId: string): Observable<string> {
    return this._interactive(version).pipe(this._patch(`/poll/${pollId}`));
  }

  private _token(version: Version): Observable<string> {
    return this._apiApp(version, '/api-client').pipe(this._patch('/tokens'));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages/:pageId
   */
  private _page(version: Version, pageId: string): Observable<string> {
    return this._interactive(version).pipe(this._patch(`/pages/${pageId}`));
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/interactive/pages
   */
  private _interactive(version: Version): Observable<string> {
    return this._apiApp(version, '/interactive');
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/:appSlug/patch
   */
  private _apiApp(version: Version, patch: string): Observable<string> {
    return this._params.appSlug.pipe(
      switchMap(appSlug => this._api(version, `/${appSlug}`)),
      this._patch(patch),
    );
  }

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/patch
   */
  private _api(version: Version, patch: string): Observable<string> {
    return of(`${this._env.apiHost}/designer/api/${version}/${this._env.userType}${patch}`);
  }

  private _adminsApi(version: Version): Observable<string> {
    return of(`${this._env.apiHost}/designer/api/${version}/admins`);
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
