import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-deletion',
  templateUrl: 'confirm-deletion.component.html',
  styleUrls: ['./confirm-deletion.component.scss'],
})
export class ConfirmDeletionComponent {
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { message: string }) {
    this.message = data?.message || 'STUDIO.SHARED.CONFIRM_QUESTION';
  }
}
