import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';

import { PopWidget } from '../../types';
import { WidgetHelperService } from '../../widget-helper.service';
import { PageHostDirective } from '../page-host.directive';

@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss'],
})
export class PageEditComponent implements OnInit {
  @Input() widget: PopWidget;
  @Input() pageId: string;
  @Input() factories: any[];
  @ViewChild(PageHostDirective, { static: true }) appPageHost: PageHostDirective;

  errorMessage: string;

  constructor(private _widgetHelper: WidgetHelperService) {}

  ngOnInit(): void {
    this.loadWidget(this.widget);
  }

  loadWidget(widget: PopWidget): void {
    const viewContainerRef = this.appPageHost.viewContainerRef;
    viewContainerRef.clear();

    const factoryClass: Type<unknown> = this._widgetHelper.findFactoryClass(this.factories, widget);
    if (factoryClass) {
      const componentRef = viewContainerRef.createComponent(factoryClass);
      const attributes = { ...widget.attributes };

      if (attributes) {
        if (widget.class === 'interactive') {
          attributes.pageId = this.pageId;
          attributes.id = widget._id;
        }

        attributes.isEditMode = true;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (componentRef.instance as any).attributes = attributes;
    }
  }
}
