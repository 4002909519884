import { Injectable } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiConfService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api.smilein.io/designer/api/:version/:userType/apps
   */
  appsBatch(version: Version): Observable<string> {
    return this.confWithoutAppSlug(version).pipe(this._patch(`/applications-batch`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/applications
   */
  appSettings(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/applications`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/business-locations/:locationId
   */
  locationSettings(version: Version, locationId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/business-locations/${locationId}`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/areas/:areaId
   */
  areaSettings(version: Version, areaId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/areas/${areaId}`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/business-locations
   */
  locations(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/business-locations`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/areas
   */
  allAreas(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/areas`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/pos/:settingsId
   */
  posSetting(version: Version, settingsId: number): Observable<string> {
    return this.posSettings(version).pipe(this._patch(`/${settingsId}`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/pos
   */
  posSettings(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/pos`));
  }

  /**
   * @returns  https://api.smilein.fr/conf/:version/admin/:appSlug/pos/ikentoo/connect/:posSettingId
   */
  ikentooConnect(version: Version, posSettingId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/pos/ikentoo/connect/${posSettingId}`));
  }

  /**
   * @returns  https://api.smilein.fr/conf/:version/admin/:appSlug/pos/:posSettingId/connect
   */
  posConnect(version: Version, posSettingId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/pos/${posSettingId}/connect`));
  }

  /**
   * @returns  https://api.smilein.fr/conf/:version/admin/:appSlug/payment/:paymentId/connect
   */
  paymentConnect(version: Version, paymentId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/payment/${paymentId}/connect`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/pos/:posSettingsId/locations
   */
  posLocations(version: Version, posSettingsId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/pos/${posSettingsId}/locations`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/pos/:posSettingsId/locations
   */
  posPaymentMethods(version: Version, posSettingsId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/pos/${posSettingsId}/payment-methods`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/sms
   */
  sms(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/sms`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/payment
   */
  paymentSettings(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/payment`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/payment/:settingsId
   */
  paymentSetting(version: Version, settingsId: number): Observable<string> {
    return this.paymentSettings(version).pipe(this._patch(`/${settingsId}`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/ip
   */
  currentIp(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/ip`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/promo-codes
   */
  promoCodes(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/promo-codes`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/promo-codes/:code
   */
  promoCode(version: Version, promoCodeId: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/promo-codes/${promoCodeId}`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/promo-codes-light
   */
  promoCodesLight(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/promo-codes-light`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/applications/labels
   */
  applicationLabels(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch(`/applications/labels`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/business-locations/:id/labels
   */
  locationLabels(version: Version, id: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/business-locations/${id}/labels`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/areas/:id/labels
   */
  areaLabels(version: Version, id: number): Observable<string> {
    return this.conf(version).pipe(this._patch(`/areas/${id}/labels`));
  }

  /**
   * @returns https://api.smilein.fr/conf/:version/:userType/:appSlug/labels
   */
  labels(version: Version): Observable<string> {
    return this.conf(version).pipe(this._patch('/labels'));
  }

  /**
   * @returns https://api.smilein.fr/conf/api/:version/:userType/:appSlug/patch
   */
  conf(version: Version): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/conf/${version}/${this._env.userType}/${appSlug}`),
    );
  }

  /**
   * @returns https://api.smilein.fr/conf/api/:version/:userType/patch
   */
  confWithoutAppSlug(version: Version): Observable<string> {
    return of(`${this._env.apiHost}/conf/${version}/${this._env.userType}`);
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
