<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row-groups">
      <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
      <designer-image-gallery formControlName="imageUrl"></designer-image-gallery>
    </div>
    <div class="form-row">
      <label for="altInput" class="form-label">{{ 'WIDGETS.SHARED.ALT' | translate }}</label>
      <input
        id="altInput"
        type="text"
        placeholder="Alternative text for image"
        formControlName="alt"
        class="form-input form-input--alt"
      />
    </div>
    <div class="form-row">
      <label for="contextInput" class="form-label">{{ 'WIDGETS.CONTACT.CONTEXT' | translate }}</label>
      <input
        id="contextInput"
        type="text"
        placeholder="Context"
        formControlName="context"
        class="form-input form-input--alt"
      />
    </div>
    <span class="form-label section-label">{{ 'WIDGETS.SHARED.LABELS' | translate }}</span>
    <div class="form-row-cols-wrapper settings-row" formGroupName="labels">
      <div class="form-col-line" *ngFor="let label of labels">
        <label for="{{ label.key }}" class="form-label form-label--labels">
          {{ labelsNames[label.key] | translate }}
        </label>
        <input
          id="{{ label.key }}"
          formControlName="{{ label.key }}"
          type="text"
          class="form-input form-input--labels"
        />
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
