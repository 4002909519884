import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LanguageButtonModule } from '../language-button/language-button.module';
import { LanguagesButtonsListComponent } from './languages-buttons-list.component';

@NgModule({
  declarations: [LanguagesButtonsListComponent],
  imports: [CommonModule, LanguageButtonModule],
  exports: [LanguagesButtonsListComponent],
})
export class LanguagesButtonsListModule {}
