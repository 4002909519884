import { PosMode, PosType } from '@designer-fsd-shared/types/pos';

import { CollectType, PaymentMethods, PlaceType } from '../types';

export type PosSettings =
  | IkentooPosSettings
  | CashpadPosSettings
  | GenericPosSettings
  | DeliverectPosSettings
  | HubrisePosSettings
  | EurodataPosSettings
  | ZeltyPosSettings
  | MicrosPosSettings
  | MicrosV2PosSettings;

export interface PosGeneric {
  mode: PosMode;
  settings: PosInnerSettings;
  type: PosType;
  id: number;
  name: string;
  externalRef: string;
  internalRef?: string;
}

export interface IkentooPosSettings extends PosGeneric {
  accountProfileCodeMap: PosAccountProfileCodeMap;
  accountProfileForHappyHoursPrice: string;
  closeOnNoCheck: boolean;
  closeOnNoCheckDelaySec: number;
  maxTimeToAttemptOrderDeliverToPos: number;
  paymentMethod: string;
  staffId: string;
  syncForceCreateInAction: boolean;
  taskTtlInMs: number;
  waitForExtraNotification: number;
  paymentMethods: PosPaymentsMethodsSettings;
  token: boolean;
  businessLocationId: string;
  catalogSyncTimestamps: string[];
}

export interface CashpadPosSettings extends PosGeneric {
  paymentMethods: PosPaymentsMethodsSettings;
  checkStocks: boolean;
  apiHost: string;
  apiUserEmail: string;
  apiUserToken: string;
  idRestaurant: string;
  catalogSyncTimestamps: string[];
  pricing?: {
    onSite?: string | null;
    takeAway?: string | null;
    happyHours?: string | null;
  };
}

export type GenericPosSettings = PosGeneric;

export type DeliverectPosSettings = PosGeneric;

export interface HubrisePosSettings extends PosGeneric {
  locationId: string;
  token: string;
  accountProfileCodeMap: PosAccountProfileCodeMap;
  paymentMethod: string;
}

export interface EurodataPosSettings extends PosGeneric {
  token: string;
  clientSecret: string;
  catalogSyncTimestamps: string[];
}

export interface ZeltyPosSettings extends PosGeneric {
  token: string;
  restaurantId: number;
  transactionMethodId: number;
  clientSecret: string;
  printReceiptForAddedItems: boolean;
}

export interface MicrosPosSettings extends PosGeneric {
  entrypoint: string;
  revenueCenter: number;
  employeeObjectNum: number;
  accountProfileCodeMap: PosAccountProfileCodeMap;
  tmedObjectNumForPayment: number;
  tmedObjectNumForNoPayment: number;
  catalogSyncTimestamps: string[];
}

export interface MicrosV2PosSettings extends PosGeneric {
  entrypoint: string;
  revenueCenter: number;
  employeeObjectNum: number;
  accountProfileCodeMap: PosAccountProfileCodeMap;
  tmedObjectNumForPayment: number;
  tmedObjectNumForNoPayment: number;
  openidHost: string;
  clientId: string;
  username: string;
  organizationName: string;
  password: string;
  apiHost: string;
  location: string;
  pricing?: {
    onSite?: string | null;
    takeAway?: string | null;
    happyHours?: string | null;
  };
}

export interface PosInnerSettings {
  displayOrderIdPrefix: string;
  sendDisplayOrderId: boolean;
  sendOrders: boolean;
  sendPayments: boolean;
  syncOnCreateOrder: boolean;
  treatsCloseNotificationAsReady: boolean;
  closeOrderOnTableMove: boolean;
  orderPreparationTimeInMin: number;
  deliveryFeeSku: string;
  commentSku: string;
  maxNrOfAttemptsAction: number;
  actionCallAfterCashPaymentMessage: string;
  sendActionCallAfterCashPayment: boolean;
  ignorePosCloseNotification: boolean;
  ignorePosCloseOrder: boolean;
  syncOrders: boolean;
  syncPayments: boolean;
  sendDirectlyToKitchen: boolean;
  sendDirectlyToKitchenCashPayment: boolean;
  sendProductionOrderMenu: boolean;
  sendProductionOrderProduct: boolean;
  enablePreCheck: boolean;
  sendName: boolean;
  sendPhone: boolean;
  sendEmail: boolean;
  customerNameDefault: string;
  syncOrderDelay: number;
  sendPlaceSkuInCustomerName: boolean;
}

export interface PosPaymentsMethodsSettings {
  enabled: boolean;
  methods: PosPaymentsMethods;
}

export interface PosPaymentsMethods {
  [PaymentMethods.ApplePay]: string;
  [PaymentMethods.Cash]: string;
  [PaymentMethods.CreditCard]: string;
  [PaymentMethods.GooglePay]: string;
  [PaymentMethods.RestaurantTicket]: string;
  [PaymentMethods.Swile]: string;
  [PaymentMethods.UserCredit]: string;
}

export interface PosAccountProfileCodeMap {
  [PlaceType.OnSite]: OnSiteAccountProfileCodeMap;
  [PlaceType.Remote]: RemoteAccountProfileCodeMap;
}

export interface OnSiteAccountProfileCodeMap {
  [CollectType.EatIn]: string;
  [CollectType.TakeAway]: string;
}

export interface RemoteAccountProfileCodeMap {
  [CollectType.Delivery]: string;
  [CollectType.TakeAway]: string;
}

export interface PosLocation {
  id: string;
  name: string;
}

export interface PosPaymentMethod {
  id: string;
  name: string;
}
