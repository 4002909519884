import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmPopinContainerComponent } from '../components';

export class ConfirmPopinBuilder {
  private icon: string;
  private title: string;
  private subTitle: string;
  private cancelLabel: string;
  private confirmLabel: string;

  constructor(private readonly dialog: MatDialog) {}

  public setIcon(icon: string): ConfirmPopinBuilder {
    this.icon = icon;
    return this;
  }

  public setTitle(title: string): ConfirmPopinBuilder {
    this.title = title;
    return this;
  }

  public setSubTitle(subTitle: string): ConfirmPopinBuilder {
    this.subTitle = subTitle;
    return this;
  }

  public setCancelLabel(cancelLabel: string): ConfirmPopinBuilder {
    this.cancelLabel = cancelLabel;
    return this;
  }

  public setConfirmLabel(confirmLabel: string): ConfirmPopinBuilder {
    this.confirmLabel = confirmLabel;
    return this;
  }

  public build(): MatDialogRef<ConfirmPopinContainerComponent> {
    return this.dialog.open(ConfirmPopinContainerComponent, {
      panelClass: 'mat-dialog-no-padding',
      data: {
        icon: this.icon,
        title: this.title,
        subTitle: this.subTitle,
        cancelLabel: this.cancelLabel,
        confirmLabel: this.confirmLabel,
      },
    });
  }
}
