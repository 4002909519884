import { ChangeDetectionStrategy, Component, Input, OnInit, TrackByFunction } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetGallery, WidgetImage } from './gallery';

@Component({
  selector: 'pop-widget-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetGalleryComponent implements OnInit, WidgetComponent {
  static widgetName = 'gallery';

  @Input() attributes: WidgetGallery;

  protected isValid: boolean;
  protected noValidMessage: string;

  protected trackByFn: TrackByFunction<WidgetImage> = (_, image) => image.url;

  constructor(private readonly warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.images || !this.attributes.images.length) {
      this.noValidMessage = this.warningService.showWarn(WidgetGalleryComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this.isValid = true;
  }
}
