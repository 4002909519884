import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';

import { EnvModule } from '@bend/env';
import { IconModule } from '@bend/icon';
import { OrderStoreModule } from '@bend/order';
import { ScriptLoaderModule } from '@bend/script-loader';
import { CatalogReloadService, OrderTypeService, TypeService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { CatalogServicesModule } from '@bend/shared-widgets/src/lib/shared/catalog-services';
import { SharedComponentsModule } from '@bend/shared-widgets/src/lib/shared/shared-components';
import { WorkingHoursService } from '@bend/shared-widgets/src/lib/shared/working-hours';
import { StorageModule } from '@bend/storage';
import { AuthStoreModule } from '@bend/store';
import { CategoryStoreModule } from '@bend/store';
import { PagesStoreModule } from '@bend/store';
import { RecommendationGroupStoreModule } from '@bend/store';
import { SettingsStoreModule } from '@bend/store';
import { WorkingHoursStoreModule } from '@bend/store';
import { TranslateModule } from '@bend/translate';
import { ViewportService } from '@bend/viewport';
import { MapModule } from '@bend/widgets-new/map';
import { QrCodeModule } from '@bend/widgets-new/qr-code';

import { WidgetActionBarComponent } from './action-bar/action-bar.component';
import { WidgetActionCallComponent } from './action-call/action-call.component';
import { WidgetActionCallService } from './action-call/action-call.service';
import { WidgetActionCallHelperService } from './action-call/action-call-helper.service';
import { WidgetActionCallDialogComponent } from './action-call/dialog/dialog.component';
import { WidgetAskQuestionComponent } from './ask-question/ask-question.component';
import { WidgetAskQuestionService } from './ask-question/ask-question.service';
import { WidgetQuestionDialogComponent } from './ask-question/question-dialog/question-dialog.component';
import { WidgetButtonsComponent } from './buttons/buttons.component';
import { WidgetCarouselComponent } from './carousel/carousel.component';
import { WidgetCarouselService } from './carousel/carousel.service';
import { WidgetContactComponent } from './contact/contact.component';
import { WidgetContactService } from './contact/contact.service';
import { WidgetContactDialogComponent } from './contact/dialog/dialog.component';
import { WidgetContactInfoComponent } from './contact-info';
import { ContactInfoService } from './contact-info/contact-info.service';
import { WidgetCustomComponent } from './custom/custom.component';
import { WidgetDocumentViewerDialogComponent } from './document-viewer/dialog/document-viewer-dialog.component';
import { WidgetDocumentViewerComponent } from './document-viewer/document-viewer.component';
import { WidgetDocumentViewerService } from './document-viewer/document-viewer.service';
import { WidgetDocumentsComponent } from './documents/documents.component';
import { WidgetFeedbackComponent } from './feedback/feedback.component';
import { WidgetFeedbackService } from './feedback/feedback.service';
import { WidgetFeedbackRatingComponent } from './feedback/feedback-rating/feedback-rating.component';
import { WidgetFeedbackReviewsComponent } from './feedback/feedback-reviews/feedback-reviews.component';
import { WidgetFeedbackUploadImageComponent } from './feedback/feedback-upload-image/feedback-upload-image.component';
import { WidgetFollowComponent } from './follow/follow.component';
import { WidgetGalleryComponent } from './gallery/gallery.component';
import { WidgetImageComponent } from './image/image.component';
import { LinkService } from './link.service';
import { WidgetLinkComponent } from './link/link.component';
import { LoyaltyShareComponent, LoyaltyShareService } from './loyalty-share';
import { WidgetPollComponent } from './poll/poll.component';
import { WidgetPollService } from './poll/poll.service';
import { FacebookPostComponent } from './post/components';
import { WidgetPostComponent } from './post/post.component';
import { MenuService } from './product-catalog/menu/services/menu.service';
import { WidgetProductCatalogComponent } from './product-catalog/product-catalog.component';
import { WidgetProductCatalogModule } from './product-catalog/product-catalog.module';
import { WidgetProductCatalogService } from './product-catalog/product-catalog.service';
import { WidgetProductCatalogHelperService } from './product-catalog/product-catalog-helper.service';
import { WidgetProductCatalogLayoutService } from './product-catalog/product-catalog-layout.service';
import { WidgetProductTeaserComponent } from './product-teaser/product-teaser.component';
import { WidgetProfileComponent } from './profile/profile.component';
import { WidgetReviewComponent } from './review/review.component';
import { WidgetReviewService } from './review/review.service';
import { SessionService } from './session.service';
import { WidgetShareComponent } from './share/share.component';
import { CustomInputsModule } from './shared/custom-inputs';
import { ErrorHandlerService } from './shared/error-handler/error-handler.service';
import { PositivePricePipe } from './shared/pipes/positive-price.pipe';
import { Interpolate } from './shared/services/interpolate.service';
import { PwaService } from './shared/services/pwa.service';
import { StyleService } from './shared/services/style.service';
import { SharedModule } from './shared/shared.module';
import { WidgetShopTeaserComponent } from './shop-teaser/shop-teaser.component';
import { TextService } from './text/services';
import { WidgetTextComponent } from './text/text.component';
import { WidgetTitleComponent } from './title/title.component';
import { TranslateHelperService } from './translate-helper.service';
import { WidgetTweetComponent } from './tweet/tweet.component';
import { WidgetTwitterHtagComponent } from './twitter-htag/twitter-htag.component';
import { WidgetTwitterProfileComponent } from './twitter-profile/twitter-profile.component';
import { WidgetVideoComponent } from './video/video.component';
import { WarningService } from './warning.service';
import { WidgetWelcomeComponent } from './welcome/welcome.component';
import { WidgetWifiComponent } from './wifi/wifi.component';

export const widgets = [
  WidgetTitleComponent,
  WidgetTextComponent,
  WidgetFollowComponent,
  WidgetLinkComponent,
  WidgetWifiComponent,
  WidgetActionBarComponent,
  WidgetProfileComponent,
  WidgetShareComponent,
  WidgetTwitterHtagComponent,
  WidgetTwitterProfileComponent,
  WidgetAskQuestionComponent,
  WidgetImageComponent,
  WidgetVideoComponent,
  WidgetReviewComponent,
  WidgetDocumentsComponent,
  WidgetGalleryComponent,
  WidgetPollComponent,
  WidgetContactComponent,
  WidgetVideoComponent,
  WidgetPostComponent,
  WidgetTweetComponent,
  WidgetButtonsComponent,
  WidgetWelcomeComponent,
  WidgetCarouselComponent,
  WidgetActionCallComponent,
  WidgetCustomComponent,
  WidgetShopTeaserComponent,
  WidgetFeedbackComponent,
  WidgetShopTeaserComponent,
  WidgetProductCatalogComponent,
  WidgetProductTeaserComponent,
  WidgetDocumentViewerComponent,
  WidgetContactInfoComponent,
  LoyaltyShareComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatSelectModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    CustomInputsModule,
    WidgetProductCatalogModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    StorageModule,
    CategoryStoreModule,
    TranslateModule,
    EnvModule,
    AuthStoreModule,
    SettingsStoreModule,
    PagesStoreModule,
    OrderStoreModule,
    QrCodeModule,
    CatalogServicesModule,
    ScriptLoaderModule,
    SharedComponentsModule,
    RecommendationGroupStoreModule,
    WorkingHoursStoreModule,
    MapModule,
    MatRadioModule,
    MatIconModule,
  ],
  declarations: [
    ...widgets,
    Interpolate,
    PositivePricePipe,
    FacebookPostComponent,
    WidgetContactDialogComponent,
    WidgetActionCallDialogComponent,
    WidgetDocumentViewerDialogComponent,
    WidgetQuestionDialogComponent,
    WidgetFeedbackRatingComponent,
    WidgetFeedbackReviewsComponent,
    WidgetFeedbackUploadImageComponent,
  ],
  exports: [...widgets, PositivePricePipe, SharedModule],
  providers: [
    WidgetAskQuestionService,
    WidgetContactService,
    WidgetReviewService,
    WidgetPollService,
    WidgetCarouselService,
    WidgetActionCallService,
    WidgetActionCallHelperService,
    WidgetFeedbackService,
    WarningService,
    LinkService,
    WidgetProductCatalogService,
    WidgetProductCatalogHelperService,
    WidgetProductCatalogLayoutService,
    SessionService,
    WidgetDocumentViewerService,
    ErrorHandlerService,
    TranslateHelperService,
    StyleService,
    PwaService,
    PositivePricePipe,
    Interpolate,
    MenuService,
    ContactInfoService,
    OrderTypeService,
    WorkingHoursService,
    ViewportService,
    LoyaltyShareService,
    TextService,
    TypeService,
    CatalogReloadService,
  ],
})
export class WidgetModule {
  static widgetComponents = {
    WidgetWifiComponent,
    WidgetWelcomeComponent,
    WidgetVideoComponent,
    WidgetTwitterProfileComponent,
    WidgetTwitterHtagComponent,
    WidgetTweetComponent,
    WidgetTitleComponent,
    WidgetTextComponent,
    WidgetShopTeaserComponent,
    WidgetShareComponent,
    WidgetReviewComponent,
    WidgetProfileComponent,
    WidgetProductTeaserComponent,
    WidgetProductCatalogComponent,
    WidgetPostComponent,
    WidgetPollComponent,
    WidgetLinkComponent,
    WidgetImageComponent,
    WidgetGalleryComponent,
    WidgetFollowComponent,
    WidgetFeedbackComponent,
    WidgetDocumentsComponent,
    WidgetDocumentViewerComponent,
    WidgetCustomComponent,
    WidgetContactComponent,
    WidgetCarouselComponent,
    WidgetButtonsComponent,
    WidgetAskQuestionComponent,
    WidgetActionCallComponent,
    WidgetActionBarComponent,
    // WidgetMapComponent,
    WidgetContactInfoComponent,
    LoyaltyShareComponent,
  };
}
