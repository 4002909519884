import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';

@Component({
  selector: 'pop-widget-tweet',
  templateUrl: './tweet.component.html',
  styleUrls: ['./tweet.component.scss'],
})
export class WidgetTweetComponent implements OnInit, AfterViewInit, WidgetComponent {
  static widgetName = 'twitter_tweet';
  @Input() attributes: any;

  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.url) {
      this.noValidMessage = this._warningService.showWarn(WidgetTweetComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    (window as any).twttr = (function (d: Document, s: string, id: string): string {
      let js: any;
      const fjs = d.getElementsByTagName(s)[0];
      const t = (window as any).twttr || {};
      if (d.getElementById(id)) {
        return t;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any): void {
        t._e.push(f);
      };

      return t;
    })(document, 'script', 'twitter-wjs');

    this.isValid = true;
  }

  ngAfterViewInit(): void {
    if (this.isValid && (window as any).twttr.ready()) {
      (window as any).twttr.widgets.load();
    }
  }
}
