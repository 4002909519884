import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { featureName } from './widget.config';
import { WidgetModel } from './widget.models';
import { widgetAdapter } from './widget.reducer';
import { WidgetState } from './widget.state';

const selectPageState = createFeatureSelector<WidgetState>(featureName);

const { selectAll, selectEntities } = widgetAdapter.getSelectors();

export const selectWidgets = createSelector(selectPageState, selectAll);
export const selectWidgetsEntities = createSelector(selectPageState, selectEntities);
export const selectWidget = (id: string): MemoizedSelector<object, WidgetModel, DefaultProjectorFn<WidgetModel>> =>
  createSelector(selectWidgetsEntities, entities => entities[id]);

export const selectIsLoadingWidgetsList = createSelector(selectPageState, state => state.isLoadingWidgetsList);
export const selectIsDuplicatingWidgetById = createSelector(selectPageState, state => state.isDuplicatingWidgetById);
export const selectIsPublishingWidgetById = createSelector(selectPageState, state => state.isPublishingWidgetById);
export const selectIsDeletingWidgetById = createSelector(selectPageState, state => state.isDeletingWidgetById);
