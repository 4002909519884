import { createAction, props } from '@ngrx/store';

import { createType, MenuCategory } from '../shared';
import { storeKey } from './menu-category.config';

const type = createType(storeKey);

export const actions = {
  addMenuCategories: createAction(type('add menu categories'), props<{ entities: MenuCategory[] }>()),
};
