import { OrderItemApi } from './order-item.type';

export interface OrderLocation {
  id: number;
  name: string;
  interpolateData: {
    scheduledTo?: string;
  };
  orderUserId: number;
  orderId: number;
}

export interface OrderLocationApi extends Omit<OrderLocation, 'orderUserIds'> {
  orderItems: OrderItemApi[];
}
