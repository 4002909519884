import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { IconModule } from '@bend/icon';
import { SharedModule } from '@bend/widgets-editor';

import { StrokedButtonModule } from '@designer-modules/buttons/components';
import { ExpandNavbarItemModule } from '@designer-modules/expand-navbar-item/expand-navbar-item.module';
import { LanguageSelectModule } from '@designer-modules/language-select/language-select.module';

import {
  ArrowToggleComponent,
  ButtonBackComponent,
  ButtonComponent,
  ButtonRectangleComponent,
  ChecboxWrapperRowComponent,
  GroupHeaderComponent,
  HeaderComponent,
  InputLabelComponent,
  InputLabelSmallComponent,
  InputWrapperCollComponent,
  InputWrapperNumberComponent,
  LabelLangConfirmationComponent,
  LanguageSelectorComponent,
  SpinnerComponent,
  TabsComponent,
  TemplateTooltipComponent,
  TemplateTooltipDirective,
  TextComponent,
  TooltipInfoComponent,
} from './components';

@NgModule({
  declarations: [
    ArrowToggleComponent,
    HeaderComponent,
    ButtonComponent,
    SpinnerComponent,
    TemplateTooltipComponent,
    TemplateTooltipDirective,
    GroupHeaderComponent,
    InputLabelComponent,
    TextComponent,
    InputLabelSmallComponent,
    TabsComponent,
    LanguageSelectorComponent,
    LabelLangConfirmationComponent,
    ButtonRectangleComponent,
    ButtonBackComponent,
    InputWrapperCollComponent,
    InputWrapperNumberComponent,
    ChecboxWrapperRowComponent,
    TooltipInfoComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatTooltipModule,
    SharedModule,
    IconModule,
    ExpandNavbarItemModule,
    LanguageSelectModule,
    TranslateModule.forChild(),
    StrokedButtonModule,
  ],
  exports: [
    HeaderComponent,
    ButtonComponent,
    SpinnerComponent,
    TemplateTooltipComponent,
    TemplateTooltipDirective,
    GroupHeaderComponent,
    ArrowToggleComponent,
    InputLabelComponent,
    TextComponent,
    InputLabelSmallComponent,
    TabsComponent,
    LanguageSelectorComponent,
    ButtonRectangleComponent,
    ButtonBackComponent,
    InputWrapperCollComponent,
    InputWrapperNumberComponent,
    ChecboxWrapperRowComponent,
    TooltipInfoComponent,
  ],
})
export class SharedComponentsModule {}
