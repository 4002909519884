import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class CanLoginGuard {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {}

  canActivate(): boolean {
    if (this._authService.isLoggedIn()) {
      this._router.navigate([this._authService.redirectUrl]);

      return false;
    }

    return true;
  }
}
