import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ParamsService } from '@bend/store';
import { ApiVisitorService } from '@bend/store-shared';

import {
  PopCartVisitor,
  PopMenu,
  PopMenuCategory,
  PopOrderItemRequestData,
  PopProduct,
  PopProductCategory,
  PopProductSubcategoryResponse,
} from './product-catalog';

@Injectable()
export class WidgetProductCatalogService {
  private _replay: number;
  private _storeCategory: { [key: string]: { value: PopProductCategory[]; replay: number } };
  private _storeSubcategory: { [key: string]: { value: PopProductSubcategoryResponse; replay: number } };

  constructor(private _http: HttpClient, private _api: ApiVisitorService, private _params: ParamsService) {
    this._replay = 5;
    this._storeCategory = {};
    this._storeSubcategory = {};
  }

  resetCatalogStore(): void {
    this._storeSubcategory = {};
    this._storeCategory = {};
  }

  getProductCategories(catalogId: string): Observable<PopProductCategory[]> {
    const store = this._storeCategory[catalogId];
    if (store && store.replay) {
      store.replay -= 1;
      return of(store.value);
    }

    return this._api.parentItemCategories('v1').pipe(
      switchMap(api => this._http.get<PopProductCategory[]>(api, { params: { catalogId } })),
      tap(value => (this._storeCategory[catalogId] = { value, replay: this._replay })),
    );
  }

  getProductSubcategories(parentId: string): Observable<PopProductSubcategoryResponse> {
    const store = this._storeSubcategory[parentId];
    if (store && store.replay) {
      store.replay -= 1;
      return of(store.value);
    }

    return this._api.itemCategories('v1').pipe(
      withLatestFrom(this._params.queryParams(['place'])),
      switchMap(([api, place]) => this._http.get<PopProductSubcategoryResponse>(api, { params: { parentId, place } })),
      tap(value => (this._storeSubcategory[parentId] = { value, replay: this._replay })),
    );
  }

  getProduct(productId: string): Observable<PopProduct> {
    return this._api.item('v1', productId).pipe(switchMap(api => this._http.get<PopProduct>(api)));
  }

  getMenus(catalogId: string): Observable<PopMenu[]> {
    return this._api.menuItems('v1').pipe(switchMap(api => this._http.get<PopMenu[]>(api, { params: { catalogId } })));
  }

  getMenuCategories(categoryId: string): Observable<PopMenuCategory[]> {
    return this._api.menuItem('v1', categoryId).pipe(
      switchMap(api => this._http.get<PopMenu>(api)),
      map(({ menuCategories }) => menuCategories),
    );
  }

  addItemToCart(orderItem: PopOrderItemRequestData): Observable<void> {
    return this._api.orderItems('v1').pipe(switchMap(api => this._http.post<void>(api, orderItem)));
  }

  getMe(): Observable<PopCartVisitor> {
    return this._api.me('v1').pipe(
      switchMap(api => this._http.get<Omit<PopCartVisitor, 'id'> & { _id: string }>(api)),
      map(({ _id, ...rest }) => ({ id: _id, ...rest })),
    );
  }

  setMyName(name: string): Observable<PopCartVisitor> {
    return this._api.me('v1').pipe(switchMap(api => this._http.patch<PopCartVisitor>(api, { name })));
  }

  createVisitor(name: string): Observable<PopCartVisitor> {
    return this._api.user('v1').pipe(
      switchMap(api => this._http.post<Omit<PopCartVisitor, 'id'> & { _id: string }>(api, { name })),
      map(({ _id: id, ...rest }) => ({ id, ...rest })),
    );
  }
}
