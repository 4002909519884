import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload/file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { ActionBarWidget } from './action-bar';

@Component({
  selector: 'pop-widget-edit-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditActionBarComponent extends WidgetBaseComponent<ActionBarWidget> implements OnInit {
  static widgetName = 'action_bar';
  @Input() appHost: string;
  finalUrl: string;
  file: FileUploadDetails;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private widgetFormService: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, widgetFormService, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    this.onChangeUrl();
  }

  onChangeUrl(): void {
    this.finalUrl = WidgetHelper.generateFinalUrl(this.form.get('url').value, this.appHost);
  }

  get email(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  private _initForm(): void {
    this.widgetFormService.initAttributes(
      this.formBuilder.group({
        url: [this.widget.attributes.url],
        email: [this.widget.attributes.email],
        flyer: [this.widget.attributes.flyer, Validators.pattern(/^https?:\/\/.+/)],
      }),
    );

    this.form = this.widgetFormService.getAttributes as UntypedFormGroup;
  }
}
