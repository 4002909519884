import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { OrderService } from '@bend/store/src/lib/order';
import { State } from '@bend/store/src/lib/order';
import { OrderItemsService } from '@bend/store/src/lib/order-items';
import { OrderLocationsService } from '@bend/store/src/lib/order-locations';
import { OrderUsersService } from '@bend/store/src/lib/order-users';
import { OrderItem, OrderItemStatus, OrderLocation, OrderUser } from '@bend/store-shared';

import { PriorityStatus } from '../../../../types';
import { OrderStatusServices } from '../order-status/order-status.service';

interface Context {
  status: PriorityStatus;
  order: State;
  users: OrderUser[];
  locations: OrderLocation[];
  items: OrderItem[];
  frontTime: string;
}

@Injectable()
export class OrderDialogSentryService {
  private _previous: Context;
  private _next: Context;

  private _statusTimeOut: Map<OrderItemStatus, number>;
  private _timeOutReference: NodeJS.Timeout;

  constructor(
    private _orderStatus: OrderStatusServices,
    private _order: OrderService,
    private _orderUsers: OrderUsersService,
    private _orderLocations: OrderLocationsService,
    private _orderItems: OrderItemsService,
  ) {
    this._statusTimeOut = new Map([
      [OrderItemStatus.PaymentInProgress, 2e4],
      [OrderItemStatus.OrderedInProgress, 3e4],
    ]);
  }

  changeStatus(status: OrderItemStatus): void {
    this._state.subscribe(current => {
      this._previous = current;
      this._next = undefined;

      this._timeOut(status);
    });
  }

  private _timeOut(status: OrderItemStatus): void {
    clearTimeout(this._timeOutReference);

    if (!this._statusTimeOut.has(status)) return;

    this._timeOutReference = setTimeout(
      () =>
        this._state.subscribe(next => {
          this._next = next;
        }),
      this._statusTimeOut.get(status),
    );
  }

  private get _state(): Observable<Context> {
    return zip(
      this._orderStatus.orderStatusChanged,
      this._order.all,
      this._orderUsers.all,
      this._orderLocations.all,
      this._orderItems.all,
      this._frontTime,
    ).pipe(
      first(),
      map(([status, order, users, locations, items, frontTime]) => ({
        status,
        order,
        users,
        locations,
        items,
        frontTime,
      })),
    );
  }

  private get _frontTime(): Observable<string> {
    return of(new Date().toLocaleString());
  }
}
