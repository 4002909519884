<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form *ngIf="isLoaded" [formGroup]="form" class="widget-edit-form product-catalog-form">
    <fieldset class="form-fieldset">
      <div class="form-fieldset-groups">
        <div class="form-fieldset-groups__caption">{{ 'WIDGETS.PRODUCT.CATALOGS' | translate }}</div>
        <div class="form-fieldset-groups__content form-fieldset-groups__content--no-pb" formArrayName="catalogs">
          <div *ngFor="let catalog of catalogs; index as i" class="form-fieldset-group">
            <pop-widget-edit-product-catalog-v2-form
              [catalog]="catalog"
              [form]="$any(catalogsFormArray.controls[i])"
              [catalogChoices]="catalogChoices"
              [categoriesGroupedByCatalog]="categoriesGroupedByCatalog"
              [appSlug]="appSlug"
              [showErrors]="showErrors"
              (deleted)="onCatalogDeleted(i)"
            ></pop-widget-edit-product-catalog-v2-form>
          </div>
          <button type="button" class="btn-flat btn-flat--add-new-item" (click)="addNewCatalog()">
            {{ 'WIDGETS.PRODUCT.NEW_CATALOG' | translate }}
          </button>
        </div>
      </div>
    </fieldset>
    <fieldset class="form-fieldset">
      <div class="form-row-group form-row-group--distanced">
        <label class="form-label form-label--radio-btns-group">
          {{ 'WIDGETS.PRODUCT.VIEW_MENU_ONLY' | translate }}:
        </label>
        <div class="form-radio-btns-group">
          <input
            id="isReadOnlyEnabled"
            type="radio"
            formControlName="isReadOnly"
            [value]="true"
            class="form-input-radio form-input-radio--group"
          />
          <label for="isReadOnlyEnabled" class="form-label form-label--radio form-label--no-font-weight">
            {{ 'WIDGETS.PRODUCT.YES' | translate }}
          </label>
          <input
            id="isReadOnlyDisabled"
            type="radio"
            formControlName="isReadOnly"
            [value]="false"
            class="form-input-radio form-input-radio--group"
          />
          <label for="isReadOnlyDisabled" class="form-label form-label--radio form-label--no-font-weight">
            {{ 'WIDGETS.PRODUCT.NO' | translate }}
          </label>
          <input
            id="isReadOnlyInherited"
            type="radio"
            formControlName="isReadOnly"
            class="form-input-radio form-input-radio--group"
            [value]="null"
          />
          <label for="isReadOnlyInherited" class="form-label form-label--radio form-label--no-font-weight">
            {{ 'WIDGETS.PRODUCT.SAME_AS_AREA' | translate }}
          </label>
        </div>
      </div>
      <div class="form-row-groups form-row-groups--distanced">
        <div class="form-row-group form-row-group--distanced">
          <input id="showKitchenComment" type="checkbox" formControlName="showKitchenComment" />
          <label for="showKitchenComment" class="form-label form-label--checkbox">
            {{ 'WIDGETS.PRODUCT.DISPLAY_COMMENT' | translate }}
          </label>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <input id="isCommentToKitchenRequired" type="checkbox" formControlName="isCommentToKitchenRequired" />
          <label for="isCommentToKitchenRequired" class="form-label form-label--checkbox">
            {{ 'WIDGETS.PRODUCT.COMMENT_REQUIRED' | translate }}
          </label>
        </div>
      </div>
      <div class="form-row-group form-row-group--distanced">
        <div class="form-row-group form-row-group--distanced">
          <input id="forceShowProductDescription" type="checkbox" formControlName="forceShowProductDescription" />
          <label for="forceShowProductDescription" class="form-label form-label--checkbox">
            {{ 'WIDGETS.PRODUCT.DISPLAY_DESCRIPTION' | translate }}
          </label>
        </div>
      </div>
      <div class="form_row">
        <label for="returnUrl" class="form-label">{{ 'WIDGETS.PRODUCT.RETURN_URL' | translate }}</label>
        <input
          id="returnUrl"
          class="form-input form-input--url"
          type="url"
          placeholder="URL"
          formControlName="returnUrl"
          (keyup)="onChangeLink()"
        />
        <pop-widget-edit-page-selector
          *ngIf="pages"
          (pageChangeEmmiter)="onPageChange($event)"
          [selectedPageId]="link.value"
          [pages]="pages"
        ></pop-widget-edit-page-selector>
        <div class="final-url">
          <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
          <span class="final-url__value">{{ finalUrl }}</span>
        </div>
        <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
      </div>
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div formGroupName="margin" class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</pop-widget-edit-widget>
