import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Version } from '../types';
import { ApiConfService } from './intern-api-conf.service';
import { ApiCreditService } from './intern-api-credit.service';
import { ApiAppService } from './intern-api-designer.service';
import { ApiShopService } from './intern-api-shop.service';

@Injectable({ providedIn: 'root' })
export class ApiVisitorService {
  // app apis
  actionCall = (version: Version, pageId: string): Observable<string> => this._apiApp.actionCall(version, pageId);
  carousel = (version: Version, carouselId: string): Observable<string> => this._apiApp.carousel(version, carouselId);
  contact = (version: Version, pageId: string): Observable<string> => this._apiApp.contact(version, pageId);
  documentViewer = (version: Version, widgetId: string): Observable<string> =>
    this._apiApp.documentViewer(version, widgetId);

  feedback = (version: Version, pageId: string): Observable<string> => this._apiApp.feedback(version, pageId);
  gdpr = (version: Version): Observable<string> => this._apiApp.gdpr(version);
  hasVote = (version: Version, pollId: string): Observable<string> => this._apiApp.hasVote(version, pollId);
  login = (version: Version): Observable<string> => this._apiApp.login(version);
  loginPos = (version: Version): Observable<string> => this._apiApp.loginPos(version);
  loginWifi = (version: Version): Observable<string> => this._apiApp.loginWifi(version);
  loginGps = (version: Version): Observable<string> => this._apiApp.loginGps(version);
  manifest = (version: Version): Observable<string> => this._apiApp.manifest(version);
  me = (version: Version): Observable<string> => this._apiApp.me(version);
  meResendCode = (version: Version): Observable<string> => this._apiApp.meResendCode(version);
  meConfirmPhone = (version: Version): Observable<string> => this._apiApp.meConfirmPhone(version);
  myReview = (version: Version, reviewId: string): Observable<string> => this._apiApp.myReview(version, reviewId);
  page = (version: Version, pageId: string): Observable<string> => this._apiApp.page(version, pageId);
  questions = (version: Version, pageId: string): Observable<string> => this._apiApp.questions(version, pageId);
  review = (version: Version, reviewId: string): Observable<string> => this._apiApp.review(version, reviewId);
  settings = (version: Version): Observable<string> => this._apiApp.settings(version);
  upload = (version: Version): Observable<string> => this._apiApp.upload(version);
  user = (version: Version): Observable<string> => this._apiApp.user(version);
  vote = (version: Version, pollId: string): Observable<string> => this._apiApp.vote(version, pollId);
  signIn = (version: Version): Observable<string> => this._apiApp.signIn(version);
  confirmPhone = (version: Version): Observable<string> => this._apiApp.confirmPhone(version);
  resendCode = (version: Version): Observable<string> => this._apiApp.resendCode(version);
  contactInfo = (version: Version, pageId: string): Observable<string> => this._apiApp.contactInfo(version, pageId);

  // shop apis
  address = (version: Version): Observable<string> => this._apiShop.addresses(version);
  item = (version: Version, itemId: string): Observable<string> => this._apiShop.item(version, itemId);
  itemCategories = (version: Version): Observable<string> => this._apiShop.itemCategories(version);
  menuItem = (version: Version, item: string): Observable<string> => this._apiShop.menuItem(version, item);
  menuItems = (version: Version): Observable<string> => this._apiShop.menuItems(version);
  catalogMenus = (version: Version): Observable<string> => this._apiShop.catalogMenus(version);
  catalogMenuCategories = (version: Version, menuId: number): Observable<string> =>
    this._apiShop.catalogMenuCategories(version, menuId);

  orderCapacity = (version: Version): Observable<string> => this._apiShop.orderCapacity(version);
  orderDetails = (version: Version): Observable<string> => this._apiShop.details(version);
  orderWorkingHours = (version: Version): Observable<string> => this._apiShop.orderWorkingHours(version);
  orderItem = (version: Version, item: number): Observable<string> => this._apiShop.orderItem(version, item);
  orderItems = (version: Version): Observable<string> => this._apiShop.orderItems(version);
  orderItemsCommand = (version: Version): Observable<string> => this._apiShop.orderItemsCommand(version);
  orderLastUpdated = (version: Version, orderId: number): Observable<string> =>
    this._apiShop.orderLastUpdated(version, orderId);

  orderReopenUserSession = (version: Version): Observable<string> => this._apiShop.orderReopenUserSession(version);
  orderUsersDetails = (version: Version): Observable<string> => this._apiShop.userDetails(version);
  orderAddress = (version: Version): Observable<string> => this._apiShop.address(version);
  ordersHistory = (version: Version): Observable<string> => this._apiShop.history(version);
  orders = (version: Version): Observable<string> => this._apiShop.orders(version);
  parentItemCategories = (version: Version): Observable<string> => this._apiShop.parentItemCategories(version);
  payment = (version: Version): Observable<string> => this._apiShop.payment(version);
  paymentCash = (version: Version): Observable<string> => this._apiShop.paymentCash(version);
  paymentMethod = (version: Version, methodId: string): Observable<string> =>
    this._apiShop.paymentMethod(version, methodId);

  paymentMethods = (version: Version): Observable<string> => this._apiShop.paymentMethods(version);
  stripeResponse = (version: Version): Observable<string> => this._apiShop.stripeResponse(version);
  paygreenResponse = (version: Version): Observable<string> => this._apiShop.paygreenResponse(version);
  tpaResponse = (version: Version): Observable<string> => this._apiShop.tpaResponse(version);
  promoCode = (version: Version): Observable<string> => this._apiShop.promoCode(version);
  tips = (version: Version): Observable<string> => this._apiShop.tips(version);
  workingHours = (version: Version): Observable<string> => this._apiShop.workingHours(version);
  sendToKitchen = (version: Version): Observable<string> => this._apiShop.sendToKitchen(version);
  placeBySlug = (version: Version, placeSlug: string): Observable<string> =>
    this._apiShop.placeBySlug(version, placeSlug);

  generateLink = (version: Version): Observable<string> => this._apiShop.generateLink(version);
  sponsorEligibility = (version: Version): Observable<string> => this._apiShop.sponsorEligibility(version);
  invitedEligibility = (version: Version, code: string): Observable<string> =>
    this._apiShop.invitedEligibility(version, code);

  checkHasOrderToday = (version: Version): Observable<string> => this._apiShop.checkHasOrderToday(version);
  itemRecommendations = (version: Version, itemId: number): Observable<string> =>
    this._apiShop.itemRecommendations(version, itemId);

  recommendationGroupsByPlace = (version: Version) => this._apiShop.recommendationGroupsByPlace(version);
  optionGroups = (version: Version): Observable<string> => this._apiShop.optionGroups(version);

  // credit apis
  credit = (version: Version): Observable<string> => this._apiCredit.credit(version);
  history = (version: Version): Observable<string> => this._apiCredit.history(version);

  // conf apis

  labels = (version: Version): Observable<string> => this._apiConf.labels(version);

  constructor(
    private _apiApp: ApiAppService,
    private _apiShop: ApiShopService,
    private _apiCredit: ApiCreditService,
    private readonly _apiConf: ApiConfService,
  ) {}
}
