import { createAction, props } from '@ngrx/store';

import { createType, SettingsApp } from '../shared';
import { storeKey } from './settings.config';

const type = createType(storeKey);
class Actions {
  getAppSettings = createAction(type('get settings'), props<{ by: string }>());
  getAppSettingsIsLoaded = createAction(type('get settings is loaded'));
  getAppSettingsSuccess = createAction(
    type('get settings success'),
    props<{ settings: SettingsApp & { id: string }; by: string }>(),
  );

  getAppSettingsError = createAction(type('get settings error'), props<{ error: string }>());

  patchAppSettings = createAction(type('patch settings'), props<{ by: string; settings: Partial<SettingsApp> }>());
  patchAppSettingsSuccess = createAction(
    type('patch settings success'),
    props<{ settings: SettingsApp & { id: string }; by: string }>(),
  );

  patchAppSettingsError = createAction(type('patch settings error'), props<{ error: string }>());

  deleteApplication = createAction(type('delete application'));
  deleteApplicationSuccess = createAction(type('delete application success'), props<{ appSlug: string }>());
  deleteApplicationError = createAction(type('delete application error'), props<{ error: string }>());
}

export const actions = new Actions();
