import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { Colors } from '@bend/store-shared';

import { Countries, Country } from '../phone-number/countries.config';

@Component({
  selector: 'pop-widget-countries-dialog-number',
  templateUrl: './countries-dialog.component.html',
  styleUrls: ['./countries-dialog.component.scss'],
})
export class CountriesDialogComponent implements OnInit {
  countries$: Observable<Country[]>;
  color$: Observable<Colors>;
  searchControl: UntypedFormControl;

  constructor(
    public dialogRef: MatDialogRef<CountriesDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _settingsService: SettingsService,
  ) {
    this.searchControl = this._fb.control('');
    this.color$ = this._settingsService.colors.all;
  }

  ngOnInit(): void {
    this.countries$ = this.searchControl.valueChanges.pipe(
      map(searchedName => this._searchCountry(searchedName)),
      startWith(Countries),
      map(countries => countries.map(({ code, ...rest }) => ({ code: code.toLowerCase(), ...rest }))),
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSelectCountry(country: Country): void {
    this.dialogRef.close(country);
  }

  private _getNameWithoutSpace(string: string): string {
    return string.toLowerCase().replace(/^\s*/, '');
  }

  private _searchCountry(searchedName: string): Country[] {
    return Countries.filter(({ name }) => name.toLowerCase().includes(this._getNameWithoutSpace(searchedName)));
  }
}
