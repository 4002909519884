import { AddressType, FeeStrategy } from './settings.type';

export interface Delivery {
  defaultCountry: string;
  freeAmountForDeliveryByZip: number;
  feeCalculationStrategy: FeeStrategy;
  zipMeta: ZipMeta;
  orderPriceMeta: OrderPriceMeta;
  addressType: AddressType;
}

export interface DeliverySettings extends Delivery {
  tva: {
    name: string;
    value: number;
  };
}

export interface ZipMeta {
  [key: string]: {
    fee: number;
  };
}

export interface OrderPriceMeta {
  [key: string]: {
    fee: number;
  };
}
