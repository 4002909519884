<label class="wrapper">
  <div class="row">
    <div class="title">
      <app-input-label class="title__first">
        <ng-content select="[title]"></ng-content>
      </app-input-label>
      <app-input-label-small>
        <ng-content select="[subtitle]"></ng-content>
      </app-input-label-small>
    </div>

    <div class="number">
      <ng-content select="[input]"></ng-content>
    </div>

    <app-input-label class="currency">
      <ng-content select="[currency]"></ng-content>
    </app-input-label>
  </div>

  <div class="description">
    <ng-content select="[description]"></ng-content>
  </div>
</label>
