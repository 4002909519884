<div class="element">
  <div
    [style.borderColor]="color$ | async"
    [class.element__border--error]="isShowError"
    [class.element__border--disabled]="isDisabled"
    class="element__border"
  ></div>
  <lib-icon *ngIf="icon" class="icon" [style.fill]="color$ | async" [icon]="icon"></lib-icon>
  <input
    #input
    [formControl]="control"
    [placeholder]="placeHolder"
    [name]="name"
    [type]="type"
    [owlDateTimeTrigger]="date"
    [owlDateTime]="date"
    readonly
    [class.element__input--disabled]="isDisabled"
    [class.element__input--have-icon]="icon"
    class="element__input"
    data-cy="elementInput"
  />
  <owl-date-time #date [pickerType]="'calendar'"></owl-date-time>
  <button *ngIf="isDisabled" (click)="enable()" class="edit">
    <lib-icon icon="pencil" [style.fill]="color$ | async" class="edit__icon"></lib-icon>
  </button>
</div>
<app-input-errors [ngControl]="ngControl"></app-input-errors>
