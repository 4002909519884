import { createAction, props } from '@ngrx/store';

import { createType } from '../shared';
import { storeKey } from './resolver.config';

const type = createType(storeKey);

class Actions {
  setLoading = createAction(type('set loading'), props<{ loading: boolean }>());
}

export const actions = new Actions();
