import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './order.config';
import { adapter } from './order.reducer';
import { State } from './order.type';

export const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, state => state);
export const total = createSelector(stateSelector, selectTotal);
export const orders = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, obj => obj.isLoading);
export const isLoaded = createSelector(stateSelector, obj => obj.isLoaded);
export const isLoadingHistory = createSelector(stateSelector, obj => obj.isLoadingHistory);
export const currentId = createSelector(stateSelector, obj => obj.currentId);
export const currentOrder = createSelector(entities, currentId, (allOrders, orderId) => allOrders[orderId]);
export const currentUpdated = createSelector(currentOrder, ({ id = null, updatedAt = null }) => ({ id, updatedAt }));
export const currentIsLoading = createSelector(currentOrder, obj => obj.isLoading);
export const currentErrorCode = createSelector(currentOrder, obj => obj.errorCode);
