import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './notifications.actions';
import { NotificationsHttpService } from './notifications.http.service';
import * as selectors from './notifications.selectors';
import { State } from './notifications.type';

@Injectable()
export class NotificationsEffects {
  constructor(
    private _actions$: Actions,
    private _notificationHttp: NotificationsHttpService,
    private _store: Store<State>,
  ) {}

  updateNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateNotification),
      withLatestFrom(this._store.pipe(select(selectors.currentAppSlug))),
      switchMap(([{ notificationId, settings }, currentAppSlug]) =>
        this._notificationHttp.updateNotification(notificationId, settings).pipe(
          map(notification => actions.updateNotificationSuccess({ notification, currentAppSlug })),
          catchError(({ code }) => of(actions.updateNotificationError({ errorCode: code }))),
        ),
      ),
    ),
  );

  getNotificationByType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getNotificationByType),
      withLatestFrom(this._store.pipe(select(selectors.ids))),
      mergeMap(([{ currentType, appSlug }, ids]) =>
        [...ids].includes(`${appSlug}/${currentType}`)
          ? of(actions.getNotificationByTypeIsLoaded({ currentType, appSlug }))
          : this._notificationHttp.notificationByType(currentType).pipe(
              map(settings => actions.getNotificationByTypeSuccess({ settings: { appSlug, ...settings } })),
              catchError(({ code }) => of(actions.getNotificationByTypeError({ errorCode: code }))),
            ),
      ),
    ),
  );

  addCustomNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addCustomNotification),
      withLatestFrom(this._store.pipe(select(selectors.currentAppSlug))),
      switchMap(([{ settings }, appSlug]) =>
        this._notificationHttp.addNotification(settings).pipe(
          map(notification => actions.addCustomNotificationSuccess({ notification, appSlug })),
          catchError(({ code }) => of(actions.addCustomNotificationError({ errorCode: code }))),
        ),
      ),
    ),
  );

  deleteCustomNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deleteCustomNotification),
      withLatestFrom(this._store.pipe(select(selectors.currentAppSlug))),
      switchMap(([{ notification }, appSlug]) =>
        this._notificationHttp.deleteNotification(notification.id).pipe(
          map(() => actions.deleteCustomNotificationSuccess({ notification, appSlug })),
          catchError(({ code }) => of(actions.deleteCustomNotificationError({ errorCode: code }))),
        ),
      ),
    ),
  );
}
