import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogGeneric } from '../../helpers';
import { DialogConfigComponent } from '../../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogErrorComponent extends DialogGeneric<DialogErrorComponent> {
  constructor(@Inject(MAT_DIALOG_DATA) data: DialogConfigComponent, dialogRef: MatDialogRef<DialogErrorComponent>) {
    super(data, dialogRef);
  }
}
