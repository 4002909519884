import { GetUsers } from './users.type';

export const storeKey = 'users';

export const byKey = ({ offset, limit, onlyPendingTickets, search }: GetUsers) => {
  let key = `${offset}-${limit}`;

  if (onlyPendingTickets) key += `-${onlyPendingTickets}`;

  if (search) key += `-${search}`;
  return key;
};
