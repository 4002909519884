import { QueryParamsHandling } from '@angular/router';

import { Icons } from '@bend/icon';

export interface NavigationLink {
  link: string;
  name: string;
  queryParamsHandling?: QueryParamsHandling;
}

export interface DashboardMenuItem {
  link: string;
  text: string;
  icon: Icons;
  separated: boolean;
}
