import { createAction, props } from '@ngrx/store';

import { createType, WorkingHoursInterval } from '../shared';
import { storeKey } from './working-hours.config';

const type = createType(storeKey);

class Actions {
  getCatalogWorkingHours = createAction(type('get'), props<{ by: number }>());
  getCatalogWorkingHoursIsLoaded = createAction(type('get - is loaded'));
  getCatalogWorkingHoursSuccess = createAction(
    type('get - success'),
    props<{ intervals: WorkingHoursInterval[]; catalogId: number }>(),
  );

  getCatalogWorkingHoursError = createAction(type('get - error'), props<{ errorCode: string }>());
}

export const actions = new Actions();
