import { Widget } from '../../shared/models';

export interface FeedbackWidget extends Widget {
  attributes: {
    showComment: boolean;
    showName: boolean;
    showPreviousFeedbacks: boolean;
    showUpload: boolean;
    showStars: boolean;
    style: {
      margin: {
        bottom: number;
        top: number;
      };
      borderRadius: number;
      showBackground: boolean;
    };
    labels: FeedbackLabels;
  };
}

export class FeedbackLabels {
  TITLE = 'Label title';
  NAME = 'Label name';
  COMMENT = 'Label comment';
  UPLOAD_PICTURE = 'Button upload';
  SUBMIT_BUTTON = 'Button submit';
  REVIEWS_TITLE = 'Label previous feedbacks';
  NAME_REQUIRED = 'Error name required';
  COMMENT_REQUIRED = 'Error comment required';
  IMAGE_REQUIRED = 'Error picture required';
  COMMENT_OR_IMAGE_REQUIRED = 'Error comment or picture required';
  UPLOAD_FAILED = 'Error upload failed';
  FEEDBACK_ADDED = 'Popup feedback added';
}

export enum FeedbackLabelsNames {
  TITLE = 'FEEDBACK.TITLE',
  NAME = 'FEEDBACK.NAME',
  COMMENT = 'FEEDBACK.COMMENT',
  UPLOAD_PICTURE = 'FEEDBACK.UPLOAD_PICTURE',
  SUBMIT_BUTTON = 'FEEDBACK.SUBMIT_BUTTON',
  REVIEWS_TITLE = 'FEEDBACK.REVIEWS_TITLE',
  NAME_REQUIRED = 'FEEDBACK.NAME_REQUIRED',
  COMMENT_REQUIRED = 'FEEDBACK.COMMENT_REQUIRED',
  IMAGE_REQUIRED = 'FEEDBACK.IMAGE_REQUIRED',
  COMMENT_OR_IMAGE_REQUIRED = 'FEEDBACK.COMMENT_OR_IMAGE_REQUIRED',
  UPLOAD_FAILED = 'FEEDBACK.UPLOAD_FAILED',
  FEEDBACK_ADDED = 'FEEDBACK.FEEDBACK_ADDED',
}

export interface FeedbackLabel {
  name: string;
  key: string;
}
