import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SettingsService } from '@bend/store';

@Component({
  selector: 'app-bottom-button',
  templateUrl: './bottom-button.component.html',
  styleUrls: ['./bottom-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomButtonComponent {
  @Input() type = 'button';
  @Input() isLoading: boolean;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter();
  fgPrimary$: Observable<string>;

  constructor(private _settings: SettingsService) {
    this.fgPrimary$ = this._settings.colors.primaryForeground;
  }

  click(): void {
    if (this.disabled || this.isLoading) return;

    this.clicked.emit();
  }
}
