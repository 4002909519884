import { createAction, props } from '@ngrx/store';

import { createType, CreditResponse, DictionaryPaymentMethods, WorkingHoursInterval } from '../shared';
import { storeKey } from './cart.config';

const type = createType(storeKey);

class Actions {
  getPaymentMethods = createAction(type('get payment methods'));
  getPaymentMethodsSuccess = createAction(
    type('get payment methods success'),
    props<{ paymentMethods: DictionaryPaymentMethods }>(),
  );

  getPaymentMethodsError = createAction(type('get payment methods error'));

  deletePaymentMethod = createAction(type('delete payment method'), props<{ id: string }>());
  deletePaymentMethodSuccess = createAction(type('delete payment method success'), props<{ id: string }>());
  deletePaymentMethodError = createAction(type('delete payment method error'));

  getCredit = createAction(type('get credit'));
  getCreditSuccess = createAction(type('get credit success'), props<CreditResponse>());
  getCreditError = createAction(type('get credit error'));

  getWorkingHours = createAction(type('get working hours'));
  getWorkingHoursSuccess = createAction(
    type('get working hours success'),
    props<{ workingHours: WorkingHoursInterval[] }>(),
  );

  getWorkingHoursError = createAction(type('get working hours error'));

  selectCredit = createAction(type('select credit'), props<{ selected: boolean }>());
}

export const actions = new Actions();
