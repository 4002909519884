import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ImageLoadingStatus, SortableImage } from '../shared/types/image-gallery.type';
import { actions } from './image-gallery.actions';
import { State } from './image-gallery.type';

export const adapter: EntityAdapter<SortableImage> = createEntityAdapter<SortableImage>({
  sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

const initialState: State = adapter.getInitialState({
  apps: null,
  imagesLoadedByApp: [],
  currentApp: null,
  defaultApp: null,
  imagesForUpload: null,
  imagesForUploadStatus: null,

  isLoading: false,
  isAppsLoaded: false,
  selectedId: null,
  errorCode: null,
});

export const imageGalleryReducer = createReducer(
  initialState,
  on(actions.getApplications, (state, { currentApp }) => ({
    ...state,
    isLoading: true,
    currentApp: currentApp,
    defaultApp: currentApp,
  })),
  on(actions.getApplicationsLoaded, state => ({
    ...state,
    isLoading: false,
  })),
  on(actions.getApplicationsSuccess, (state, { apps }) => ({
    ...state,
    isLoading: false,
    isAppsLoaded: true,
    apps: apps,
  })),
  on(actions.getImagesByAppSuccess, (state, { images }) =>
    adapter.addMany(images, {
      ...state,
      imagesLoadedByApp: [...state.imagesLoadedByApp, state.currentApp],
      isLoading: false,
    }),
  ),
  on(actions.getImagesByAppLoaded, state => ({
    ...state,
    isLoading: false,
  })),
  on(actions.changeApp, (state, { app }) => ({
    ...state,
    currentApp: app,
  })),
  on(actions.uploadImages, (state, { files }) => ({
    ...state,
    isLoading: true,
    imagesForUpload: files.map(file => ({ image: null, id: String(file.lastModified) })),
    imagesForUploadStatus: files.reduce(
      (acc, { lastModified }) => ({ ...acc, [lastModified]: ImageLoadingStatus.Pending }),
      {},
    ),
  })),
  on(actions.uploadImageSuccess, (state, { imageEvent }) =>
    adapter.addOne(imageEvent.image, {
      ...state,
      isLoading: false,
      fileToUpload: null,
      imagesForUploadStatus: { ...state.imagesForUploadStatus, [imageEvent.id]: ImageLoadingStatus.Success },
      imagesForUpload: state.imagesForUpload.map(imgToUpload =>
        imgToUpload.id === imageEvent.id ? { ...imgToUpload, image: imageEvent.image } : imgToUpload,
      ),
    }),
  ),
  on(actions.uploadImageError, (state, { errorCode }) => ({
    ...state,
    isLoading: false,
    fileToUpload: null,
    errorCode: errorCode,
  })),
  on(actions.getImagesByApp, actions.uploadImageByUrl, actions.uploadCroppedImage, state => ({
    ...state,
    isLoading: true,
  })),
  on(actions.uploadImageByUrlSuccess, (state, { image }) =>
    adapter.addOne(image, {
      ...state,
      isLoading: false,
      currentApp: image.relatedToApp,
    }),
  ),
  on(actions.deleteImageSuccess, (state, { id }) => adapter.removeOne(id, state)),
  on(actions.uploadCroppedImageSuccess, (state, { image }) =>
    adapter.addOne(image, {
      ...state,
      isLoading: false,
      currentApp: image.relatedToApp,
    }),
  ),
  on(
    actions.uploadImageByUrlError,
    actions.getApplicationsError,
    actions.getImagesByAppError,
    actions.uploadCroppedImageError,
    (state, { errorCode }) => ({
      ...state,
      isLoading: false,
      errorCode: errorCode,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return imageGalleryReducer(state, action);
}
