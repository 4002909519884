import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { OrderItemCreationMode, OrderItemStatus } from '@bend/store-shared';

import { PriorityStatus } from '../../../../types';

@Injectable()
export class OptimisticService {
  status: Observable<PriorityStatus>;

  private _optimisticStatus: Subject<PriorityStatus>;

  constructor() {
    this._optimisticStatus = new Subject();
    this.status = this._optimisticStatus.asObservable();
  }

  pay(): void {
    this._optimisticStatus.next({
      creationMode: OrderItemCreationMode.Server,
      status: OrderItemStatus.PaymentInProgress,
      updatedAt: new Date(),
    });
  }

  /**
   * @description check if new item was added to order
   */
  checkNewItem(): void {
    this._optimisticStatus.next({
      creationMode: OrderItemCreationMode.Server,
      status: OrderItemStatus.New,
      updatedAt: new Date(),
    });
  }
}
