import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './image-gallery.config';
import { adapter } from './image-gallery.reducer';
import { State } from './image-gallery.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const total = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const errorCode = createSelector(stateSelector, state => state.errorCode);
export const isAppsLoaded = createSelector(stateSelector, state => state.isAppsLoaded);
export const apps = createSelector(stateSelector, state => state.apps);
export const currentApp = createSelector(stateSelector, state => state.currentApp);
export const imagesLoadedByApp = createSelector(stateSelector, state => state.imagesLoadedByApp);
export const defaultApp = createSelector(stateSelector, state => state.defaultApp);
export const imagesForUpload = createSelector(stateSelector, state => state.imagesForUpload);

export const imagesByApp = createSelector(total, currentApp, (allEntities, app) => {
  return allEntities.filter(entity => entity.relatedToApp === app);
});

export const getImageById = (id: number) => createSelector(entities, entities => entities[id]);
