import { createReducer, on } from '@ngrx/store';

import { appsActions, AppsState } from '@designer-store/apps';

const initialAppsState: AppsState = {
  applications: null,
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const appsReducer = createReducer(
  initialAppsState,
  on(appsActions.getApps, state => ({
    ...state,
    isLoading: true,
  })),

  on(appsActions.getAppsSuccess, (state, { applications }) => ({
    ...state,
    applications,
    isLoading: false,
    isLoaded: true,
  })),
);
