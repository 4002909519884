import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class SplitBillService {
  private _selectedAmount: BehaviorSubject<number>;

  constructor() {
    this._selectedAmount = new BehaviorSubject(0);
  }

  setAmount(amount: number): void {
    this._selectedAmount.next(amount);
  }

  increaseAmount(amount: number): void {
    this._selectedAmount.next(this._selectedAmount.value + amount);
  }

  decreaseAmount(amount: number): void {
    if (!this._selectedAmount.value) return;

    this._selectedAmount.next(this._selectedAmount.value - amount);
  }

  get splitAmount(): Observable<number> {
    return this._selectedAmount.asObservable().pipe(distinctUntilChanged());
  }
}
