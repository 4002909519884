import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './settings.config';
import { adapter } from './settings.reducer';
import { State } from './settings.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);

export const selectedNextId = createSelector(stateSelector, state => state.selectedNextId);
export const selectedId = createSelector(stateSelector, state => state.selectedId);
export const settings = createSelector(entities, selectedId, (settingsEntities, id) => settingsEntities[id]);

export const error = createSelector(stateSelector, state => state.error);
export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const isLoadedBy = createSelector(stateSelector, selectedNextId, (state, id) => state.isLoadedBy.includes(id));

export const name = createSelector(settings, allSettings => allSettings.name);
export const placeName = createSelector(settings, allSettings => allSettings?.placeName);
export const placeStatus = createSelector(settings, allSettings => allSettings?.placeStatus);
export const language = createSelector(settings, allSettings => allSettings.language);
export const analytics = createSelector(settings, allSettings => allSettings.analytics);
export const gdpr = createSelector(settings, allSettings => allSettings.gdpr);
export const delivery = createSelector(settings, allSettings => allSettings.delivery);
export const takeAway = createSelector(settings, allSettings => allSettings.takeAway);
export const widgets = createSelector(settings, allSettings => allSettings.widgets);
export const phone = createSelector(settings, allSettings => allSettings.phone);
export const promoCode = createSelector(settings, allSettings => allSettings.promoCode);
export const colors = createSelector(settings, allSettings => allSettings.colors);
export const credit = createSelector(settings, allSettings => allSettings.credit);
export const currency = createSelector(settings, allSettings => allSettings.currency);
export const addresses = createSelector(settings, allSettings => allSettings.addresses);
export const workingHours = createSelector(settings, allSettings => allSettings.workingHours);
export const happyHours = createSelector(settings, allSettings => allSettings.happyHours);
export const capacityManagement = createSelector(settings, allSettings => allSettings.capacityManagement);
export const businessLocation = createSelector(settings, allSettings => allSettings.businessLocation);
export const pwaSettings = createSelector(settings, allSettings => allSettings.pwa);
export const upgradeMethods = createSelector(settings, allSettings => allSettings.pop.upgradeMethods);
export const tokenRequired = createSelector(settings, allSettings => allSettings.pop.tokenRequired);
export const signIn = createSelector(settings, allSettings => allSettings.signIn);
export const smartWifiSettings = createSelector(settings, allSettings => allSettings.sw);
export const newsletters = createSelector(settings, allSettings => allSettings.newsletters);
export const general = createSelector(settings, allSettings => allSettings.general);
export const page = createSelector(settings, allSettings => allSettings.page);
export const defaultPage = createSelector(settings, allSettings => allSettings.defaultPage);
export const printer = createSelector(settings, allSettings => allSettings.printer);
export const kioskStyles = createSelector(settings, allSettings => allSettings.kiosk);
