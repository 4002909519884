<div *ngIf="isValid; else elseBlock" class="widget-poll">
  <div *ngIf="!hasVoted; else votedTemplate">
    <mat-form-field class="email-form-field" appearance="fill" color="accent" *ngIf="showEmailField$ | async">
      <mat-label>{{ 'CONTACT.EMAIL' | translate }}</mat-label>
      <input matInput [formControl]="email" type="email" />
    </mat-form-field>
    <ng-container *ngFor="let questionObject of attributes.questions; first as isFirst">
      <h3 class="question" [class.first-question]="isFirst">
        {{ questionObject.question }}
        <ng-container *ngTemplateOutlet="requiredTemplate; context: { $implicit: questionObject }"></ng-container>
      </h3>
      <mat-radio-group class="question-radio-group" [(ngModel)]="responses[questionObject._id]">
        <mat-radio-button
          *ngFor="let response of questionObject.responses"
          [name]="questionObject._id"
          [value]="response._id"
          class="response"
          color="accent"
        >
          {{ response.text }}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <button (click)="sendPoll()" class="btn-vote" [style.borderColor]="buttonColor" [style.color]="buttonColor">
      {{ labels.VOTE || 'POLL.VOTE' | translate }}
    </button>
  </div>
  <ng-template #votedTemplate>
    <ng-container *ngIf="attributes.showResultsToUser; else hidePollResultsTemplate">
      <div *ngFor="let questionObject of attributes.questions; first as isFirst">
        <h3 class="question" [class.first-question]="isFirst">{{ questionObject.question }}</h3>
        <div *ngFor="let response of questionObject.responses" class="response">
          <div *ngIf="response.text">
            <span class="response__title">{{ response.text }}</span>
            <span [style.color]="attributes.mainColor">{{ response.count }}%</span>
            <hr
              class="percentage-line"
              [style.width.%]="response.count"
              [style.borderColor]="attributes.mainColor"
              [style.backgroundColor]="attributes.mainColor"
            />
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #hidePollResultsTemplate>
      <div class="voted-block">
        <div class="icons">
          <mat-icon class="first-icon">celebration</mat-icon>
          <mat-icon>sentiment_very_satisfied</mat-icon>
          <mat-icon>celebration</mat-icon>
        </div>
        <h3>{{ labels.MESSAGE || 'POLL.MESSAGE' | translate }}</h3>
      </div>
    </ng-template>
  </ng-template>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-poll">{{ noValidMessage }}</div>
</ng-template>

<ng-template #requiredTemplate let-question>
  <ng-container *ngIf="question.required">
    <ng-container *ngIf="!responses[question._id] && touched; else mark">
      <span [style.color]="'#f00'">*</span>
      <span class="required-mark">(Required)</span>
    </ng-container>
  </ng-container>
  <ng-template #mark>*</ng-template>
</ng-template>
