export const enum OnlineOrderError {
  ZipCodeNotAllowed = 'DELIVERY_ZIP_CODE_NOT_ALLOWED',
  OrderMinAmountNotReached = 'ORDER_MIN_AMOUNT_NOT_REACHED',
}

export const DELIVERY_ORDER_ERROR = {
  [OnlineOrderError.ZipCodeNotAllowed]: 'CART.ADDRESS.ERROR.ZIP',
  [OnlineOrderError.OrderMinAmountNotReached]: 'CART.COLLECT.DELIVERY.ERROR.AMOUNT',
};

export const TAKE_AWAY_ORDER_ERROR = {
  [OnlineOrderError.ZipCodeNotAllowed]: 'CART.ADDRESS.ERROR.ZIP',
  [OnlineOrderError.OrderMinAmountNotReached]: 'CART.COLLECT.TAKE_AWAY.ERROR.AMOUNT',
};
