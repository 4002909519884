import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { SettingsApp } from '../shared';
import { actions } from './settings.actions';
import { State } from './settings.type';

export const adapter: EntityAdapter<SettingsApp> = createEntityAdapter<SettingsApp>({
  selectId: (settings: SettingsApp) => settings.general.slug,
});

export const initialState: State = adapter.getInitialState({
  error: null,
  isLoading: false,
  isLoadedBy: [],
  selectedId: null,
});

const settingsReducer = createReducer(
  initialState,

  on(actions.getAppSettings, actions.patchAppSettings, (state, { by }) => ({
    ...state,
    isLoading: true,
    selectedId: by,
  })),

  on(actions.getAppSettingsIsLoaded, state => ({
    ...state,
    isLoading: false,
  })),

  on(actions.getAppSettingsError, actions.patchAppSettingsError, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(actions.getAppSettingsSuccess, actions.patchAppSettingsSuccess, (state, { settings, by }) =>
    adapter.upsertOne(settings, {
      ...state,
      isLoading: false,
      isLoadedBy: [...state.isLoadedBy, by],
    }),
  ),

  on(actions.deleteApplication, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.deleteApplicationSuccess, (state, { appSlug }) =>
    adapter.removeOne(appSlug, {
      ...state,
      isLoading: false,
      isLoadedBy: state.isLoadedBy.filter(slug => slug !== appSlug),
      selectedId: null,
    }),
  ),

  on(actions.deleteApplicationError, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return settingsReducer(state, action);
}
