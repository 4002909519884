import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuItem } from '../shared';
import * as selectors from './menu-item.selectors';
import { State } from './menu-item.type';

@Injectable({ providedIn: 'root' })
export class MenuItemService {
  constructor(private _store: Store<State>) {}

  menuItemByMenuCategory(menuCategoryId: number): Observable<MenuItem[]> {
    return this._store
      .select(selectors.all)
      .pipe(map(categories => categories.filter(category => category.parentId === menuCategoryId)));
  }
}
