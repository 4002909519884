import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { Price } from '../helpers/price.helper';

@Pipe({ name: 'centsToBills' })
export class CentsToBillsPipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}

  transform(value: number, currencyCode: string): string {
    const bills = isNaN(value) ? value : Price.toFloat(value);

    return this._currencyPipe.transform(bills, currencyCode.toUpperCase());
  }
}
