import { OrderItemStatus } from '@bend/store-shared';

export const enum ErrorCodes {
  NotAllItemsBelongToOrderOrStatusIsWrong = 'NOT_ALL_ITEMS_BELONG_TO_ORDER_OR_STATUS_IS_WRONG',
  PosNoStock = 'POS_NO_STOCK',
  TemporarilyUnavailable = 'TEMPORARILY_UNAVAILABLE',
  PosUnknownProducts = 'POS_UNKNOWN_PRODUCTS',
  PosRestaurantClose = 'POS_RESTAURANT_CLOSE',
  PosGenericError = 'POS_GENERIC_ERROR',
  CredentialsNotFound = 'CREDENTIALS_NOT_FOUND',
  PaymentCashIsNotAllowed = 'PAYMENT_CASH_IS_NOT_ALLOWED',
  PaymentCashNoNewItems = 'PAYMENT_CASH_NO_NEW_ITEMS',
  EpayNotEnoughDataOnCallback = 'EPAY_NOT_ENOUGH_DATA_ON_CALLBACK',
  TransactionResponseInvalidSignature = 'TRANSACTION_RESPONSE_INVALID_SIGNATURE',
  TransactionResponseWrongAccount = 'TRANSACTION_RESPONSE_WRONG_ACCOUNT',
  InvalidNotificationData = 'INVALID_NOTIFICATION_DATA',
  PlaceNotFound = 'PLACE_NOT_FOUND',
  InvalidSignatureData = 'INVALID_SIGNATURE_DATA',
  PaymentIntentIdIsMissing = 'PAYMENT_INTENT_ID_IS_MISSING',
  PaymentAmountExceededTotalAmountToPay = 'PAYMENT_AMOUNT_EXCEEDED_TOTAL_AMOUNT_TO_PAY',
  TransactionResponse_wrong_amount = 'TRANSACTION_RESPONSE_WRONG_AMOUNT',
  TransactionNotFound = 'TRANSACTION_NOT_FOUND',
  DeliveryFeeIsNotSupportedWithMultiKitchens = 'DELIVERY_FEE_IS_NOT_SUPPORTED_WITH_MULTI_KITCHENS',
  OrderInactive = 'ORDER_INACTIVE',
  CreditCardExpired = 'CREDIT_CARD_EXPIRED',

  GenericPreCheckError = 'GENERIC_PRE_CHECK_ERROR',
  GenericError = 'GENERIC_ERROR',
  OrderNotAvailable = 'ORDER_NOT_AVAILABLE',

  LoyaltyAddOnlyOnFreeItem = 'LOYALTY_CODE_ADD_ONLY_ONE_FREE_ITEM',
  LoyaltyCodeNotFound = 'LOYALTY_CODE_NOT_FOUND',
  LoyaltyCodeAlreadyUsed = 'LOYALTY_CODE_ALREADY_USED',
  LoyaltyHadOrdersAlready = 'LOYALTY_CODE_HAD_ORDERS_ALREADY',
  LoyaltyInvitedNotConnected = 'LOYALTY_CODE_INVITED_NOT_CONNECTED',
  LoyaltySamePhoneWithSponsor = 'LOYALTY_CODE_SAME_PHONE_WITH_SPONSOR',
  LoyaltyInProgressByAnotherInvited = 'LOYALTY_CODE_IN_PROGRESS_BY_ANOTHER_INVITED',
  LoyaltyRevoked = 'LOYALTY_CODE_REVOKED',
  LoyaltyNotUsableYet = 'LOYALTY_CODE_NOT_USABLE_YET',
}

export const ITEM_ERRORS = {
  [OrderItemStatus.NoStock]: 'OUT_OF_STOCK',
  [OrderItemStatus.UnknownProduct]: 'UNKNOWN_PRODUCT',
  [OrderItemStatus.TemporarilyUnavailable]: 'TEMPORARILY_UNAVAILABLE',
};
