import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetTwitterHtag } from './twitter-htag';

@Component({
  selector: 'pop-widget-twitter-htag',
  templateUrl: './twitter-htag.component.html',
  styleUrls: ['./twitter-htag.component.scss'],
})
export class WidgetTwitterHtagComponent implements OnInit, AfterViewInit, WidgetComponent {
  static widgetName = 'twitter_htag';
  @Input() attributes: WidgetTwitterHtag;

  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService) {}

  openTimeline(): void {
    const htag = document.getElementById(`htag-${this.attributes.twitterWidgetId}`);
    const htagTimeline = document.getElementById(`htag-timeline-${this.attributes.twitterWidgetId}`);

    htag.classList.toggle('active');
    htag.className = htag.classList.toString();
    htagTimeline.classList.toggle('hidden');
    htagTimeline.className = htagTimeline.classList.toString();
  }

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.htag || !this.attributes.twitterWidgetId) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetTwitterHtagComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this.isValid = true;

    (window as any).twttr = (function (d: Document, s: string, id: string): string {
      let js: any;
      const fjs = d.getElementsByTagName(s)[0];
      const t = (window as any).twttr || {};
      if (d.getElementById(id)) {
        return t;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any): void {
        t._e.push(f);
      };

      return t;
    })(document, 'script', 'twitter-wjs');
  }

  ngAfterViewInit(): void {
    if (this.isValid) {
      const twitterTimeline = document.getElementById(`twitter-timeline-${this.attributes.twitterWidgetId}`);
      twitterTimeline.setAttribute('data-widget-id', this.attributes.twitterWidgetId);
      if ((window as any).twttr.ready()) {
        (window as any).twttr.widgets.load();
      }
    }
  }
}
