import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  catchError,
  combineLatest,
  delay,
  distinctUntilChanged,
  iif,
  map,
  merge,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { appear } from '@bend/animations';
import { DialogService } from '@bend/dialog';
import { CatalogReloadService, OptimisticService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { CatalogIsReadOnlyService } from '@bend/shared-widgets/src/lib/shared/catalog-services';
import { ErrorCodes } from '@bend/shared-widgets/src/lib/types';
import { ItemUnavailabilityDisplay, SettingsService, UserSocketUpdatesService } from '@bend/store';
import { RecommendationService } from '@bend/store';
import {
  RecommendationMenuBackdropService,
  RecommendationsMenuService,
} from '@bend/widgets-old/product-catalog/services';
import { PricesService } from '@bend/widgets-old/product-catalog/services/prices.service';

import { prepareErrorForOutOfStock } from '../../shared/error-handler/error-handler.helper';
import { ErrorHandlerService } from '../../shared/error-handler/error-handler.service';
import { TranslateHelperService } from '../../translate-helper.service';
import { PopCurrency, PopSettings } from '../../widget';
import { isTemporarilyUnavailable } from '../helpers';
import {
  PopALaCarteOrderItem,
  PopCartVisitor,
  PopMenuCategory,
  PopMenuOrderItem,
  PopOptionGroup,
  PopProduct,
  PopProductDetailsDialogParams,
  PRICE_DISPLAY_MODE,
  WidgetProductCatalog,
} from '../product-catalog';
import { WidgetProductCatalogService } from '../product-catalog.service';
import { WidgetProductCatalogHelperService } from '../product-catalog-helper.service';
import { WidgetProductCatalogLayoutService } from '../product-catalog-layout.service';
import { WidgetProductCatalogSharedCartDialogComponent } from '../shared-cart-dialog/shared-cart-dialog.component';

const LABEL_PREFIX = 'PRODUCT_CATALOG';

@Component({
  selector: 'pop-product-catalog-details-dialog',
  templateUrl: './product-details-dialog.component.html',
  styleUrls: ['./product-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [appear],
})
export class WidgetProductCatalogDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  product: PopProduct;
  attributes: WidgetProductCatalog;
  errorMessages: number[];
  total: number;
  commentToKitchen: string;
  isMenu: boolean;
  currency: PopCurrency;
  quantity: number;
  priceDisplayModeEnum = PRICE_DISPLAY_MODE;
  hasFullPriceOptions: boolean;
  isLoading = false;
  isCommentToKitchenInvalid: boolean;
  isCommentToKitchenRequired: boolean;
  itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  itemUnavailabilityDisplayEnum = ItemUnavailabilityDisplay;
  showErrors: boolean;
  fullPhoto: boolean;
  optionGroupQuantityError: number[];
  isReadOnlyMenu: boolean;
  category: PopMenuCategory;
  isKitchenClosed$: Observable<boolean>;
  @ViewChild('contentWrapper') contentWrapperEl: ElementRef;
  @ViewChild('fixedEl') fixedEl: ElementRef;
  @ViewChild('fakeFooterEl') fakeFooterEl: ElementRef;

  private _subscription: Subscription = new Subscription();

  showBackdrop = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopProductDetailsDialogParams,
    public dialogRef: MatDialogRef<WidgetProductCatalogDetailsDialogComponent>,
    private _productCatalogService: WidgetProductCatalogService,
    private _helperService: WidgetProductCatalogHelperService,
    private _layoutService: WidgetProductCatalogLayoutService,
    private _matDialog: MatDialog,
    private _errorHandlerService: ErrorHandlerService,
    private _translateHelper: TranslateHelperService,
    private _dialog: DialogService,
    private _cdr: ChangeDetectorRef,
    private _optimistic: OptimisticService,
    private _catalogReload: CatalogReloadService,
    private _recommendations: RecommendationService,
    private _catalogIsReadOnly: CatalogIsReadOnlyService,
    private _userSocketUpdate: UserSocketUpdatesService,
    private _settings: SettingsService,
    private pricesService: PricesService,
    private recommendationMenuBackdropService: RecommendationMenuBackdropService,
    private recommendationsMenuService: RecommendationsMenuService,
  ) {
    this.errorMessages = [];
    this.optionGroupQuantityError = [];
    this._subscription.add(
      this.recommendationMenuBackdropService.showBackdrop$.subscribe(showBackdrop => {
        this.showBackdrop = showBackdrop;
        this._cdr.detectChanges();
      }),
    );
  }

  ngOnInit(): void {
    this.product = this.data.product;
    this.quantity = this.product.quantityMultiplier;
    this.attributes = this.data.attributes;
    this.currency = this.data.attributes.currency;
    this.isCommentToKitchenRequired = this.data.attributes.isCommentToKitchenRequired;
    this._helperService.selectDefaultOptions(this.product);
    this.total = this._helperService.calculateProductTotal(this.product, this.quantity);
    this.isMenu = this.data.isMenu;
    this._sortOptionGroups();
    this._sortSubProducts();
    this._addMissingAttributes();
    this.hasFullPriceOptions = this.product.itemOptionGroups.some(
      group => group.optionGroup.priceDisplayMode === PRICE_DISPLAY_MODE.FULL,
    );
    /**
     * show kitchen is closed label only when place is out of working hours
     */
    this.isKitchenClosed$ = combineLatest([
      this._catalogIsReadOnly.getIsClose(this.product.catalogId).pipe(distinctUntilChanged()),
      this._settings.orderForAnotherDay,
    ]).pipe(map(([catalogIsReadOnly, orderForAnotherDay]) => (orderForAnotherDay ? false : catalogIsReadOnly)));
    this.itemUnavailabilityDisplay = this.data.attributes.itemUnavailabilityDisplay;
    this._subscription.add(this._onCatalogUpdate());
    this._subscription.add(this.onSettingsUpdate());
  }

  ngAfterViewInit(): void {
    this._layoutService.setFakeFooterHeightFromFixedPositionedEl(this.fakeFooterEl, this.fixedEl);
  }

  ngOnDestroy(): void {
    this.recommendationsMenuService.reset();
    this._subscription.unsubscribe();
  }

  pick(): void {
    this.showErrors = false;
    if (this.isLoading) {
      return;
    }

    this.errorMessages = this._helperService.getAllRequiredOptionsNotSelected(this.product);
    if (this.errorMessages.length) {
      return;
    }

    if (this.product.children?.length && !this.isSelectedSubProduct()) {
      this.showErrors = true;
      return;
    }

    if (this.attributes.isCommentToKitchenRequired && !this.commentToKitchen) {
      this.isCommentToKitchenInvalid = true;
      this.showErrors = true;
      return;
    }

    this.optionGroupQuantityError = this._helperService.getOptionGroupsWithQuantityError(this.product);
    if (this.optionGroupQuantityError.length) {
      this.showErrors = true;
      return;
    }

    if (!this.attributes.allowOrderForOthers) {
      this.saveProductToCart(null);
      return;
    }
    this.showSharedCartDialog(this.product);
  }

  showSharedCartDialog(product: PopProduct): void {
    const dialogRef = this._matDialog.open(WidgetProductCatalogSharedCartDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      data: {
        product,
        attributes: this.attributes,
      },
      panelClass: 'shared-cart-dialog-overlay',
    });

    dialogRef.afterClosed().subscribe((orderUser: PopCartVisitor) => {
      if (orderUser) {
        this.saveProductToCart(orderUser);
      }
    });
  }

  saveProductToCart(orderUser: PopCartVisitor): void {
    const orderItem = this._helperService.generateALaCarteItem(
      this.product,
      this.quantity,
      this.commentToKitchen,
      orderUser,
    );
    this.isLoading = true;

    this._productCatalogService
      .addItemToCart(orderItem)
      .pipe(
        /**
         * @description start optimistic update orders
         */
        tap(() => this._optimistic.checkNewItem()),
        switchMap(() => this.addRecommendationsToCart(this.recommendationsMenuService.getAddedRecommendations)),
        catchError(error =>
          this._helperService.handleOrderClose(error).pipe(
            tap(isCreatedOrder => {
              if (isCreatedOrder) this.saveProductToCart(orderUser);
              else this._handleHttpError(error, orderItem.orderItem);
            }),
          ),
        ),
      )
      .subscribe(() => this._handleProductAddedToCart());
  }

  decreasesOptionQuantity(option: PopProduct): void {
    if (option.quantityMultiplier) {
      option.quantityMultiplier -= 1;
    }
    if (!option.quantityMultiplier) {
      option.isSelected = false;
    }
    this._updateTotal();
  }

  addOptionQuantity(option: PopProduct, optionGroup: PopOptionGroup): void {
    if (
      optionGroup.maxAllowedOptions &&
      this._helperService.selectedOptionsCount(optionGroup) >= optionGroup.maxAllowedOptions
    ) {
      return;
    }
    option.quantityMultiplier += 1;
    if (option.quantityMultiplier) {
      option.isSelected = true;
    }
    this._updateTotal();
  }

  selectOption(option: PopProduct, optionGroup: PopOptionGroup): void {
    if (!option.available || isTemporarilyUnavailable(option) || !option.available) return;

    this._helperService.selectOption(option, optionGroup);
    this._updateTotal();
  }

  selectSubProduct(subProductId: number): void {
    this._helperService.toggleSubProduct(subProductId, this.product);
    this._updateTotal();
  }

  onCommentChanged(comment: string): void {
    this.commentToKitchen = comment;
  }

  scrollToAddCommentToKitchen(scrollToPosition: number): void {
    const contentWrapperNativeEl = this.contentWrapperEl.nativeElement;
    contentWrapperNativeEl.scrollTop = scrollToPosition + (contentWrapperNativeEl.scrollTop as number);
  }

  incrementQuantity(): void {
    if (this.isLoading) {
      return;
    }

    this.quantity += this.product.quantityMultiplier;
    this._updateTotal();
  }

  decrementQuantity(): void {
    if (this.isLoading) {
      return;
    }

    if (this.quantity > this.product.quantityMultiplier) {
      this.quantity -= this.product.quantityMultiplier;
    }
    this._updateTotal();
  }

  isSelectedSubProduct(): boolean {
    return this._helperService.isSelectedSubProduct(this.product);
  }

  private _updateTotal(): void {
    this.total = this._helperService.calculateProductTotal(this.product, this.quantity);
  }

  private _handleProductAddedToCart(): void {
    this.dialogRef.close();
  }

  private _translateLabel(label: string, prefix?: string): Observable<string> {
    return this._translateHelper.translateLabel(label, prefix ? prefix : LABEL_PREFIX);
  }

  private _handleHttpError(error: HttpErrorResponse, orderItem: PopALaCarteOrderItem | PopMenuOrderItem): void {
    const isNoActiveOrderError = ['DISABLE_ADDITIONS_AFTER_ORDER_SESSION_ORDERED', 'NO_ACTIVE_ORDER_FOR_USER'].includes(
      error?.error?.message || error.message,
    );

    if (
      [
        'CI_MULTIPLE_KITCHEN_ON_NEW_ITEM',
        'BUSINESS_LOCATION_FOR_ITEM_NOT_WORKING_NOW',
        'ORDER_NOT_AVAILABLE',
        'CART_ITEMS_NOT_VISIBLE',
      ].includes(error.message)
    ) {
      const productNotVisible = error.message === 'CART_ITEMS_NOT_VISIBLE';
      const isCartError = [ErrorCodes.OrderNotAvailable as string].includes(error.message);

      this._subscription.add(
        this._translateLabel(error.message, productNotVisible || isCartError ? 'CART' : '')
          .pipe(
            switchMap(res =>
              iif(
                () => productNotVisible,
                of(res).pipe(
                  tap(() => this._dialog.info({ message: res })),
                  delay(3000),
                  tap(() => window.location.reload()),
                ),
                of(res).pipe(tap(() => this._dialog.error({ message: res }))),
              ),
            ),
          )
          .subscribe(() => this._cdr.detectChanges()),
      );
    } else if (isNoActiveOrderError) {
      this._dialog.info({ message: 'CART.ORDER_NOT_AVAILABLE' });
    } else if (error.message === 'POS_NO_STOCK') {
      this._dialog.error(prepareErrorForOutOfStock(error as any, orderItem));
      this._catalogReload.reloadCatalog();
    } else if (error.message === ErrorCodes.PosUnknownProducts) {
      this._dialog.error({ message: `CART.${ErrorCodes.PosUnknownProducts}` });
      this._catalogReload.reloadCatalog();
    } else if (error.message === ErrorCodes.TemporarilyUnavailable) {
      this._dialog.error({ message: 'PRODUCT_CATALOG.TEMPORARILY_UNAVAILABLE_PRODUCT' });
      this._catalogReload.reloadCatalog();
    } else if (error.status !== 401) {
      this._errorHandlerService.handleHttpError(error);
    }
    this.isLoading = false;
  }

  private _sortOptionGroups(): void {
    this.product.itemOptionGroups
      .sort((a, b) => a.order - b.order)
      .map(({ optionGroup: { optionGroupSubitems } }) => {
        optionGroupSubitems.sort((a, b) => a.item.order - b.item.order);
      });
  }

  private _sortSubProducts(): void {
    this.product.children.sort((a, b) => a.order - b.order);
  }

  makeFullPhoto(): void {
    this.fullPhoto = !this.fullPhoto;
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }
  }

  private onSettingsUpdate(): Subscription {
    return this._userSocketUpdate
      .settings()
      .pipe(switchMap(() => this.updateProductPricesAfterSocketUpdate()))
      .subscribe();
  }

  private _onCatalogUpdate(): Subscription {
    return this._userSocketUpdate
      .catalog()
      .pipe(switchMap(() => this.updateProductPricesAfterSocketUpdate()))
      .subscribe(() => {
        // Temporarily disabled
        // this._recommendationGroup.getRecommendations(this.data.product.id);
        this._cdr.detectChanges();
      });
  }

  private updateProductPricesAfterSocketUpdate(): Observable<void> {
    return this.pricesService.flow.pipe(
      switchMap(flow =>
        this._productCatalogService.getProduct(String(this.product.id)).pipe(
          tap(product => {
            this.product = {
              ...this.pricesService.getUpdatedProductByFlow(product, flow),
              children: product.children.map(child => this.pricesService.getUpdatedProductByFlow(child, flow)),
              itemOptionGroups: this.pricesService.updatedItemOptionGroups(product.itemOptionGroups, flow),
            };
            this.total = this._helperService.calculateProductTotal(this.product, this.product.quantityMultiplier);
            this._cdr.detectChanges();
          }),
          map(() => null),
        ),
      ),
    );
  }

  private addRecommendationsToCart(recommendations: PopProduct[]): Observable<void> {
    if (!recommendations || !recommendations.length) return of(null);

    const recommendations$ = recommendations
      .map(rec => this._helperService.generateALaCarteItem(rec, rec.quantityMultiplier, '', null))
      .map(orderItem => this._productCatalogService.addItemToCart(orderItem));

    return merge(...recommendations$).pipe(tap(() => this._optimistic.checkNewItem()));
  }
}
