import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OptionStoreModule } from '../option';
import { storeKey } from './option-group.config';
import { OptionGroupEffects } from './option-group.effects';
import { OptionGroupHttpService } from './option-group.http.service';
import { reducer } from './option-group.reducer';
import { OptionGroupService } from './option-group.service';

@NgModule({
  imports: [
    StoreModule.forFeature(storeKey, reducer),
    EffectsModule.forFeature([OptionGroupEffects]),
    OptionStoreModule,
  ],
  providers: [OptionGroupHttpService, OptionGroupService],
})
export class OptionGroupStoreModule {}
