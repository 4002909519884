import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { tapError } from '@bend/shared-widgets/src/lib/helpers';
import { emailValidator } from '@bend/widgets-new/validators/email.validator';

import { WidgetComponent } from '../../../../widgets-old/src/lib/widget.component';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { ContactInfoService } from './contact-info.service';
import { ContactInfo, ContactInfoWidget } from './types';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class WidgetContactInfoComponent implements OnInit, OnDestroy, WidgetComponent {
  static widgetName = 'contact_info';

  @Input() attributes: ContactInfoWidget;
  form: UntypedFormGroup;
  me: any = { _id: '', name: '', email: '', phone: '' };
  phoneData: { phone: string; prefix: string };
  noValidMessage: string;
  pageId: string;
  place: string;

  private _subscription: Subscription;

  constructor(
    @Optional() private _dialog: DialogService,
    private _location: Location,
    private _formBuilder: UntypedFormBuilder,
    private _contactInfoService: ContactInfoService,
    private _warningService: WarningService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
  ) {
    this.pageId = this._route.snapshot.params.pageId;
    this.place = this._route.snapshot.queryParams.place;
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this.noValidMessage = this._warningService.showWarn(
      WidgetContactInfoComponent.widgetName,
      WarnTypeEnum.NoAttributes,
    );
    if (!this.attributes.isEditMode) {
      this._getContactInfo();
    } else {
      this._initForm({ name: '', email: '', phone: '' });
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  onSubmit(): void {
    if (this.attributes.mandatoryFields && this._isFormInvalid()) {
      return;
    }

    const { name = '', email = '', phone = { phone: '', prefix: '' } } = this.form.getRawValue();
    const data = { name, email, placeSlug: this.place, phone: `${phone.prefix}${phone.phone.replace(/\s/g, '')}` };

    this._saveData(data as ContactInfo);
  }

  // TODO(Serghei) add getter to fix strict templates
  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  // TODO(Serghei) add getter to fix strict templates
  get email(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  // TODO(Serghei) add getter to fix strict templates
  get phone(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }

  private _saveData(saveData: ContactInfo): void {
    this._subscription.add(
      this._contactInfoService
        .saveContactInfo(this.pageId, [saveData])
        .pipe(
          tap(() => {
            if (this.attributes.redirectUrl) {
              this._redirect();
            } else {
              this._dialog.info({ message: this.attributes.labels.confirmationMessage });
            }
          }),
          tapError(({ message }) => this._dialog.error({ message })),
        )
        .subscribe(() => this._cdr.detectChanges()),
    );
  }

  private _redirect(): void {
    if (/http(s)?/g.test(this.attributes.redirectUrl)) {
      this._location.go(this.attributes.redirectUrl);
    } else {
      this._router.navigate(['..', this.attributes.redirectUrl], {
        relativeTo: this._route,
        queryParamsHandling: 'merge',
      });
    }
  }

  private _isFormInvalid(): boolean {
    this.form.controls.name.markAsTouched();
    this.form.controls.email.markAsTouched();
    this.form.controls.phone.markAsTouched();
    this.form.controls.phone['controls'].phone.markAsTouched();
    this.form.updateValueAndValidity();

    return this.form.invalid;
  }

  private _getContactInfo(): void {
    this._subscription.add(
      this._contactInfoService
        .getMe()
        .pipe(
          tap(me => (this.me = me)),
          tap(({ name = '', email = '', phone = '' }) => this._initForm({ name, email, phone })),
        )
        .subscribe(() => this._cdr.detectChanges()),
    );
  }

  private _initForm({ name, email, phone }: { name: string; email: string; phone: string }): void {
    this.phoneData = { phone: '', ...this.attributes.page.phone };
    this.form = this._formBuilder.group({
      name: [name, this.attributes.mandatoryFields && this.attributes.showName ? [Validators.required] : []],
      email: [
        email,
        this.attributes.mandatoryFields && this.attributes.showEmail ? [Validators.required, emailValidator] : [],
      ],
      phone: this._formBuilder.group({
        phone: [phone, this.attributes.mandatoryFields && this.attributes.showPhone ? [Validators.required] : []],
        prefix: '',
      }),
    });
  }
}
