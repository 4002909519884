<div *ngIf="isValid; else elseBlock" class="widget-contact" [class.img-wrapper]="attributes.imageUrl">
  <img
    class="contact-img"
    *ngIf="attributes.imageUrl"
    [src]="attributes.imageUrl"
    [alt]="attributes.alt"
    (click)="openDialog()"
  />
  <button
    class="contact-button"
    [class.contact-overlay-btn]="attributes.imageUrl"
    (click)="openDialog()"
    [style.backgroundColor]="buttonColor"
    [style.color]="textColor"
  >
    {{ labels.CONTACT_BTN || 'CONTACT.CONTACT_BTN' | translate }}
  </button>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-contact">{{ noValidMessage }}</div>
</ng-template>
