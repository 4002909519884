import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';

import { StylesService } from '../../../catalog-services';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent {
  isRow$: Observable<boolean>;

  constructor(private _styles: StylesService, public elementRef: ElementRef) {
    this.isRow$ = this._styles.isRowNav$;
  }
}
