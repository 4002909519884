import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './catalog.config';
import { adapter } from './catalog.reducer';
import { State } from './catalog.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);

export const selectedNextId = createSelector(stateSelector, state => state.selectedNextId);
export const selectedId = createSelector(stateSelector, state => state.selectedId);
export const catalog = createSelector(entities, selectedId, (catalogs, id) => catalogs[id]);

export const error = createSelector(stateSelector, state => state.error);
export const isLoading = createSelector(stateSelector, category => category.isLoading);
export const isLoadedBy = createSelector(stateSelector, selectedNextId, (state, id) => state.isLoadedBy.includes(id));
