import { Injectable } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';

import { Line, ParsedLine } from './type';

@Injectable()
export class ParserService {
  constructor(private _translateParser: TranslateParser) {}

  /**
   *
   * @param actions
   * @example
   *  [start][{{ name }}]
   *  [start][{{ phone }}]
   *  [
   *    [indent]
   *    [start][{{ name }} x {{ count }}]
   *    [
   *      [indent]
   *      [start][{{ name }} x {{ count }}]
   *      [unindent]
   *    ][options]
   *    [unindent]
   *  ][products]
   *
   * @param dataset
   * @example
   *  {
   *    name: 'User name',
   *    phone: '+373 xxx xxx xx',
   *    products: [
   *      {
   *        name: 'Product name',
   *        count: 1,
   *        options: [
   *          {
   *            name: 'Option name',
   *            count: 2,
   *          },
   *        ],
   *      },
   *    ],
   *  }
   * @returns
   * @example
   * [action][string]
   * [action][string][string]
   */
  parse(template: string, dataset: Record<string, unknown>): ParsedLine[] {
    /**
     * separate template in actions
     */
    const actions = template
      .split('\n')
      /**
       * remove empty all empty lines
       */
      .filter(line => !/^\s*$/.test(line));

    return this._parse(actions, dataset);
  }

  private _parse(actions: Line[], dataset: Record<string, unknown>): ParsedLine[] {
    let stack: ParsedLine[] = [];

    let arrayStack: Line[] = [];
    let arrayStackStart = 0;

    for (const action of actions) {
      /**
       * check is started new array of actions
       */
      if (/^\s*\[$/.test(action)) {
        /**
         * if the data in an array has already started to be collected
         * we will ignore the arrays inside it and collect them in the current array.
         */
        if (arrayStackStart) arrayStack.push(action);
        arrayStackStart++;
      } else if (/^\s*\]/.test(action)) {
        arrayStackStart--;

        if (!arrayStackStart) {
          const [key] = this._separate(action);
          const parsedArray = this._parseArray(
            arrayStack,
            dataset[key] as Record<string, Record<string, unknown>[]>[],
            dataset,
          );
          stack = stack.concat(parsedArray);
          arrayStack = [];
        } else {
          arrayStack.push(action);
        }
      } else {
        if (!arrayStackStart) stack.push(this._interpolate(this._separate(action), dataset));
        else arrayStack.push(action);
      }
    }

    return stack;
  }

  private _parseArray(
    actions: Line[],
    dataset: Record<string, Record<string, unknown>[]>[],
    parentDataset: Record<string, unknown>,
  ): ParsedLine[] {
    return dataset.flatMap(data => {
      return this._parse(actions, { ...parentDataset, ...data });
    });
  }

  private _interpolate([action, ...params]: ParsedLine, data: Record<string, unknown>): ParsedLine {
    return [action, ...params.map(param => this._translateParser.interpolate(param, data))];
  }

  private _separate(line: Line): ParsedLine {
    return line
      .replace(/^\s*/, '')
      .replace(/^\s*\]/, '')
      .replace(/\\\[/g, 'ɐɐ')
      .replace(/\\\]/g, 'əə')
      .match(/[^\[\]]+/g)!
      .map(str => str.replace(/ɐɐ/g, '[').replace(/əə/g, ']')) as ParsedLine;
  }
}
