import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { toggleY } from '@bend/animations';

import { InputGeneric } from '../../../../../../../shared-widgets/src/lib/helpers';

@Component({
  selector: 'app-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss'],
  animations: [toggleY],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorsComponent extends InputGeneric implements OnInit {
  @Input() ngControl?: NgControl;

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.isExistControl) {
      this.ngControl.control.statusChanges.subscribe(() => this._cdr.detectChanges());
    }
  }

  get isShowRequiredError(): boolean {
    return this.ngControl.hasError('required');
  }

  get isShowEmailError(): boolean {
    return this.ngControl.hasError('email');
  }

  get isShowPhoneError(): boolean {
    return this.ngControl.hasError('phone-invalid');
  }
}
