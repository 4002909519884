import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload/file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { DocumentsWidget } from './documents';

@Component({
  selector: 'pop-widget-edit-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditDocumentsComponent extends WidgetBaseComponent<DocumentsWidget> implements OnInit {
  static widgetName = 'documents';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    if (this.widget.attributes.documents && this.widget.attributes.documents.length) {
      for (const document of this.widget.attributes.documents) {
        const data = {
          name: document['name'],
          url: document['url'],
          type: document['type'],
          size: document['size'],
        };
        this.addDocument(data);
      }
    } else {
      this.initDocument();
    }
  }

  initDocument(value: object = {}): UntypedFormGroup {
    return this._formBuilder.group({
      name: [value['name'], Validators.required],
      url: [value['url'], Validators.required],
      type: [value['type']],
      size: [value['size']],
    });
  }

  addDocument(value: object = {}): void {
    const control = this.form.controls['documents'] as UntypedFormArray;
    control.push(this.initDocument(value));
  }

  removeDocument(index: number): void {
    const control = this.form.controls['documents'] as UntypedFormArray;
    control.removeAt(index);
  }

  onFileChange(event: FileUploadDetails, formGroup: number): void {
    const control = this.documentsControls[formGroup];
    control.get('url').setValue(event.url);
    control.get('name').setValue(event.name);
    control.get('type').setValue(event.type);
    control.get('size').setValue(event.size);
  }

  get documentsControls(): UntypedFormGroup[] {
    return (this.form.get('documents') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        documents: this._formBuilder.array([], Validators.minLength(1)),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
