import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-quantity-modifier',
  templateUrl: './quantity-modifier.component.html',
  styleUrls: ['./quantity-modifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityModifierComponent {
  @Input() quantity = 0;
  @Input() quantityMultiplier: number;
  @Output() change: EventEmitter<number>;

  constructor() {
    this.change = new EventEmitter<number>();
  }

  addItem(): void {
    this.change.emit(this.quantity + this.quantityMultiplier);
  }

  removeItem(): void {
    if (this.quantity === 0) return;

    this.change.emit(this.quantity - this.quantityMultiplier);
  }
}
