import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { iif, of } from 'rxjs';
import { catchError, map, mergeMap,switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './payment.actions';
import { PaymentHttpService } from './payment.http.service';
import * as selectors from './payment.selectors';
import { State } from './payment.type';

@Injectable()
export class PaymentEffects {
  getPaymentConfigList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPaymentConfigList),
      withLatestFrom(this._store.pipe(select(selectors.isLoaded))),
      switchMap(([{ appSlug }, isLoaded]) =>
        iif(
          () => isLoaded,
          of(actions.getPaymentConfigListIsLoaded()),
          this._paymentHttp.getPaymentConfigList().pipe(
            map(paymentConfigList => actions.getPaymentConfigListSuccess({ paymentConfigList, appSlug })),
            catchError(({ errorCode }) => of(actions.getPaymentConfigListError({ errorCode }))),
          ),
        ),
      ),
    ),
  );

  getPaymentConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPaymentConfig),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedByIds))),
      mergeMap(([{ id }, isLoaded]) =>
        iif(
          () => isLoaded,
          of(actions.getPaymentConfigIsLoaded()),
          this._paymentHttp.getPaymentConfig(id).pipe(
            map(paymentConfig => actions.getPaymentConfigSuccess({ paymentConfig })),
            catchError(({ errorCode }) => of(actions.getPaymentConfigError({ errorCode }))),
          ),
        ),
      ),
    ),
  );

  addPaymentConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addPaymentConfig),
      switchMap(({ paymentConfig }) =>
        this._paymentHttp.addPaymentConfig(paymentConfig).pipe(
          map(newPaymentConfig => actions.addPaymentConfigSuccess({ paymentConfig: newPaymentConfig })),
          catchError(({ errorCode }) => of(actions.addPaymentConfigError({ errorCode }))),
        ),
      ),
    ),
  );

  updatePaymentConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updatePaymentConfig),
      switchMap(({ paymentConfig }) =>
        this._paymentHttp.updatePaymentConfig(paymentConfig).pipe(
          map(updatedPaymentConfig => actions.updatePaymentConfigSuccess({ paymentConfig: updatedPaymentConfig })),
          catchError(({ errorCode }) => of(actions.updatePaymentConfigError({ errorCode }))),
        ),
      ),
    ),
  );

  deletePaymentConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deletePaymentConfig),
      switchMap(({ id }) =>
        this._paymentHttp.deletePaymentConfig(id).pipe(
          map(() => actions.deletePaymentConfigSuccess({ id })),
          catchError(({ errorCode }) => of(actions.deletePaymentConfigError({ errorCode }))),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _paymentHttp: PaymentHttpService, private _store: Store<State>) {}
}
