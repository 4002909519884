import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

const enum TransactionProvider {
  NO_PAYMENT = 'no_payment',
  POS = 'pos',
}
@Pipe({ name: 'transactionProvider' })
export class TransactionProviderPipe extends TranslatePipe implements PipeTransform {
  constructor(translate: TranslateService, ref: ChangeDetectorRef) {
    super(translate, ref);
  }

  transform(value: string): string {
    return super.transform(this._label(value));
  }

  private _label(key: string): string {
    switch (key) {
      case TransactionProvider.NO_PAYMENT:
        return 'STUDIO.ORDERS.STATUS.ACCEPTED';
      case TransactionProvider.POS:
        return 'STUDIO.SHARED.POS';

      default:
        return 'SmileIn';
    }
  }
}
