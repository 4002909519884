import { Option } from './option.type';

export interface OptionGroup {
  id: number;
  name: string;
  order: number;
  allowMultipleSameOptions: boolean;
  multiple: boolean;
  minAllowedOptions: number;
  maxAllowedOptions: number;
  required: boolean;
  options: Option[];
}
