import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { ParamsService } from '@bend/store';

import { PopDirectLink, PopQueryParam } from './widget';

@Injectable()
export class LinkService {
  constructor(private _router: Router, private _params: ParamsService) {}

  navigate(link: string, openInNewTab: boolean, isEditMode: boolean): void {
    if (!link) {
      return;
    }

    zip(this._params.appSlug, this._params.queryParams())
      .pipe(
        first(),
        tap(([appSlug, queryParams]) => {
          const params: PopQueryParam[] = Object.entries(queryParams).map(([key, value]) => ({ key, value }));

          const directLink = this.generateDirectLink(link, params);
          const externalRex = /https?/i;

          if (externalRex.test(directLink.link)) {
            window.open(directLink.link, openInNewTab ? '_blank' : '_top');
          } else if (!isEditMode) {
            this._router.navigate([`${appSlug}/${directLink.linkWithoutQueryParams}`], {
              queryParams: directLink.queryParams,
              queryParamsHandling: 'merge',
            });
          }
        }),
      )
      .subscribe();
  }

  generateDirectLink(url: string, queryParams: PopQueryParam[]): PopDirectLink {
    let link = url;
    const urlUppercase = url.toUpperCase();
    const templateKeys = urlUppercase.match(/<\$[^>]+>/g); // will return ['${param1}', '${param2}']
    const queryParamsUppercase: PopQueryParam[] = queryParams.map(param => ({
      key: param.key.toUpperCase(),
      value: param.value,
    }));

    const areAllKeysAvailableInQueryParams =
      templateKeys &&
      templateKeys.every(key => queryParamsUppercase.some(param => param.key === key.substring(2, key.length - 1)));

    if (areAllKeysAvailableInQueryParams) {
      link = urlUppercase;
      templateKeys.forEach(key => {
        const keyWithoutSpecialCharacters = key.substring(2, key.length - 1);
        const val = queryParamsUppercase.find(param => param.key === keyWithoutSpecialCharacters).value;
        link = link.toLowerCase();
        link = link.replace(key.toLowerCase(), val);
      });
    }

    const directLink = this._generateDirectLinkObject(link, queryParams);
    return directLink;
  }

  private _generateDirectLinkObject(link: string, queryParams: PopQueryParam[]): PopDirectLink {
    let linkWithoutQueryParams = link;
    const queryParamsArray = link.match(/\?.+$/g);
    const queryParamsObject = {};

    if (queryParamsArray) {
      const queryParamsString = queryParamsArray[0];
      linkWithoutQueryParams = linkWithoutQueryParams.replace(queryParamsString, '');
      queryParamsString
        .substring(1)
        .split('&')
        .forEach(pair => {
          const parts = pair.split('=');
          queryParamsObject[parts[0]] = parts[1];
        });
    }

    if (queryParams.length) {
      queryParams.forEach(param => {
        queryParamsObject[param.key] = param.value;
      });
    }

    const directLink: PopDirectLink = {
      link,
      linkWithoutQueryParams,
      queryParams: queryParamsObject,
    };

    return directLink;
  }
}
