import { ModuleWithProviders, NgModule } from '@angular/core';

import { AnimationsService } from './animations.service';

@NgModule()
export class AnimationsModule {
  static forRoot(): ModuleWithProviders<AnimationsModule> {
    return {
      ngModule: AnimationsModule,
      providers: [AnimationsService],
    };
  }
}
