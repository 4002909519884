import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrinterType } from 'projects/store/src/lib/settings/settings-deprecated.type';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EPosService, ParserService, TemplateService } from '@bend/e-pos';
import { OrderHttpService } from '@bend/order';
import { CollectType, ItemMeta, ItemMetaPromoCode, OrderStatus, Settings, SettingsService } from '@bend/store';

import { KioskOrderDetail, KioskPrinterFactoryService, OrderDetail, StoreData } from '../kiosk-printer';

type ItemData = { quantity: number; name: string; items?: ItemData[]; priceFinalValue?: number; price?: number };
type PreparedItemData = { nameWithQuantity: string; items: PreparedItemData[]; price: string };

const prepareItemData = ({ quantity, name, items, price, priceFinalValue }: ItemData): PreparedItemData => {
  return {
    nameWithQuantity: `${quantity} x ${name}`,
    price: (((price ?? priceFinalValue ?? 0) * quantity) / 100).toFixed(2),
    items: (items ?? []).map(prepareItemData),
  };
};

const mapItems = (items: Omit<ItemMeta, 'name'> | Omit<ItemMetaPromoCode, 'name'>): OrderDetail[] => {
  return 'options' in items
    ? items.options.map(option =>
        mapItemForPLV({
          name: option.name,
          quantity: option.quantity,
          price: option.priceFinalValue,
        }),
      )
    : 'items' in items
    ? items.items.map(item =>
        mapItemForPLV({
          name: item.name,
          quantity: item.quantity,
          price: item.priceFinalValue,
        }),
      )
    : [];
};

const mapItemForPLV = ({ quantity, name, price }: ItemData): OrderDetail => {
  return {
    Libelle_Article: name,
    Prix_Ligne: price,
    qt: `${quantity}`,
  };
};

export type TemplateCheckData = Record<'date' | 'time' | 'total' | 'prefix' | 'suffix', string> & {
  items: {
    nameWithQuantity: string;
    price: string;
  }[];
};

const templateCheckStart = `
[start][{{prefix}}]
[spaceBetween][{{date}}][{{time}}]
`;

const templateCheckEnd = `
[start][------------------------------------------------]
[
  [indent]
  [spaceBetween][{{ nameWithQuantity }}][{{ price }}]
  [
    [indent]
    [spaceBetween][{{ nameWithQuantity }}][{{ price }}]
    [
      [indent]
      [spaceBetween][{{ nameWithQuantity }}][{{ price }}]
      [unindent]
    ][items]
    [unindent]
  ][items]
  [unindent]
][items]
[start][------------------------------------------------]
[spaceBetween][Total:][{{ total }}]
[start][{{suffix}}]
`;

const padStart = (e: number | string, length: number, padValue: string) => e.toString().padStart(length, padValue);

@Injectable()
export class PrinterCheckService {
  constructor(
    private _settings: SettingsService,
    private _orderHttp: OrderHttpService,
    private _parser: ParserService,
    private _template: TemplateService,
    private _ePos: EPosService,
    private kioskPrinterFactoryService: KioskPrinterFactoryService,
    private _translate: TranslateService,
  ) {}

  print(): Observable<unknown> {
    return this._settings.printer.pipe(
      switchMap(printer => {
        if (printer?.type === PrinterType.EPSON) {
          return this.printWithEpson(printer);
        } else if (printer?.type === PrinterType.PLV_BROKER || printer?.type === PrinterType.KIOSK) {
          return this.printWithOnKiosk(printer, printer.type);
        } else if (printer?.type === PrinterType.NOOP_PRINTER) {
          return this.printWithNoop();
        } else {
          return of(null);
        }
      }),
    );
  }

  printWithEpson({ data: { printerIp, printerTemplateMeta, printerPort } }: Settings['printer']) {
    return combineLatest([this._orderHttp.getRaw(), this._ePos.connect(printerIp, printerPort)]).pipe(
      switchMap(([data]) => {
        const { orderUsers, id, orderDisplayId } = data;

        const templateMeta = printerTemplateMeta?.trim() ? JSON.parse(printerTemplateMeta) : {};

        const dataSet: TemplateCheckData[] = orderUsers
          .map(({ businessLocations }) =>
            businessLocations.map(({ orderItems }): TemplateCheckData => {
              const date = new Date();

              return {
                prefix: templateMeta.prefix ?? '',
                suffix: templateMeta.suffix ?? '',
                total: (orderItems.reduce((sum, e) => sum + e.price * e.quantity, 0) / 100).toFixed(2),
                time: `${padStart(date.getHours(), 2, '0')}:${padStart(date.getMinutes(), 2, '0')}`,
                date: `${padStart(date.getDate(), 2, '0')}-${padStart(date.getMonth(), 2, '0')}-${date.getFullYear()}`,
                items: orderItems.map(({ itemMeta: { name, ...itemMeta }, price, quantity }) => ({
                  ...prepareItemData({
                    name,
                    price,
                    quantity,
                    items:
                      'options' in itemMeta
                        ? itemMeta.options
                        : 'items' in itemMeta
                        ? itemMeta.items.map(({ options, ...data }) => ({ ...data, items: options }))
                        : [],
                  }),
                })),
              };
            }),
          )
          .flat();

        const render = (template: string) => {
          const generateTemplate = this._template.generate();

          this._parser
            // NOTE: assumed that order have only one businessLocations
            .parse(template, dataSet[0])
            .forEach(([action, ...params]) => generateTemplate[action](params));

          return generateTemplate.text();
        };

        const orderDisplayIdTemplate = templateMeta?.orderDisplayIdTemplate ?? 'No Commande [value]';
        const orderDisplayIdText = orderDisplayIdTemplate.replace('[value]', orderDisplayId.toString());

        return this._ePos.print(String(id), {
          render: device => {
            device.addText(`${render(templateCheckStart)}\n`);

            // change style for next text and revert it after
            device.addTextAlign('center').addTextSize(2, 2).addTextStyle(false, false, true);
            device.addText(`${orderDisplayIdText}\n`);
            device.addTextAlign('left').addTextSize(1, 1).addTextStyle(false, false, false);

            device.addText(`${render(templateCheckEnd)}\n`);
            device.addCut();
          },
        });
      }),
    );
  }

  printWithOnKiosk({ data: { printerTemplateMeta } }: Settings['printer'], printerType: PrinterType): Observable<void> {
    this._orderHttp.getRaw().subscribe(orderData => {
      const { orderUsers } = orderData;
      const orderDetails = orderUsers
        .map(e => e.businessLocations)
        .flat()
        .map(businessLocations =>
          businessLocations.orderItems.map(({ itemMeta: { name, ...itemMeta }, price, quantity }) => {
            return { ...mapItemForPLV({ quantity, name, price }), detail: mapItems(itemMeta) };
          }),
        )
        .reduce((acc, val) => (acc = [...acc, ...val]));

      const unpaidStatuses = new Set([OrderStatus.New, OrderStatus.Send]);

      const order: KioskOrderDetail = {
        commande: {
          numeroCommande: `${orderData.orderDisplayId}`,
          mode: this._translate.instant(
            orderData.details.collectType === CollectType.EatIn
              ? 'CART.COLLECT.EAT_IN.TITLE'
              : 'CART.COLLECT.TAKE_AWAY.TITLE',
          ),
          status: this._translate.instant(
            unpaidStatuses.has(orderData.status as OrderStatus)
              ? 'CART.LOCATION_STATUS_UNPAID'
              : 'CART.LOCATION_STATUS_PAID',
          ),
          amount: orderData.totalInfo.total,
        },
        ligne: orderDetails,
      };

      const printerService = this.kioskPrinterFactoryService.getPrinterService(printerType);
      printerService.printReceipt(JSON.parse(printerTemplateMeta) as unknown as StoreData, order);
    });
    return of(null);
  }

  printWithNoop(): Observable<void> {
    this._orderHttp.getRaw().subscribe(orderData => {
      const { orderUsers } = orderData;
      const orderDetails = orderUsers
        .map(e => e.businessLocations)
        .flat()
        .map(businessLocations =>
          businessLocations.orderItems.map(({ itemMeta: { name, ...itemMeta }, price, quantity }) => {
            return { ...mapItemForPLV({ quantity, name, price }), detail: mapItems(itemMeta) };
          }),
        )
        .reduce((acc, val) => (acc = [...acc, ...val]));

      const unpaidStatuses = new Set([OrderStatus.New, OrderStatus.Send]);

      const order: KioskOrderDetail = {
        commande: {
          numeroCommande: `${orderData.orderDisplayId}`,
          mode: this._translate.instant(
            orderData.details.collectType === CollectType.EatIn
              ? 'CART.COLLECT.EAT_IN.TITLE'
              : 'CART.COLLECT.TAKE_AWAY.TITLE',
          ),
          status: this._translate.instant(
            unpaidStatuses.has(orderData.status as OrderStatus)
              ? 'CART.LOCATION_STATUS_UNPAID'
              : 'CART.LOCATION_STATUS_PAID',
          ),
          amount: orderData.totalInfo.total,
        },
        ligne: orderDetails,
      };
      // eslint-disable-next-line no-console
      console.log('printer a receipt with printer');
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(order));
      // eslint-disable-next-line no-console
      console.log('----------------------------------');
    });

    return of(null);
  }
}
