import { Widget } from '../../shared/models';

export interface AskQuestionWidget extends Widget {
  attributes: {
    hideQuestions: boolean;
    showOnlyMyQuestions: boolean;
    labels: AskQuestionLabels;
  };
}
export class AskQuestionLabels {
  TITLE = 'Title';
  QUESTION = 'Question';
  NAME = 'Name';
  BY = 'By';
  THANK_YOU = 'Thank you for your question';
  QUESTION_REQUIRED = 'Error question required';
  SUBMIT_BUTTON = 'Button submit';
  CLOSE = 'Close';
}

export enum AskQuestionLabelsNames {
  TITLE = 'ASK_QUESTION.TITLE',
  QUESTION = 'ASK_QUESTION.QUESTION',
  NAME = 'ASK_QUESTION.NAME',
  BY = 'ASK_QUESTION.BY',
  THANK_YOU = 'ASK_QUESTION.THANK_YOU',
  QUESTION_REQUIRED = 'ASK_QUESTION.QUESTION_REQUIRED',
  SUBMIT_BUTTON = 'ASK_QUESTION.SUBMIT_BUTTON',
  CLOSE = 'ASK_QUESTION.CLOSE',
}

export interface AskQuestionLabel {
  name: string;
  key: string;
}
