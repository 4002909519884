import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonAbstractComponent } from '../../abstract';

@Component({
  selector: 'designer-flat-button',
  templateUrl: './flat-button.component.html',
  styleUrls: ['../../styles/common-button.scss', './flat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatButtonComponent extends ButtonAbstractComponent {
  constructor() {
    super();
  }
}
