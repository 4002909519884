import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StoreService } from '@bend/store';

import { User, usersActions, UsersState } from '@designer-store/users';
import * as usersSelectors from '@designer-store/users/users.selectors';

@Injectable()
export class UsersDesignerStoreService extends StoreService<UsersState> {
  constructor(protected _store: Store<UsersState>, protected _actions: Actions) {
    super(_store, _actions, usersSelectors);
  }

  getUsers(pagination: { offset: number; limit: number }, search?: string, appSlug?: string): Observable<boolean> {
    this._store.dispatch(usersActions.getUsers({ pagination, search, appSlug }));

    return this._finishedActionStatus(this._error, usersActions.getUsersSuccess, usersActions.getUsersError);
  }

  getUser(id: string, appSlug: string): Observable<boolean> {
    this._store.dispatch(usersActions.getUser({ id, appSlug }));

    return this._finishedActionStatus(this._error, usersActions.getUserSuccess, usersActions.getUserError);
  }

  addUser(user: User, appSlug: string = null): Observable<boolean> {
    this._store.dispatch(usersActions.addUser({ user, appSlug }));

    return this._finishedActionStatus(this._error, usersActions.addUserSuccess, usersActions.addUserError);
  }

  updateUser(user: User, appSlug: string): Observable<boolean> {
    this._store.dispatch(usersActions.updateUser({ user, appSlug }));

    return this._finishedActionStatus(this._error, usersActions.updateUserSuccess, usersActions.updateUserError);
  }

  deleteUser(id: string, appSlug: string): Observable<boolean> {
    this._store.dispatch(usersActions.deleteUser({ id, appSlug }));

    return this._finishedActionStatus(this._error, usersActions.deleteUserSuccess, usersActions.deleteUserError);
  }

  get users(): Observable<User[]> {
    return this._store.select(usersSelectors.users);
  }

  get totalCount(): Observable<number> {
    return this._store.select(usersSelectors.totalCount);
  }

  userById(id: string): Observable<User> {
    return this._store.select(usersSelectors.userById(id));
  }

  private get _error(): Observable<string> {
    return this._store.select(usersSelectors.errorCode);
  }
}
