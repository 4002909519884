import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { OrderStatusServices } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { CartService } from '@bend/store';
import { SettingsService } from '@bend/store';
import { OrderItemStatus, PaymentMethods } from '@bend/store-shared';

@Injectable()
export class SelectPaymentTypeService {
  readonly selectPayment: Observable<PaymentMethods>;

  private _selectPayment: BehaviorSubject<PaymentMethods>;

  constructor(
    private _settings: SettingsService,
    private _orderStatus: OrderStatusServices,
    private _cart: CartService,
  ) {
    this._selectPayment = new BehaviorSubject<PaymentMethods>(PaymentMethods.CreditCard);
    this.selectPayment = this._selectPayment.asObservable();

    this._cart.paymentMethods
      .pipe(
        first(),
        tap(methods => methods[0] && this.changePaymentType(methods[0])),
      )
      .subscribe();
  }

  get payByPop(): Observable<boolean> {
    const payByPopMethods: Set<PaymentMethods> = new Set([
      PaymentMethods.ApplePay,
      PaymentMethods.GooglePay,
      PaymentMethods.RestaurantTicket,
      PaymentMethods.Swile,
      PaymentMethods.Other,
      PaymentMethods.CreditCard,
      PaymentMethods.Lyf,
    ]);

    return this.isShow.pipe(
      switchMap(bySelect =>
        iif(
          () => bySelect,
          /**
           * use selected value
           */
          this.selectPayment.pipe(map(paymentMethod => payByPopMethods.has(paymentMethod))),
          this._settings.noPayment.pipe(
            /**
             * need to reverse value because you need to return true when is pay by pop
             */
            map(payByWaiter => !payByWaiter),
          ),
        ),
      ),
    );
  }

  get isShow(): Observable<boolean> {
    const allowStatuses: Set<OrderItemStatus> = new Set([
      OrderItemStatus.Empty,
      OrderItemStatus.New,
      OrderItemStatus.Reimbursed,
      OrderItemStatus.PaymentNotSent,
      OrderItemStatus.OrderNotSent,
      OrderItemStatus.Ordered,
      OrderItemStatus.FailedPreCheck,
    ]);

    return this._settings.widgetCart.pipe(
      map(({ allowCashPayment, cashPaymentOnly }) => allowCashPayment && !cashPaymentOnly),
      switchMap(allowCash =>
        iif(
          /**
           * @description show if is pay by pop and waiter
           */
          () => allowCash,
          of(true),
          this._settings.noPayment.pipe(
            /**
             * @description show if is pay by pop only and have multiple payment methods
             */
            withLatestFrom(this._cart.paymentMethods.pipe(map(methods => methods.length > 1))),
            map(([payByWaiter, isMultipleMethods]) => !payByWaiter && isMultipleMethods),
          ),
        ),
      ),
      switchMap(isMultiple =>
        isMultiple
          ? /**
             * @description allow only for some statuses
             */
            this._orderStatus.orderStatusChanged.pipe(map(({ status }) => allowStatuses.has(status)))
          : of(false),
      ),
    );
  }

  /**
   * @param value if true means pay by pop and if false is pay by waiter
   * @description value = true  -> pay by pop; value = false -> pay by waiter
   */
  changePaymentType(value: PaymentMethods): void {
    this._selectPayment.next(value);
  }
}
