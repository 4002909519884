import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ParamsService } from '@bend/store';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { ContactLabels, WidgetContact } from './contact';
import { WidgetContactDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'pop-widget-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class WidgetContactComponent implements OnInit, WidgetComponent, OnDestroy {
  static widgetName = 'contact';
  @Input() attributes: WidgetContact;

  isValid: boolean;
  noValidMessage: string;
  labels: ContactLabels;
  isReadOnly: boolean;
  buttonColor: string;
  textColor: string;

  private _subscription: Subscription;

  constructor(private _dialog: MatDialog, private _warningService: WarningService, private _params: ParamsService) {
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    if (!this.attributes) {
      this.noValidMessage = this._warningService.showWarn(WidgetContactComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }
    this._addMissingAttributes();
    this.labels = this.attributes.labels;
    this.isReadOnly = this.attributes.isEditMode || this.attributes.pop.validationStatus === PopValidationStatus.FAILED;
    this.buttonColor = this.isReadOnly ? 'grey' : this.attributes.mainColor;
    this.textColor = this.isReadOnly ? 'white' : this.attributes.textColor;
    this.isValid = true;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  openDialog(): void {
    if (this.isReadOnly) {
      return;
    }

    this._dialog.open(WidgetContactDialogComponent, {
      width: '100%',
      maxWidth: '100%',
      data: this.attributes,
      panelClass: 'custom-md-dialog-panel',
    });
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }

    if (!this.attributes.labels) {
      this.attributes.labels = new ContactLabels();
    }

    if (!this.attributes.pageId) {
      this._subscription.add(this._params.pageId.subscribe(pageId => (this.attributes.pageId = pageId)));
    }
  }
}
