import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './recommendations-designer.config';
import { RecommendationsDesignerEffects } from './recommendations-designer.effects';
import { RecommendationsDesignerHttpService } from './recommendations-designer.http.service';
import { reducer } from './recommendations-designer.reducer';
import { RecommendationsDesignerService } from './recommendations-designer.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([RecommendationsDesignerEffects])],
  providers: [RecommendationsDesignerHttpService, RecommendationsDesignerService],
})
export class RecommendationsDesignerStoreModule {}
