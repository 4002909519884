import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { CkeditorConfigService } from '../../shared/services/ckeditor-config.service';
import { WidgetService } from '../widget.service';
import { TextWidget } from './text';

@Component({
  selector: 'pop-widget-edit-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@StaticImplements<WidgetType>()
export class PopWidgetEditTextComponent extends WidgetBaseComponent<TextWidget> implements OnInit {
  static widgetName = 'text';

  protected ckeditor = this.ckeditorConfigService.Editor;
  protected editorConfig = this.ckeditorConfigService.EditorConfig;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
    private ckeditorConfigService: CkeditorConfigService,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  get text(): UntypedFormControl {
    return this.form.get('text') as UntypedFormControl;
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        text: [this.widget.attributes.text, Validators.required],
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
