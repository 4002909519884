import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { LinkService } from '../link.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopQueryParam } from '../widget';
import { WidgetComponent } from '../widget.component';
import { WidgetCustom } from './custom';

@Component({
  selector: 'pop-widget-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
})
export class WidgetCustomComponent implements OnInit, WidgetComponent {
  static widgetName = 'custom';

  @Input() attributes: WidgetCustom;

  noValidMessage: string;
  isValid = false;
  url: any = '';
  queryParams = '';

  constructor(
    private warningService: WarningService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private linkService: LinkService,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.url) {
      this.noValidMessage = this.warningService.showWarn(WidgetCustomComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this.isValid = true;
    this.prepareUrl();
  }

  prepareUrl(): void {
    const queryParams: PopQueryParam[] = this.route.snapshot.queryParamMap.keys.map(item => ({
      key: item,
      value: this.route.snapshot.queryParamMap.get(item),
    }));
    const directLink = this.linkService.generateDirectLink(this.attributes.url, queryParams);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(directLink.link);
  }
}
