import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { DuplicatePageComponent } from '../duplicate/duplicate-page/duplicate-page.component';
import { QrCodeService } from '../qr-code/qr-code.service';
import { PopPage } from '../types';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent {
  @Input() title: string;
  @Input() page?: PopPage;
  @Input() hasBackBtn?: boolean;
  @Input() addBtnTitle?: string;
  @Input() addBtnUrl?: string;
  @Input() queryParams: any = null;
  @Input() appHost: string;
  @Input() backUrl: string;

  @Output() addBtnClickEmmiter?: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private _location: Location,
    private _router: Router,
    private _route: ActivatedRoute,
    private _qrCodeService: QrCodeService,
  ) {}

  onAddBtnClick(): void {
    this.addBtnClickEmmiter.emit();
  }

  goBack(): void {
    if (this.backUrl) {
      this._router.navigate([this.backUrl], { relativeTo: this._route });
      return;
    }
    this._location.back();
  }

  gotToPageEdit(): void {
    const appSlug = this._route.parent.snapshot.params.appSlug;
    this._router.navigateByUrl(`/dashboard/${appSlug}/pages/edit/${this.page._id}`);
  }

  showQrCode(): void {
    this._qrCodeService.showQrCode(this.appHost, this.title);
  }

  openDuplicatePageEditor(): MatDialogRef<any> {
    return this.dialog.open(DuplicatePageComponent, {
      data: {
        pageId: this.page._id,
        appSlug: this._route.parent.snapshot.params.appSlug,
      },
    });
  }
}
