import { Pipe, PipeTransform } from '@angular/core';

import { PopTable } from '../../dashboard/components';

@Pipe({ name: 'sortByNameAndNumber' })
export class SortByNameAndNumberPipe implements PipeTransform {
  transform(value: PopTable[]): PopTable[] {
    if (value) {
      const sortByNumber = value.filter(item => !!Number(item.name)).sort((a, b) => Number(a.name) - Number(b.name));
      const sortByName = value.filter(item => !Number(item.name)).sort((a, b) => (a.name < b.name ? -1 : 1));
      return sortByNumber.concat(sortByName);
    }
    return value;
  }
}
