import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettingsService } from '@bend/settings-app';

import { AuthService } from '@designer-core';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderComponent {
  appName$: Observable<string>;

  constructor(private _authService: AuthService, private _router: Router, private _settings: AppSettingsService) {
    this.appName$ = this._settings.general.pipe(map(settings => settings?.name));
  }

  onChangePassword(): void {
    this._router.navigate(['apps/change-password']);
  }

  logout(): void {
    this._authService.logout();
  }
}
