export interface CustomerInfo {
  name: string;
  email: string;
  phone: string;
  gender: string;
  dateOfBirth: string;
  birthPlace: string;
  firstName: string;
  lastName: string;
  shopIdentifier: string;
}
