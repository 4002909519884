export const enum CollectType {
  EatIn = 'eat-in',
  TakeAway = 'take-away',
  Delivery = 'delivery',
}

export const CollectTypeTranslations: { [key in CollectType]: string } = {
  [CollectType.EatIn]: 'STUDIO.POS_INTEGRATIONS.EAT_IN',
  [CollectType.TakeAway]: 'STUDIO.POS_INTEGRATIONS.TAKE_AWAY',
  [CollectType.Delivery]: 'STUDIO.POS_INTEGRATIONS.DELIVERY',
};

export const getTranslationKeyByCollectType = (collectType: CollectType): string => {
  return CollectTypeTranslations[collectType];
};
