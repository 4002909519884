<lib-icon class="flag" [style.fill]="mainColor" icon="user"></lib-icon>
<input
  class="input"
  type="text"
  data-cy="personNameInput"
  [formControl]="nameControl"
  [class.input--invalid]="nameControl.invalid && nameControl.touched"
/>
<button *ngIf="isCompleted" mat-icon-button class="button" (click)="editInput()">
  <lib-icon class="button__icon" icon="pencil"></lib-icon>
</button>
