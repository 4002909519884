import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { WidgetHelper } from '../../../widgets/widget.helper';
import { Page } from '../../interfaces';

@Component({
  selector: 'pop-widget-edit-link-selector',
  templateUrl: './link-selector.component.html',
  styleUrls: ['./link-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkSelectorComponent {
  @Input() control: UntypedFormControl;
  @Input() pages: Page[] = [];
  @Input() host: string;

  get pageLink(): string {
    return WidgetHelper.generateFinalUrl(this.control.value, this.host);
  }
}
