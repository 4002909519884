import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const checkDuplicateItem: (field: string) => (control: UntypedFormControl) => ValidationErrors = field => {
  return (control: UntypedFormControl): ValidationErrors | null => {
    let hasDuplicate = false;
    const group = control.parent as UntypedFormGroup;

    if (group) {
      const values = control.parent.parent.value.map((value: { [x: string]: any }) => value[field]);

      hasDuplicate = values.filter((value: { [x: string]: any }) => value === control.value).length > 1;
    }

    return hasDuplicate ? { duplicate: true } : null;
  };
};
