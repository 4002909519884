import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

export interface DialogConfig {
  // if there are several lines in the message then the first line is the title and the rest is the description.
  //  but if it's just a line, this will be the description, and the title won't be displayed.
  // interpolation has this syntax {{ variable }}
  message: string | Observable<string>;
  // this key displays the close button.
  // default value is true but for progress it is false
  showCloseButton?: boolean;
  // timeToClose is indicated in milliseconds and is for automatic closing of the dialog
  // default value is not set
  timeToClose?: number;
  // allowToCloseOutside allows other dialogs to close the current dialog
  // default value is true
  allowToCloseOutside?: boolean;
  // closePreviousDialogs closes all dialogs that are open and have the allowToCloseOutside set to true
  // default value is true
  closePreviousDialogs?: boolean;
  // interpolatedData is the data to be interpolated in the message
  // default value is {}
  interpolateData?: Dictionary<string | number> | Observable<Dictionary<string | number>>;
}

export type DialogConfirmConfig = Omit<DialogConfig, 'showCloseButton'>

export interface DialogConfigComponent extends Omit<DialogConfig, 'message' | 'interpolateData'> {
  title: Observable<SafeHtml | undefined>;
  description: Observable<SafeHtml>;
  close: (id: string) => void;
}
