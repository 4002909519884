import { Widget } from '../../shared/models';

export interface ShopTeaserWidget extends Widget {
  attributes: {
    icon: string;
    title: string;
    link: string;
    subtitle: string;
    openInNewTab: boolean;
    style: {
      margin: {
        bottom: number;
        top: number;
      };
      borderRadius: number;
      displayType: ShopTeaserDisplayType;
    };
  };
}

export enum ShopTeaserDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}
