import { Component, Input } from '@angular/core';

@Component({
  selector: 'pop-widget-product-catalog-subcategory',
  templateUrl: './product-catalog-subcategory.component.html',
  styleUrls: ['./product-catalog-subcategory.component.scss'],
})
export class WidgetProductCatalogSubcategoryComponent {
  @Input() subcategoryName: string;
  @Input() subcategoryDescription: string;
  @Input() mainColor: string;
  @Input() secondColor: string;
  @Input() styleType: string;
  @Input() styleNumber: number;
  @Input() set limits({ min, max }: { min: number; max: number }) {
    if (!min && !max) this.limitMessage = '';
    if (!min && max) this.limitMessage = `Max: ${max}`;
    if (min && !max) this.limitMessage = `Min: ${min}`;
    if (min && max) this.limitMessage = `Min: ${min} - Max: ${max}`;
  }

  protected limitMessage: string;
  subcategoryStyleType: string;
}
