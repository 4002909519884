import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '../../api';

@Injectable({ providedIn: 'root' })
export class SessionHttpService {
  constructor(private _api: ApiVisitorService, private _http: HttpClient) {}

  create(place: string): Observable<void> {
    return this._api.orders('v2').pipe(switchMap(api => this._http.post<void>(api, { place })));
  }
}
