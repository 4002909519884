import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StoreService } from '@bend/store';

import { Application } from '@designer-store/apps';
import { appsActions } from '@designer-store/apps/apps.actions';
import * as appsSelectors from '@designer-store/apps/apps.selectors';
import { AppsState } from '@designer-store/apps/apps.state';

@Injectable()
export class AppsDesignerStoreService extends StoreService<AppsState> {
  constructor(protected _store: Store<AppsState>, protected _actions: Actions) {
    super(_store, _actions, appsSelectors);
  }

  getApps(): Observable<boolean> {
    this._store.dispatch(appsActions.getApps());

    return this._finishedActionStatus(
      this._error,
      appsActions.getAppsLoaded,
      appsActions.getAppsSuccess,
      appsActions.getAppsError,
    );
  }

  get apps(): Observable<Application[]> {
    return this._store.select(appsSelectors.apps);
  }

  private get _error(): Observable<string> {
    return this._store.select(appsSelectors.errorCode);
  }
}
