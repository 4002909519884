import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ParamsService } from '@bend/store';
import { SettingsService } from '@bend/store';
import { LoyaltyParams, ProfileService } from '@bend/store-shared';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { TextService } from './services';
import { WidgetText } from './text';

@Component({
  selector: 'pop-widget-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetTextComponent implements OnInit, WidgetComponent {
  static widgetName = 'text';
  @Input() attributes: WidgetText;

  isValid: boolean;
  noValidMessage: string;
  text$: Observable<SafeHtml>;

  constructor(
    private _warningService: WarningService,
    private _sanitizer: DomSanitizer,
    private _settings: SettingsService,
    private _params: ParamsService,
    private _textService: TextService,
    private _profile: ProfileService,
  ) {}

  ngOnInit(): void {
    // if (this.attributes.isEditMode) return;
    if (!this.attributes || !this.attributes.text) {
      this.noValidMessage = this._warningService.showWarn(WidgetTextComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }
    this.text$ = this._text;
    this.isValid = true;
  }

  private get _userName(): Observable<string> {
    return this._params
      .queryParams([LoyaltyParams.ShareCode])
      .pipe(
        switchMap(([shareCode]) =>
          shareCode
            ? this._textService.invitedEligibility(shareCode).pipe(map(invitedEligibility => invitedEligibility?.name))
            : this._profile.getMe().pipe(map(profile => profile?.name)),
        ),
      );
  }

  private _replaceUserName(text: string): Observable<string> {
    return this._userName.pipe(map(userName => text.replace('&lt;$user_name&gt;', userName)));
  }

  private _replacePlaceName(text: string): Observable<string> {
    return this._settings.placeName.pipe(map(placeName => text.replace('&lt;$table_name&gt;', placeName || '')));
  }

  private get _text(): Observable<SafeHtml> {
    return of(this.attributes.text).pipe(
      switchMap(text => (text.includes('&lt;$user_name&gt;') ? this._replaceUserName(text) : of(text))),
      switchMap(text => (text.includes('&lt;$table_name&gt;') ? this._replacePlaceName(text) : of(text))),
      map(text => this._sanitizer.bypassSecurityTrustHtml(text)),
    );
  }
}
