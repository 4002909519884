import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '../../api';
import { CreditHistory } from '../../types';

@Injectable({ providedIn: 'root' })
export class CreditsService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  getCreditHistory(): Observable<CreditHistory[]> {
    return this._api.history('v1').pipe(switchMap(api => this._http.get<CreditHistory[]>(api)));
  }
}
