import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Subscription } from 'rxjs';

import { DialogService } from '@bend/dialog';
import { ParamsService } from '@bend/store';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { AskQuestionLabels, AskQuestionResponse, Visitor, WidgetAskQuestion } from './ask-question';
import { WidgetAskQuestionService } from './ask-question.service';
import { WidgetQuestionDialogComponent } from './question-dialog/question-dialog.component';

@Component({
  selector: 'pop-widget-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss'],
})
export class WidgetAskQuestionComponent implements OnInit, WidgetComponent, OnDestroy {
  static widgetName = 'ask_question';
  @Input() attributes: WidgetAskQuestion;

  form: UntypedFormGroup;
  myQuestions: AskQuestionResponse[] = [];
  showErrors = false;
  isValid: boolean;
  noValidMessage: string;
  userId: string;
  labels: AskQuestionLabels;
  isLoadingLocal: boolean;
  isReadOnly: boolean;
  buttonColor: string;

  private _subscription: Subscription;

  constructor(
    @Optional() private _dialog: DialogService,
    private _askQuestionService: WidgetAskQuestionService,
    private _formBuilder: UntypedFormBuilder,
    private _warningService: WarningService,
    private _matDialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _params: ParamsService,
  ) {
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    if (!this.attributes) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetAskQuestionComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this._addMissingAttributes();
    this._initLabels();
    this.isValid = true;
    this._initForm();
    this.isReadOnly = this.attributes.isEditMode || this.attributes.pop.validationStatus === PopValidationStatus.FAILED;
    this.buttonColor = this.isReadOnly ? 'grey' : this.attributes.mainColor;

    if (this.isReadOnly || this.attributes.hideQuestions) {
      return;
    }

    this._initMyQuestions();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  onSubmit(form: UntypedFormGroup): void {
    if (this.isLoadingLocal || this.isReadOnly) {
      return;
    }

    if (this.form.controls.question.value) {
      this.form.controls.question.setValue(this.form.controls.question.value.trim());
    }
    if (this.form.controls.name.value) {
      this.form.controls.name.setValue(this.form.controls.name.value.trim());
    }

    if (!form.valid) {
      this.showErrors = true;
      return;
    }

    this.isLoadingLocal = true;
    this.showErrors = false;
    this._subscription.add(
      this._askQuestionService.askQuestion(this.attributes.pageId, form.value).subscribe({
        next: (res: AskQuestionResponse) => {
          this.isLoadingLocal = false;
          if (!this.attributes.hideQuestions) {
            this.myQuestions.push(res);
          }
          form.controls.question.setValue('');
          this._showThanksDialog();
          this._cdr.detectChanges();
        },
        error: () => {
          this.isLoadingLocal = false;
          this._dialog.error({ message: 'Failed to save the question. Please try again.' });
          this._cdr.detectChanges();
        },
      }),
    );
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }

    if (!this.attributes.labels) {
      this.attributes.labels = new AskQuestionLabels();
    }

    if (!this.attributes.pageId) {
      this._subscription.add(this._params.pageId.subscribe(pageId => (this.attributes.pageId = pageId)));
    }
  }

  private _initLabels(): void {
    this.labels = this.attributes.labels;
  }

  private _showThanksDialog(): MatDialogRef<WidgetQuestionDialogComponent> {
    return this._matDialog.open(WidgetQuestionDialogComponent, {
      data: {
        mainColor: this.attributes.mainColor,
        labels: this.labels,
      },
      maxWidth: '90vw',
    });
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      name: [],
      question: ['', Validators.required],
    });
  }

  private _initMyQuestions(): void {
    this._subscription.add(
      forkJoin([
        this._askQuestionService.getMe(),
        this._askQuestionService.getQuestions(this.attributes.pageId),
      ]).subscribe(values => {
        const user: Visitor = values[0];
        let questions: AskQuestionResponse[] = values[1] as AskQuestionResponse[];

        if (this.attributes.showOnlyMyQuestions) {
          questions = questions.filter(question => question.user._id === user._id);
        }

        this.myQuestions = questions;
        this._cdr.detectChanges();
      }),
    );
  }
}
