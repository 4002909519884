import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import { StoreService, Tag } from '../shared';
import { actions } from './tags.actions';
import * as selectors from './tags.selectors';
import { State } from './tags.type';

@Injectable()
export class TagsService extends StoreService<State> {
  readonly list: Observable<Tag[]>;
  readonly currentTag: Observable<Tag>;
  readonly lastAdded: Observable<number | string>;
  readonly isLoaded: Observable<boolean>;

  constructor(
    protected override readonly _store: Store<State>,
    protected override readonly _actions: Actions,
    protected readonly _designerRouter: DesignerRouterService,
  ) {
    super(_store, _actions, selectors);

    this.list = this._list;
    this.currentTag = this._currentTag;
    this.lastAdded = this._lastAdded;
    this.isLoaded = this._isLoaded;
  }

  getList(appSlug: string): Observable<boolean> {
    this._store.dispatch(actions.getList({ appSlug }));

    return this._finishedActionStatus(this._error, actions.getListSuccess, actions.getListError);
  }

  getOne(id: number): Observable<boolean> {
    this._store.dispatch(actions.get({ id }));

    return this._finishedActionStatus(this._error, actions.getSuccess, actions.getError);
  }

  add(tag: Partial<Tag>): Observable<boolean> {
    this._store.dispatch(actions.add({ tag }));

    return this._finishedActionStatus(this._error, actions.addSuccess, actions.addError);
  }

  update(tag: Tag): Observable<boolean> {
    this._store.dispatch(actions.update({ tag }));

    return this._finishedActionStatus(this._error, actions.updateSuccess, actions.updateError);
  }

  delete(id: number): Observable<boolean> {
    this._store.dispatch(actions.delete({ id }));

    return this._finishedActionStatus(this._error, actions.deleteSuccess, actions.deleteError);
  }

  private get _list(): Observable<Tag[]> {
    return this._store.select(selectors.total);
  }

  private get _currentTag(): Observable<Tag> {
    return this._store.select(selectors.currentTag).pipe(filter<Tag>(Boolean));
  }

  private get _lastAdded(): Observable<string | number> {
    return this._store.select(selectors.lastAdded);
  }

  private get _isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoaded);
  }

  private get _error(): Observable<string> {
    return this._store.select(selectors.errorCode);
  }
}
