<div class="head-panel">
  <div class="head-panel__main" [ngClass]="page ? 'head-panel__main--column-flex-direction' : ''">
    <div class="head-panel__main__content" [class.full-width-main-column]="page">
      <button *ngIf="hasBackBtn" class="btn btn--back" (click)="goBack()">
        <svg
          class="svg-element--white-fill btn--back__arrow"
          xmlns="http://www.w3.org/2000/svg"
          height="10"
          version="1.2"
          viewBox="0 0 11.62617 10"
          width="11.626"
        >
          <path
            d="M10.44 9.707c-1.06-1.614-2.37-2.12-3.98-2.25v.93c0 .344-.135.668-.38.913-.488.487-1.34.484-1.823.002L.193 5.292C.07 5.173 0 5.008 0 4.833c0-.174.07-.338.193-.46L4.253.366c.488-.487 1.34-.487 1.828 0 .244.245.38.57.38.915v1.1c2.983.603 5.166 3.228 5.166 6.33v.644c0 .286-.187.538-.46.62-.062.016-.123.026-.186.026-.213 0-.417-.107-.54-.293zM5.83 6.133c1.424.036 2.995.254 4.363 1.37C9.698 5.426 7.948 3.82 5.74 3.576c-.324-.036-.573-.034-.573-.034v-2.26l-3.6 3.55 3.6 3.553v-2.26s.478.006.662.006z"
          />
        </svg>
        <span>{{ 'STUDIO.SHARED.BACK' | translate }}</span>
      </button>
      <h2 class="head-panel__main__title">{{ title }}</h2>
      <button *ngIf="page" class="btn btn--from-head-panel" (click)="openDuplicatePageEditor()">
        <span>{{ 'STUDIO.SHARED.DUPLICATE' | translate }}</span>
      </button>
      <button *ngIf="page" class="btn btn--from-head-panel" (click)="gotToPageEdit()">
        <span>{{ 'STUDIO.SHARED.EDIT' | translate }}</span>
      </button>
    </div>
    <div class="head-panel__main__btns-wrapper" [class.full-width-main-column]="page">
      <a
        *ngIf="addBtnTitle && addBtnUrl"
        [routerLink]="addBtnUrl"
        [queryParams]="queryParams"
        class="btn btn--from-head-panel"
      >
        <span>{{ addBtnTitle }}</span>
      </a>
      <a
        *ngIf="addBtnTitle && !addBtnUrl && addBtnClickEmmiter"
        (click)="onAddBtnClick()"
        class="btn btn--from-head-panel"
      >
        <span>{{ addBtnTitle }}</span>
      </a>
    </div>
  </div>
</div>
