import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PayButtonService {
  isShowButton: Observable<boolean>;

  private _isShowButton: BehaviorSubject<boolean>;

  constructor() {
    this._isShowButton = new BehaviorSubject<boolean>(true);
    this.isShowButton = this._isShowButton.asObservable();
  }

  setShowButton(value: boolean): void {
    this._isShowButton.next(value);
  }
}
