import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './order.config';
import { OrderEffects } from './order.effects';
import { OrderHttpService } from './order.http.service';
import { metaReducers, reducer } from './order.reducer';
import { OrderService } from './order.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer, { metaReducers }), EffectsModule.forFeature([OrderEffects])],
  providers: [OrderHttpService, OrderService],
})
export class OrderStoreModule {}
