<h2 class="title">{{ labels.TITLE || 'CONTACT.TITLE' | translate }}</h2>
<form *ngIf="!isSent; else blockElse" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field">
    <label for="name">{{ labels.NAME || 'CONTACT.NAME' | translate }}</label>
    <div *ngIf="showErrors && form.controls['name'].invalid" class="error">
      {{ labels.NAME_REQUIRED || 'CONTACT.NAME_REQUIRED' | translate }}
    </div>
    <input type="text" name="name" formControlName="name" />
  </div>
  <div class="field">
    <label for="email">{{ labels.EMAIL || 'CONTACT.EMAIL' | translate }}</label>
    <div *ngIf="showErrors && form.controls['email'].invalid" class="error">
      {{ labels.EMAIL_INVALID || 'CONTACT.EMAIL_INVALID' | translate }}
    </div>
    <input type="email" name="email" formControlName="email" />
  </div>
  <div class="field">
    <label for="phone">{{ labels.PHONE || 'CONTACT.PHONE' | translate }}</label>
    <input type="text" name="phone" formControlName="phone" />
  </div>
  <mat-dialog-actions>
    <button type="submit" [style.backgroundColor]="data.mainColor" [style.color]="data.textColor" class="button">
      {{ labels.SUBMIT || 'CONTACT.SUBMIT' | translate }}
    </button>
  </mat-dialog-actions>
</form>
<ng-template #blockElse>
  <div class="success-message">
    {{ labels.SUCCESS_MESSAGE || 'CONTACT.SUCCESS_MESSAGE' | translate }}
  </div>
</ng-template>
