import { Component, Renderer2 } from '@angular/core';

import { LanguagesConfig } from '@bend/store';

import { LanguageService } from '@designer-core/services';

import { TextDirectionService } from './core/services/text-direction.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _textDirectionService: TextDirectionService,
    private _renderer: Renderer2,
    private readonly _language: LanguageService,
  ) {
    this._language.setDefaultLang('en');
    this._language.use((localStorage.getItem('lang') as LanguagesConfig) || this._language.defaultLang);
    this._language.onLangChange().subscribe(params => {
      this._renderer.setAttribute(document.body, 'dir', this._textDirectionService.getTextDirection(params.lang));
    });
  }
}
