import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload/file-upload';
import { WidgetBaseComponent } from '../../shared/components/widget-base/widget-base.component';
import { StaticImplements } from '../../shared/decorators';
import { Margin, Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { CkeditorConfigService } from '../../shared/services/ckeditor-config.service';
import { heightValidator } from '../../shared/validators/height.validator';
import { widthValidator } from '../../shared/validators/width.validator';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { ImageWidget, VerticalAlignType } from './image';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-edit-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditImageComponent extends WidgetBaseComponent<ImageWidget> implements OnInit {
  static widgetName = 'image';
  @Input() appHost: string;
  @Input() pages: Page[];

  protected editor = this.ckeditorConfigService.Editor;
  protected editorConfig = this.ckeditorConfigService.EditorConfig;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private widgetFormService: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
    private ckeditorConfigService: CkeditorConfigService,
  ) {
    super(widgetService, widgetFormService, snackbarService);
  }

  finalUrl: string;
  verticalAlignTypes: string[];

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this._initForm();

    this.onChangeUrl();
    this.verticalAlignTypes = Object.values(VerticalAlignType);
  }

  onFileChange(event: FileUploadDetails): void {
    const urlValue = event.url;
    const alt = event.name;
    this.url.setValue(urlValue);
    this.form.controls.alt.setValue(alt);
  }

  onChangeUrl(): void {
    this.finalUrl = WidgetHelper.generateFinalUrl(this.form.get('link').value, this.appHost);
  }

  onPageChange(page: Page, formControl: UntypedFormControl): void {
    formControl.setValue(page['_id']);
    this.onChangeUrl();
  }

  private _initForm(): void {
    const { url, alt, link, style, openInNewTab, text } = this.widget.attributes;
    this.widgetFormService.initAttributes(
      this._formBuilder.group({
        url: [url, Validators.required],
        alt: [alt],
        link: [link],
        openInNewTab: [openInNewTab],
        style: this._formBuilder.group({
          backgroundColor: [style.backgroundColor],
          borderRadius: [style.borderRadius],
          height: [style.height, heightValidator()],
          width: [style.width, widthValidator()],
          margin: this._formBuilder.group({
            top: [style.margin.top],
            bottom: [style.margin.bottom],
          }),
        }),
        text: this._formBuilder.group({
          content: [text ? text.content : ''],
          margin: [text ? text.margin : 0],
          verticalAlign: [text ? text.verticalAlign : VerticalAlignType.CENTER],
          backgroundColor: [text ? text.backgroundColor : ''],
        }),
      }),
    );

    this.form = this.widgetFormService.getAttributes as UntypedFormGroup;
  }

  private _addMissingAttributes(widget: ImageWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        height: DEFAULT,
        backgroundColor: '',
        width: DEFAULT,
        borderRadius: 10,
        margin: new Margin(),
      };
    }

    if (!attributes.style.width) {
      attributes.style.width = DEFAULT;
    }

    if (!attributes.style.height) {
      attributes.style.height = DEFAULT;
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new Margin();
    }
  }

  get url(): UntypedFormControl {
    return this.form.get('url') as UntypedFormControl;
  }

  get link(): UntypedFormControl {
    return this.form.get('link') as UntypedFormControl;
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }

  get textBackgroundColor(): UntypedFormControl {
    return this.form.get('text.backgroundColor') as UntypedFormControl;
  }

  get textBackgroundColorValue(): string {
    return this.textBackgroundColor.value;
  }

  set textBackgroundColorValue(color: string) {
    this.textBackgroundColor.setValue(color);
  }

  get height(): UntypedFormControl {
    return this.form.get('style.height') as UntypedFormControl;
  }

  get width(): UntypedFormControl {
    return this.form.get('style.width') as UntypedFormControl;
  }
}
