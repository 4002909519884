import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { ParamsService } from '@bend/store';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { LoyaltyShareService } from './services';
import { WidgetLoyaltyShare } from './types';

@Component({
  selector: 'pop-widget-loyalty-share',
  templateUrl: './loyalty-share.component.html',
  styleUrls: ['./loyalty-share.component.scss'],
})
export class LoyaltyShareComponent implements OnInit, WidgetComponent {
  static widgetName = 'loyalty_share';

  @Input() attributes: WidgetLoyaltyShare;
  isValid: boolean;
  noValidMessage: string;
  shareLink: string;
  shareAvailable: boolean;

  constructor(
    private _warningService: WarningService,
    private _loyalty: LoyaltyShareService,
    private _cdr: ChangeDetectorRef,
    private _dialog: DialogService,
    private _params: ParamsService,
  ) {
    this.shareAvailable = true;
  }

  ngOnInit(): void {
    if (!this.attributes) {
      this.noValidMessage = this._warningService.showWarn(LoyaltyShareComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }
    this.isValid = true;
  }

  copy(): void {
    try {
      navigator.clipboard.writeText(`${this.attributes.message}\n${this.shareLink}`);
    } catch (error) {
      /* empty */
    }
  }

  share(): void {
    this._link
      .pipe(
        tap({
          next: link => {
            this.shareLink = link;
            this.shareAvailable = !!navigator.share;
            this._cdr.detectChanges();
          },
          error: ({ errorCode }) => this._dialog.error({ message: `LOYALTY.${errorCode}` }),
        }),
        switchMap(() => (this.shareAvailable ? this._socialShare() : of(null))),
        first(),
      )
      .subscribe();
  }

  private get _link(): Observable<string> {
    return this._params
      .queryParams(['place'])
      .pipe(switchMap(([place]) => this._loyalty.generateLink(this.attributes.url, place)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _socialShare(): Observable<any> {
    return from(
      navigator.share({
        url: this.shareLink,
        text: this.attributes.message,
        title: 'Smilein',
      }),
    );
  }
}
