<ng-container *ngIf="!isLoading; else preloader">
  <ng-container *ngIf="!isVideo; else videoBlock">
    <img
      loading="lazy"
      [src]="imageUrl || NO_IMAGE"
      [width]="width"
      [height]="height"
      alt="image"
      (error)="handleImageLoadError($event)"
    />
  </ng-container>
  <ng-template #videoBlock>
    <video [width]="width" [height]="height" controls (error)="handleImageLoadError($event)">
      <source [src]="imageUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </ng-template>
  <div *ngIf="showDescription" class="description">
    <small class="description__name" [style.max-width]="setDescriptionWidth">{{ name || 'Unnamed' }}</small>
    <div class="description__details">
      <small>{{ date || '0' | date }}</small>
      <button *ngIf="deleteClicked.observed" class="delete-button"
      mat-icon-button
      [matTooltip]="'STUDIO.SHARED.DELETE' | translate"
      class="delete-button"
      (click)="deleteClicked.emit()"
      >
      <mat-icon>delete_outline</mat-icon>
    </button>
    </div>
  </div>
</ng-container>

<ng-template #preloader>
  <designer-spinner [diameter]="40"></designer-spinner>
</ng-template>
