import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, combineLatest, Observable, of, switchMap, timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { AppType, KitchenType } from '@bend/shared-widgets/src/lib/types';
import { StorageService } from '@bend/storage';
import { OrderItemStatus, ParamsService, SettingsService } from '@bend/store';
import { PrinterCheckService } from '@bend/widgets-new/cart/services';

@Injectable()
export class KioskModeBehaviorService {
  private readonly countMsInSecond = 1000;

  constructor(
    private _settings: SettingsService,
    private _dialog: DialogService,
    private _router: Router,
    private _printer: PrinterCheckService,
    private _params: ParamsService,
    private _storage: StorageService,
  ) {}

  isKioskMode(): Observable<boolean> {
    return this._settings.kioskMode;
  }

  homePageNavigate(): Observable<void> {
    return combineLatest([this._settings.defaultPage, this._params.appSlug]).pipe(
      take(1),
      tap(([page, appSlug]) => this._router.navigate([appSlug, page], { queryParamsHandling: 'merge' })),
      map(() => null),
    );
  }

  clearSession(): Observable<unknown> {
    return this.paramsForNavigate().pipe(
      tap(params => {
        this._storage.clearLocalStorage();
        this.reload(params);
      }),
    );
  }

  execBehaviorIfIsNecessary(status: OrderItemStatus): void {
    const allowStatus = [
      OrderItemStatus.WaitingForPayment,
      OrderItemStatus.Preparing,
      OrderItemStatus.NextForPreparing,
    ];
    if (!allowStatus.includes(status)) {
      return;
    }

    combineLatest([this._settings.kioskMode, this._settings.resetSessionAfterSec]).subscribe(
      ([kioskMode, resetTimerSeconds]) => kioskMode && this.execBehavior(resetTimerSeconds),
    );
  }

  private execBehavior(resetTimerSeconds: number | null): void {
    this._printer
      .print()
      .pipe(
        catchError((error: Error | string) => {
          // TODO: provide to restaurant info about this errors
          if (typeof error === 'string') {
            if (error === 'DEVICE_NOT_FOUND') {
              // eslint-disable-next-line no-console
              console.error('error:: paper is over, or some other problems with printer');
            } else {
              // eslint-disable-next-line no-console
              console.error('unknown error:: ', error);
            }
          } else if (error.message?.includes('Invalid URL')) {
            // eslint-disable-next-line no-console
            console.error('error:: Failed connect to printer, check ip and port');
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          return of(null);
        }),
        switchMap(() => this.startBoardTimer(resetTimerSeconds)),
      )
      .subscribe();

    // close progress dialog without showing success dialog
    this._dialog.closeAll({ force: true });

    // add boardOrderFinished to links query params
    this._router.navigate([], {
      queryParams: { boardOrderFinished: true },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * @description clear storage and hard reload window
   * after redirect to default page
   */
  private startBoardTimer(seconds: number | null): Observable<unknown> {
    return !seconds ? of(null) : timer(seconds * this.countMsInSecond).pipe(switchMap(() => this.clearSession()));
  }

  private paramsForNavigate(): Observable<{ defaultPage: string; appSlug: string; place: string }> {
    return combineLatest([this._settings.defaultPage, this._params.appSlug, this._params.queryParams(['place'])]).pipe(
      map(([defaultPage, appSlug, [place]]) => ({ defaultPage, appSlug, place })),
    );
  }

  private reload({ appSlug, defaultPage, place }: { defaultPage: string; appSlug: string; place: string }): void {
    window.location.href = `${window.location.origin}/${appSlug}/${defaultPage}/?place=${place}`;
  }
}

// TODO refactor , not clear (string| null, boolean, we don't known what it is, and it's sad for a type)
// orderItemStatus, kitchenType, orderNumber, orderDate ?, appType <=> orderMode, isKioskMode
export type BehaviorTapInput = [OrderItemStatus, KitchenType, number, string | null, AppType, boolean];
