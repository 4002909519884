<div class="custom-backdrop" *ngIf="showBackdrop" @appear></div>
<div class="product-details-dialog-content product-details__v2 dialog-content">
  <div class="content-wrapper" #contentWrapper [recommendation-menu-backdrop]="showBackdrop" data-cy="onScrollBox">
    <div
      class="image-wrapper"
      [ngClass]="{ 'image-wrapper--full-photo': fullPhoto, 'image-wrapper--height': product.imageUrl }"
    >
      <button mat-dialog-close class="close-dialog-btn" title="close">
        <lib-icon icon="back" class="close-dialog-btn__icon"></lib-icon>
      </button>
      <img
        *ngIf="product.imageUrl"
        [src]="product.imageUrl"
        width="100%"
        class="product-details-image"
        (click)="makeFullPhoto()"
        alt="product details"
      />
    </div>

    <div class="details-wrapper">
      <div class="product-header">
        <ng-container *ngIf="product.itemTags.length">
          <h3 class="product-name">{{ product.name }}</h3>
          <div class="product-description">{{ product.description }}</div>
          <div class="product-important-details">
            <widgets-old-tag-list [tags]="product.itemTags" [showAll]="true"></widgets-old-tag-list>
            <ng-container *ngIf="!hasFullPriceOptions">
              <span *ngIf="product.priceFinalValue !== 0" class="product-price">
                {{ product.priceFinalValue | currency }}
              </span>
              <span *ngIf="product.priceInitialValue && !isMenu" class="product-price product-price--striked">
                {{ product.priceInitialValue | currency }}
              </span>
            </ng-container>
          </div>
        </ng-container>

        <div *ngIf="!product.itemTags.length" class="product-important-details product-important-details--no-wrap">
          <h3 class="product-name product-name--growed">{{ product.name }}</h3>
          <div class="product-description">{{ product.description }}</div>
          <ng-container *ngIf="!hasFullPriceOptions && !product.children.length">
            <span *ngIf="product.priceFinalValue !== 0" class="product-price">
              {{ product.priceFinalValue | currency }}
            </span>
            <span *ngIf="product.priceInitialValue && !isMenu" class="product-price product-price--striked">
              {{ product.priceInitialValue | currency }}
            </span>
          </ng-container>
        </div>
      </div>

      <div *ngIf="product.children.length" class="option-group">
        <h4 class="option-group__name">
          {{ product.subProductDisplayText ? product.subProductDisplayText : product.name }}
          <span class="option-group-required-msg">
            {{ 'PRODUCT_CATALOG.REQUIRED' | translate }}
          </span>
        </h4>

        <div class="error-message-wrapper" data-cy="errorMessagesWrapper">
          <span *ngIf="showErrors && !isSelectedSubProduct()" class="error-msg">
            {{ 'PRODUCT_CATALOG.PLEASE_SELECT_THE_REQUIRED_OPTIONS' | translate }}
          </span>
        </div>

        <div class="options-wrapper" data-cy="productOptionsWrappers">
          <ng-container *ngFor="let subProduct of product.children">
            <button
              *ngIf="subProduct.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
              class="option"
              type="button"
              [class.option--selected]="subProduct.isSelected"
              (click)="product.available && selectSubProduct(subProduct.id)"
              [disabled]="!subProduct.available"
            >
              <span class="option__details-wrapper">
                <span *ngIf="attributes.styleConfig.productDescriptionStyle === 2" class="styled-radio"></span>
                <div class="option__details">
                  <span class="option__details-name">{{ subProduct.name }}</span>
                  <app-product-status-checkout
                    [product]="subProduct"
                    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                    class="option-status"
                  ></app-product-status-checkout>
                </div>
              </span>
              <span *ngIf="subProduct.priceFinalValue" class="option__price">
                {{ subProduct.priceFinalValue | currency }}
              </span>
            </button>
          </ng-container>
        </div>
      </div>

      <div *ngFor="let item of product.itemOptionGroups; let optionGroupIndex = index" class="option-group">
        <ng-container *ngIf="item.optionGroup.optionGroupSubitems.length">
          <h4 class="option-group__name">
            {{ item.optionGroup.nameToDisplay }}
            <span *ngIf="item.optionGroup.required" class="option-group-required-msg">
              +
              {{ 'PRODUCT_CATALOG.REQUIRED' | translate }}
            </span>
            <div class="option-group-quantity" *ngIf="item.optionGroup.multiple">
              <ng-container
                *ngIf="
                  item.optionGroup.minAllowedOptions > 0 &&
                    item.optionGroup.maxAllowedOptions > 0 &&
                    item.optionGroup.minAllowedOptions === item.optionGroup.maxAllowedOptions;
                  else elseBlock
                "
              >
                <span>
                  {{
                    item.optionGroup.minAllowedOptions === 1
                      ? ('PRODUCT_CATALOG.SELECT_OPTION' | translate)
                      : ('PRODUCT_CATALOG.SELECT_OPTIONS' | translate: { count: item.optionGroup.minAllowedOptions })
                  }}
                </span>
              </ng-container>
              <ng-template #elseBlock>
                <span
                  *ngIf="item.optionGroup.minAllowedOptions && item.optionGroup.minAllowedOptions > 0"
                  class="option-group-quantity__limit"
                >
                  {{ item.optionGroup.minAllowedOptions }} min
                </span>
                <span
                  *ngIf="item.optionGroup.maxAllowedOptions && item.optionGroup.maxAllowedOptions > 0"
                  class="option-group-quantity__limit"
                >
                  {{ item.optionGroup.maxAllowedOptions }} max
                </span>
              </ng-template>
            </div>
          </h4>

          <div class="error-message-wrapper" data-cy="errorMessagesWrapper">
            <span
              *ngIf="item.optionGroup.required && errorMessages.indexOf(item.optionGroup.id) >= 0"
              class="error-msg"
            >
              {{ 'PRODUCT_CATALOG.PLEASE_SELECT_THE_REQUIRED_OPTIONS' | translate }}
            </span>
            <span *ngIf="showErrors && optionGroupQuantityError.includes(item.id)" class="error-msg">
              {{
                item.optionGroup.maxAllowedOptions !== item.optionGroup.minAllowedOptions
                  ? item.optionGroup.minAllowedOptions > 0 && item.optionGroup.maxAllowedOptions > 0
                    ? ('PRODUCT_CATALOG.OPTION_QUANTITY_ERROR'
                      | translate: { min: item.optionGroup.minAllowedOptions, max: item.optionGroup.maxAllowedOptions })
                    : item.optionGroup.minAllowedOptions > 0
                      ? ('PRODUCT_CATALOG.OPTION_MIN_QUANTITY_ERROR'
                        | translate: { min: item.optionGroup.minAllowedOptions })
                      : ('PRODUCT_CATALOG.OPTION_MAX_QUANTITY_ERROR'
                        | translate: { max: item.optionGroup.maxAllowedOptions })
                  : ('PRODUCT_CATALOG.OPTION_FIXED_QUANTITY_ERROR'
                    | translate: { count: item.optionGroup.minAllowedOptions })
              }}
            </span>
          </div>

          <div class="options-wrapper" data-cy="productOptionsWrappers">
            <ng-container *ngIf="!item.optionGroup.allowMultipleSameOptions; else enabledQuantity">
              <ng-container *ngFor="let option of item.optionGroup.optionGroupSubitems; let optionIndex = index">
                <button
                  *ngIf="
                    option.item.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock
                  "
                  class="option"
                  type="button"
                  [class.option--selected]="option.item.isSelected"
                  (click)="product.available && selectOption(option.item, item.optionGroup)"
                  [disabled]="!option.item.available"
                  [attr.data-cy]="'optionGroupOption-' + optionGroupIndex + '-' + optionIndex"
                >
                  <span class="option__name">
                    <div>
                      <div>{{ option.item.name }}</div>
                      <app-product-status-checkout
                        [product]="option.item"
                        [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                        class="option-status"
                      >
                        <span *ngIf="attributes.styleConfig.productDescriptionStyle === 2">
                          <span *ngIf="item.optionGroup.multiple; else showRadio" class="styled-checkbox"></span>
                          <ng-template #showRadio>
                            <span class="styled-radio"></span>
                          </ng-template>
                        </span>
                      </app-product-status-checkout>
                    </div>
                  </span>
                  <span class="option__price">
                    {{ option.item.priceFinalValue ? '+' + (option.item.priceFinalValue | currency) : '' }}
                  </span>
                </button>
              </ng-container>
            </ng-container>

            <ng-template #enabledQuantity>
              <ng-container *ngFor="let option of item.optionGroup.optionGroupSubitems; let optionIndex = index">
                <div
                  *ngIf="
                    option.item.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock
                  "
                  class="option"
                  [attr.data-cy]="'optionGroupOption-' + optionGroupIndex + '-' + optionIndex"
                >
                  <div class="option__details">
                    <span class="option__details-name">{{ option.item.name }}</span>
                    <app-product-status-checkout
                      [product]="option.item"
                      [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                    ></app-product-status-checkout>
                  </div>

                  <div class="option__modify-quantity">
                    <span class="option__price option__price--margin-right">
                      {{ option.item.priceFinalValue ? '+' + (option.item.priceFinalValue | currency) : '' }}
                    </span>

                    <app-product-quantity-buttons *ngIf="product.available" [product]="option.item">
                      <div class="option__quantity-buttons">
                        <app-button-quantity
                          (click)="decreasesOptionQuantity(option.item)"
                          [negative]="true"
                        ></app-button-quantity>
                        <span class="option__quantity">{{ option.item.quantityMultiplier }}</span>
                        <app-button-quantity
                          (click)="addOptionQuantity(option.item, item.optionGroup)"
                          [attr.data-cy]="'optionGroupOptionIncrease-' + optionGroupIndex + '-' + optionIndex"
                        ></app-button-quantity>
                      </div>
                    </app-product-quantity-buttons>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="!attributes.isConsultingMode">
        <div class="comment-wrapper" *ngIf="attributes.showKitchenComment && !isMenu">
          <pop-comment-to-kitchen
            (onChanged)="onCommentChanged($event)"
            [mainColor]="attributes.mainColor"
            [isCommentToKitchenRequired]="isCommentToKitchenRequired"
            (scrollToEmitter)="scrollToAddCommentToKitchen($event)"
            [styleConfig]="attributes.styleConfig"
          ></pop-comment-to-kitchen>
          <div *ngIf="showErrors && isCommentToKitchenInvalid" class="error-msg">
            {{ 'PRODUCT_CATALOG.COMMENT_TO_KITCHEN_REQUIRED' | translate }}
          </div>
        </div>
        <div *ngIf="!isMenu || (isMenu && category.allowQuantity)" class="quantity-controls-wrapper">
          <button
            type="button"
            (click)="decrementQuantity()"
            class="quantity-btn quantity-btn--minus"
            [style.borderColor]="attributes.mainColor"
            [style.color]="attributes.mainColor"
          >
            -
          </button>
          <span class="quantity">{{ quantity ?? 1 }}</span>
          <button
            type="button"
            (click)="incrementQuantity()"
            class="quantity-btn quantity-btn--plus"
            [style.borderColor]="attributes.mainColor"
            [style.color]="attributes.mainColor"
          >
            +
          </button>
        </div>
      </ng-container>

      <div class="recommendations" *ngIf="product">
        <app-recommendations
          [productId]="product.id"
          [parentItemAvailability]="product.available"
          [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
        ></app-recommendations>
      </div>
    </div>
  </div>
  <div class="fake-footer" #fakeFooterEl data-cy="fixed-footer"></div>
  <div #fixedEl class="fixed-footer">
    <lib-icon icon="scrollIndicator" class="scroll-indicator"></lib-icon>
    <ng-container
      *ngIf="!attributes.isConsultingMode && !product.isReadOnly && !attributes.isReadOnly && !isReadOnlyMenu"
    >
      <app-product-status-checkout
        [product]="product"
        [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
        class="product-status"
      >
        <button
          class="pick-btn"
          [style.backgroundColor]="attributes.mainColor"
          [style.color]="attributes.textColor"
          (click)="pick()"
          data-cy="pickBtn"
        >
          {{ isMenu ? ('PRODUCT_CATALOG.SELECT' | translate) : ('PRODUCT_CATALOG.ADD_TO_CART' | translate) }}
          <span *ngIf="total !== 0">- {{ total | currency }}</span>
          <pop-widget-spinner *ngIf="isLoading" [width]="35" [height]="25"></pop-widget-spinner>
        </button>
      </app-product-status-checkout>
    </ng-container>
    <ng-container *ngIf="!attributes.isConsultingMode">
      <ng-container *ngIf="isKitchenClosed$ | async as isClosed; else orderNotAvailable">
        <h3>
          {{ 'PRODUCT_CATALOG.BUSINESS_LOCATION_FOR_ITEM_NOT_WORKING_NOW' | translate }}
        </h3>
      </ng-container>
      <ng-template #orderNotAvailable>
        <h3 *ngIf="product.isReadOnly">
          {{ 'CART.ORDER_NOT_AVAILABLE' | translate }}
        </h3>
      </ng-template>
    </ng-container>
  </div>
</div>
