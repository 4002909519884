import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Option,resetEntitiesQuantity, sortByKey } from '../shared'; // TODO: review
import { actions } from './option.actions';
import { State } from './option.type';

export const adapter: EntityAdapter<Option> = createEntityAdapter<Option>({
  sortComparer: sortByKey<Option>()('order'),
});

export const initialState: State = adapter.getInitialState({});

const optionReducer = createReducer(
  initialState,
  on(actions.addOptions, (state, { entities }) => adapter.upsertMany(entities, state)),

  on(actions.changeQuantity, (state, { by, quantity }) =>
    adapter.updateOne(
      {
        id: by,
        changes: { quantity },
      },
      state,
    ),
  ),

  on(actions.resetQuantity, state => adapter.updateMany(resetEntitiesQuantity<Option>(state.entities), state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return optionReducer(state, action);
}
