import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserSocketUpdatesService } from '../user-socket-update/user-socket-update.service';
import { storeKey } from './settings.config';
import { SettingsEffects } from './settings.effects';
import { SettingsHttpService } from './settings.http.service';
import { reducer } from './settings.reducer';
import { SettingsService } from './settings.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([SettingsEffects])],
  providers: [SettingsHttpService, UserSocketUpdatesService],
})
export class SettingsStoreModule {
  static forRoot(): ModuleWithProviders<SettingsStoreModule> {
    return {
      ngModule: SettingsStoreModule,
      providers: [SettingsService],
    };
  }
}
