import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { StorageService } from '@bend/storage';

@Component({
  selector: 'pop-widget-person-email',
  templateUrl: './person-email.component.html',
  styleUrls: ['./person-email.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailInputComponent implements OnInit, OnDestroy {
  @Input() emailControl: UntypedFormControl;
  @Input() me: any;
  @Input() mainColor: string;
  @Input() editable = true;
  icon: string;
  isCompleted: boolean;
  private _subscription: Subscription;

  constructor(private _localStorage: StorageService) {
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this._initName();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  editInput(): void {
    this.isCompleted = false;
    this.emailControl.enable();
  }

  private _initName(): void {
    const email = this._localStorage.email || this.me.email;

    if (email) {
      this.emailControl.setValue(email);
      if (this.editable) {
        this.isCompleted = true;
        this.emailControl.disable();
      }
    }
  }
}
