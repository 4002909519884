import { NgControl } from '@angular/forms';

export abstract class InputGeneric {
  ngControl?: NgControl;

  get isShowError(): boolean {
    if (this.notExistControl) return false;

    const { dirty, touched } = this.ngControl;

    return this.invalid ? dirty || touched : false;
  }

  get invalid(): boolean {
    return this.isExistControl ? this.ngControl.invalid : false;
  }

  get notExistControl(): boolean {
    return !this.isExistControl;
  }

  get isExistControl(): boolean {
    return !!this.ngControl && !!this.ngControl.control;
  }
}
