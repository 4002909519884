import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { InputErrorsDirective } from './input-errors.directive';

@NgModule({
  declarations: [InputErrorsDirective],
  imports: [CommonModule, TranslateModule],
  exports: [InputErrorsDirective],
})
export class InputErrorsModule {}
