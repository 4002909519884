import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { InputGeneric } from '../../../helpers';

type SelectType = string | number | object;

@Component({
  selector: 'app-select',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SelectBoxComponent extends InputGeneric implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('input') input: ElementRef;
  @Input() options: SelectType[];
  @Input() showError: boolean;
  @Input() keyValue: string;
  isDisabled: boolean;
  control: UntypedFormControl;
  value: SelectType;

  private _checkValidation: boolean;
  private _subscription: Subscription;

  @Input() compareWith = (option1: any, option2: any): boolean => option1 === option2;
  onChange: (value: SelectType) => void = () => undefined;
  onTouched: () => void = () => undefined;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _cdr: ChangeDetectorRef,
  ) {
    super();

    if (this.ngControl) this.ngControl.valueAccessor = this;

    this.control = new UntypedFormControl();
    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    this._subscription.add(this.control.valueChanges.subscribe(value => this._setValue(value as SelectType)));

    if (this.isExistControl) {
      this._subscription.add(this.ngControl.control.valueChanges.subscribe(() => this._cdr.detectChanges()));
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  enable(): void {
    if (this.isExistControl) this.ngControl.control.enable();
  }

  writeValue(value: SelectType): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: SelectType) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.notExistControl) return;

    // to validate an input, it must be removed from disabled.
    // When it becomes disabled comes a false event  and you have to skip this event
    if (!isDisabled && this._checkValidation) return;

    isDisabled ? this._disableState() : this._enableState();

    this._cdr.detectChanges();
  }

  private _setValue(value: SelectType): void {
    if (value === undefined) return;

    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  private _enableState(): void {
    this.control.enable({ emitEvent: false });

    // focuses only when the previous state was true
    if (this.isDisabled) {
      this.input.nativeElement.focus();
    }

    this.isDisabled = false;
  }

  private _disableState(): void {
    if (this._checkValidation) {
      this._checkValidation = false;
      return;
    }

    // https://github.com/angular/angular/issues/11447
    // enable for check validation
    this._checkValidation = true;
    this.ngControl.control.enable({ emitEvent: false });

    const { invalid } = this.ngControl.control;

    if (invalid) {
      // if the item is invalid make input enabled
      this.ngControl.control.reset();
    } else {
      this.ngControl.control.disable({ emitEvent: false });
      this.control.disable({ emitEvent: false });

      this.isDisabled = true;
    }
  }
}
