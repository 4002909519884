import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, State, Store } from '@ngrx/store';
import { iif, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

// TODO: move all this part in catalog module
import { actions as categoryActions } from '../category/category.actions';
import { actions as itemActions } from '../item/item.actions';
import { actions as menuActions } from '../menu/menu.actions';
import { actions as menuCategoryActions } from '../menu-category/menu-category.actions';
import { actions as menuItemActions } from '../menu-item/menu-item.actions';
import { actions as optionActions } from '../option/option.actions';
import { actions as optionGroupActions } from '../option-group/option-group.actions';
import { Catalog } from '../shared';
import { actions as subCategoryActions } from '../sub-category/sub-category.actions';
import { actions as subItemActions } from '../sub-item/sub-item.actions';
import { actions as catalogActions } from './catalog.actions';
import { CatalogHttpService } from './catalog.http.service';
import * as selectors from './catalog.selectors';

@Injectable()
export class CatalogEffects {
  loadCatalog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(catalogActions.getCatalog),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedBy))),
      mergeMap(([{ by }, isLoadedBy]) =>
        iif(
          () => isLoadedBy,
          of(catalogActions.getCatalogIsLoaded()),
          this._catalogHttp.get().pipe(
            mergeMap(
              ({
                categories,
                subCategories,
                items,
                options,
                menus,
                menuItems,
                subItems,
                optionGroups,
                menuCategories,
                ...catalog
              }) => [
                categoryActions.addCategories({ entities: categories }),
                subCategoryActions.addSubCategories({ entities: subCategories }),
                itemActions.addItems({ entities: items }),
                subItemActions.addSubItems({ entities: subItems }),
                optionGroupActions.addOptionGroups({ entities: optionGroups }),
                optionActions.addOptions({ entities: options }),
                menuActions.addMenus({ entities: menus }),
                menuCategoryActions.addMenuCategories({ entities: menuCategories }),
                menuItemActions.addMenuItems({ entities: menuItems }),
                catalogActions.getCatalogSuccess({ by, catalog }),
              ],
            ),
            catchError(({ message }) => of(catalogActions.getCatalogError({ error: message }))),
          ),
        ),
      ),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _catalogHttp: CatalogHttpService,
    private _store: Store<State<Catalog>>,
  ) {}
}
