import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { SubItem } from '../shared';
import { actions } from './sub-item.actions';
import { State } from './sub-item.type';

export const adapter: EntityAdapter<SubItem> = createEntityAdapter<SubItem>();

export const initialState: State = adapter.getInitialState({});

const subItemReducer = createReducer(
  initialState,
  on(actions.addSubItems, (state, { entities }) => adapter.addMany(entities, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return subItemReducer(state, action);
}
