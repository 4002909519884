import { createAction, props } from '@ngrx/store';

import { User } from '@designer-store/users';

export const usersActions = {
  getUsers: createAction(
    '[Users] Get Users',
    props<{ pagination: { offset: number; limit: number }; search: string; appSlug?: string }>(),
  ),
  getUsersSuccess: createAction('[Users] Get Users Success', props<{ docs: User[]; count: number }>()),
  getUsersError: createAction('[Users] Get Users Error', props<{ errorCode: string }>()),

  addUser: createAction('[Users] Add Admin User', props<{ user: User; appSlug?: string }>()),
  addUserSuccess: createAction('[Users] Add Admin User Success', props<{ user: User }>()),
  addUserError: createAction('[Users] Add Admin User Error', props<{ errorCode }>()),

  deleteUser: createAction('[Users] Delete Admin User', props<{ id: string; appSlug: string }>()),
  deleteUserSuccess: createAction('[Users] Delete Admin User Success', props<{ id: string }>()),
  deleteUserError: createAction('[Users] Delete Admin User Error', props<{ errorCode }>()),

  getUser: createAction('[Users] Get User Details', props<{ id: string; appSlug: string }>()),
  getUserSuccess: createAction('[Users] Get User Details Success', props<{ user: User }>()),
  getUserError: createAction('[Users] Get User Details Error', props<{ errorCode }>()),

  updateUser: createAction('[Users] Update Admin User', props<{ user: User; appSlug: string }>()),
  updateUserSuccess: createAction('[Users] Update Admin User Success', props<{ user: User }>()),
  updateUserError: createAction('[Users] Update Admin User Error', props<{ errorCode }>()),
};
