import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBackComponent {
  @Output() clicked: EventEmitter<any>;

  constructor() {
    this.clicked = new EventEmitter<any>();
  }

  goBack(): void {
    this.clicked.emit();
  }
}
