import { Component, Input } from '@angular/core';

import { PopProductTag } from '../../product-catalog';

@Component({
  selector: 'widgets-old-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() tag: { tag: PopProductTag };
}
