<div
  class="product-item product-item__cc"
  [class.product-item__step-by-step]="stepByStepMenu"
  [class.product-item--align-end]="!product.imageUrl"
  [class.product-item--selected]="product.isSelected"
  [style.border-color]="selectedColor | async"
  data-cy="productItem"
>
  <div class="product-item__image" *ngIf="product.imageUrl" (click)="click(true, false)">
    <img [src]="product.imageUrl" alt="product photo" height="100" />
  </div>
  <div class="product-item__content">
    <div class="product-item__main-details" (click)="click(true, true)">
      <p class="product__title">{{ product.name }}</p>
      <p class="product__description" *ngIf="product.description">{{ product.description }}</p>
      <div *ngIf="product.itemTags?.length" class="product__tags">
        <widgets-old-tag-list [tags]="product.itemTags"></widgets-old-tag-list>
      </div>
    </div>
    <div class="product-item__price">
      <div class="price" (click)="click(true, true)" data-cy="productItemPrice">
        <div *ngIf="product.priceInitialValue; else elsePrice" class="product-item__initial-price price__board">
          <span>
            {{ product.priceInitialValue | currency }}
          </span>
          {{ product.priceFinalValue | currency }}
        </div>
        <ng-template #elsePrice>
          <span *ngIf="product.priceFinalValue" class="price__board">{{ product.priceFinalValue | currency }}</span>
        </ng-template>
      </div>
      <ng-container *ngIf="!isConsultingMode && !isReadOnly && !product.isReadOnly">
        <app-product-status-checkout
          [product]="product"
          data-cy="productItemStatus"
          [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
        >
          <button *ngIf="!isReadOnly && isMenu" class="product-item__btn" (click)="click(!product.isSelected, false)">
            <lib-icon
              *ngIf="product.isSelected"
              class="menu-check menu-check--selected"
              icon="addedSquare"
              [style.fill]="primaryColor$ | async"
            ></lib-icon>
            <lib-icon
              *ngIf="!product.isSelected"
              class="menu-check"
              icon="add"
              [style.fill]="primaryColor$ | async"
            ></lib-icon>
          </button>
          <button
            *ngIf="!isMenu && !isReadOnly && loadingProductId !== product.id"
            class="add-to-cart__btn"
            data-cy="productBtn"
            (click)="click(false)"
            [style.color]="secondColor$ | async"
            [style.background-color]="primaryColor$ | async"
          >
            {{ 'PRODUCT_CATALOG.ADD_TO_CART' | translate }}
          </button>
        </app-product-status-checkout>
      </ng-container>
      <pop-widget-spinner
        *ngIf="loadingProductId === product.id && !isReadOnly"
        class="add-to-cart__loader"
        [width]="25"
        [height]="25"
        [color]="secondColor"
      ></pop-widget-spinner>
    </div>
  </div>
</div>
