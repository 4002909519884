import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './item.config';
import { ItemEffects } from './item.effects';
import { ItemHttpService } from './item.http.service';
import { reducer } from './item.reducer';
import { ItemService } from './item.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([ItemEffects])],
  providers: [ItemService, ItemHttpService],
})
export class ItemStoreModule {}
