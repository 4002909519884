import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { TwitterProfileWidget } from './twitter-profile';

@Component({
  selector: 'pop-widget-edit-twitter-profile',
  templateUrl: './twitter-profile.component.html',
  styleUrls: ['./twitter-profile.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditTwitterProfileComponent extends WidgetBaseComponent<TwitterProfileWidget> implements OnInit {
  static widgetName = 'twitter_profile';
  isValid: boolean;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    if (!this.widget || !this.widget.attributes) {
      return;
    }
    this.isValid = true;

    this._initForm();
  }

  get url(): UntypedFormControl {
    return this.form.get('url') as UntypedFormControl;
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get twitterId(): UntypedFormControl {
    return this.form.get('twitterId') as UntypedFormControl;
  }

  get image(): UntypedFormControl {
    return this.form.get('image') as UntypedFormControl;
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        url: [this.widget.attributes.url, Validators.required],
        image: [this.widget.attributes.image],
        name: [this.widget.attributes.name, Validators.required],
        twitterId: [this.widget.attributes.twitterId, Validators.required],
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
