import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackComponent } from '../components/snack/snack.component';
@Injectable({ providedIn: 'root' })
export class SnackBarService {
  private _durationInMilliseconds = 3000;
  constructor(private _snackBar: MatSnackBar) {}
  showSuccessMessage(message: string): void {
    this._openSnackBar(message, 'success');
  }

  showErrorMessage(message: string): void {
    this._openSnackBar(message, 'error');
  }

  private _openSnackBar(message: string, style: 'success' | 'error'): void {
    this._snackBar.openFromComponent(SnackComponent, {
      duration: this._durationInMilliseconds,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['snack-bar', `snack-bar--${style}`],
      data: message,
    });
  }
}
