import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResolverStoreModule } from '../resolver';
import { SettingsStoreModule } from '../settings';
import { storeKey } from './widgets.config';
import { WidgetsEffects } from './widgets.effects';
import { WidgetsHttpService } from './widgets.http.service';
import { metaReducers, reducer } from './widgets.reducer';

@NgModule({
  imports: [
    SettingsStoreModule,
    ResolverStoreModule,
    StoreModule.forFeature(storeKey, reducer, { metaReducers }),
    EffectsModule.forFeature([WidgetsEffects]),
  ],
  providers: [WidgetsHttpService],
})
export class WidgetsStoreModule {}
