import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Injectable()
export class ViewportService {
  get changeToMobile(): Observable<boolean> {
    return this.isMobile.pipe(filter<boolean>(Boolean));
  }

  get changeToDesktop(): Observable<boolean> {
    return this.isDesktop.pipe(filter<boolean>(Boolean));
  }

  get changeToBoard(): Observable<boolean> {
    return this.isBoard.pipe(filter<boolean>(Boolean));
  }

  get isMobile(): Observable<boolean> {
    return this._mediaChanges('screen and (max-width: 1024px)');
  }

  get isDesktop(): Observable<boolean> {
    return this._mediaChanges('(min-width: 1024px) and (orientation: landscape)');
  }

  get isBoard(): Observable<boolean> {
    return this._mediaChanges('(min-width: 1080px) and (orientation: portrait)');
  }

  private _mediaChanges(query: string): Observable<boolean> {
    const mediaQuery = window.matchMedia(query);

    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map(list => list.matches),
      distinctUntilChanged(),
    );
  }
}
