<div class="catalog-wrapper">
  <div
    scrolledTo
    #scrolledToElement="scrolledTo"
    class="slider-wrapper-container"
    [style.height.px]="styleConfig.categoriesStyle === 3 && categoriesSliderEl.clientHeight"
    *ngIf="productCategories.length > 1"
  >
    <div
      #categoriesSliderEl
      id="categoriesNavbar"
      class="slider-wrapper"
      [class.slider-wrapper--elegant]="styleConfig.categoryStyleType === 2"
      [class.slider-wrapper__stick-top]="
        styleConfig.categoriesStyle === 2 || (scrolledToElement.reached && styleConfig.categoriesStyle === 3)
      "
      [style.top.px]="
        styleConfig.categoriesStyle === 2 || (scrolledToElement.reached && styleConfig.categoriesStyle === 3)
          ? catalogsNavHeight
          : null
      "
      [class.slider-wrapper__stick-top__zero]="
        (styleConfig.categoriesStyle === 2 && numberOfCatalogs <= 1) ||
        (scrolledToElement.reached && styleConfig.categoriesStyle === 3 && numberOfCatalogs <= 1)
      "
    >
      <div
        class="product-itemCategories-slider"
        [class.product-itemCategories-slider__stick-top]="
          styleConfig.categoriesStyle === 2 || (scrolledToElement.reached && styleConfig.categoriesStyle === 3)
        "
        [class.product-itemCategories-slider__stack]="styleConfig.categoryAlignType === 2"
        [class.product-widget__v2]="categoryStyleType > 1"
      >
        <div class="slider-margin" [class.slider-margin__stack]="styleConfig.categoryAlignType === 2" #contentWrapperEl>
          <span
            *ngFor="let category of productCategories; let index = index"
            (click)="selectCatalog(index)"
            class="product-category-slide"
            data-cy="productCategoryBtn"
            [class.product-category-slide--active]="currentCategory.id === category.id"
            [style.borderColor]="(isMobile$ | async) && currentCategory.id === category.id ? mainColor : undefined"
            [class.ng-default-category]="category.isDefault"
            [style.backgroundColor]="
              (isMobile$ | async) && styleConfig.categoryStyleType === 1 ? getStyleBg(category.id) : ''
            "
            [attr.selectedCategory]="currentCategory.id === category.id ? 1 : 0"
            [style.borderColor]="
              styleConfig.categoryStyleType === categoryStyleTypes.Style2 && currentCategory.id === category.id
                ? mainColor
                : null
            "
            #categoriesRef
          >
            <span
              [class]="'product-category-slide__name no-img' + ' ' + styleType"
              [style.color]="
                styleConfig.categoryStyleType === categoryStyleTypes.Style2 && currentCategory.id === category.id
                  ? mainColor
                  : null
              "
              [style.fontWeight]="
                styleConfig.categoryStyleType === categoryStyleTypes.Style2 && currentCategory.id === category.id
                  ? 500
                  : 400
              "
            >
              {{ category.name }}
            </span>
            <pop-widget-spinner
              *ngIf="loadingCategoryId === category.id"
              class="loading-spinner-wrapper"
              [width]="styleType ? 25 : 35"
              [height]="styleType ? 25 : 35"
              [color]="secondColor"
            ></pop-widget-spinner>
          </span>
        </div>
      </div>
      <span
        #scrollIndicatorEnd
        *ngIf="
          isCategoriesOverflown(contentWrapperEl) &&
          (isMobile$ | async) &&
          (styleConfig.categoryAlignType === 1 || !styleConfig.categoryAlignType)
        "
        class="scroll-indicator__category--end"
        height="15"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -44.499 28.043 15" width="20">
          <path
            d="M1.927-44.172a1.137 1.137 0 0 0-1.597 0 1.11 1.11 0 0 0 0 1.58l12.895 12.766c.44.436 1.154.436 1.595 0l12.893-12.765a1.11 1.11 0 0 0 0-1.58 1.137 1.137 0 0 0-1.596 0L14.022-32.53z"
            [attr.fill]="mainColor"
            fill-opacity=".941"
          />
        </svg>
      </span>
    </div>
  </div>

  <div class="products-wrapper">
    <div
      *ngIf="currentCategoryDescription"
      class="current-category-description"
      [style.color]="secondaryForeground$ | async"
    >
      {{ currentCategoryDescription }}
    </div>

    <div class="subcategories-section">
      <ng-container *ngIf="alacarteItems$ | async as items">
        <div *ngIf="items.length" [class]="'subcategory subcategory--uncategorized-products ' + styleType">
          <div class="side-by-side__container">
            <ng-container *ngFor="let product of items">
              <div
                *ngIf="product.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
                class="product-item-wrapper product-item-wrapper__cc"
              >
                <pop-widget-product-catalog-item
                  [product]="product"
                  [currency]="currency"
                  (loadProductDetailsEmitter)="loadProductDetails(product, $event)"
                  [mainColor]="mainColor"
                  [secondColor]="secondColor"
                  [isReadOnly]="isReadOnly"
                  [isConsultingMode]="isConsultingMode"
                  [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                  [loadingProductId]="loadingProductId"
                  [styleType]="styleType"
                  [styleNumber]="styleNumber"
                  [forceShowProductDescription]="forceShowProductDescription"
                ></pop-widget-product-catalog-item>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div *ngFor="let subcategory of productSubcategories" [class]="'subcategory' + ' ' + styleType">
        <pop-widget-product-catalog-subcategory
          [subcategoryName]="subcategory.name"
          [subcategoryDescription]="subcategory.description"
          [mainColor]="mainColor"
          [secondColor]="secondColor"
          [styleType]="styleType"
          [styleNumber]="styleNumber"
          *ngIf="subcategory.items.length"
        ></pop-widget-product-catalog-subcategory>
        <div class="subcategory-details">
          <div *ngIf="subcategory.imageUrl" class="subcategory-image-wrapper">
            <img [src]="subcategory?.imageUrl" alt="subcategory image" width="100%" class="subcategory-image" />
          </div>
          <div class="side-by-side__container">
            <ng-container *ngFor="let product of subcategory.items">
              <div
                *ngIf="product.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
                class="product-item-wrapper product-item-wrapper__cc"
              >
                <pop-widget-product-catalog-item
                  [product]="product"
                  [currency]="currency"
                  (loadProductDetailsEmitter)="loadProductDetails(product, $event, subcategory.imageUrl)"
                  [mainColor]="mainColor"
                  [secondColor]="secondColor"
                  [isReadOnly]="isReadOnly"
                  [isConsultingMode]="isConsultingMode"
                  [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                  [loadingProductId]="loadingProductId"
                  [styleType]="styleType"
                  [forceShowProductDescription]="forceShowProductDescription"
                  [styleNumber]="styleNumber"
                ></pop-widget-product-catalog-item>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
