export enum StorageKeys {
  isDisabledCookieFunctional = 'isDisabledCookieFunctional',
  isDisabledCookieAnalytics = 'isDisabledCookieAnalytics',
  isDisabledCookieGoogleAnalytics = 'isDisabledCookieGoogleAnalytics',
  isDisabledCookieHotjar = 'isDisabledCookieHotjar',
  isDisabledCookieAccept = 'isDisabledCookieAccept',

  isDisabledToken = 'isDisabledToken',
  isDisabledRefreshToken = 'isDisabledRefreshToken',
  isDisabledIsRegistered = 'isDisabledIsRegistered',
  isDisabledUserName = 'isDisabledUserName',
  isDisabledPhone = 'isDisabledPhone',
  isDisabledPrefix = 'isDisabledPrefix',
  isDisabledEmail = 'isDisabledEmail',
}

export enum StorageFunctionalKeys {
  userName = 'userName',
  phone = 'phone',
  prefix = 'prefix',
  email = 'email',
  confirmAuth = 'confirmAuth',
}

export interface Storage {
  isDisabledCookieFunctional: boolean;
  isDisabledCookieAnalytics: boolean;
  isDisabledCookieGoogleAnalytics: boolean;
  isDisabledCookieHotjar: boolean;
  isDisabledCookieAccept: boolean;

  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
  getToken: (appSlug: string) => string;
  setToken: (appSlug: string, token: string) => void;
  getRefreshToken: (appSlug: string) => string;
  setRefreshToken: (appSlug: string, refreshToken: string) => void;
  getIsRegistered: (appSlug: string) => boolean;
  setIsRegistered: (appSlug: string, isRegistered: boolean) => void;
  isDisabledToken: boolean;
  isDisabledRefreshToken: boolean;
  isDisabledIsRegistered: boolean;

  userName: string;
  isDisabledUserName: boolean;

  phone: string;
  isDisabledPhone: boolean;

  prefix: string;
  isDisabledPrefix: boolean;

  email: string;
  isDisabledEmail: boolean;

  // TODO: check how works disabled fields
  // and add disabled functionality
  firstName: string;
  IsDisabledFirstName: boolean;

  lastName: string;
  isDisabledLastName: boolean;

  gender: string;
  isDisabledGender: boolean;

  dateOfBirth: string;
  isDisabledDateOfBirth: boolean;

  birthPlace: string;
  isDisabledBirthPlace: boolean;

  confirmAuth: boolean;

  shopIdentifier: string;
  isDisabledShopIdentifier: boolean;
}
