import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageDTO, PageModel } from './page.models';

export const pageActions = createActionGroup({
  source: 'page',
  events: {
    'Get Pages': emptyProps(),
    'Get Pages Success': props<{ pages: PageModel[] }>(),
    'Get Pages Error': props<{ error: string }>(),

    'Get Page': emptyProps(),
    'Get Page Success': props<{ page: PageModel }>(),
    'Get Page Error': props<{ error: string }>(),

    'Create Page': props<{ page: PageDTO }>(),
    'Create Page Success': props<{ page: PageModel }>(),
    'Create Page Error': props<{ error: string }>(),

    'Update Page': props<{ page: PageDTO }>(),
    'Update Page Success': props<{ page: PageModel }>(),
    'Update Page Error': props<{ error: string }>(),

    'Delete Page': props<{ pageId: string }>(),
    'Delete Page Success': props<{ pageId: string }>(),
    'Delete Page Error': props<{ pageId: string; error: string }>(),

    'Duplicate Page': props<{ name: string }>(),
    'Duplicate Page Success': props<{ page: PageModel }>(),
    'Duplicate Page Error': props<{ error: string }>(),

    'Refresh Preview': emptyProps(),

    'Reset': emptyProps(),
  },
});
