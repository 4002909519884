import {
  ComponentRef,
  createNgModuleRef,
  Injectable,
  Injector,
  NgModuleRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WidgetNgModuleType } from './types';
import { ScriptLoader, Status } from './types/script-loader.type';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
  private _styles: { [key: string]: ScriptLoader };
  private _scripts: { [key: string]: ScriptLoader };
  private _modules: { [key: string]: NgModuleRef<unknown> };

  constructor() {
    this._styles = {};
    this._scripts = {};
    this._modules = {};
  }

  /**
   * load a single or multiple scripts
   */
  load(scripts: string): Promise<ScriptLoader>;
  load(...scripts: string[]): Promise<ScriptLoader[]>;
  load(...scripts: string[]): Promise<ScriptLoader | ScriptLoader[]> {
    if (!Array.isArray(scripts)) return this._loadSingleScript(scripts);

    const promises: Promise<ScriptLoader>[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach(script => promises.push(this._loadSingleScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  /**
   * load a single or multiple style
   */
  loadStyle(styleUri: string): Promise<ScriptLoader>;
  loadStyle(...styleUri: string[]): Promise<ScriptLoader[]>;
  loadStyle(...styleUri: string[]): Promise<ScriptLoader | ScriptLoader[]> {
    if (!Array.isArray(styleUri)) return this._loadSingleStyle(styleUri);

    const promises: Promise<ScriptLoader>[] = [];
    // push the returned promise of each loadStyle call
    styleUri.forEach(style => promises.push(this._loadSingleStyle(style)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  /**
   * load angular module
   */
  loadModule<C>(
    module: Promise<unknown>,
    moduleName: string,
    componentName: string,
    injector: Injector,
    viewContainerRef: ViewContainerRef,
  ): Observable<ComponentRef<C>> {
    return from(module.then(m => m[moduleName])).pipe(
      map((typeOfModule: WidgetNgModuleType<C>) => {
        /**
         * get module reference from saved modules references
         */
        let moduleRef = this._modules[moduleName];
        /**
         * if module reference not found then create new module reference
         * and save it in the saved modules
         */
        if (!moduleRef) {
          moduleRef = createNgModuleRef<C>(typeOfModule as unknown as Type<C>, injector);
          this._modules[moduleName] = moduleRef;
        }
        /**
         * get the widget components from the module
         */
        const components = typeOfModule.widgetComponents;
        /**
         * get the selected component from the components
         */
        const component = components[componentName];
        /**
         * create reference of the component
         */
        const componentRef = viewContainerRef.createComponent(component, {
          injector,
          ngModuleRef: moduleRef,
        });

        return componentRef;
      }),
    );
  }

  // load the script
  private _loadSingleScript(src: string): Promise<ScriptLoader> {
    return new Promise(resolve => {
      /**
       * resolve if already loaded
       */
      if (this._scripts[src]?.loaded) {
        resolve(this._scripts[src]);
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;

        script.onload = (): void => {
          this._scripts[src] = { script: src, loaded: true, status: Status.Loaded };
          resolve(this._scripts[src]);
        };

        script.onerror = (): void => {
          this._scripts[src] = { script: src, loaded: false, status: Status.NotLoaded };
          resolve(this._scripts[src]);
        };
        /**
         *  finally append the script tag in the DOM
         */
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  // load the single style
  private _loadSingleStyle(src: string): Promise<ScriptLoader> {
    return new Promise(resolve => {
      /**
       * resolve if already loaded
       */
      if (this._styles[src]?.loaded) {
        resolve(this._styles[src]);
      } else {
        // load style
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = src;

        style.onload = (): void => {
          this._styles[src] = { script: src, loaded: true, status: Status.Loaded };
          resolve(this._styles[src]);
        };

        style.onerror = (): void => {
          this._styles[src] = { script: src, loaded: false, status: Status.NotLoaded };
          resolve(this._styles[src]);
        };
        /**
         *  finally append the style tag in the DOM
         */
        document.getElementsByTagName('head')[0].appendChild(style);
      }
    });
  }
}
