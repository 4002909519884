import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarBuilder } from './snackbar.builder';

@Injectable({
  providedIn: 'root',
})
export class SnackBarBuilderService {
  constructor(private readonly snackbar: MatSnackBar) {}

  getBuilder(): SnackBarBuilder {
    return new SnackBarBuilder(this.snackbar);
  }
}
