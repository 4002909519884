<h1 md-dialog-title>
  <span>Duplicate catalog</span>
</h1>
<form (submit)="onSubmit()" [formGroup]="form" #htmlForm="ngForm" class="form">
  <div md-dialog-content>
    <div class="form-row-cols-wrapper">
      <div class="form-row-cols">
        <div class="form-row-cols__col flex-grow-1 form-row-cols__col--max-50-chars">
          <div
            class="form-row-cols__col__content"
            [class.input--error]="htmlForm.submitted && form.get('name').invalid"
          >
            <label for="name" class="form-label">
              New catalog Name
              <span class="form-label-required">(required)</span>
            </label>
            <input id="name" type="text" formControlName="name" class="form-input" maxlength="50" />
            <ng-container *ngIf="htmlForm.submitted">
              <span *ngIf="form.get('name').hasError('required')" class="form-error-msg">
                Please enter a name for catalog
              </span>
              <span *ngIf="form.get('name').hasError('maxlength')" class="form-error-msg">
                Catalog name must not be longer than 50 characters
              </span>
            </ng-container>
            <span class="form-field-info">Limited to 50 characters</span>
          </div>
        </div>
      </div>
      <br />
      <div>
        <input id="moveCatalog" formControlName="moveCatalog" type="checkbox" class="form-input-checkbox" />
        <label for="moveCatalog" class="form-label form-label--checkbox" (click)="changeValidatorStatus()">
          Move to another app
        </label>
      </div>
      <br />
      <div class="form-row-cols">
        <div class="form-row-cols__col flex-grow-1" *ngIf="moveCatalogToApp">
          <div
            class="form-row-cols__col__content"
            [class.input--error]="moveCatalogToApp && form.get('appSlug').invalid && htmlForm.submitted"
          >
            <label for="appSlug" class="form-label">
              App Slug
              <span class="form-label-required">(required)</span>
            </label>
            <input id="appSlug" type="text" formControlName="appSlug" class="form-input" />
            <ng-container *ngIf="moveCatalogToApp && form.get('appSlug').invalid && htmlForm.submitted">
              <span *ngIf="form.get('appSlug').hasError('required')" class="form-error-msg">Please enter a slug</span>
              <span *ngIf="form.get('appSlug').hasError('pattern')" class="form-error-msg">
                Slug is invalid. Only lowercase letters, digits and hyphens can be used.
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="options">
        <div class="col_content ng-untouched ng-pristine ng-valid">
          <div>
            <input id="copyTvas" formControlName="copyTvas" type="checkbox" class="form-input-checkbox" />
            <label for="copyTvas" class="form-label form-label--checkbox">Duplicate Tvas</label>
          </div>
          <div>
            <input id="copyTags" formControlName="copyTags" type="checkbox" class="form-input-checkbox" />
            <label for="copyTags" class="form-label form-label--checkbox">Duplicate Tags</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer" md-dialog-actions>
    <button class="btn app-button-color" type="submit">
      Duplicate
      <pop-widget-spinner *ngIf="isSaving" [width]="25" [height]="13"></pop-widget-spinner>
    </button>
    <button class="btn app-button-color" mat-dialog-close>Cancel</button>
  </div>
</form>
