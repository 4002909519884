<div class="third-panel third-panel--buttons">
  <div class="btn-preview app-button-color">
    <a [href]="pageUrlUser" target="_blank">{{ 'STUDIO.PAGES.PAGE.PREVIEW' | translate }}</a>
  </div>
  <button class="btn-view-code app-button-color" (click)="showQrCode()">
    <span>{{ 'STUDIO.PAGES.PAGE.VIEW_CODE' | translate }}</span>
  </button>
</div>
<div class="widgets-container" [style.backgroundColor]="backgroundColor">
  <div id="preview-container" class="fake-wrapper">
    <ng-template appPageHost></ng-template>
  </div>
  <div #widgetsContainerFakeFooter class="fake-footer"></div>
</div>
