<div
  *ngIf="isValid; else elseBlock"
  class="widget-link"
  [ngClass]="{
    'widget-link__v2': attributes.style.displayType === displayTypes.STYLE2,
    'widget-link__v2-full-width': attributes.style.width === 'full',
    ' tablet-size': !attributes.isEditMode
  }"
  [style.marginTop.px]="attributes.style.margin.top"
  [style.marginBottom.px]="attributes.style.margin.bottom"
  [style.marginLeft.px]="widthStyle.marginLeft"
  [style.marginRight.px]="widthStyle.marginRight"
>
  <div
    class="widget-link-content"
    [ngClass]="{
      'widget-link-content__v2': attributes.style.displayType === 'style2',
      'widget-link-content__padding-right': attributes.style.showArrow
    }"
    [style.width.px]="widthStyle.width"
    [style.height.px]="attributes.style.height"
    [style.backgroundColor]="attributes.style.backgroundColor || attributes.mainColor"
    [style.borderRadius.px]="attributes.style.borderRadius"
    [style.borderWidth.px]="attributes.style.borderWidth"
    [style.borderColor]="attributes.style.borderColor"
    (click)="navigate()"
  >
    <div *ngIf="attributes.style.iconUrl" class="cell">
      <img
        alt=""
        [src]="attributes.style.iconUrl"
        [style.maxHeight.px]="imageHeight"
        [style.paddingLeft.px]="attributes.style.borderRadius || 5"
      />
    </div>
    <div
      class="title"
      [style.fontSize.px]="attributes.style.fontSize"
      [style.color]="attributes.style.textColor"
      [style.textAlign]="attributes.style.textAlign"
    >
      {{ attributes.title }}
    </div>
    <div *ngIf="attributes.style.showArrow && attributes.style.displayType !== 'style2'; else slimArrow" class="cell">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="100%"
        [style.maxWidth.px]="imageHeight"
        [style.maxHeight.px]="imageHeight"
        [style.fill]="attributes.style.textColor"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </div>
    <ng-template #slimArrow>
      <lib-icon *ngIf="attributes.style.showArrow" class="link-arrow" icon="slim-arrow-right"></lib-icon>
    </ng-template>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes.isEditMode" class="widget-link">{{ noValidMessage }}</div>
</ng-template>
