<div
  *ngIf="isValid; else elseBlock"
  class="widget-custom"
  [class.bounds]="attributes.style?.bounds"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
>
  <iframe
    [src]="url"
    [style.width.%]="attributes.style?.fullWidth ? 100 : ''"
    [style.borderRadius.px]="attributes.style?.borderRadius"
    [style.height.px]="attributes.style?.height"
  ></iframe>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-custom">{{ noValidMessage }}</div>
</ng-template>
