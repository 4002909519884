import { TimeZone } from '@designer-shared/types';

export const timeZoneConfig: TimeZone[] = [
  { city: 'Pacific/Niue', delay: -11, cityWithDelay: 'GMT-11:00 (Pacific/Niue)' },
  { city: 'Pacific/Tahiti', delay: -10, cityWithDelay: 'GMT-10:00 (Pacific/Tahiti)' },
  { city: 'America/Anchorage', delay: -9, cityWithDelay: 'GMT-9:00 (America/Anchorage)' },
  { city: 'America/Los_Angeles', delay: -8, cityWithDelay: 'GMT-8:00 (America/Los_Angeles)' },
  { city: 'America/Denver', delay: -7, cityWithDelay: 'GMT-7:00 (America/Denver)' },
  { city: 'America/Chicago', delay: -6, cityWithDelay: 'GMT-6:00 (America/Chicago)' },
  { city: 'America/New_York', delay: -5, cityWithDelay: 'GMT-5:00 (America/New_York)' },
  { city: 'America/Aruba', delay: -4, cityWithDelay: 'GMT-4:00 (America/Aruba)' },
  { city: 'America/Argentina/Buenos_Aires', delay: -3, cityWithDelay: 'GMT-3:00 (America/Argentina/Buenos_Aires)' },
  { city: 'America/Noronha', delay: -2, cityWithDelay: 'GMT-2:00 (America/Noronha)' },
  { city: 'Atlantic/Azores', delay: -1, cityWithDelay: 'GMT-1:00 (Atlantic/Azores)' },
  { city: 'Europe/London', delay: 0, cityWithDelay: 'GMT+0 (Europe/London)' },
  { city: 'Europe/Paris', delay: 1, cityWithDelay: 'GMT+1 (Europe/Paris)' },
  { city: 'Europe/Athens', delay: 2, cityWithDelay: 'GMT+2 (Europe/Athens)' },
  { city: 'Europe/Moscow', delay: 3, cityWithDelay: 'GMT+3 (Europe/Moscow)' },
  { city: 'Asia/Kabul', delay: 4, cityWithDelay: 'GMT+4 (Asia/Kabul)' },
  { city: 'Asia/Ashgabat', delay: 5, cityWithDelay: 'GMT+5 (Asia/Ashgabat)' },
  { city: 'Asia/Dhaka', delay: 6, cityWithDelay: 'GMT+6 (Asia/Dhaka)' },
  { city: 'Asia/Bangkok', delay: 7, cityWithDelay: 'GMT+7 (Asia/Bangkok)' },
  { city: 'Asia/Irkutsk', delay: 8, cityWithDelay: 'GMT+8 (Asia/Irkutsk)' },
  { city: 'Asia/Tokyo', delay: 9, cityWithDelay: 'GMT+9 (Asia/Tokyo)' },
  { city: 'Australia/Port_Moresby', delay: 10, cityWithDelay: 'GMT+10 (Australia/Port_Moresby)' },
  { city: 'Pacific/Guadalcanal', delay: 11, cityWithDelay: 'GMT+11 (Pacific/Guadalcanal)' },
  { city: 'Pacific/Wallis', delay: 12, cityWithDelay: 'GMT+12 (Pacific/Wallis)' },
];
