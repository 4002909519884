import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Settings } from '../shared';
import { settingsActions as actions } from './settings.actions';
import { State } from './settings.type';

export const adapter: EntityAdapter<Settings> = createEntityAdapter<Settings>();

export const initialState: State = adapter.getInitialState({
  error: null,
  isLoading: false,
  isLoadedBy: [],
  selectedNextId: null,
  selectedId: null,
});

const widgetReducer = createReducer(
  initialState,

  on(actions.getSettings, actions.getSettingsByPlace, (state, { by }) => ({
    ...state,
    isLoading: true,
    selectedNextId: by,
  })),

  on(actions.getSettingsIsLoaded, actions.getSettingsByPlaceIsLoaded, state => ({
    ...state,
    isLoading: false,
  })),

  on(actions.getSettingsError, actions.getSettingsByPlaceError, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(actions.getSettingsSuccess, actions.getSettingsByPlaceSuccess, (state, { settings, by }) =>
    adapter.addOne(settings, {
      ...state,
      isLoading: false,
      isLoadedBy: [...state.isLoadedBy, by],
      selectedId: by,
    }),
  ),

  /**
   * update settings with working hours
   */
  on(
    actions.updateSettingsSuccess,
    actions.updateSettingsByPlaceSuccess,
    (state, { settings: { id, workingHours, placeStatus, happyHours } }) =>
      adapter.updateOne({ id, changes: { workingHours, placeStatus, happyHours } }, state),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return widgetReducer(state, action);
}
