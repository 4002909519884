<dialog-background class="background"></dialog-background>
<div class="confirm">
  <dialog-icon-wrap>
    <lib-icon icon="dialogInfo" class="confirm__icon"></lib-icon>
  </dialog-icon-wrap>
  <dialog-content-wrap>
    <dialog-title *ngIf="title$ | async" [innerHTML]="title$ | async"></dialog-title>
    <dialog-description [innerHTML]="description$ | async"></dialog-description>
  </dialog-content-wrap>
  <div class="confirm__buttons">
    <dialog-button (click)="close(false)" class="confirm__button confirm__button--red">
      {{ 'CANCEL' | translate }}
    </dialog-button>
    <dialog-button (click)="close(true)" class="confirm__button">
      {{ 'OKEY' | translate }}
    </dialog-button>
  </div>
</div>
