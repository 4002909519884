import { BackgroundContentType } from '@designer-store/page/page.models';

import { Widget } from './widget.type';

export interface PageFont {
  name: string;
  url: string;
}

export interface Page {
  id: string;
  name: string;
  type: string;
  font: PageFont;
  background: {
    content: string;
    type: BackgroundContentType;
  };
  widgets: string[];
}

export interface PageApi extends Omit<Page, 'widgets'> {
  widgets: Widget<any>[];
}

export enum LoyaltyParams {
  Share = 'share',
  Invited = 'invited',
  ShareCode = 'share-code',
}

export enum GuardQueryParams {
  HasOrdersToday = 'has-orders-today',
}

export enum CatalogQueryParams {
  AddItem = 'addItem',
  AddMenu = 'addMenu',
  CatalogId = 'catalogId',
}

export enum CatalogParams {
  TabId = 'tabId',
}

export enum LoyaltyInvitedStatus {
  Valid = 'valid',
  NotFound = 'not-found',
  AlreadyUsed = 'already-used',
  HasOrdersAlready = 'had-orders-already',
  InvitedNotConnected = 'invited-not-connected',
  SamePhoneWithSponsor = 'same-phone-with-sponsor',
  InProgressByAnotherInvited = 'in-progress-by-another-invited',
  Revoked = 'revoked',
  NotUsableYet = 'not-usable-yet',
}
