<div class="header">
  <a class="header__link" [routerLink]="['/']"></a>

  <div class="form-box">
    <div class="form">
      <ng-content></ng-content>
      <div class="form__element">
        <div class="icon">
          <lib-icon class="icon__img" icon="userHeader"></lib-icon>
        </div>
        <div class="icon icon--clickable" (click)="toggleMenu()">
          <lib-icon class="icon__img" icon="arrowHeader"></lib-icon>
        </div>
      </div>
      <div class="form__element lang">
        <designer-language-select></designer-language-select>
      </div>
    </div>
    <div class="menu" *ngIf="menuToggled">
      <div class="menu__item" (click)="onChangePassword()">
        <span class="menu__text">{{ 'STUDIO.HOME.CHANGE_PASSWORD' | translate }}</span>
        <lib-icon class="menu__icon" icon="changePasswordApps"></lib-icon>
      </div>
      <div class="menu__item" (click)="onLogOut()">
        <span class="menu__text">{{ 'STUDIO.HOME.LOG_OUT' | translate }}</span>
        <lib-icon class="menu__icon" icon="logoutHeader"></lib-icon>
      </div>
    </div>
  </div>
</div>
