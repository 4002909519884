import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './settings.config';
import { adapter } from './settings.reducer';
import { State } from './settings.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, state => state);
export const total = createSelector(stateSelector, selectTotal);

export const selectedId = createSelector(stateSelector, state => state.selectedId);
export const isLoadedBy = createSelector(stateSelector, state => state.isLoadedBy);
export const settings = createSelector(entities, selectedId, (settingsEntities, id) => settingsEntities[id]);

export const error = createSelector(stateSelector, state => state.error);
export const isLoading = createSelector(stateSelector, state => state.isLoading);

export const general = createSelector(settings, allSettings => allSettings && allSettings.general);
export const behavior = createSelector(settings, allSettings => allSettings.behavior);
export const pop = createSelector(settings, allSettings => allSettings.pop);
export const tips = createSelector(settings, allSettings => allSettings.tips);
export const workingHours = createSelector(settings, allSettings => allSettings.workingHours);
export const happyHours = createSelector(settings, allSettings => allSettings.happyHours);
export const googleAnalytics = createSelector(settings, allSettings => allSettings.googleAnalytics);
export const hotjarAnalytics = createSelector(settings, allSettings => allSettings.hotjarAnalytics);
export const topBar = createSelector(settings, allSettings => allSettings.topBar);
export const bottomBar = createSelector(settings, allSettings => allSettings.bottomBar);
export const cart = createSelector(settings, allSettings => allSettings.cart);
export const promoCodes = createSelector(settings, allSettings => allSettings.promoCode);
export const productCatalog = createSelector(settings, allSettings => allSettings.productCatalog);
export const gdpr = createSelector(settings, allSettings => allSettings.gdpr);
export const capacity = createSelector(settings, allSettings => allSettings.capacity);
export const pos = createSelector(settings, allSettings => allSettings.pos);
export const sw = createSelector(settings, allSettings => allSettings.sw);
export const delivery = createSelector(settings, allSettings => allSettings.delivery);
export const takeAway = createSelector(settings, allSettings => allSettings.takeAway);
export const paymentMethods = createSelector(settings, allSettings => allSettings.paymentMethods);
export const config = createSelector(settings, allSettings => allSettings.config);
export const languages = createSelector(settings, allSettings => allSettings && allSettings.languages);
