export interface Style {
  backgroundColor?: string;
  borderRadius?: number;
  buttonBackgroundColor?: string;
  color?: string;
  textColor: string;
  fullWidth?: boolean;
  margin: Margin;
  hasSlideBounds: boolean;
  imageBorderRadius: boolean;
}

export interface Image {
  url: string;
  alt: string;
}

export class Margin {
  top = 10;
  bottom = 10;
}

export interface Page {
  _id?: string;
  name: string;
  type: string;
  directLinkSettings?: DirectLinkSetting[];
}

export interface DirectLinkSetting {
  name: string;
  value: string;
  url: string;
  language: string;
  isDefault: boolean;
}

export enum TextAlignWidget {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export type EmojiPickerPosition = 'left' | 'right' | 'bottom';
