<div
  *ngIf="isValid; else elseBlock"
  class="widget-action-call"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.marginRight.px]="attributes.style?.fullWidth && 0"
  [style.marginLeft.px]="attributes.style?.fullWidth && 0"
  [style.borderRadius.px]="attributes.style?.borderRadius"
  [style.backgroundColor]="buttonColor"
  (click)="handleClick()"
>
  <ng-container [ngSwitch]="attributes.style.displayType">
    <div *ngSwitchCase="'style2'" class="content-v2">
      <span *ngIf="attributes.title" class="action-call-title-v2" [innerHTML]="attributes.title"></span>
      <div class="action-call-icon-wrapper-v2">
        <img
          *ngIf="attributes.icon; else defaultIcon"
          class="action-call-icon-v2"
          [src]="attributes.icon"
          alt="action call icon"
        />
      </div>
    </div>
    <div *ngSwitchDefault class="content">
      <span *ngIf="attributes.title" class="action-call-title" [innerHTML]="attributes.title"></span>
      <div class="action-call-icon-wrapper">
        <img
          *ngIf="attributes.icon; else defaultIcon"
          class="action-call-icon"
          [src]="attributes.icon"
          alt="action call icon"
        />
      </div>
    </div>
  </ng-container>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-action-call">{{ noValidMessage }}</div>
</ng-template>
<ng-template #defaultIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 9.26 9.26">
    <circle r="4.63" cy="4.625" cx="4.634" fill="none" />
    <circle r="4.617" cy="4.63" cx="4.63" fill="#fff" fill-rule="evenodd" />
    <path
      [style.fill]="buttonColor"
      d="M3.58 6.85c.311.312.73.477 1.166.477.914 0 1.662-.748 1.662-1.661V2.799a.286.286 0 0 0-.292-.291.286.286 0 0 0-.292.291v1.614h-.291V2.41a.286.286 0 0 0-.292-.29.287.287 0 0 0-.292.291V4.22h-.29V2.216a.286.286 0 0 0-.292-.292.286.286 0 0 0-.292.292v2.1h-.292V2.507a.286.286 0 0 0-.29-.292.286.286 0 0 0-.292.292v3.119l-.875-.875a.282.282 0 0 0-.408 0 .282.282 0 0 0 0 .408z"
    />
  </svg>
</ng-template>
