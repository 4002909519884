import { WeekDays } from '@designer-shared/types';

export const weekDaysConfig: WeekDays[] = [
  {
    dayNr: 1,
    dayName: 'Monday',
  },
  {
    dayNr: 2,
    dayName: 'Tuesday',
  },
  {
    dayNr: 3,
    dayName: 'Wednesday',
  },
  {
    dayNr: 4,
    dayName: 'Thursday',
  },
  {
    dayNr: 5,
    dayName: 'Friday',
  },
  {
    dayNr: 6,
    dayName: 'Saturday',
  },
  {
    dayNr: 0,
    dayName: 'Sunday',
  },
];

export interface WeekDaysSpecialPrices {
  id: number;
  label: string;
}
export const weekDaysSpecialPricesConfig: WeekDaysSpecialPrices[] = [
  { id: 0, label: 'STUDIO.WEEKDAYS.SUNDAY' },
  { id: 1, label: 'STUDIO.WEEKDAYS.MONDAY' },
  { id: 2, label: 'STUDIO.WEEKDAYS.TUESDAY' },
  { id: 3, label: 'STUDIO.WEEKDAYS.WEDNESDAY' },
  { id: 4, label: 'STUDIO.WEEKDAYS.THURSDAY' },
  { id: 5, label: 'STUDIO.WEEKDAYS.FRIDAY' },
  { id: 6, label: 'STUDIO.WEEKDAYS.SATURDAY' },
];
