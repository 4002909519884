import { PrinterType } from 'projects/store/src/lib/settings/settings-deprecated.type';

import { ApplicationType, CollectType } from '@bend/store-shared';

import { PopApplication } from '@designer-shared/types';

export const defaultAppConfig: PopApplication = {
  id: null,
  name: null,
  timezone: '',
  period: {
    startAt: '',
    endAt: '',
  },
  place: {
    city: '',
  },
  social: {
    instagram: '',
    twitter: '',
    linkedin: '',
    facebook: '',
  },
  colors: {
    primary: null,
    secondary: '#ffffff',
  },
  settings: {
    allowedLanguages: ['en'],
    language: 'en',
    catalog: {
      isReadOnly: false,
      allowOrderForOthers: true,
      syncPricesWithPos: true,
    },
    config: {
      mode: 'dev',
    },
    cart: {
      isShared: true,
      payBefore: false,
      allowPickup: false,
      showTableName: true,
      showMoveMessage: true,
      askForPickupName: true,
      collectTypes: [CollectType.EatIn],
      closeAfterMin: 10,
    },
    order: {
      enableSound: null,
    },
    receipt: {
      header: null,
      footer: null,
    },
    printer: {
      type: PrinterType.NONE,
      data: null,
    },
    timezone: null,
    currency: {
      code: 'eur',
      symbol: '€',
    },
    discount: {
      hasDiscount: false,
      discountEmailRequired: false,
      chartRequired: false,
      chartUrl: '',
      hasPromoCode: false,
      hasCoupon: false,
      showAllPromoCodes: false,
    },
    pop: {
      tokenRequired: null,
      allowReadOnly: null,
      readOnlyDisplayMsg: null,
      noAccessDisplayMsg: null,
      tokenExpiresIn: '10',
    },
    ui: {
      styles: {
        general: null,
        widgets: {
          product_teaser: {
            productDescriptionStyle: null,
          },
          product_catalog: {
            hasSearch: false,
            productDescriptionStyle: null,
            stepByStepCategories: false,
            stepByStepMenus: false,
            style: null,
            categoriesStyle: null,
            menuType: null,
            menuStyleType: null,
            menuItemStyleType: null,
            categoryStyleType: null,
            categoryAlignType: null,
            fullWidth: false,
          },
        },
      },
    },
  },
  cover: {
    alt: '',
    url: '',
  },
  tags: [''],
  organizer: [''],
  url: '',
  language: 'en',
  description: '',
  merchantId: '',
  page: '',
  slug: '',
  appType: '',
  applicationType: ApplicationType.Demo,
};
