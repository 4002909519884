import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

import { LanguagesConfig } from '@bend/store-shared';

import {
  selectAppSlugParam,
  selectAreaIdParam,
  selectLanguageQueryParam,
  selectLocationIdParam,
  selectPageIdParam,
  selectPaymentConfigIdParam,
  selectQueryParams,
  selectRouteParams,
} from './router.selectors';
import { DesignerParam, DesignerQueryParam } from './router.types';

@Injectable({ providedIn: 'root' })
export class DesignerRouterService {
  language: Observable<LanguagesConfig | undefined>;
  languageChanges: Observable<LanguagesConfig | undefined>;

  constructor(private _store: Store<RouterReducerState>) {
    this.language = this._language;
    this.languageChanges = this._languageChanges;
  }

  get appSlug(): Observable<string | undefined> {
    return this.appSlugChanges.pipe(first());
  }

  get appSlugChanges(): Observable<string | undefined> {
    return this._store.select(selectAppSlugParam);
  }

  get locationId(): Observable<string | undefined> {
    return this.locationIdChanges.pipe(first());
  }

  get locationIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectLocationIdParam));
  }

  get areaId(): Observable<string | undefined> {
    return this.areaIdChanges.pipe(first());
  }

  get areaIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectAreaIdParam));
  }

  get pageId(): Observable<string | undefined> {
    return this.pageIdChanges.pipe(first());
  }

  get pageIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectPageIdParam));
  }

  get paymentConfigId(): Observable<string | undefined> {
    return this.paymentConfigIdChanges.pipe(first());
  }

  get paymentConfigIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectPaymentConfigIdParam));
  }

  params(): Observable<Params>;
  params(params: DesignerParam[]): Observable<(string | undefined)[]>;
  params(params?: DesignerParam[]): Observable<Params | (string | undefined)[]> {
    return this.paramsChanges(params).pipe(first());
  }

  paramsChanges(): Observable<Params>;
  paramsChanges(params: DesignerParam[]): Observable<(string | undefined)[]>;
  paramsChanges(params?: DesignerParam[]): Observable<Params | (string | undefined)[]> {
    return this._genericParams(selectRouteParams, params);
  }

  queryParams(): Observable<Params>;
  queryParams<T = string>(params?: DesignerQueryParam[]): Observable<(T | undefined)[]>;
  queryParams<T = string>(params?: DesignerQueryParam[]): Observable<Params | (T | undefined)[]> {
    return this.queryParamsChanges(params).pipe(first());
  }

  queryParamsChanges(): Observable<Params>;
  queryParamsChanges<T = string>(params: DesignerQueryParam[]): Observable<(T | undefined)[]>;
  queryParamsChanges<T = string>(params?: DesignerQueryParam[]): Observable<Params | (T | undefined)[]> {
    return this._genericParams(selectQueryParams, params);
  }

  private _genericParams(
    selector: MemoizedSelector<RouterReducerState, Params>,
    params?: (DesignerParam | DesignerQueryParam)[],
  ): Observable<Params | (string | undefined)[]> {
    return this._store.pipe(
      select(selector),
      map(allParams => (params ? params.map(param => allParams[param]) : allParams)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );
  }

  private get _language(): Observable<LanguagesConfig | undefined> {
    return this._languageChanges.pipe(first());
  }

  private get _languageChanges(): Observable<LanguagesConfig | undefined> {
    return this._store.pipe(select(selectLanguageQueryParam)) as Observable<LanguagesConfig | undefined>;
  }
}
