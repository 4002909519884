import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import { CatalogItem, CatalogsCategoriesResponse, PopPackage, ProductItem } from './product-catalog';

@Injectable()
export class ProductCatalogService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getCatalogs(): Observable<CatalogItem[]> {
    return this._api.catalogs('v1').pipe(switchMap(api => this._http.get<CatalogItem[]>(api)));
  }

  getProducts(catalogId: number): Observable<ProductItem[]> {
    return this._api
      .items('v1')
      .pipe(switchMap(api => this._http.get<ProductItem[]>(api, { params: { catalogId: catalogId.toString() } })));
  }

  getAppCategories(): Observable<CatalogsCategoriesResponse> {
    return this._api.catalogCategories('v1').pipe(switchMap(api => this._http.get<CatalogsCategoriesResponse>(api)));
  }

  getPackages(catalogId: number): Observable<PopPackage[]> {
    const options = {
      params: {
        catalogId: catalogId.toString(),
      },
    };

    return this._api.menus('v1').pipe(switchMap(api => this._http.get<PopPackage[]>(api, options)));
  }
}
