import { ErrorHandler, Injectable } from '@angular/core';

import { Storage, StorageFunctionalKeys, StorageKeys } from './storage.type';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StorageService extends Storage {}

@Injectable()
export class StorageService {
  constructor(private _error: ErrorHandler) {
    this._createPropertiesForCookieConfig();
    this._createPropertiesForFunctionalCookie();
  }

  setItem(key: string, value: string): void {
    if (this.isDisabledToken) {
      this._remove(this._cookieKey(`dyn_${key}`));
      return;
    }

    this._set(this._cookieKey(`dyn_${key}`), value);
  }

  getItem(key: string): string | undefined {
    if (this.isDisabledToken) {
      this._remove(this._cookieKey(`dyn_${key}`));
      return undefined;
    }

    return this._get(this._cookieKey(`dyn_${key}`));
  }

  setToken(appSlug: string, token: string): void {
    if (this.isDisabledToken) {
      this._remove(this._cookieKey(`token_${appSlug}`));
      return;
    }

    this._set(this._cookieKey(`token_${appSlug}`), token);
  }

  getToken(appSlug: string): string | undefined {
    if (this.isDisabledToken) {
      this._remove(this._cookieKey(`token_${appSlug}`));
      return undefined;
    }

    return this._get(this._cookieKey(`token_${appSlug}`));
  }

  setRefreshToken(appSlug: string, refreshToken: string): void {
    if (this.isDisabledRefreshToken) {
      this._remove(this._cookieKey(`refresh_token_${appSlug}`));
      return;
    }

    this._set(this._cookieKey(`refresh_token_${appSlug}`), refreshToken);
  }

  getRefreshToken(appSlug: string): string | undefined {
    if (this.isDisabledRefreshToken) {
      this._remove(this._cookieKey(`refresh_token_${appSlug}`));
      return undefined;
    }

    return this._get(this._cookieKey(`refresh_token_${appSlug}`));
  }

  setIsRegistered(appSlug: string, isRegistered: boolean): void {
    if (this.getIsRegistered(appSlug) !== isRegistered) {
      this._error.handleError({
        action: 'CHANGE_STORAGE',
        token: this.getToken(appSlug),
        refreshToken: this.getRefreshToken(appSlug),
        prevIsRegistered: this.getIsRegistered(appSlug),
        currentIsRegistered: isRegistered,
        isDisabledIsRegistered: this.isDisabledIsRegistered,
      });
    }
    if (this.isDisabledIsRegistered) {
      this._remove(this._cookieKey(`is_registered_${appSlug}`));
      return;
    }

    this._set(this._cookieKey(`is_registered_${appSlug}`), isRegistered);
  }

  getIsRegistered(appSlug: string): boolean {
    if (this.isDisabledIsRegistered) {
      this._remove(this._cookieKey(`is_registered_${appSlug}`));
      return false;
    }

    return !!this._get(this._cookieKey(`is_registered_${appSlug}`));
  }

  clearLocalStorage(): void {
    window.localStorage.clear();
  }

  private _createPropertiesForFunctionalCookie(): void {
    Object.values(StorageFunctionalKeys).forEach((key: string) => {
      Object.defineProperty(this, key, {
        set: value => {
          if (this.hasOwnProperty(this._propertyConfigKey(key))) {
            this._remove(this._cookieKey(key));
            return;
          }

          this._set(this._cookieKey(key), value);
        },
        get: () => {
          if (this.hasOwnProperty(this._propertyConfigKey(key))) {
            this._remove(this._cookieKey(key));
            return undefined;
          }

          return this._get(this._cookieKey(key));
        },
      });
    });
  }

  private _createPropertiesForCookieConfig(): void {
    Object.values(StorageKeys).forEach(key => {
      Object.defineProperty(this, key, {
        set: value => this._set(this._cookieKey(key), value),
        get: () => !!this._get(this._cookieKey(key)),
      });
    });
  }

  private _cookieKey(key: string): string {
    return `k_${key.replace(/([A-Z])/g, '_$1')}`.toLocaleLowerCase();
  }

  private _propertyConfigKey(key: string): string {
    return `isDisabled${key[0].toUpperCase()}${key.slice(1)}`;
  }

  private _set(key: string, payload: any): void {
    const body: string = JSON.stringify(payload);
    window.localStorage.setItem(key, body);
  }

  private _get<T>(key: string): T | string {
    const body: string = window.localStorage.getItem(key);

    try {
      return JSON.parse(body) as T;
    } catch (e) {
      return body;
    }
  }

  private _remove(key: string): void {
    window.localStorage.removeItem(key);
  }
}
