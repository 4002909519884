import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { scan, takeWhile, tap } from 'rxjs/operators';

import { toggleX, toggleY } from '@bend/animations';
import { SettingsService } from '@bend/store';

import { TimerService } from '../../services';

@Component({
  selector: 'app-resend-code',
  templateUrl: './resend-code.component.html',
  styleUrls: ['./resend-code.component.scss'],
  animations: [toggleY, toggleX],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendCodeComponent {
  @Input() isLoading: boolean;
  @Output() clicked: EventEmitter<any>;
  bgPrimary$: Observable<string>;
  showTimer$: Observable<boolean>;
  time$: Observable<number>;

  constructor(private _settings: SettingsService, private _timer: TimerService) {
    this.clicked = new EventEmitter<any>();

    this.bgPrimary$ = this._settings.colors.primary;
    this.showTimer$ = this._timer.isShow$.pipe(tap(isShow => isShow && (this.time$ = this._time)));
  }

  onClick(): void {
    this.clicked.emit();
  }

  private get _time(): Observable<number> {
    return timer(0, 1000).pipe(
      scan(acc => acc - 1, 30),
      takeWhile(time => time >= 0),
      tap(time => time === 0 && this._timer.stop()),
    );
  }
}
