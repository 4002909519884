<h2 mat-dialog-title class="text">{{ 'STUDIO.SHARED.DELETE' | translate }}</h2>

<mat-dialog-content>
  <p class="description text" [innerHtml]="message | translate"></p>
</mat-dialog-content>

<mat-dialog-actions class="buttons">
  <designer-stroked-button class="buttons_cancel" color="warn" mat-dialog-close>
    {{ 'STUDIO.SHARED.CANCEL' | translate }}
  </designer-stroked-button>
  <designer-stroked-button color="primary" [mat-dialog-close]="true">
    {{ 'STUDIO.SHARED.YES' | translate }}
  </designer-stroked-button>
</mat-dialog-actions>
