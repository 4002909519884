export const enum OtherStatus {
  ProgressDelay = 'progress_delay',
  Capacity = 'capacity',
  Capacity0Min = 'capacity_0_min',
  CapacityOutOfTime = 'capacity_out_of_time',
  ScheduledForPreparing0Min = 'scheduled_for_preparing_0_min',
}

export const enum LocationOtherStatus {
  ScheduledForPreparing0Min = 'scheduled_for_preparing_0_min',
}
