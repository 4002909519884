import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './recommendations-designer.actions';
import { RecommendationsDesignerHttpService } from './recommendations-designer.http.service';
import * as selectors from './recommendations-designer.selectors';
import { State } from './recommendations-designer.type';

@Injectable()
export class RecommendationsDesignerEffects {
  constructor(
    private _httpService: RecommendationsDesignerHttpService,
    private _store: Store<State>,
    private _actions$: Actions,
  ) {}

  getRecommendations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getRecommendations),
      withLatestFrom(this._store.select(selectors.isLoadedGroups)),
      switchMap(([{ id }, isLoadedGroups]) =>
        [...isLoadedGroups].includes(id)
          ? of(actions.getRecommendationsIsLoaded())
          : this._httpService.getRecommendations(id).pipe(
              map(recommendations => actions.getRecommendationsSuccess({ recommendations, recommendationGroupId: id })),
              catchError(({ errorCode }) => of(actions.getRecommendationsError({ errorCode }))),
            ),
      ),
    ),
  );
}
