export interface SmartWifiSettings {
  enabled: boolean;
  backgroundColor: string;
  title: SwTitle;
  message: SwMessage;
  button: SwButton;
}

export interface SwUiElementGeneric {
  font: string;
  color: string;
}

export interface SwButton extends SwUiElementGeneric {
  text: string;
  backgroundColor: string;
}

export type SwTitle = SwUiElementGeneric;
export type SwMessage = SwUiElementGeneric;
