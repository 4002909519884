import { CurrencyPipe as NgCurrencyPipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService } from '@bend/store';
import { Currency } from '@bend/store-shared';

@Pipe({
  name: 'currency',
  pure: true,

  standalone: true,
})
export class CurrencyPipe extends NgCurrencyPipe implements PipeTransform, OnDestroy {
  private _currency: Currency;
  private _subscription: Subscription;

  constructor(private _settings: SettingsService) {
    super('en-US');

    this._subscription = this._settings.currency.subscribe(currency => (this._currency = currency));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  transform(value: number | string | null | undefined): string | null;
  transform(value: null | undefined): null;
  transform(value: number | string): string | null {
    const integer = Number(value);

    if (!this._currency || isNaN(integer)) {
      return null;
    }

    const price = this._transformToFloat(integer);

    const { currencyCode, display } = this._currency;
    return super.transform(price, currencyCode, display);
  }

  private _transformToFloat(integer: number): number {
    return integer / 100;
  }
}
