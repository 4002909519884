import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

import { InvitedEligibility } from '../types';

@Injectable()
export class TextService {
  constructor(private _api: ApiVisitorService, private _http: HttpClient) {}

  invitedEligibility(code: string): Observable<InvitedEligibility> {
    return this._api.invitedEligibility('v1', code).pipe(switchMap(api => this._http.get<InvitedEligibility>(api)));
  }
}
