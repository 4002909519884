import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ModalSelected, SelectItem } from '../../../../../../../shared-widgets/src/lib/types';
import { ModalSelectRadioComponent } from '../../components';

interface ModalSelectRadioData<T> {
  items: SelectItem<T>[];
  indexSelected: number;
}

@Injectable()
export class ModalService {
  private _modalConfig: MatDialogConfig;

  constructor(private _dialog: MatDialog) {
    this._modalConfig = {
      width: '80vw',
      maxWidth: '400px',
      maxHeight: '80vh',
      panelClass: 'k-modal',
    };
  }

  select<T>(items: SelectItem<T>[], indexSelected?: number | undefined): Observable<ModalSelected<T>> {
    const dialogRef = this._dialog.open<ModalSelectRadioComponent, ModalSelectRadioData<T>, ModalSelected<T>>(
      ModalSelectRadioComponent,
      {
        ...this._modalConfig,
        data: {
          items,
          ...(indexSelected !== undefined ? { indexSelected } : { indexSelected: -1 }),
        },
      },
    );

    return dialogRef.afterClosed();
  }
}
