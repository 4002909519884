import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { toggleX } from '@bend/animations';
import { SettingsService } from '@bend/store';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [toggleX],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
  @Input() type = 'button';
  @Input() isLoading = false;
  @Input() disabled: boolean;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('style.backgroundColor') backgroundColor;
  @HostBinding('style.opacity') opacity;

  color: string;
  fgPrimary$: Observable<string>;

  constructor(private _settings: SettingsService) {
    this.fgPrimary$ = this._settings.colors.primaryForeground;

    this._setButtonBackground();
  }

  ngOnChanges(): void {
    this.opacity = this.disabled ? 0.6 : 1;
    this.backgroundColor = this.disabled ? 'grey' : this.color;
  }

  onClick(): void {
    if (!this.disabled || !this.isLoading) this.clicked.emit();
  }

  private _setButtonBackground(): void {
    this._settings.colors.primary
      .pipe(
        first(),
        /**
         * set background for host component
         */
        tap(color => {
          this.backgroundColor = color;
          this.color = color;
        }),
      )
      .subscribe();
  }
}
