import { EntityState } from '@ngrx/entity';

import { Order } from '../shared';

export interface State extends EntityState<Order> {
  isLoading: boolean;
  isLoadingHistory: boolean;
  isLoaded?: boolean;
  errorCode: null | string;
  currentId: Order['id'];
}
