import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const isAppSelected: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const urlSegments = state.url.split('/');

  return isExistAppSlug(urlSegments) ? true : router.createUrlTree(['/']);
};

const isExistAppSlug = (urlSegments: string[]): boolean => {
  const dashboardIndex = urlSegments.indexOf('dashboard');
  return !!urlSegments[dashboardIndex + 1];
};
