import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { createType, OrderItem } from '../shared';
import { storeKey } from './order-items.config';

const type = createType(storeKey);

export const actions = {
  addOrderItems: createAction(type('add order items'), props<{ orderItems: OrderItem[] }>()),
  updateOrderItem: createAction(type('update order item'), props<{ orderItem: Update<OrderItem> }>()),
  upsertOrderItems: createAction(type('upsert order items'), props<{ orderItems: OrderItem[] }>()),
  updateOrderItems: createAction(type('update order items'), props<{ orderItems: OrderItem[] }>()),
  removeOrderItem: createAction(type('remove order item'), props<{ orderItemId: number; orderUserId: number }>()),

  reset: createAction(type('reset')),
};
