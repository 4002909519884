import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() color: 'primary' | 'accent' | 'warn';
  @Input() disabled = false;
  @Output() clicked: EventEmitter<any>;

  // Temporary solution for app-button while we don't implement material button
  @HostBinding('class')
  private get _backgroundColor(): string {
    if (this.color === 'primary') return 'color_primary';
    if (this.color === 'accent') return 'color_accent';
    if (this.color === 'warn') return 'color_warn';
    return '';
  }

  constructor() {
    this.clicked = new EventEmitter();
  }

  onClick(): void {
    if (this.disabled) return;

    this.clicked.emit();
  }
}
