import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EnvModule, EnvService } from '@bend/env';
import { SocketModule } from '@bend/socket';

import { environment } from '@designer-environment';

import { NavigatorProgressComponent } from './components';
import { AuthService, HttpInterceptorService } from './services';

@NgModule({
  declarations: [NavigatorProgressComponent],
  imports: [
    EnvModule.forRoot(environment),
    SocketModule.forRoot({
      deps: [AuthService, EnvService],
    }),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  exports: [NavigatorProgressComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
    }
  }
}
