import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { actions } from './working-hours.actions';
import { State, WorkingHoursState } from './working-hours.type';

export const adapter: EntityAdapter<WorkingHoursState> = createEntityAdapter<WorkingHoursState>();

export const initialState: State = adapter.getInitialState({
  errorCode: null,
  isLoading: false,
  isLoadedBy: [],
});

export const authReducer = createReducer(
  initialState,
  on(actions.getCatalogWorkingHours, state => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getCatalogWorkingHoursIsLoaded, state => ({
    ...state,
    isLoading: false,
  })),
  on(actions.getCatalogWorkingHoursSuccess, (state, { intervals, catalogId }) =>
    adapter.addOne(
      {
        intervals,
        id: catalogId,
      },
      {
        ...state,
        isLoading: false,
        isLoadedBy: [...state.isLoadedBy, catalogId],
      },
    ),
  ),
  on(actions.getCatalogWorkingHoursError, (state, { errorCode }) => ({
    ...state,
    errorCode,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}
