<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <ng-container formGroupName="social">
      <div class="form-row">
        <label for="titleInput" class="form-label">{{ 'WIDGETS.SHARED.TITLE' | translate }}</label>
        <input
          id="titleInput"
          type="url"
          class="form-input form-input--url"
          placeholder="Title"
          formControlName="title"
        />
      </div>
      <div class="form-row" formGroupName="facebook">
        <label for="facebookInput" class="form-label">{{ 'WIDGETS.FOLLOW.FACEBOOK' | translate }}</label>
        <input
          id="facebookInput"
          type="url"
          class="form-input form-input--url"
          placeholder="Facebook URL"
          formControlName="link"
        />
        <designer-image-gallery formControlName="icon"></designer-image-gallery>
      </div>
      <div class="form-row" formGroupName="instagram">
        <label for="instagramInput" class="form-label">{{ 'WIDGETS.FOLLOW.INSTAGRAM' | translate }}</label>
        <input
          id="instagramInput"
          type="url"
          class="form-input form-input--url"
          placeholder="Instagram URL"
          formControlName="link"
        />
        <designer-image-gallery formControlName="icon"></designer-image-gallery>
      </div>
      <div class="form-row" formGroupName="linkedin">
        <label for="linkedinInput" class="form-label">{{ 'WIDGETS.FOLLOW.LINKEDIN' | translate }}</label>
        <input
          id="linkedinInput"
          type="url"
          class="form-input form-input--url"
          placeholder="Linkedin URL"
          formControlName="link"
        />
        <designer-image-gallery formControlName="icon"></designer-image-gallery>
      </div>
      <div class="form-row" formGroupName="twitter">
        <label for="twitterInput" class="form-label">{{ 'WIDGETS.FOLLOW.TWITTER' | translate }}</label>
        <input
          id="twitterInput"
          type="url"
          class="form-input form-input--url"
          placeholder="Twitter URL"
          formControlName="link"
        />
        <designer-image-gallery class="mt-5" formControlName="icon"></designer-image-gallery>
      </div>
      <div class="form-row" formGroupName="tripadvisor">
        <label for="tripadvisorInput" class="form-label">{{ 'WIDGETS.FOLLOW.TRIPADVISOR' | translate }}</label>
        <input
          id="tripadvisorInput"
          type="url"
          class="form-input form-input--url"
          placeholder="Tripadvisor URL"
          formControlName="link"
        />
        <designer-image-gallery formControlName="icon"></designer-image-gallery>
      </div>
    </ng-container>
    <div class="color-group">
      <div class="color-group__item">
        <label for="bgColor" class="form-label">{{ 'WIDGETS.FOLLOW.BG_COLOR' | translate }}</label>
        <input
          id="bgColor"
          [colorPicker]="backgroundColor.value"
          [cpPosition]="'top'"
          (colorPickerChange)="onBackgroundColorChange($event)"
          [style.background]="backgroundColor.value"
          readonly
          class="form-colorpicker"
        />
      </div>
      <div class="color-group__item">
        <label for="textColor" class="form-label">{{ 'WIDGETS.FOLLOW.TEXT_COLOR' | translate }}</label>
        <input
          id="textColor"
          [colorPicker]="textColor.value"
          [cpPosition]="'top'"
          (colorPickerChange)="onTextColorChange($event)"
          [style.background]="textColor.value"
          readonly
          class="form-colorpicker"
        />
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
