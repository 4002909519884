import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

import { Contact } from './contact';

@Injectable()
export class WidgetContactService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  sendContact(pageId: string, contact: Contact): Observable<any> {
    return this._api.contact('v1', pageId).pipe(switchMap(api => this._http.post(api, contact)));
  }
}
