<h2 class="title" mat-dialog-title>{{ 'STUDIO.IMAGE_GALLERY.SET_IMAGE' | translate }}</h2>

<div class="controls">
  <input style="display: none" type="file" multiple (change)="onFileChange($event)" #fileUploader />
  <button mat-raised-button (click)="fileUploader.click()" type="button" color="primary">
    {{ 'WIDGETS.UPLOAD.CHOOSE' | translate }}
  </button>
  <button mat-raised-button (click)="openUrlDialog()" type="button" color="primary">
    {{ 'STUDIO.IMAGE_GALLERY.ENTER_URL' | translate }}
  </button>
  <button mat-raised-button (click)="openCropperDialog()" [disabled]="!selectedImageId" type="button" color="primary">
    {{ 'STUDIO.IMAGE_GALLERY.CROP_IMAGE' | translate }}
  </button>
</div>

<designer-gallery-menu-presenter
  class="menu"
  [appSlug]="appSlug$ | async"
  [list]="applicationsList$ | async"
  [currentApp]="currentApp"
  (appChange)="changeSelectedApp($event)"
></designer-gallery-menu-presenter>

<designer-gallery-grid-presenter
  class="grid"
  (selected)="selectedImageId = $event"
  [isLoading]="isLoading$ | async"
  [images]="applicationsImages$ | async"
  [selectedImageId]="selectedImageId"
></designer-gallery-grid-presenter>

<div class="actions">
  <button mat-raised-button (click)="submit()" type="button" color="primary" [disabled]="!selectedImageId">
    {{ 'STUDIO.SHARED.SAVE' | translate }}
  </button>
  <button mat-raised-button (click)="cancel()" type="button" color="warn">
    {{ 'STUDIO.SHARED.CANCEL' | translate }}
  </button>
</div>
