import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ParamsService } from '../params';
import { filterUndefined, StoreError, Widget } from '../shared';
import { actions } from './widgets.actions';
import * as selectors from './widgets.selectors';
import { State } from './widgets.type';

@Injectable({ providedIn: 'root' })
export class WidgetsService {
  readonly productCatalogWidget$ = this._store.select(selectors.selectProductCatalog);

  constructor(private _store: Store<State>, private _params: ParamsService) {}

  get isLoading(): Observable<boolean> {
    return this._store.select(selectors.isLoading).pipe(filterUndefined());
  }

  get isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoaded).pipe(filterUndefined());
  }

  get error(): Observable<StoreError> {
    return this._store.select(selectors.error).pipe(filterUndefined());
  }

  byIds<T>(ids: string[]): Observable<Widget<T>[]> {
    return this._store.select(selectors.widgets(ids));
  }

  get shopWidgets(): Observable<Widget<any>[]> {
    return this._store
      .select(selectors.pageWidgets)
      .pipe(map(widgets => widgets.filter(({ type }) => type === 'ShopComponent')));
  }

  get changeSelectedPageId(): Observable<any> {
    return this._params.pageId.pipe(
      filter<string>(Boolean),
      tap(pageId => this._store.dispatch(actions.changeSelectedPageId({ pageId }))),
    );
  }
}
