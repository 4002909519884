import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { MenuItem } from '../shared';
import { actions } from './menu-item.actions';
import { State } from './menu-item.type';

export const adapter: EntityAdapter<MenuItem> = createEntityAdapter<MenuItem>();

export const initialState: State = adapter.getInitialState({});

const menuItemReducer = createReducer(
  initialState,
  on(actions.addMenuItems, (state, { entities }) => adapter.addMany(entities, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return menuItemReducer(state, action);
}
