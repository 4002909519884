export interface SettingsPop {
  tokenRequired: boolean;
  allowReadOnly: boolean;
  readOnlyDisplayMsg: string;
  noAccessDisplayMsg: string;
  upgradeMethods: PopUpgradeMethods;
}

export interface PopUpgradeMethods {
  [PopTypes.Gps]: GpsPop;
  [PopTypes.POS]: GenericUpgradeMethod;
  [PopTypes.WiFi]: WiFiPop;
}

export interface GenericUpgradeMethod {
  enabled: boolean;
}

export enum PopTypes {
  Gps = 'gps',
  POS = 'pos',
  WiFi = 'wifi',
}

export interface WiFiPop extends GenericUpgradeMethod {
  popSuccessMsg: string;
  popErrorMsg: string;
  ips: string[];
}

export interface GpsPop extends GenericUpgradeMethod {
  gpsPoint: GpsPoint;
  radius: number;
  introMsg: string;
  popSuccessMsg: string;
  popErrorMsg: string;
  deniedMsg: string;
}

interface GpsPoint {
  lat: number;
  long: number;
}
