import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './menu.config';
import { adapter } from './menu.reducer';
import { State } from './menu.type';

const state = createFeatureSelector<State>(storeKey);
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(state, selectIds);
export const entities = createSelector(state, selectEntities);
export const all = createSelector(state, selectAll);
export const total = createSelector(state, selectTotal);
