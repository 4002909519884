import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ImportService {
  private _test: Subject<boolean>;

  constructor() {
    this._test = new Subject();
  }

  get success(): Observable<boolean> {
    return this._test.asObservable();
  }

  added(): void {
    this._test.next(true);
  }
}
