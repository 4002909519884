import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class TipsService {
  private _tipsAmount: BehaviorSubject<number>;

  readonly amount$: Observable<number>;

  constructor() {
    this._tipsAmount = new BehaviorSubject(0);
    this.amount$ = this._amount;
  }

  setAmount(amount: number): void {
    this._tipsAmount.next(amount);
  }

  private get _amount(): Observable<number> {
    return this._tipsAmount.asObservable().pipe(distinctUntilChanged());
  }
}
