import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { SmileHistoryItem } from '../shared';
import { actions } from './smile-history.actions';
import { State } from './smile-history.type';

export const adapter: EntityAdapter<SmileHistoryItem> = createEntityAdapter<SmileHistoryItem>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
  total: 0,
  skip: 0,
  limit: 10,
});

const smileHistoryReducer = createReducer(
  initialState,
  on(actions.getSmileHistory, state => ({ ...state, isLoading: true })),

  on(actions.getSmileHistorySuccess, (state, { total, skip, limit, data }) =>
    adapter.setAll(data, {
      ...state,
      skip,
      limit,
      total,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return smileHistoryReducer(state, action);
}
