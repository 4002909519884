import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { OrderService } from '@bend/store/src/lib/order';
import { ParamsService } from '@bend/store/src/lib/params';
import { CollectType } from '@bend/store-shared';

import { AppType } from '../../../../types';
import { WorkingHoursService } from '../../../working-hours';
import { TypeService } from '../type/type.service';

@Injectable()
export class OrderTypeService {
  readonly isReadOnlyTrigger$: Observable<boolean>;

  private _isReadOnlyTrigger: BehaviorSubject<boolean>;

  constructor(
    private _settings: SettingsService,
    private _order: OrderService,
    private _workingHours: WorkingHoursService,
    private _type: TypeService,
    private _params: ParamsService,
  ) {
    this._isReadOnlyTrigger = new BehaviorSubject<boolean>(false);
    this.isReadOnlyTrigger$ = this._isReadOnlyTrigger.asObservable();
  }

  setReadOnlyTrigger(value: boolean): void {
    this._isReadOnlyTrigger.next(value);
  }

  get isReadOnly(): Observable<boolean> {
    return this._params.queryParams(['place']).pipe(
      switchMap(([place]) =>
        place
          ? this.isReadOnlyTrigger$.pipe(
              /**
               * check if order is offline
               * because wee put in readonly only in offline mode
               */
              switchMap(isReadOnly =>
                !isReadOnly
                  ? /**
                     * if order is offline type
                     * wee check if user have order
                     */
                    this.isOffline.pipe(
                      switchMap(isOffline =>
                        isOffline
                          ? this._workingHours.isClose.pipe(
                              withLatestFrom(
                                this._order.isLoaded,
                                this._settings.allowCreateNewSession,
                                this._type.app,
                              ),
                              /**
                               * @description if allowCreateNewSession is false and do not has order - put in readonly
                               * or if pay after and do not has order - put in readonly
                               */
                              switchMap(([isClose, hasOrder, allowNewSession, appType]) =>
                                isClose
                                  ? of(true)
                                  : of(!hasOrder && (appType === AppType.PayAfter || !allowNewSession)),
                              ),
                            )
                          : /**
                             * if order type is online ordering,
                             * we don't show product catalog in read only
                             */
                            of(false),
                      ),
                    )
                  : of(true),
              ),
            )
          : of(true),
      ),
      distinctUntilChanged(),
    );
  }

  get isOffline(): Observable<boolean> {
    return this.isOnline.pipe(map(isOnline => !isOnline));
  }

  get isOnline(): Observable<boolean> {
    const allowType = new Set<CollectType>([CollectType.Delivery, CollectType.TakeAway]);

    return this._settings.collectTypes.pipe(
      /**
       * check if collect type is delivery or take-away
       */
      map(collects => collects.some(collect => allowType.has(collect))),
      switchMap(isOnline =>
        iif(
          () => isOnline,
          of(true),
          // TO DO: wee need to implement new collect type by pick-up option
          this._workingHours.isOpen.pipe(
            switchMap(isOpen =>
              isOpen ? this._settings.widgetCart.pipe(map(cartSettings => cartSettings?.allowPickup)) : of(false),
            ),
          ),
        ),
      ),
    );
  }
}
