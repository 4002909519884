import { createAction, props } from '@ngrx/store';

import { createType, OrderMenuItem } from '../shared';
import { storeKey } from './order-menu-items.config';

const type = createType(storeKey);

export const actions = {
  addOrderMenuItems: createAction(type('add order items menus'), props<{ orderMenuItems: OrderMenuItem[] }>()),
  upsertOrderMenuItems: createAction(type('upsert order items menus'), props<{ orderMenuItems: OrderMenuItem[] }>()),
  updateOrderMenuItems: createAction(type('update order items menus'), props<{ orderMenuItems: OrderMenuItem[] }>()),
  reset: createAction(type('reset')),
};
