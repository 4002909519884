<ng-content select="[label]"></ng-content>
<app-button
  (clicked)="click()"
  class="button"
  [type]="type"
  [isLoading]="isLoading"
  [disabled]="disabled"
  data-cy="bottomBtn"
>
  <ng-content></ng-content>
</app-button>
