import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PaymentData, PaymentType } from '@bend/store-shared';

@Injectable()
export class PaymentProviderService<T = PaymentType> {
  readonly data: Observable<PaymentData<T> | null>;
  private _data: BehaviorSubject<PaymentData<T> | null>;

  constructor() {
    this._data = new BehaviorSubject(null);
    this.data = this._data.asObservable();
  }

  setData(data: PaymentData<T>): void {
    this._data.next(data);
  }

  removeData(): void {
    this._data.next(null);
  }
}
