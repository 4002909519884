import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptimisticService } from 'projects/shared-widgets/src/lib/shared/cart-services/services/optimistic/optimistic.service';
import { PricesService } from 'projects/widgets-old/src/lib/product-catalog/services/prices.service';
import {
  combineLatest,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  Observable,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  ApiVisitorService,
  ItemUnavailabilityDisplay,
  ParamsService,
  PlaceRecommendationGroup,
  RecommendationGroupService,
  SettingsService,
} from '@bend/store';
import { PopOrderItemRequestData, PopProduct } from '@bend/widgets-old/product-catalog/product-catalog';
import { WidgetProductCatalogHelperService } from '@bend/widgets-old/product-catalog/product-catalog-helper.service';

@Injectable()
export class CartRecommendationsService {
  recommendationsSettings$ = this.settings.widgetCart.pipe(
    map(cart => cart.recommendations),
    /**
     * Emmit new value only if recommendations will be changed
     * */
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  recommendations$ = this.recommendationsSettings$.pipe(
    /**
     * Check if settings exist
     * */
    filter(Boolean),
    /**
     * Check if recommendations are enabled and have recommendation groups
     * */
    filter(({ enabled, groups }) => enabled && !!groups && !!groups.length),
    /**
     * Get place from params
     * */
    withLatestFrom(this.params.queryParams(['place'])),
    /**
     * Take place only
     * */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, param]) => param[0]),
    /**
     * Store call for fetching recommendations by place
     * */
    tap(place => this.recommendationGroup.getRecommendationsByPlace(place)),
    /**
     * Get recommendations by place
     * */
    switchMap(() => this.recommendationGroup.recommendationsByPlace()),
  );

  recommendationsInLoading$ = this.recommendationGroup.placeRecommendationsInLoading;

  constructor(
    private http: HttpClient,
    private api: ApiVisitorService,
    private settings: SettingsService,
    private params: ParamsService,
    private productCatalogHelperService: WidgetProductCatalogHelperService,
    private recommendationGroup: RecommendationGroupService,
    private optimistic: OptimisticService,
    private pricesService: PricesService,
  ) {}

  isRecommendationGroupAvailable(
    group: PlaceRecommendationGroup,
    itemUnavailabilityDisplay: ItemUnavailabilityDisplay,
  ): boolean {
    return (
      group.recommendations.some(rec => rec.available) ||
      itemUnavailabilityDisplay === ItemUnavailabilityDisplay.OutOfStock
    );
  }

  addToCart(itemId: number, groupId: number): void {
    combineLatest([this.recommendationGroup.placeRecommendation(itemId, groupId), this.pricesService.flow])
      .pipe(
        take(1),
        tap(() => this.recommendationGroup.addPlaceRecommendationToCart(itemId, groupId)),
        /**
         * Update product price by flow
         * (add children empty array for generateALaCarteItem method)
         * */
        map(([rec, flow]) => ({ ...this.pricesService.getUpdatedProductByFlow(rec, flow), children: [] })),
        /**
         * Generate a-la-carte item
         * */
        map(rec => this.productCatalogHelperService.generateALaCarteItem(rec as unknown as PopProduct, 1, '', null)),

        exhaustMap(rec =>
          this.addRecommendationToCart(rec).pipe(
            tap(() => this.optimistic.checkNewItem()),
            tap(() => this.recommendationGroup.addPlaceRecommendationSuccess(itemId, groupId)),
            catchError(error => {
              return this.productCatalogHelperService
                .handleOrderClose(error)
                .pipe(
                  tap(() => this.recommendationGroup.addPlaceRecommendationError(itemId, error.errorCode as string)),
                );
            }),
          ),
        ),
      )
      .subscribe();
  }

  private addRecommendationToCart(orderItem: PopOrderItemRequestData): Observable<void> {
    return this.api.orderItems('v1').pipe(switchMap(api => this.http.post<void>(api, orderItem)));
  }
}
