<div class="element">
  <div
    [class.element__border--error]="isShowError"
    [class.element__border--disabled]="isDisabled"
    class="element__border"
  ></div>
  <ng-container *ngIf="options; else spinner">
    <select [compareWith]="compareWith" #input [formControl]="control" class="element__input">
      <option *ngFor="let option of options" [ngValue]="keyValue ? option[keyValue] : option">
        <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ option: option }"></ng-container>
      </option>
    </select>
  </ng-container>
  <ng-template #spinner>
    <app-loader class="element__spinner" stroke="#000"></app-loader>
  </ng-template>
</div>
<app-input-errors [ngControl]="ngControl" [showError]="isShowError"></app-input-errors>
