<h1 md-dialog-title>
  <span>{{ 'STUDIO.SETTINGS.GENERAL.DUPLICATE' | translate }}</span>
</h1>
<form (submit)="onSubmit()" [formGroup]="form" #htmlForm="ngForm" class="form">
  <div md-dialog-content>
    <div class="form-row-cols-wrapper">
      <div class="form-row-cols">
        <div class="form-row-cols__col flex-grow-1 form-row-cols__col--max-50-chars">
          <div
            class="form-row-cols__col__content"
            [class.input--error]="htmlForm.submitted && form.get('name').invalid"
          >
            <label for="name" class="form-label">
              {{ 'STUDIO.SETTINGS.GENERAL.APP_NAME' | translate }}
              <span class="form-label-required">{{ 'PRODUCT_CATALOG.REQUIRED' | translate }}</span>
            </label>
            <input id="name" type="text" formControlName="name" class="form-input" maxlength="50" />
            <ng-container *ngIf="htmlForm.submitted">
              <span *ngIf="form.get('name').hasError('required')" class="form-error-msg">
                {{ 'STUDIO.SETTINGS.GENERAL.ERROR_NAME' | translate }}
              </span>
              <span *ngIf="form.get('name').hasError('maxlength')" class="form-error-msg">
                {{ 'STUDIO.SETTINGS.GENERAL.ERROR_NAME_LENGTH' | translate }}
              </span>
            </ng-container>
            <span class="form-field-info">{{ 'STUDIO.SHARED.MAX_CHARACTER' | translate }}</span>
          </div>
        </div>
        <div class="form-row-cols__col flex-grow-1">
          <div
            class="form-row-cols__col__content"
            [class.input--error]="htmlForm.submitted && form.get('slug').invalid"
          >
            <label for="slug" class="form-label">
              {{ 'STUDIO.SETTINGS.GENERAL.SLUG' | translate }}
              <span class="form-label-required">{{ 'PRODUCT_CATALOG.REQUIRED' | translate }}</span>
            </label>
            <input id="slug" type="text" formControlName="slug" class="form-input" />
            <ng-container *ngIf="htmlForm.submitted">
              <span *ngIf="form.get('slug').hasError('required')" class="form-error-msg">
                {{ 'STUDIO.SETTINGS.GENERAL.ERROR_SLUG' | translate }}
              </span>
              <span *ngIf="form.get('slug').hasError('pattern')" class="form-error-msg">
                {{ 'STUDIO.SETTINGS.GENERAL.ERROR_SLUG_PATTERN' | translate }}
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="options">
        <input id="duplicateCatalogs" formControlName="duplicateCatalogs" type="checkbox" class="form-input-checkbox" />
        <label for="duplicateCatalogs" class="form-label form-label--checkbox">
          {{ 'STUDIO.SETTINGS.GENERAL.DUPLICATE_PRODUCT_CATALOG' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="form-footer" md-dialog-actions>
    <button class="btn app-button-color" type="submit">
      {{ 'STUDIO.SHARED.DUPLICATE' | translate }}
      <pop-widget-spinner *ngIf="isSaving" [width]="25" [height]="13"></pop-widget-spinner>
    </button>
    <button class="btn app-button-color" mat-dialog-close>{{ 'STUDIO.SHARED.CANCEL' | translate }}</button>
  </div>
</form>
