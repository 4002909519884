import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-wrapper-coll',
  templateUrl: './input-wrapper-coll.component.html',
  styleUrls: ['./input-wrapper-coll.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InputWrapperCollComponent {
  @Input() tooltip = '';
}
