import { Component, Input } from '@angular/core';

import { FeedbackLabels, FeedbackResponse } from '../feedback';

@Component({
  selector: 'pop-feedback-reviews',
  templateUrl: './feedback-reviews.component.html',
  styleUrls: ['./feedback-reviews.component.scss'],
})
export class WidgetFeedbackReviewsComponent {
  @Input() mainColor: string;
  @Input() feedbacks: FeedbackResponse[] = [];
  @Input() labels: FeedbackLabels;
  starsRange: number[] = [1, 2, 3, 4, 5];

  constructor() {}
}
