<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced form-row-groups--overflow-visible">
          <div class="form-row-group form-row-group--distanced">
            <label for="linkInput" class="form-label">{{ 'WIDGETS.SHARED.LINK' | translate }}</label>
            <span
              *ngIf="url.invalid && (((url.dirty || url.touched) && url.errors.required) || showErrors)"
              class="form-error-msg"
            >
              {{ 'WIDGETS.SHARED.LINK_ENTER' | translate }}
            </span>
            <input
              id="linkInput"
              class="form-input form-input--url"
              type="url"
              placeholder="URL"
              formControlName="url"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="height" class="form-label">{{ 'WIDGETS.STYLE.HEIGHT' | translate }}</label>
            <input
              id="height"
              type="number"
              formControlName="height"
              min="0"
              class="form-input form-input--number-bigger"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="fullWidth" class="form-label">
              <input id="fullWidth" type="checkbox" formControlName="fullWidth" />
              {{ 'WIDGETS.STYLE.FULL_WIDTH' | translate }}
            </label>
            <label for="bounds" class="form-label">
              <input id="bounds" type="checkbox" formControlName="bounds" />
              {{ 'WIDGETS.STYLE.BOUNDS' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
