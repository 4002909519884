<div @toggleY *ngIf="isShowError" class="error">
  <span *ngIf="isShowRequiredError" class="form-error-msg">{{ 'WIDGETS.SHARED.REQUIRED' | translate }}</span>
  <span *ngIf="isShowEmailError" class="form-error-msg">{{ 'WIDGETS.SHARED.EMAIL_VALID' | translate }}</span>
  <span *ngIf="isShowMinError" class="form-error-msg">
    {{ 'WIDGETS.SHARED.MIN' | translate }} {{ ngControl.getError('min').min }}
  </span>
  <span *ngIf="isShowMaxError" class="form-error-msg">
    {{ 'WIDGETS.SHARED.MAX' | translate }} {{ ngControl.getError('max').max }}
  </span>
  <span *ngIf="isShowMinLengthError" class="form-error-msg">
    {{ 'WIDGETS.SHARED.MIN_LENGTH' | translate }} {{ ngControl.getError('minlength').requiredLength }}
  </span>
  <span *ngIf="isShowCapitalLetterError" class="form-error-msg">
    {{ 'WIDGETS.SHARED.HAS_CAPITAL_LETTER' | translate }}
  </span>
  <span *ngIf="isShowNoCharacterError" class="form-error-msg">
    {{ 'WIDGETS.SHARED.NO_CHARACTER' | translate }}
  </span>
  <span *ngIf="isShowMaxLengthError" class="form-error-msg">
    {{ 'WIDGETS.SHARED.MAX_LENGTH' | translate }} {{ ngControl.getError('maxlength').requiredLength }}
  </span>
  <span *ngIf="isShowDuplicateError" class="form-error-msg">Duplicate</span>
  <span *ngIf="isNotIntegerError" class="form-error-msg">{{ 'WIDGETS.SHARED.INTEGER_ERROR' | translate }}</span>
  <span *ngIf="isNotIpAddressError" class="form-error-msg">{{ 'WIDGETS.SHARED.IP_ADDRESS_ERROR' | translate }}</span>
  <span *ngIf="isNotPortError" class="form-error-msg">{{ 'WIDGETS.SHARED.PORT_ERROR' | translate }}</span>
  <span *ngIf="isNotUrlError" class="form-error-msg">{{ 'WIDGETS.SHARED.URL_ERROR' | translate }}</span>
  <span *ngIf="isInvalidSelection" class="form-error-msg">
    {{ 'WIDGETS.SHARED.INVALID_SELECTION_ERROR' | translate }}
  </span>
</div>
