import { EnvService } from '@bend/env';

import { Auth, Socket as SocketInterface } from './socket.type';
import { SocketInstance } from './socket-instance';

export class SocketService {
  private _instances: Map<string, SocketInstance>;

  constructor(
    private _auth: Auth,
    private _env: EnvService,
  ) {
    this._instances = new Map();
  }

  connect(channelType: string, channelId: string = ''): SocketInterface {
    const key = `${channelType}/${channelId}`;
    if (this._instances.has(key)) {
      return this._instances.get(key);
    }

    const removeInstance = (): boolean => this._instances.delete(key);

    const newInstance = new SocketInstance(this._auth, this._env, removeInstance, channelType, channelId);
    this._instances.set(key, newInstance);

    return newInstance;
  }

  close(patch: string = ''): void {
    this._instances.get(patch).close();
  }
}
