import { Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetProfile } from './profile';

@Component({
  selector: 'pop-widget-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class WidgetProfileComponent implements OnInit, WidgetComponent {
  static widgetName = 'profile';
  @Input() attributes: WidgetProfile;

  longDescription: string;
  shortDescription: string;
  isShowMore: boolean;
  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    const SHORT_DESCRIPTION_MAX_LENGTH = 100;

    if (!this.attributes || !this.attributes.name) {
      this.noValidMessage = this._warningService.showWarn(WidgetProfileComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    if (this.attributes.description && this.attributes.description.length > SHORT_DESCRIPTION_MAX_LENGTH) {
      this.shortDescription = `${this.attributes.description.substring(0, SHORT_DESCRIPTION_MAX_LENGTH)}...`;
      this.longDescription = this.attributes.description;
    } else {
      this.shortDescription = this.attributes.description;
    }

    this.isValid = true;
  }

  showMore(): void {
    this.isShowMore = !this.isShowMore;
  }
}
