import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';

import { AppSettingsService, LanguagesConfig, ParamsService } from '@bend/store';

interface LanguagesButtonsListContainer {
  allowedLanguages: LanguagesConfig[];
  currentLanguage: LanguagesConfig;
  languagesErrors: { [key in LanguagesConfig]: boolean };
}

@Component({
  selector: 'translate-languages-buttons-list-container [languagesErrors]',
  templateUrl: './languages-buttons-list-container.component.html',
  styleUrls: ['./languages-buttons-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesButtonsListContainerComponent implements OnInit {
  /**
   * All languages error appeared in form
   */
  @Input() languagesErrors!: { [key in LanguagesConfig]: boolean };

  data$: Observable<LanguagesButtonsListContainer>;

  constructor(
    private readonly _params: ParamsService,
    private readonly _settings: AppSettingsService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.data$ = this._data;
  }

  updateLanguageQueryParam(language: LanguagesConfig): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { language },
      queryParamsHandling: 'merge',
    });
  }

  private get _data(): Observable<LanguagesButtonsListContainer> {
    return combineLatest([this._settings.languages, this._params.language]).pipe(
      map(([{ allowedLanguages }, currentLanguage]) => ({
        allowedLanguages,
        currentLanguage,
        languagesErrors: this.languagesErrors,
      })),
    );
  }
}
