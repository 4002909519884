import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { FeedbackLabel, FeedbackLabels, FeedbackLabelsNames, FeedbackWidget } from './feedback';

@Component({
  selector: 'pop-widget-edit-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditFeedbackComponent extends WidgetBaseComponent<FeedbackWidget> implements OnInit {
  static widgetName = 'feedback';
  labels: FeedbackLabel[];
  labelsNames = FeedbackLabelsNames;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.labels = this._getLabels();
    this._initForm();
  }

  private _getLabelGroup(labels: FeedbackLabel[], widget: FeedbackWidget): object {
    const group = {};

    labels.forEach(label => {
      group[label.key] = [widget.attributes.labels[label.key]];
    });

    return group;
  }

  private _getLabels(): FeedbackLabel[] {
    const feedbackLabels = new FeedbackLabels();
    const labels = Object.keys(feedbackLabels).map(key => {
      return {
        key,
        name: feedbackLabels[key],
      };
    });

    return labels;
  }

  private _addMissingAttributes(widget: FeedbackWidget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = new FeedbackLabels();
    }
  }

  private _initForm(): void {
    const labelGroup = this._getLabelGroup(this.labels, this.widget);
    const { showComment, showName, showPreviousFeedbacks, showUpload, showStars, style } = this.widget.attributes;

    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        showComment: [showComment],
        showName: [showName],
        showPreviousFeedbacks: [showPreviousFeedbacks],
        showUpload: [showUpload],
        showStars: [showStars],
        style: this._formBuilder.group({
          margin: this._formBuilder.group({
            bottom: [style.margin.bottom],
            top: [style.margin.top],
          }),
          borderRadius: [style.borderRadius],
          showBackground: [style.showBackground],
        }),
        labels: this._formBuilder.group(labelGroup),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
