export interface WidgetStyle {
  height: string;
  margin: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
  border: {
    width: string | null;
    radius: string | null;
    style: string | null;
    color: string | null;
  };
  boxShadow: string | null;
  background: string | null;
  color: string | null;
}
