import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EnvModule } from '@bend/env';

import { storeKey } from './pages.config';
import { PagesEffects } from './pages.effects';
import { PagesHttpService } from './pages.http.service';
import { metaReducers, reducer } from './pages.reducer';
import { PagesService } from './pages.service';

@NgModule({
  imports: [
    StoreModule.forFeature(storeKey, reducer, { metaReducers }),
    EffectsModule.forFeature([PagesEffects]),
    EnvModule,
  ],
  providers: [PagesService, PagesHttpService],
})
export class PagesStoreModule {}
