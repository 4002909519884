import { createAction, props } from '@ngrx/store';

import { createType } from '../shared';
import { storeKey } from './smile-history.config';
import { GetSmileHistory, GetSmileHistorySuccess } from './smile-history.type';

const type = createType(storeKey);

export const actions = {
  getSmileHistory: createAction(type('get smile history'), props<GetSmileHistory>()),
  getSmileHistorySuccess: createAction(type('get smile history success'), props<GetSmileHistorySuccess>()),
  getSmileHistoryError: createAction(type('get smile history error')),
};
