import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';
import { PollResponsePayload } from '@bend/widgets-old/poll/poll';

@Injectable()
export class WidgetPollService {
  constructor(
    private _http: HttpClient,
    private _api: ApiVisitorService,
  ) {}

  getHasVoted(pollId: string): Observable<any> {
    return this._api.hasVote('v1', pollId).pipe(switchMap(api => this._http.get(api)));
  }

  vote(pollId: string, payload: PollResponsePayload): Observable<any> {
    return this._api.vote('v1', pollId).pipe(switchMap(api => this._http.post(api, payload)));
  }

  sendEmail(email: string): Observable<void> {
    return this._api.me('v1').pipe(switchMap(api => this._http.patch<void>(api, { email })));
  }
}
