import { createAction, props } from '@ngrx/store';

import { createType, PlaceRecommendationGroup, RecommendationGroup } from '../shared';
import { storeKey } from './recommendation-group.config';

const type = createType(storeKey);

export const actions = {
  getRecommendationGroups: createAction(type('get'), props<{ by: number }>()),
  getRecommendationGroupsSuccess: createAction(
    type('get - success'),
    props<{ entities: RecommendationGroup[]; by: number }>(),
  ),
  getRecommendationGroupsIsLoaded: createAction(type('get - is loaded')),
  getRecommendationGroupsError: createAction(type('get - error'), props<{ errorCode: string }>()),

  getPlaceRecommendations: createAction(type('get by place'), props<{ place: string }>()),
  getPlaceRecommendationsSuccess: createAction(
    type('get by place - success'),
    props<{ recommendations: PlaceRecommendationGroup[]; place: string }>(),
  ),
  getPlaceRecommendationsError: createAction(type('get by place - error'), props<{ errorCode: string }>()),

  addPlaceRecommendationToCart: createAction(
    type('add place recommendation to cart'),
    props<{ itemId: number; groupId: number }>(),
  ),
  addPlaceRecommendationToCartSuccess: createAction(
    type('add place recommendation to cart - success'),
    props<{ itemId: number; groupId: number }>(),
  ),
  addPlaceRecommendationToCartError: createAction(
    type('add place recommendation to cart - error'),
    props<{ itemId: number; errorCode: string }>(),
  ),

  reset: createAction(type('reset')),
};
