import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { actions as itemsActions } from '../item/item.actions'; // TODO: review
import { Category, Item } from '../shared';
import { actions as categoryActions } from './category.actions';
import { CategoryHttpService } from './category.http.service';
import * as selectors from './category.selectors';
import { State } from './category.type';

@Injectable()
export class CategoryEffects {
  loadCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(categoryActions.getCategories),
      withLatestFrom(this._store.select(selectors.loadedTabs)),
      mergeMap(([{ catalogId, tabId }, loadedCatalogs]) =>
        loadedCatalogs[tabId]
          ? of(categoryActions.getCategoriesIsLoaded({ catalogId, tabId }))
          : this._http.getCategories(catalogId).pipe(
              mergeMap(categories => [
                itemsActions.addItems({ entities: this._getItems(categories, catalogId) }),
                categoryActions.getCategoriesSuccess({ tabId, entities: this._getCategories(categories, catalogId) }),
              ]),
              catchError(({ errorCode }) => of(categoryActions.getCategoriesError({ errorCode, tabId }))),
            ),
      ),
    ),
  );

  loadCategoriesSingle$ = createEffect(() =>
    this._actions$.pipe(
      ofType(categoryActions.getCategoriesSingle),
      withLatestFrom(this._store.select(selectors.loadedTabs)),
      mergeMap(([{ catalogId, tabId }, loadedCatalogs]) =>
        loadedCatalogs[tabId]
          ? of(categoryActions.getCategoriesSingleIsLoaded({ tabId }))
          : this._http.getCategories(catalogId).pipe(
              mergeMap(categories => [
                itemsActions.addItems({ entities: this._getItems(categories, catalogId) }),
                categoryActions.getCategoriesSingleSuccess({
                  tabId,
                  entities: this._getCategories(categories, catalogId),
                }),
              ]),
              catchError(({ errorCode }) => of(categoryActions.getCategoriesSingleError({ errorCode, tabId }))),
            ),
      ),
    ),
  );

  loadMenuCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(categoryActions.getMenuCategories),
      withLatestFrom(this._store.select(selectors.loadedTabs)),
      mergeMap(([{ menuId, catalogId }]) =>
        false
          ? of(categoryActions.getMenuCategoriesIsLoaded({ menuId }))
          : this._http.getMenuCategories(menuId).pipe(
              mergeMap(categories => [
                itemsActions.addItems({ entities: this._getMenuCategoryItems(categories, catalogId) }),
                categoryActions.getMenuCategoriesSuccess({
                  menuId,
                  entities: this._getMenuCategories(categories, menuId),
                }),
              ]),
              catchError(({ errorCode }) => of(categoryActions.getMenuCategoriesError({ errorCode, menuId }))),
            ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _store: Store<State>, private _http: CategoryHttpService) {}

  private _getItems(categories: Category[], catalogId: number): Item[] {
    return categories.flatMap(({ id, subCategories, items }) => [
      ...items.map(item => ({
        ...item,
        catalogId,
        quantity: 0,
        categoryId: id,
      })),
      ...subCategories.flatMap(({ id: subcategoryId, items: subcategoryProducts }) =>
        subcategoryProducts.map(item => ({
          ...item,
          catalogId,
          quantity: 0,
          categoryId: subcategoryId,
        })),
      ),
    ]);
  }

  private _getCategories(categories: Category[], catalogId: number): Category[] {
    return categories.flatMap(category => {
      return [
        { ...category, catalogId },
        ...category.subCategories.map(subCategory => {
          return {
            ...subCategory,
            catalogId,
            categoryId: category.id,
          };
        }),
      ];
    });
  }

  private _getMenuCategories(categories: Category[], menuId: number): Category[] {
    return categories.flatMap(({ id, items, ...rest }) => [{ id, items, menuId, ...rest }]);
  }

  private _getMenuCategoryItems(categories: Category[], catalogId: number): Item[] {
    return categories.flatMap(({ items }) => items.map(item => ({ ...item, catalogId })));
  }
}
