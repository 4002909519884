import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  AppsDesignerStoreService,
  AppsEffects,
  AppsHttpService,
  appsReducer,
  DESIGNER_APPS_STATE_KEY,
} from '@designer-store/apps';

@NgModule({
  imports: [StoreModule.forFeature(DESIGNER_APPS_STATE_KEY, appsReducer), EffectsModule.forFeature([AppsEffects])],
  providers: [AppsDesignerStoreService, AppsHttpService],
})
export class AppsStoreModule {}
