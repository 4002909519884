import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

import { RecommendationMenuBackdropService } from '../services';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideMenuDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
    private backdrop: RecommendationMenuBackdropService,
  ) {
    this.backdrop.setBackdrop();
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.backdrop.removeBackdrop();
      this.clickOutside.emit();
    }
  }
}
