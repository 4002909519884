import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import { StoreService, User } from '../shared';
import { actions } from './users.actions';
import * as selectors from './users.selectors';
import { State } from './users.type';

@Injectable()
export class UsersService extends StoreService<State> {
  constructor(
    private _designerRouter: DesignerRouterService,
    protected _store: Store<State>,
    protected _actions: Actions,
  ) {
    super(_store, _actions, selectors);
  }

  get byPage(): Observable<User[]> {
    return this._store.pipe(select(selectors.users));
  }

  get total(): Observable<number> {
    return this._store.pipe(select(selectors.total));
  }

  get currentIsLoading(): Observable<boolean> {
    return this.current.pipe(map(user => user?.isLoading));
  }

  get errorCode(): Observable<string> {
    return this._store.pipe(select(selectors.error));
  }

  get current(): Observable<User> {
    return this._designerRouter.paramsChanges(['userId']).pipe(
      switchMap(([userId]) =>
        this._store.pipe(
          select(selectors.entities),
          map(user => user?.[userId]),
        ),
      ),
    );
  }

  getUsers(limit: number, offset: number, onlyPendingTickets: boolean, search: string): void {
    this._store.dispatch(actions.getUsersByOffsetAndLimit({ limit, offset, onlyPendingTickets, search }));
  }

  getUsersBySearch(limit: number, onlyPendingTickets: boolean, search: string): void {
    this._store.dispatch(actions.getUsersBySearch({ limit, search, onlyPendingTickets, offset: 0 }));
  }

  addCredit(id: User['id'], credit: User['credit'], reason: string, paymentMethod: string): void {
    this._store.dispatch(
      actions.addCredits({ user: { id, changes: { isLoading: true } }, add: { credit, reason, paymentMethod } }),
    );
  }

  getUser(userId: User['id']): void {
    this._store.dispatch(actions.getUser({ userId }));
  }

  updateUser({ name, phone, email, id }: User): Observable<boolean> {
    this._store.dispatch(actions.updateUser({ user: { id, name, phone, email } }));

    return this._finishedAction(actions.updateUserSuccess, actions.updateUserError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }
}
