import { CollectType } from './collect.type';
import { PlaceType } from './place.type';

export interface OrderDetails<T = Date> {
  collectType: CollectType;
  placeType: PlaceType;
  nrCutlery?: number;
  additionalDetails?: string;
  orderTime?: T;
}
