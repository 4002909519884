import { WidgetConfig, WidgetStyle } from '@bend/shared-widgets/src/lib/types';

export interface Pin {
  coords: google.maps.LatLngLiteral;
  icon?: string;
  title?: string;
}

interface MapWidgetStyle extends WidgetStyle {
  borderRadius: number;
  fullWidth: boolean;
}

export interface MapConfig extends WidgetConfig {
  style: MapWidgetStyle;
  route: {
    color: string;
    coords: [];
  };
  zoom: {
    focus: {
      zoom: number;
      coords: google.maps.LatLngLiteral;
    };
    fitBounds: true;
  };
  live: {
    isLive: false;
    icon: null;
    title: null;
  };
  pins: [
    {
      title: string;
      icon: null;
      coords: google.maps.LatLngLiteral;
    },
  ];
  mainColor: string;
  secondColor: string;
  textColor: string;
  pop: {
    validationStatus: string;
    readOnlyDisplayMsg: string;
  };
}
