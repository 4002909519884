import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { HistoryService } from '../../services';

@Injectable()
export class ButtonBackGuard {
  constructor(
    private _router: Router,
    private _history: HistoryService,
  ) {}

  canActivate(): UrlTree | boolean {
    if (!this._history.isPopState) return true;

    return this._router.parseUrl(this._history.backUrl);
  }
}
