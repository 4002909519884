import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LanguagesConfig } from '@bend/store';

@Component({
  selector: 'translate-languages-buttons [allowedLanguages][currentLanguage]',
  templateUrl: './languages-buttons-list.component.html',
  styleUrls: ['./languages-buttons-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesButtonsListComponent {
  /**
   * Allowed languages
   */
  @Input() allowedLanguages!: LanguagesConfig[];
  /**
   * All languages error appeared in form
   */
  @Input() languagesErrors: { [key in LanguagesConfig]?: boolean } = {};
  /**
   * Current language
   */
  @Input() currentLanguage!: LanguagesConfig;
  /**
   * Show icons in buttons
   */
  @Input() showIcons = false;
  /**
   * Is disabled buttons
   */
  @Input() disabled = false;
  /**
   * Emit event on click
   */
  @Output() clicked = new EventEmitter<LanguagesConfig>();

  onClick(langauge: LanguagesConfig): void {
    this.clicked.emit(langauge);
  }
}
