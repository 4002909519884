<div class="widget-gallery">
  <ng-container *ngIf="isValid; else elseBlock">
    <div class="gallery-content">
      <div class="img-container" *ngFor="let image of attributes.images; trackBy: trackByFn">
        <img [src]="image.url" [alt]="image.alt" />
      </div>
    </div>
  </ng-container>

  <ng-template #elseBlock>
    <div *ngIf="attributes?.isEditMode">{{ noValidMessage }}</div>
  </ng-template>
</div>
