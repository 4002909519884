import { createAction, props } from '@ngrx/store';

import { createType, RecommendationsItemsDesigner } from '../shared';
import { storeKey } from './recommendations-designer.config';

const type = createType(storeKey);

export const actions = {
  getRecommendations: createAction(type('get recommendations'), props<{ id: number }>()),
  getRecommendationsIsLoaded: createAction(type('get recommendations is loaded')),
  getRecommendationsSuccess: createAction(
    type('get recommendations success'),
    props<{
      recommendations: RecommendationsItemsDesigner[];
      recommendationGroupId: number;
    }>(),
  ),
  getRecommendationsError: createAction(type('get recommendations error'), props<{ errorCode: string }>()),

  removeAllRecommendations: createAction(type('remove all recommendations')),

  saveRecommendations: createAction(
    type('save recommendations success'),
    props<{
      recommendations: RecommendationsItemsDesigner[];
      groupId: number;
    }>(),
  ),

  deleteRecommendations: createAction(type('delete recommendations success'), props<{ groupId: number }>()),
};
