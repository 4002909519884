<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <span class="form-label section-label">Labels</span>
    <div class="form-row-cols-wrapper settings-row" formGroupName="labels">
      <div class="form-col-line" *ngFor="let label of labels">
        <label for="{{ label.key | translate }}" class="form-label form-label--print">
          {{ labelValues[label.key] | translate }}
        </label>
        <input
          id="{{ label.key }}"
          formControlName="{{ label.key }}"
          type="text"
          class="form-input form-input--print"
        />
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
