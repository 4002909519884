import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { filter, map, Observable } from 'rxjs';

import { ParamsService, PartialNullify, RecommendationsGroupsDesignerService, SettingsCart } from '@bend/store';

import { CartSettingsFormGroup, RecommendationGroupsFormArray } from '@designer-shared/types';

@Injectable()
export class CartSettingsService {
  private readonly SAME_AS_RECOMMENDATIONS = { enabled: null, groups: null };

  private recommendationsForm: RecommendationGroupsFormArray;

  constructor(
    private recommendations: RecommendationsGroupsDesignerService,
    private params: ParamsService,
    private fb: FormBuilder,
  ) {}

  getCartSettingsFormGroup(cart: PartialNullify<SettingsCart>): CartSettingsFormGroup {
    return this.fb.group({
      confirmPayment: cart.confirmPayment,
      redirectUrl: cart.redirectUrl,
      confirmPaymentAsAPopup: cart.confirmPaymentAsAPopup,
      recommendations: this.fb.group({
        enabled: cart.recommendations.enabled,
        groups: this.fb.array<number>(cart.recommendations.groups || []),
      }),
    });
  }

  updateRecommendationsForm(form: RecommendationGroupsFormArray): void {
    this.recommendationsForm = form;
  }

  value(form: CartSettingsFormGroup): SettingsCart {
    if (form.controls.recommendations.controls.enabled.value === null) {
      return {
        ...form.getRawValue(),
        recommendations: this.SAME_AS_RECOMMENDATIONS,
      };
    }

    const recommendationGroups = form.controls.recommendations.controls.groups;

    recommendationGroups.clear();

    this.recommendationsForm.controls.forEach(control => {
      recommendationGroups.push(this.fb.control(control.value.id));
    });

    return form.getRawValue();
  }

  get isRecommendationsFormValid(): boolean {
    return this.recommendationsForm && this.recommendationsForm.valid;
  }

  get recommendationsGroups$(): Observable<{ id: number; name: string }[]> {
    this.getRecommendationsGroups();

    return this.recommendations.recommendationsGroups.pipe(
      filter(groups => !!groups && !!groups.length),
      map(groups => groups.map(group => ({ id: group.id, name: group.name }))),
    );
  }

  private getRecommendationsGroups(): void {
    this.params.appSlug.subscribe(slug => this.recommendations.getRecommendationsGroups(slug));
  }
}
