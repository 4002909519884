import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';

import { DialogService } from '@bend/dialog';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { MyReview, ReviewLabels, ReviewResponse, WidgetReview } from './review';
import { WidgetReviewService } from './review.service';

@Component({
  selector: 'pop-widget-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class WidgetReviewComponent implements OnInit, WidgetComponent {
  static widgetName = 'review';
  @Input() attributes: WidgetReview;

  stars = [1, 2, 3, 4, 5];
  myReview: number;
  averageReview: number;
  isValid: boolean;
  noValidMessage: string;
  labels: ReviewLabels;
  isReadOnly: boolean;

  constructor(
    @Optional() private _dialog: DialogService,
    private _reviewService: WidgetReviewService,
    private _warningService: WarningService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.id) {
      this.noValidMessage = this._warningService.showWarn(WidgetReviewComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this._calculateAverageReview();
    this._addMissingAttributes();
    this.labels = this.attributes.labels;
    this.isReadOnly = this.attributes.isEditMode || this.attributes.pop.validationStatus === PopValidationStatus.FAILED;

    if (this.isReadOnly) {
      this.isValid = true;

      return;
    }

    this._downloadMyReview();
  }

  getStar(index: number): string {
    return index <= this.myReview ? 'star-filled' : 'star-empty-black';
  }

  createReview(star: number): void {
    if (this.isReadOnly) {
      return;
    }

    const previousReview = this.myReview;
    this.myReview = star;

    this._reviewService.review(this.attributes.id, { review: star }).subscribe({
      next: (review: ReviewResponse) => {
        this.attributes.reviewCount = review.attributes.reviewCount;
        this.attributes.reviewSum = review.attributes.reviewSum;
        this.myReview = star;
        this._calculateAverageReview();
        this._cdr.detectChanges();
      },
      error: () => {
        this.myReview = previousReview;
        this._dialog.error({ message: 'Failed to save the review. Please try again.' });
        this._cdr.detectChanges();
      },
    });
  }

  private _calculateAverageReview(): void {
    if (this.attributes.reviewCount) {
      this.averageReview = this.attributes.reviewSum / this.attributes.reviewCount;
    }
  }

  private _downloadMyReview(): void {
    this._reviewService.getMyReview(this.attributes.id).subscribe({
      next: (review: MyReview) => {
        if (review && review.review) {
          this.myReview = review.review;
        }
        this.isValid = true;
        this._cdr.detectChanges();
      },
      error: () => {
        this.isValid = true;
        this._cdr.detectChanges();
      },
    });
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }

    if (!this.attributes.labels) {
      this.attributes.labels = new ReviewLabels();
    }
  }
}
