import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UsersEffects } from '@designer-store/users/users.effects';
import { usersReducer } from '@designer-store/users/users.reducer';
import { DESIGNER_USERS_STATE_KEY } from '@designer-store/users/users.selectors';
import { UsersDesignerStoreService } from '@designer-store/users/users.service';
import { UsersHttpService } from '@designer-store/users/users-http.service';

@NgModule({
  imports: [StoreModule.forFeature(DESIGNER_USERS_STATE_KEY, usersReducer), EffectsModule.forFeature([UsersEffects])],
  providers: [UsersDesignerStoreService, UsersHttpService],
})
export class UsersStoreModule {}
