import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { allPaths, parseQueryParams, parseUrl } from '../../helpers';
import { QueryParams } from '../../types';

@Injectable()
export class HistoryService {
  private _home: string;
  private _isPopState: boolean;
  private _stackKey: string;
  private _stack: string[];
  private _currentUrlChanged: BehaviorSubject<string>;
  private _stackSizeChanged: BehaviorSubject<number>;

  constructor(private _router: Router) {
    this._isPopState = false;

    this._stackKey = 'history-stack';
    this._stack = [];
    this._currentUrlChanged = new BehaviorSubject('/');
    this._stackSizeChanged = new BehaviorSubject(0);
  }

  init(): Subscription {
    return this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) this._isPopState = event.navigationTrigger === 'popstate';

      if (event instanceof NavigationEnd) {
        const { url: originUrl } = event;

        /**
         * remove unauthorized query parameters
         */
        const url = this._removeQueryParameter(originUrl);
        /**
         * skip canceled routing
         */
        if (url === '/') return;
        /**
         * if location is not changed only router updated not change history
         * check url is equal with current url
         */
        if (url === this.currentUrl) return;
        /**
         * when user access page for first time url is added in stack
         *
         * when user made refresh we add in stack but after that stack is changed with values from session
         */
        this._pushStack(url);

        if (!this._home) {
          this._home = this._constructHome();
          /**
           * check if user change the location from location bar
           */
          if (this._checkLocationChangedFromBar(event)) this._home = this._constructHome();
          /**
           * initialization stack from local session to have previously history
           */
          this._initStackFromSession();
        } else {
          /**
           * update stack after initialization
           */
          this._updateStackInSession();
        }

        this._currentUrlChanged.next(url);
      }
    });
  }

  get currentUrlChanged(): Observable<string> {
    return this._currentUrlChanged.asObservable();
  }

  get stackSizeChanged(): Observable<number> {
    return this._stackSizeChanged.asObservable();
  }

  get backUrl(): string {
    /**
     * remove current url from stack
     */
    this._stack.pop();
    /**
     * get previous url from stack
     */
    const previousUrl = this._stack.pop();
    /**
     * update stack in session storage
     */
    this._updateStackInSession();

    if (previousUrl) return previousUrl;

    return this._home;
  }

  get currentUrl(): string {
    if (!this._stack.length) return '';

    return this._stack[this._stack.length - 1];
  }

  get previousUrl(): string {
    if (!this._stack.length) return '';

    return this._stack[this._stack.length - 2];
  }

  get home(): string {
    return this._home;
  }

  get isPopState(): boolean {
    return this._isPopState;
  }

  private _pushStack(url: string): void {
    /**
     * back behavior
     * we have pages: 1, 2, 3
     * user go back:  1
     * if 1 !== 1/2
     *
     * next behavior
     * we have pages: 1, 2
     * user go next:  1, 2, 3
     * if 1/2 !== 1/2/3
     *
     * change query params behavior
     * we have pages: 1, 2
     * user change params:  1, 2
     * if 1/2 === 1/2
     */
    const { root: nextRoot } = this._router.parseUrl(url);

    const nextPaths = allPaths(nextRoot);

    /**
     * remove the path if the user closes the page from an action other than backward
     *
     * "/kryshac/5f7f12fdba94eb0012ec4e55?place=restaurant-1_1",
     * "/kryshac/5e0e3ae497302d001209f24c?place=restaurant-1_1",
     * "/kryshac/5e0e3ae497302d001209f24c/(page:cart)?place=restaurant-1_1",
     * "/kryshac/5e0e3ae497302d001209f24c/(page:cart/collect)?place=restaurant-1_1"
     *
     * when user go to
     * "/kryshac/5f7f12fdba94eb0012ec4e55?place=restaurant-1_1",
     *
     * need to remove
     * "/kryshac/5e0e3ae497302d001209f24c?place=restaurant-1_1",
     * "/kryshac/5e0e3ae497302d001209f24c/(page:cart)?place=restaurant-1_1",
     * "/kryshac/5e0e3ae497302d001209f24c/(page:cart/collect)?place=restaurant-1_1"
     *
     * and after that push
     * "/kryshac/5e0e3ae497302d001209f24c?place=restaurant-1_1",
     */
    const index = this._stack.findIndex(itemStack => {
      const { root } = this._router.parseUrl(itemStack);
      const paths = allPaths(root);

      return paths.join('/') === nextPaths.join('/');
    });

    if (index >= 0) this._stack.splice(index);

    this._stack.push(url);
  }

  private _constructHome(): string {
    /**
     * key which will be set in localSession
     */
    const key = 'history-home-page';
    /**
     * get home url from localSession
     */
    const currentHome = window.sessionStorage.getItem(key);
    /**
     * if user have home url in local session
     * return that url
     */
    if (currentHome) return currentHome;
    /**
     * create home path without custom routing
     */
    const path = parseUrl(this.currentUrl)
      /**
       * key 0 is appSlug
       * key 1 is pageId
       */
      .slice(0, 2)
      .join('/');

    const queryParams = Object.entries(parseQueryParams(this.currentUrl))
      .map(([queryKey, queryValue]) => `${queryKey}=${queryValue}`)
      .join('&');

    const home = `${path}?${queryParams}`;

    /**
     * set home url in localSession
     */
    window.sessionStorage.setItem(key, home);

    return home;
  }

  private _checkLocationChangedFromBar({ id }: NavigationEnd): boolean {
    /**
     * if id is not equal to 1,
     * then there are no first entries in locations and nothing needs to be changed in stack
     */
    if (id !== 1) return false;

    const [previousAppSlug] = parseUrl(this._home);
    const [currentAppSlug] = parseUrl(this.currentUrl);

    /**
     * if the appSlug is the same we keep the history after refresh
     */
    if (previousAppSlug === currentAppSlug) return false;

    window.sessionStorage.clear();

    return true;
  }

  private _initStackFromSession(): void {
    /**
     * get stack string from local session
     */
    const stackString = window.sessionStorage.getItem(this._stackKey);
    /**
     * check if user have stack in local session
     */
    if (!stackString) return;
    /**
     * convert string stack in string[]
     */
    const stack = JSON.parse(stackString);
    /**
     * set stack from local session
     */
    this._stack = stack;

    this._stackSizeChanged.next(this._stack.length);
  }

  private _updateStackInSession(): void {
    /**
     * convert stack in string
     */
    const stackString = JSON.stringify(this._stack);
    /**
     * set stack in session storage
     */
    window.sessionStorage.setItem(this._stackKey, stackString);

    this._stackSizeChanged.next(this._stack.length);
  }

  private _removeQueryParameter(url: string): string {
    const queries = Object.values(QueryParams);

    // eslint-disable-next-line
    const regExp = new RegExp(`\&?(${queries.join('|')})=[^?&#]*`);

    return url.replace(regExp, '');
  }
}
