import { createAction, props } from '@ngrx/store';

import { DesignerApplication } from '@designer-store/apps/apps.models';

export const appsActions = {
  getApps: createAction('[Apps] Get Apps'),
  getAppsSuccess: createAction('[Apps] Get Apps Success', props<{ applications: DesignerApplication[] }>()),
  getAppsError: createAction('[Apps] Get Apps Error', props<{ error: string }>()),
  getAppsLoaded: createAction('[Apps] Get Apps Loaded'),
};
