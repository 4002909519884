<ng-container *ngIf="styleConfig.menuType === menuTypes.SinglePage; else stepByStep">
  <div class="product-widget__v2">
    <div *ngIf="menus.length > 1" class="menu-select-wrapper">
      <ul
        class="menu-select menu-select--scroll"
        [style.borderColor]="mainColor"
        [class.category-classic]="styleConfig.categoryStyleType === categoryStyleTypes.Style1"
        #menuCategoriesEl
      >
        <li
          *ngFor="let listmenu of menus"
          class="menu-select__option"
          (click)="loadMenuCategories(listmenu)"
          [class.menu-select-option--active]="menu.id === listmenu.id"
          [style.backgroundColor]="styleConfig.categoryStyleType === 1 ? getStyle(listmenu.id) : ''"
          [style.borderColor]="
            styleConfig.categoryStyleType === categoryStyleTypes.Style2 && menu.id === listmenu.id ? mainColor : null
          "
          #menusRef
        >
          <span
            class="menu-item-name"
            [style.color]="
              styleConfig.categoryStyleType === categoryStyleTypes.Style2 && menu.id === listmenu.id
                ? mainColor
                : undefined
            "
            [style.fontWeight]="
              styleConfig.categoryStyleType === categoryStyleTypes.Style2 && menu.id === listmenu.id ? 500 : 400
            "
          >
            {{ listmenu.name }}
            <span *ngIf="listmenu.priceFinalValue">{{ listmenu.priceFinalValue | currency }}</span>
            <app-product-status-checkout
              [product]="listmenu"
              [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
              class="ml-3"
            ></app-product-status-checkout>
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="menu?.description" class="current-category-description">{{ menu?.description }}</div>

  <div
    class="subcategories-section"
    [class.menu-style-extended]="styleConfig.menuItemStyleType === menuItemStyleTypes.Extended"
  >
    <div
      #subcategory
      *ngFor="let category of menuCategories | sortByOrder; let menuCategoryIndex = index"
      class="subcategory"
      [class.ng-category-with-error]="errorCategories.indexOf(category.id) >= 0"
    >
      <div class="menu-subcategory-header" (click)="foldCategory(category)">
        <div class="toggle-arrow">
          <svg
            class="toggle-details-icon"
            [class.toggle-details-icon--rotate]="category.folded"
            fill="#000"
            height="15"
            width="15"
            version="1.1"
            viewBox="0 0 512 512"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M256,213.7L256,213.7L256,213.7l174.2,167.2c4.3,4.2,11.4,4.1,15.8-0.2l30.6-29.9c4.4-4.3,4.5-11.3,0.2-15.5L264.1,131.1  c-2.2-2.2-5.2-3.2-8.1-3c-3-0.1-5.9,0.9-8.1,3L35.2,335.3c-4.3,4.2-4.2,11.2,0.2,15.5L66,380.7c4.4,4.3,11.5,4.4,15.8,0.2L256,213.7  z"
            />
          </svg>
        </div>
        <pop-widget-product-catalog-subcategory
          [subcategoryName]="category.name"
          [mainColor]="mainColor"
          [secondColor]="secondColor"
          [limits]="{ min: category.min, max: category.max }"
          [styleType]="'product-widget__v2 big-image side-by-side'"
        ></pop-widget-product-catalog-subcategory>
      </div>
      <div>
        <span *ngIf="errorCategories.indexOf(category.id) >= 0" class="error-msg">
          {{ 'PRODUCT_CATALOG.PLEASE_SELECT_A_PRODUCT' | translate }}
        </span>
        <div *ngIf="category.description" class="subcategory-details-header">{{ category.description }}</div>
        <div [class.step-menu__side-by-side]="styleConfig.menuItemStyleType === menuItemStyleTypes.SideBySide">
          <ng-container
            *ngFor="let product of category.menuCategoryItems | sortByOrder; let menuCategoryItemIndex = index"
          >
            <ng-container
              *ngIf="product.item.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
            >
              <div
                class="product-item-wrapper"
                *ngIf="!category.folded"
                [attr.data-cy]="'menuCategoryItem-' + menuCategoryIndex + '-' + menuCategoryItemIndex"
              >
                <pop-widget-product-catalog-item
                  [product]="product.item"
                  [currency]="currency"
                  [isMenu]="true"
                  (loadProductDetailsEmitter)="
                    loadProductDetails(product.item, product.priceFinalValue, category.id, $event, category)
                  "
                  [mainColor]="mainColor"
                  [secondColor]="secondColor"
                  [isReadOnly]="isReadOnlyMenu || isReadOnly"
                  [isConsultingMode]="isConsultingMode"
                  [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                  [styleNumber]="styleConfig.menuItemStyleType"
                  [styleType]="styleType"
                  [forceShowProductDescription]="forceShowProductDescription"
                ></pop-widget-product-catalog-item>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isConsultingMode && !isReadOnly && menuCategories.length > 0">
    <div [class.menu-style-extended]="styleConfig.menuItemStyleType === menuItemStyleTypes.Extended">
      <div class="add-comment-wrapper" *ngIf="showKitchenComment">
        <pop-comment-to-kitchen
          (onChanged)="onCommentChanged($event)"
          [mainColor]="mainColor"
          [resetCommentAndCloseEmitter]="resetCommentAndCloseEmitter"
          [styleConfig]="styleConfig"
        ></pop-comment-to-kitchen>
        <div *ngIf="showErrors && isCommentToKitchenInvalid" class="error-msg">
          {{ 'PRODUCT_CATALOG.COMMENT_TO_KITCHEN_REQUIRED' | translate }}
        </div>
      </div>

      <app-product-status-checkout
        [product]="menu"
        [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
        class="product-status"
      >
        <button
          data-cy="addToCartBtn"
          (click)="addToCart()"
          class="add-to-cart-btn"
          [style.backgroundColor]="canAddToCart ? mainColor : 'gray'"
          [disabled]="!canAddToCart"
        >
          <ng-container *ngIf="canAddToCart; else choose">
            {{ 'PRODUCT_CATALOG.ADD_TO_CART' | translate }} -
            {{ total | currency }}
          </ng-container>
          <ng-template #choose>{{ 'PRODUCT_CATALOG.CHOOSE_PRODUCT' | translate }}</ng-template>
          <pop-widget-spinner *ngIf="isLoading" [width]="35" [height]="20"></pop-widget-spinner>
        </button>
      </app-product-status-checkout>
    </div>
  </ng-container>
</ng-container>

<ng-template #stepByStep>
  <div class="step-menu">
    <div [class.step-menu__side-by-side]="styleConfig.menuStyleType === menuStyleTypes.SideBySide">
      <div *ngFor="let menu of menus" class="product-item-wrapper" [class.margin-bottom]="styleConfig.style === 4">
        <pop-menu-catalog-item
          *ngIf="menu.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
          (click)="navigate(menu)"
          [menuItem]="menu"
          [currency]="currency"
          [isReadOnly]="isReadOnly"
          [isConsultingMode]="isConsultingMode"
          [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
          [photoItem]="styleConfig.menuStyleType === 3"
          [menuStyleType]="styleConfig.menuStyleType"
        ></pop-menu-catalog-item>
      </div>
    </div>
    <div *ngIf="showStepper && menu">
      <pop-step-by-step-menu
        (closeStepper)="changeStepperState($event)"
        [menu]="menu"
        [currency]="currency"
        [mainColor]="mainColor"
        [secondaryColor]="secondColor"
        [styleType]="menuStyleType"
        [styleConfig]="styleConfig"
        [pageAnalytics]="pageAnalytics"
        [menuItemAdded]="menuItemAdded"
        [isReadOnly]="isReadOnly"
        [isConsultingMode]="isConsultingMode"
        [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
        [forceShowProductDescription]="forceShowProductDescription"
      ></pop-step-by-step-menu>
    </div>
  </div>
</ng-template>
