import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { DialogService } from '@bend/dialog';
import { StorageService } from '@bend/storage';
import { emailValidator } from '@bend/widgets-new/validators/email.validator';

import { ContactLabels, WidgetContact } from '../contact';
import { WidgetContactService } from '../contact.service';

@Component({
  selector: 'pop-widget-contact-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class WidgetContactDialogComponent implements OnInit {
  form: UntypedFormGroup;
  isSent = false;
  showErrors = false;
  labels: ContactLabels;

  constructor(
    public dialogRef: MatDialogRef<WidgetContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetContact,
    private _formBuilder: UntypedFormBuilder,
    private _contactService: WidgetContactService,
    private _localStorage: StorageService,
    private _dialog: DialogService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.labels = this.data.labels;
    this.form = this._formBuilder.group({
      name: [this._localStorage.userName || '', [Validators.required]],
      email: [this._localStorage.email || '', [Validators.required, emailValidator]],
      phone: [this._localStorage.phone || ''],
      context: [this.data.context],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    this.showErrors = false;
    this._contactService.sendContact(this.data.pageId, this.form.value).subscribe({
      next: () => {
        this.isSent = true;

        setTimeout(() => {
          this.dialogRef.close();
          this._cdr.detectChanges();
        }, 3000);
        this._cdr.detectChanges();
      },
      error: () => this._dialog.error({ message: 'Failed to save. Please try again.' }),
    });
  }
}
