import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CartService } from '@bend/store';
import { SelectPaymentTypeService } from '@bend/widgets-new/cart/services';

@Injectable()
export class SavedCartService {
  constructor(private _selectPaymentType: SelectPaymentTypeService, private _cart: CartService) {}

  get isShow(): Observable<boolean> {
    /**
     * @description show saved card if not have select-box for payment methods
     */
    return this._selectPaymentType.isShow.pipe(map(isShow => !isShow));
  }

  get haveSavedCard(): Observable<boolean> {
    return this._cart.creditCart.pipe(map(method => !!method?.savedCards?.length));
  }
}
