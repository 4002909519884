<div [formGroup]="form">
  <div formArrayName="{{ actionButtonArray }}" class="form_row">
    <div [formGroupName]="arrayItem" class="form-row-groups form-row-groups-wrapper">
      <div class="form-row-group form-row-group--distanced col-width">
        <label class="form-label phone-label">Phone nr</label>
        <input type="text" formControlName="telephone" class="title-input form-input input-width" />
      </div>
    </div>
  </div>
</div>
