import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { ItemUnavailabilityDisplay } from '@bend/store';
import { SettingsService } from '@bend/store';
import { MenuStyleType } from '@bend/store-shared';

import { PopCurrency } from '../../widget';
import { PopMenu } from '../product-catalog';

export interface MenuItem {
  name: string;
  description: string;
  priceFinalValue: number;
  imageUrl: string;
  isSelected: boolean;
  isOutOfStock: boolean;
}

@Component({
  selector: 'pop-menu-catalog-item',
  templateUrl: './menu-catalog-item.component.html',
  styleUrls: ['./menu-catalog-item.component.scss'],
})
export class MenuCatalogItemComponent {
  @Input() menuItem: PopMenu;
  @Input() currency: PopCurrency;
  @Input() photoItem: boolean;
  @Input() menuStyleType: number;
  @Input() isReadOnly: boolean;
  @Input() isConsultingMode: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  fgColor$: Observable<string>;
  styleTypes = MenuStyleType;

  constructor(private _settings: SettingsService) {
    this.fgColor$ = this._settings.colors.primaryForeground;
  }
}
