<div
  *ngIf="isValid; else elseBlock"
  class="widget-text"
  [innerHTML]="text$ | async"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
></div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-text">{{ noValidMessage }}</div>
</ng-template>
