import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import { Carousel } from './carousel';

@Injectable()
export class CarouselService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getCarousels(): Observable<Carousel[]> {
    return this._api.carousels('v1').pipe(switchMap(api => this._http.get<Carousel[]>(api)));
  }
}
