import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { IconModule } from '@bend/icon';

import {
  CheckboxComponent,
  ConditionalTabComponent,
  InputComponent,
  InputDateComponent,
  InputErrorsComponent,
  InputNumberComponent,
  InputPercentageComponent,
  LoaderComponent,
  QueryParamValidatorComponent,
  RadioComponent,
  SelectBoxComponent,
  SnackComponent,
  TextAreaComponent,
  TimeValidatorComponent,
  WidgetComponent,
} from './components';
import { EmojiPickerComponent } from './components/emoji-picker/emoji-picker.component';
import { LinkSelectorComponent } from './components/link-selector/link-selector.component';
import { PageSelectorComponent } from './page-selector/page-selector.component';
import { WidgetFormService } from './services';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    IconModule,
    TranslateModule,
    NgxMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PickerModule,
  ],
  declarations: [
    PageSelectorComponent,
    ConditionalTabComponent,
    WidgetComponent,
    TimeValidatorComponent,
    QueryParamValidatorComponent,
    InputComponent,
    InputErrorsComponent,
    SelectBoxComponent,
    CheckboxComponent,
    RadioComponent,
    SnackComponent,
    LinkSelectorComponent,
    InputNumberComponent,
    LoaderComponent,
    InputPercentageComponent,
    InputDateComponent,
    TextAreaComponent,
    EmojiPickerComponent,
  ],
  exports: [
    PageSelectorComponent,
    WidgetComponent,
    InputComponent,
    SelectBoxComponent,
    CheckboxComponent,
    RadioComponent,
    LinkSelectorComponent,
    InputNumberComponent,
    LoaderComponent,
    InputPercentageComponent,
    InputDateComponent,
    TextAreaComponent,
  ],
  providers: [WidgetFormService, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }],
})
export class SharedModule {}
