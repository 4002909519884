<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="tweetUrlInput" class="form-label">{{ 'WIDGETS.TWEET.URL' | translate }}</label>
      <div
        *ngIf="url.invalid && (((url.dirty || url.touched) && url.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.TWEET.URL_ENTER' | translate }}
      </div>
      <input
        id="tweetUrlInput"
        type="url"
        class="form-input form-input--url form-input--tooltip"
        placeholder="URL"
        formControlName="url"
      />
      <div class="form-field-info">
        {{ 'WIDGETS.TWEET.CORRECT_URL' | translate }}
        <br />
        {{ 'WIDGETS.TWEET.CORRECT_URL_ONE' | translate }}
        <br />
        {{ 'WIDGETS.TWEET.CORRECT_URL_TWO' | translate }}
        <br />
        {{ 'WIDGETS.TWEET.CORRECT_URL_EX' | translate }}
        <pre class="preformatted-code-example">
&lt;blockquote class="twitter-tweet" data-lang="en"&gt;
  &lt;p lang="en" dir="ltr"&gt;
    &lt;a href="https://twitter.com/hashtag/CognitiveEra?src=hash&amp;ref_src=twsrc%5Etfw"&gt;#CognitiveEra&lt;/a&gt;
  &lt;/p&gt;
&lt;/blockquote&gt;</pre
        >
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
