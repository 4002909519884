import { createAction, props } from '@ngrx/store';

import { createType, Settings } from '../shared';
import { storeKey } from './settings.config';

export type settingsResponse = { settings: Settings & { id: string } };
export type settingsResponseBy = { settings: Settings & { id: string }; by: string };

const type = createType(storeKey);

export const settingsActions = {
  getSettings: createAction(type('get settings'), props<{ by: string }>()),
  getSettingsIsLoaded: createAction(type('get settings is loaded')),
  getSettingsSuccess: createAction(type('get settings success'), props<settingsResponseBy>()),
  getSettingsError: createAction(type('get settings error'), props<{ error: string }>()),

  updateSettings: createAction(type('update settings'), props<{ by: string }>()),
  updateSettingsSuccess: createAction(type('update settings success'), props<settingsResponse>()),
  updateSettingsError: createAction(type('update settings error'), props<{ error: string }>()),

  getSettingsByPlace: createAction(type('get settings by place'), props<{ by: string }>()),
  getSettingsByPlaceIsLoaded: createAction(type('get settings by place is loaded')),
  getSettingsByPlaceSuccess: createAction(type('get settings by place success'), props<settingsResponseBy>()),
  getSettingsByPlaceError: createAction(type('get settings by place error'), props<{ error: string }>()),

  updateSettingsByPlace: createAction(type('update settings by place'), props<{ by: string }>()),
  updateSettingsByPlaceSuccess: createAction(type('update settings by place success'), props<settingsResponse>()),
  updateSettingsByPlaceError: createAction(type('update settings by place error'), props<{ error: string }>()),
};
