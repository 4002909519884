import { Pipe, PipeTransform } from '@angular/core';

import { Application } from '@bend/store/src/lib/shared/types/image-gallery.type';

@Pipe({ name: 'sortApp' })
export class SortAppListPipe implements PipeTransform {
  transform(list: Application[], appSlug: string): Application[] {
    if (!list.length || !list.some(app => app.appSlug === appSlug)) {
      return [{ appSlug: appSlug, countFiles: 0, name: appSlug }, ...list];
    }

    if (list[0].appSlug === appSlug) return list;

    const app: Application = list.find(app => app.appSlug === appSlug);
    return [app, ...list.filter(app => app.appSlug != appSlug)];
  }
}
