import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './order-menu-items.config';
import { metaReducers, reducer } from './order-menu-items.reducer';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer, { metaReducers })],
})
export class OrderMenuItemsStoreModule {}
