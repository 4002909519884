import { ItemError, OrderItemOptionApi, OrderItemStatus } from './order-item.type';

export interface OrderMenuItem {
  name: string;
  quantityMultiplier?: number;
  sku: string;
  id?: number;
  priceFinalValue: number;
  productionOrder?: number | null;
  orderItemId: number;
  orderUserId: number;
  orderId: number;
  errorCode: ItemError;
  status?: OrderItemStatus;
  quantity: number;
  categoryId?: number;
  options?: OrderItemOptionApi[];
}

export interface OrderMenuItemApi extends Omit<OrderMenuItem, 'orderItemIds' | 'id'> {
  options?: OrderItemOptionApi[];
  id?: OrderMenuItem['id'];
}
