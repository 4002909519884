export interface Auth {
  token: string;
  refreshToken: string;
}

export interface Profile {
  name: string;
  email: string;
  phone: string;
  requiresConfirmation?: boolean;
  allowNewsletters?: boolean;
}

export interface ConfirmCode {
  phone: string;
  code: string;
}

export interface SettingsSignIn {
  enabled: boolean;
}
