import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Cart, PaymentMethods } from '../shared';
import { actions } from './cart.actions';
import { State } from './cart.type';

export const adapter = createEntityAdapter<Cart>();

export const initialState: State<number> = {
  isLoading: 0,
  paymentMethods: {},
  credit: {
    selected: false,
    value: null,
  },
};

const widgetReducer = createReducer(
  initialState,

  on(actions.getPaymentMethods, actions.deletePaymentMethod, actions.getCredit, actions.getWorkingHours, state => ({
    ...state,
    isLoading: state.isLoading + 1,
  })),

  on(
    actions.getPaymentMethodsError,
    actions.deletePaymentMethodError,
    actions.getCreditError,
    actions.getWorkingHoursError,
    state => ({ ...state, isLoading: state.isLoading - 1 }),
  ),

  on(actions.getPaymentMethodsSuccess, (state, { paymentMethods }) => ({
    ...state,
    paymentMethods,
    isLoading: state.isLoading - 1,
  })),

  on(actions.deletePaymentMethodSuccess, (state, { id }) => ({
    ...state,
    paymentMethods: {
      ...state.paymentMethods,
      [PaymentMethods.CreditCard]: {
        ...state.paymentMethods[PaymentMethods.CreditCard],
        savedCards: state.paymentMethods[PaymentMethods.CreditCard].savedCards.filter(card => card.id !== id),
      },
    },
    isLoading: state.isLoading - 1,
  })),

  on(actions.getCreditSuccess, (state, { credit }) => ({
    ...state,
    credit: { ...state.credit, value: credit },
    isLoading: state.isLoading - 1,
  })),

  on(actions.selectCredit, (state, { selected }) => ({ ...state, credit: { ...state.credit, selected } })),

  on(actions.getWorkingHoursSuccess, (state, { workingHours }) => ({
    ...state,
    workingHours,
    isLoading: state.isLoading - 1,
  })),
);

export function reducer(state: State<number> | undefined, action: Action): State<number> {
  return widgetReducer(state, action);
}
