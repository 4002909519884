import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LanguagesConfig } from '@bend/store';

@Component({
  selector: 'translate-language-button [language]',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageButtonComponent {
  /**
   * Language showed in button
   */
  @Input() language!: LanguagesConfig;
  /**
   * Is language have errors
   */
  @Input() error = false;
  /**
   * Is need to show icon
   */
  @Input() showIcon = false;
  /**
   * Is button disabled
   */
  @Input() disabled = false;
  /**
   * Emit event on click
   */
  @Output() clicked = new EventEmitter<void>();

  onClick(): void {
    this.clicked.emit();
  }
}
