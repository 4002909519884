import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class WidgetFormService {
  private _form: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  getControl(controlName: string): AbstractControl {
    return this._form.get(controlName);
  }

  get getAllControls(): UntypedFormGroup {
    return this._form;
  }

  get getAttributes(): AbstractControl {
    return this._form.get('attributes');
  }

  initAttributes(form: AbstractControl): void {
    this._form = this._formBuilder.group({ attributes: form });
  }

  addControl(controlName: string, control: AbstractControl): void {
    this.removeControl(controlName);
    this._form.addControl(controlName, control);
  }

  removeControl(controlName: string): void {
    this._form.removeControl(controlName);
  }
}
