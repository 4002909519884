import { createAction, props } from '@ngrx/store';

import { createType } from '../shared';
import { storeKey } from './credit-history.config';
import { GetUserTransactions, GetUserTransactionsSuccess } from './credit-history.type';

const type = createType(storeKey);

export const actions = {
  getUserTransactions: createAction(type('get user transactions'), props<GetUserTransactions>()),
  getUserTransactionsSuccess: createAction(type('get user transactions success'), props<GetUserTransactionsSuccess>()),
  getUserTransactionsError: createAction(type('get user transactions error')),
};
