<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-fieldset-groups">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.DOCUMENT.DOCUMENTS' | translate }}</span>
      <div class="form-fieldset-groups__content" formArrayName="documents">
        <div *ngFor="let document of documentsControls; let i = index" class="form-fieldset-group">
          <div [formGroupName]="i">
            <div class="form-row-groups">
              <pop-widget-edit-file-upload
                [title]="'WIDGETS.DOCUMENT.DOCUMENT' | translate"
                [fileUrl]="document.controls.url.value"
                [fileName]="document.controls.name.value"
                [fileType]="document.controls.type.value"
                [fileSize]="document.controls.size.value"
                (fileChangeEmitter)="onFileChange($event, i)"
              ></pop-widget-edit-file-upload>
            </div>
            <div class="form-row">
              <label for="nameInput" class="form-label">{{ 'WIDGETS.DOCUMENT.DOCUMENT_NAME' | translate }}</label>
              <div
                *ngIf="
                  document.controls.name.invalid &&
                  (((document.controls.name.dirty || document.controls.name.touched) &&
                    document.controls.name.errors.required) ||
                    showErrors)
                "
                class="form-error-msg"
              >
                {{ 'WIDGETS.DOCUMENT.NAME_ENTER' | translate }}
              </div>
              <input
                id="nameInput"
                class="form-input form-input--text"
                type="text"
                [placeholder]="'WIDGETS.DOCUMENT.DOCUMENT_NAME' | translate"
                formControlName="name"
              />
            </div>
            <div class="form-row">
              <label for="typeInput" class="form-label">{{ 'WIDGETS.DOCUMENT.DOCUMENT_TYPE' | translate }}</label>
              <input
                id="typeInput"
                class="form-input form-input--extension"
                type="text"
                [placeholder]="'WIDGETS.DOCUMENT.DOCUMENT_TYPE' | translate"
                formControlName="type"
              />
              <span class="form-field-info">Ex: docx, pptx, etc.</span>
            </div>
            <div class="form-row clearfix">
              <button
                *ngIf="documentsControls.length > 1"
                class="btn-flat btn-flat--remove"
                type="button"
                (click)="removeDocument(i)"
              >
                <span class="hyphen">-</span>
                {{ 'WIDGETS.DOCUMENT.REMOVE_DOCUMENT' | translate }}
              </button>
            </div>
          </div>
        </div>
        <button class="btn-flat btn-flat--add-new-item" type="button" (click)="addDocument()">
          {{ 'WIDGETS.DOCUMENT.ADD_DOCUMENT' | translate }}
        </button>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
