<div class="controls">
  <button mat-raised-button (click)="openDialog()" type="button" color="primary">
    {{ 'STUDIO.IMAGE_GALLERY.SELECT_IMAGE' | translate }}
  </button>
  <button mat-raised-button (click)="deleteImage()" type="button" color="warn">
    {{ 'STUDIO.IMAGE_GALLERY.DELETE_IMAGE' | translate }}
  </button>
</div>

<designer-image
  [imageUrl]="imageUrl"
  [showDescription]="false"
  width="300"
  height="250"
  [isVideo]="isVideoFile(imageUrl)"
></designer-image>
