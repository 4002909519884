import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { WidgetDocumentViewer } from '../document-viewer';

@Component({
  selector: 'pop-document-viewer-dialog',
  templateUrl: './document-viewer-dialog.component.html',
  styleUrls: ['./document-viewer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetDocumentViewerDialogComponent implements OnInit {
  url: SafeResourceUrl;

  constructor(@Inject(MAT_DIALOG_DATA) public attributes: WidgetDocumentViewer, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.url = this._sanitizer.bypassSecurityTrustResourceUrl(
      `https://arender.io/ARender/?uuid=${this.attributes.uuid}&topPanel.logo=false&topPanel.documentMenu=false&topPanel.print=false&topPanel.annotationMenu=false&topPanel.zoom.in=true&topPanel.zoom.out=true&topPanel.zoom.zone=false&topPanel.zoom.fullWidth=false&topPanel.zoom.fullHeight=false&topPanel.zoom.fullPage=false&topPanel.zoom.zoneGlass=false&topPanel.rotation.right=false&topPanel.rotation.left=false&topPanel.fullscreen=false&topPanel.obfuscate=false&topPanel.refresh=false&contextualMenu.enabled=false&zoom.type=FullWidth`,
    );
  }
}
