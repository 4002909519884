import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiDesignerService,RecommendationsGroupDesigner } from '../shared';

@Injectable()
export class RecommendationsGroupsDesignerHttpService {
  constructor(private _api: ApiDesignerService, private _http: HttpClient) {}

  getRecommendationGroups(): Observable<RecommendationsGroupDesigner[]> {
    return this._api
      .recommendationGroups('v1')
      .pipe(switchMap(api => this._http.get<RecommendationsGroupDesigner[]>(api)));
  }

  addRecommendationGroup(body: RecommendationsGroupDesigner): Observable<RecommendationsGroupDesigner> {
    return this._api.recommendationGroups('v1').pipe(
      switchMap(api => this._http.post<RecommendationsGroupDesigner>(api, body)),
      this._addRecommendationGroupId(),
    );
  }

  saveRecommendationGroup(body: RecommendationsGroupDesigner): Observable<RecommendationsGroupDesigner> {
    return this._api.recommendationGroup('v1', body.id).pipe(
      switchMap(api => this._http.patch<RecommendationsGroupDesigner>(api, body)),
      this._addRecommendationGroupId(),
    );
  }

  deleteRecommendationGroup(id: number): Observable<{ id: string }> {
    return this._api.recommendationGroup('v1', id).pipe(switchMap(api => this._http.delete<{ id: string }>(api)));
  }

  private _addRecommendationGroupId(): OperatorFunction<RecommendationsGroupDesigner, RecommendationsGroupDesigner> {
    return map(({ recommendations, id, ...rest }) => ({
      ...rest,
      id,
      recommendations: recommendations.map(recommendation => ({ recommendationGroupId: id, ...recommendation })),
    }));
  }
}
