import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload/file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { ContactLabel, ContactLabels, ContactLabelsNames, ContactWidget } from './contact';

@Component({
  selector: 'pop-widget-edit-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditContactComponent extends WidgetBaseComponent<ContactWidget> implements OnInit {
  static widgetName = 'contact';
  labels: ContactLabel[];
  labelsNames = ContactLabelsNames;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.labels = this._getLabels();
    this._initForm();
  }

  onFileChange(event: FileUploadDetails): void {
    this.imageUrl.setValue(event.url);
  }

  private _initForm(): void {
    const labelGroup = this._getLabelGroup(this.labels, this.widget);

    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        imageUrl: [this.widget.attributes.imageUrl],
        alt: [this.widget.attributes.alt],
        context: [this.widget.attributes.context],
        labels: this._formBuilder.group(labelGroup),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _getLabelGroup(labels: ContactLabel[], widget: ContactWidget): Record<string, string> {
    const group = {};

    labels.forEach(label => {
      group[label.key] = [widget.attributes.labels[label.key]];
    });

    return group;
  }

  private _getLabels(): ContactLabel[] {
    const feedbackLabels = ContactLabels;
    const labels = Object.keys(feedbackLabels).map(key => {
      return {
        key,
        name: feedbackLabels[key],
      };
    });

    return labels;
  }

  private _addMissingAttributes(widget: ContactWidget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = ContactLabels;
    }
  }

  get imageUrl(): UntypedFormControl {
    return this.form.get('imageUrl') as UntypedFormControl;
  }
}
