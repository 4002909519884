import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StylesService } from '../../../catalog-services';

@Component({
  selector: 'app-nav-bar-item-category',
  templateUrl: './nav-bar-item-category.component.html',
  styleUrls: ['./nav-bar-item-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarItemCategoryComponent {
  @Input() path: string | number;
  @Input() isDefault: boolean;

  itemStyle$: Observable<object>;

  constructor(private _router: Router, private _route: ActivatedRoute, private _styles: StylesService) {
    this.itemStyle$ = this._itemStyle;
  }

  navigateTo(): void {
    this._router.navigate(['./'], {
      relativeTo: this._route,
      fragment: String(this.path),
      queryParamsHandling: 'merge',
    });
  }

  private get _itemStyle(): Observable<any> {
    return this._route.fragment.pipe(
      switchMap(fragment => (fragment === this.path ? this._selectedStyle : this._unselectedStyle)),
    );
  }

  private get _selectedStyle(): Observable<object> {
    return this._styles.categoryPrimaryBg$.pipe(
      map(primary => ({
        border: `1px solid ${primary}`,
        borderRadius: '20px',
        textShadow: '.1px 0px .1px,-.1px 0px .1px',
      })),
    );
  }

  private get _unselectedStyle(): Observable<object> {
    return of({
      color: '#000',
      border: '1px solid #fff',
      backgroundColor: '#fff',
      borderRadius: 'unset',
      textShadow: 'unset',
    });
  }
}
