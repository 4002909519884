<div class="element">
  <div
    [class.element__border--error]="isShowError"
    [class.element__border--disabled]="isDisabled"
    class="element__border"
  ></div>
  <input
    #input
    [formControl]="control"
    [placeholder]="placeHolder"
    [name]="name"
    [class.element__input--disabled]="isDisabled"
    class="element__input"
    [owlDateTimeTrigger]="owlDate"
    [owlDateTime]="owlDate"
    readonly
  />
  <owl-date-time #owlDate></owl-date-time>
</div>
<app-input-errors [ngControl]="ngControl" [showError]="isShowError"></app-input-errors>
