import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ViewportService } from '@bend/viewport';

import { LinkService } from '../link.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { PopButton, WidgetButtons, WidgetButtonsStyleButton } from './buttons';

@Component({
  selector: 'pop-widget-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class WidgetButtonsComponent implements WidgetComponent, OnInit {
  static widgetName = 'buttons';
  @Input() attributes: WidgetButtons;

  isValid: boolean;
  noValidMessage: string;
  isMobile$: Observable<boolean>;

  constructor(
    private _warningService: WarningService,
    private _linkService: LinkService,
    private _viewport: ViewportService,
  ) {
    this.isMobile$ = this._viewport.isMobile;
  }

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.buttons || !this.attributes.buttons.length) {
      this.noValidMessage = this._warningService.showWarn(WidgetButtonsComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this._addMissingAttributes(this.attributes);
    this.isValid = true;
  }

  navigate(button: PopButton): void {
    this._linkService.navigate(button.link, button.openInNewTab, this.attributes.isEditMode);
  }

  private _addMissingAttributes(attributes: WidgetButtons): void {
    if (attributes.style.showBackground === undefined) {
      attributes.style.showBackground = true;
    }

    if (!attributes.style.button) {
      attributes.style.button = new WidgetButtonsStyleButton();
    }
  }
}
