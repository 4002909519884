<button
  [type]="type"
  (click)="click()"
  [style.backgroundColor]="bgPrimary$ | async"
  [style.color]="fgPrimary$ | async"
  class="button"
>
  <span class="button__text">
    <ng-content></ng-content>
  </span>
  <app-spinner @toggleX *ngIf="loading" class="button__loading"></app-spinner>
</button>
