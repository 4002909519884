<div
  *ngIf="isValid; else elseBlock"
  class="widget-product-teaser"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.marginRight.px]="attributes.style?.fullWidth ? 0 : ''"
  [style.marginLeft.px]="attributes.style?.fullWidth ? 0 : ''"
  [style.borderRadius.px]="attributes.style?.borderRadius"
  [style.backgroundColor]="attributes.style?.backgroundColor"
  (click)="showProduct()"
>
  <img *ngIf="imageUrl" alt="" [src]="imageUrl" class="img" width="100%" />
  <div
    class="text-overlay noselect"
    *ngIf="text"
    [style.padding.px]="attributes.style?.text?.margin"
    [style.top.%]="textTop"
    [style.transform]="textTransform"
    [style.background]="attributes.style?.text?.backgroundColor"
    [innerHTML]="text"
  ></div>
  <div class="text-overlay-center" *ngIf="!text && product">{{ product.name }}</div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-image">{{ noValidMessage }}</div>
</ng-template>
