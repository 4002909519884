import { SettingsGoogleAnalytics, SettingsHotjarAnalytics } from './analytics.type';
import { SettingsBehavior } from './behavior.type';
import { BottomBar } from './bottom-bar.type';
import { CapacityManagement } from './capacity.type';
import { SettingsCart } from './cart.type';
import { SettingsCatalog } from './catalog.type';
import { DeliverySettings } from './delivery.type';
import { Gdpr } from './gdpr.type';
import { DictionaryHappyHoursIntervals, HappyHours } from './happy-hours.type';
import { LanguagesConfig } from './languages.config';
import { SettingsPayment } from './payment.type';
import { SettingsPop } from './pop.type';
import { SettingsPos } from './pos.type';
import { PromoCodeSettings } from './promo-code.type';
import { SmartWifiSettings } from './smart-wifi.type';
import { SettingsTakeAway } from './take-away.type';
import { SettingsTips } from './tips.type';
import { TopBar } from './top-bar.type';
import { DictionaryWorkingHoursIntervals, WorkingHours } from './working-hours.type';

export interface SettingsApp {
  general: SettingsGeneralApp;
  behavior: SettingsBehavior;
  pop: SettingsPop;
  tips: SettingsTips;
  workingHours: WorkingHours<string, DictionaryWorkingHoursIntervals>;
  happyHours: HappyHours<string, DictionaryHappyHoursIntervals>;
  googleAnalytics: SettingsGoogleAnalytics;
  hotjarAnalytics: SettingsHotjarAnalytics;
  topBar: TopBar;
  bottomBar: BottomBar;
  cart: SettingsCart;
  config: AppConfig;
  productCatalog: SettingsCatalog;
  gdpr: Gdpr;
  capacity: CapacityManagement;
  pos: SettingsPos;
  sw: SmartWifiSettings;
  promoCode: PromoCodeSettings;
  delivery: DeliverySettings;
  takeAway: SettingsTakeAway;
  paymentMethods: SettingsPayment;
  languages: Languages;
}

export interface AppConfig {
  mode: ConfigMode;
}

export interface Languages {
  allowedLanguages: LanguagesConfig[];
  language: LanguagesConfig;
}

export interface SettingsGeneralApp {
  id: string;
  name: string;
  page: string;
  slug: string;
  language: string;
  timezone: string;
  defaultPage: string;
  defaultPlace: string;
  country: string;
  phone: {
    prefix: string;
  };
  colors: {
    secondary: string;
    primary: string;
  };
  cover: {
    url: string;
    alt: string;
  };
  fiscalInfo: {
    restaurantAddress: string;
    taxRateNumber: string;
    siretNumber: string;
  };
  currency: CurrencySettings;
  applicationType: ApplicationType;
}

export interface CurrencySettings {
  code: string;
  symbol: string;
  name?: string;
}

export enum ApplicationType {
  All = 'all',
  Demo = 'demo',
  Production = 'production',
  Development = 'development',
  Template = 'template',
  Internal = 'internal',
}

export enum ConfigMode {
  Dev = 'dev',
  Prod = 'prod',
}

export enum CountriesCodes {
  FR = 'FR',
  DK = 'DK',
  DE = 'DE',
}
