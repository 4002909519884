import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { bounce } from '@bend/animations';
import { SettingsService } from '@bend/store';

import { TotalService } from '../../../../../../../shared-widgets/src/lib/shared/cart-services';

@Component({
  selector: 'app-button-total',
  templateUrl: './button-total.component.html',
  styleUrls: ['./button-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [bounce],
})
export class ButtonTotalComponent {
  total$: Observable<number>;
  totalUser$: Observable<number>;
  totalPaid$: Observable<number>;

  isShowUserTotal$: Observable<boolean>;
  fgColor$: Observable<string>;
  route: ActivatedRoute;

  constructor(
    private _settings: SettingsService,
    private _total: TotalService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    this.total$ = this._total.all;
    this.totalPaid$ = this._total.paid;
    this.totalUser$ = this._total.userWithSharedAll;
    this.isShowUserTotal$ = this._isShowUserTotal;
    this.fgColor$ = this._settings.colors.primaryForeground;
    this.route = this._route.parent;
  }

  navigate(): void {
    this._router.navigate([{ outlets: { page: 'cart' } }], {
      relativeTo: this._route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * @description check if the total is equal to the total of the current user
   */
  private get _isShowUserTotal(): Observable<boolean> {
    return combineLatest([this.total$, this.totalUser$]).pipe(map(([total, totalUser]) => total !== totalUser));
  }
}
