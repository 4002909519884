import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItemStatus } from '@bend/store-shared';

import { OrderDialogLabels } from '../../../../config';
import { DialogKey, KitchenType, LocationPriorityStatus } from '../../../../types';
import { OrderDialogDisplayedService } from '../order-dialog-displayed/order-dialog-displayed.service';

@Injectable()
export class OrderDialogReadyService {
  constructor(private _dialog: DialogService, private _orderDialogDisplayed: OrderDialogDisplayedService) {}

  behavior(): MonoTypeOperatorFunction<[OrderItemStatus, KitchenType, string, string]> {
    const allowStatuses = new Set([OrderItemStatus.PickUpReady]);

    return tap(([status, kitchenType, orderNumber]) => {
      // show only in status allowed
      if (!allowStatuses.has(status)) return;

      if (kitchenType === KitchenType.Multi) return;

      this._dialog.success({
        message: OrderDialogLabels.Ready,
        interpolateData: { orderNumber },
      });
    });
  }

  partialBehavior(): MonoTypeOperatorFunction<[LocationPriorityStatus[], KitchenType, string]> {
    const allowStatuses = new Set([OrderItemStatus.PickUpReady]);

    return tap(([locations, kitchenType, orderNumber]) => {
      if (kitchenType === KitchenType.Mono) return;

      locations.forEach(({ id, status, updatedAt, name: locationName }) => {
        // show only in status allowed
        if (!allowStatuses.has(status)) return;

        if (this._orderDialogDisplayed.check(updatedAt, id, DialogKey.Location)) return;

        this._dialog.success({
          message: OrderDialogLabels.ReadyPart,
          interpolateData: { orderNumber, locationName },
        });
      });
    });
  }
}
