import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubCategory } from '../shared';
import * as selectors from './sub-category.selectors';
import { State } from './sub-category.type';

@Injectable({ providedIn: 'root' })
export class SubCategoryService {
  constructor(private _store: Store<State>) {}

  subCategoriesByCategory(categoryId: number): Observable<SubCategory[]> {
    return this._store
      .pipe(select(selectors.all))
      .pipe(map(categories => categories.filter(category => category.parentId === categoryId)));
  }
}
