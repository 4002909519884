import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, zip } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { UserSocketUpdatesService } from '@bend/store';
import { CartService } from '@bend/store/src/lib/cart';
import { OrderService } from '@bend/store/src/lib/order';
import { ParamsService } from '@bend/store/src/lib/params';

import { SocketOrder, SocketOrderType } from '../../../../types';
import { OtherDialogService } from '../other-dialog/other-dialog.service';

@Injectable()
export class OrderSocketService {
  message: Observable<SocketOrder>;

  orderCloseEvent$ = new Subject<void>();

  constructor(
    private _order: OrderService,
    private _otherDialog: OtherDialogService,
    private _router: Router,
    private _params: ParamsService,
    private _cart: CartService,
    private readonly _userSocketUpdate: UserSocketUpdatesService,
  ) {}

  init(): void {
    if (this.message) return;

    this.message = this._userSocketUpdate.order().pipe(
      map(event =>
        event.type === SocketOrderType.OrderUpdated
          ? // cast updateAt in Date type
            { ...event, updatedAt: new Date(event.updatedAt) }
          : event,
      ),
      /**
       * Emmit orderCloseEvent
       * */
      tap(event => event.type === SocketOrderType.OrderUpdated && this._order.update(event)),
      tap(event => event.type === SocketOrderType.CreditRefunded && this._cart.getCredit()),
      tap(event => event.type === SocketOrderType.OrderClosed && this._closeOrder()),
    );
  }

  close(): void {
    if (!this.message) return;

    this.message = undefined;
    this._userSocketUpdate.orderClose();
  }

  private _closeOrder(): void {
    /**
     * @description Emmit order close event
     * */
    this.orderCloseEvent$.next();
    /**
     * @description reset store for order
     */
    this._order.reset();
    /**
     * @description show info dialog and say order is closed
     */
    this._otherDialog.orderClosed();
    /**
     * @description go home page
     */
    zip(this._params.appSlug, this._params.pageId)
      // Temporary fix for kiosk
      .pipe(filter(() => !location.pathname.includes('/kiosk')))
      .subscribe(([appSlug, pageId]) => this._router.navigate([appSlug, pageId], { queryParamsHandling: 'merge' }));
  }
}
