<div class="scroll-container">
  <label *ngFor="let option of items; index as index" class="radio">
    <input
      type="radio"
      [checked]="index === indexSelected"
      [name]="option"
      (click)="select(option, index)"
      class="radio__input"
    />
    <span class="radio__label">{{ option.label | translate | titlecase }}</span>
    <div [style.backgroundColor]="bg$ | async" class="radio__mark" data-cy="radio-mark">
      <span [style.backgroundColor]="bg$ | async" class="radio__checked"></span>
    </div>
  </label>
</div>
