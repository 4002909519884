import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './tags.config';
import { PosSettingsEffects } from './tags.effects';
import { TagsHttpService } from './tags.http.service';
import { reducer } from './tags.reducer';
import { TagsService } from './tags.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([PosSettingsEffects])],
  providers: [TagsService, TagsHttpService],
})
export class TagsStoreModule {}
