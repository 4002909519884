import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TransportType } from '../shared';
import { storeKey } from './notifications.config';
import { adapter } from './notifications.reducer';
import { State } from './notifications.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const notificationsList = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, notifications => notifications.isLoading);
export const errorCode = createSelector(stateSelector, notifications => notifications.errorCode);
export const currentType = createSelector(stateSelector, notifications => notifications.currentType);
export const currentAppSlug = createSelector(stateSelector, notifications => notifications.currentAppSlug);
export const isLoaded = createSelector(stateSelector, notifications => notifications.isLoaded);

export const selectEntity = createSelector(entities, currentType, (total, type) => total[type]);

export const selectEmail = createSelector(selectEntity, type => type[TransportType.Email]);

export const selectEmailPosPdf = createSelector(selectEntity, type => type[TransportType.EmailPosPdf]);

export const selectSms = createSelector(selectEntity, type => type[TransportType.Sms]);
