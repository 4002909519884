import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { paramsResetMetaReducer } from '../params/params.reducer';
import { ParamsSerializer } from '../params/params.serializer';

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot({
      stateKey: 'params',
      serializer: ParamsSerializer,
    }),
    StoreModule.forRoot(
      {
        params: routerReducer,
      },
      {
        metaReducers: [paramsResetMetaReducer],
        runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
        initialState: {
          params: { state: { url: '/', params: {}, queryParams: {} }, navigationId: 0 },
        },
      },
    ),
    EffectsModule.forRoot([]),
  ],
})
export class GlobalStoreModule {}
