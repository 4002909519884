import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { isNumeric } from '../../helpers';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Margin } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { Carousel, CarouselWidget } from './carousel';
import { CarouselService } from './carousel.service';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-edit-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditCarouselComponent extends WidgetBaseComponent<CarouselWidget> implements OnInit {
  static readonly widgetName = 'carousel';
  private readonly DEFAULT_COLOR = '#000000';
  carousels: Carousel[];

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _carouselService: CarouselService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this._initForm();
    this.getCarousels();
  }

  get carouselId(): UntypedFormControl {
    return this.form.get('carouselId') as UntypedFormControl;
  }

  get carouselfontColorValue(): string {
    return (this.form.get('style.fontColor') as UntypedFormControl).value;
  }

  set carouselfontColorValue(titleColor: string) {
    (this.form.get('style.fontColor') as UntypedFormControl).setValue(titleColor);
  }

  get fontSize(): UntypedFormControl {
    return this.form.get('style.fontSize') as UntypedFormControl;
  }

  get subtitleSize(): UntypedFormControl {
    return this.form.get('style.subtitleSize') as UntypedFormControl;
  }

  get carouselsubtitleColorValue(): string {
    return (this.form.get('style.subtitleColor') as UntypedFormControl).value;
  }

  set carouselsubtitleColorValue(subtitleColor: string) {
    (this.form.get('style.subtitleColor') as UntypedFormControl).setValue(subtitleColor);
  }

  get widgetFontColorValue(): string {
    return (this.form.get('style.widgetFontColor') as UntypedFormControl).value;
  }

  set widgetFontColorValue(widgetTitleColor: string) {
    (this.form.get('style.widgetFontColor') as UntypedFormControl).setValue(widgetTitleColor);
  }

  get widgetFontSize(): UntypedFormControl {
    return this.form.get('style.widgetFontSize') as UntypedFormControl;
  }

  get widgetSubtitleSize(): UntypedFormControl {
    return this.form.get('style.widgetSubtitleSize') as UntypedFormControl;
  }

  get widgetSubtitleColorValue(): string {
    return (this.form.get('style.widgetSubtitleColor') as UntypedFormControl).value;
  }

  set widgetSubtitleColorValue(widgetSubtitleColor: string) {
    (this.form.get('style.widgetSubtitleColor') as UntypedFormControl).setValue(widgetSubtitleColor);
  }

  getCarousels(): void {
    this._carouselService.getCarousels().subscribe((carousels: Carousel[]) => {
      this.carousels = carousels;
    });
  }

  private _addMissingAttributes(widget: CarouselWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        imageBorderRadius: '',
        hasSlideBounds: false,
        margin: new Margin(),
        fontSize: DEFAULT,
        fontColor: '',
        subtitleSize: DEFAULT,
        subtitleColor: '',
        widgetFontSize: DEFAULT,
        widgetFontColor: '',
        widgetSubtitleSize: DEFAULT,
        widgetSubtitleColor: '',
      };
    }
  }

  private _initForm(): void {
    const { carouselId, title, subtitle, openInNewTab, style } = this.widget.attributes;
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        carouselId: [carouselId, [Validators.required]],
        title: [title],
        subtitle: [subtitle],
        openInNewTab: [openInNewTab],
        style: this._formBuilder.group({
          imageBorderRadius: [style ? style.imageBorderRadius : ''],
          hasSlideBounds: [style ? style.hasSlideBounds : false],
          margin: this._formBuilder.group({
            top: [style && style.margin ? style.margin.top : ''],
            bottom: [style && style.margin ? style.margin.bottom : ''],
          }),
          fontSize: [style.fontSize || DEFAULT, this._fontSizeValidator()],
          fontColor: [WidgetHelper.parseColor(style.fontColor, this.DEFAULT_COLOR)],
          subtitleSize: [style.subtitleSize || DEFAULT, this._fontSizeValidator()],
          subtitleColor: [WidgetHelper.parseColor(style.subtitleColor, this.DEFAULT_COLOR)],
          widgetFontSize: [style.widgetFontSize || DEFAULT, this._fontSizeValidator()],
          widgetFontColor: [WidgetHelper.parseColor(style.widgetFontColor, this.DEFAULT_COLOR)],
          widgetSubtitleSize: [style.widgetSubtitleSize || DEFAULT, this._fontSizeValidator()],
          widgetSubtitleColor: [WidgetHelper.parseColor(style.widgetSubtitleColor, this.DEFAULT_COLOR)],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _fontSizeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = control.value === 'default' || isNumeric(control.value as string);
      return valid ? null : { fontSize: { value: control.value } };
    };
  }
}
