import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { appear } from '@bend/animations';

@Component({ template: '', animations: [appear] })
export abstract class ButtonAbstractComponent {
  /**
   * The color of the button.
   */
  @Input() color: ThemePalette = 'primary';
  /**
   * Whether ripples are disabled.
   */
  @Input() disableRipple: boolean;
  /**
   * Whether the button is disabled.
   */
  @Input() disabled = false;
  /**
   * The type of the button.
   */
  @Input() type: 'button' | 'submit' = 'button';
  /**
   * The size of the button.
   */
  @Input() size: 'small' | 'medium' | 'xsmall' = 'small';
  /**
   * The icon to show in the button.
   */
  @Input() icon = '';
  /**
   * The url to navigate to when the button is clicked.
   */
  @Input() href = '';
  /**
   * How the link should be opened.
   */
  @Input() target = '_blank';
  /**
   * Show a loading spinner.
   */
  @Input() isLoading = false;
  /**
   * Emitted when the button is clicked.
   */
  @Output() clicked = new EventEmitter<void>();

  onClick(): void {
    if (this.disabled || this.isLoading) return;

    this.clicked.emit();
  }
}
