import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { widgets as factories } from '@bend/widgets-old';

import { environment } from '@designer-environment';

import { QrCodeService } from '../../qr-code/qr-code.service';
import { PageDesignerService } from '../../services';
import { PageFont, PageSettings, PopWidget } from '../../types';
import { WidgetHelperService } from '../../widget-helper.service';
import { PageHostDirective } from '../page-host.directive';

export const FALLBACK_FONT = 'Rubik, sans-serif';

@Component({
  selector: 'app-page-preview',
  templateUrl: './page-preview.component.html',
  styleUrls: ['./page-preview.component.scss'],
})
export class PagePreviewComponent implements OnChanges, AfterViewInit {
  pageUrlUser: string;
  @Input() widgets: PopWidget[];
  @Input() pageId: string;
  @Input() appSlug: string;
  @Input() appHost: string;
  @Input() title: string;
  @ViewChild(PageHostDirective) appPageHost: PageHostDirective;
  @ViewChild('widgetsContainerFakeFooter') fakeFooterEl: ElementRef;
  cartWidgetsSubscriptions: Subscription[];
  phoneSettings: { prefix: string };
  backgroundColor: string;
  renderer2: Renderer2;

  constructor(
    private _widgetHelper: WidgetHelperService,
    private _qrCodeService: QrCodeService,
    private _route: ActivatedRoute,
    private _pageDesignerService: PageDesignerService,
    private _rendererFactoryService: RendererFactory2,
    private _cdr: ChangeDetectorRef,
  ) {
    this.renderer2 = this._rendererFactoryService.createRenderer(null, null);
    this.cartWidgetsSubscriptions = [];
  }

  ngOnChanges(change: SimpleChanges): void {
    if (!change['widgets']?.firstChange) {
      this.cartWidgetsUnsubscribe();
      this.loadWidgets(this.widgets);
    }
  }

  ngAfterViewInit(): void {
    this.appSlug = this._route.parent.snapshot.paramMap.get('appSlug');
    this._route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          const pageId = params.get('pageId');
          const envUrl = `${environment.appHost}/${this.appSlug}`;
          this.pageUrlUser = `${envUrl}/${pageId}`;
          return this._pageDesignerService.getPage(pageId);
        }),
      )
      .subscribe(page => {
        this.backgroundColor = page.settings.backgroundColor;
        this.phoneSettings = page.appSettings.phone;
        this.cartWidgetsUnsubscribe();
        this.loadWidgets(this.widgets);
        this.loadPageFont(page.settings);
      });

    this._cdr.detectChanges();
  }

  loadWidgets(widgets: PopWidget[]): void {
    const viewContainerRef = this.appPageHost.viewContainerRef;
    viewContainerRef.clear();

    const sortedWidgets = widgets.slice().sort((a, b) => a.order - b.order);
    sortedWidgets.forEach(widget => {
      const factoryClass = this._widgetHelper.findFactoryClass(factories, widget);
      if (factoryClass) {
        const componentRef = viewContainerRef.createComponent(factoryClass);
        const attributes = { ...widget.attributes };

        if (attributes) {
          if (widget.type === 'contact_info') {
            attributes.page = {
              phone: this.phoneSettings,
            };
          }

          if (widget.class === 'interactive') {
            attributes.pageId = this.pageId;
            attributes.id = widget._id;
          }

          if (widget.type !== 'video') {
            attributes.isEditMode = true;
          }
        }
        const componentRefInstance = componentRef.instance as any;

        componentRefInstance.attributes = attributes;

        if (widget.type === 'cart') {
          this.cartWidgetsSubscriptions.push(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            componentRefInstance.parentFooterHeightSubject.subscribe(
              (height: number) => (this.fakeFooterEl.nativeElement.style.height = `${height} + px`),
            ),
          );
        }
      }
    });
  }

  cartWidgetsUnsubscribe(): void {
    if (this.cartWidgetsSubscriptions.length) {
      this.cartWidgetsSubscriptions.forEach(subscription => {
        if (!subscription.closed) {
          subscription.unsubscribe();
        }
      });
      this.cartWidgetsSubscriptions.splice(0);

      this.fakeFooterEl.nativeElement.style.height = '0';
    }
  }

  showQrCode(): void {
    this._qrCodeService.showQrCode(this.appHost, this.title);
  }

  loadPageFont(pageSettings: PageSettings): void {
    const pageFont: PageFont = pageSettings && pageSettings.pageFont;
    let fontFamily = '';
    const previewEl = this.resetPreviewElFontFamily();

    if (pageFont) {
      const headEl = window.document.head;
      const url = pageFont.url;
      const linkEl = headEl.querySelector(`link[href="${url}"]`);

      fontFamily = `'${pageFont.name}', ${FALLBACK_FONT}`;

      if (!linkEl && url) {
        const newLinkEl = this.renderer2.createElement('link');
        this.renderer2.setAttribute(newLinkEl, 'rel', 'stylesheet');
        this.renderer2.setAttribute(newLinkEl, 'href', url);
        this.renderer2.appendChild(headEl, newLinkEl);
      }
    }

    if (fontFamily) {
      this.renderer2.setStyle(previewEl, 'fontFamily', fontFamily);
    }
  }

  resetPreviewElFontFamily(): any {
    const previewEl = document.getElementById('preview-container');

    this.renderer2.removeStyle(previewEl, 'fontFamily');

    return previewEl;
  }
}
