<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="urlInput" class="form-label">{{ 'WIDGETS.POST.POST_URL' | translate }}</label>
      <div
        *ngIf="url.invalid && (((url.dirty || url.touched) && url.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.POST.POST_URL_ENTER' | translate }}
      </div>
      <input id="urlInput" type="url" class="form-input form-input--url" placeholder="URL" formControlName="url" />
      <a
        class="form-field-info"
        href="https://developers.facebook.com/docs/plugins/embedded-posts#how-to-get-a-posts-url"
        target="_blank"
      >
        {{ 'WIDGETS.POST.USER_GUIDE' | translate }}
      </a>
    </div>
  </form>
</pop-widget-edit-widget>
