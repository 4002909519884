<dialog-background class="background"></dialog-background>
<div class="error" data-cy="dialogError">
  <dialog-icon-wrap>
    <lib-icon icon="dialogError" class="error__icon"></lib-icon>
  </dialog-icon-wrap>
  <dialog-content-wrap>
    <dialog-title *ngIf="title$ | async" [innerHTML]="title$ | async"></dialog-title>
    <dialog-description [innerHTML]="description$ | async"></dialog-description>
  </dialog-content-wrap>
  <dialog-button *ngIf="showCloseButton" (click)="close()" class="error__button" data-cy="closeDialogErrorBtn">
    OK
  </dialog-button>
</div>
