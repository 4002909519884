import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './sub-item.config';
import { reducer } from './sub-item.reducer';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer)],
})
export class SubItemStoreModule {}
