import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Icons } from './icon.type';
import { icons } from './icons';

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon: Icons;

  iconSymbol: SafeHtml;
  viewBox: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.iconSymbol = this.sanitizer.bypassSecurityTrustHtml(icons[this.icon].symbol);
    this.viewBox = icons[this.icon].viewBox;
  }
}
