import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';

import { PopProduct } from '@bend/widgets-old/product-catalog/product-catalog';

import { actions as orderActions } from '../order/order.actions';
import { ParamsService } from '../params';
import { actions as recommendationActions } from '../recommendation/recommendation.actions';
import { SettingsService } from '../settings';
import {
  PlaceRecommendationGroup,
  PlaceRecommendationGroupHttp,
  RecommendationGroup,
  RecommendationGroupHttp,
} from '../shared';
import { actions as recommendationGroupsActions } from './recommendation-group.actions';
import { RecommendationGroupHttpService } from './recommendation-group.http.service';
import * as selectors from './recommendation-group.selectors';
import { State } from './recommendation-group.type';

@Injectable()
export class RecommendationGroupEffects {
  loadRecommendations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(recommendationGroupsActions.getRecommendationGroups),
      withLatestFrom(this._store.select(selectors.isLoadedBy)),
      switchMap(([{ by }, isLoadedBy]) =>
        isLoadedBy.includes(by)
          ? of(recommendationGroupsActions.getRecommendationGroupsIsLoaded())
          : this._http.getRecommendationGroups(by).pipe(
              switchMap(groups => [
                recommendationActions.addRecommendations({
                  entities: this._getRecommendations(groups, by) as PopProduct[],
                }),
                recommendationGroupsActions.getRecommendationGroupsSuccess({
                  by,
                  entities: this._getRecommendationGroups(groups, by),
                }),
              ]),
              catchError(({ errorCode }) =>
                of(recommendationGroupsActions.getRecommendationGroupsError({ errorCode })),
              ),
            ),
      ),
    ),
  );

  loadByPlace$ = createEffect(() =>
    this._actions$.pipe(
      ofType(recommendationGroupsActions.getPlaceRecommendations),
      switchMap(({ place }) =>
        this._http.getRecommendationGroupsByPlace(place).pipe(
          map(groups => this.transformRecommendations(groups)),
          map(groups => recommendationGroupsActions.getPlaceRecommendationsSuccess({ recommendations: groups, place })),
          catchError(({ errorCode }) => of(recommendationGroupsActions.getPlaceRecommendationsError({ errorCode }))),
        ),
      ),
    ),
  );

  onRemovedItemFromCart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.removeOrderItemSuccess),
      concatLatestFrom(() => this.settings.widgetCart.pipe(map(cart => cart.recommendations))),
      filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, recommendationSettings]) =>
          recommendationSettings.enabled && !!recommendationSettings.groups && !!recommendationSettings.groups.length,
      ),
      concatLatestFrom(() => this.params.queryParams(['place'])),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, place]) => recommendationGroupsActions.getPlaceRecommendations({ place: place[0] })),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private settings: SettingsService,
    private params: ParamsService,
    private _http: RecommendationGroupHttpService,
  ) {}

  private _getRecommendations(groups: RecommendationGroupHttp[], productId: number): Partial<PopProduct>[] {
    return groups.flatMap(({ recommendations }) => recommendations.map(item => ({ ...item, productId, quantity: 0 })));
  }

  private _getRecommendationGroups(groups: RecommendationGroupHttp[], productId: number): RecommendationGroup[] {
    return groups.map(({ recommendations, ...rest }) => ({ ...rest, productId }));
  }

  private transformRecommendations(groups: PlaceRecommendationGroupHttp[]): PlaceRecommendationGroup[] {
    return groups.map(({ id, recommendations, name, displayLabel, order }) => ({
      id,
      name,
      displayLabel,
      order,
      recommendations: recommendations.map(({ groupId, order, item }) => ({
        groupId,
        order,
        quantity: 0,
        ...item,
      })),
    }));
  }
}
