import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';

import { IconModule } from '@bend/icon';
import { FileUploadModule, WidgetsModule as WidgetEditorModule } from '@bend/widgets-editor';
import { SharedModule as SharedModuleWidget } from '@bend/widgets-editor';
import { PageSelectorComponent } from '@bend/widgets-editor/src/lib/shared/page-selector/page-selector.component';
import { WidgetModule } from '@bend/widgets-old';

import { environment } from '@designer-environment';
import { ButtonsModule } from '@designer-modules/buttons';
import { LanguageSelectModule } from '@designer-modules/language-select/language-select.module';
import { DateFormatPipe } from '@designer-shared/pipe/date-format.pipe';

import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmPaymentComponent } from './confirm/confirmPayment.component';
import { ConfirmDeletionComponent } from './confirm-deletion/confirm-deletion.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { InputSpinnerComponent } from './input-spinner/input-spinner.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConditionalInfoComponent } from './page-designer/conditional-info/conditional-info.component';
import { PageEditComponent } from './page-designer/edit/page-edit.component';
import { PageHostDirective } from './page-designer/page-host.directive';
import { PagePreviewComponent } from './page-designer/preview/page-preview.component';
import { CapitalizePipe } from './pipe/capitalize.pipe';
import { CentsToBillsPipe } from './pipe/cents-to-bills.pipe';
import { PositivePricePipe } from './pipe/positivePrice';
import { PromoCodesTypePipe } from './pipe/promo-code-type.pipe';
import { SetOrNotPipe } from './pipe/set-or-not.pipe';
import { ShowHoursPipe } from './pipe/showHours.pipe';
import { SortByNameAndNumberPipe } from './pipe/sortByNameAndNumber.pipe';
import { TransactionProviderPipe } from './pipe/transaction-provider.pipe';
import { TransactionStatusPipe } from './pipe/transaction-status.pipe';
import { QrCodeService } from './qr-code/qr-code.service';
import { QrCodeDialogComponent } from './qr-code/qr-code-dialog.component';
import {
  ApplicationService,
  CartSettingsService,
  PageDesignerService,
  PageSelectorService,
  PagesServices,
  PosCatalogsService,
  Printer2Service,
  PrinterService,
} from './services';
import { SharedComponentsModule } from './shared-components';
import { WidgetHelperService } from './widget-helper.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', `.json?cb=${environment.release}`);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    WidgetModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatExpansionModule,
    MatAutocompleteModule,
    FormsModule,
    QRCodeModule,
    FileUploadModule,
    SharedModuleWidget,
    MatTooltipModule,
    MatSlideToggleModule,
    WidgetEditorModule,
    IconModule,
    SharedComponentsModule,
    MatCardModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LanguageSelectModule,
    ButtonsModule,
  ],
  declarations: [
    NotFoundComponent,
    MainHeaderComponent,
    ContentHeaderComponent,
    PageHostDirective,
    PageEditComponent,
    PagePreviewComponent,
    ConfirmDeletionComponent,
    ConfirmComponent,
    ConfirmPaymentComponent,
    AutofocusDirective,
    CapitalizePipe,
    SetOrNotPipe,
    PromoCodesTypePipe,
    CentsToBillsPipe,
    PositivePricePipe,
    ShowHoursPipe,
    TransactionStatusPipe,
    TransactionProviderPipe,
    SortByNameAndNumberPipe,
    QrCodeDialogComponent,
    InputSpinnerComponent,
    DateFormatPipe,
    ConditionalInfoComponent,
  ],
  providers: [
    PageDesignerService,
    WidgetHelperService,
    QrCodeService,
    PrinterService,
    Printer2Service,
    PageSelectorService,
    ApplicationService,
    PagesServices,
    PosCatalogsService,
    CartSettingsService,
  ],
  exports: [
    MainHeaderComponent,
    ContentHeaderComponent,
    PagePreviewComponent,
    PageEditComponent,
    PageHostDirective,
    ConfirmDeletionComponent,
    ConfirmComponent,
    ConfirmPaymentComponent,
    AutofocusDirective,
    CapitalizePipe,
    SetOrNotPipe,
    PromoCodesTypePipe,
    CentsToBillsPipe,
    PositivePricePipe,
    ShowHoursPipe,
    TransactionStatusPipe,
    TransactionProviderPipe,
    SortByNameAndNumberPipe,
    PageSelectorComponent,
    MatTooltipModule,
    InputSpinnerComponent,
    DateFormatPipe,
    TranslateModule,
  ],
})
export class SharedModule {}
