import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { languagesLabels } from '@bend/store';

@Component({
  selector: 'designer-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent {
  languages = ['en', 'fr', 'de', 'dk', 'he'];
  langSelect: UntypedFormControl;
  languagesLabels = languagesLabels;

  constructor(private _translate: TranslateService) {
    this.langSelect = new UntypedFormControl(this._translate.currentLang);
  }

  changeLang(lang: string): void {
    this.langSelect.patchValue(lang);
    localStorage.setItem('lang', lang);
    this._translate.use(lang);
  }
}
