import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { settings } from 'projects/store/src/lib/settings/settings.selectors';
import { combineLatest } from 'rxjs';

import { appStateSuccess } from './actions';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(private store: Store) {
    this.mapSettingUpdate();
  }

  private mapSettingUpdate(): void {
    // my glue for settings here
    combineLatest([this.store.select(settings)]).subscribe(([settings]) => {
      if (!settings) return;
      this.store.dispatch(
        appStateSuccess({
          isConsultingMode:
            settings.placeStatus === 'closed' || settings.widgets.catalog.isReadOnly || settings.widgets.cart.payOnly,
          isPayOnlyMode: settings.widgets.cart.payOnly,
        }),
      );
    });
  }
}
