import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { OrderService } from '@bend/store/src/lib/order';
import { OrderUsersService } from '@bend/store/src/lib/order-users';
import { OrderUser } from '@bend/store-shared';

@Injectable()
export class OrderUserCurrentService {
  constructor(private _orderUsers: OrderUsersService, private _order: OrderService) {}

  /**
   * @description others users for current user
   */
  get others(): Observable<OrderUser[]> {
    return combineLatest([this._order.id, this._order.currentOrderUserId]).pipe(
      switchMap(([orderId, sessionUserId]) => this._orderUsers.others(orderId, sessionUserId)),
    );
  }

  /**
   * @description shared users for current user
   */
  get shares(): Observable<OrderUser[]> {
    return combineLatest([this._order.id, this._order.currentOrderUserId]).pipe(
      switchMap(([orderId, sessionUserId]) => this._orderUsers.shares(orderId, sessionUserId)),
    );
  }

  /**
   * @description all users added by current user with current user
   */
  get currentWithOthers(): Observable<OrderUser[]> {
    return combineLatest([this._order.id, this._order.currentOrderUserId]).pipe(
      switchMap(([orderId, sessionUserId]) => this._orderUsers.orderUserWithOthers(orderId, sessionUserId)),
    );
  }

  /**
   * @description current user
   */
  get current(): Observable<OrderUser> {
    return this._order.currentOrderUserId.pipe(switchMap(sessionUserId => this._orderUsers.orderUser(sessionUserId)));
  }
}
