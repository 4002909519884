import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './working-hours.config';
import { adapter } from './working-hours.reducer';
import { State } from './working-hours.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const catalogs = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const isLoadedBy = createSelector(stateSelector, state => state.isLoadedBy);
export const intervals = createSelector(stateSelector, selectAll);
