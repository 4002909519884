import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { Auth } from '@bend/socket';

import { StoreService } from '../shared';
import { actions } from './auth.actions';
import * as selectors from './auth.selectors';
import { State } from './auth.type';

@Injectable()
export class AuthService extends StoreService<State> implements Auth {
  constructor(_store: Store<State>, _actions: Actions) {
    super(_store, _actions, selectors);
  }

  login(): Observable<boolean> {
    this.isLoading
      .pipe(
        // dispatch action when is not loading
        filter(isLoading => !isLoading),
        first(),
        // get current url and convert in base64
        map(() => btoa(location.href)),
        // dispatch login action
        tap(url => this._store.dispatch(actions.login({ url }))),
      )
      .subscribe();

    return this._finishedAction(actions.loginSuccess, actions.loginError);
  }

  loginPos(): Observable<boolean> {
    this._store.dispatch(actions.loginPos());

    return this._finishedAction(actions.loginPosSuccess, actions.loginPosError);
  }

  loginWifi(): Observable<boolean> {
    this._store.dispatch(actions.loginWifi());

    return this._finishedAction(actions.loginWifiSuccess, actions.loginWifiError);
  }

  signIn(phone: string): Observable<boolean> {
    this._store.dispatch(actions.signIn({ phone }));

    return this._finishedAction(actions.signInSuccess, actions.signInError);
  }

  resendCode(): Observable<boolean> {
    this._store.dispatch(actions.resendCode());

    return this._finishedAction(actions.resendCodeSuccess, actions.resendCodeError);
  }

  confirmCode(code: string): Observable<boolean> {
    this._store.dispatch(actions.confirmCode({ code }));

    return this._finishedAction(actions.confirmCodeSuccess, actions.confirmCodeError);
  }

  get token(): Observable<string> {
    return this.isLoading.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => this._store.pipe(select(selectors.token))),
      first(),
    );
  }

  get socketToken(): Observable<string> {
    return this.isLoading.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => this._store.pipe(select(selectors.token))),
      filter(Boolean),
      first(),
    );
  }

  get refreshToken(): Observable<string> {
    return this.isLoading.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => this._store.pipe(select(selectors.refreshToken))),
      first(),
    );
  }

  get isAuthenticated(): Observable<boolean> {
    return this._store.pipe(select(selectors.isAuthenticated));
  }

  get tokenCurrent(): Observable<string> {
    return this._store.pipe(select(selectors.token), first());
  }

  get errorCode(): Observable<string> {
    return this._store.pipe(select(selectors.errorCode));
  }

  get isNew(): Observable<boolean> {
    return this._store.pipe(select(selectors.isNew));
  }

  get isRegistered(): Observable<boolean> {
    return this._store.pipe(select(selectors.isRegistered));
  }

  get phone(): Observable<string> {
    return this._store.pipe(select(selectors.phone));
  }

  extendToken(): void {
    this.login();
  }

  loginGps(latitude: number, longitude: number): void {
    this._store.dispatch(actions.loginGps({ gpsPoint: [latitude, longitude] }));
  }

  logOut(): Observable<boolean> {
    this._store.dispatch(actions.logOut());

    return this._finishedAction(actions.logOut, actions.loginError);
  }
}
