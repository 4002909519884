export class WidgetHelper {
  /**
   * FinalUrl generator helper parse url and generate finalUrl depending on
   * url type (absolute|relative)
   * @param url url which is parsed
   * @param visitorUrl base url which is used as a prefix for relative url values
   *
   * @return string generated final absolute url
   */
  static generateFinalUrl(url: string, appHost: string): string {
    let finalUrl = url;
    if (url && !/http(s)?/g.test(url)) {
      let separator = '';
      const URL_SEPARATOR = '/';
      if (url.charAt(0) !== URL_SEPARATOR) {
        separator = URL_SEPARATOR;
      }
      finalUrl = appHost + separator + url;
    }

    return finalUrl;
  }

  static parseColor(color: string, defaultColor = '#ffffff'): string {
    return color ? color : defaultColor;
  }
}
