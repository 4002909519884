import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { EnvModule } from '@bend/env';

import { ImageGalleryModule } from '@designer-modules/image-gallery';
import { TitleModule } from '@designer-modules/title';

import { FileUploadModule } from '../file-upload/file-upload.module';
import { SharedModule } from '../shared/shared.module';
import { PopWidgetEditActionBarComponent } from './action-bar/action-bar.component';
import { PopWidgetEditActionCallComponent } from './action-call/action-call.component';
import { ActionCallService } from './action-call/action-call.service';
import { PopWidgetEditActionCallDesignerFormComponent } from './action-call/designer-form/designer-form.component';
import { PopWidgetEditActionCallEmailFormComponent } from './action-call/email-form/email-form.component';
import { PopWidgetEditActionCallPosFormComponent } from './action-call/pos-form/pos-form.component';
import { PopWidgetEditActionCallSmsFormComponent } from './action-call/sms-form/sms-form.component';
import { PopWidgetEditActionCallTelephoneFormComponent } from './action-call/telephone-form/telephone-form.component';
import { PopWidgetEditActionCallWaiterFormComponent } from './action-call/waiter-form/waiter-form.component';
import { PopWidgetEditAskQuestionComponent } from './ask-question/ask-question.component';
import { PopWidgetEditButtonsComponent } from './buttons/buttons.component';
import { PopWidgetEditCarouselComponent } from './carousel/carousel.component';
import { CarouselService } from './carousel/carousel.service';
import { PopWidgetEditCartComponent } from './cart/cart.component';
import { PopWidgetEditContactComponent } from './contact/contact.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { PopWidgetEditCustomComponent } from './custom/custom.component';
import { PopWidgetEditDocumentViewerComponent } from './document-viewer/document-viewer.component';
import { DocumentViewerService } from './document-viewer/document-viewer.service';
import { PopWidgetEditDocumentsComponent } from './documents/documents.component';
import { PopWidgetEditFeedbackComponent } from './feedback/feedback.component';
import { PopWidgetEditFollowComponent } from './follow/follow.component';
import { PopWidgetEditGalleryComponent } from './gallery/gallery.component';
import { PopWidgetEditImageComponent } from './image/image.component';
import { PopWidgetEditLinkComponent } from './link/link.component';
import { PopWidgetEditLoyaltyShareComponent } from './loyalty-share/loyalty-share.component';
import { PopWidgetEditMapComponent } from './map/map.component';
import { PopWidgetEditPollComponent } from './poll/poll.component';
import { PopWidgetEditPostComponent } from './post/post.component';
import { PopWidgetEditProductCatalogFormComponent } from './product-catalog/catalog-form/catalog-form.component';
import { PopWidgetEditProductCatalogComponent } from './product-catalog/product-catalog.component';
import { ProductCatalogService } from './product-catalog/product-catalog.service';
import { PopWidgetEditProductCatalogV2FormComponent } from './product-catalog-v2/catalog-form/catalog-v2-form.component';
import { PopWidgetEditProductCatalogV2Component } from './product-catalog-v2/product-catalog-v2.component';
import { ProductCatalogV2Service } from './product-catalog-v2/product-catalog-v2.service';
import { PopWidgetEditProductTeaserComponent } from './product-teaser/product-teaser.component';
import { PopWidgetEditProfileComponent } from './profile/profile.component';
import { PopWidgetEditReviewComponent } from './review/review.component';
import { PopWidgetEditShareComponent } from './share/share.component';
import { PopWidgetEditShopTeaserComponent } from './shop-teaser/shop-teaser.component';
import { PopWidgetEditTextComponent } from './text/text.component';
import { PopWidgetEditTitleComponent } from './title/title.component';
import { PopWidgetEditTweetComponent } from './tweet/tweet.component';
import { PopWidgetEditTwitterHtagComponent } from './twitter-htag/twitter-htag.component';
import { PopWidgetEditTwitterProfileComponent } from './twitter-profile/twitter-profile.component';
import { PopWidgetEditVideoComponent } from './video/video.component';
import { PopWidgetEditWelcomeComponent } from './welcome/welcome.component';
import { WidgetService } from './widget.service';
import { PopWidgetEditWifiComponent } from './wifi/wifi.component';

export const widgets = [
  PopWidgetEditTitleComponent,
  PopWidgetEditTextComponent,
  PopWidgetEditImageComponent,
  PopWidgetEditVideoComponent,
  PopWidgetEditLinkComponent,
  PopWidgetEditProfileComponent,
  PopWidgetEditActionBarComponent,
  PopWidgetEditWifiComponent,
  PopWidgetEditShareComponent,
  PopWidgetEditFollowComponent,
  PopWidgetEditPollComponent,
  PopWidgetEditPostComponent,
  PopWidgetEditContactComponent,
  PopWidgetEditTwitterProfileComponent,
  PopWidgetEditTwitterHtagComponent,
  PopWidgetEditTweetComponent,
  PopWidgetEditDocumentsComponent,
  PopWidgetEditButtonsComponent,
  PopWidgetEditWelcomeComponent,
  PopWidgetEditGalleryComponent,
  PopWidgetEditCarouselComponent,
  PopWidgetEditMapComponent,
  PopWidgetEditProductCatalogComponent,
  PopWidgetEditProductCatalogV2Component,
  PopWidgetEditCartComponent,
  PopWidgetEditActionCallComponent,
  PopWidgetEditShopTeaserComponent,
  PopWidgetEditFeedbackComponent,
  PopWidgetEditCustomComponent,
  PopWidgetEditProductTeaserComponent,
  PopWidgetEditDocumentViewerComponent,
  PopWidgetEditAskQuestionComponent,
  PopWidgetEditReviewComponent,
  ContactInfoComponent,
  PopWidgetEditLoyaltyShareComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ColorPickerModule,
    FileUploadModule,
    SharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgSelectModule,
    MatTooltipModule,
    EnvModule,
    TranslateModule,
    ImageGalleryModule,
    TitleModule,
    MatCheckboxModule,
  ],
  declarations: [
    ...widgets,
    PopWidgetEditProductCatalogFormComponent,
    PopWidgetEditProductCatalogV2FormComponent,
    PopWidgetEditActionCallDesignerFormComponent,
    PopWidgetEditActionCallPosFormComponent,
    PopWidgetEditActionCallEmailFormComponent,
    PopWidgetEditActionCallSmsFormComponent,
    PopWidgetEditActionCallTelephoneFormComponent,
    PopWidgetEditActionCallWaiterFormComponent,
  ],
  exports: widgets,
  providers: [
    WidgetService,
    CarouselService,
    ProductCatalogService,
    ProductCatalogV2Service,
    ActionCallService,
    DocumentViewerService,
  ],
})
export class WidgetsModule {}
