import { Item } from './item.type';

export interface Category {
  id: number;
  name: string;
  order: number;
  isDefault: boolean;
  tabId: string;
  menuId?: number;
  imageUrl: string;
  description: string;
  categoryId?: number;
  catalogId?: number;
  min?: number;
  max?: number;
  multiple?: boolean;
  subCategories: Category[];
  items: Item[];
}
