import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@designer-core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  menuToggled = false;

  constructor(private _authService: AuthService, private _router: Router) {}

  onChangePassword(): void {
    this._router.navigate(['apps/change-password']);
  }

  onLogOut(): void {
    this._authService.logout();
  }

  toggleMenu(): void {
    this.menuToggled = !this.menuToggled;
  }
}
