import { createReducer, on } from '@ngrx/store';

import { usersActions } from '@designer-store/users';

import { UsersState } from './users.state';

const initialUsersState: UsersState = {
  users: null,
  totalCount: null,
  currentApplication: null,
  loadedUsersByApp: null,
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const usersReducer = createReducer(
  initialUsersState,
  on(usersActions.getUsers, state => ({
    ...state,
    isLoading: true,
  })),

  on(usersActions.getUsersSuccess, (state, { docs, count }) => ({
    ...state,
    users: docs,
    totalCount: count,
    isLoading: false,
    error: null,
  })),

  on(usersActions.addUserSuccess, (state, { user }) => ({
    ...state,
    users: [...state.users, user],
    totalCount: state.totalCount + 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  })),

  on(usersActions.deleteUserSuccess, (state, { id }) => ({
    ...state,
    users: state.users.filter(user => user._id !== id),
    totalCount: state.totalCount - 1,
    isLoading: false,
    error: null,
  })),

  on(usersActions.updateUserSuccess, (state, { user }) => {
    return {
      ...state,
      users: state.users.map(u => (u._id === user._id ? user : u)),
      isLoading: false,
      error: null,
    };
  }),

  on(usersActions.getUserSuccess, (state, { user }) => ({
    ...state,
    users: state.users.map(u => (u._id === user._id ? user : u)),
    isLoading: false,
    error: null,
  })),

  on(usersActions.addUser, usersActions.deleteUser, usersActions.updateUser, usersActions.getUser, state => ({
    ...state,
    isLoading: true,
  })),

  on(usersActions.addUserError, usersActions.deleteUserError, usersActions.updateUserError, (state, { errorCode }) => ({
    ...state,
    isLoading: false,
    error: errorCode,
  })),
);
