import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

@Injectable()
export class LoyaltyShareService {
  constructor(private _api: ApiVisitorService, private _http: HttpClient) {}

  generateLink(link: string, placeSlug: string): Observable<string> {
    return this._api.generateLink('v1').pipe(switchMap(api => this._http.post<string>(api, { link, placeSlug })));
  }
}
