import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './working-hours.actions';
import { WorkingHoursHttpService } from './working-hours.http.service';
import * as selectors from './working-hours.selectors';
import { State } from './working-hours.type';

@Injectable()
export class WorkingHoursEffectsService {
  loadCatalogIntervals$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getCatalogWorkingHours),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedBy))),
      mergeMap(([{ by }, isLoadedBy]) =>
        isLoadedBy.includes(by)
          ? of(actions.getCatalogWorkingHoursIsLoaded())
          : this._workingHoursHttp.getCatalogWorkingHours(by).pipe(
              map(intervals => actions.getCatalogWorkingHoursSuccess({ intervals, catalogId: by })),
              catchError(({ errorCode }) => of(actions.getCatalogWorkingHoursError({ errorCode }))),
            ),
      ),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _workingHoursHttp: WorkingHoursHttpService,
  ) {}
}
