import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';

import { OrderService } from '@bend/store/src/lib/order';

import { OrderCheckUpdateService } from '../order-check-update/order-check-update.service';
import { OrderSocketService } from '../order-socket/order-socket.service';
import { OrderTypeService } from '../order-type/order-type.service';
import { OrderUserService } from '../order-user/order-user.service';
import { SessionService } from '../session/session.service';
import { TotalService } from '../total/total.service';

@Injectable()
export class OrderControllerService {
  constructor(
    private _order: OrderService,
    private _orderSocket: OrderSocketService,
    private _orderCheckUpdate: OrderCheckUpdateService,
    private _orderType: OrderTypeService,
    private _total: TotalService,
    private _orderUser: OrderUserService,
    private _session: SessionService,
  ) {}

  reopenOrder(): Observable<boolean> {
    return this._allowNewOrder.pipe(
      switchMap(allow => (allow ? this.createNewOrder() : of(false))),
      first(),
    );
  }

  createNewSession(): Observable<boolean> {
    return this._orderType.isReadOnly.pipe(
      first(),
      tap(isReadOnly => {
        if (!isReadOnly) {
          this._orderCheckUpdate.close();
          this._orderSocket.close();
          this._session.init();
        }
      }),
      /**
       * @description check if order is loaded
       */
      switchMap(() => this._order.isLoaded),
      filter<boolean>(Boolean),
      /**
       * @description need just one event for prevent add multiple items in order
       */
      first(),
    );
  }

  createNewOrder(): Observable<boolean> {
    this._orderCheckUpdate.close();
    this._orderSocket.close();

    this._order.newOrder();

    return this._order.isLoading.pipe(
      /**
       * go next page when order start to loading
       */
      takeWhile(isLoading => isLoading, true),
      filter(isLoading => !isLoading),
      /**
       * check if order is created by checking if order have an error
       */
      switchMap(() => this._order.errorCode),
      /**
       * cast error in boolean
       * when wee have an error, order is not created
       */
      map(error => !error),
      /**
       * if order wasn't created wee skip socket initialization
       */
      tap(isCreated => {
        if (!isCreated) return;

        this._orderSocket.init();
        this._orderCheckUpdate.init();
      }),
    );
  }

  private get _allowNewOrder(): Observable<boolean> {
    return this._total.isShow.pipe(
      /**
       * check if order is closed
       */
      switchMap(haveOrder =>
        haveOrder
          ? /**
             * if order is closed check is allowed by status and options
             */
            this._orderUser.allowCreateNewOrder
          : /**
             * if order is closed
             * we check order type is delivery, take-away or pik-up
             */
            this._orderType.isOnline,
      ),
      first(),
    );
  }
}
