import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './pos-settings.actions';
import { PosSettingsHttpService } from './pos-settings.http.service';
import * as selectors from './pos-settings.selectors';
import { State } from './pos-settings.type';

@Injectable()
export class PosSettingsEffects {
  constructor(private _httpService: PosSettingsHttpService, private _store: Store<State>, private _actions$: Actions) {}

  getPosSettingsList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPosSettingsList),
      withLatestFrom(this._store.select(selectors.isLoadedByAppSlug)),
      switchMap(([{ appSlug }, isLoadedByAppSlug]) =>
        appSlug === isLoadedByAppSlug
          ? of(actions.getPosSettingsListIsLoaded())
          : this._httpService.getPosSettingsList().pipe(
              map(posSettings => actions.getPosSettingsListSuccess({ posSettings, appSlug })),
              catchError(({ errorCode }) => of(actions.getPosSettingsListError({ errorCode }))),
            ),
      ),
    ),
  );

  getPosSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPosSettings),
      withLatestFrom(this._store.select(selectors.isLoadedBy)),
      switchMap(([{ posSettingsId }, loadedBy]) =>
        loadedBy.includes(posSettingsId)
          ? of(actions.getPosSettingsIsLoaded({ posSettingsId }))
          : this._httpService.getPosSettings(posSettingsId).pipe(
              map(posSettings => actions.getPosSettingsSuccess({ posSettings })),
              catchError(({ errorCode }) => of(actions.getPosSettingsError({ errorCode }))),
            ),
      ),
    ),
  );

  addPosSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addPosSettings),
      switchMap(({ posSettings }) =>
        this._httpService.addPosSettings(posSettings).pipe(
          map(settings => actions.addPosSettingsSuccess({ posSettings: settings })),
          catchError(({ errorCode }) => of(actions.addPosSettingsError({ errorCode }))),
        ),
      ),
    ),
  );

  deletePosSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deletePosSettings),
      switchMap(({ posSettingsId }) =>
        this._httpService.deletePosSettings(posSettingsId).pipe(
          map(() => actions.deletePosSettingsSuccess({ posSettingsId })),
          catchError(({ errorCode }) => of(actions.deletePosSettingsError({ errorCode }))),
        ),
      ),
    ),
  );

  updatePosSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updatePosSettings),
      switchMap(({ posSettings }) =>
        this._httpService.updatePosSettings(posSettings).pipe(
          map(settings => actions.updatePosSettingsSuccess({ posSettings: settings })),
          catchError(({ errorCode }) => of(actions.updatePosSettingsError({ errorCode }))),
        ),
      ),
    ),
  );
}
