import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ItemStoreModule } from '../item';
import { storeKey } from './category.config';
import { CategoryEffects } from './category.effects';
import { CategoryHttpService } from './category.http.service';
import { reducer } from './category.reducer';
import { CategoryService } from './category.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([CategoryEffects]), ItemStoreModule],
  providers: [CategoryHttpService, CategoryService],
})
export class CategoryStoreModule {}
