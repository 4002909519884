import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { ProfileService } from '@bend/store';
import { emailValidator } from '@bend/widgets-new/validators/email.validator';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { HasVotedResponse, PollLabels, PollQuestion, PollResponsePayload, VoteResponse, WidgetPoll } from './poll';
import { WidgetPollService } from './poll.service';

@Component({
  selector: 'pop-widget-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class WidgetPollComponent implements OnInit, WidgetComponent {
  static widgetName = 'poll';
  @Input() attributes: WidgetPoll;

  isValid: boolean;
  noValidMessage: string;
  hasVoted: boolean;
  labels: PollLabels;
  isReadOnly: boolean;
  buttonColor: string;

  showEmailField$: Observable<boolean>;

  protected email: FormControl<string> = new FormControl('', [Validators.required, emailValidator]);

  // Service object to handle multiple questions responses
  protected responses: { [key: string]: string };

  protected touched = false;

  constructor(
    @Optional() private _dialog: DialogService,
    private _pollService: WidgetPollService,
    private profileService: ProfileService,
    private _warningService: WarningService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.responses = this.initResponsesServiceObject(this.attributes);
    // Check if contact details need to be provided
    this.dataProvideCheck(this.attributes.saveContact);

    if (!this.isValidWidgetAttributes(this.attributes)) {
      this.noValidMessage = this._warningService.showWarn(WidgetPollComponent.widgetName, WarnTypeEnum.NoAttributes);
      return;
    }

    this._addMissingAttributes();
    this.labels = this.attributes.labels;
    this.isReadOnly = this.attributes.pop.validationStatus === PopValidationStatus.FAILED;
    this.buttonColor = this.isReadOnly ? 'grey' : this.attributes.mainColor;

    if (this.isReadOnly) {
      this.isValid = true;

      return;
    }

    this._checkIfHasVoted();
  }

  sendPoll(): void {
    if (!this.isValidResponses(this.responses, this.attributes.questions)) {
      this.touched = true;
      return;
    }

    if (this.isReadOnly) return;

    // Vote stream
    const vote$ = this._pollService.vote(this.attributes.id, this.generateResponsesPayload(this.responses));
    // Send email stream
    const sendEmail$ = (email: string) => this._pollService.sendEmail(email);

    of(this.email.value)
      .pipe(switchMap(email => (!email ? vote$ : sendEmail$(email).pipe(switchMap(() => vote$)))))
      .subscribe({
        next: (res: VoteResponse) => {
          if (res.err) {
            this._dialog.error({ message: res.err });
            return;
          }

          this._calculatePercentage(res.attributes.questions);
          this.attributes.questions = res.attributes.questions;
          this.hasVoted = true;
          this._cdr.detectChanges();
        },
        error: () => {
          this._dialog.error({ message: 'Failed to vote. Please try again.' });
          this._cdr.detectChanges();
        },
      });
  }

  private isValidResponses(responses: { [p: string]: string }, questions: PollQuestion[]): boolean {
    if (this.email.invalid) {
      this.email.markAsTouched();
      return false;
    }

    const requiredQuestions = questions.filter(q => q.required);

    // Every required question should have a response
    for (const question of requiredQuestions) {
      if (!responses[question._id]) {
        return false;
      }
    }

    return true;
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }

    if (!this.attributes.labels) {
      this.attributes.labels = new PollLabels();
    }
  }

  private _checkIfHasVoted(): void {
    this._pollService.getHasVoted(this.attributes.id).subscribe({
      next: (res: HasVotedResponse) => {
        this.hasVoted = res.hasVoted;
        this.isValid = true;
        this._calculatePercentage(this.attributes.questions);
        this._cdr.detectChanges();
      },
      error: () => {
        this.hasVoted = false;
        this.isValid = true;
        this._cdr.detectChanges();
      },
    });
  }

  private _calculatePercentage(questions: PollQuestion[]): void {
    for (const question of questions) {
      const votesCount = question.responses.reduce((acc, { count }) => acc + count, 0);

      question.responses.forEach(response => {
        response.count = Math.round((response.count * 100) / (votesCount || 1));
      });
    }
  }

  private isValidWidgetAttributes(attributes: WidgetPoll): boolean {
    /*
     * Valid poll has
     * 1) Question with valid name
     * 2) Responses with valid text
     * */
    return (
      !!attributes.questions &&
      !!attributes.questions.length &&
      attributes.questions.every(q => !!q.question) &&
      attributes.questions.every(q => !!q.responses.length) &&
      attributes.questions.every(q => !!q.responses.every(res => !!res.text))
    );
  }

  private generateResponsesPayload(responses: { [key: string]: string }): PollResponsePayload {
    return (
      Object.entries(responses)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, responseId]) => !!responseId)
        .map(([questionId, responseId]) => ({
          questionId,
          responseId,
        }))
    );
  }

  private initResponsesServiceObject(attributes: WidgetPoll): { [key: string]: string } {
    /**
     * Service object for handle multiple questions
     * { [key - Question _id ]: value - Response _id }
     *
     * Example
     * { "6601832e5060b007ee5e9427": "6601832e5060b0839b5e9428" }
     *
     * */
    return attributes.questions.reduce((acc, question) => {
      acc[question._id] = '';
      return acc;
    }, {});
  }

  private dataProvideCheck(saveContact: boolean): void {
    const verifyProvidedEmail$ = this.profileService.getMe().pipe(
      tap(user => this.email.patchValue(user.email || '')),
      map(user => !user.email),
    );

    const noDataCollect$ = of(false).pipe(tap(show => !show && this.email.disable()));

    this.showEmailField$ = of(saveContact).pipe(
      // if saveContact === true => make request to check if user already has email provided, else noDataCollect logic
      switchMap(save => (save ? verifyProvidedEmail$ : noDataCollect$)),
    );
  }
}
