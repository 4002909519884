import { Pipe, PipeTransform } from '@angular/core';

import { PopProduct } from '../product-catalog';
import { RecommendationsMenuService } from '../services';

@Pipe({
  name: 'recommendationDetailsQuantity',
  pure: false,
})
export class RecommendationDetailsQuantityPipe implements PipeTransform {
  constructor(private menuService: RecommendationsMenuService) {}

  transform(product: PopProduct): number {
    return this.calculateProductQuantity(product);
  }

  private calculateProductQuantity(product: PopProduct): number {
    const recs = this.menuService.getAddedRecommendations;

    if (this.menuService.hasDetails(product)) {
      return recs
        .filter(rec => rec.id === product.id)
        .map(rec => rec.quantityMultiplier)
        .reduce((acc, val) => acc + val, 0);
    } else {
      return recs.find(({ id }) => id === product.id)?.quantityMultiplier || 0;
    }
  }
}
