<ng-container *ngIf="list; else preloader">
  <div
    *ngFor="let item of list | sortApp: appSlug"
    class="menu-item"
    (click)="changeApplication(item.appSlug)"
    [class.active]="item.appSlug === currentApp"
  >
    <span>{{ item.name }}</span>
  </div>
</ng-container>
<ng-template #preloader>
  <designer-spinner class="preloader" [diameter]="30"></designer-spinner>
</ng-template>
