export interface EnvGeneric {
  name: string;
  release: string;
  production: boolean;

  wssHost: string;
  storageHost: string;
  apiHost: string;

  userType: string;

  googleApiKey: string;
}

export interface EnvVisitor extends EnvGeneric {
  hotjarTrackId: string;
  sentryDsn: string;
  pwa: boolean;
}

export interface EnvDesigner extends EnvGeneric {
  appHost: string;
  qrHost: string;
  nfcHost: string;
}
