import { createAction, props } from '@ngrx/store';

import { createType, SubItem } from '../shared';
import { storeKey } from './sub-item.config';

const type = createType(storeKey);

export const actions = {
  addSubItems: createAction(type('add sub items'), props<{ entities: SubItem[] }>()),
};
