import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsConsultingMode } from '@visitor-store';
import { HappyHoursService } from 'projects/shared-widgets/src/lib/shared/happy-hours/happy-hours.service';
import { OrderService } from 'projects/store/src/lib/order/order.service';
import { combineLatest, distinctUntilChanged, iif, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';

import { CollectType, ParamsService, Prices, SettingsService } from '@bend/store';
import { ItemOptionGroup } from '@bend/widgets-old/product-catalog/product-catalog';

import { PriceFlowKey } from '@designer-config';

type FromCombineLatestData = [boolean, boolean, CollectType[], CollectType];

@Injectable({
  providedIn: 'root',
})
export class PricesService {
  flow: Observable<PriceFlowKey> = this.store
    .select(selectIsConsultingMode)
    .pipe(
      withLatestFrom(this.isPreviewMode()),
      switchMap(([isConsultingMode, isPreviewMode]) =>
        combineLatest([
          this.happyHoursService.isHappyHours,
          this.settings.widgetCart.pipe(map(cart => cart.allowPickup)),
          this.settings.widgetCart.pipe(map(cart => cart.collectTypes)),
          iif(() => isConsultingMode || isPreviewMode, of(null), this.order.currentDetails.collectType),
        ]),
      ),
    )
    .pipe(
      distinctUntilChanged(this.distinctUntilChangedCallback),
      map(([isHappyHours, allowPickup, collectTypes, currentCollectType]) =>
        this._getPriceFlowKey(isHappyHours, allowPickup, collectTypes, currentCollectType),
      ),
    );

  constructor(
    private settings: SettingsService,
    private order: OrderService,
    private happyHoursService: HappyHoursService,
    private store: Store,
    private params: ParamsService,
  ) {}

  private _getPriceFlowKey(
    isHappyHours: boolean,
    allowPickup: boolean,
    collectTypes: CollectType[],
    currentCollectType: CollectType,
  ): PriceFlowKey {
    // if enabled pick-up mode:
    if (allowPickup) {
      if (currentCollectType === CollectType.EatIn) return isHappyHours ? 'happyHours' : 'onSite';
      else return 'takeAway';
    }

    // online mode:
    if (collectTypes.includes(CollectType.TakeAway)) return 'takeAway';

    // other flows (pay-after, pay-before)
    return isHappyHours ? 'happyHours' : 'onSite';
  }

  getUpdatedProductByFlow<T extends { prices: Prices }>(product: T, flow: PriceFlowKey): T {
    return {
      ...product,
      priceInitialValue: this.initialPrice(product.prices, flow),
      priceFinalValue: this.finalPrice(product.prices, flow),
      price: this.finalPrice(product.prices, flow),
    };
  }

  initialPrice(prices: Prices, flow: PriceFlowKey): number | null {
    if (!prices[flow] || !prices[flow]?.price) return null;
    // not show initialPrice if happyHours flow is active and happyHours price more than onSite price
    if (flow === 'happyHours' && this.finalPrice(prices, flow) > prices.onSite.price) return null;

    return this.finalPrice(prices, flow) === prices.onSite.price || flow === 'takeAway' ? null : prices.onSite.price;
  }

  finalPrice(prices: Prices, flow: PriceFlowKey): number {
    return this.isPriceExist(prices, flow) ? prices[flow].price : prices.onSite.price;
  }

  /**
   * Returns { itemOptionGroups } - full updated options;
   * */
  public updatedItemOptionGroups(itemOptionGroups: ItemOptionGroup[], flow: PriceFlowKey): ItemOptionGroup[] {
    return itemOptionGroups.map(itemOptionGroup => {
      return {
        ...itemOptionGroup,
        optionGroup: {
          ...itemOptionGroup.optionGroup,
          optionGroupSubitems: itemOptionGroup.optionGroup.optionGroupSubitems.map(optionGroupSubitem => {
            return {
              ...optionGroupSubitem,
              item: this.getUpdatedProductByFlow(optionGroupSubitem.item, flow),
            };
          }),
        },
      };
    });
  }

  private isPriceExist(prices: Prices, flow: PriceFlowKey): boolean {
    return prices[flow] && prices[flow]?.price !== null && prices[flow]?.price !== undefined;
  }

  private isPreviewMode(): Observable<boolean> {
    return this.params.queryParams().pipe(map(({ _preview }) => _preview === 'true'));
  }

  private get distinctUntilChangedCallback(): (prev: FromCombineLatestData, curr: FromCombineLatestData) => boolean {
    return (prev, curr) => prev[0] === curr[0] && prev[1] === curr[1] && prev[2] === curr[2] && prev[3] === curr[3];
  }
}
