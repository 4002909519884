import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'designer-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnInit {
  readonly NO_IMAGE = 'assets/images/no-image.png';
  readonly DESCRIPTION_HEIGHT = 40;
  readonly DESCRIPTION_NAME_MARGIN = 20;

  /*
   * Url of displayed image
   *  */
  @Input() imageUrl: string;
  /*
   * Height of displayed image (not container)
   * */
  @Input() height = '200';
  /*
   * Width of displayed image (not container)
   * */
  @Input() width = '200';
  /*
   * Is image active (selected)
   * */
  @Input() active = false;
  /*
   * Name of displayed image
   * */
  @Input() name = 'Unnamed';
  /*
   * Date of displayed image
   * */
  @Input() date: string;
  /*
   * Is image loading
   * */
  @Input() isLoading = false;
  /*
   * Is need to show description
   * */
  @Input() showDescription = true;
  /*
   * Is image in grid of image-gallery
   * */
  @Input() isGridImage = false;
  /**
   * Is video file
   * */
  @Input() isVideo = false;

  @Output() deleteClicked = new EventEmitter();


  @HostBinding('class.grid') isGrid;
  @HostBinding('style.height')
  private get _setContainerHeight(): string {
    if (this.showDescription) return `${Number(this.height) + this.DESCRIPTION_HEIGHT}px`;

    return `${this.height}px`;
  }

  @HostBinding('style.width')
  private get _setContainerWidth(): string {
    return `${this.width}px`;
  }

  @HostBinding('style.justifyContent')
  private get _setPreloaderPosition(): string {
    return this.isLoading ? 'center' : 'space-between';
  }

  @HostBinding('class.active')
  private get _setIsActive(): boolean {
    return this.active;
  }

  protected previewImage: string;

  ngOnInit(): void {
    this.isGrid = this.isGridImage;
  }

  handleImageLoadError($event: ErrorEvent): void {
    ($event.target as HTMLImageElement).src = this.NO_IMAGE;
  }

  get setDescriptionWidth(): string {
    return `${Number(this.width) - this.DESCRIPTION_NAME_MARGIN}px`;
  }
}
