import { UrlPaths } from '../types';

export const parseUrl = (url: string, redirectTo: string = './'): UrlPaths => {
  /**
   * split url in paths
   */
  const urlTokens = url
    /**
     * remove query params
     */
    .replace(/\?.*/, '')
    /**
     * split url in paths
     */
    .split('/')
    /**
     * remove first empty string
     */
    .filter(path => !!path);
  /**
   * split redirect url in paths
   */
  const redirectToTokens = redirectTo
    .split('/')
    /**
     * remove first empty string
     */
    .filter(path => !!path);
  /**
   * get first path from redirect url
   */
  let token = redirectToTokens.shift();

  while (token) {
    if (token !== '.' && token !== '..') {
      /**
       * if path is not dot put path in redirect and combine with url
       */
      redirectToTokens.unshift(token);
      break;
    }
    /**
     * if path is .. remove last path from url
     */
    if (token === '..') urlTokens.pop();

    token = redirectToTokens.shift();
  }
  return urlTokens.concat(redirectToTokens);
};
