import { ChangeDetectorRef, Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogService } from '@bend/dialog';

import { Action, WidgetActionCallDialogParams } from '../action-call';
import { WidgetActionCallService } from '../action-call.service';
import { WidgetActionCallHelperService } from '../action-call-helper.service';

@Component({
  selector: 'pop-widget-action-call-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class WidgetActionCallDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<WidgetActionCallDialogComponent>,
    @Optional() private _dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: WidgetActionCallDialogParams,
    private _actionCallService: WidgetActionCallService,
    private _helperService: WidgetActionCallHelperService,
    private _cdr: ChangeDetectorRef,
  ) {}

  handleClick(action: Action): void {
    if (action.directMessage) {
      this._sendActionCall(action._id, action.actionMessage);
      return;
    }

    const url = this._helperService.getSingleActionUrl(action, this.data.place);
    if (url) {
      window.open(url, '_self');
    } else {
      this._sendActionCall(action._id, action.actionMessage);
    }
  }

  private _sendActionCall(actionId: string, message: string): void {
    const { pageId, place, id } = this.data;

    this._actionCallService.callAction(pageId, id, actionId, place).subscribe(() => {
      this.dialogRef.close();
      this._dialog.success({ message });
      this._cdr.detectChanges();
    });
  }
}
