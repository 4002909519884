<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="ckeditorInput" class="form-label">{{ 'WIDGETS.SHARED.TEXT' | translate }}</label>
      <ckeditor id="ckeditorInput" [editor]="ckeditor" [config]="editorConfig" formControlName="text"></ckeditor>
      <div
        *ngIf="text.invalid && (((text.dirty || text.touched) && text.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.TEXT_ENTER' | translate }}
      </div>
      <span class="info-text">{{ 'WIDGETS.TEXT.ADD_TABLE' | translate }}</span>
    </div>
  </form>
</pop-widget-edit-widget>
