<div class="header">
  <div class="header__language">
    <span class="language">Language:</span>
    <app-button
      *ngFor="let language of languages"
      class="language"
      type="button"
      color="primary"
      (clicked)="select(language)"
    >
      {{ language | uppercase }}
    </app-button>
  </div>
</div>
