import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { SelectItem } from '@bend/shared-widgets/src/lib/types';
import { CartService, LanguagesConfig } from '@bend/store';
import { SettingsService } from '@bend/store';

const enum DateFormat {
  Hour = 'HH:mm',
  Day = 'EEEE, d.MM.yy',
}

const LOCALES = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  dk: 'da-DK',
  /**
   * TODO: replace with hebrew locale (he-IL) when application will support hebrew
   */
  he: 'en-US',
};

@Injectable()
export class WorkingHoursService {
  constructor(private _settings: SettingsService, private _cart: CartService) {}

  delivery(day: Date): Observable<SelectItem<Date>[]> {
    /**
     * @description get delivery time by day
     * added minDeliveryTime and minOrderPreparationTime to final time
     */
    return this._getDeliveryTime(day).pipe(
      /**
       * @description convert to select item for modal selector
       * has label and value for selector options
       */
      map(items => this._convertInSelectItem(items, DateFormat.Hour)),
    );
  }

  takeAway(day: Date): Observable<SelectItem<Date>[]> {
    return this._customIntervalTimes(day).pipe(map(items => this._convertInSelectItem(items, DateFormat.Hour)));
  }

  get workingDays(): Observable<SelectItem<Date>[]> {
    return this._cart.workingHours.all.pipe(
      /**
       * @description get day time
       */
      map(items => items.map(({ open }) => open)),
      /**
       * @description sort the days ascending
       */
      map(items => items.sort((a, b) => a.getTime() - b.getTime())),
      /**
       * @description get days from all intervals and remove duplicates
       */
      map(items => this._getDays(items)),
      /**
       * @description convert to select item for modal selector
       * has label and value for selector options
       */
      withLatestFrom(this._settings.language),
      map(([items, language]) => this._convertInSelectItem(items, DateFormat.Day, language)),
      /**
       * @description check if first item is today
       * if true change label from date in Today world
       */
      map(([{ value, label }, ...rest]) => [
        { value, label: value.getDay() === new Date().getDay() ? 'CART.COLLECT.DELIVERY.TODAY' : label },
        ...rest,
      ]),
    );
  }

  private _getDays(items: Date[]): Date[] {
    /**
     * @description need for check if are duplicate days in items
     */
    const days: number[] = [];

    return items.reduce((accumulator, current) => {
      if (!days.includes(current.getDay())) {
        /**
         * @description if is today, push current time in array
         */
        const date = current.getDay() === new Date().getDay() ? new Date() : current;

        days.push(date.getDay());
        accumulator.push(date);
      }

      return accumulator;
    }, []);
  }

  private _getDeliveryTime(day: Date): Observable<Date[]> {
    return this._settings.widgetCart.pipe(
      switchMap(({ minOrderDeliveryTime }) =>
        this._customIntervalTimes(day).pipe(
          map(items => items.map(item => new Date(item.getTime() + this._castToSeconds(minOrderDeliveryTime)))),
        ),
      ),
    );
  }

  /**
   * @param day get all intervals for this day
   */
  private _customIntervalTimes(day: Date): Observable<Date[]> {
    return this._settings.widgetCart.pipe(
      switchMap(({ minOrderPreparationTime }) =>
        this._cart.workingHours.all.pipe(
          /**
           * @description filter intervals by day
           */
          map(intervals => intervals.filter(({ open }) => open.getDay() === day.getDay())),
          /**
           * @description sort time to start interval
           */
          map(intervals => [...intervals].sort(({ open: a }, { open: b }) => a.getTime() - b.getTime())),
          /**
           * @description create select items
           */
          map(intervals =>
            intervals.flatMap(({ open, close }) => this._createTimesIntervals(open, close, minOrderPreparationTime)),
          ),
          /**
           * @description remove all past times items
           */
          map(items => this._removePastItems(items, minOrderPreparationTime, day)),
        ),
      ),
    );
  }

  /**
   * @param preparationTime number in minutes
   */
  private _removePastItems(items: Date[], preparationTime: number, start: Date): Date[] {
    const nextTime = this._nextTime(start, preparationTime);

    return items.filter(item => item.getTime() >= nextTime.getTime());
  }

  /**
   * @param open date start interval
   * @param close date close interval
   * @param preparationTime number in minutes
   */
  private _createTimesIntervals(open: Date, close: Date, preparationTime: number): Date[] {
    const times: Date[] = [];

    let nextTime = this._nextTime(open, preparationTime);

    while (this._validateNextTime(nextTime, close)) {
      times.push(nextTime);

      nextTime = this._nextTime(nextTime, preparationTime);
    }

    return times;
  }

  /**
   * @param preparationTime number in minutes
   */
  private _nextTime(start: Date, preparationTime: number): Date {
    const startMilliseconds = start.getTime();

    const nextTime = new Date(startMilliseconds + this._castToSeconds(preparationTime));

    return nextTime;
  }

  private _validateNextTime(nextTime: Date, closeTime: Date): boolean {
    return nextTime.getTime() <= closeTime.getTime();
  }

  private _castToSeconds(minutes: number): number {
    return minutes * 6e4;
  }

  private _convertInSelectItem(
    items: Date[],
    dateFormat: DateFormat,
    language: LanguagesConfig = 'en',
  ): SelectItem<Date>[] {
    return items.map(value => ({ value, label: this._formatTime(value, dateFormat, language) }));
  }

  private _formatTime(time: Date, format: DateFormat, language?: LanguagesConfig): string {
    const datePipe = new DatePipe(LOCALES[language]);

    return datePipe.transform(time, format);
  }
}
