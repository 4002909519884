import { UntypedFormGroup } from '@angular/forms';

import { ApplicationType as ApplicationSettingsType, CollectType, LanguagesConfig } from '@bend/store-shared';
import { CartLabels } from '@bend/widgets-editor/src/lib/widgets/cart/cart';

import { Printer } from '../../../../../store/src/lib/settings/settings-deprecated.type';
import { ActionType } from './pages.type';

export interface PopApplication {
  cover?: {
    url: string;
    alt: string;
  };
  timezone?: string;
  place?: {
    city?: string;
  };
  social?: {
    instagram: string;
    twitter: string;
    linkedin: string;
    facebook: string;
  };
  paymentSettings?: PaymentSettings;
  description?: string;
  name?: string;
  merchantId?: string;
  settings?: CatalogSettings;
  uiSettings?: UiSettings;
  colors?: {
    primary: string;
    secondary: string;
  };
  tags?: string[];
  organizer?: string[];
  url?: string;
  language?: LanguagesConfig;
  id?: string;
  templateId?: string;
  period?: {
    endAt: string;
    startAt: string;
  };
  page?: string;
  slug?: string;
  appType?: string;
  applicationType: ApplicationSettingsType;
}

export interface UiSettings {
  bottomBar?: BottomBar;
  topBar?: TopBar;
  styles?: UiStyles;
}

export interface UiStyles {
  general: {
    productCatalogStyle: number;
  };
  widgets: WidgetsSettings;
}

export interface WidgetsSettings {
  product_teaser: {
    productDescriptionStyle: number;
  };
  product_catalog: ProductCatalogStyleConfig;
}

export interface ProductCatalogStyleConfig {
  hasSearch: boolean;
  productDescriptionStyle: number;
  stepByStepCategories: boolean;
  stepByStepMenus: boolean;
  style: ProductCatalogDisplayType;
  categoriesStyle: number;
  menuType: number;
  menuStyleType: number;
  menuItemStyleType: number;
  categoryStyleType: number;
  categoryAlignType: number;
  fullWidth: boolean;
}

export enum ProductCatalogDisplayType {
  STYLE1 = 1,
  STYLE2 = 2,
  STYLE3 = 3,
  STYLE4 = 4,
}

export enum ProductCatalogTeaserDisplayType {
  STYLE1 = 1,
  STYLE2 = 2,
}

export enum CategoriesDisplayType {
  STYLE1 = 1,
  STYLE2 = 2,
  STYLE3 = 3,
}

export enum MenuType {
  STYLE1 = 0,
  STYLE2 = 1,
}

export enum MenuStyleType {
  STYLE1 = 1,
  STYLE2 = 2,
  STYLE3 = 3,
  STYLE4 = 4,
}

export enum MenuItemStyleType {
  STYLE1 = 1,
  STYLE2 = 2,
}

export enum CategoryStyleType {
  STYLE1 = 1,
  STYLE2 = 2,
}

export interface TopBar {
  display: boolean;
  allowUserProfile: boolean;
  backButton: HomeBackMenuButton;
  homeButton: HomeBackMenuButton;
  menuButton: HomeBackMenuButton;
  brandIcon: HomeBackMenuButton;
}

export interface HomeBackMenuButton {
  display: boolean;
  icon: string;
}

export interface BottomBar {
  display?: boolean;
  buttons?: BottomBarButton[];
  actionCall?: BottomBarActionCall;
  cart?: BottomBarCart;
}

export interface BottomBarCart {
  display: boolean;
  attributes: {
    labels?: CartLabels;
  };
}

export interface BottomBarButton {
  title: string;
  link: string;
  icon: string;
  type?: ButtonType;
}

export class BottomBarActionCall {
  display = false;
  title: string = null;
  popupHeader: string = null;
  icon: string = null;
  actionButtons: ActionCall[] = [];
}

export interface ActionCall {
  _id?: string;
  type?: ActionType;
  transmitionType?: TransmitionType;
  title?: string;
  icon?: string;
  description?: string;
  actionMessage?: string;
  telephone?: string;
  sms?: string;
  email?: string;
  pos?: string;
  designer?: string;
  addTableName?: boolean;
  body?: string;
  directMessage?: boolean;
  from?: string;
  subject?: string;
}

export interface ButtonChecked extends BottomBarButton {
  checked: boolean;
}

export interface ActionChecked extends ActionCall {
  checked: boolean;
}

export enum TransmitionType {
  TELEPHONE = 'telephone',
  SMS = 'sms',
  EMAIL = 'email',
  POS = 'pos',
  DESIGNER = 'designer',
}

export enum ButtonType {
  REVIEW = 'Review',
  ORDER = 'Order',
  PAY = 'Pay',
  AMENITIES = 'Amenities',
  PROGRAM = 'Program',
  CUSTOM = 'Custom',
}

export interface GroupedPopApplications {
  [key: string]: PopApplication[];
}

export interface AppType {
  name: string;
  slug: string;
  logo: {
    url: string;
    alt: string;
  };
  banner: {
    url: string;
    alt: string;
  };
  image: {
    url: string;
    alt: string;
  };
  description: string;
  isActive: boolean;
  order: number;
  _id: string;
}

export interface CatalogSettings {
  ui?: UiSettings;
  allowedLanguages?: LanguagesConfig[];
  language?: LanguagesConfig;
  catalog?: {
    isReadOnly: boolean | null;
    allowOrderForOthers: boolean | null;
    syncPricesWithPos: boolean | null;
  };
  cart?: {
    isShared?: boolean | null;
    payBefore?: boolean | null;
    allowPickup?: boolean | null;
    showTableName?: boolean | null;
    showMoveMessage?: boolean | null;
    closeAfterMin?: number;
    askForPickupName?: boolean;
    tableAsUsername?: boolean;
    allowSplitBill?: boolean;
    autoClosePayBefore?: boolean;
    autoClosePayAfter?: boolean;
    moveBehavior?: MoveBehavior;
    allowCashPayment?: boolean;
    cashPaymentOnly?: boolean;
    isPaymentRequired?: boolean;
    allowUserCreateNewSesssion?: boolean;
    displayNameAsOrderIdPrefix?: boolean;
    orderIdPrefix?: string;
    allowSmsNotification?: boolean;
    closeAfterFullyPayMin?: number;
    noPayment?: boolean;
    confirmPayment?: boolean;
    multiKitchen?: boolean;
    askEmail?: boolean;
    askName?: boolean;
    saveCreditCard?: boolean;
    allowOrderForOthers?: boolean;
    collectTypes?: CollectType[];
  };
  businessLocation?: {
    address: Address;
  };
  behaviors?: {
    disableAditionsAfterOrder?: boolean;
  };
  tips?: Tips;
  config?: {
    mode: string;
  };
  analytics?: {
    trackingId?: string;
    hotjarId?: string;
  };
  order?: {
    enableSound: boolean | null;
  };
  receipt?: {
    header: string | null;
    footer: string | null;
  };
  printer?: Printer;
  pwa?: PwaSettings;
  gdpr?: GpdrSettings;
  defaultPage?: string | null;
  defaultPlace?: string | null;
  currency?: PopCurrency;
  timezone?: string;
  discount?: DiscountSettings;
  pop?: PopSettings;
  workingHours?: WorkingHoursSettings;
  phone?: {
    prefix: string;
    mask: string;
  };
  capacityManagement?: {
    enabled: boolean;
    type: 'auto' | 'manual';
    timeSlot: number;
    capacity: number;
  };
}

export interface GpdrSettings {
  labels: GdprLabels;
  showGdpr: boolean;
  url: string;
}

export interface PwaSettings {
  themeColor: string;
  backgroundColor: string;
  enabled: boolean;
  manifest: {
    name: string;
    short_name: string;
    theme_color: string;
    background_color: string;
    display: string;
    Scope: string;
    start_url: string;
    icons: {
      src: string;
      sizes: string;
      type: string;
    }[];
    splash_pages: string;
  };
  icon: string;
  showAddAfterLaunch: boolean;
  showAddAfterPayment: boolean;
  showAddButtonInTopBar: boolean;
  labels: {
    INSTALL: 'Installer';
    MESSAGE1_ANDROID: 'Cliquez sur le bouton menu';
    MESSAGE2_ANDROID: 'Puis cliquez sur \'Ajouter à l\'écran d\'accueil\'';
    MESSAGE1_IOS: 'Cliquez sur le bouton partager';
    MESSAGE2_IOS: 'Puis cliquez sur \'Ajouter à l\'écran d\'accueil\'';
    TITLE: 'Comment ajouter {{ name }} à mon téléphone ?';
    CART_TITLE: 'Envie de recommander ?';
    CART_SUBTITLE: 'Ajoutez un raccourci sur votre téléphone !';
    CART_INSTALL: 'Installer';
    CART_NOT_NOW: 'Plus tard';
  };
}

export interface GdprLabels {
  RGPD_MESSAGE: string;
  RGPD_SEE_LINK: string;
  RGPD_ACCEPT: string;
}

export enum DemoProd {
  DEMO = '(DEMO)',
  PROD = '(PROD)',
}

export enum EnabledDisabled {
  ENABLED = '(Enabled)',
  DISABLED = '(Disabled)',
}

export enum WorkingHoursStatus {
  AlwaysOpen = '(Always open)',
  Custom = '(Custom)',
}

export enum IsSet {
  SET = '(Set)',
}

export class AppSettingsStatus {
  payment = DemoProd.DEMO;
  pop = EnabledDisabled.DISABLED;
  tips = EnabledDisabled.DISABLED;
  workingHours = WorkingHoursStatus.AlwaysOpen;
  analytics = '';
  hotjar = '';
  topBar = EnabledDisabled.DISABLED;
  bottomBar = EnabledDisabled.DISABLED;
}

export interface GpsSettings {
  type: 'gps';
  enabled: boolean;
  introMsg: string;
  popSuccessMsg: string;
  popErrorMsg: string;
  deniedMsg: string;
  gpsPoint: [number, number];
  radius: number;
}

export interface PopSettings {
  tokenRequired: boolean;
  allowReadOnly: boolean;
  readOnlyDisplayMsg: string;
  noAccessDisplayMsg: string;
  tokenExpiresIn?: string;
  upgradeMethod?: GpsSettings[];
}

export interface TimeZone {
  city: string;
  delay: number;
  cityWithDelay: string;
}

export interface PaymentSettings {
  credentials?: PaymentCredentials;
  mId?: string;
  key?: string;
  provider?: string;
  acceptMethods?: string[];
  publicKey?: string;
  privateKey?: string;
  mode?: BraintreePaymentMode;
  account?: string;
  sharedSecret?: string;
  rebatePassword?: string;
}

export interface PaymentCredentials {
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  scope: string;
  tokenType: string;
}

export class PrinterSettings {
  isSoundEnabled = false;
  isPrinterEnabled = false;
  copiesToPrint = 1;
}

export interface DiscountSettings {
  hasDiscount: boolean;
  discountEmailRequired: boolean;
  chartRequired: boolean;
  chartUrl: string;
  hasPromoCode: boolean;
  hasCoupon: boolean;
  showAllPromoCodes: boolean;
}

export enum ApplicationType {
  EVENT = 'event',
  TRAVEL = 'travel',
  CORNER = 'corner',
  EAT = 'eat',
}

export interface PopCurrency {
  code: string;
  symbol: string;
  name?: string;
}

export interface WorkingHours {
  items: WorkingHoursItem[];
  settings: WorkingHoursSettings;
}

export interface HappyHours {
  items: WorkingHoursItem[];
  settings: HappyHoursSettings;
}

export interface WorkingHoursItem {
  id?: string;
  open: string;
  close: string;
  weekDay: number;
}

export enum ScheduleType {
  AlwaysOpen = 'alwaysOpen',
  Custom = 'custom',
}

export class WorkingHoursSettings {
  timezone: string = null;
  scheduleType: string = null;
  redirectUrl: string = null;
  entityType: WorkingHoursEntityType = null;
  entityId: string = null;
  closeMessage: string = null;
  aboutToCloseMessage: string = null;
}

export class HappyHoursSettings {
  timezone: string = null;
  enabled = false;
  redirectUrl: string = null;
  entityType: WorkingHoursEntityType = null;
  entityId: string = null;
  closeMessage: string = null;
  aboutToCloseMessage: string = null;
}

export interface WorkingHoursDisplay {
  isDay?: boolean;
  dayNr: number;
  dayName: string;
  workInterval: WorkIntervalDisplay[];
}

export interface WorkIntervalDisplay {
  id?: string;
  open: string;
  close: string;
}

export interface WorkingHoursItemsSettingsDisplay {
  items: WorkingHoursDisplay[];
  settings: WorkingHoursSettings;
}

export interface WorkingHoursChildrenData {
  workingHoursForm: UntypedFormGroup;
  itemIdsToBeDeleted: string[];
}

export enum WorkingHoursEntityType {
  AREA = 'area',
  LOCATION = 'location',
  APPLICATION = 'application',
}
export interface Tips {
  allowed: boolean;
  options: TipsOptions[];
  restrictions: TipsRestrictions[];
}

export interface TipsOptions {
  type: string;
  value?: number;
  label: string;
  default?: boolean;
}

export interface TipsRestrictions {
  type: string;
  value: number;
  key: string;
}

export enum TipsType {
  ABSOLUTE = 'absolute',
  PERCENTAGE = 'percent',
}

export enum MoveBehavior {
  MOVE = 'move',
  NEW = 'new',
  DENY = 'deny',
}

enum BraintreePaymentMode { // TODO: check if it was used only by braintree and in this case remove it
  DEV = 2,
  PROD = 1,
}

export interface FileData {
  name: string;
  data: string;
  type: string;
}

export interface PopAppForDuplicate {
  duplicateCatalogs: boolean;
  templateId: string;
  copyTvas: boolean;
  copyTags: boolean;
  name: string;
  slug: string;
}

export interface Address {
  name: string;
  addressName: string;
  zipCode: string;
  city: string;
  additionalDetails: string;
}
