import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ArrayStore,
  CartCredit,
  DictionaryPaymentMethods,
  DictionaryStore,
  ifPluck,
  ifPluckArray,
  PaymentMethod,
  PaymentMethods,
  StoreService,
  WorkingHoursInterval,
} from '../shared';
import { actions } from './cart.actions';
import * as selectors from './cart.selectors';
import { State } from './cart.type';

@Injectable()
export class CartService extends StoreService<State> {
  paymentMethods: Observable<PaymentMethods[]>;
  credit: DictionaryStore<CartCredit>;
  workingHours: ArrayStore<WorkingHoursInterval>;

  constructor(_store: Store<State>, _actions: Actions) {
    super(_store, _actions, selectors);

    this.paymentMethods = this._sortedPaymentMethods;
    this.credit = this._credit;
    this.workingHours = this._workingHours;
  }

  getMethods(): Observable<boolean> {
    this._store.dispatch(actions.getPaymentMethods());

    return this._finishedAction(actions.getPaymentMethodsSuccess, actions.getPaymentMethodsError);
  }

  deleteMethod(id: string): void {
    this._store.dispatch(actions.deletePaymentMethod({ id }));
  }

  getCredit(): Observable<boolean> {
    this._store.dispatch(actions.getCredit());

    return this._finishedAction(actions.getCreditSuccess, actions.getCreditError);
  }

  getWorkingHours(): Observable<boolean> {
    this._store.dispatch(actions.getWorkingHours());

    return this._finishedAction(actions.getWorkingHoursSuccess, actions.getWorkingHoursError);
  }

  selectCredit(selected: boolean): void {
    this._store.dispatch(actions.selectCredit({ selected }));
  }

  get creditCart(): Observable<{ provider: string; savedCards: PaymentMethod[] }> {
    return this._paymentMethods.pipe(map(paymentMethod => paymentMethod[PaymentMethods.CreditCard]));
  }

  get restaurantTicket(): Observable<PaymentMethod> {
    return this._paymentMethods.pipe(map(paymentMethod => paymentMethod[PaymentMethods.RestaurantTicket]));
  }

  get googlePay(): Observable<PaymentMethod> {
    return this._paymentMethods.pipe(map(paymentMethod => paymentMethod[PaymentMethods.GooglePay]));
  }

  get applePay(): Observable<PaymentMethod> {
    return this._paymentMethods.pipe(map(paymentMethod => paymentMethod[PaymentMethods.ApplePay]));
  }

  get swile(): Observable<PaymentMethod> {
    return this._paymentMethods.pipe(map(paymentMethod => paymentMethod[PaymentMethods.Swile]));
  }

  private get _paymentMethods(): Observable<DictionaryPaymentMethods> {
    return this._store.select(selectors.paymentMethods);
  }

  private get _credit(): DictionaryStore<CartCredit> {
    const all = this._store.select(selectors.credit);
    return {
      all,
      selected: all.pipe(ifPluck('selected')),
      value: all.pipe(ifPluck('value')),
    };
  }

  private get _workingHours(): ArrayStore<WorkingHoursInterval> {
    const all = this._store.select(selectors.workingHours);
    return {
      all,
      id: all.pipe(ifPluckArray('id')),
      open: all.pipe(ifPluckArray('open')),
      close: all.pipe(ifPluckArray('close')),
    };
  }

  private get _sortedPaymentMethods(): Observable<PaymentMethods[]> {
    return this._paymentMethods.pipe(
      map(methods => {
        const order = {
          [PaymentMethods.CreditCard]: 4,
          [PaymentMethods.RestaurantTicket]: 3,
          [PaymentMethods.GooglePay]: 2,
          [PaymentMethods.ApplePay]: 1,
        };

        return Object.keys(methods).sort((a, b) => order[b] - order[a]) as PaymentMethods[];
      }),
    );
  }
}
