import { Injectable } from '@angular/core';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TranslateWrapperService {
  constructor(private _translate: TranslateService, private _parser: TranslateParser) {}

  stream(message: string, interpolateDate: any): Observable<string> {
    return this._translate.stream(message).pipe(map(value => this._parser.interpolate(value, interpolateDate)));
  }
}
