import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Margin } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { fontSizeValidator } from '../../shared/validators/font-size.validator';
import { WidgetService } from '../widget.service';
import { WifiDisplayType, WifiWidget } from './wifi';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-edit-wifi',
  templateUrl: './wifi.component.html',
  styleUrls: ['./wifi.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditWifiComponent extends WidgetBaseComponent<WifiWidget> implements OnInit {
  static widgetName = 'wifi';
  typesName: object;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this._initForm();

    const { STYLE1, STYLE2 } = WifiDisplayType;
    this.typesName = {
      [STYLE1]: 'Classic',
      [STYLE2]: 'Elegant',
    };
  }

  private _initForm(): void {
    const { style } = this.widget.attributes;

    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        name: [this.widget.attributes.name, Validators.required],
        pass: [this.widget.attributes.pass],
        style: this._formBuilder.group({
          fontSize: [style.fontSize || DEFAULT, fontSizeValidator()],
          backgroundColor: [style.backgroundColor],
          iconUrl: [style.iconUrl],
          fullWidth: [style.fullWidth],
          showBorders: [style.showBorders],
          margin: this._formBuilder.group({
            top: [style.margin.top],
            bottom: [style.margin.bottom],
          }),
          displayType: [style.displayType],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _addMissingAttributes(widget: WifiWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        showBorders: true,
        fullWidth: false,
        fontSize: DEFAULT,
        backgroundColor: '',
        margin: new Margin(),
        iconUrl: '',
        displayType: WifiDisplayType.STYLE1,
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new Margin();
    }

    if (!attributes.style.displayType) {
      attributes.style.displayType = WifiDisplayType.STYLE1;
    }
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get pass(): UntypedFormControl {
    return this.form.get('pass') as UntypedFormControl;
  }

  get fontSize(): UntypedFormControl {
    return this.form.get('style.fontSize') as UntypedFormControl;
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }
}
