<div [formGroup]="form" class="query-param">
  <div class="form-field query-param__value">
    <label for="param-{{ form['controls'].key.value }}" class="form-field__label">
      {{ 'WIDGETS.SHARED.PARAM_NAME' | translate }}
    </label>
    <div class="form-field__content query-param__value-content">
      <div class="tags">
        <input
          id="param-{{ form['controls'].key.value }}"
          formControlName="key"
          type="text"
          class="form-field__input"
        />
      </div>
      <span *ngIf="form['controls'].key.touched && form['controls'].key.invalid" class="form-field__error">
        {{ 'WIDGETS.SHARED.REQUIRED' | translate }}
      </span>
    </div>
  </div>

  <div class="form-field query-param__value">
    <label for=" value-{{ form['controls'].key.value }}" class="form-field__label">
      {{ 'WIDGETS.SHARED.VALUE' | translate }}
    </label>
    <div class="form-field__content query-param__value-content">
      <div class="tags">
        <div *ngFor="let paramValue of form.value.rules; index as i" class="tag">
          <span class="tag__title">{{ paramValue }}</span>
          <button (click)="removeQueryParamRule(i)" class="tag__close-button">
            <lib-icon icon="x" class="close-icon"></lib-icon>
          </button>
        </div>
        <input
          id="value-{{ form['controls'].key.value }}"
          type="text"
          class="tag__input"
          (blur)="blurInputTag($any($event.target))"
          (keyup.enter)="addQueryParamRule($any($event.target))"
        />
      </div>
      <span *ngIf="form['controls'].rules.touched && form['controls'].rules.invalid" class="form-field__error">
        {{ 'WIDGETS.SHARED.REQUIRED' | translate }}
      </span>
    </div>
  </div>
</div>
