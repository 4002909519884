import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { GenericPromoCode } from '../shared';
import { actions } from './promo-codes.actions';
import { State } from './promo-codes.type';

export const adapter: EntityAdapter<GenericPromoCode> = createEntityAdapter<GenericPromoCode>({
  sortComparer: (a: GenericPromoCode, b: GenericPromoCode) => b.id - a.id,
});

export const initialState: State = adapter.getInitialState({
  isLoadedByAppSlug: '',
  isLoading: false,
  limit: 0,
  total: 0,
  offset: 0,
  error: '',
});

const promocodeReducer = createReducer(
  initialState,
  on(actions.getPromoCodes, actions.addPromoCode, actions.deletePromoCode, actions.savePromoCode, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.getPromoCodesIsLoaded, state => ({ ...state, isLoading: false })),

  // Success

  on(actions.getPromoCodesSuccess, (state, { promoCodes: { items, total, limit, offset }, appSlug }) =>
    adapter.setAll(items, { ...state, total, limit, offset, isLoadedByAppSlug: appSlug }),
  ),

  on(actions.addPromoCodeSuccess, (state, { code }) => adapter.addOne(code, { ...state, total: state.total + 1 })),

  on(actions.deletePromoCodeSuccess, (state, { promoCodeId }) =>
    adapter.removeOne(promoCodeId, { ...state, total: state.total - 1 }),
  ),

  on(actions.savePromoCodeSuccess, (state, { code }) => adapter.upsertOne(code, state)),

  on(
    actions.getPromoCodesSuccess,
    actions.addPromoCodeSuccess,
    actions.deletePromoCodeSuccess,
    actions.savePromoCodeSuccess,
    state => ({ ...state, isLoading: false }),
  ),

  // Error

  on(
    actions.getPromoCodesError,
    actions.addPromoCodeError,
    actions.deletePromoCodeError,
    actions.savePromoCodeError,
    (state, { code }) => ({ ...state, isLoading: false, error: code }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return promocodeReducer(state, action);
}
