<div class="wrapper" *ngFor="let language of allowedLanguages">
  <translate-language-button
    class="wrapper__button"
    [language]="language"
    [error]="languagesErrors[language]"
    [disabled]="disabled"
    [showIcon]="showIcons"
    (clicked)="onClick(language)"
  ></translate-language-button>

  <div
    class="wrapper__underline"
    [ngClass]="{
      'wrapper__underline--visible': language === currentLanguage,
      'wrapper__underline--disabled': disabled
    }"
  ></div>
</div>
