import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { ProfileWidget } from './profile';

@Component({
  selector: 'pop-widget-edit-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditProfileComponent extends WidgetBaseComponent<ProfileWidget> implements OnInit, OnDestroy {
  static widgetName = 'profile';
  private _subscription: Subscription;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  get profileImage(): UntypedFormGroup {
    return this.form.get('profile_image') as UntypedFormGroup;
  }

  get profileImageUrl(): UntypedFormControl {
    return this.profileImage.get('url') as UntypedFormControl;
  }

  get profileImageAlt(): UntypedFormControl {
    return this.profileImage.get('alt') as UntypedFormControl;
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  ngOnInit(): void {
    this._initForm();
    this._subscription = this.name.valueChanges.subscribe(value => this.profileImageAlt.setValue(value));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        name: [this.widget.attributes.name, Validators.required],
        description: [this.widget.attributes.description],
        social: this.formBuilder.group({
          instagram: [this.widget.attributes.social.instagram],
          twitter: [this.widget.attributes.social.twitter],
          facebook: [this.widget.attributes.social.facebook],
          linkedin: [this.widget.attributes.social.linkedin],
        }),
        profile_image: this.formBuilder.group({
          url: [this.widget.attributes.profile_image.url],
          alt: [this.widget.attributes.profile_image.alt],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
