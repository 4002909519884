import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IConfig } from 'ngx-mask';

import { CountriesDialogComponent } from '../countries-dialog/countries-dialog.component';
import { Countries, Country } from './countries.config';

@Component({
  selector: 'pop-widget-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements OnInit, AfterViewInit {
  // TD(artiom) remove any
  @Input() phone: any;
  @Input() data: UntypedFormControl;
  @Input() mainColor: string;
  @Input() editable = true;
  phoneControl: UntypedFormControl;
  mask: string;
  specialCharacters: IConfig['specialCharacters'];
  patterns: IConfig['patterns'];
  icon: string;
  isCompleted: boolean;
  prefixSeparator: string;
  initialPhone = '';
  private _countries: Country[];

  constructor(public dialog: MatDialog, private _cdr: ChangeDetectorRef) {
    this.mask = 'zxx xx xxx';
    this._countries = Countries;
    this.specialCharacters = ['-', ' ', '(', ')'];
    this.patterns = { 'x': { pattern: /[0-9]/ }, 'z': { pattern: /[1-9]/ }, '!': { pattern: /error/ } };
  }

  ngOnInit(): void {
    this.phoneControl = this.data.get('phone') as UntypedFormControl;
    this._initPhone();
  }

  ngAfterViewInit(): void {
    this.phoneControl.setValue(this.initialPhone);

    if (this.phoneControl.valid && this.phoneControl.value && this.editable) {
      this.isCompleted = true;
      this.phoneControl.disable();
    }
    this._cdr.detectChanges();
  }

  editInput(): void {
    this.isCompleted = false;
    this.phoneControl.enable();
  }

  onOpenModal(): void {
    if (!this.editable || this.isCompleted) {
      return;
    }

    const dialogRef = this.dialog.open(CountriesDialogComponent, {
      hasBackdrop: false,
      closeOnNavigation: true,
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'country-select-dialog-container',
    });

    dialogRef.afterClosed().subscribe(country => {
      if (!country) {
        return;
      }

      this._setAllData(country);
      this.isCompleted = false;
      this.phoneControl.enable();
      this._cdr.detectChanges();
    });
  }

  get prefixControl(): AbstractControl {
    return this.data.get('prefix');
  }

  private _initPhone(): void {
    const country = this._findCountry(this.data.value.phone);

    if (country) {
      this._setAllData(country);
    } else {
      this._setDefaultPrefix();
    }

    this._cdr.markForCheck();
  }

  private _findCountry(phone: string): Country | undefined {
    const country = Countries.find(({ prefix, mask }) => {
      if (phone.includes(prefix)) {
        this.initialPhone = phone.replace(prefix, '');

        const maskStrExp = mask
          // remove all characters from mask
          .replace(/[., -]/g, '')
          // replace x for use in regex 0-9
          .replace(/x/g, '[0-9]')
          // replace x for use in regex 1-9
          .replace(/z/g, '[1-9]')
          // only allow the exact length
          .concat('$');

        const maskRegExp = new RegExp(maskStrExp);

        return maskRegExp.test(this.initialPhone);
      }

      return false;
    });

    return country;
  }

  private _setDefaultPrefix(): void {
    const country = this._countries.find(({ prefix }) => prefix === this.phone.prefix);

    this._setAllData(country);
  }

  private _setAllData(country: Country): void {
    const { prefix, mask, code, prefixSeparator } = country;

    this.prefixControl.setValue(prefix);

    this.prefixSeparator = prefixSeparator;
    this.mask = prefix === this.phone.prefix && this.phone.mask ? this.phone.mask : mask;
    this.icon = `/assets/images/flags/${code.toLocaleLowerCase()}.svg`;
  }
}
