<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form">
    <label class="input-label">
      {{ 'WIDGETS.LOYALTY_SHARE.TEXT' | translate }}
      <app-input class="input" formControlName="text"></app-input>
    </label>

    <label class="input-label">
      {{ 'WIDGETS.LOYALTY_SHARE.URL' | translate }}
      <pop-widget-edit-link-selector
        class="page-selector"
        [host]="host"
        [pages]="pages$ | async"
        [control]="url"
        class="input"
      ></pop-widget-edit-link-selector>
    </label>

    <label class="input-label">
      {{ 'WIDGETS.LOYALTY_SHARE.MESSAGE' | translate }}
      <textarea formControlName="message" rows="10" class="form-input input form-input--long-textarea"></textarea>
    </label>
  </form>
</pop-widget-edit-widget>
