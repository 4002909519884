<label>
  <div class="title">
    <app-input-label class="title__first">
      <ng-content select="[title]"></ng-content>
    </app-input-label>

    <app-input-label-small class="title__subtitle">
      <ng-content select="[subtitle]"></ng-content>
    </app-input-label-small>

    <app-input-label *ngIf="tooltip" class="title__tooltip">
      <lib-icon [matTooltip]="tooltip" matTooltipPosition="above" matTooltipHideDelay="200" icon="info-icon"></lib-icon>
    </app-input-label>
  </div>

  <div>
    <ng-content select="[input]"></ng-content>
  </div>

  <span class="description">
    <ng-content select="[description]"></ng-content>
  </span>
</label>
