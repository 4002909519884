import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  AdyenConfig,
  filterByType,
  LyfPayConfig,
  PaymentConfig,
  PaymentType,
  StoreService,
  TpaConfig,
  YavinConfig,
} from '../shared';
import { actions } from './payment.actions';
import * as selectors from './payment.selectors';
import { State } from './payment.type';

@Injectable()
export class PaymentService extends StoreService<State> {
  readonly adyenConfig: Observable<AdyenConfig>;
  readonly lyfPayConfig: Observable<LyfPayConfig>;
  readonly tpaConfig: Observable<TpaConfig>;
  readonly yavinConfig: Observable<YavinConfig>;

  constructor(
    protected override _store: Store<State>,
    protected override _actions: Actions,
  ) {
    super(_store, _actions, selectors);

    this.adyenConfig = this._adyenConfig;
    this.lyfPayConfig = this._lyfPayConfig;
    this.tpaConfig = this._tpaConfig;
    this.yavinConfig = this._yavinConfig;
  }

  get paymentConfigList(): Observable<PaymentConfig[]> {
    return this._store.pipe(select(selectors.paymentConfigList));
  }

  get paymentConfig(): Observable<PaymentConfig> {
    return this._store.pipe(select(selectors.currentPaymentConfig));
  }

  get selectIsLoadedByIds(): Observable<number[]> {
    return this._store.select(selectors.selectIsLoadedByIds);
  }

  get allState(): Observable<any> {
    return this._store.pipe(select(selectors.all));
  }

  get errorCode(): Observable<string> {
    return this._store.pipe(select(selectors.errorCode));
  }

  get isLoaded(): Observable<any> {
    return this._store.pipe(select(selectors.isLoaded));
  }

  getPaymentConfigList(appSlug: string): Observable<boolean> {
    this._store.dispatch(actions.getPaymentConfigList({ appSlug }));

    return this._finishedAction(actions.getPaymentConfigListSuccess);
  }

  getPaymentConfig(id: number): Observable<boolean> {
    this._store.dispatch(actions.getPaymentConfig({ id }));

    return this._finishedAction(actions.getPaymentConfigSuccess);
  }

  updatePaymentConfig(paymentConfig: PaymentConfig): Observable<boolean> {
    this._store.dispatch(actions.updatePaymentConfig({ paymentConfig }));

    return this._finishedAction(actions.updatePaymentConfigSuccess, actions.updatePaymentConfigError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  deletePaymentConfig(id: number): Observable<boolean> {
    this._store.dispatch(actions.deletePaymentConfig({ id }));

    return this._finishedAction(actions.deletePaymentConfigSuccess, actions.deletePaymentConfigError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  addPaymentConfig(paymentConfig: PaymentConfig): Observable<boolean> {
    this._store.dispatch(actions.addPaymentConfig({ paymentConfig }));

    return this._finishedAction(actions.addPaymentConfigSuccess, actions.addPaymentConfigError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  getCurrentPaymentConfig(id: number): Observable<PaymentConfig> {
    return this._store.pipe(
      select(selectors.entities),
      map(payment => payment[id]),
    );
  }

  getLyfPaymentConfigs(): void {
    this.lyfPaymentConfigList
      .pipe(
        first(),
        map(lyfPaymentConfigList => lyfPaymentConfigList.map(config => config.id)),
        mergeMap(lyfPaymentConfigListIds => lyfPaymentConfigListIds.map(id => this.getPaymentConfig(id))),
      )
      .subscribe();
  }

  get lyfMultiboxIsEnabled(): Observable<boolean> {
    return combineLatest([this.lyfPaymentConfigList, this.selectIsLoadedByIds]).pipe(
      /**
       * check that all lyf configs are loaded fully
       */
      filter(([lyfPaymentConfigList, isLoadedByIds]) => {
        const lyfPaymentConfigListIds = lyfPaymentConfigList.map(config => config.id);

        return lyfPaymentConfigListIds.every(id => isLoadedByIds.includes(id));
      }),
      map(([lyfPaymentConfigList]) => lyfPaymentConfigList.some(config => config.multibox)),
    );
  }

  private get _adyenConfig(): Observable<AdyenConfig> {
    return this._store.pipe(select(selectors.currentPaymentConfig)).pipe(filterByType(PaymentType.Adyen));
  }

  private get _lyfPayConfig(): Observable<LyfPayConfig> {
    return this._store.pipe(select(selectors.currentPaymentConfig)).pipe(filterByType(PaymentType.Lyf));
  }

  private get _tpaConfig(): Observable<TpaConfig> {
    return this._store.pipe(select(selectors.currentPaymentConfig)).pipe(filterByType(PaymentType.Tpa));
  }

  private get _yavinConfig(): Observable<YavinConfig> {
    return this._store.pipe(select(selectors.currentPaymentConfig)).pipe(filterByType(PaymentType.Yavin));
  }

  private get lyfPaymentConfigList(): Observable<LyfPayConfig[]> {
    return this._store
      .select(selectors.paymentConfigList)
      .pipe(
        map(paymentConfigList => paymentConfigList.filter(config => config.type === PaymentType.Lyf) as LyfPayConfig[]),
      );
  }
}
