import { Injectable } from '@angular/core';

import { DialogService } from '@bend/dialog';
import { CatalogReloadService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { ErrorCodes } from '@bend/shared-widgets/src/lib/types';

import { PopALaCarteOrderItem, PopMenuOrderItem } from '../../product-catalog/product-catalog';
import { prepareErrorForOutOfStock } from './error-handler.helper';
import { Observable } from 'rxjs';

@Injectable()
export class ErrorHandlerService {
  constructor(private _dialog: DialogService, private _catalogReload: CatalogReloadService) {}

  handleHttpError(error: any, orderItem?: PopALaCarteOrderItem | PopMenuOrderItem): Observable<void> {
    this._catalogReload.reloadCatalog();

    if (ErrorCodes.PosNoStock === error.errorCode) {
      return this._dialog.error(prepareErrorForOutOfStock(error, orderItem));
    }

    if (ErrorCodes.PosUnknownProducts === error.errorCode) {
      return this._dialog.error({ message: `CART.${ErrorCodes.PosUnknownProducts}` });
    }

    if (ErrorCodes.TemporarilyUnavailable === error.errorCode) {
      return this._dialog.error({ message: 'PRODUCT_CATALOG.TEMPORARILY_UNAVAILABLE_PRODUCT' });
    }

    if (ErrorCodes.OrderNotAvailable === error.errorCode) {
      return this._dialog.error({ message: `CART.${ErrorCodes.OrderNotAvailable}` });
    }

    return this._dialog.error({ message: ErrorCodes.GenericError });
  }
}
