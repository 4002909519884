import { Injectable } from '@angular/core';
import { combineLatest, iif, Observable, of, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { OrderService } from '@bend/store/src/lib/order';
import { OrderItemsService } from '@bend/store/src/lib/order-items';
import { OrderLocationsService } from '@bend/store/src/lib/order-locations';
import { OrderUsersService } from '@bend/store/src/lib/order-users';
import { OrderItem, OrderItemStatus, OrderItemType, OrderLocation, OrderUser } from '@bend/store-shared';

import { OrderStatusServices } from '../order-status/order-status.service';
import { OrderTypeService } from '../order-type/order-type.service';
import { OrderUserCurrentService } from '../order-user-current/order-user-current.service';

@Injectable()
export class OrderUserService {
  constructor(
    private _order: OrderService,
    private _orderUsers: OrderUsersService,
    private _orderLocations: OrderLocationsService,
    private _orderItems: OrderItemsService,
    private _settings: SettingsService,
    private _orderStatus: OrderStatusServices,
    private _orderUserCurrent: OrderUserCurrentService,
    private _orderType: OrderTypeService,
  ) {}

  get hasNewButton(): Observable<boolean> {
    return of(false);
  }

  get allowCreateNewOrder(): Observable<boolean> {
    const allowStatus = new Set<OrderItemStatus>([
      OrderItemStatus.Preparing,
      OrderItemStatus.ToBePrepared,
      OrderItemStatus.Paid,
      OrderItemStatus.PickUpReady,
      OrderItemStatus.Handed,
      OrderItemStatus.InDelivery,
      OrderItemStatus.ScheduledForPreparing,
      OrderItemStatus.NextForPreparing,
    ]);

    return this._orderStatus.orderStatusChanged.pipe(
      map(({ status }) => allowStatus.has(status)),
      withLatestFrom(this._settings.allowCreateNewSession),
      /**
       * check
       */
      map(([isInProgress, allowCreateNewSession]) => isInProgress && allowCreateNewSession),
      /**
       * check if user have NextForPreparing items
       * because NextForPreparing have small priority that preparing
       * and we don't show new button when user have NextForPreparing items
       */
      switchMap(isAllow =>
        isAllow
          ? this._settings.showOrderHistory.pipe(
              withLatestFrom(this._order.id, this._order.currentOrderUserId),
              switchMap(([showOrderHistory, orderId, sessionUserId]) =>
                showOrderHistory
                  ? /**
                     * if order history is disabled we should check items next for preparing
                     */
                    of(true)
                  : /**
                     * check length of NextForPreparing items
                     */
                    this.itemsNextForPreparing(orderId, sessionUserId).pipe(
                      /**
                       * if user doesn't have items and is not order history we return true
                       */
                      map(items => !items.length),
                    ),
              ),
            )
          : of(false),
      ),
    );
  }

  get hasButtons(): Observable<boolean> {
    return combineLatest([
      /**
       * @description check if has unpaid item
       */
      this.unpaidItems.pipe(
        map(items => !!items.length),
        /**
         * check is pay after and is only by waiter
         */
        switchMap(hasUnpaid =>
          iif(
            () => hasUnpaid,
            this._orderStatus.orderStatusChanged.pipe(
              switchMap(({ status }) =>
                iif(
                  /**
                   * if status is ordered or empty check allow
                   */
                  () => status === OrderItemStatus.Ordered || status === OrderItemStatus.Empty,
                  /**
                   * if cash payment only is false show pay button
                   */
                  this._settings.cashPaymentOnly.pipe(map(cashPaymentOnly => !cashPaymentOnly)),
                  of(true),
                ),
              ),
            ),
            of(false),
          ),
        ),
      ),
      /**
       * @description check if order is in readonly mode
       */
      this._orderType.isReadOnly,
    ]).pipe(
      map(([hasUnpaid, isReadOnly]) => hasUnpaid && !isReadOnly),
      distinctUntilChanged(),
    );
  }

  /**
   * @description get unpaid items by current user
   */
  get unpaidItemsUser(): Observable<OrderItem[]> {
    return this._order.currentOrderUserId.pipe(
      switchMap(userId =>
        this.unpaidItems.pipe(map(items => items.filter(({ orderUserId }) => orderUserId === userId))),
      ),
    );
  }

  get unpaidItems(): Observable<OrderItem[]> {
    /**
     * allow items for send to payment
     */
    const allowItems = new Set<OrderItemStatus>([
      OrderItemStatus.New,
      OrderItemStatus.Reimbursed,
      OrderItemStatus.OrderNotSent,
      OrderItemStatus.Ordered,
      OrderItemStatus.PaymentNotSent,
      OrderItemStatus.FailedPreCheck,
      OrderItemStatus.NoStock,
      OrderItemStatus.UnknownProduct,
    ]);

    return this._order.id.pipe(
      switchMap(orderId => this._orderItems.byOrderId(orderId)),
      map(items => items.filter(({ status }) => allowItems.has(status))),
    );
  }

  get isShowMe(): Observable<boolean> {
    return this._order.currentOrderUserId.pipe(switchMap(userId => this._isShowUser(userId)));
  }

  // when there are many kitchen shows my name if there are other users
  // if there are not many kitchen shows my name all the time
  get isShowMyName(): Observable<boolean> {
    return combineLatest([this.isMultiKitchen, this._order.id, this._order.currentOrderUserId]).pipe(
      switchMap(([multiKitchen, orderId, sessionUserId]) =>
        iif(
          () => multiKitchen,
          // is a multi kitchen, show current user if exist multiple users
          this.orderUserSessionsOthers(orderId, sessionUserId).pipe(this._isHaveItems()),
          // is not a multi kitchen, show always username
          of(true),
        ),
      ),
    );
  }

  // show me icon when there are more users at the table
  get isShowMeIcon(): Observable<boolean> {
    // displays the icon when sharing users exist in card
    return this.shares.pipe(map(orderUsers => !!orderUsers.length));
  }

  // displays my total when there are more users in the order
  get isShowMyTotal(): Observable<boolean> {
    return combineLatest([this._order.id, this._order.currentOrderUserId]).pipe(
      switchMap(([orderId, sessionUserId]) => this._orderUsers.allWithoutCurrent(orderId, sessionUserId)),
      switchMap(userSessions =>
        combineLatest(userSessions.map(userSession => this._isShowUser(userSession.id))).pipe(
          // remove orderVisitorSession with empty locations
          map(locations => userSessions.filter((_, i: number) => locations[i])),
        ),
      ),
      this._isHaveItems(),
    );
  }

  /**
   * other user from current user
   */
  orderUserSessionsOthers(orderId: number, sessionUserId: number): Observable<OrderUser[]> {
    return this._orderUsers.others(orderId, sessionUserId).pipe(
      switchMap(userSessions =>
        iif(
          () => !userSessions.length,
          of([]),
          // get locations for all orderVisitorSession
          combineLatest(userSessions.map(userSession => this._isShowUser(userSession.id))).pipe(
            // remove orderVisitorSession with empty locations
            map(locations => userSessions.filter((_, i: number) => locations[i])),
          ),
        ),
      ),
    );
  }

  /**
   * shared user
   */
  get shares(): Observable<OrderUser[]> {
    return this._orderUserCurrent.shares.pipe(
      switchMap(users =>
        // get locations for all orderVisitorSession
        combineLatest(users.map(user => this._isShowUser(user.id))).pipe(
          // remove orderVisitorSession with empty locations
          map(locations => users.filter((_, i: number) => locations[i])),
        ),
      ),
    );
  }

  get isShowStatusAfterUserName(): Observable<boolean> {
    return this.isMultiKitchen.pipe(map(multiKitchen => !multiKitchen));
  }

  get isMultiKitchen(): Observable<boolean> {
    return this._settings.widgetCart.pipe(map(cartSettings => cartSettings.multiKitchen));
  }

  get promoCode(): Observable<OrderItem<OrderItemType.Promo> | undefined> {
    return this._order.currentOrderUserId.pipe(switchMap(userId => this._orderItems.promoCode(userId)));
  }

  locations(orderUserId: number): Observable<OrderLocation[]> {
    return this._locations(orderUserId);
  }

  itemsByLocation(orderUserId: number, locationId: number): Observable<OrderItem[]> {
    return this.itemsInCart(orderUserId).pipe(map(items => items.filter(item => item.orderLocationId === locationId)));
  }

  singleItemsByLocation(orderUserId: number, locationId: number): Observable<OrderItem<OrderItemType.Single>[]> {
    return this.itemsByLocation(orderUserId, locationId).pipe(
      /**
       * filter items by Single
       */
      map(itemsByLocations => itemsByLocations.filter(this._isSingleItem)),
    );
  }

  /**
   * only items that are displayed in the main cart
   */
  itemsInCart(orderUserId: number): Observable<OrderItem[]> {
    const notAllowItemsStatus: Set<OrderItemStatus> = new Set([OrderItemStatus.NextForPreparing]);

    return this._orderItems
      .userSingleItems(orderUserId)
      .pipe(map(items => items.filter(({ status }) => !notAllowItemsStatus.has(status))));
  }

  /**
   * next for preparing items for current user and for other users
   */
  itemsNextForPreparing(orderId: number, sessionUserId: number): Observable<OrderItem[]> {
    return this.items(orderId, sessionUserId).pipe(
      map(items => items.filter(({ status }) => status === OrderItemStatus.NextForPreparing)),
    );
  }

  singleItemsNextForPreparing(orderId: number, sessionUserId: number): Observable<OrderItem<OrderItemType.Single>[]> {
    return this.itemsNextForPreparing(orderId, sessionUserId).pipe(
      /**
       * filter items by Single
       */
      map(itemsByLocations => itemsByLocations.filter(this._isSingleItem)),
    );
  }

  /**
   * items for current user and for other users
   */
  items(orderId: number, sessionUserId: number): Observable<OrderItem[]> {
    return this.orderUserSessionsOthers(orderId, sessionUserId).pipe(
      map(users => users.map(({ id }) => id)),
      switchMap(otherUsersIds =>
        combineLatest([sessionUserId, ...otherUsersIds].map(userId => this._orderItems.userItems(userId))),
      ),

      map(items => items.flat()),
    );
  }

  get number(): Observable<string> {
    return this._settings.widgetCart.pipe(
      switchMap(({ orderIdPrefix }) => this._order.orderDisplayId.pipe(map(orderId => `${orderIdPrefix}${orderId}`))),
    );
  }

  private _isShowUser(orderUserId: number): Observable<boolean> {
    return this._locations(orderUserId).pipe(this._isHaveItems());
  }

  private _locations(orderUserId: number): Observable<OrderLocation[]> {
    return this._orderLocations.bySessionVisitorId(orderUserId).pipe(
      switchMap(locations =>
        iif(
          () => !!locations.length,
          // get locations is showing
          combineLatest(locations.map(orderLocation => this._isShowLocation(orderUserId, orderLocation.id))).pipe(
            // remove locations with empty items
            map(isShowLocations => locations.filter((_, i) => isShowLocations[i])),
          ),
          of([]),
        ),
      ),
    );
  }

  private _isShowLocation(orderUserId: number, orderLocationId: number): Observable<boolean> {
    return this._orderItems.byLocation(orderUserId, orderLocationId).pipe(
      map(items => items.filter(({ status }) => status !== OrderItemStatus.NextForPreparing)),
      this._isHaveItems(),
    );
  }

  private _isHaveItems<T>(): OperatorFunction<T[], boolean> {
    return map((items: T[]) => !!items.length);
  }

  private _isSingleItem(orderItem: OrderItem<OrderItemType>): orderItem is OrderItem<OrderItemType.Single> {
    return orderItem.type === OrderItemType.Single;
  }
}
