import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Catalog } from '../shared';
import { actions } from './catalog.actions';
import * as selectors from './catalog.selectors';
import { State } from './catalog.type';

@Injectable({ providedIn: 'root' })
export class CatalogService {
  constructor(private _store: Store<State>) {}

  get catalog(): Observable<Partial<Catalog>> {
    return this._store.pipe(select(selectors.catalog));
  }

  get isLoading(): Observable<boolean> {
    return this._store.pipe(select(selectors.isLoading));
  }

  getCatalog(catalogId: number): void {
    this._store.dispatch(actions.getCatalog({ by: catalogId }));
  }
}
