import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { InputGeneric } from '../../../helpers';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent extends InputGeneric implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() placeHolder = '';
  @Input() name = '';
  @Input() showError = false;
  @ViewChild('input') input: ElementRef;
  isDisabled: boolean;

  control: UntypedFormControl;

  private _value: string;
  private _checkValidation: boolean;
  private _subscription: Subscription;

  onChange: (val: string) => void = () => {};
  onTouched: (val: string) => void = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, private _cdr: ChangeDetectorRef) {
    super();

    if (this.ngControl) this.ngControl.valueAccessor = this;

    this.control = new UntypedFormControl();
    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    this._subscription.add(this.control.valueChanges.subscribe(value => this._setValue(value)));

    if (this.isExistControl) {
      this._subscription.add(this.ngControl.control.valueChanges.subscribe(() => this._cdr.detectChanges()));
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  enable(): void {
    if (this.isExistControl) this.ngControl.control.enable();
  }

  writeValue(value: string): void {
    this._value = value;
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (val: string) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.notExistControl) return;

    // to validate an input, it must be removed from disabled.
    // When it becomes disabled comes a false event  and you have to skip this event
    if (!isDisabled && this._checkValidation) return;

    isDisabled ? this._disableState() : this._enableState();

    this._cdr.detectChanges();
  }

  private _setValue(value: string): void {
    if (value === undefined || this._value === value) return;

    this._value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  private _enableState(): void {
    this.control.enable({ emitEvent: false });
    if (this.isDisabled) {
      this.input.nativeElement.focus();
    }

    this.isDisabled = false;
  }

  private _disableState(): void {
    if (this._checkValidation) {
      this._checkValidation = false;
      return;
    }

    this._checkValidation = true;
    this.ngControl.control.enable({ emitEvent: false });

    const { invalid } = this.ngControl.control;

    if (invalid) {
      this.ngControl.control.reset();
    } else {
      this.ngControl.control.disable({ emitEvent: false });
      this.control.disable({ emitEvent: false });

      this.isDisabled = true;
    }
  }
}
