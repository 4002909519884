export interface Place {
  id: number;
  name: string;
  posSku: string;
  applicationId: number;
  status: string;
  orders: any[];
  defaultSku: string;
  slug?: string;
  isCalled?: boolean;
  areaId?: number;
  deliveryFee: number;
  isForDelivery: boolean;
  address: DeliveryAddress;
}

export class DeliveryAddress {
  name: string;
  addressName: string;
  zipCode: string;
  city: string;
  additionalDetails: string;
  constructor() {
    this.name = '';
    this.addressName = '';
    this.zipCode = '';
    this.city = '';
    this.additionalDetails = '';
  }
}
