import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderLocation } from '../shared';
import * as selectors from './order-locations.selectors';
import { State } from './order-locations.type';

@Injectable({ providedIn: 'root' })
export class OrderLocationsService {
  constructor(private _store: Store<State>) {}

  get all(): Observable<OrderLocation[]> {
    return this._store.pipe(select(selectors.all));
  }

  bySessionVisitorId(orderUserId: number): Observable<OrderLocation[]> {
    return this.all.pipe(
      map(orderLocations => orderLocations.filter(orderLocation => orderLocation.orderUserId === orderUserId)),
    );
  }

  byId(orderLocationId: number, orderUserId: number): Observable<OrderLocation> {
    return this._store.pipe(
      select(selectors.entities),
      map(orderLocations => orderLocations[`${orderLocationId}-${orderUserId}`]),
    );
  }
}
