import {
  PopALaCarteOrderItem,
  PopCartOption,
  PopMenuOrderItem,
  PopOrderMenuItem,
} from '../../product-catalog/product-catalog';
import { ErrorCodes } from '@bend/shared-widgets/src/lib/types';

export function prepareErrorForOutOfStock(
  { data }: { data: { ids: number[] } },
  orderItem?: PopALaCarteOrderItem | PopMenuOrderItem,
) {
  const message = `CART.${ErrorCodes.PosNoStock}`;

  const productName = orderItem && data?.ids.length && extractProductNamesByIds(data?.ids, orderItem).join('\n').trim();
  if (!productName) {
    console.error('Failed to extract product name from error');
  }

  return { message, interpolateData: { productName: productName || 'UNKNOWN PRODUCT' } };
}

function extractProductNamesByIds(
  ids: number[],
  orderItem: PopALaCarteOrderItem | PopMenuOrderItem | PopCartOption | PopOrderMenuItem,
): string[] {
  const result: string[] = [];

  if (ids.includes(orderItem.id)) {
    result.push(orderItem.name);
  }
  if ('options' in orderItem) {
    orderItem.options.forEach(option => {
      result.push(...extractProductNamesByIds(ids, option));
    });
  }
  if ('items' in orderItem) {
    orderItem.items.forEach(item => {
      result.push(...extractProductNamesByIds(ids, item));
    });
  }

  return result;
}
