import { createAction, props } from '@ngrx/store';

import { createType, Ticket } from '../shared';
import { storeKey } from './tickets.config';
import { GetTicketsSuccess } from './tickets.type';

const type = createType(storeKey);

export const actions = {
  getTickets: createAction(type('get tickets'), props<{ userId: number; limit: number; offset: number }>()),
  getTicketsSuccess: createAction(type('get tickets success'), props<GetTicketsSuccess>()),
  getTicketsError: createAction(type('get tickets error')),

  getTicket: createAction(type('get ticket'), props<{ id: number }>()),
  getTicketSuccess: createAction(type('get ticket success'), props<Ticket>()),
  getTicketError: createAction(type('get ticket error')),

  editTicket: createAction(type('edit tickets'), props<{ id: number; ticket: Partial<Ticket> }>()),
  editTicketSuccess: createAction(type('edit tickets success'), props<{ ticket: Ticket }>()),
  editTicketError: createAction(type('edit tickets error')),
};
