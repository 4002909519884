import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './option-group.config';
import { adapter } from './option-group.reducer';
import { State } from './option-group.type';

const stateSelector = createFeatureSelector<State>(storeKey);
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);

export const error = createSelector(stateSelector, state => state.errorCode);
export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const selectedProductId = createSelector(stateSelector, state => state.selectedProductId);

export const idsByProduct = createSelector(
  stateSelector,
  selectedProductId,
  (state, productId) => state.loadedGroupIds[productId] || [],
);
export const isLoaded = createSelector(idsByProduct, groupIds => !!groupIds);
