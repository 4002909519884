import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { OptionGroup,sortByKey } from '../shared';
import { actions } from './option-group.actions';
import { State } from './option-group.type';

export const adapter: EntityAdapter<OptionGroup> = createEntityAdapter<OptionGroup>({
  sortComparer: sortByKey<OptionGroup>()('order'),
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  selectedProductId: null,
  loadedGroupIds: {},
  errorCode: null,
});

const optionGroupReducer = createReducer(
  initialState,
  on(actions.getOptionGroups, (state, { productId }) => ({
    ...state,
    isLoading: true,
    selectedProductId: productId,
  })),
  on(actions.addOptionGroups, (state, { entities }) => adapter.addMany(entities, state)),
  on(actions.getOptionGroupsSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, {
      ...state,
      isLoading: false,
      loadedGroupIds: { ...state.loadedGroupIds, [state.selectedProductId]: entities.map(({ id }) => id) },
    }),
  ),

  on(actions.getOptionGroupsIsLoaded, state => ({
    ...state,
    isLoading: false,
  })),

  on(actions.getOptionGroupsError, (state, { errorCode }) => ({
    ...state,
    errorCode,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return optionGroupReducer(state, action);
}
