import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { actions as optionsActions } from '../option/option.actions'; // TODO: review
import { Option, OptionGroup } from '../shared';
import { actions as optionGroupsActions } from './option-group.actions';
import { OptionGroupHttpService } from './option-group.http.service';
import * as selectors from './option-group.selectors';
import { State } from './option-group.type';

@Injectable()
export class OptionGroupEffects {
  loadOptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(optionGroupsActions.getOptionGroups),
      withLatestFrom(this._store.select(selectors.idsByProduct)),
      mergeMap(([{ productId }, idsByProduct]) =>
        idsByProduct.length
          ? of(optionGroupsActions.getOptionGroupsIsLoaded())
          : this._http.getOptionGroups(productId).pipe(
              mergeMap(groups => [
                optionsActions.addOptions({ entities: this._getOptions(groups) }),
                optionGroupsActions.getOptionGroupsSuccess({ entities: groups }),
              ]),
              catchError(({ errorCode }) => of(optionGroupsActions.getOptionGroupsError({ errorCode }))),
            ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _store: Store<State>, private _http: OptionGroupHttpService) {}

  private _getOptions(groups: OptionGroup[]): Option[] {
    return groups.flatMap(({ options }) =>
      options.map(({ isDefault, quantityMultiplier, ...rest }) => ({
        ...rest,
        isDefault,
        quantityMultiplier,
        quantity: isDefault ? quantityMultiplier : 0,
      })),
    );
  }
}
