<div [class]="'subcategory-header' + ' ' + styleType">
  <h3
    class="subcategory-header__name"
    [style.backgroundColor]="styleType ? secondColor : null"
    [style.color]="styleType ? mainColor : null"
  >
    <div class="header">
      <span>{{ subcategoryName }}</span>
      <span class="limits">{{ limitMessage }}</span>
    </div>

    <div *ngIf="subcategoryDescription && styleType; else elseDesc" class="subcategory-description">
      {{ subcategoryDescription }}
    </div>
  </h3>
  <ng-template #elseDesc>
    <div *ngIf="subcategoryDescription" class="subcategory-description">{{ subcategoryDescription }}</div>
  </ng-template>
</div>
