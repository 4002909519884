<div
  class="product-item"
  [class.product-item--align-end]="!product.imageUrl"
  [class.product-item--selected]="product.isSelected"
  [style.border-color]="selectedColor | async"
>
  <div class="product-item__image" *ngIf="product.imageUrl" (click)="click(true, true)">
    <img [src]="product.imageUrl" alt="product photo" height="100" />
  </div>
  <div class="product-item__content">
    <div class="product-item__main-details" (click)="click(true, true)">
      <p class="product__title">{{ product.name }}</p>
      <p class="product__description" *ngIf="product.description">{{ product.description }}</p>
      <div *ngIf="product.itemTags?.length" class="product__tags">
        <widgets-old-tag-list [tags]="product.itemTags"></widgets-old-tag-list>
      </div>
    </div>
    <div class="product-item__price">
      <div class="price" (click)="click(true, true)" data-cy="productItemPrice">
        <span *ngIf="product.priceInitialValue; else elsePrice" class="product-item__initial-price price__board">
          {{ product.priceFinalValue | currency }}
          <span>
            {{ product.priceInitialValue | currency }}
          </span>
        </span>
        <ng-template #elsePrice>
          <span *ngIf="product.priceFinalValue" class="price__board">{{ product.priceFinalValue | currency }}</span>
        </ng-template>
      </div>
      <div>
        <ng-container *ngIf="!isConsultingMode && !isReadOnly && !product.isReadOnly">
          <app-product-status-checkout [product]="product" [itemUnavailabilityDisplay]="itemUnavailabilityDisplay">
            <button
              *ngIf="!isMenu && loadingProductId !== product.id"
              class="add-to-cart__btn"
              data-cy="productBtn"
              (click)="click(false)"
              [style.color]="secondColor$ | async"
              [style.background-color]="primaryColor$ | async"
            >
              {{ 'PRODUCT_CATALOG.ADD_TO_CART' | translate }}
            </button>
            <button *ngIf="isMenu" class="product-item__btn" (click)="click(!product.isSelected, false)">
              <lib-icon
                *ngIf="product.isSelected; else notSelected"
                class="menu-check menu-check--selected"
                icon="addedSquare"
                [style.fill]="primaryColor$ | async"
              ></lib-icon>
              <ng-template #notSelected>
                <lib-icon class="menu-check" icon="add" [style.fill]="primaryColor$ | async"></lib-icon>
              </ng-template>
            </button>
          </app-product-status-checkout>
        </ng-container>
        <pop-widget-spinner
          *ngIf="loadingProductId === product.id && !isReadOnly"
          class="add-to-cart__loader"
          [width]="25"
          [height]="25"
          [color]="secondColor"
        ></pop-widget-spinner>
      </div>
    </div>
  </div>
</div>
