import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderMenuItem } from '../shared';
import * as selectors from './order-menu-items.selectors';
import { State } from './order-menu-items.type';

@Injectable({ providedIn: 'root' })
export class OrderMenuItemsService {
  constructor(private _store: Store<State>) {}

  byOrderItemId(orderItemId: number, orderUserId: number): Observable<OrderMenuItem[]> {
    return this._store.pipe(
      select(selectors.all),
      map(orderMenuItems =>
        orderMenuItems.filter(
          orderMenuItem => orderMenuItem.orderItemId === orderItemId && orderMenuItem.orderUserId === orderUserId,
        ),
      ),
    );
  }
}
