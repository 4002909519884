import { Injectable } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

import { actions } from './resolver.actions';
import * as selectors from './resolver.selectors';
import { State } from './resolver.type';

@Injectable()
export class ResolverService {
  constructor(private _store: Store<State>, private _router: Router) {
    this._router.events
      .pipe(switchMap(event => this._preventInterruptedLoading(event)))
      .subscribe(event => this._manageTheLoading(event));
  }

  get isLoading(): Observable<boolean> {
    return this._store.pipe(select(selectors.isLoading));
  }

  private _preventInterruptedLoading(event: Event): Observable<Event> {
    return iif(() => event.constructor === NavigationCancel, of(event).pipe(delay(10)), of(event));
  }

  private _manageTheLoading(event: Event): void {
    switch (event.constructor) {
      case NavigationStart:
        this._startLoading();
        break;
      case NavigationCancel:
      case NavigationEnd:
        this._stopLoading();
        break;
    }
  }

  private _startLoading(): void {
    this._store.dispatch(actions.setLoading({ loading: true }));
  }

  private _stopLoading(): void {
    this._store.dispatch(actions.setLoading({ loading: false }));
  }
}
