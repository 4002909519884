import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { StorageService } from '@bend/storage';

@Component({
  selector: 'pop-widget-person-name',
  templateUrl: './person-name.component.html',
  styleUrls: ['./person-name.component.scss'],
})
export class NameInputComponent implements OnInit, OnDestroy {
  @Input() nameControl: UntypedFormControl;
  @Input() me: any;
  @Input() mainColor: string;
  @Input() editable = true;
  icon: string;
  isCompleted: boolean;
  private _subscription: Subscription;

  constructor(private _localStorage: StorageService) {
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this._initName();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  editInput(): void {
    this.isCompleted = false;
    this.nameControl.enable();
  }

  private _initName(): void {
    const name = this._localStorage.userName || this.me.name;

    if (name) {
      this.nameControl.setValue(name);
      if (this.editable) {
        this.isCompleted = true;
        this.nameControl.disable();
      }
    }
  }
}
