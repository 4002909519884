import { Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetDocument, WidgetDocuments } from './document';

@Component({
  selector: 'pop-widget-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class WidgetDocumentsComponent implements OnInit, WidgetComponent {
  static widgetName = 'documents';
  @Input() attributes: WidgetDocuments;

  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.documents || !this.attributes.documents.length) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetDocumentsComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this.isValid = true;
  }

  getDocumentFooter(document: WidgetDocument): string {
    const fileSize = this.formatFileSize(document.size);
    const footer = fileSize ? `${document.type} - ${fileSize}` : document.type;
    return footer;
  }

  formatFileSize(value: number): string {
    if (!value || isNaN(value) || !isFinite(value)) {
      return '';
    }
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    const number = Math.floor(Math.log(value) / Math.log(1024));

    return ` ${(value / Math.pow(1024, Math.floor(number))).toFixed(0)}   ${units[number]}`;
  }
}
