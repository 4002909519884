import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Version } from '../types';
import { ApiConfService } from './intern-api-conf.service';
import { ApiCreditService } from './intern-api-credit.service';
import { ApiAppService } from './intern-api-designer.service';
import { ApiIlluminatiInternService } from './intern-api-illuminati.service';
import { ApiImageService } from './intern-api-image.service';
import { ApiIosService } from './intern-api-ios.service';
import { ApiNotificationsService } from './intern-api-notifications.service';
import { ApiPosInternService } from './intern-api-pos.service';
import { ApiShopService } from './intern-api-shop.service';

@Injectable({ providedIn: 'root' })
export class ApiDesignerService {
  // app apis
  activeAdmins = (version: Version): Observable<string> => this._apiApp.activeAdmins(version);
  admins = (version: Version, id?: string): Observable<string> => this._apiApp.admins(version, id);
  exportCatalog = (version: Version): Observable<string> => this._apiApp.exportCatalog(version);
  widgetStore = (version: Version): Observable<string> => this._apiApp.widgetStore(version);
  widgetStoreType = (version: Version, type: string): Observable<string> => this._apiApp.widgetStoreType(version, type);
  carousels = (version: Version): Observable<string> => this._apiApp.carousels(version);
  links = (version: Version): Observable<string> => this._apiApp.links(version);
  reports = (version: Version): Observable<string> => this._apiApp.reports(version);
  report = (version: Version, reportId: string): Observable<string> => this._apiApp.report(version, reportId);
  adminReports = (version: Version): Observable<string> => this._apiApp.adminReports(version);
  adminReport = (version: Version, reportId: string): Observable<string> => this._apiApp.adminReport(version, reportId);
  duplicatePage = (version: Version, pageId: string): Observable<string> => this._apiApp.duplicatePage(version, pageId);
  pages = (version: Version): Observable<string> => this._apiApp.pages(version);
  page = (version: Version, pageId: string): Observable<string> => this._apiApp.page(version, pageId);
  catalogsByPageId = (version: Version, pageId: string): Observable<string> =>
    this._apiApp.catalogsByPageId(version, pageId);

  pageWidgets = (version: Version, pageId: string): Observable<string> => this._apiApp.pageWidgets(version, pageId);
  pageWidget = (version: Version, pageId: string, widgetId: string): Observable<string> =>
    this._apiApp.pageWidget(version, pageId, widgetId);

  widgetsOrder = (version: Version, pageId: string): Observable<string> => this._apiApp.widgetsOrder(version, pageId);
  hashify = (version: Version): Observable<string> => this._apiApp.hashify(version);
  analyticsPages = (version: Version): Observable<string> => this._apiApp.analyticsPages(version);
  analyticsPage = (version: Version, pageId: string): Observable<string> => this._apiApp.analyticsPage(version, pageId);
  pollResultsInRange = (version: Version, pageId: string, widgetId: string): Observable<string> =>
    this._apiApp.pollResultsInRange(version, pageId, widgetId);

  exportPollResults = (version: Version, pageId: string, widgetId: string): Observable<string> =>
    this._apiApp.exportPollResults(version, pageId, widgetId);

  logout = (version: Version): Observable<string> => this._apiApp.logout(version);
  changePassword = (version: Version): Observable<string> => this._apiApp.changePassword(version);
  refreshToken = (version: Version): Observable<string> => this._apiApp.refreshToken(version);
  appTypes = (version: Version): Observable<string> => this._apiApp.appTypes(version);
  apps = (version: Version): Observable<string> => this._apiApp.apps(version);
  appsBatch = (version: Version): Observable<string> => this._apiConf.appsBatch(version);
  app = (version: Version): Observable<string> => this._apiApp.application(version);
  carousel = (version: Version, carouselId: string): Observable<string> => this._apiApp.carousel(version, carouselId);
  login = (version: Version): Observable<string> => this._apiApp.loginDesigner(version);
  versions = (version: Version): Observable<string> => this._apiApp.versions(version);
  upload = (version: Version): Observable<string> => this._apiApp.upload(version);

  // conf apis
  appSettings = (version: Version): Observable<string> => this._apiConf.appSettings(version);
  locationSettings = (version: Version, locationId: number): Observable<string> =>
    this._apiConf.locationSettings(version, locationId);

  locations = (version: Version): Observable<string> => this._apiConf.locations(version);
  areaSettings = (version: Version, areaId: number): Observable<string> => this._apiConf.areaSettings(version, areaId);
  allAreas = (version: Version): Observable<string> => this._apiConf.allAreas(version);
  posSettings = (version: Version): Observable<string> => this._apiConf.posSettings(version);
  posSetting = (version: Version, settingsId: number): Observable<string> =>
    this._apiConf.posSetting(version, settingsId);

  posConnection = (version: Version, posSettingId: number): Observable<string> =>
    this._apiConf.posConnect(version, posSettingId);

  PosPaymentMethods = (version: Version, posSettingId: number): Observable<string> =>
    this._apiConf.posPaymentMethods(version, posSettingId);

  posLocations = (version: Version, posSettingId: number): Observable<string> =>
    this._apiConf.posLocations(version, posSettingId);

  sms = (version: Version): Observable<string> => this._apiConf.sms(version);
  paymentSettings = (version: Version): Observable<string> => this._apiConf.paymentSettings(version);
  paymentSetting = (version: Version, settingsId: number): Observable<string> =>
    this._apiConf.paymentSetting(version, settingsId);

  paymentConnect = (version: Version, paymentId: number): Observable<string> =>
    this._apiConf.paymentConnect(version, paymentId);

  currentIp = (version: Version): Observable<string> => this._apiConf.currentIp(version);
  promoCodes = (version: Version): Observable<string> => this._apiConf.promoCodes(version);
  promoCode = (version: Version, promoCodeId: number): Observable<string> =>
    this._apiConf.promoCode(version, promoCodeId);

  promoCodesLight = (version: Version): Observable<string> => this._apiConf.promoCodesLight(version);

  // shop apis
  areas = (version: Version): Observable<string> => this._apiShop.areas(version);
  area = (version: Version, areaId: number): Observable<string> => this._apiShop.area(version, areaId);
  catalogs = (version: Version): Observable<string> => this._apiShop.catalogs(version);
  catalog = (version: Version, catalogId: number): Observable<string> => this._apiShop.catalog(version, catalogId);
  availableItems = (version: Version, catalogId: number): Observable<string> =>
    this._apiShop.availableItems(version, catalogId);

  catalogsExport = (version: Version): Observable<string> => this._apiShop.catalogsExport(version);

  duplicateCatalog = (version: Version): Observable<string> => this._apiShop.duplicateCatalog(version);
  items = (version: Version): Observable<string> => this._apiShop.items(version);
  itemsForMenus = (version: Version): Observable<string> => this._apiShop.itemsForMenus(version);
  item = (version: Version, itemId: string): Observable<string> => this._apiShop.item(version, itemId);
  itemsReorder = (version: Version): Observable<string> => this._apiShop.itemsReorder(version);
  itemsReorderChildren = (version: Version): Observable<string> => this._apiShop.itemsReorderChildren(version);
  optionGroupSubitems = (version: Version, optionGroupSubitemId: number): Observable<string> =>
    this._apiShop.optionGroupSubitems(version, optionGroupSubitemId);

  optionGroups = (version: Version): Observable<string> => this._apiShop.optionGroups(version);
  optionGroup = (version: Version, optionGroupId: number): Observable<string> =>
    this._apiShop.optionGroup(version, optionGroupId);

  optionGroupsPos = (version: Version, posId: string): Observable<string> =>
    this._apiShop.optionGroupsPos(version, posId);

  recommendationGroups = (version: Version): Observable<string> => this._apiShop.recommendationGroups(version);
  recommendationGroup = (version: Version, id: number): Observable<string> =>
    this._apiShop.recommendationGroup(version, id);

  catalogsPopulated = (version: Version): Observable<string> => this._apiShop.catalogsPopulated(version);
  productionReadiness = (version: Version): Observable<string> => this._apiShop.productionReadiness(version);
  tags = (version: Version): Observable<string> => this._apiShop.tags(version);
  tag = (version: Version, id: number): Observable<string> => this._apiShop.tag(version, id);
  transactions = (version: Version): Observable<string> => this._apiShop.transactions(version);
  transaction = (version: Version, id: number): Observable<string> => this._apiShop.transaction(version, id);
  tvas = (version: Version): Observable<string> => this._apiShop.tvas(version);
  tva = (version: Version, tvaId: string): Observable<string> => this._apiShop.tva(version, tvaId);
  workingHours = (version: Version): Observable<string> => this._apiShop.workingHours(version);
  posSyncApp = (version: Version): Observable<string> => this._apiShop.posSyncApp(version);

  posItemsWithOptionGroups = (version: Version, productId: string): Observable<string> =>
    this._apiShop.posItemsWithOptionGroups(version, productId);

  itemsSearch = (version: Version): Observable<string> => this._apiShop.itemsSearch(version);
  optionsSearch = (version: Version): Observable<string> => this._apiShop.optionsSearch(version);
  optionGroupsSearch = (version: Version): Observable<string> => this._apiShop.optionGroupsSearch(version);
  callWaiters = (version: Version): Observable<string> => this._apiShop.callWaiters(version);
  menus = (version: Version): Observable<string> => this._apiShop.menus(version);
  menuReorder = (version: Version): Observable<string> => this._apiShop.menuReorder(version);
  menu = (version: Version, menuId: number): Observable<string> => this._apiShop.menu(version, menuId);
  itemCategories = (version: Version): Observable<string> => this._apiShop.itemCategories(version);
  itemCategory = (version: Version, idCategory: number): Observable<string> =>
    this._apiShop.itemCategory(version, idCategory);

  itemCategoriesExtended = (version: Version): Observable<string> => this._apiShop.itemCategoriesExtended(version);
  itemCategoriesReorder = (version: Version): Observable<string> => this._apiShop.itemCategoriesReorder(version);
  placesLight = (version: Version): Observable<string> => this._apiShop.placesLight(version);
  businessLocations = (version: Version): Observable<string> => this._apiShop.businessLocations(version);
  businessLocation = (version: Version, businessLocationId: number): Observable<string> =>
    this._apiShop.businessLocation(version, businessLocationId);

  places = (version: Version): Observable<string> => this._apiShop.places(version);
  place = (version: Version, placeId: number): Observable<string> => this._apiShop.place(version, placeId);
  payOrdered = (version: Version, orderId: number): Observable<string> => this._apiShop.payOrdered(version, orderId);
  orderReceipt = (version: Version, orderId: number): Observable<string> =>
    this._apiShop.orderReceipt(version, orderId);

  orders = (version: Version): Observable<string> => this._apiShop.orders(version);
  orderCheck = (version: Version, orderId: number): Observable<string> => this._apiShop.orderCheck(version, orderId);
  order = (version: Version, orderId: number): Observable<string> => this._apiShop.order(version, orderId);
  exportOrders = (version: Version): Observable<string> => this._apiShop.exportOrders(version);
  agentViewPayment = (version: Version): Observable<string> => this._apiShop.agentViewPayment(version);
  orderSimplified = (version: Version, orderId: number): Observable<string> =>
    this._apiShop.orderSimplified(version, orderId);

  ordersSimplified = (version: Version): Observable<string> => this._apiShop.ordersSimplified(version);
  orderClose = (version: Version, orderId: number): Observable<string> => this._apiShop.orderClose(version, orderId);
  // this endpoint is related to catalogs import functionality
  catalogsImportsPos = (version: Version): Observable<string> => this._apiShop.catalogsImportsPos(version);
  catalogsImportsPosId = (version: Version, importId: string): Observable<string> =>
    this._apiShop.catalogsImportsPosId(version, importId);

  importProductsFromCsv = (version: Version): Observable<string> => this._apiShop.importProductsFromCsv(version);
  importProductsFromCsvId = (version: Version, importId: string): Observable<string> =>
    this._apiShop.importProductsFromCsvId(version, importId);

  applicationsPaymentConfig = (version: Version): Observable<string> =>
    this._apiShop.applicationsPaymentConfig(version);

  catalogCategories = (version: Version): Observable<string> => this._apiShop.catalogCategories(version);
  readySms = (version: Version, orderId: number): Observable<string> => this._apiShop.readySms(version, orderId);

  // illuminati apis
  orderActions = (version: Version): Observable<string> => this._apiIlluminatiIntern.actions(version);

  // credits apis
  users = (version: Version): Observable<string> => this._apiCreditService.users(version);
  userInfo = (version: Version, userId: string): Observable<string> => this._apiCreditService.userInfo(version, userId);
  userTransactions = (version: Version): Observable<string> => this._apiCreditService.transactions(version);
  addCredit = (version: Version, userId: string): Observable<string> => this._apiCreditService.add(version, userId);
  creditHistory = (version: Version): Observable<string> => this._apiCreditService.creditHistory(version);
  ticket = (version: Version): Observable<string> => this._apiCreditService.ticket(version);
  tickets = (version: Version): Observable<string> => this._apiCreditService.tickets(version);
  updateTicket = (version: Version, ticketId: string): Observable<string> =>
    this._apiCreditService.updateTicket(version, ticketId);

  // ios apis
  smiles = (version: Version): Observable<string> => this._apiIosService.smiles(version);
  smileHistory = (version: Version): Observable<string> => this._apiIosService.smileHistory(version);
  smilePlace = (version: Version): Observable<string> => this._apiIosService.smilePlace(version);
  editSmile = (version: Version, smileId: string): Observable<string> => this._apiIosService.edit(version, smileId);
  generateSmiles = (version: Version): Observable<string> => this._apiIosService.generate(version);
  exportSmiles = (version: Version): Observable<string> => this._apiIosService.export(version);
  updateSmilesPageId = (version: Version): Observable<string> => this._apiIosService.smilesPageId(version);

  // notifications api
  notifications = (version: Version): Observable<string> => this._apiNotification.settings(version);
  notification = (version: Version, notificationId: number): Observable<string> =>
    this._apiNotification.notification(version, notificationId);

  defaultSettings = (version: Version): Observable<string> => this._apiNotification.defaultSettings(version);
  notificationType = (version: Version): Observable<string> => this._apiNotification.notificationType(version);
  addNotification = (version: Version): Observable<string> => this._apiNotification.addNotification(version);
  deleteNotification = (version: Version, notificationId: number): Observable<string> =>
    this._apiNotification.notification(version, notificationId);

  // pos api
  posCatalogs = (version: Version, catalogId?: string): Observable<string> => this._apiPos.catalogs(version, catalogId);
  posCatalogSyncs = (version: Version, id?: string): Observable<string> => this._apiPos.catalogSyncs(version, id);
  ikentooCatalogs = (version: Version): Observable<string> => this._apiPos.ikentooCatalogs(version);
  zeltyCatalogs = (version: Version): Observable<string> => this._apiPos.zeltyCatalogs(version);
  cashpadCatalogs = (version: Version): Observable<string> => this._apiPos.cashpadCatalogs(version);

  //image api
  getImages = (version: Version, app: string): Observable<string> => this._apiImages.images(version, app);
  getApplications = (version: Version): Observable<string> => this._apiImages.applications(version);
  uploadImage = (version: Version): Observable<string> => this._apiImages.uploadImage(version);
  uploadByUrl = (version: Version): Observable<string> => this._apiImages.uploadImageByUrl(version);
  deleteImage = (id: number): Observable<string> => this._apiImages.deleteImage(id);

  // labels api
  applicationLabels = (version: Version): Observable<string> => this._apiConf.applicationLabels(version);
  locationLabels = (version: Version, id: number): Observable<string> => this._apiConf.locationLabels(version, id);
  areaLabels = (version: Version, id: number): Observable<string> => this._apiConf.areaLabels(version, id);

  // tokens api
  generateToken = (version: Version): Observable<string> => this._apiApp.token(version);
  getTokens = (version: Version): Observable<string> => this._apiApp.token(version);
  revokeToken = (version: Version, tokenId: string): Observable<string> => this._apiApp.revokeToken(version, tokenId);

  constructor(
    private _apiApp: ApiAppService,
    private _apiShop: ApiShopService,
    private _apiConf: ApiConfService,
    private _apiIlluminatiIntern: ApiIlluminatiInternService,
    private _apiCreditService: ApiCreditService,
    private _apiIosService: ApiIosService,
    private _apiNotification: ApiNotificationsService,
    private _apiPos: ApiPosInternService,
    private _apiImages: ApiImageService,
  ) {}
}
