import { Prices } from '@bend/store';

import { PosMode, PosType } from '@designer-fsd-shared/types/pos';

export interface ImportPosProvider {
  id: number;
  name: string;
  type: PosType;
  mode: PosMode;
  isValid: boolean;
}

export enum PosCatalogSyncStatus {
  FAILED = 'failed',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  SUCCEEDED = 'succeeded',
}

interface SimpleChanges {
  added: unknown[];
  updated: unknown[];
  removed: unknown[];
}

export interface PosCatalogSync {
  date: string;
  status: PosCatalogSyncStatus;
  changes: {
    categories: SimpleChanges;
    menuCategories: SimpleChanges;
    optionGroups: SimpleChanges;
    items: SimpleChanges;
    options: SimpleChanges;
    menus: SimpleChanges;
    tags: SimpleChanges;
  };
}

export interface SyncAllCatalog {
  changePrices?: boolean;
}

export type PosCategory = Omit<PosSubCategory, 'children'> & {
  children: Array<PosSubCategory | PosCategoryProductListItem>;
};

export interface PosSubCategory {
  id: string;
  name: string;
  children: PosCategoryProductListItem[];
  order: number;
}

export type PosCategoryProductListItem = Omit<PosCategoryProduct, 'children'> & {
  children: Array<Omit<PosCategorySubProduct, 'children'>>;
};

export type PosCategoryProduct = Omit<PosCategorySubProduct, 'children'> & {
  children: Array<PosCategorySubProduct | PosOptionGroup>;
};

// type Prices = Record<
//   'onSite' | 'takeAway' | 'delivery' | 'happyHours',
//   { price: number; tvaId: number | null } | null | undefined
// >;

export interface PosCategorySubProduct {
  id: string;
  name: string;
  prices: Prices;
  visible: boolean;
  menuOnly: boolean;
  order: number;
  children: PosOptionGroup[];
}

export interface PosCatalog {
  id: string;
  name?: string;
  posProviderId: number;
  posMenuId?: string;
  posType: PosType;
  lastSyncedAt: string;
}

export interface PosCatalogWithCategoriesAndMenus extends PosCatalog {
  categories: PosCategory[];
  menus: PosMenu[];
}

export interface PosProduct {
  id: string;
  sku: string;
  name: string;
  available: boolean;
  menuOnly: boolean;
  prices: Prices;
  children: PosOptionGroup[];
}

export interface PosOptionGroup {
  id: string;
  sku: string;
  name: string;
  min: number;
  max: number;
  multiple: boolean;
  children: PosOptionGroupSubitem[];
}

interface PosOptionGroupSubitem {
  id: string;
  sku: string;
  name: string;
  visible: boolean;
  priceFinalValue: number;
  order: number;
}

export interface GetPosImportResponse {
  finished: boolean;
  name?: string;
  applicationId?: number;
  elapsed?: number;
  error?: {
    name: string;
    className: string;
    code: number;
    data: any;
    errors: any;
  };
}

export interface PosMenu {
  id: string;
  name: string;
  prices: Prices;
  available: boolean;
  packageOnly: boolean;
  sku: string;
  order: number;
  children: PosMenuCategory[];
}

export interface PosMenuCategory {
  applicationId: number;
  id: string;
  catalogId: string;
  itemMenuId: string;
  sku: string;
  name: string;
  description: string;
  order: number;
  min: number;
  max: number;
  allowQuantity: boolean;
  allowMultiple: boolean;
  createdAt: Date;
  updatedAt: Date;
  children: PosMenuCategoryItem[];
}

export interface PosMenuCategoryItem {
  applicationId: number;
  id: string;
  catalogId: string;
  menuCategoryId: string;
  itemId: string;
  prices: Prices;
  order: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
}
