<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row-group">
      <label class="form-label form-label--radio-btns-group">{{ 'WIDGETS.PRODUCT.VIEW_MENU_ONLY' | translate }}:</label>
      <div class="form-radio-btns-group">
        <input
          id="isReadOnlyEnabled"
          type="radio"
          formControlName="isReadOnly"
          [value]="true"
          class="form-input-radio form-input-radio--group"
        />
        <label for="isReadOnlyEnabled" class="form-label form-label--radio form-label--no-font-weight">
          {{ 'WIDGETS.PRODUCT.YES' | translate }}
        </label>
        <input
          id="isReadOnlyDisabled"
          type="radio"
          formControlName="isReadOnly"
          [value]="false"
          class="form-input-radio form-input-radio--group"
        />
        <label for="isReadOnlyDisabled" class="form-label form-label--radio form-label--no-font-weight">
          {{ 'WIDGETS.PRODUCT.NO' | translate }}
        </label>
        <input
          id="isReadOnlyInherited"
          type="radio"
          formControlName="isReadOnly"
          class="form-input-radio form-input-radio--group"
          [value]="null"
        />
        <label for="isReadOnlyInherited" class="form-label form-label--radio form-label--no-font-weight">
          {{ 'WIDGETS.PRODUCT.SAME_AS_AREA' | translate }}
        </label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <input id="useProductFromUrl" type="checkbox" formControlName="useProductFromUrl" />
            <label for="useProductFromUrl" class="form-label form-label--checkbox">
              {{ 'WIDGETS.PRODUCT.PRODUCT_FROM_URL' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="!useProductFromUrl">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <label for="catalog" class="form-label">
              {{ 'WIDGETS.PRODUCT.CATALOGS' | translate }}
              <select
                id="catalog"
                formControlName="catalogId"
                (change)="selectCatalog()"
                class="form-input form-input--select"
              >
                <option value="" class="hidden"></option>
                <option *ngFor="let catalog of catalogs" [value]="catalog.id">{{ catalog.name }}</option>
              </select>
            </label>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="title" class="form-label">
              {{ 'WIDGETS.PRODUCT.PRODUCT' | translate }}
              <select id="catalog" formControlName="productId" class="form-input form-input--select">
                <option value="" class="hidden"></option>
                <option *ngFor="let product of products" [value]="product.id">{{ product.name }}</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="!useProductFromUrl">
      <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
      <designer-image-gallery formControlName="imageUrl"></designer-image-gallery>
      <span class="form-field-info">{{ 'WIDGETS.PRODUCT.PRODUCT_IMAGE' | translate }}</span>
    </div>
    <div class="form-row-groups-wrapper" formGroupName="style">
      <div class="form-row-groups form-row-groups--distanced" formGroupName="text">
        <div class="form-row-group form-row-group--distanced">
          <label class="form-label">{{ 'WIDGETS.STYLE.TEXT_MARGIN' | translate }}</label>
          <input type="number" formControlName="margin" min="0" class="form-input form-input--number" />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label class="form-label">{{ 'WIDGETS.STYLE.TEXT_VERTICAL' | translate }}</label>
          <select class="form-input form-input--select" formControlName="verticalAlign">
            <option *ngFor="let alignType of verticalAlignTypes" [value]="alignType">
              {{ alignType | titlecase }}
            </option>
          </select>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="textBackgroundColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.TEXT_BACKGROUND' | translate }}
          </label>
          <span
            id="textBackgroundColorInput"
            [(colorPicker)]="textBackgroundColorValue"
            [cpOutputFormat]="'rgba'"
            class="form-colorpicker"
            [style.background]="textBackgroundColorValue"
          ></span>
        </div>
      </div>
    </div>
    <div class="form-row" id="ckEditor">
      <label for="ckeditorInput" class="form-label">{{ 'WIDGETS.SHARED.TEXT' | translate }}</label>
      <div
        *ngIf="text.invalid && (((text.dirty || text.touched) && text.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.TEXT_ENTER' | translate }}
      </div>
      <ckeditor id="ckeditorInput" [editor]="editor" [config]="editorConfig" formControlName="text"></ckeditor>
    </div>
    <div class="form-row-groups form-row-groups--distanced">
      <div class="form-row-group form-row-group--distanced">
        <input id="showKitchenComment" type="checkbox" formControlName="showKitchenComment" />
        <label for="showKitchenComment" class="form-label form-label--checkbox">
          {{ 'WIDGETS.PRODUCT.DISPLAY_COMMENT' | translate }}
        </label>
      </div>
      <div class="form-row-group form-row-group--distanced">
        <input id="isCommentToKitchenRequired" type="checkbox" formControlName="isCommentToKitchenRequired" />
        <label for="isCommentToKitchenRequired" class="form-label form-label--checkbox">
          {{ 'WIDGETS.PRODUCT.COMMENT_REQUIRED' | translate }}
        </label>
      </div>
    </div>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="buttonBackgroundColorInput" class="form-label">
              {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
            </label>
            <span
              id="buttonBackgroundColorInput"
              [(colorPicker)]="backgroundColorValue"
              [style.backgroundColor]="backgroundColorValue"
              class="form-colorpicker"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form_row">
      <div class="form-row-groups-wrapper" formGroupName="style">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <input id="fullWidth" type="checkbox" formControlName="fullWidth" />
            <label for="fullWidth" class="form-label form-label--checkbox">
              {{ 'WIDGETS.STYLE.FULL_WIDTH' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <span class="form-label section-label">{{ 'WIDGETS.SHARED.LABELS' | translate }}</span>
    <div formGroupName="labels">
      <ng-container *ngFor="let labelGroup of labelGroups">
        <div class="form-col-line settings-group-row">
          <label class="form-label form-label--group-name">{{ labelGroup.name }}</label>
        </div>
        <div class="form-col-line settings-row" *ngFor="let label of labelGroup.labels">
          <label for="{{ labelName[label.key] | translate }}" class="form-label form-label--labels">
            {{ labelName[label.key] | translate }}
          </label>
          <input
            id="{{ label.key }}"
            formControlName="{{ label.key }}"
            type="text"
            class="form-input form-input--labels"
          />
        </div>
      </ng-container>
    </div>
  </form>
</pop-widget-edit-widget>
