import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import { Action, ActionType, Font, LEFT_BUTTON_TYPE, Page, PagesList, PageWithSettings } from '../types';

@Injectable()
export class PagesServices {
  languages = [
    {
      code: 'fr',
      name: 'French',
    },
    {
      code: 'he',
      name: 'Hebrew',
    },
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'zh',
      name: 'Chinese',
    },
    {
      code: 'cs',
      name: 'Czech',
    },
    {
      code: 'da',
      name: 'Danish',
    },
    {
      code: 'nl',
      name: 'Dutch',
    },
    {
      code: 'fi',
      name: 'Finnish',
    },
    {
      code: 'de',
      name: 'German',
    },
    {
      code: 'el',
      name: 'Greek',
    },
    {
      code: 'it',
      name: 'Italian',
    },
    {
      code: 'ja',
      name: 'Japanese',
    },
    {
      code: 'ko',
      name: 'Korean',
    },
    {
      code: 'pl',
      name: 'Polish',
    },
    {
      code: 'pt',
      name: 'Portuguese',
    },
    {
      code: 'ro',
      name: 'Romanian',
    },
    {
      code: 'ru',
      name: 'Russian',
    },
    {
      code: 'es',
      name: 'Spanish',
    },
    {
      code: 'sv',
      name: 'Swedish',
    },
    {
      code: 'tr',
      name: 'Turkish',
    },
  ];

  fonts: Font[] = [
    {
      name: 'Georgia',
      url: '',
    },
    {
      name: 'Palatino Linotype',
      url: '',
    },
    {
      name: 'Times New Roman',
      url: '',
    },
    {
      name: 'Arial',
      url: '',
    },
    {
      name: 'Arial Black',
      url: '',
    },
    {
      name: 'Comic Sans MS',
      url: '',
    },
    {
      name: 'Impact',
      url: '',
    },
    {
      name: 'Lucida Sans Unicode',
      url: '',
    },
    {
      name: 'Tahoma',
      url: '',
    },
    {
      name: 'Trebuchet MS',
      url: '',
    },
    {
      name: 'Verdana',
      url: '',
    },
    {
      name: 'Courier New',
      url: '',
    },
    {
      name: 'Lucida Console',
      url: '',
    },
    {
      name: 'BebasNeue',
      url: '',
    },
  ];

  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getAllPages(): Observable<PagesList> {
    return this._api.pages('v1').pipe(switchMap(api => this._http.get<PagesList>(api)));
  }

  getConcatPages(): Observable<Page[]> {
    return this.getAllPages().pipe(map(pages => this.concatPages(pages)));
  }

  concatPages(pages: PagesList): Page[] {
    return pages.single.sort((a, b) => a.name.localeCompare(b.name));
  }

  deletePage(pageId: string): Observable<any> {
    return this._api.page('v1', pageId).pipe(switchMap(api => this._http.delete(api)));
  }

  createPage(data: any): Observable<any> {
    return this._api.pages('v1').pipe(switchMap(api => this._http.post(api, data)));
  }

  updatePage(pageId: string, data: any): Observable<any> {
    return this._api.page('v1', pageId).pipe(switchMap(api => this._http.patch(api, data)));
  }

  getDefaultActionButtons(): Action[] {
    return [
      {
        title: 'Bread',
        actionMessage: 'Bread is on the way',
        icon: 'https://designer.smilein.fr/assets/images/action-call/bread.svg',
        description: 'bread',
        type: ActionType.BREAD,
      },
      {
        title: 'Water',
        actionMessage: 'Water is on the way',
        icon: 'https://designer.smilein.fr/assets/images/action-call/water.svg',
        description: 'water',
        type: ActionType.WATER,
      },
      {
        title: 'Check',
        actionMessage: 'Check is on the way',
        icon: 'https://designer.smilein.fr/assets/images/action-call/bill.svg',
        description: 'check',
        type: ActionType.CHECK,
      },
      {
        title: 'Waiter',
        actionMessage: 'Waiter called',
        icon: 'https://designer.smilein.fr/assets/images/action-call/other.svg',
        description: 'waiter',
        type: ActionType.WAITER,
      },
      {
        title: 'Call',
        icon: 'https://designer.smilein.fr/assets/images/action-call/phone.svg',
        telephone: '',
        type: ActionType.TELEPHONE,
      },
      {
        title: 'Send SMS',
        icon: 'https://designer.smilein.fr/assets/images/action-call/sms.svg',
        sms: '',
        type: ActionType.SMS,
      },
      {
        title: 'Send email',
        icon: 'https://designer.smilein.fr/assets/images/action-call/email.svg',
        email: '',
        type: ActionType.EMAIL,
      },
    ];
  }

  getNewPage(): PageWithSettings {
    return {
      name: '',
      type: 'normal',
      isLive: false,
      settings: {
        backgroundColor: '#ededed',
        pageFont: {
          url: null,
          name: null,
        },
        leftButtonType: LEFT_BUTTON_TYPE.BACK,
        actionCall: {
          display: false,
          title: '',
          popupHeader: '',
          icon: '',
          actionButtons: [],
        },
      },
    };
  }

  getPage(pageId: string): Observable<PageWithSettings> {
    return this._api.page('v1', pageId).pipe(switchMap(api => this._http.get<PageWithSettings>(api)));
  }
}
