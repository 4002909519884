<div
  *ngIf="isValid; else elseBlock"
  class="widget-carousel"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
>
  <div *ngIf="attributes.title || attributes.subtitle" class="carousel-header">
    <h3
      *ngIf="attributes.title"
      class="carousel-header-title"
      [style.color]="attributes.style?.widgetFontColor"
      [style.fontSize.px]="attributes.style?.widgetFontSize"
    >
      {{ attributes.title }}
    </h3>
    <span
      *ngIf="attributes.subtitle"
      class="carousel-header-adds"
      [style.color]="attributes.style?.widgetSubtitleColor"
      [style.fontSize.px]="attributes.style?.widgetSubtitleSize"
      [innerHTML]="attributes.subtitle"
    ></span>
  </div>
  <div *ngIf="carousel?.slides?.length" class="carousel-slide-container">
    <div
      *ngFor="let slide of carousel.slides"
      class="carousel-slide"
      [style.boxShadow]="
        attributes.style?.hasSlideBounds != undefined && !attributes.style?.hasSlideBounds ? 'none' : ''
      "
      [style.borderRadius]="
        attributes.style?.hasSlideBounds != undefined && !attributes.style?.hasSlideBounds ? '0' : ''
      "
      [style.padding]="attributes.style?.hasSlideBounds != undefined && !attributes.style?.hasSlideBounds ? '0' : ''"
      (click)="navigate(slide)"
      [style.backgroundColor]="
        attributes.style?.hasSlideBounds != undefined && !attributes.style?.hasSlideBounds ? 'transparent' : ''
      "
      [class.hoverable]="slide.link"
    >
      <h4
        *ngIf="slide.title"
        class="slide-title"
        [style.color]="attributes.style?.fontColor"
        [style.fontSize.px]="attributes.style?.fontSize"
        [innerHTML]="slide.title"
      ></h4>
      <h5
        *ngIf="slide.subtitle"
        class="slide-subtitle"
        [style.color]="attributes.style?.subtitleColor"
        [style.fontSize.px]="attributes.style?.subtitleSize"
        [innerHTML]="slide.subtitle"
      ></h5>
      <img
        *ngIf="slide.image"
        class="slide-image"
        alt=""
        [src]="slide.image?.url"
        width="100%"
        [style.borderRadius.px]="attributes.style?.imageBorderRadius"
      />
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-carousel">{{ noValidMessage }}</div>
</ng-template>
