import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, switchMap } from 'rxjs';

import { ApiDesignerService } from '@bend/store-shared';
import { CartWidget } from '@bend/widgets-editor/src/lib/widgets/cart/cart';

import { defaultAppConfig } from '@designer-config';

import {
  AppType,
  BottomBarActionCall,
  CatalogSettings,
  PaymentSettings,
  PopAppForDuplicate,
  PopApplication,
  TipsType,
  UiSettings,
  WorkingHours,
  WorkingHoursDisplay,
  WorkingHoursItem,
  WorkingHoursItemsSettingsDisplay,
  WorkIntervalDisplay,
} from '../types';

const DISPLAY_DAY_START = 'Day start';
const DISPLAY_DAY_END = 'Day end';
const DAY_START = 'startOfDay';
const DAY_END = 'endOfDay';
const CUSTOM_TIPS = 'Custom';

@Injectable()
export class ApplicationService {
  app: PopApplication = defaultAppConfig;

  private _isAppLoading: boolean;
  private _appCacheSubject: BehaviorSubject<PopApplication>;
  private _listeners = new Subject<any>();

  constructor(private _http: HttpClient, private _api: ApiDesignerService) {
    this._appCacheSubject = new BehaviorSubject(defaultAppConfig);
  }

  getApplications(): Observable<PopApplication[]> {
    return this._api.apps('v1').pipe(switchMap(api => this._http.get<PopApplication[]>(api)));
  }

  getAppTypes(): Observable<AppType[]> {
    return this._api.appTypes('v1').pipe(switchMap(api => this._http.get<AppType[]>(api)));
  }

  setCachedApplication(app: PopApplication): void {
    this.app = app;
    this._appCacheSubject.next(app);
  }

  getCachedApplication(appSlug: string): Observable<PopApplication> {
    if ((!this.app || this.app.slug !== appSlug) && !this._isAppLoading) {
      this._isAppLoading = true;
      this.getApplication().subscribe(app => {
        this.app = app;
        this._appCacheSubject.next(app);
        this._isAppLoading = false;
      });
    }

    return this._appCacheSubject.asObservable();
  }

  listen(): Observable<any> {
    return this._listeners.asObservable();
  }

  changeApp(app: PopApplication): void {
    this._listeners.next(app);
    this.setCachedApplication(app);
  }

  getApplication(): Observable<PopApplication> {
    return this._api.app('v1').pipe(switchMap(api => this._http.get<PopApplication>(api)));
  }

  createApplication(app: PopApplication): Observable<PopApplication> {
    return this._api.apps('v1').pipe(switchMap(api => this._http.post<PopApplication>(api, app)));
  }

  duplicateApplication(appData: PopAppForDuplicate): Observable<PopApplication> {
    return this._api.apps('v1').pipe(switchMap(api => this._http.post<PopApplication>(api, appData)));
  }

  editApplication(app: PopApplication): Observable<PopApplication> {
    return this._api.app('v1').pipe(switchMap(api => this._http.patch<PopApplication>(api, app)));
  }

  deleteApplication(): Observable<any> {
    return this._api.app('v1').pipe(switchMap(api => this._http.delete<any>(api)));
  }

  newPaymentSettings(paymentData: PaymentSettings): Observable<any> {
    return this._api.applicationsPaymentConfig('v1').pipe(switchMap(api => this._http.patch<any>(api, paymentData)));
  }

  getPaymentSettings(): Observable<PaymentSettings> {
    return this._api.applicationsPaymentConfig('v1').pipe(switchMap(api => this._http.get<PaymentSettings>(api)));
  }

  getWorkingHours(type: string): Observable<WorkingHours> {
    return this._api.workingHours('v1').pipe(switchMap(api => this._http.get<WorkingHours>(api, { params: { type } })));
  }

  postWorkingHours(type: string, workingHoursData: WorkingHoursItem[]): Observable<WorkingHoursItem[]> {
    return this._api
      .workingHours('v1')
      .pipe(switchMap(api => this._http.post<WorkingHoursItem[]>(api, workingHoursData, { params: { type } })));
  }

  deleteWorkingHoursInterval(type: string, ids: string[]): Observable<WorkingHours> {
    return this._api
      .workingHours('v1')
      .pipe(switchMap(api => this._http.delete<WorkingHours>(api, { params: { type, ids } })));
  }

  getCartLabels(): Observable<CartWidget> {
    return this._api.widgetStoreType('v1', 'cart').pipe(switchMap(api => this._http.get<CartWidget>(api)));
  }

  prepareDataForServer(workingHours: WorkingHoursItemsSettingsDisplay): WorkingHours {
    const dataForServer: WorkingHours = {
      settings: workingHours.settings,
      items: [],
    };
    workingHours.items.forEach(item => {
      item.workInterval.forEach(interval => {
        if (interval.open && interval.close) {
          interval.open = interval.open === DISPLAY_DAY_START ? DAY_START : interval.open;
          interval.close = interval.close === DISPLAY_DAY_END ? DAY_END : interval.close;
          let close = interval.close;
          if (!this.doesIntervalSpanAcrossTwoDays(interval)) {
            close = DAY_END;
            const lastDay = {
              weekDay: item.dayNr < 6 ? item.dayNr + 1 : 0,
              open: DAY_START,
              close: interval.close,
            };
            dataForServer.items.push(lastDay);
          }
          const day: WorkingHoursItem = {
            close,
            id: interval.id,
            weekDay: item.dayNr,
            open: interval.open,
          };
          const { id, ...itemWithoutId } = day;
          if (item.isDay && !day.id) {
            dataForServer.items.push(itemWithoutId);
          } else {
            if (item.isDay) {
              dataForServer.items.push(day);
            }
          }
        }
      });
    });
    return dataForServer;
  }

  doesIntervalSpanAcrossTwoDays(interval: WorkIntervalDisplay): boolean {
    let open = interval.open === DAY_START ? '00:00' : interval.open;
    let close = interval.close === DAY_END ? '23:59' : interval.close;
    close = close.replace(':', '');
    open = open.replace(':', '');
    if (parseInt(open, 10) > parseInt(close, 10)) {
      return false;
    }
    return true;
  }

  getWeekDaysAAAA(items: WorkingHoursItem[]): WorkingHoursDisplay[] {
    const weekDays: WorkingHoursDisplay[] = [
      {
        dayNr: 1,
        dayName: 'Monday',
        workInterval: [],
      },
      {
        dayNr: 2,
        dayName: 'Tuesday',
        workInterval: [],
      },
      {
        dayNr: 3,
        dayName: 'Wednesday',
        workInterval: [],
      },
      {
        dayNr: 4,
        dayName: 'Thursday',
        workInterval: [],
      },
      {
        dayNr: 5,
        dayName: 'Friday',
        workInterval: [],
      },
      {
        dayNr: 6,
        dayName: 'Saturday',
        workInterval: [],
      },
      {
        dayNr: 0,
        dayName: 'Sunday',
        workInterval: [],
      },
    ];

    weekDays.forEach((day, index) => {
      items.forEach(dayInfo => {
        if (dayInfo.weekDay === day.dayNr) {
          const interval = {
            open: dayInfo.open === DAY_START ? DISPLAY_DAY_START : dayInfo.open,
            close: dayInfo.close === DAY_END ? DISPLAY_DAY_END : dayInfo.close,
            id: dayInfo.id,
          };
          weekDays[index].workInterval.push(interval);
        }
      });
    });

    return weekDays;
  }

  addMissingAttributes(aplication: PopApplication): Observable<PopApplication> {
    let { settings } = aplication;

    return this._addMissingUiAttributes(settings).pipe(
      map(uiSettings => {
        const { id, _id, ...appWithoutSettings } = aplication as any;
        settings.ui = uiSettings;
        if (!settings) {
          settings = {
            pop: {
              tokenRequired: false,
              allowReadOnly: true,
              readOnlyDisplayMsg: null,
              noAccessDisplayMsg: null,
              tokenExpiresIn: '10',
            },
          };
        }

        if (!settings.pop) {
          settings.pop = {
            tokenRequired: false,
            allowReadOnly: true,
            readOnlyDisplayMsg: null,
            noAccessDisplayMsg: null,
            tokenExpiresIn: '10',
          };
        }

        if (!settings.tips) {
          settings.tips = {
            allowed: false,
            options: [
              {
                type: TipsType.ABSOLUTE,
                value: null,
                label: null,
                default: false,
              },
            ],
            restrictions: [
              {
                type: TipsType.ABSOLUTE,
                value: null,
                key: 'max_value',
              },
            ],
          };
        }

        if (!settings.tips.options.length) {
          settings.tips.options = [
            {
              type: TipsType.ABSOLUTE,
              value: null,
              label: null,
              default: false,
            },
          ];
        }

        if (settings.tips.options.length === 1 && settings.tips.options[0].label === CUSTOM_TIPS) {
          settings.tips.options = [
            {
              type: TipsType.ABSOLUTE,
              value: null,
              label: null,
              default: false,
            },
            settings.tips.options[0],
          ];
        }

        if (!settings.tips.restrictions.length) {
          settings.tips.restrictions = [
            {
              type: TipsType.ABSOLUTE,
              value: null,
              key: 'max_value',
            },
          ];
        }

        if (settings.cart) {
          if (settings.cart.askForPickupName === undefined || settings.cart.askForPickupName === null) {
            settings.cart.askForPickupName = true;
          }

          if (settings.cart.closeAfterMin === undefined) {
            settings.cart.closeAfterMin = 10;
          }
        }
        return { id: id || _id, ...appWithoutSettings, settings };
      }),
    );
  }

  private _addMissingUiAttributes(settings: CatalogSettings): Observable<UiSettings> {
    return this.getCartLabels().pipe(
      map(labels => {
        if (!settings.ui) {
          settings.ui = {
            bottomBar: {
              display: false,
              buttons: [],
              actionCall: new BottomBarActionCall(),
              cart: {
                display: false,
                attributes: labels.attributes,
              },
            },
            topBar: {
              display: false,
              allowUserProfile: false,
              backButton: {
                display: false,
                icon: null,
              },
              brandIcon: {
                display: false,
                icon: null,
              },
              homeButton: {
                display: false,
                icon: null,
              },
              menuButton: {
                display: false,
                icon: null,
              },
            },
          };
        }

        if (!settings.ui.topBar) {
          settings.ui.topBar = {
            display: false,
            allowUserProfile: false,
            backButton: {
              display: false,
              icon: null,
            },
            brandIcon: {
              display: false,
              icon: null,
            },
            homeButton: {
              display: false,
              icon: null,
            },
            menuButton: {
              display: false,
              icon: null,
            },
          };
        }

        if (!settings.ui.bottomBar) {
          settings.ui.bottomBar = {
            display: false,
            buttons: [],
            actionCall: new BottomBarActionCall(),
            cart: {
              display: false,
              attributes: labels.attributes,
            },
          };
        }

        if (!settings.ui.bottomBar.actionCall) {
          settings.ui.bottomBar.actionCall = {
            title: null,
            popupHeader: null,
            icon: null,
            display: false,
            actionButtons: [],
          };
        }

        if (!settings.ui.bottomBar.cart) {
          settings.ui.bottomBar.cart = {
            display: false,
            attributes: labels.attributes,
          };
        }

        if (!settings.ui.bottomBar.actionCall.actionButtons) {
          settings.ui.bottomBar.actionCall.actionButtons = [];
        }

        if (!settings.ui.bottomBar.cart.attributes) {
          settings.ui.bottomBar.cart.attributes = labels.attributes;
        }
        return settings.ui;
      }),
    );
  }
}
