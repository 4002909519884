import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { LanguagesConfig } from '@bend/store';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(private readonly _translate: TranslateService) {}

  get currentLang(): LanguagesConfig {
    return this._translate.currentLang as LanguagesConfig;
  }

  get defaultLang(): LanguagesConfig {
    return this._translate.defaultLang as LanguagesConfig;
  }

  use(language: LanguagesConfig): void {
    this._translate.use(language);
    localStorage.setItem('lang', language);
  }

  setDefaultLang(language: LanguagesConfig): void {
    this._translate.setDefaultLang(language);
    this._translate.onLangChange;
  }

  onLangChange(): EventEmitter<LangChangeEvent> {
    return this._translate.onLangChange;
  }
}
