import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, OperatorFunction } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';

import {
  Address,
  ApiVisitorService,
  CustomerInfo,
  Order,
  OrderApi,
  OrderDetails,
  OrderItem,
  OrderItemAdd,
  OrderItemApi,
  OrderItemOption,
  OrderItemType,
  OrderLocation,
  OrderMenuItem,
  OrderUpdated,
  OrderUpdatedApi,
  OrderUser,
} from '../shared';

@Injectable()
export class OrderHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  getAll(place: string): Observable<{
    orders: Order[];
    orderUsers: OrderUser[];
    orderLocations: OrderLocation[];
    orderItems: OrderItem[];
    orderItemOptions: OrderItemOption[];
    orderMenuItems: OrderMenuItem[];
  }> {
    return this._api.ordersHistory('v2').pipe(
      switchMap(api => this._http.get<OrderApi[]>(api, { params: { place } })),
      /**
       * @description cast string date for every order to Date type
       */
      switchMap(orders => from(orders).pipe(this._castOrderToDate())),
      /**
       * @description map orders to array
       */
      toArray(),
      map(orders => ({
        orders: orders.flatMap(order => this._filterOrder(order)),
        orderUsers: orders.flatMap(order => this._filterOrderUsers(order)),
        orderLocations: orders.flatMap(order => this._filterOrderLocations(order)),
        orderItems: orders.flatMap(order => this._filterOrderItems(order)),
        orderItemOptions: orders.flatMap(order => this._filterOrderItemOptions(order)),
        orderMenuItems: orders.flatMap(order => this._filterOrderMenuItems(order)),
      })),
    );
  }

  get(): Observable<{
    order: Order;
    orderUsers: OrderUser[];
    orderLocations: OrderLocation[];
    orderItems: OrderItem[];
    orderItemOptions: OrderItemOption[];
    orderMenuItems: OrderMenuItem[];
  }> {
    return this._api.orders('v2').pipe(
      switchMap(api => this._http.get<OrderApi>(api)),
      this._castOrderToDate(),
      map(order => ({
        order: this._filterOrder(order),
        orderUsers: this._filterOrderUsers(order),
        orderLocations: this._filterOrderLocations(order),
        orderItems: this._filterOrderItems(order),
        orderItemOptions: this._filterOrderItemOptions(order),
        orderMenuItems: this._filterOrderMenuItems(order),
      })),
    );
  }

  getRaw(): Observable<OrderApi> {
    return this._api.orders('v2').pipe(switchMap(api => this._http.get<OrderApi>(api)));
  }

  addOrderItem(orderItem: OrderItemAdd): Observable<void> {
    return this._api.orderItems('v1').pipe(switchMap(api => this._http.post<void>(api, orderItem)));
  }

  updateOrderItem(orderItemId: number, quantity: number): Observable<OrderUpdated> {
    return this._api.orderItem('v2', orderItemId).pipe(
      switchMap(api => this._http.patch<OrderUpdatedApi>(api, { quantity })),
      this._castToDate(),
    );
  }

  addOrderAddress(address: Address): Observable<void> {
    return this._api.orderAddress('v1').pipe(switchMap(api => this._http.post<void>(api, address)));
  }

  addOrderDetails(orderDetails: OrderDetails): Observable<void> {
    return this._api.orderDetails('v1').pipe(switchMap(api => this._http.post<void>(api, orderDetails)));
  }

  addOrderUserDetails(customerInfo: CustomerInfo): Observable<void> {
    return this._api.orderUsersDetails('v1').pipe(switchMap(api => this._http.post<void>(api, customerInfo)));
  }

  removeOrderItem(orderItemId: number): Observable<OrderUpdated> {
    return this._api.orderItem('v2', orderItemId).pipe(
      switchMap(api => this._http.delete<OrderUpdatedApi>(api)),
      this._castToDate(),
    );
  }

  orderLastUpdated(orderId: number): Observable<OrderUpdated> {
    return this._api.orderLastUpdated('v1', orderId).pipe(
      switchMap(api => this._http.get<OrderUpdatedApi>(api)),
      this._castToDate(),
    );
  }

  sendToKitchen(orderId: number): Observable<void> {
    return this._api.sendToKitchen('v1').pipe(switchMap(api => this._http.post<void>(api, { orderId })));
  }

  /**
   * need remove data from response
   */
  newOrder(): Observable<any> {
    return this._api.orderReopenUserSession('v1').pipe(switchMap(api => this._http.post(api, {})));
  }

  private _castOrderToDate(): OperatorFunction<OrderApi, OrderApi<Date>> {
    return map(order => {
      const { details, updatedAt, ...rest } = order;

      const { orderTime, ...restDetails } = details;

      return {
        ...rest,
        updatedAt: new Date(updatedAt),
        details: {
          ...restDetails,
          orderTime: (orderTime && new Date(orderTime)) || null,
        },
      };
    });
  }

  private _castToDate(): OperatorFunction<OrderUpdatedApi, OrderUpdated> {
    return map(response => {
      const { updatedAt, ...rest } = response;

      return {
        ...rest,
        updatedAt: new Date(updatedAt),
      };
    });
  }

  private _filterOrder(order: OrderApi<Date>): Order {
    const { orderUsers, ...rest } = order;
    return { ...rest, isLoading: false };
  }

  private _filterOrderUsers(orderApi: OrderApi<Date>): OrderUser[] {
    const { id, orderUsers } = orderApi;
    return orderUsers.map<OrderUser>(({ businessLocations: locations, transactions, ...rest }) => ({
      ...rest,
      transactions: transactions.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()),
      orderId: id,
    }));
  }

  private _filterOrderLocations(orderApi: OrderApi<Date>): OrderLocation[] {
    return orderApi.orderUsers.flatMap<OrderLocation>(({ id, businessLocations: locations }) =>
      locations.map<OrderLocation>(({ orderItems, ...rest }) => ({ ...rest, orderUserId: id, orderId: orderApi.id })),
    );
  }

  private _filterOrderItems(orderApi: OrderApi<Date>): OrderItem[] {
    return orderApi.orderUsers.flatMap<OrderItem>(({ id: orderUserId, businessLocations: locations }) =>
      locations.flatMap<OrderItem>(({ id: orderLocationId, orderItems }) =>
        orderItems.map(({ updatedAt, ...rest }) => ({
          ...rest,
          orderUserId,
          orderLocationId,
          updatedAt: new Date(updatedAt),
          orderId: orderApi.id,
        })),
      ),
    );
  }

  private _filterOrderItemOptions(orderApi: OrderApi<Date>): OrderItemOption[] {
    return orderApi.orderUsers.flatMap<OrderItemOption>(({ id: orderUserId, businessLocations: locations }) =>
      locations.flatMap<OrderItemOption>(({ orderItems }) =>
        orderItems
          .filter(this._isOrderItemSingle)
          .flatMap<OrderItemOption>(({ id: itemId, itemMeta: { options = [] } }) => [
            ...options.map<OrderItemOption>(option => ({
              orderUserId,
              orderId: orderApi.id,
              ...option,
              parentId: `${itemId}`,
            })),
          ]),
      ),
    );
  }

  private _filterOrderMenuItems(orderApi: OrderApi<Date>): OrderMenuItem[] {
    return orderApi.orderUsers.flatMap<OrderMenuItem>(({ id: orderUserId, businessLocations: locations }) =>
      locations.flatMap<OrderMenuItem>(({ orderItems }) =>
        orderItems
          .filter(this._isOrderItemSingle)
          .filter(({ itemMeta }) => !!itemMeta.items)
          .flatMap<OrderMenuItem>(({ id, itemMeta: { items } }) =>
            items.map<OrderMenuItem>(menuItem => ({
              orderUserId,
              ...menuItem,
              orderId: orderApi.id,
              orderItemId: id,
            })),
          ),
      ),
    );
  }

  private _isOrderItemSingle(item: OrderItemApi): item is OrderItemApi<OrderItemType.Single> {
    return item.type === OrderItemType.Single;
  }
}
