import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-button-rectangle',
  templateUrl: './button-rectangle.component.html',
  styleUrls: ['./button-rectangle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonRectangleComponent {
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() color: ThemePalette = 'primary';
  @Output() clicked: EventEmitter<any>;

  constructor() {
    this.clicked = new EventEmitter();
  }

  onClick(): void {
    if (this.disabled) return;

    this.clicked.emit();
  }
}
