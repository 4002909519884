<div class="phone-input">
  <lib-icon [style.fill]="mainColor" class="phone-icon" icon="phoneNumber"></lib-icon>
  <div class="country">
    <img (click)="onOpenModal()" [src]="icon" alt="" class="country__flag" />
    <span class="country__prefix">
      {{ prefixControl.value }}
      <pre>{{ prefixSeparator }}</pre>
    </span>
  </div>
  <input
    [class.input--invalid]="phoneControl.invalid && phoneControl.touched"
    [dropSpecialCharacters]="true"
    [formControl]="phoneControl"
    [mask]="mask"
    [patterns]="patterns"
    [specialCharacters]="specialCharacters"
    [validation]="true"
    class="input"
    id="phone"
    type="text"
  />
  <button (click)="editInput()" *ngIf="isCompleted" class="button" mat-icon-button>
    <lib-icon class="button__icon" icon="pencil"></lib-icon>
  </button>
</div>
<div *ngIf="phoneControl.invalid && phoneControl.touched" class="mat-error">
  {{ 'CART.PHONE_NUMBER_ERROR' | translate }}
</div>
