import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

import { AskQuestion, Visitor } from './ask-question';

@Injectable()
export class WidgetAskQuestionService {
  constructor(private _httpClient: HttpClient, private _api: ApiVisitorService) {}

  askQuestion(pageId: string, question: AskQuestion): Observable<any> {
    return this._api.questions('v1', pageId).pipe(switchMap(api => this._httpClient.post(api, question)));
  }

  getQuestions(pageId: string): Observable<any> {
    return this._api.questions('v1', pageId).pipe(switchMap(api => this._httpClient.get(api)));
  }

  getMe(): Observable<Visitor> {
    return this._api.me('v1').pipe(switchMap(api => this._httpClient.get<Visitor>(api)));
  }
}
