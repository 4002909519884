import { createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { OrderLocation,sortByKey } from '../shared';
import { actions } from './order-locations.actions';
import { State } from './order-locations.type';

export const adapter = createEntityAdapter<OrderLocation>({
  sortComparer: sortByKey<OrderLocation>()('id'),
  selectId: (location: OrderLocation) => `${location.id}-${location.orderUserId}`,
});

export const initialState: State = adapter.getInitialState({});

const orderItemsReducer = createReducer(
  initialState,
  on(actions.upsertOrderLocations, (state, { orderLocations }) => adapter.upsertMany(orderLocations, state)),
  on(actions.updateOrderLocations, (state, { orderLocations }) => adapter.setAll(orderLocations, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return orderItemsReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
