import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService, PosLocation, PosPaymentMethod, PosSettings } from '../shared';

@Injectable()
export class PosSettingsHttpService {
  constructor(private _api: ApiDesignerService, private _http: HttpClient) {}

  getPosSettingsList(): Observable<PosSettings[]> {
    return this._api.posSettings('v1').pipe(switchMap(api => this._http.get<PosSettings[]>(api)));
  }

  getPosSettings(posSettingsId: number): Observable<PosSettings> {
    return this._api.posSetting('v1', posSettingsId).pipe(switchMap(api => this._http.get<PosSettings>(api)));
  }

  addPosSettings(posSettings: Partial<PosSettings>): Observable<PosSettings> {
    return this._api.posSettings('v1').pipe(switchMap(api => this._http.post<PosSettings>(api, posSettings)));
  }

  deletePosSettings(posSettingsId: number): Observable<unknown> {
    return this._api.posSetting('v1', posSettingsId).pipe(switchMap(api => this._http.delete<unknown>(api)));
  }

  updatePosSettings(posSettings: PosSettings): Observable<PosSettings> {
    return this._api
      .posSetting('v1', posSettings.id)
      .pipe(switchMap(api => this._http.patch<PosSettings>(api, posSettings)));
  }

  posConnection(posSettingId: number): Observable<{ url: string }> {
    return this._api.posConnection('v1', posSettingId).pipe(switchMap(api => this._http.get<{ url: string }>(api, {})));
  }

  posLocations(posSettingId: number): Observable<PosLocation[]> {
    return this._api.posLocations('v1', posSettingId).pipe(switchMap(api => this._http.get<PosLocation[]>(api)));
  }

  posPaymentMethods(posSettingId: number): Observable<PosPaymentMethod[]> {
    return this._api
      .PosPaymentMethods('v1', posSettingId)
      .pipe(switchMap(api => this._http.get<PosPaymentMethod[]>(api)));
  }
}
