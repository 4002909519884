import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { ViewportService } from '@bend/viewport';

@Injectable()
export class BarTopPageViewsResolver {
  constructor(private _viewport: ViewportService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> {
    return this._viewport.isMobile.pipe(
      first(),
      switchMap(isMobile => {
        return isMobile
          ? from(Promise.all([import('../../views/bar-top-page-mobile')]))
          : from(Promise.all([import('../../views/bar-top-page-desktop')]));
      }),
    );
  }
}
