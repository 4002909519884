import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import { RecommendationsItemsDesigner, StoreService } from '../shared';
import { actions } from './recommendations-designer.actions';
import * as selectors from './recommendations-designer.selectors';
import { State } from './recommendations-designer.type';

@Injectable()
export class RecommendationsDesignerService extends StoreService<State> {
  readonly isLoadedGroups: Observable<number[]>;
  readonly isLoading: Observable<boolean>;
  readonly currentCatalogId: Observable<number>;

  constructor(
    protected _store: Store<State>,
    protected _actions: Actions,
    protected _designerRouter: DesignerRouterService,
  ) {
    super(_store, _actions, selectors);

    this.isLoading = this._isLoading;
    this.isLoadedGroups = this._isLoadedGroups;
    this.currentCatalogId = this._currentCatalogId;
  }

  getRecommendations(id: number): Observable<boolean> {
    this._store.dispatch(actions.getRecommendations({ id }));

    return this._finishedAction(actions.getRecommendationsSuccess, actions.getRecommendationsError);
  }

  allByGroup(groupId: number): Observable<RecommendationsItemsDesigner[]> {
    return this._store.pipe(
      select(selectors.total),
      map(recommendations => recommendations.filter(({ recommendationGroupId }) => recommendationGroupId === groupId)),
    );
  }

  private get _isLoadedGroups(): Observable<number[]> {
    return this._store.select(selectors.isLoadedGroups);
  }

  private get _isLoading(): Observable<boolean> {
    return this._store.select(selectors.isLoading);
  }

  private get _currentCatalogId(): Observable<number> {
    return this._store.select(selectors.catalogId);
  }
}
