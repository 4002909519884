export interface SmileHistory {
  data: SmileHistoryItem[];
  total: number;
  limit: number;
  skip: number;
}

export interface SmileHistoryItem {
  id: number;
  redirectType: string;
  device: string;
  url: string;
  createdAt: string;
}
