<div class="document-viewer-dialog-content dialog-content">
  <div class="content-wrapper">
    <button mat-dialog-close class="close-dialog-btn" title="close" [style.color]="attributes.mainColor">
      <svg
        [style.fill]="attributes.mainColor"
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        viewBox="0 0 16 16"
        width="16"
      >
        <path
          d="M15.56 13.44L10.12 8l5.44-5.44a1.496 1.496 0 0 0 0-2.12 1.496 1.496 0 0 0-2.12 0L8 5.88 2.56.44a1.496 1.496 0 0 0-2.12 0 1.496 1.496 0 0 0 0 2.12L5.88 8 .44 13.44a1.496 1.496 0 0 0 0 2.12 1.496 1.496 0 0 0 2.12 0L8 10.12l5.44 5.44a1.496 1.496 0 0 0 2.12 0 1.503 1.503 0 0 0 0-2.12z"
        />
      </svg>
    </button>

    <iframe [src]="url"></iframe>
  </div>
</div>
