import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './promo-codes.actions';
import { PromoCodesHttpService } from './promo-codes.http.service';
import * as selectors from './promo-codes.selectors';
import { State } from './promo-codes.type';

@Injectable()
export class PromoCodesEffects {
  constructor(private _store: Store<State>, private _actions$: Actions, private _httpService: PromoCodesHttpService) {}

  getPromoCodes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPromoCodes),
      withLatestFrom(
        this._store.pipe(select(selectors.isLoadedByAppSlug)),
        this._store.pipe(select(selectors.limit)),
        this._store.pipe(select(selectors.offset)),
      ),
      switchMap(([{ appSlug, limit, offset }, currentAppSlug, currentLimit, currentOffset]) =>
        appSlug === currentAppSlug && offset === currentOffset && currentLimit === limit
          ? of(actions.getPromoCodesIsLoaded())
          : this._httpService.getPromoCodes(limit, offset).pipe(
              map(promoCodes => actions.getPromoCodesSuccess({ promoCodes, appSlug })),
              catchError(({ code }) => of(actions.getPromoCodesError({ code }))),
            ),
      ),
    ),
  );

  addPromoCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addPromoCode),
      switchMap(({ promoCode }) =>
        this._httpService.addPromoCode(promoCode).pipe(
          map(code => actions.addPromoCodeSuccess({ code })),
          catchError(({ code }) => of(actions.addPromoCodeError({ code }))),
        ),
      ),
    ),
  );

  savePromoCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.savePromoCode),
      switchMap(({ promoCode, promoCodeId }) =>
        this._httpService.savePromoCode(promoCode, promoCodeId).pipe(
          map(code => actions.savePromoCodeSuccess({ code })),
          catchError(({ code }) => of(actions.savePromoCodeError({ code }))),
        ),
      ),
    ),
  );

  deletePromoCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deletePromoCode),
      switchMap(({ promoCodeId }) =>
        this._httpService.deletePromoCode(promoCodeId).pipe(
          map(() => actions.deletePromoCodeSuccess({ promoCodeId })),
          catchError(({ code }) => of(actions.deletePromoCodeError({ code }))),
        ),
      ),
    ),
  );
}
