import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService, PaymentConfig } from '../shared';

@Injectable()
export class PaymentHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getPaymentConfigList(): Observable<PaymentConfig[]> {
    return this._api.paymentSettings('v1').pipe(switchMap(api => this._http.get<PaymentConfig[]>(api)));
  }

  getPaymentConfig(id: number): Observable<PaymentConfig> {
    return this._api.paymentSetting('v1', id).pipe(switchMap(api => this._http.get<PaymentConfig>(api)));
  }

  addPaymentConfig(paymentConfig: PaymentConfig): Observable<PaymentConfig> {
    return this._api.paymentSettings('v1').pipe(switchMap(api => this._http.post<PaymentConfig>(api, paymentConfig)));
  }

  updatePaymentConfig(paymentConfig: PaymentConfig): Observable<PaymentConfig> {
    return this._api
      .paymentSetting('v1', paymentConfig.id)
      .pipe(switchMap(api => this._http.patch<PaymentConfig>(api, paymentConfig)));
  }

  deletePaymentConfig(id: number): Observable<void> {
    return this._api.paymentSetting('v1', id).pipe(switchMap(api => this._http.delete<void>(api)));
  }
}
