import { createAction, props } from '@ngrx/store';

import { createType, GenericPromoCode } from '../shared';
import { storeKey } from './promo-codes.config';
import { GetPromoCodes } from './promo-codes.type';

const type = createType(storeKey);

export const actions = {
  getPromoCodes: createAction(type('get promocodes'), props<{ appSlug: string; limit: number; offset: number }>()),
  getPromoCodesIsLoaded: createAction(type('get promocodes is loaded')),
  getPromoCodesSuccess: createAction(
    type('get promocodes success'),
    props<{ promoCodes: GetPromoCodes; appSlug: string }>(),
  ),
  getPromoCodesError: createAction(type('get promocodes error'), props<{ code: string }>()),

  addPromoCode: createAction(type('add promocode'), props<{ promoCode: GenericPromoCode }>()),
  addPromoCodeSuccess: createAction(type('add promocode success'), props<{ code: GenericPromoCode }>()),
  addPromoCodeError: createAction(type('add promocode error'), props<{ code: string }>()),

  savePromoCode: createAction(type('save promocode'), props<{ promoCode: GenericPromoCode; promoCodeId: number }>()),
  savePromoCodeSuccess: createAction(type('save promocode success'), props<{ code: GenericPromoCode }>()),
  savePromoCodeError: createAction(type('save promocode error'), props<{ code: string }>()),

  deletePromoCode: createAction(type('delete promocode'), props<{ promoCodeId: number }>()),
  deletePromoCodeSuccess: createAction(type('delete promocode success'), props<{ promoCodeId: number }>()),
  deletePromoCodeError: createAction(type('delete promocode error'), props<{ code: string }>()),
};
