import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './auth.config';
import { State } from './auth.type';

const stateSelector = createFeatureSelector<State>(storeKey);

export const all = createSelector(stateSelector, state => state);
export const isLoading = createSelector(stateSelector, auth => auth.isLoading);
export const isAuthenticated = createSelector(stateSelector, auth => auth.isAuthenticated);
export const token = createSelector(stateSelector, auth => auth.token);
export const refreshToken = createSelector(stateSelector, auth => auth.refreshToken);
export const errorCode = createSelector(stateSelector, auth => auth.errorCode);
export const isNew = createSelector(stateSelector, auth => auth.isNew);
export const isRegistered = createSelector(stateSelector, auth => auth.isRegistered);
export const phone = createSelector(stateSelector, auth => auth.phone);
