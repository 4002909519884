<span class="form-row-groups__caption">{{ title }}</span>
<div class="form-row-groups__content form-row-bordered">
  <div class="form-row-group form-row-group--file-upload">
    <button
      type="button"
      class="btn--choose app-button-color"
      (click)="onChooseFile(fileInputBtn)"
      tabindex="-1"
      [disabled]="displayProgressBar"
    >
      <span>{{ 'WIDGETS.UPLOAD.CHOOSE' | translate }}</span>
    </button>
    <input id="fileInput" class="input--hidden" type="file" (change)="onFileChange($event)" #fileInputBtn />
  </div>
  <div class="form-row-group form-row-group--file-upload form-row-group--growed">
    <label for="fileInputUrl" class="form-label form-label--inline">{{ 'WIDGETS.UPLOAD.OR' | translate }}</label>
    <div class="form-input-wrapper-growed">
      <!-- TODO: add validator errors sent by parent conponent -->
      <input
        id="fileInputUrl"
        type="url"
        placeholder="URL"
        [value]="fileUrl"
        class="form-input form-input--file"
        (input)="onChangeUrl($event)"
        [disabled]="displayProgressBar"
      />
    </div>
  </div>
  <div *ngIf="displayProgressBar" class="progress-bar-wrapper">
    <div class="progress-bar" [style.width.%]="uploadedSizePercent"></div>
  </div>
  <div
    *ngIf="!displayProgressBar"
    class="form-row-group form-row-group--large"
    [class.form-row-group--hidden]="!fileUrl"
  >
    <div class="image-preview-card">
      <img
        [src]="fileUrl ? fileUrl : '/assets/images/file-upload/file-icon.svg'"
        class="file-icon"
        alt="image preview"
        width="225"
      />
      <div class="image-preview-card__details">
        <span class="file-name">{{ file.name }}</span>
        <div class="delete-section">
          <button type="button" class="delete-icon-btn" (click)="deleteLoadedImage()">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="15.556">
              <path
                d="M1.11 17.778c0 1.228.996 2.222 2.223 2.222h8.89a2.22 2.22 0 0 0 2.22-2.222V4.444H1.112zM15.557 1.11h-3.89L10.557 0H5L3.89 1.11H0v2.223h15.556z"
                fill="#bebebf"
                fill-opacity=".941"
                class="svg-element-hoverable"
              />
              <path d="M-4.167 2.5h20v20h-20z" fill="none" />
            </svg>
          </button>
          <span class="file-size">{{ getFileSizeWithUnits(file.size) }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
