import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Margin, TextAlignWidget } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { fontSizeValidator } from '../../shared/validators/font-size.validator';
import { heightValidator } from '../../shared/validators/height.validator';
import { widthValidator } from '../../shared/validators/width.validator';
import { WidgetService } from '../widget.service';
import { TitleWidget } from './title';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-edit-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditTitleComponent extends WidgetBaseComponent<TitleWidget> implements OnInit {
  static widgetName = 'title';
  textAligns: string[];

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.textAligns = Object.values(TextAlignWidget);
    this._initForm();
  }

  private _addMissingAttributes(widget: TitleWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        fontSize: DEFAULT,
        textAlign: TextAlignWidget.CENTER,
        textColor: '',
        height: DEFAULT,
        backgroundColor: '',
        width: DEFAULT,
        margin: new Margin(),
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new Margin();
    }

    if (!attributes.style.textAlign) {
      attributes.style.textAlign = TextAlignWidget.CENTER;
    }
  }

  get title(): UntypedFormControl {
    return this.form.get('title') as UntypedFormControl;
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }

  get textColor(): UntypedFormControl {
    return this.form.get('style.textColor') as UntypedFormControl;
  }

  get textColorValue(): string {
    return this.textColor.value;
  }

  set textColorValue(color: string) {
    this.textColor.setValue(color);
  }

  get fontSize(): UntypedFormControl {
    return this.form.get('style.fontSize') as UntypedFormControl;
  }

  get width(): UntypedFormControl {
    return this.form.get('style.width') as UntypedFormControl;
  }

  get height(): UntypedFormControl {
    return this.form.get('style.height') as UntypedFormControl;
  }

  private _initForm(): void {
    const { title, style } = this.widget.attributes;
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        title: [title],
        style: this._formBuilder.group({
          fontSize: [style.fontSize || DEFAULT, fontSizeValidator()],
          textAlign: [style.textAlign],
          textColor: [style.textColor],
          height: [style.height, heightValidator()],
          backgroundColor: [style.backgroundColor],
          margin: this._formBuilder.group({
            top: [style.margin.top],
            bottom: [style.margin.bottom],
          }),
          width: [style.width, widthValidator()],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
