export interface PosProviderModel {
  id: number;
  name: string;
  type: PosType;
  mode: PosMode;
  isValid: boolean;
}

export enum PosMode {
  Test = 'test',
  Prod = 'prod',
}

export enum PosType {
  Ikentoo = 'ikentoo',
  Cashpad = 'cashpad',
  Generic = 'generic',
  Deliverect = 'deliverect',
  Hubrise = 'hubrise',
  Eurodata = 'eurodata',
  Zelty = 'zelty',
  Micros = 'micros',
  Micros_V2 = 'micros_v2',
}

export interface PosMenuToSelect {
  id: string;
  name: string;
}
