import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { TemplateTooltipComponent } from './template-tooltip.component';

@Directive({
  selector: '[templateTooltip]',
})
export class TemplateTooltipDirective implements OnInit, OnDestroy {
  @Input('templateTooltip') template: TemplateRef<any>;

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const positionStrategy = this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 5,
      },
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -5,
      },
    ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show(): void {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<TemplateTooltipComponent> = this._overlayRef.attach(
        new ComponentPortal(TemplateTooltipComponent),
      );

      tooltipRef.instance.template = this.template;
    }
  }

  @HostListener('mouseleave')
  hide(): void {
    this._overlayRef.detach();
  }

  ngOnDestroy(): void {
    this._overlayRef.detach();
  }
}
