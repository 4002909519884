import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PwaService {
  private _pwaSettings: any;

  private _callAddButtonSubject = new Subject<string>();
  private _pwaSettingsSubject = new Subject<any>();

  updatePwaSettings(pwaSettings: any): void {
    this._pwaSettings = pwaSettings;
    this._pwaSettingsSubject.next(pwaSettings);
  }

  getPwaSettings(): any {
    return this._pwaSettings;
  }

  onPwaSettingsUpdated(): Observable<any> {
    return this._pwaSettingsSubject.asObservable();
  }

  callAddButton(origin: string): void {
    this._callAddButtonSubject.next(origin);
  }

  onAddButtonCalled(): Observable<string> {
    return this._callAddButtonSubject.asObservable();
  }
}
