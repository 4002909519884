import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { ApiDesignerService } from '@bend/store';

import { DesignerApplication } from '@designer-store/apps';

@Injectable()
export class AppsHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getApplicationsList(): Observable<DesignerApplication[]> {
    return this._api.apps('v1').pipe(switchMap(url => this._http.get<DesignerApplication[]>(url)));
  }
}
