import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

import { ContactInfo } from './types';

@Injectable()
export class ContactInfoService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  // TD(artiom) remove any
  getMe(): Observable<any> {
    return this._api.me('v1').pipe(switchMap(api => this._http.get<any>(api)));
  }

  saveContactInfo(pageId: string, data: ContactInfo[]): Observable<ContactInfo[]> {
    return this._api.contactInfo('v1', pageId).pipe(switchMap(api => this._http.post<ContactInfo[]>(api, data)));
  }
}
