import { Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetActionBar } from './action-bar';

@Component({
  selector: 'pop-widget-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class WidgetActionBarComponent implements OnInit, WidgetComponent {
  static widgetName = 'action_bar';
  @Input() attributes: WidgetActionBar;

  isValid: boolean;
  noValidMessage: string;
  meta: string;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || (!this.attributes.email && !this.attributes.flyer && !this.attributes.url)) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetActionBarComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this.isValid = true;
    this.meta = this.getMeta();
  }

  getMeta(): string {
    let query = '';
    if (this.attributes.email) {
      query = `mailto:${this.attributes.email}`;
    }

    return `${query}`;
  }

  isFlyerDownloadable(): boolean {
    return /\.(?=(xls|xlsx|ods|xlr|doc|docx|odt|pdf|key|odp|pps|ppt|pptx)).{3,4}$/gi.test(this.attributes.flyer);
  }
}
