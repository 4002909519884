import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RecommendationService } from '@bend/store';
import { Recommendation } from '@bend/store-shared';

@Component({
  selector: 'app-item-quantity-modifier',
  templateUrl: './item-quantity-modifier.component.html',
  styleUrls: ['./item-quantity-modifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemQuantityModifierComponent {
  @Input() item: Recommendation | Omit<Recommendation, 'productId'>;
  quantity: number;

  constructor(private _recommendations: RecommendationService) {
    this.quantity = 0;
  }

  addItem(): void {
    this.quantity = this.quantity + this.item.quantityMultiplier;

    this._recommendations.changeQuantity(this.item.id, this.item.groupId, this.quantity);
  }

  removeItem(): void {
    if (this.quantity === 0) return;

    this.quantity -= this.item.quantityMultiplier;

    this._recommendations.changeQuantity(this.item.id, this.item.groupId, this.quantity);
  }
}
