import { DictionaryWorkingHoursIntervals } from '../types';

export interface PromoCode<T = PromoCodeType> {
  type: T;
  code: T extends PromoCodeType.Auto ? string : null;
  enabled: boolean;
  showAllPromoCodes: boolean;
}

export interface PromoCodeSettings<T = PromoCodeType> {
  type: T;
  code: T extends PromoCodeType.Auto ? string : null;
  enabled: boolean;
}

export interface GenericPromoCode {
  id: number;
  applicationId: number;
  displayedName: string;
  type: PromoCodeType;
  code: string;
  amount: number;
  minOrderAmount: number;
  amountType: PromoCodeAmountType;
  isActive: boolean;
  maxUsesPerUser: number;
  maxUsesTotal: number;
  validityStartDate: Date | null;
  validityEndDate: Date | null;
  usesSchedule: PromoCodeSchedule;
  intervals: DictionaryWorkingHoursIntervals<string>;
  intervalsScheduleType: IntervalsScheduleType;
  orderItemsValidations: OrderItemsValidations | null;
}

export enum PromoCodeType {
  Auto = 'auto',
  Manual = 'manual',
  Pos = 'pos',
}

export enum PromoCodeAmountType {
  Absolute = 'absolute',
  Percentage = 'percentage',
}

export enum PromoCodeSchedule {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface PromoCodeTimeFrames {
  from: string;
  to: string;
  day: number;
}

export enum IntervalsScheduleType {
  AlwaysAvailable = 'alwaysAvailable',
  Custom = 'custom',
}

export interface OrderItemsValidations {
  maxQuantity: number | null;
  maxItemCount: number | null;
  checkProductionOrder: boolean | null;
}

export interface PromoCodeLight {
  code: string;
  displayedName: string;
}
