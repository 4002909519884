import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../shared';
import { actions } from './users.actions';
import { State } from './users.type';

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
  total: 0,
  offset: 0,
  onlyPendingTickets: false,
  limit: 10,
  isLoadedBy: '',
});

const userReducer = createReducer(
  initialState,
  on(actions.getUsersByOffsetAndLimit, actions.updateUser, actions.getUser, state => ({ ...state, isLoading: true })),

  on(
    actions.getUsersByOffsetAndLimitSuccess,
    actions.getUsersBySearchSuccess,
    (state, { total, offset, limit, items }) =>
      adapter.setAll(items, {
        ...state,
        offset,
        limit,
        total,
      }),
  ),

  on(actions.addCredits, actions.addCreditsSuccess, actions.addCreditsError, (state, { user }) =>
    adapter.updateOne(user, state),
  ),

  on(actions.updateUserSuccess, actions.getUserSuccess, (state, { user: { id, ...rest } }) =>
    adapter.updateOne({ id, changes: { ...rest, isLoading: false } }, state),
  ),

  on(
    actions.updateUserError,
    actions.getUserError,
    actions.addCreditsError,
    actions.getUsersByOffsetAndLimitError,
    actions.getUsersBySearchError,
    (state, { errorCode }) => ({ ...state, isLoading: false, error: errorCode }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return userReducer(state, action);
}
