import { Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetFollow } from './follow';

@Component({
  selector: 'pop-widget-follow',
  templateUrl: './follow.component.html',
  styleUrls: ['./follow.component.scss'],
})
export class WidgetFollowComponent implements OnInit, WidgetComponent {
  static widgetName = 'follow';
  @Input() attributes: WidgetFollow;

  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.social.title) {
      this.noValidMessage = this._warningService.showWarn(WidgetFollowComponent.widgetName, WarnTypeEnum.NoAttributes);
    } else {
      this.isValid = true;
    }
  }
}
