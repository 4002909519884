import { Injectable } from '@angular/core';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { CartService } from '@bend/store/src/lib/cart';
import { OrderService } from '@bend/store/src/lib/order';
import { OrderItemsService } from '@bend/store/src/lib/order-items';
import { OrderItemType } from '@bend/store-shared';

import { TipsService } from '../../services';
import { OrderUserCurrentService } from '../order-user-current/order-user-current.service';
import { SplitBillService } from '../split-bill/split-bill.service';

@Injectable()
export class TotalService {
  constructor(
    private _order: OrderService,
    private _orderItems: OrderItemsService,
    private _cart: CartService,
    private _orderUserCurrent: OrderUserCurrentService,
    private _splitBill: SplitBillService,
    private _tips: TipsService,
  ) {}

  get isShow(): Observable<boolean> {
    return this._order.all.pipe(
      switchMap(order =>
        iif(
          /**
           * check user have order
           */
          () => order.isLoaded,
          /**
           * when user have order check if user have items in cart
           */
          this._orderItems.byOrderId(order.currentId).pipe(
            map(items => {
              const specialItemTypes = new Set([OrderItemType.Promo, OrderItemType.DeliveryFee, OrderItemType.Tips]);
              /**
               * @description check if are special items like promo code, delivery fee
               * if has just special items return false else return true
               */
              return items.some(({ type }) => !specialItemTypes.has(type));
            }),
          ),
          /**
           * when user don't have order we don't show bottom bar
           * for example when the command closes.
           */
          of(false),
        ),
      ),
    );
  }

  get user(): Observable<number> {
    return this._order.currentOrderUserId.pipe(switchMap(userId => this._orderItems.userTotal(userId)));
  }

  get userWithShared(): Observable<number> {
    return this._orderUserCurrent.currentWithOthers.pipe(
      map(users => users.map(({ id }) => id)),
      switchMap(usersId => this._orderItems.usersTotal(usersId)),
    );
  }

  get userAll(): Observable<number> {
    return this._order.currentOrderUserId.pipe(switchMap(userId => this._orderItems.userTotalAll(userId)));
  }

  get userWithSharedAll(): Observable<number> {
    return this._orderUserCurrent.currentWithOthers.pipe(
      map(users => users.map(({ id }) => id)),
      switchMap(usersId => this._orderItems.usersTotalAll(usersId)),
    );
  }

  /**
   * @description remain to pay for current order
   */
  remainToPay(orderId: number): Observable<number> {
    return this._order.orderTotalInfo(orderId).totalPaid.pipe(
      // get total paid
      switchMap(paid =>
        this._orderItems.allTotal(orderId).pipe(
          /**
           * decreases how much was paid from total
           */
          map(total => total - paid),
        ),
      ),
      withLatestFrom(this._order.currentId),
      switchMap(([remain, currentId]) =>
        currentId === orderId
          ? /**
             * @description if is current order check if use credits
             * if not return remain
             */
            this.all.pipe(
              switchMap(total =>
                this.useCredit(total).pipe(
                  /**
                   * decreases the credit from remain to pay
                   */
                  map(credit => remain - credit),
                ),
              ),
            )
          : of(remain),
      ),
    );
  }

  get currentRemainToPay(): Observable<number> {
    return combineLatest([
      this._order.currentId.pipe(switchMap(id => this.remainToPay(id))),
      this.splittedAmountWithCredits,
    ]).pipe(map(([remainToPay, splitBillAmount]) => (!isNaN(splitBillAmount) ? splitBillAmount : remainToPay)));
  }

  get currentRemainToPayWithTips(): Observable<number> {
    return combineLatest([this.currentRemainToPay, this._tips.amount$]).pipe(
      map(([remainToPay, tipsAmount]) => (tipsAmount ? remainToPay + tipsAmount : remainToPay)),
    );
  }

  get splittedAmountWithCredits(): Observable<number> {
    return this._splitBill.splitAmount.pipe(
      switchMap(amount =>
        this.useCredit(amount).pipe(
          /**
           * @description decreases the amount from remain to pay
           */
          map(credit => (amount !== null ? amount - credit : NaN)),
        ),
      ),
    );
  }

  get totalUseCredit(): Observable<number> {
    return combineLatest([this._splitBill.splitAmount, this.all]).pipe(
      switchMap(([splittedAmount, all]) => (splittedAmount ? this.useCredit(splittedAmount) : this.useCredit(all))),
    );
  }

  /**
   * @description returns the credit they use
   */
  useCredit(total: number): Observable<number> {
    return this._cart.credit.selected.pipe(
      switchMap(selected =>
        iif(
          /**
           * check if user selected to use credit
           */
          () => selected,
          /**
           * return credit
           */
          this.needCredit(total),
          /**
           * if user don't select to use credit return 0 because we need in remainToPay
           */
          of(0),
        ),
      ),
    );
  }

  /**
   * @description returns the credit I need to use to pay for the order
   */
  needCredit(total: number): Observable<number> {
    return combineLatest([this._cart.credit.value, this.paid]).pipe(
      /**
       * @description if remain to pay is less than 0.50 need to change remain to pay at 0.50
       * and use just a part of credits
       * this is because min amount for payment is 0.50
       */
      map(([credit, ...rest]) => [total - credit <= 50 && total - credit > 0 ? total - 50 : credit, ...rest]),
      map(([credit, paid]) =>
        credit > total - paid
          ? /**
             * if the credit is greater than the remain to pay then we will display the remain to pay
             */
            total - paid
          : /**
             * we display credit when it is equal to 0 and it is less than or equal to the remain to pay
             * when the user has no credit then it will be zero and will not be displayed
             */
            credit,
      ),
    );
  }

  get all(): Observable<number> {
    return this._order.currentId.pipe(switchMap(orderId => this._orderItems.allTotal(orderId)));
  }

  get allFromOrder(): Observable<number> {
    return this._order.totalInfo.total;
  }

  get paid(): Observable<number> {
    return this._order.totalInfo.totalPaid;
  }

  fee(userId: number): Observable<number> {
    // get fee item from order
    return this._orderItems.fee(userId).pipe(
      // check exist fee
      switchMap(fee =>
        iif(
          () => fee === undefined,
          // if there is no fee item, return 0
          of(null),
          // if exist fee return fee price
          of(fee).pipe(map(orderItemType => orderItemType.price)),
        ),
      ),
    );
  }
}
