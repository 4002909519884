import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './smiles.actions';
import { SmilesHttpService } from './smiles.http.service';
import * as selectors from './smiles.selectors';

@Injectable()
export class SmilesEffects {
  constructor(private _actions$: Actions, private _store: Store, private _smileHttpService: SmilesHttpService) {}

  getListOfSmiles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getSmiles),
      mergeMap(({ limit, skip, search }) =>
        this._smileHttpService.getSmiles({ limit, skip, search }).pipe(
          map(data => actions.getSmilesSuccess(data)),
          catchError(() => of(actions.getSmilesError())),
        ),
      ),
    ),
  );

  getListOfSmilesBySearch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getSmilesBySearch),
      mergeMap(({ limit, search }) =>
        this._smileHttpService.getSmiles({ limit, search, skip: 0 }).pipe(
          map(data => actions.getSmilesBySearchSuccess(data)),
          catchError(() => of(actions.getSmilesBySearchError())),
        ),
      ),
    ),
  );

  getSmile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getSmile),
      mergeMap(({ id }) =>
        this._smileHttpService.getSmileById(id).pipe(
          map(smile => actions.getSmileSuccess(smile)),
          catchError(() => of(actions.getSmileError())),
        ),
      ),
    ),
  );

  editSmile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.editSmile),
      mergeMap(({ smile }) =>
        this._smileHttpService.editSmiles(smile).pipe(
          map(changedSmile => actions.editSmileSuccess({ smile: changedSmile, id: smile.id })),
          catchError(() => of(actions.editSmileError())),
        ),
      ),
    ),
  );

  getNewSmiles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getNewSmiles),
      mergeMap(({ limit, search }) =>
        this._smileHttpService.getSmiles({ limit, search, skip: 0 }).pipe(
          map(resp => actions.getNewSmilesSuccess(resp)),
          catchError(() => of(actions.getNewSmilesError())),
        ),
      ),
    ),
  );

  generateSmiles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.generateSmiles),
      mergeMap(({ getSmiles, type, ...rest }) =>
        this._smileHttpService.generateSmiles(rest).pipe(
          mergeMap(() => [actions.generateSmilesSuccess(), actions.getNewSmiles(getSmiles)]),
          catchError(() => of(actions.generateSmilesError())),
        ),
      ),
    ),
  );

  updateSmilesPageId$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateSmilesPageId),
      withLatestFrom(this._store.select(selectors.limit), this._store.select(selectors.skip)),
      mergeMap(([{ params }, limit, skip]) =>
        this._smileHttpService.updateSmilesPageId(params).pipe(
          mergeMap(() => [actions.updateSmilesPageIdSuccess(), actions.getSmiles({ limit, skip })]),
          catchError(() => of(actions.generateSmilesError())),
        ),
      ),
    ),
  );
}
