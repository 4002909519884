<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form_row">
      <div class="form-row-groups-wrapper">
        <label class="form-label mb5">{{ 'WIDGETS.SHARED.DISPLAY_PARAMS' | translate }}</label>
        <div>
          <input id="showStars" type="checkbox" formControlName="showStars" />
          <label for="showStars" class="form-label form-label--checkbox">
            {{ 'WIDGETS.FEEDBACK.STARS' | translate }}
          </label>
        </div>
        <div>
          <input id="showName" type="checkbox" formControlName="showName" />
          <label for="showName" class="form-label form-label--checkbox">{{ 'WIDGETS.SHARED.NAME' | translate }}</label>
        </div>
        <div>
          <input id="showComment" type="checkbox" formControlName="showComment" />
          <label for="showComment" class="form-label form-label--checkbox">
            {{ 'WIDGETS.FEEDBACK.COMMENTS' | translate }}
          </label>
        </div>
        <div>
          <input id="showUpload" type="checkbox" formControlName="showUpload" />
          <label for="showUpload" class="form-label form-label--checkbox">
            {{ 'WIDGETS.FEEDBACK.UPLOAD_PICTURE' | translate }}
          </label>
        </div>
        <div>
          <input id="showPreviousFeedbacks" type="checkbox" formControlName="showPreviousFeedbacks" />
          <label for="showPreviousFeedbacks" class="form-label form-label--checkbox">
            {{ 'WIDGETS.FEEDBACK.PREVIOUS' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="showBackgroundInput" class="form-label">
              {{ 'WIDGETS.STYLE.BACKGROUND_SHOW' | translate }}
            </label>
            <input id="showBackgroundInput" type="checkbox" formControlName="showBackground" />
          </div>
        </div>
      </div>
    </div>
    <span class="form-label section-label">{{ 'WIDGETS.SHARED.LABELS' | translate }}</span>
    <div class="form-row-cols-wrapper settings-row" formGroupName="labels">
      <div class="form-col-line" *ngFor="let label of labels">
        <label for="{{ label.key }}" class="form-label form-label--print">
          {{ labelsNames[label.key] | translate }}
        </label>
        <input
          id="{{ label.key }}"
          formControlName="{{ label.key }}"
          type="text"
          class="form-input form-input--print"
        />
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
