import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiIosService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/smile-export
   */
  export(version: Version): Observable<string> {
    return this.smile(version).pipe(this._patch(`/smile-export`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/smile-generate
   */
  generate(version: Version): Observable<string> {
    return this.smile(version).pipe(this._patch(`/smile-generate`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/smile/smileId
   */
  edit(version: Version, smileId: string): Observable<string> {
    return this.smile(version).pipe(this._patch(`/smile/${smileId}`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/smile-place
   */
  smilePlace(version: Version): Observable<string> {
    return this.smile(version).pipe(this._patch(`/smile-place`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/smile-history
   */
  smileHistory(version: Version): Observable<string> {
    return this.smile(version).pipe(this._patch(`/smile-history`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/smile
   */
  smiles(version: Version): Observable<string> {
    return this.smile(version).pipe(this._patch(`/smile`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug/url-sync
   */
  smilesPageId(version: Version): Observable<string> {
    return this.smile(version).pipe(this._patch(`/url-sync`));
  }

  /**
   * @returns https://api-test.smilein.fr/ios/version/userType/appSlug
   */
  smile(version: Version): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/ios/${version}/${this._env.userType}/${appSlug}`),
    );
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
