import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, OperatorFunction, pipe, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItemStatus } from '@bend/store';
import { OrderService } from '@bend/store/src/lib/order';
import { OrderItemCreationMode } from '@bend/store-shared';

import { DialogKey, KitchenType, PriorityStatus } from '../../../../types';
import { KioskModeBehaviorService } from '../kiosk-mode-behavior/kiosk-mode-behavior.service';
import { OptimisticService } from '../optimistic/optimistic.service';
import { OrderDialogDisplayedService } from '../order-dialog-displayed/order-dialog-displayed.service';
import { OrderDialogErrorService } from '../order-dialog-error/order-dialog-error.service';
import { OrderDialogPaymentService } from '../order-dialog-payment/order-dialog-payment.service';
import { OrderDialogPreparingService } from '../order-dialog-preparing/order-dialog-preparing.service';
import { OrderDialogProgressService } from '../order-dialog-progress/order-dialog-progress.service';
import { OrderDialogPromoCodeService } from '../order-dialog-promo-code/order-dialog-promo-code.service';
import { OrderDialogReadyService } from '../order-dialog-ready/order-dialog-ready.service';
// eslint-disable-next-line max-len
import { OrderDialogTransactionsStatusService } from '../order-dialog-transactions-status/order-dialog-transactions-status.service';
import { OrderStatusServices } from '../order-status/order-status.service';
import { OrderUserService } from '../order-user/order-user.service';
import { TypeService } from '../type/type.service';

@Injectable()
export class OrderDialogService {
  /**
   * session key for previous status
   */
  private readonly _PREV_STATUS_KEY = 'prev-status';

  private _subscription: Subscription;

  constructor(
    private _order: OrderService,
    private _orderUser: OrderUserService,
    private _orderDialogProgress: OrderDialogProgressService,
    private _orderDialogPreparing: OrderDialogPreparingService,
    private _orderDialogPayment: OrderDialogPaymentService,
    private _orderDialogReady: OrderDialogReadyService,
    private _orderDialogError: OrderDialogErrorService,
    private _orderDialogDisplayed: OrderDialogDisplayedService,
    private _orderDialogPromoCode: OrderDialogPromoCodeService,
    private _orderDialogTransactionsStatus: OrderDialogTransactionsStatusService,
    private _dialog: DialogService,
    private _orderStatus: OrderStatusServices,
    private _type: TypeService,
    private _optimistic: OptimisticService,
    private _kiosk: KioskModeBehaviorService,
  ) {}

  init(): void {
    if (this._subscription) return;

    this._subscription = new Subscription();

    // need set time out because matDialog have a bug
    // https://github.com/angular/components/issues/5268
    setTimeout(() => {
      this._subscription.add(
        this._orderStatus.orderStatusChanged
          .pipe(
            /**
             * emit new events only when status or update_at or creationMode are changed
             */
            distinctUntilChanged((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)),
            this._filterByCreationMode(),

            // map(priorityStatus => priorityStatus?.status),
            /**
             * skip new status because he duplicate dialogs
             *
             * example
             * item1 -> paid
             * item2 -> new
             *
             * if I remove item2 status paid is show again
             */
            filter(priorityStatus => priorityStatus.status !== OrderItemStatus.New),
            /**
             * change NextForPreparing in Preparing because is the same dialog
             */
            map(priorityStatus =>
              priorityStatus.status === OrderItemStatus.NextForPreparing
                ? { ...priorityStatus, status: OrderItemStatus.Preparing }
                : priorityStatus,
            ),
            /**
             * check order updatedAt is changed
             * when is the same skip this event
             */
            this._filterByUpdatedAt(),
            /**
             * skip if the previous status from session storage is the same
             */
            filter(
              priorityStatus =>
                priorityStatus.creationMode !== OrderItemCreationMode.Server ||
                priorityStatus.status !== sessionStorage.getItem(this._PREV_STATUS_KEY),
            ),
            map(priorityStatus => priorityStatus.status),
            /**
             * set current status in session storage
             */
            tap(status => sessionStorage.setItem(this._PREV_STATUS_KEY, status)),

            withLatestFrom(
              this._type.kitchen,
              this._orderUser.number,
              this._orderStatus.currentOrderScheduledTo,
              this._type.app,
              this._kiosk.isKioskMode(),
            ),

            this._orderDialogPayment.waitingBehavior(),
            this._orderDialogProgress.orderedBehavior(),
            this._orderDialogPayment.errorBehavior(),
            this._orderDialogPayment.readyBehavior(),
            this._orderDialogPreparing.behavior(),
            this._orderDialogPreparing.scheduledBehavior(),
            this._orderDialogReady.behavior(),
            this._closeAll(),
          )
          .subscribe(),
      );

      this._subscription.add(
        this._orderStatus.orderStatusChanged
          .pipe(
            distinctUntilChanged((prev, current) => {
              return prev.status === current.status;
            }),
            this._filterByCreationMode(),
            map(priorityStatus => priorityStatus?.status),
            tap(status => this._kiosk.execBehaviorIfIsNecessary(status)),
          )
          .subscribe(),
      );

      this._subscription.add(this._optimistic.status.pipe(this._orderDialogPayment.progressBehavior()).subscribe());

      this._subscription.add(
        this._orderStatus.locationsStatusChanged
          .pipe(
            withLatestFrom(this._type.kitchen, this._orderUser.number),

            this._orderDialogReady.partialBehavior(),
          )
          .subscribe(),
      );

      // errors dialogs
      this._subscription.add(
        this._orderStatus.itemsStatusChanged
          .pipe(
            withLatestFrom(this._type.app, this._type.kitchen, this._orderUser.number),
            // TODO: remove map operator and fix type error
            /* eslint-disable */
            map(value => value as any),
            /* eslint-enable */
            this._orderDialogError.behavior(),
          )
          .subscribe(),
      );

      this._subscription.add(
        this._orderUser.promoCode
          .pipe(
            // TODO: remove any in generic
            /* eslint-disable */
            filter<any>(Boolean),
            /* eslint-enable */

            map(({ itemMeta: { promoStatus } }) => promoStatus),

            distinctUntilChanged(),

            switchMap(() => this._orderUser.promoCode.pipe(first())),

            withLatestFrom(this._order.id),

            this._orderDialogPromoCode.behavior(),
            this._orderDialogPromoCode.errorBehavior(),
          )
          .subscribe(),
      );

      this._subscription.add(
        this._orderStatus.transactionStatusChanged
          .pipe(
            withLatestFrom(this._orderStatus.orderStatusChanged, this._type.app),

            this._orderDialogTransactionsStatus.successBehavior(),
            this._orderDialogTransactionsStatus.errorBehavior(),
            this._orderDialogProgress.paymentBehavior(),
          )
          .subscribe(),
      );
    });
  }

  unsubscribe(): void {
    if (!this._subscription) return;

    this._subscription.unsubscribe();
    this._subscription = undefined;
  }

  private _filterByCreationMode(): OperatorFunction<PriorityStatus, PriorityStatus> {
    const allowStatuses = new Set([
      OrderItemStatus.New,
      OrderItemStatus.OrderedInProgress,
      // OrderItemStatus.Ordered,
      OrderItemStatus.OrderNotSent,
      // OrderItemStatus.Preparing,
      OrderItemStatus.ScheduledForPreparing,
      OrderItemStatus.WaitingForPayment,
      OrderItemStatus.Paid,
      OrderItemStatus.PaymentInProgress,
      OrderItemStatus.PaymentNotSent,
      OrderItemStatus.Reimbursed,
      OrderItemStatus.PickUpReady,
      OrderItemStatus.InDelivery,
      OrderItemStatus.Closed,
    ]);

    /**
     * ignore the status when the products are added from pos
     */
    return filter(({ status, creationMode }) => {
      /**
       * if item is created from server don't exclude any status
       */
      if (creationMode === OrderItemCreationMode.Server) return true;

      /**
       * in case when creation mode has changed from server -> POS
       * sync previous order status
       */
      sessionStorage.setItem(this._PREV_STATUS_KEY, status);
      /**
       * in case when creation mode has changed from server -> POS
       * clear delay dialog
       */
      this._orderDialogProgress.clearDelayDialog();
      /**
       * if items is added from pos we check is allowed in statuses
       */
      return allowStatuses.has(status);
    });
  }

  private _filterByUpdatedAt(): OperatorFunction<PriorityStatus, PriorityStatus> {
    return pipe(
      /**
       * a switchMap is needed here, since the updatedAt appears later in the order store
       * than the updated items, and it turns out that the filter checks for two identical dates
       * and does not skip the emit, although the data has already been updated
       */
      switchMap(status =>
        this._order.updatedAt.pipe(
          // skip when is the same date
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          filter(date => !this._orderDialogDisplayed.check(date, 0, DialogKey.Order)),
          // get only order item status and exclude date
          map(() => status),
        ),
      ),
    );
  }

  private _closeAll(): MonoTypeOperatorFunction<[OrderItemStatus, KitchenType, string, string]> {
    const allowStatuses = new Set([
      OrderItemStatus.Closed,
      OrderItemStatus.InDelivery,
      OrderItemStatus.New,

      // it used in payment components
      // it used in order dialog payment
      // OrderItemStatus.PaymentInProgress,

      // it is used in order dialog error
      // OrderItemStatus.Reimbursed,
      // OrderItemStatus.OrderNotSent,
      // OrderItemStatus.PaymentNotSent,

      // it is used in order dialog preparing
      // OrderItemStatus.Ordered,
      // OrderItemStatus.Preparing,
      // OrderItemStatus.NextForPreparing,
      // OrderItemStatus.ScheduledForPreparing,

      // it is used in order dialog progress
      // OrderItemStatus.OrderedInProgress,

      // it is used in order dialog payment
      // OrderItemStatus.Paid,
      // OrderItemStatus.WaitingForPayment,

      // it is used in order dialog ready
      // OrderItemStatus.PickUpReady,
    ]);

    return tap(([status]) => {
      // show only in status allowed
      if (!allowStatuses.has(status)) return;

      this._dialog.closeAll();
    });
  }
}
