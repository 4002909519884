import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';

import { EnvModule } from '@bend/env';
import { ScriptLoaderModule } from '@bend/script-loader';

import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [CommonModule, GoogleMapsModule, ScriptLoaderModule, EnvModule, TranslateModule],
})
export class MapModule {
  static widgetComponents = {
    MapComponent,
  };
}
