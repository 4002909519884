import { createAction, props } from '@ngrx/store';

import { createType, OrderUser } from '../shared';
import { storeKey } from './order-users.config';

const type = createType(storeKey);

export const actions = {
  addOrderUsers: createAction(type('add order session users'), props<{ orderUsers: OrderUser[] }>()),
  updateOrderUsers: createAction(type('update order session users'), props<{ orderUsers: OrderUser[] }>()),

  reset: createAction(type('reset')),
};
