import { PopProduct } from '@bend/widgets-old/product-catalog/product-catalog';

import { Product } from './item.type';

export type RecommendationGroupHttp = Omit<RecommendationGroup, 'productId'> & {
  recommendations: Omit<Recommendation, 'productId'>[];
};

export interface Recommendation extends Product {
  groupId: number;
  productId: number;
}

export interface RecommendationGroup {
  id: number;
  name: string;
  displayLabel: string;
  order: number;
  productId: number;
}

export interface PlaceRecommendationGroup extends Omit<RecommendationGroup, 'productId'> {
  recommendations: Recommendation[];
}

export type PlaceRecommendationGroup2 = Omit<
  RecommendationGroup & { recommendations: Omit<Recommendation, 'productId'>[] },
  'productId'
>;

export interface PlaceRecommendationGroupHttp extends Omit<RecommendationGroup, 'productId'> {
  recommendations: PlaceRecommendation[];
}

interface PlaceRecommendation {
  groupId: number;
  order: number;
  item: Omit<Recommendation, 'groupId'>;
}

export interface ProductRecommendationGroup extends RecommendationGroup {
  itemId: number;
  recommendations: PopProduct[];
}
