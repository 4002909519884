import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { OrderTypeService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { ItemUnavailabilityDisplay } from '@bend/store';
import { ParamsService } from '@bend/store';
import { CatalogQueryParams } from '@bend/store-shared';

import { PopCurrency } from '../../widget';
import { PopProduct, ProductCatalogStyleConfig } from '../product-catalog';
import { WidgetProductCatalogHelperService } from '../product-catalog-helper.service';

const MAX_DESCRIPTION_LENGTH = 150;

@Component({
  selector: 'pop-widget-product-catalog-item',
  templateUrl: './product-catalog-item.component.html',
  styleUrls: ['./product-catalog-item.component.scss'],
})
export class WidgetProductCatalogItemComponent implements OnInit, OnDestroy {
  @Input() product: PopProduct;
  @Input() currency: PopCurrency;
  @Input() mainColor: string;
  @Input() secondColor: string;
  @Input() isReadOnly: boolean;
  @Input() isConsultingMode: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  @Input() isMenu: boolean;
  @Input() loadingProductId: number;
  @Input() styleType: string;
  @Input() styleNumber: number;
  @Input() forceShowProductDescription: boolean;
  @Input() styleConfig: ProductCatalogStyleConfig;
  @Output() loadProductDetailsEmitter: EventEmitter<{
    type: string;
    showDetails: boolean;
    forceDetailsForMenu: boolean;
  }>;

  productDescription: string;
  productStyleType: string;
  selectedItems: PopProduct[] = [];

  private _subscription: Subscription;

  constructor(
    private _params: ParamsService,
    private _router: Router,
    private _orderType: OrderTypeService,
    private _helperService: WidgetProductCatalogHelperService,
  ) {
    this.loadProductDetailsEmitter = new EventEmitter<{
      type: string;
      showDetails: boolean;
      forceDetailsForMenu: boolean;
    }>();
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this.productDescription =
      this.product.description.length > MAX_DESCRIPTION_LENGTH
        ? `${this.product.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
        : this.product.description;

    /**
     * queryParams return only one event, no need to unsubscribe
     */
    this._subscription.add(
      this._orderType.isReadOnly
        .pipe(
          /**
           * allow only catalog is not read only
           */
          filter(isReadOnly => !isReadOnly),
          /**
           * get item id from query params
           */
          switchMap(() => this._params.queryParams<string>([CatalogQueryParams.AddItem])),
          map(([itemId]) => itemId),
          /**
           *  check exist addItem
           */
          filter(Boolean),
          /**
           * cast itemId to number
           */
          map(Number),
          /**
           * check is this item
           */
          filter(itemId => itemId === this.product.id),
          tap(() => {
            /**
             * emit value to add item
             */
            this.loadProductDetailsEmitter.emit({ type: 'fromQuery', forceDetailsForMenu: true, showDetails: false });
            /**
             * remove item id from queryParams
             */
            this._router.navigate([], {
              queryParams: { addItem: null },
              queryParamsHandling: 'merge',
            });
          }),
        )
        .subscribe(),
    );
    this._helperService.selectDefaultSubProduct(this.product);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  emitLoadProductDetails({ showDetails, forceDetailsForMenu }: any): void {
    if (!this.loadingProductId) {
      this.loadProductDetailsEmitter.emit({ showDetails, forceDetailsForMenu, type: 'fromButton' });
    }
  }
}
