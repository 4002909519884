import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TimerService {
  isShow$: Observable<boolean>;

  private _isShow$: BehaviorSubject<boolean>;

  constructor() {
    this._isShow$ = new BehaviorSubject<boolean>(true);
    this.isShow$ = this._isShow$.asObservable();
  }

  start(): void {
    this._isShow$.next(true);
  }

  stop(): void {
    this._isShow$.next(false);
  }
}
