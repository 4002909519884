<div *ngIf="country$ | async as country" class="phone">
  <div
    [style.borderColor]="color$ | async"
    [class.phone__border--error]="isShowError"
    [class.phone__border--disabled]="isDisabled"
    class="phone__border"
  ></div>
  <button class="phone__flag" (click)="select()" tabindex="-1">
    <img src="/assets/images/flags/{{ country.code.toLowerCase() }}.svg" alt="Country" class="phone__icon" />
    <span class="phone__prefix" [class.phone__prefix--disabled]="isDisabled">{{ country.prefix }}</span>
    <span class="phone__separator" [class.phone__separator--disabled]="isDisabled">{{ country.prefixSeparator }}</span>
  </button>
  <input
    #input
    [formControl]="control"
    [mask]="country.mask"
    [patterns]="customPatterns"
    [validation]="true"
    [class.phone__input--disabled]="isDisabled"
    data-cy="phoneInput"
    name="phone"
    type="string"
    inputmode="numeric"
    class="phone__input"
  />
  <button *ngIf="isDisabled" (click)="enable()" class="edit">
    <lib-icon icon="pencil" [style.fill]="color$ | async" class="edit__icon"></lib-icon>
  </button>
</div>
<app-input-errors [ngControl]="ngControl"></app-input-errors>
