<div class="validators">
  <div class="validator">
    <div class="checkbox validator__check">
      <input (change)="changeTimeValue()" [checked]="timeCheckBox" id="conditional-time" type="checkbox" />
      <label for="conditional-time" class="label">{{ 'WIDGETS.CONDITIONAL.BY_TIME' | translate }}</label>
    </div>
    <div *ngIf="timeCheckBox" class="validator__content days">
      <ng-container *ngFor="let validator of form['controls']">
        <pop-widget-edit-time-validator
          *ngIf="validator.value.type === 'time'"
          [form]="$any(validator)"
        ></pop-widget-edit-time-validator>
      </ng-container>
    </div>
  </div>
  <div class="validator">
    <div class="checkbox validator__check">
      <input
        (change)="changeQueryParamValue()"
        [checked]="queryParamCheckBox"
        id="conditional-queryParam"
        type="checkbox"
      />
      <label for="conditional-queryParam" class="label">{{ 'WIDGETS.CONDITIONAL.CUSTOM' | translate }}</label>
    </div>
    <div *ngIf="queryParamCheckBox" class="validator__content query-param">
      <div class="query-param__content">
        <ng-container *ngFor="let validator of form['controls']; index as i">
          <div *ngIf="validator.value.type === 'queryParam'" class="query-param__item">
            <pop-widget-edit-query-param-validator
              [form]="$any(validator)"
              class="query-param__param"
            ></pop-widget-edit-query-param-validator>
            <div class="query-param__controls">
              <button (click)="addQueryParam(i)" class="svg-button">
                <svg class="svg-button__icon">
                  <use xlink:href="../../../../../assets/images/icons.svg#add"></use>
                </svg>
              </button>
              <button *ngIf="showQueryParamButtonRemove" (click)="removeQueryParam(i)" class="svg-button">
                <svg class="svg-button__icon">
                  <use xlink:href="../../../../../assets/images/icons.svg#remove"></use>
                </svg>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
