import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SnackBarBuilderService } from '@designer-modules/snackbar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(
    private snackBarBuilderService: SnackBarBuilderService,
    private translateService: TranslateService,
  ) {}

  success(message: string): void {
    this.snackBarBuilderService
      .getBuilder()
      .setSuccessSnackbar()
      .setTitle(this.translate('STUDIO.SNACK.LABEL.TITLE.SUCCESS'))
      .setSubtitle(this.translate(message))
      .setButtonLabel(this.translate('ASK_QUESTION.CLOSE'))
      .build();
  }

  error(error?: string): void {
    this.snackBarBuilderService
      .getBuilder()
      .setWarningSnackbar()
      .setTitle(this.translate('STUDIO.SNACK.LABEL.TITLE.ERROR'))
      .setSubtitle(this.translate(error || 'STUDIO.SNACK.ERROR.GENERIC_ERROR'))
      .setButtonLabel(this.translate('ASK_QUESTION.CLOSE'))
      .build();
  }

  information(message: string): void {
    this.snackBarBuilderService
      .getBuilder()
      .setInformationSnackbar()
      .setTitle(this.translate('STUDIO.SNACK.LABEL.TITLE.INFO'))
      .setSubtitle(this.translate(message))
      .setButtonLabel(this.translate('ASK_QUESTION.CLOSE'))
      .build();
  }

  private translate(messageKey: string): string {
    return this.translateService.instant(messageKey);
  }
}
