import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Menu } from '../shared';
import * as selectors from './menu.selectors';
import { State } from './menu.type';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(private _store: Store<State>) {}

  menuByCatalog(catalogId: number): Observable<Menu[]> {
    return this._store.select(selectors.all).pipe(map(menus => menus.filter(menu => menu.parentId === catalogId)));
  }
}
