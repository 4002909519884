import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatIconRegistryModule } from '@designer-shared/icons/material-icon-registry.module';

import {
  ConfirmPopinComponent,
  ConfirmPopinContainerComponent,
  PopinComponent,
  PopinContentDirective,
} from './components';
import { PopinBuilderService } from './services';

@NgModule({
  declarations: [PopinComponent, PopinContentDirective, ConfirmPopinComponent, ConfirmPopinContainerComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatIconRegistryModule],
  providers: [PopinBuilderService],
})
export class PopinModule {}
