import { ElementRef, Injectable } from '@angular/core';

@Injectable()
export class WidgetProductCatalogLayoutService {
  delayDisplayNone(subcategoryDetailsEl: HTMLElement): void {
    subcategoryDetailsEl.style.display = 'none';
  }

  subcatEaseInAnimation(subcategoryDetailsEl: HTMLElement): void {
    const elem = subcategoryDetailsEl;
    let pos = -120;
    const id = setInterval(frame, 0.375);
    function frame(): void {
      if (pos === 0) {
        clearInterval(id);
      } else {
        pos++;
        elem.style.opacity = '1';
        elem.style.transform = `translate(${pos}%)`;
      }
    }
  }

  setFakeFooterHeightFromFixedPositionedEl(fakeFooterEl: ElementRef, fixedEl: ElementRef): void {
    if (fakeFooterEl && fixedEl) {
      const fixedElBoundingClientRect = fixedEl.nativeElement.getBoundingClientRect();
      if (fixedElBoundingClientRect) {
        fakeFooterEl.nativeElement.style.height = `${fixedElBoundingClientRect.height}px`;
      }
    }
  }
}
