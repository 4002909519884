import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './credit-history.config';
import { adapter } from './credit-history.reducer';
import { State } from './credit-history.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectAll, selectEntities } = adapter.getSelectors();

export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const error = createSelector(stateSelector, state => state.error);
export const isLoading = createSelector(stateSelector, action => action.isLoading);
export const total = createSelector(stateSelector, state => state.total);
