import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { SharedComponentsModule } from '@bend/shared-widgets/src/lib/shared/shared-components';
import { RecommendationMenuBackdropDirective } from '@bend/widgets-old/product-catalog/directives/recommendation-menu-backdrop.directive';

import { SortByOrderPipe } from '../shared/pipes/sort-by-order.pipe';
import { SharedModule } from '../shared/shared.module';
import { WidgetTopBarComponent } from '../top-bar/top-bar.component';
import { WidgetProductCatalogALaCarteComponent } from './a-la-carte/product-catalog-a-la-carte.component';
import { WidgetProductCatalogCommentToKitchenComponent } from './comment-to-kitchen/comment-to-kitchen.component';
import {
  ItemQuantityModifierComponent,
  RecommendationComponent,
  RecommendationContainerComponent,
  RecommendationGroupComponent,
  RecommendationMenuComponent,
  RecommendationsComponent,
} from './components';
import { WidgetProductCatalogCreateVisitorDialogComponent } from './create-visitor-dialog/create-visitor-dialog.component';
import { ClickOutsideMenuDirective, IsLoadedDirective } from './directives';
import { WidgetProductCatalogMenuComponent } from './menu/product-catalog-menu.component';
import { StepByStepMenuComponent } from './menu/step-by-step-menu/step-by-step-menu.component';
import { MenuCatalogItemComponent } from './menu-catalog-item/menu-catalog-item.component';
import { WidgetProductCatalogMenuDetailsDialogComponent } from './menu-details-dialog/menu-details-dialog.component';
import { RecommendationDetailsQuantityPipe } from './pipes';
import { ClassicItemComponent } from './product-catalog-item/components/classic-item/classic-item.component';
import { CompactItemComponent } from './product-catalog-item/components/compact-item/compact-item.component';
import { ExtendedItemComponent } from './product-catalog-item/components/extended-item/extended-item.component';
import { SideItemComponent } from './product-catalog-item/components/side-item/side-item.component';
import { WidgetProductCatalogItemComponent } from './product-catalog-item/product-catalog-item.component';
import { WidgetProductCatalogSubcategoryComponent } from './product-catalog-subcategory/product-catalog-subcategory.component';
import { WidgetProductCatalogDetailsDialogComponent } from './product-details-dialog/product-details-dialog.component';
import { ProductQuantityButtonsComponent } from './product-quantity-buttons/product-quantity-buttons.component';
import { ProductStatusCheckoutComponent } from './product-status-checkout/product-status-checkout.component';
import { RecommendationMenuBackdropService, RecommendationsMenuService, RecommendationsService } from './services';
import { WidgetProductCatalogSharedCartDialogComponent } from './shared-cart-dialog/shared-cart-dialog.component';
import { TagComponent } from './tags-presenter/tag/tag.component';
import { TagListComponent } from './tags-presenter/tag-list/tag-list.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [
    ClassicItemComponent,
    CompactItemComponent,
    ExtendedItemComponent,
    SideItemComponent,
    RecommendationContainerComponent,
    WidgetProductCatalogItemComponent,
    WidgetProductCatalogALaCarteComponent,
    WidgetProductCatalogCommentToKitchenComponent,
    WidgetProductCatalogCreateVisitorDialogComponent,
    WidgetProductCatalogMenuComponent,
    StepByStepMenuComponent,
    WidgetProductCatalogMenuDetailsDialogComponent,
    WidgetProductCatalogSubcategoryComponent,
    WidgetProductCatalogDetailsDialogComponent,
    WidgetProductCatalogSharedCartDialogComponent,
    MenuCatalogItemComponent,
    WidgetTopBarComponent,
    SortByOrderPipe,
    ProductStatusCheckoutComponent,
    RecommendationsComponent,
    RecommendationGroupComponent,
    RecommendationComponent,
    ItemQuantityModifierComponent,
    ProductQuantityButtonsComponent,
    TagComponent,
    TagListComponent,
    IsLoadedDirective,
    ClickOutsideMenuDirective,
    RecommendationMenuBackdropDirective,
    RecommendationMenuComponent,
    RecommendationDetailsQuantityPipe,
  ],
  providers: [
    RecommendationsMenuService,
    RecommendationMenuBackdropService,
    RecommendationsService,
    RecommendationDetailsQuantityPipe,
  ],
  exports: [
    SortByOrderPipe,
    WidgetProductCatalogItemComponent,
    WidgetProductCatalogMenuComponent,
    WidgetProductCatalogALaCarteComponent,
    WidgetTopBarComponent,
    ProductStatusCheckoutComponent,
    ItemQuantityModifierComponent,
    RecommendationDetailsQuantityPipe,
    IsLoadedDirective,
  ],
})
export class WidgetProductCatalogModule {}
