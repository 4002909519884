import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './recommendations-groups-designer.config';
import { adapter } from './recommendations-groups-designer.reducer';
import { State } from './recommendations-groups-designer.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const total = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const isLoadedByAppSlug = createSelector(stateSelector, state => state.isLoadedByAppSlug);
export const selectedId = createSelector(stateSelector, state => state.selectedId);
export const error = createSelector(stateSelector, state => state.error);
export const isLoadingByAppSlug = createSelector(stateSelector, state => state.isLoadingByAppSlug);

export const isLoaded = createSelector(
  stateSelector,
  isLoadingByAppSlug,
  (state, appSlug) => !!appSlug && state.isLoadedByAppSlug === appSlug,
);

export const lastAdded = createSelector(ids, ([allIds]) => allIds);
