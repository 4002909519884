<div class="element">
  <div
    [class.element__border--error]="isShowError"
    [class.element__border--disabled]="isDisabled"
    class="element__border"
  ></div>
  <input
    #input
    [formControl]="control"
    [placeholder]="placeHolder"
    [dropSpecialCharacters]="false"
    [name]="name"
    maxlength="3"
    mask="percent"
    [class.element__input--disabled]="isDisabled"
    class="element__input"
  />
</div>
<app-input-errors [ngControl]="ngControl" [showError]="isShowError"></app-input-errors>
