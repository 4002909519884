import { Observable } from 'rxjs';

export type DictionaryStore<T> = {
  [K in keyof T | 'all']: K extends keyof T
    ? T[K] extends string | number | boolean | Date
      ? // return key of object
        Observable<T[K]>
      : // return ArrayStore or DictionaryStore
        IsArrayStore<T[K]>
    : // return 'all' key
      Observable<T>;
};

export type IsArrayStore<T> = T extends any[] ? ArrayStore<T[number]> : DictionaryStore<T>;

export type ArrayStore<T> = {
  [K in keyof T | 'all']: K extends keyof T
    ? // return array of one key from object
      Observable<T[K][]>
    : // return 'all' key
      Observable<T[]>;
};
