export interface ScriptLoader {
  script: string;
  loaded: boolean;
  status: Status;
}

export const enum Status {
  AlreadyLoaded = 'already_loaded',
  Loaded = 'loaded',
  NotLoaded = 'not-loaded',
}
