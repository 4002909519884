import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions as recommendationsActions } from '../recommendations-designer/recommendations-designer.actions';
import { actions } from './recommendations-groups-designer.actions';
import { RecommendationsGroupsDesignerHttpService } from './recommendations-groups-designer.http.service';
import * as selectors from './recommendations-groups-designer.selectors';
import { State } from './recommendations-groups-designer.type';

@Injectable()
export class RecommendationsGroupsDesignerEffects {
  constructor(
    private _httpService: RecommendationsGroupsDesignerHttpService,
    private _store: Store<State>,
    private _actions$: Actions,
  ) {}

  getRecommendationsGroups$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getRecommendationsGroups),
      withLatestFrom(this._store.select(selectors.isLoadedByAppSlug)),
      switchMap(([{ appSlug }, isLoadedByAppSlug]) =>
        appSlug === isLoadedByAppSlug
          ? of(actions.getRecommendationsGroupsIsLoaded())
          : this._httpService.getRecommendationGroups().pipe(
              mergeMap(groups => [
                actions.getRecommendationsGroupsSuccess({ groups, appSlug }),
                recommendationsActions.removeAllRecommendations(),
              ]),
              catchError(({ errorCode }) => of(actions.getRecommendationsGroupsError({ errorCode }))),
            ),
      ),
    ),
  );

  addRecommendationsGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addRecommendationsGroup),
      switchMap(({ group }) =>
        this._httpService.addRecommendationGroup(group).pipe(
          mergeMap(({ recommendations, id, ...rest }) => [
            actions.addRecommendationsGroupSuccess({ recommendationsGroup: { id, ...rest } }),
            recommendationsActions.getRecommendationsSuccess({ recommendations, recommendationGroupId: id }),
          ]),
          catchError(({ errorCode }) => of(actions.addRecommendationsGroupError({ errorCode }))),
        ),
      ),
    ),
  );

  saveRecommendationsGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.saveRecommendationsGroup),
      switchMap(({ group }) =>
        this._httpService.saveRecommendationGroup(group).pipe(
          mergeMap(({ recommendations, id, ...rest }) => [
            actions.saveRecommendationsGroupSuccess({ recommendationsGroup: { id, ...rest } }),
            recommendationsActions.saveRecommendations({ recommendations, groupId: id }),
          ]),
          catchError(({ errorCode }) => of(actions.saveRecommendationsGroupError({ errorCode }))),
        ),
      ),
    ),
  );

  deleteRecommendationsGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deleteRecommendationsGroup),
      switchMap(({ groupId }) =>
        this._httpService.deleteRecommendationGroup(groupId).pipe(
          mergeMap(({ id }) => [
            actions.deleteRecommendationsGroupSuccess({ groupId: +id }),
            recommendationsActions.deleteRecommendations({ groupId: +id }),
          ]),
          catchError(({ errorCode }) => of(actions.deleteRecommendationsGroupError({ errorCode }))),
        ),
      ),
    ),
  );
}
