import { Injectable } from '@angular/core';
import { combineLatest, OperatorFunction, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog/src/lib/services';

import { ErrorCodes } from '../../../../types';
import { TypeService } from '../type/type.service';
import { InterpolateFn, LABELS } from './error.type';

@Injectable()
export class ErrorService {
  private _notAllowedErrorCodes: Set<ErrorCodes>;
  private _allowedErrorCodes: Set<ErrorCodes>;

  constructor(private _dialog: DialogService, private _type: TypeService) {
    this._notAllowedErrorCodes = new Set([
      ErrorCodes.PosNoStock,
      ErrorCodes.PosUnknownProducts,
      ErrorCodes.TemporarilyUnavailable,
    ]);

    this._allowedErrorCodes = new Set([
      ErrorCodes.GenericPreCheckError,
      ErrorCodes.OrderNotAvailable,
      ErrorCodes.CreditCardExpired,
      ErrorCodes.DeliveryFeeIsNotSupportedWithMultiKitchens,
      ErrorCodes.PaymentAmountExceededTotalAmountToPay,
      ErrorCodes.LoyaltyAddOnlyOnFreeItem,
      ErrorCodes.LoyaltyCodeNotFound,
      ErrorCodes.LoyaltyCodeAlreadyUsed,
      ErrorCodes.LoyaltyHadOrdersAlready,
      ErrorCodes.LoyaltyInvitedNotConnected,
      ErrorCodes.LoyaltySamePhoneWithSponsor,
      ErrorCodes.LoyaltyInProgressByAnotherInvited,
      ErrorCodes.LoyaltyRevoked,
      ErrorCodes.LoyaltyNotUsableYet,
    ]);
  }

  show<T>(fn?: InterpolateFn): OperatorFunction<T, T> {
    return catchError(error =>
      combineLatest([this._type.app, this._type.kitchen]).pipe(
        first(),
        /**
         * get application type and kitchen type
         */
        switchMap(() => {
          const { errorCode, data } = error;

          if (this._notAllowedErrorCodes.has(errorCode)) return throwError(() => error);
          /**
           * check if error is allowed to show
           */
          if (this._allowedErrorCodes.has(errorCode)) {
            /**
             * check if we have interpolate data function
             */
            const interpolateData = fn ? fn({ errorCode, data }) : null;

            this._dialog.error({
              /**
               * get label by errorCode
               */
              message: LABELS[errorCode],
              /**
               * check if exist interpolateData
               */
              ...(interpolateData && { interpolateData }),
            });
          } else {
            /**
             * if is unexpected error show generic error
             */
            this._dialog.error({ message: ErrorCodes.GenericError });
          }

          return throwError(() => error);
        }),
      ),
    );
  }
}
