import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() stroke: ThemePalette = 'primary';

  // Temporary solution while we don't implement mat-spinner
  // stroke color FOR primary | accent | warn
  // On primary color button the primary stroke are invisible, so here an 'inversion'
  strokeColor = {
    primary: 'white',
    accent: 'var(--var-color-accent-light)',
    warn: 'white',
  };
}
