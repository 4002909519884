import { ChangeDetectionStrategy, Component, Input, OnInit, TrackByFunction } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';

import { OrderItem, OrderItemType, OrderLocation } from '@bend/store-shared';

import { OrderUserService } from '../../../cart-services';

@Component({
  selector: 'lib-board-order-locations [orderUserId] [locations]',
  templateUrl: './board-order-locations.component.html',
  styleUrls: ['./board-order-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrderLocationsComponent implements OnInit {
  @Input() orderUserId: number;
  @Input() locations: OrderLocation[];

  items$: Observable<OrderItem<OrderItemType.Single>[]>;

  trackByFn: TrackByFunction<OrderItem<OrderItemType.Single>> = (_, item) => item.id;

  constructor(private _orderUser: OrderUserService) {}

  ngOnInit(): void {
    this.items$ = this._items;
  }

  private get _items(): Observable<OrderItem<OrderItemType.Single>[]> {
    return combineLatest(
      this.locations.map(location => this._orderUser.singleItemsByLocation(this.orderUserId, location.id)),
    ).pipe(map(itemsByLocations => itemsByLocations.flat()));
  }
}
