import { createAction, props } from '@ngrx/store';

import { createType, OptionGroup } from '../shared';
import { storeKey } from './option-group.config';

const type = createType(storeKey);

export const actions = {
  addOptionGroups: createAction(type('add option group'), props<{ entities: OptionGroup[] }>()),
  getOptionGroups: createAction(type('get'), props<{ productId: number }>()),
  getOptionGroupsSuccess: createAction(type('get - success'), props<{ entities: OptionGroup[] }>()),
  getOptionGroupsIsLoaded: createAction(type('get - is loaded')),
  getOptionGroupsError: createAction(type('get - error'), props<{ errorCode: string }>()),

  reset: createAction(type('reset')),
};
