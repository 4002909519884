import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';

import { appsActions, AppsState } from '@designer-store/apps';
import * as appsSelectors from '@designer-store/apps/apps.selectors';
import { AppsHttpService } from '@designer-store/apps/apps-http.service';

@Injectable({ providedIn: 'root' })
export class AppsEffects {
  constructor(private actions$: Actions, private _store: Store<AppsState>, private _appsHttp: AppsHttpService) {}

  onGetApps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appsActions.getApps),
      withLatestFrom(this._store.select(appsSelectors.apps)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, isLoaded]) =>
        isLoaded
          ? of(appsActions.getAppsLoaded())
          : this._appsHttp.getApplicationsList().pipe(
              map(applications => appsActions.getAppsSuccess({ applications })),
              catchError(({ errorCode }) => of(appsActions.getAppsError({ error: errorCode }))),
            ),
      ),
    );
  });
}
