import { GetSmiles } from './smiles.type';

export const storeKey = 'smiles';

export const byKey = ({ skip, limit, search }: GetSmiles) => {
  let key = `${skip}-${limit}`;

  if (search) key += `-${search}`;
  return key;
};
