import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

@Injectable()
export class WidgetReviewService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  getMyReview(reviewId: string): Observable<any> {
    return this._api.myReview('v1', reviewId).pipe(switchMap(api => this._http.get(api)));
  }

  review(reviewId: string, review: any): Observable<any> {
    return this._api.review('v1', reviewId).pipe(switchMap(api => this._http.post(api, review)));
  }
}
