import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { OrderService } from '@bend/store/src/lib/order';
import { ParamsService } from '@bend/store/src/lib/params';
import { SessionHttpService } from '@bend/store-shared';

import { OrderCheckUpdateService } from '../order-check-update/order-check-update.service';
import { OrderSocketService } from '../order-socket/order-socket.service';

@Injectable()
export class SessionService {
  private _subscription: Subscription;

  constructor(
    private _params: ParamsService,
    private _order: OrderService,
    private _sessionHttp: SessionHttpService,
    private _orderSocket: OrderSocketService,
    private _orderCheckUpdate: OrderCheckUpdateService,
  ) {}

  init(): void {
    if (this._subscription && !this._subscription.closed) return;

    this._subscription = this._params
      .queryParams(['place', '_preview'])
      .pipe(
        filter(([, preview]) => preview !== 'true'),
        switchMap(([place]) => this._sessionHttp.create(place)),
        tap(() => this._orderSocket.init()),
        tap(() => this._orderCheckUpdate.init()),
        tap(() => this._order.getOrder()),
      )
      .subscribe();
  }

  // NOTE(leonid): this_sessionHttp.create() will init OrderUser or take existent.
  //               It will cancel existing transactions that are not yet completed.
  reset(): void {
    this._params
      .queryParams(['place'])
      .pipe(switchMap(([place]) => this._sessionHttp.create(place)))
      .subscribe();
  }

  close(): void {
    if (this._subscription) this._subscription.unsubscribe();
    this._order.reset();
    this._orderSocket.close();
    this._orderCheckUpdate.close();
  }
}
