import { Observable } from 'rxjs';

import { ErrorCodes } from '../../../../types';

export type InterpolateFn = (
  params: ErrorParams,
) => Dictionary<string | number> | Observable<Dictionary<string | number>> | void;

export interface ErrorParams {
  errorCode: ErrorCodes;
  data: Dictionary<any>;
}

export const LABELS: Partial<Record<ErrorCodes, string>> = {
  [ErrorCodes.GenericPreCheckError]: ErrorCodes.GenericPreCheckError,
  [ErrorCodes.OrderNotAvailable]: `CART.${ErrorCodes.OrderNotAvailable}`,
  [ErrorCodes.DeliveryFeeIsNotSupportedWithMultiKitchens]: `CART.${ErrorCodes.DeliveryFeeIsNotSupportedWithMultiKitchens}`,
  [ErrorCodes.LoyaltyAddOnlyOnFreeItem]: `CART.${ErrorCodes.LoyaltyAddOnlyOnFreeItem}`,
  [ErrorCodes.LoyaltyCodeNotFound]: `CART.${ErrorCodes.LoyaltyCodeNotFound}`,
  [ErrorCodes.LoyaltyCodeAlreadyUsed]: `CART.${ErrorCodes.LoyaltyCodeAlreadyUsed}`,
  [ErrorCodes.LoyaltyHadOrdersAlready]: `CART.${ErrorCodes.LoyaltyHadOrdersAlready}`,
  [ErrorCodes.LoyaltyInvitedNotConnected]: `CART.${ErrorCodes.LoyaltyInvitedNotConnected}`,
  [ErrorCodes.LoyaltySamePhoneWithSponsor]: `CART.${ErrorCodes.LoyaltySamePhoneWithSponsor}`,
  [ErrorCodes.LoyaltyInProgressByAnotherInvited]: `CART.${ErrorCodes.LoyaltyInProgressByAnotherInvited}`,
  [ErrorCodes.LoyaltyNotUsableYet]: `CART.${ErrorCodes.LoyaltyNotUsableYet}`,
  [ErrorCodes.LoyaltyRevoked]: `CART.${ErrorCodes.LoyaltyRevoked}`,
  [ErrorCodes.CreditCardExpired]: `CART.DIALOG.PAYMENT.${ErrorCodes.CreditCardExpired}`,
  [ErrorCodes.PaymentAmountExceededTotalAmountToPay]: `CART.${ErrorCodes.PaymentAmountExceededTotalAmountToPay}`,
};
