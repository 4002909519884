export interface BottomBar {
  display: boolean;
}

export interface BottomBarButton {
  icon: string;
  link: string;
  title: string;
  type: ButtonType;
}

export enum ButtonType {
  Review = 'Review',
  Order = 'Order',
  Pay = 'Pay',
  Amenities = 'Amenities',
  Program = 'Program',
  Custom = 'Custom',
  Tips = 'tips',
  Details = 'details',
}
