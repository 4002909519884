<app-input type="url" [formControl]="control" placeholder="URL"></app-input>
<pop-widget-edit-page-selector
  *ngIf="pages?.length"
  (pageChangeEmmiter)="control.setValue($event['_id'])"
  [selectedPageId]="control.value"
  [pages]="pages"
></pop-widget-edit-page-selector>
<span class="label label-small">
  {{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:
  <span class="info">{{ pageLink }}</span>
</span>
<div class="info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</div>
