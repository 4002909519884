import { HappyHoursInterval } from '../types';

/**
 *
 * @returns
 * from:
 * [
 *   {open: '10:00', close: '12:00'},
 * ]
 *
 * to:
 * [
 *   {open: '2021-09-01T10:00:00.000Z', close: '2021-09-01T12:00:00.000Z'}
 * ]
 * */
export function convertTimeStringToIsoString(inputData: HappyHoursInterval<string>[]): HappyHoursInterval<string>[] {
  const outputData = [];

  for (const item of inputData) {
    const convertedItem = {
      open: convertToISO(item.open),
      close: convertToISO(item.close),
    };
    outputData.push(convertedItem);
  }

  return outputData;
}

function convertToISO(timeStr: string): string {
  const [hours, minutes] = timeStr.split(':');
  const now = new Date();
  now.setHours(parseInt(hours, 10));
  now.setMinutes(parseInt(minutes, 10));
  return now.toISOString();
}
