<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <input id="showOnlyMyQuestions" type="checkbox" formControlName="showOnlyMyQuestions" />
            <label for="showOnlyMyQuestions" class="form-label form-label--checkbox">
              {{ 'WIDGETS.ASK_QUESTION.SHOW_QUESTIONS' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <input id="hideQuestions" type="checkbox" formControlName="hideQuestions" />
            <label for="hideQuestions" class="form-label form-label--checkbox">
              {{ 'WIDGETS.ASK_QUESTION.HIDE_QUESTIONS' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <span class="form-label section-label">
      {{ 'WIDGETS.SHARED.LABELS' | translate }}
    </span>
    <div class="form-row-cols-wrapper settings-row">
      <div class="form-col-line" formGroupName="labels" *ngFor="let label of labels">
        <label for="{{ label.key }}" class="form-label form-label--print">
          {{ labelsNames[label.key] | translate }}
        </label>
        <input
          id="{{ label.key }}"
          formControlName="{{ label.key }}"
          type="text"
          class="form-input form-input--print"
        />
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
