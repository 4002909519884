<ng-container *ngIf="menu.quantity === 1; else menuWithQuantity">{{ menu.name }}</ng-container>

<ng-template #menuWithQuantity>{{ menu.quantity }} x {{ menu.name }}</ng-template>

<lib-items-wrapper *ngIf="menu.options.length">
  <lib-board-order-item-option
    *ngFor="let option of menu.options; trackBy: trackByFn"
    [option]="option"
  ></lib-board-order-item-option>
</lib-items-wrapper>
