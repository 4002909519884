import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsConsultingMode } from '@visitor-store';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, tap } from 'rxjs/operators';

import { xCenterScrollTo } from '@bend/shared-widgets/src/lib/helpers';
import { CatalogIsReadOnlyService } from '@bend/shared-widgets/src/lib/shared/catalog-services';
import { CatalogReloadService } from '@bend/shared-widgets/src/public-api';
import {
  CollectType,
  ItemUnavailabilityDisplay,
  ParamsService,
  SettingsService,
  UserSocketUpdatesService,
  WorkingHoursStoreService,
} from '@bend/store';
import { CategoryStyleType } from '@bend/store-shared';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopCurrency, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { PopCatalog, WidgetProductCatalog } from './product-catalog';

@Component({
  selector: 'pop-widget-product-catalog',
  templateUrl: './product-catalog.component.html',
  styleUrls: ['./product-catalog.component.scss'],
})
export class WidgetProductCatalogComponent
  implements OnInit, OnDestroy, AfterViewInit, WidgetComponent, AfterViewChecked
{
  static widgetName = 'product_catalog';
  @Input() attributes: WidgetProductCatalog;
  isValid: boolean;
  noValidMessage: string;
  currentCatalog: PopCatalog;
  catalogId: PopCatalog;
  isReadOnly: boolean;
  isPopValidated: boolean;
  productWidgetStyleType: string;
  menuProductWidgetStyleType: string;
  styleType: number;
  categoryStyleType: number;
  isConsultingMode$: Observable<boolean>;
  itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  isTopBarVisibleProp: string = sessionStorage.getItem('topBarVisible');
  showCatalogsOnTop$: Observable<boolean>;
  categoryStyleTypes = CategoryStyleType;
  @ViewChild('contentWrapperEl') contentWrapperEl: ElementRef;
  @ViewChildren('catalogs') catalogs!: QueryList<ElementRef>;
  @ViewChild('scroll') scrollEl: ElementRef;

  private readonly _ONLINE_TYPES = new Set<CollectType>([CollectType.Delivery, CollectType.TakeAway]);

  private _subscription: Subscription = new Subscription();
  private _isReadOnlySubscription: Subscription;
  private componentDestroyed = new Subject<void>();
  private _showCatalogsOnTop: Subject<boolean> = new Subject<boolean>();

  trackByFn: TrackByFunction<PopCatalog> = (_, item): string => item.catalogId;

  constructor(
    private _warningService: WarningService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _params: ParamsService,
    private _cdr: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _workingHoursStore: WorkingHoursStoreService,
    private _catalogIsReadOnly: CatalogIsReadOnlyService,
    private _catalogReload: CatalogReloadService,
    private _userSocketUpdate: UserSocketUpdatesService,
    private _store: Store,
    private readonly _settings: SettingsService,
    private route: ActivatedRoute,
  ) {
    this.showCatalogsOnTop$ = this._showCatalogsOnTop.asObservable().pipe(distinctUntilChanged());
  }

  ngOnInit(): void {
    this.kioskModeRedirect();

    if (!this.attributes || !this.attributes.catalogs) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetProductCatalogComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }
    this._addMissingAttributes(this.attributes);
    this.styleType = this.attributes.styleConfig ? this.attributes.styleConfig.style : 1;
    this.categoryStyleType = this.attributes.styleConfig ? this.attributes.styleConfig.categoryStyleType : 1;
    this.isReadOnly = false;
    this.isValid = true;
    this.isPopValidated = this.attributes.pop.validationStatus !== PopValidationStatus.FAILED;
    this.itemUnavailabilityDisplay =
      this.attributes.itemUnavailabilityDisplay || this.attributes.styleConfig.itemUnavailabilityDisplay;
    this._setProductWidgetStyleType();
    this._setMenuProductWidgetStyleType();
    // This if code block is temporary, to be removed after authorize admin user to access shop-api
    if (this.attributes.isEditMode) {
      this.currentCatalog = {} as unknown as PopCatalog;

      return;
    }
    this._subscription.add(this.catalogAutoselect());
    this._subscription.add(this._refreshCatalogOnUpdate());

    this.isConsultingMode$ = this._store
      .select(selectIsConsultingMode)
      .pipe(
        map(isConsultingMode =>
          isConsultingMode
            ? isConsultingMode
            : this.attributes.isReadOnly !== null
              ? this.attributes.isReadOnly
              : false,
        ),
      );
  }

  ngAfterViewInit(): void {
    this._subscription.add(this._scrollTopOnNavigate().subscribe());
    this._subscription.add(this._scrollToSelectedCatalog().subscribe());
  }

  ngAfterViewChecked(): void {
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this._subscription.unsubscribe();
    if (this._isReadOnlySubscription) this._isReadOnlySubscription.unsubscribe();
  }

  catalogAutoselect(): Subscription {
    return this._params.queryParamsChanges().subscribe(({ catalogId }) => {
      if (catalogId && catalogId < this.attributes.catalogs.length) {
        this.selectCatalog(+catalogId);
      } else {
        this.selectDefaultCatalog();
      }
      this._cdr.detectChanges();
    });
  }

  selectDefaultCatalog(): void {
    const index = this.attributes.catalogs.findIndex(catalog => catalog.isDefault);
    if (index !== -1) {
      this.selectCatalog(index);
    } else if (this.attributes.catalogs.length) {
      this.selectCatalog(0);
    }
  }

  getStyleCurrentCatalog(uniqueId: number): string {
    return uniqueId === this.currentCatalog.uniqueId ? this.attributes.secondColor : 'grey';
  }

  selectCatalog(index: number): void {
    // This if code block is temporary, to be removed after authorize admin user to access shop-api
    if (!this.attributes.isEditMode) {
      const newCatalog = this.attributes.catalogs[index];
      this.currentCatalog = newCatalog;
      this.currentCatalog.uniqueId = index;
      this._workingHoursStore.getWorkingHours(+this.currentCatalog.catalogId);
      if (this._isReadOnlySubscription) {
        this._isReadOnlySubscription.unsubscribe();
      }
      this._isReadOnlySubscription = this._checkIsReadOnly(+this.currentCatalog.catalogId);
    }
  }

  private _refreshCatalogOnUpdate(): Subscription {
    return this._userSocketUpdate.catalog().subscribe(() => this._catalogReload.reloadCatalog());
  }

  private _checkIsReadOnly(catalogId: number): Subscription {
    return combineLatest([
      this._catalogIsReadOnly.getIsReadOnly(catalogId).pipe(distinctUntilChanged()),
      this._settings.collectTypes,
      this._settings.orderForAnotherDay,
    ]).subscribe(([isReadOnly, collectTypes, orderForAnotherDay]) => {
      /**
       * do not block the ability to add an item to the cart
       * if the current order is of the collect type Delivery or TakeAway
       * and can also be made for another day
       */
      this.isReadOnly =
        collectTypes.some(collect => this._ONLINE_TYPES.has(collect)) && orderForAnotherDay ? false : isReadOnly;
      this._cdr.detectChanges();
    });
  }

  private _scrollToSelectedCatalog(): Observable<number> {
    return this._params.queryParamsChanges().pipe(
      map(({ catalogId }) => catalogId),
      distinctUntilChanged(),
      map(index => (!isNaN(index) ? +index : this.attributes.catalogs.findIndex(catalog => catalog.isDefault) || 0)),
      tap(index => {
        const catalogEl = this.catalogs?.find((_, i) => i === index);

        if (catalogEl) xCenterScrollTo(catalogEl.nativeElement);
      }),
    );
  }

  changeCatalog(catalogId: number): void {
    this._params.queryParams().subscribe(({ catalogId: catalogIdActive }) => {
      const queryParams = {
        catalogId,
        ...(catalogId !== Number(catalogIdActive) && { categoryId: null }),
      };

      this._router.navigate([], {
        queryParams,
        relativeTo: this._route,
        queryParamsHandling: 'merge',
      });

      this._cdr.detectChanges();
    });
  }

  displayNoDataWarn(): void {
    this.noValidMessage = this._warningService.showWarn(WidgetProductCatalogComponent.widgetName, WarnTypeEnum.NoData);
  }

  private _addMissingAttributes(attributes: WidgetProductCatalog): void {
    if (!attributes.currency) {
      attributes.currency = new PopCurrency();
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }
  }

  private _setProductWidgetStyleType(): void {
    switch (this.styleType) {
      case 1:
        this.productWidgetStyleType = 'product-widget__v2 classic';
        break;
      case 2:
        this.productWidgetStyleType = 'product-widget__v2';
        break;
      case 3:
        this.productWidgetStyleType = 'product-widget__v2 big-image';
        break;
      case 4:
        this.productWidgetStyleType = 'product-widget__v2 big-image side-by-side';
    }
  }

  private _setMenuProductWidgetStyleType(): void {
    switch (this.attributes.styleConfig.menuType) {
      case 0:
        this.menuProductWidgetStyleType = this.productWidgetStyleType;
        break;
      case 1:
        this.menuProductWidgetStyleType = 'product-widget__v2 big-image side-by-side';
    }
  }

  private _scrollTopOnNavigate(): Observable<any> {
    return this._router.events.pipe(
      // TODO: remove any
      filter<any>(event => event instanceof NavigationEnd),
      // Due to update rxjs to version 7
      startWith({ url: this._router.url, urlAfterRedirects: null, id: null } as NavigationEnd),
      pairwise<NavigationEnd>(),
      filter(([prev, curr]) => prev.url !== curr.url && prev.url.replace(/\?.*/, '') === curr.url.replace(/\?.*/, '')),
      tap(() => window.scrollTo({ behavior: 'smooth', top: this._offsetTop })),
    );
  }

  private get _offsetTop(): number {
    let navbarHeight = 0;

    if (this.attributes.styleConfig.categoriesStyle === 2) {
      const categoriesNavbarHeight = document.getElementById('categoriesNavbar')?.clientHeight || 0;

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      navbarHeight = categoriesNavbarHeight + this.contentWrapperEl.nativeElement.clientHeight;
    }

    return this._elementRef.nativeElement.offsetTop - 1 - navbarHeight;
  }

  private kioskModeRedirect(): void {
    this._settings.kioskMode.subscribe(kiosk => {
      kiosk && this._router.navigate(['kiosk'], { queryParamsHandling: 'merge', relativeTo: this.route });
    });
  }
}
