import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MenuService {
  showStepByStepMenu: Observable<boolean>;
  private _showStepByStepMenu: BehaviorSubject<boolean>;

  constructor() {
    this._showStepByStepMenu = new BehaviorSubject<boolean>(false);
    this.showStepByStepMenu = this._showStepByStepMenu.asObservable();
  }

  toggleStepperStatus(): void {
    this._showStepByStepMenu.next(!this._showStepByStepMenu.value);
  }

  closeStepper(): void {
    this._showStepByStepMenu.next(false);
  }
}
