export interface Twilio {
  authToken?: string;
  accountSid: string;
  defaultFrom: string;
}

export interface Callr {
  login: string;
  password?: string;
  defaultFrom: string;
}

export interface SMSSettings {
  provider: SMSProvider;
  [SMSProvider.twilio]: Twilio;
  [SMSProvider.callr]: Callr;
}

export enum SMSProvider {
  twilio = 'twilio',
  callr = 'callr',
}
