import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions as itemsActions } from '../order-items/order-items.actions';
import * as itemSelectors from '../order-items/order-items.selectors';
import { actions as locationsActions } from '../order-locations/order-locations.actions';
import * as locationSelectors from '../order-locations/order-locations.selectors';
import { actions as menuItemsActions } from '../order-menu-items/order-menu-items.actions';
import * as menuSelectors from '../order-menu-items/order-menu-items.selectors';
import { actions as itemsOptionsActions } from '../order-options/order-options.actions';
import * as optionsSelectors from '../order-options/order-options.selectors';
import { actions as usersActions } from '../order-users/order-users.actions';
import * as settingsSelectors from '../settings/settings.selectors';
import { actions } from './order.actions';
import { OrderHttpService } from './order.http.service';
import * as selectors from './order.selectors';
import { State } from './order.type';

@Injectable()
export class OrderEffects {
  loadOrders$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getOrders),
      // add is loaded order
      switchMap(() => this._store.pipe(select(settingsSelectors.selectedId))),
      mergeMap(place =>
        this._order.getAll(place).pipe(
          mergeMap(({ orders, orderUsers, orderLocations, orderItems, orderItemOptions, orderMenuItems }) => [
            itemsActions.upsertOrderItems({ orderItems }),
            itemsOptionsActions.upsertOrderItemOptions({ orderItemOptions }),
            menuItemsActions.upsertOrderMenuItems({ orderMenuItems }),
            locationsActions.upsertOrderLocations({ orderLocations }),
            usersActions.updateOrderUsers({ orderUsers }),
            actions.getOrdersSuccess({ orders }),
          ]),
          catchError(({ errorCode }) => of(actions.getOrdersError({ errorCode }))),
        ),
      ),
    ),
  );

  loadOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getOrder),
      // add is loaded order
      withLatestFrom(this._store.pipe(select(selectors.isLoaded))),
      mergeMap(([, isLoaded]) =>
        isLoaded
          ? // order is loaded
            of(actions.getOrderIsLoaded())
          : // order is not loaded
            this._order.get().pipe(
              mergeMap(({ order, orderUsers, orderLocations, orderItems, orderItemOptions, orderMenuItems }) => [
                itemsActions.addOrderItems({ orderItems }),
                itemsOptionsActions.addOrderItemOptions({ orderItemOptions }),
                menuItemsActions.addOrderMenuItems({ orderMenuItems }),
                locationsActions.upsertOrderLocations({ orderLocations }),
                usersActions.addOrderUsers({ orderUsers }),
                actions.getOrderSuccess({ order }),
              ]),
              catchError(({ errorCode }) => of(actions.getOrderError({ errorCode }))),
            ),
      ),
    ),
  );

  updateOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateOrder),
      concatMap(action => of(action).pipe(withLatestFrom(this._store.pipe(select(selectors.currentUpdated))))),
      mergeMap(([newUpdated, updated]) =>
        newUpdated.id === updated.id && newUpdated.updatedAt.getTime() === updated.updatedAt.getTime()
          ? of(actions.updateOrderIsUpdated({ id: newUpdated.id }))
          : this._order.get().pipe(
              /**
               * @description we need orders data for merge with current order
               */
              withLatestFrom(
                this._store.pipe(select(itemSelectors.all)),
                this._store.pipe(select(locationSelectors.all)),
                this._store.pipe(select(menuSelectors.all)),
                this._store.pipe(select(optionsSelectors.all)),
              ),
              mergeMap(
                ([
                  { order, orderUsers, orderLocations, orderItems, orderItemOptions, orderMenuItems },
                  currentItems,
                  currentLocations,
                  currentMenus,
                  currentOptions,
                ]) => [
                  /**
                   * @description from all orders we remove current order and merge with current updated order
                   * this is for remove one or all items from order
                   */
                  itemsActions.updateOrderItems({
                    orderItems: [...currentItems.filter(item => item.orderId !== order.id), ...orderItems],
                  }),
                  itemsOptionsActions.updateOrderItemOptions({
                    orderItemOptions: [
                      ...currentOptions.filter(option => option.orderId !== order.id),
                      ...orderItemOptions,
                    ],
                  }),
                  menuItemsActions.updateOrderMenuItems({
                    orderMenuItems: [...currentMenus.filter(menu => menu.orderId !== order.id), ...orderMenuItems],
                  }),
                  locationsActions.updateOrderLocations({
                    orderLocations: [
                      ...currentLocations.filter(location => location.orderId !== order.id),
                      ...orderLocations,
                    ],
                  }),
                  usersActions.updateOrderUsers({ orderUsers }),
                  actions.updateOrderSuccess({ id: newUpdated.id, changes: order }),
                ],
              ),
              catchError(({ errorCode }) =>
                of(actions.updateOrderError({ id: newUpdated.id, changes: { errorCode } })),
              ),
            ),
      ),
    ),
  );

  updateOrderItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateOrderItem),
      mergeMap(({ id, itemId, quantity }) =>
        this._order.updateOrderItem(itemId, quantity).pipe(
          mergeMap(({ updatedAt }) => [
            actions.updateOrderItemSuccess({ id, changes: { updatedAt } }),
            itemsActions.updateOrderItem({ orderItem: { id: itemId, changes: { quantity } } }),
          ]),
          catchError(({ errorCode }) => of(actions.updateOrderItemError({ id, changes: { errorCode } }))),
        ),
      ),
    ),
  );

  addOrderDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateOrderDetails),
      mergeMap(({ id, details }) =>
        this._order.addOrderDetails(details).pipe(
          map(() => actions.updateOrderDetailsSuccess({ id, changes: { details } })),
          catchError(({ errorCode }) => of(actions.updateOrderDetailsError({ id, changes: { errorCode } }))),
        ),
      ),
    ),
  );

  addCustomerInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateOrderUserDetails),
      mergeMap(({ id, customerInfo }) =>
        this._order.addOrderUserDetails(customerInfo).pipe(
          map(() => actions.updateOrderUserDetailsSuccess({ id, changes: { customerInfo } })),
          catchError(({ errorCode }) => of(actions.updateOrderUserDetailsError({ id, changes: { errorCode } }))),
        ),
      ),
    ),
  );

  removeOrderItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.removeOrderItem),
      mergeMap(({ id, itemId, orderUserId }) =>
        this._order.removeOrderItem(itemId).pipe(
          mergeMap(orderUpdated => [
            actions.removeOrderItemSuccess({ id, changes: orderUpdated }),
            itemsActions.removeOrderItem({ orderUserId, orderItemId: itemId }),
          ]),
          catchError(({ errorCode }) => of(actions.removeOrderItemError({ id, changes: { errorCode } }))),
        ),
      ),
    ),
  );

  newOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.newOrder),
      mergeMap(() =>
        this._order.newOrder().pipe(
          map(() => actions.getOrder()),
          catchError(({ errorCode }) => of(actions.newOrderError({ errorCode }))),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _order: OrderHttpService, private _store: Store<State>) {}
}
