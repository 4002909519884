import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CatalogsListComponent } from '../../../views/catalogs/components/catalogs-list/catalogs-list.component';
import { CatalogsService } from '../../../views/catalogs/services';
import { SnackBarService } from '../../services';
import { PopCatalogForDuplicate, SuccessCode } from '../../types';

@Component({
  selector: 'designer-duplicate-catalog',
  templateUrl: './duplicate-catalog.component.html',
  styleUrls: ['./duplicate-catalog.component.scss'],
})
export class DuplicateCatalogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  moveCatalogToApp: boolean;
  isSaving: boolean;
  appSlug: string;
  private _subscriptions: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DuplicateCatalogComponent>,
    private _catalogsService: CatalogsService,
    private _formBuilder: UntypedFormBuilder,
    private _snackBarService: SnackBarService,
  ) {
    this._subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this.appSlug = this.data.appSlug;
    this.moveCatalogToApp = false;
    this._initForm();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      appSlug: ['', [Validators.required, Validators.pattern('[0-9a-z-]+')]],
      moveCatalog: false,
      copyTvas: false,
      copyTags: false,
    });
    this.form.get('appSlug').disable();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.moveCatalogToApp ? this._copyCatalogToAnotherApp() : this._duplicateCatalog();
  }

  changeValidatorStatus(): void {
    this.moveCatalogToApp = !this.moveCatalogToApp;

    this.moveCatalogToApp ? this.form.get('appSlug').enable() : this.form.get('appSlug').disable();
  }

  private _close(): void {
    this.isSaving = false;
    this.dialogRef.close();
  }

  private _duplicateCatalog(): void {
    this._subscriptions.add(
      this._catalogsService.duplicateCatalog(this._parrams).subscribe({
        next: catalog => {
          this._snackBarService.success(SuccessCode.DUPLICATED);
          CatalogsListComponent.created.next(catalog);
          this._close();
        },
        error: error => this._showError(error),
      }),
    );
  }

  private _copyCatalogToAnotherApp(): void {
    this._subscriptions.add(
      this._catalogsService.copyCatalogToAnotherApp(this._parrams).subscribe({
        next: () => {
          this._snackBarService.success(SuccessCode.DUPLICATED);
          this._close();
        },
        error: error => this._showError(error),
      }),
    );
  }

  private _showError(error: any): void {
    this._close();
    this._snackBarService.error(error?.error?.error?.message || 'GENERIC_ERROR');
  }

  private get _parrams(): PopCatalogForDuplicate {
    const { id } = this.data;
    const { name, ...rest } = this.form.value;

    return {
      catalogs: [{ id, name }],
      ...rest,
    };
  }
}
