import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { appear, itemHide, ngIf } from '@bend/animations/src/lib/animations';

import { EmojiPickerPosition } from '../../interfaces';
import { TranslateWrapperService } from '../../services/translate-wrapper.service';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ngIf, itemHide, appear],
})
export class EmojiPickerComponent {
  @Input() inputControl: UntypedFormControl;
  @Input() pickerPosition: EmojiPickerPosition = 'right';
  @Input() titleEmoji = '😄';
  showPicker: boolean;

  private _hoverListener: Subject<void>;

  isRtl$: Observable<boolean>;

  constructor(private _cdr: ChangeDetectorRef, private _translate: TranslateWrapperService) {
    this.showPicker = false;
    this._hoverListener = new Subject<void>();
    this.isRtl$ = this._translate.isRtlTextDirection;
  }

  onPickEmoji(event: EmojiEvent) {
    this.inputControl.patchValue(`${this.inputControl.value}${event.emoji.native}`);
  }

  openPicker() {
    this._hoverListener.next();
    this.showPicker = true;
  }

  closePicker() {
    timer(400)
      .pipe(
        takeUntil(this._hoverListener),
        tap(() => {
          this.showPicker = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }
}
