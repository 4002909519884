import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { WidgetService } from '../../../widgets/widget.service';
import { Widget, WidgetBase } from '../../models';
import { SnackBarService, WidgetFormService } from '../../services';

@Component({
  selector: '',
  template: '',
})
export class WidgetBaseComponent<T extends Widget> implements WidgetBase<T> {
  @Input() widget: T;
  @Output() update = new EventEmitter<any>();
  @Output() publish = new EventEmitter<any>();

  form: UntypedFormGroup;
  showErrors: boolean;
  constructor(
    private _widgetService: WidgetService,
    public _widgetFormService: WidgetFormService,
    private _snackbarService: SnackBarService,
  ) {}

  onSubmit(): void {
    const { valid, value } = this._widgetFormService.getAllControls;

    if (!valid) {
      this.showErrors = true;
      return;
    }

    this._widgetService.updateWidget(this.widget._id, value).subscribe({
      next: result => {
        this.update.emit(result);
        this._snackbarService.showSuccessMessage('The widget was updated successfully.');
      },
      error: () => this._snackbarService.showErrorMessage('Something went wrong.'),
    });
  }

  onSaveAndPublish(): void {
    const { valid, value } = this._widgetFormService.getAllControls;

    if (!valid) {
      this.showErrors = true;
      return;
    }

    this._widgetService.updateWidget(this.widget._id, { ...value, published: true }).subscribe({
      next: result => {
        this.update.emit(result);
        this.publish.emit();
        this._snackbarService.showSuccessMessage('The widget was updated successfully.');
      },
      error: () => this._snackbarService.showErrorMessage('Something went wrong.'),
    });
  }
}
