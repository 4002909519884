import { Component, HostBinding, Inject } from '@angular/core';

import { WidgetConfig } from '../types';
import { WIDGET_CONFIG } from './widget-config.helper';

@Component({ template: '' })
export abstract class Widget {
  @HostBinding('style.display') display: string;
  @HostBinding('style.overflow') overflow: string;

  @HostBinding('style.height') height: string;
  @HostBinding('style.margin-top') marginTop: string;
  @HostBinding('style.margin-right') marginRight: string;
  @HostBinding('style.margin-bottom') marginBottom: string;
  @HostBinding('style.margin-left') marginLeft: string;
  @HostBinding('style.border-width') borderWidth: string | number;
  @HostBinding('style.border-radius') borderRadius: string | number;
  @HostBinding('style.border-style') borderStyle: string;
  @HostBinding('style.border-color') borderColor: string;
  @HostBinding('style.background') background: string;
  @HostBinding('style.box-shadow') boxShadow: string;

  constructor(@Inject(WIDGET_CONFIG) protected _config: WidgetConfig) {
    this.display = 'flex';
    this.overflow = 'hidden';

    this.height = this._config.style.height || 'initial';
    this.marginTop = this._config.style.margin?.top || 'initial';
    this.marginRight = this._config.style.margin?.right || 'initial';
    this.marginBottom = this._config.style.margin?.bottom || 'initial';
    this.marginLeft = this._config.style.margin?.left || 'initial';
    this.borderWidth = this._config.style.border?.width || 0;
    this.borderRadius = this._config.style.border?.radius || 0;
    this.borderStyle = this._config.style.border?.style || 'solid';
    this.borderColor = this._config.style.border?.color || '#000';
    this.background = this._config.style.background || '#fff';
    this.boxShadow = this._config.style.boxShadow || 'none';
  }
}
