import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './image-gallery.actions';
import { ImageGalleryHttpService } from './image-gallery.http.service';
import * as selectors from './image-gallery.selectors';
import { State } from './image-gallery.type';

@Injectable()
export class ImageGalleryEffects {
  constructor(
    private _httpService: ImageGalleryHttpService,
    private _store: Store<State>,
    private _actions$: Actions,
  ) {}

  getApplications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getApplications),
      withLatestFrom(this._store.select(selectors.isAppsLoaded)),
      switchMap(([{ fileTypes }, isAppsLoaded]) =>
        isAppsLoaded
          ? of(actions.getApplicationsLoaded())
          : this._httpService.getApplicationsList(fileTypes).pipe(
              map(apps => actions.getApplicationsSuccess({ apps })),
              catchError(({ errorCode }) => of(actions.getApplicationsError({ errorCode }))),
            ),
      ),
    ),
  );

  getImagesByApp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getImagesByApp),
      withLatestFrom(this._store.select(selectors.currentApp), this._store.select(selectors.imagesLoadedByApp)),
      switchMap(([{ fileTypes }, currentApp, imagesLoadedByApp]) =>
        imagesLoadedByApp.includes(currentApp)
          ? of(actions.getImagesByAppLoaded())
          : this._httpService.getAppImages(currentApp, fileTypes).pipe(
              map(images => actions.getImagesByAppSuccess({ images })),
              catchError(({ errorCode }) => of(actions.getImagesByAppError({ errorCode }))),
            ),
      ),
    ),
  );

  uploadImages$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.uploadImages),
      withLatestFrom(this._store.select(selectors.defaultApp)),
      switchMap(([{ files }, app]) =>
        this._httpService.uploadMultipleImages(files).pipe(
          map(imgEvent => {
            const imageEvent = {
              ...imgEvent,
              image: {
                ...imgEvent.image,
                relatedToApp: app,
              },
            };
            return actions.uploadImageSuccess({ imageEvent });
          }),
          catchError(({ errorCode }) => of(actions.uploadImageError({ errorCode }))),
        ),
      ),
    ),
  );

  uploadImageByUrl$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.uploadImageByUrl),
      withLatestFrom(this._store.select(selectors.defaultApp)),
      switchMap(([{ url }, app]) =>
        this._httpService.uploadImageByUrl(url).pipe(
          map(image => actions.uploadImageByUrlSuccess({ image: { ...image, relatedToApp: app } })),
          catchError(({ errorCode }) => of(actions.uploadImageByUrlError({ errorCode }))),
        ),
      ),
    ),
  );

  deleteImage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deleteImage),
      switchMap(({ id }) =>
        this._httpService.deleteImage(id).pipe(
          map(() => actions.deleteImageSuccess({ id })),
          catchError(({ errorCode }) => of(actions.deleteImageError({ errorCode }))),
        ),
      ),
    ),
  );

  uploadCroppedImage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.uploadCroppedImage),
      withLatestFrom(this._store.select(selectors.defaultApp)),
      switchMap(([{ base64, fileName }, app]) =>
        this._httpService.uploadCroppedImage(base64, fileName).pipe(
          map(image => actions.uploadCroppedImageSuccess({ image: { ...image, relatedToApp: app } })),
          catchError(({ errorCode }) => of(actions.uploadCroppedImageError({ errorCode }))),
        ),
      ),
    ),
  );
}
