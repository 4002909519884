import { OrderLocationApi } from './order-location.type';

export interface OrderUser {
  id: number;
  name: string;
  userId: string;
  createdBy: string;
  orderId: number;
  phone?: string;
  email?: string;
  transactions: OrderUserTransaction[];
}

export interface OrderUserApi extends Omit<OrderUser, 'orderId'> {
  businessLocations: OrderLocationApi[];
}

export interface OrderUserTransaction {
  id: number;
  amount: number;
  status: TransactionStatus;
  type: TransactionType;
  groupId: number;
  orderUserId: number;
  createdAt: string;
  updatedAt: string;
  provider: TransactionProvider;
}

export enum TransactionType {
  admin = 'admin',
  cb = 'cb',
  discount = 'discount',
  forcedCash = 'forced_cash',
  posIkentoo = 'pos-ikentoo',
  refund = 'refund',
  refundUserCredit = 'refund_user_credit',
  userCredit = 'user_credit',
}

export enum TransactionStatus {
  New = 'new',
  Accepted = 'accepted',
  Refused = 'refused',
  Sent = 'sent',
  NotSent = 'not-sent',
  Cancel = 'cancel',
  CancelSameUser = 'cancel-same-user',
  ToBeCaptured = 'to-be-captured',
}

export enum TransactionProvider {
  Discount = 'discount',
  Epay = 'epay',
  FastCredits = 'fast-credit',
  Nepting = 'nepting',
  Pos = 'pos',
  Stripe = 'stripe',
  Paygreen = 'paygreen',
  Waiter = 'waiter',
}

export type OrderUserLight = Pick<OrderUser, 'id' | 'name'>;
