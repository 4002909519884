import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ItemStoreModule } from '../item';
import { storeKey } from './menu-category.config';
import { reducer } from './menu-category.reducer';
import { MenuCategoryService } from './menu-category.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), ItemStoreModule],
  providers: [MenuCategoryService],
})
export class MenuCategoryStoreModule {}
