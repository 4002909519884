<pop-widget-edit-widget [widget]="widget" (submit)="onSubmitCart()" (onSaveAndPublish)="onSaveAndPublishCart()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form_row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <input id="isExpanded" type="checkbox" formControlName="isExpanded" />
            <label for="isExpanded" class="form-label form-label--checkbox">
              {{ 'WIDGETS.CART.ALWAYS_OPEN' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form_row">
      <label for="productCatalogUrl" class="form-label">{{ 'WIDGETS.CART.DEFAULT_URL' | translate }}</label>
      <input
        id="productCatalogUrl"
        class="form-input form-input--url"
        type="url"
        placeholder="URL"
        formControlName="productCatalogUrl"
        (keyup)="onChangeLink()"
      />
      <pop-widget-edit-page-selector
        *ngIf="pages"
        [selectedPageId]="link.value"
        (pageChangeEmmiter)="onPageChange($event)"
        [pages]="pages"
      ></pop-widget-edit-page-selector>
      <div class="final-url">
        <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
        <span class="final-url__value">{{ finalUrl }}</span>
      </div>
      <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
    </div>
  </form>
</pop-widget-edit-widget>
