import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './tags.actions';
import { TagsHttpService } from './tags.http.service';
import * as selectors from './tags.selectors';
import { State } from './tags.type';

@Injectable()
export class PosSettingsEffects {
  constructor(
    private readonly _httpService: TagsHttpService,
    private readonly _store: Store<State>,
    private readonly _actions$: Actions,
  ) {}

  getList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getList),
      withLatestFrom(this._store.select(selectors.isLoadedByAppSlug)),
      switchMap(([{ appSlug }, isLoadedByAppSlug]) =>
        appSlug === isLoadedByAppSlug
          ? of(actions.getListIsLoaded())
          : this._httpService.getAll().pipe(
              map(tags => actions.getListSuccess({ tags, appSlug })),
              catchError(({ errorCode }) => of(actions.getListError({ errorCode }))),
            ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.get),
      withLatestFrom(this._store.select(selectors.isLoadedBy)),
      switchMap(([{ id }, loadedBy]) =>
        loadedBy.includes(id)
          ? of(actions.getIsLoaded({ id }))
          : this._httpService.getOne(id).pipe(
              map(tag => actions.getSuccess({ tag })),
              catchError(({ errorCode }) => of(actions.getError({ errorCode }))),
            ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.add),
      switchMap(({ tag }) =>
        this._httpService.add(tag).pipe(
          map(tag => actions.addSuccess({ tag })),
          catchError(({ errorCode }) => of(actions.addError({ errorCode }))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.update),
      switchMap(({ tag }) =>
        this._httpService.update(tag).pipe(
          map(tag => actions.updateSuccess({ tag })),
          catchError(({ errorCode }) => of(actions.updateError({ errorCode }))),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.delete),
      switchMap(({ id }) =>
        this._httpService.delete(id).pipe(
          map(() => actions.deleteSuccess({ id })),
          catchError(({ errorCode }) => of(actions.deleteError({ errorCode }))),
        ),
      ),
    ),
  );
}
