import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { actions } from './cart.actions';
import { CartHttpService } from './cart.http.service';

@Injectable()
export class CartEffects {
  getPaymentMethods$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.getPaymentMethods),
      mergeMap(() =>
        this._cart.paymentMethods().pipe(
          map(paymentMethods => actions.getPaymentMethodsSuccess({ paymentMethods })),
          catchError(() => of(actions.getPaymentMethodsError())),
        ),
      ),
    ),
  );

  deletePaymentMethod$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.deletePaymentMethod),
      mergeMap(({ id }) =>
        this._cart.deletePaymentMethod(id).pipe(
          map(() => actions.deletePaymentMethodSuccess({ id })),
          catchError(() => of(actions.deletePaymentMethodError())),
        ),
      ),
    ),
  );

  getCredit$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.getCredit),
      mergeMap(() =>
        this._cart.credit().pipe(
          map(credit => actions.getCreditSuccess(credit)),
          catchError(() => of(actions.getCreditError())),
        ),
      ),
    ),
  );

  getWorkingHours$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.getWorkingHours),
      mergeMap(() =>
        this._cart.workingHours().pipe(
          map(workingHours => actions.getWorkingHoursSuccess({ workingHours })),
          catchError(() => of(actions.getWorkingHoursError())),
        ),
      ),
    ),
  );

  constructor(private _actions: Actions, private _cart: CartHttpService) {}
}
