import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EnvModule } from '@bend/env';

import { storeKey } from './payment.config';
import { PaymentEffects } from './payment.effects';
import { PaymentHttpService } from './payment.http.service';
import { reducer } from './payment.reducer';
import { PaymentService } from './payment.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([PaymentEffects]), EnvModule],
  providers: [PaymentHttpService, PaymentService],
})
export class PaymentStoreModule {}
