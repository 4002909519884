import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { Page } from '../shared';
import { actions } from './pages.actions';
import { State } from './pages.type';

export const adapter: EntityAdapter<Page> = createEntityAdapter<Page>();

export const initialState: State = adapter.getInitialState({
  error: null,
  isLoadingById: {},
  isLoadedById: {},
  selectedNextPageId: null,
  selectedPageId: null,
});

const pageReducer = createReducer(
  initialState,

  on(actions.getById, (state, { id }) => ({
    ...state,
    error: null,
    isLoadingById: { ...state.isLoadingById, [id]: true },
    selectedNextPageId: id,
  })),

  on(actions.getByIdError, (state, { id, error }) => ({
    ...state,
    error,
    isLoadingById: { ...state.isLoadingById, [id]: false },
  })),
  on(actions.getByIdIsLoaded, (state, { id }) => ({
    ...state,
    isLoadingById: { ...state.isLoadingById, [id]: false },
    selectedPageId: id,
  })),
  on(actions.getByIdSuccess, (state, { page }) =>
    adapter.upsertOne(page, {
      ...state,
      isLoadingById: { ...state.isLoadingById, [page.id]: false },
      isLoadedById: { ...state.isLoadedById, [page.id]: true },
      selectedPageId: page.id,
    }),
  ),

  on(actions.resetPageById, (state, { id }) => ({
    ...state,
    isLoadingById: { ...state.isLoadingById, [id]: false },
    isLoadedById: { ...state.isLoadedById, [id]: false },
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return pageReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(
        {
          selectedNextPageId: null,
          selectedPageId: null,
          entities: {},
          ids: [],
          error: null,
          isLoadingById: {},
          isLoadedById: {},
        },
        action,
      );
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
