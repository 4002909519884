import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { StorageService } from '@bend/storage';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetWelcome } from './welcome';

@Component({
  selector: 'pop-widget-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WidgetWelcomeComponent implements OnInit, WidgetComponent {
  static widgetName = 'welcome';
  @Input() attributes: WidgetWelcome;

  isValid: boolean;
  noValidMessage: string;
  title: string;

  constructor(
    private _warningService: WarningService,
    private _localStorage: StorageService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.text) {
      this.noValidMessage = this._warningService.showWarn(WidgetWelcomeComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }
    this._checkVariable();
    this.isValid = true;
  }

  private _checkVariable(): void {
    this.title = this.attributes.text.replace('<$table_name>', '');
    this.title = this.title.replace('<$user_name>', this._localStorage.userName || '');
  }
}
