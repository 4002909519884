import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Icons } from '@bend/icon';
import { SettingsService } from '@bend/store';

@Component({
  selector: 'app-button-quantity',
  templateUrl: './button-quantity.component.html',
  styleUrls: ['./button-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonQuantityComponent implements OnInit {
  @Input() negative: boolean;
  background$: Observable<string>;
  color$: Observable<string>;
  icon: Icons;

  constructor(private _settings: SettingsService) {
    this.background$ = this._settings.colors.primary;
    this.color$ = this._settings.colors.primaryForeground;
  }

  ngOnInit(): void {
    this.icon = this.negative ? 'circleMinus' : 'circlePlus';
  }
}
