<div
  *ngIf="isValid; else elseBlock"
  [class]="'widget-product-catalog' + ' ' + productWidgetStyleType"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
>
  <div
    scrolledTo
    #scrolledToElement="scrolledTo"
    class="slider-wrapper"
    [style.height.px]="attributes.styleConfig.categoriesStyle === 3 && contentWrapperEl?.nativeElement.clientHeight"
    [class.slider-wrapper--elegant]="attributes.styleConfig.categoryStyleType === 2"
  >
    <div *ngIf="attributes.catalogs.length > 1">
      <div
        #contentWrapperEl
        [class.catalogs-slider__stick-top]="
          attributes.styleConfig.categoriesStyle === 2 ||
          (scrolledToElement.reached && attributes.styleConfig.categoriesStyle === 3)
        "
        [class.slider-wrapper__stick-top__zero]="
          (attributes.styleConfig.categoriesStyle === 2 ||
            (scrolledToElement.reached && attributes.styleConfig.categoriesStyle === 3)) &&
          !isTopBarVisibleProp
        "
      >
        <div
          class="centered"
          [class.mlr-15]="
            attributes.styleConfig.categoriesStyle === 2 ||
            (scrolledToElement.reached && attributes.styleConfig.categoriesStyle === 3)
          "
        >
          <div
            [class.catalogs-slider__stack]="attributes.styleConfig.categoryAlignType === 2"
            [class.catalogs-slider__scroll]="
              attributes.styleConfig.categoryAlignType === 1 || !attributes.styleConfig.categoryAlignType
            "
          >
            <span
              *ngFor="let catalog of attributes.catalogs; let index = index; trackBy: trackByFn"
              [class.catalog-btn-rap--active]="catalog.uniqueId === currentCatalog.uniqueId"
              [attr.selectedCatalog]="catalog.uniqueId === currentCatalog.uniqueId ? '1' : '0'"
              #catalogs
              [style.borderColor]="attributes.mainColor"
              class="catalog-slide-wrapper"
              (click)="changeCatalog(index)"
              data-cy="catalogButton"
            >
              <span class="catalog-img-btn catalog-slide">
                <span
                  *ngIf="
                    productWidgetStyleType &&
                    catalog.imageMeta &&
                    catalog.imageMeta.url &&
                    attributes.styleConfig.categoryStyleType === 2
                  "
                >
                  <img [src]="catalog.imageMeta.url" class="catalog-image" alt="catalog image" />
                </span>
                <button
                  class="catalog-btn"
                  [class.catalog-btn__border]="attributes.styleConfig.categoryAlignType === 2"
                  [class.catalog-btn--active]="catalog.uniqueId === currentCatalog.uniqueId"
                  [class.catalog-btn--scroll]="attributes.styleConfig.categoryAlignType === 2"
                  [style.backgroundColor]="
                    attributes.styleConfig.categoryStyleType === 1 ? getStyleCurrentCatalog(catalog.uniqueId) : ''
                  "
                  [style.color]="
                    attributes.styleConfig.categoryStyleType === categoryStyleTypes.Style2 &&
                    catalog.uniqueId === currentCatalog.uniqueId
                      ? attributes.mainColor
                      : ''
                  "
                  [style.fontWeight]="
                    attributes.styleConfig.categoryStyleType === categoryStyleTypes.Style2 &&
                    catalog.uniqueId === currentCatalog.uniqueId
                      ? 500
                      : 400
                  "
                  [style.borderColor]="
                    attributes.styleConfig.categoryStyleType === categoryStyleTypes.Style2 &&
                    catalog.uniqueId === currentCatalog.uniqueId
                      ? attributes.mainColor
                      : null
                  "
                >
                  {{ catalog.name }}
                </button>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    [class.full-width]="attributes.styleConfig.fullWidth"
    *ngIf="currentCatalog && currentCatalog.catalogType === 'menu'"
    [style.backgroundColor]="attributes.styleConfig.menuStyleType === 4 ? 'transparent' : null"
  >
    <pop-product-catalog-menu
      [catalogId]="currentCatalog.catalogId"
      [currency]="attributes.currency"
      [isReadOnly]="isReadOnly"
      [isConsultingMode]="isConsultingMode$ | async"
      [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
      [allowOrderForOthers]="attributes.allowOrderForOthers"
      [mainColor]="attributes.mainColor"
      [secondColor]="attributes.secondColor"
      [textColor]="attributes.textColor"
      [allowCommonPot]="attributes.allowCommonPot"
      [visibleMenus]="currentCatalog.visibleMenus"
      [showKitchenComment]="attributes.showKitchenComment"
      [isCommentToKitchenRequired]="attributes.isCommentToKitchenRequired"
      [returnUrl]="attributes.returnUrl"
      [pageAnalytics]="attributes.page"
      [styleType]="productWidgetStyleType"
      [menuStyleType]="menuProductWidgetStyleType"
      [styleConfig]="attributes.styleConfig"
      [styleNumber]="styleType"
      [forceShowProductDescription]="attributes.forceShowProductDescription"
    ></pop-product-catalog-menu>
  </div>

  <div
    [class.full-width]="attributes.styleConfig.fullWidth"
    *ngIf="currentCatalog && currentCatalog.catalogType === 'a_la_carte'"
  >
    <pop-product-catalog-a-la-carte
      [catalogId]="currentCatalog.catalogId"
      [isReadOnly]="isReadOnly"
      [isConsultingMode]="isConsultingMode$ | async"
      [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
      [currency]="attributes.currency"
      [allowOrderForOthers]="attributes.allowOrderForOthers"
      [mainColor]="attributes.mainColor"
      [secondColor]="attributes.secondColor"
      [textColor]="attributes.textColor"
      [allowCommonPot]="attributes.allowCommonPot"
      [visibleCategories]="currentCatalog.visibleCategories"
      [forceShowProductDescription]="attributes.forceShowProductDescription"
      [showKitchenComment]="attributes.showKitchenComment"
      [isCommentToKitchenRequired]="attributes.isCommentToKitchenRequired"
      [returnUrl]="attributes.returnUrl"
      [pageAnalytics]="attributes.page"
      [styleType]="productWidgetStyleType"
      [categoryStyleType]="categoryStyleType"
      [styleNumber]="styleType"
      [styleConfig]="attributes.styleConfig"
      [workingHours]="attributes.workingHours"
      [topBar]="attributes.topBar"
      [numberOfCatalogs]="attributes.catalogs.length"
      [catalogsNavHeight]="contentWrapperEl?.nativeElement.clientHeight"
    ></pop-product-catalog-a-la-carte>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-product-catalog">{{ noValidMessage }}</div>
</ng-template>
