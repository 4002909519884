<ng-container *ngIf="isValidFiles && !uploadLimit; else error">
  <h2 class="title" mat-dialog-title>Loading</h2>
  <mat-dialog-content>
    <div class="container">
      <designer-image
        *ngFor="let image of images$ | async"
        class="item"
        [name]="image.image?.fileName"
        [imageUrl]="image.image?.domain + image.image?.relativePath"
        [date]="image.image?.createdAt || 0 | date"
        [isLoading]="!image.image"
      ></designer-image>
    </div>
  </mat-dialog-content>
</ng-container>
<ng-template #error>
  <div class="error-dialog">
    <h2 *ngIf="!isValidFiles">{{ 'STUDIO.IMAGE_GALLERY.INVALID_TYPE' | translate }}</h2>
    <h2 *ngIf="uploadLimit">
      {{ 'STUDIO.IMAGE_GALLERY.UPLOAD_LIMIT' | translate }} - {{ this.MAX_FILES_UPLOAD }} files
    </h2>
    <button mat-raised-button (click)="dialogRef.close()" type="button" color="warn">
      {{ 'STUDIO.SHARED.CANCEL' | translate }}
    </button>
  </div>
</ng-template>
