<div [ngClass]="{ 'add-comment-area': true, 'kitchen-style__v2': styleConfig.productDescriptionStyle === 2 }">
  <button
    (click)="toggleComment()"
    [style.color]="styleConfig.productDescriptionStyle === 2 ? null : mainColor"
    class="add-comment-btn"
  >
    <span>
      <svg
        *ngIf="styleConfig.productDescriptionStyle === 2"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="chat-icon"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.0153062 14.8489C0.0658325 15.0316 0.231727 15.1579 0.421201 15.1579L0.422043 15.1588C1.28941 15.1588 2.36394 14.843 3.61699 14.2198C4.42373 13.8181 5.0991 13.3912 5.46036 13.1503C6.27636 13.3659 7.13025 13.4745 8.00015 13.4745C10.118 13.4745 12.113 12.8295 13.6178 11.659C14.3597 11.0813 14.9441 10.4068 15.3534 9.65391C15.7829 8.86401 16.0001 8.02528 16.0001 7.15791C16.0001 6.29054 15.7829 5.45096 15.3534 4.66191C14.9441 3.90823 14.3597 3.2337 13.6178 2.65686C12.113 1.48633 10.118 0.841278 8.00015 0.841278C5.88225 0.841278 3.88731 1.48633 2.38246 2.65686C1.64057 3.23454 1.05615 3.90907 0.646885 4.66191C0.217411 5.4518 0.000148326 6.29138 0.000148326 7.15791C0.000148326 8.87412 0.88099 10.5137 2.42878 11.6985C2.2191 12.2796 1.62878 13.5217 0.20478 14.3756C0.0422536 14.4724 -0.0352201 14.6661 0.0153062 14.8489ZM0.842254 7.15791C0.842254 4.1398 4.0532 1.68423 8.00015 1.68423C11.9471 1.68423 15.158 4.1398 15.158 7.15791C15.158 10.176 11.9471 12.6316 8.00015 12.6316C7.14036 12.6316 6.2991 12.5154 5.50078 12.2872C5.37952 12.2526 5.24983 12.2737 5.14625 12.3453C4.27467 12.9449 3.09404 13.611 1.99762 13.995C2.89446 13.0223 3.22962 12.0143 3.32815 11.6472C3.37362 11.4762 3.30794 11.2952 3.1631 11.1933C1.68857 10.1575 0.842254 8.68633 0.842254 7.15791Z"
          fill="black"
          fill-opacity="0.25"
        />
      </svg>
      {{ 'PRODUCT_CATALOG.ADD_COMMENT_TO_KITCHEN' | translate }}
    </span>
    <svg
      *ngIf="styleConfig.productDescriptionStyle === 2"
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.88888 15.2C1.78648 15.2 1.68408 15.1608 1.60568 15.0832C1.44968 14.9272 1.44968 14.6736 1.60568 14.5176L8.52248 7.6008L1.60568 0.684004C1.44968 0.528004 1.44968 0.274404 1.60568 0.118404C1.76168 -0.0375962 2.01528 -0.0375962 2.17128 0.118404L9.37128 7.3184C9.52728 7.4744 9.52728 7.728 9.37128 7.884L2.17128 15.084C2.09288 15.1624 1.99048 15.2008 1.88808 15.2008L1.88888 15.2Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
  </button>
  <form [class.hidden]="!isCommentVisible && !isCommentToKitchenRequired" class="comment-form">
    <textarea
      id="comment"
      #commentEl
      [ngModel]="comment"
      name="comment"
      (ngModelChange)="commentChanged($event)"
      class="comment"
      rows="4"
      [placeholder]="'PRODUCT_CATALOG.YOUR_COMMENT_HERE' | translate"
    ></textarea>
  </form>
</div>
