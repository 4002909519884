<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="titleInput" class="form-label">{{ 'WIDGETS.SHARED.TITLE' | translate }}</label>
      <span
        *ngIf="title.invalid && (((title.dirty || title.touched) && title.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.TITLE_ENTER' | translate }}
      </span>
      <input
        id="titleInput"
        type="text"
        [placeholder]="'WIDGETS.SHARED.TITLE' | translate"
        formControlName="title"
        class="form-input form-input--title"
      />
    </div>
    <div class="form-row">
      <label for="linkInput" class="form-label">{{ 'WIDGETS.SHARED.LINK' | translate }}</label>
      <span
        *ngIf="link.invalid && (((link.dirty || link.touched) && link.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.LINK_ENTER' | translate }}
      </span>
      <input
        id="linkInput"
        class="form-input form-input--url"
        type="url"
        placeholder="URL"
        formControlName="link"
        (keyup)="onChangeLink()"
      />
      <pop-widget-edit-page-selector
        *ngIf="pages"
        (pageChangeEmmiter)="onPageChange($event, link)"
        [selectedPageId]="link.value"
        [pages]="pages"
      ></pop-widget-edit-page-selector>
      <div class="final-url">
        <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
        <span class="final-url__value">{{ finalUrl }}</span>
      </div>
      <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
    </div>
    <input id="openInNewTabInput" class="form-input-checkbox" type="checkbox" formControlName="openInNewTab" />
    <label for="openInNewTabInput" class="form-label form-label--checkbox">
      {{ 'WIDGETS.SHARED.EXTERNAL_TAB' | translate }}
    </label>
    <div class="form-row">
      <div class="form-row-groups">
        <div formGroupName="style">
          <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.ICON' | translate }}</span>
          <designer-image-gallery formControlName="iconUrl"></designer-image-gallery>
        </div>
      </div>
    </div>
    <div formGroupName="style" class="form-row-groups-wrapper">
      <input id="showArrowInput" class="form-input-checkbox" type="checkbox" formControlName="showArrow" />
      <label for="showArrowInput" class="form-label form-label--checkbox">
        {{ 'WIDGETS.STYLE.SHOW_ARROW' | translate }}
      </label>
    </div>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <span class="form-fieldset-groups__caption">{{ 'WIDGETS.STYLE.STYLES' | translate }}</span>
        <div class="form-fieldset-groups__content">
          <div class="form-row-groups">
            <div class="form-row-group form-row-group--distanced" formGroupName="margin">
              <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
              <input
                id="marginTopInput"
                type="number"
                formControlName="top"
                min="0"
                class="form-input form-input--number"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced" formGroupName="margin">
              <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
              <input
                id="marginBottomInput"
                type="number"
                formControlName="bottom"
                min="0"
                class="form-input form-input--number"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="displayTypeSelect" class="form-label">{{ 'WIDGETS.STYLE.DISPLAY_TYPE' | translate }}</label>
              <select class="form-input form-input--select" formControlName="displayType">
                <option *ngFor="let item of typesName | keyvalue" [value]="item.key">
                  {{ item.value }}
                </option>
              </select>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="fontSizeInput" class="form-label">{{ 'WIDGETS.STYLE.FONT_SIZE' | translate }}</label>
              <input
                id="heightInput"
                type="text"
                formControlName="fontSize"
                matInput
                [matAutocomplete]="fontSizeAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #fontSizeAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="fontSize.invalid && fontSize.errors.fontSize">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>

            <div class="form-row-group form-row-group--distanced">
              <label for="textColorInput" class="form-label">{{ 'WIDGETS.STYLE.TEXT_COLOR' | translate }}</label>
              <span
                id="textColorInput"
                [(colorPicker)]="textColorValue"
                [style.background]="textColorValue"
                class="form-colorpicker"
              ></span>
            </div>

            <div class="form-row-group form-row-group--distanced">
              <label for="textAlignSelect" class="form-label">{{ 'WIDGETS.STYLE.TEXT_ALIGN' | translate }}</label>
              <select class="form-input form-input--select" formControlName="textAlign">
                <option *ngFor="let textAlign of textAligns" [value]="textAlign">{{ textAlign }}</option>
              </select>
            </div>

            <div class="form-row-group form-row-group--distanced">
              <label for="widthInput" class="form-label">{{ 'WIDGETS.STYLE.WIDTH' | translate }}</label>
              <input
                id="widthInput"
                type="text"
                formControlName="width"
                matInput
                [matAutocomplete]="widthAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #widthAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
                <mat-option [value]="'full'">{{ 'WIDGETS.STYLE.FULL' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="width.invalid && width.errors.width">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="heightInput" class="form-label">{{ 'WIDGETS.STYLE.HEIGHT' | translate }}</label>
              <input
                id="heightInput"
                type="text"
                formControlName="height"
                matInput
                [matAutocomplete]="heightAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #heightAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="height.invalid && height.errors.height">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="backgroundColorInput" class="form-label">
                {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
              </label>
              <span
                id="backgroundColorInput"
                [(colorPicker)]="backgroundColorValue"
                [cpOutputFormat]="'rgba'"
                class="form-colorpicker"
                [style.backgroundColor]="backgroundColorValue"
              ></span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="borderColorInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_COLOR' | translate }}</label>
              <span
                id="borderColorInput"
                [(colorPicker)]="borderColorValue"
                class="form-colorpicker"
                [style.background]="borderColorValue"
              ></span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="borderWidthInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_WIDTH' | translate }}</label>
              <input
                id="BorderWidthInput"
                class="form-input form-input--number"
                type="number"
                formControlName="borderWidth"
                min="0"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
              <input
                id="borderRadiusInput"
                class="form-input form-input--number"
                type="number"
                formControlName="borderRadius"
                min="0"
              />
              <span class="form-input-number-units">px</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
