import { UpdateNum } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';

import { createType, User } from '../shared';
import { storeKey } from './users.config';
import { GetUsers, GetUsersSuccess } from './users.type';

const type = createType(storeKey);

export const actions = {
  getUsersByOffsetAndLimit: createAction(type('get users'), props<GetUsers>()),
  getUsersByOffsetAndLimitSuccess: createAction(type('get users success'), props<GetUsersSuccess>()),
  getUsersByOffsetAndLimitError: createAction(type('get users error'), props<{ errorCode: string }>()),

  getUsersBySearch: createAction(type('get users by search'), props<GetUsers>()),
  getUsersBySearchSuccess: createAction(type('get users by search success'), props<GetUsersSuccess>()),
  getUsersBySearchError: createAction(type('get users by search error'), props<{ errorCode: string }>()),

  addCredits: createAction(
    type('add credits'),
    props<{ user: UpdateNum<User>; add: { credit: User['credit']; reason: string; paymentMethod: string } }>(),
  ),
  addCreditsSuccess: createAction(type('add credits success'), props<{ user: UpdateNum<User> }>()),
  addCreditsError: createAction(type('add credits error'), props<{ user: UpdateNum<User>; errorCode: string }>()),

  getUser: createAction(type('get user'), props<{ userId: User['id'] }>()),
  getUserSuccess: createAction(type('get user success'), props<{ user: User }>()),
  getUserError: createAction(type('get user error'), props<{ errorCode: string }>()),

  updateUser: createAction(type('edit user'), props<{ user: Partial<User> }>()),
  updateUserSuccess: createAction(type('edit user success'), props<{ user: User }>()),
  updateUserError: createAction(type('edit user error'), props<{ errorCode: string }>()),
};
