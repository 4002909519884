import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

const enum OrderStatus {
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  CANCEL = 'cancel',
  CANCEL_SAME_USER = 'cancel-same-user',
  CANCEL_FROM_POS = 'cancel-from-pos',
  ALL = 'all',
  NEW = 'new',
  SEND = 'send',
  PAID = 'paid',
  PARTIALLY_READY = 'partially-ready',
  READY = 'ready',
  DELIVERED = 'delivered',
  CLOSED = 'closed',
  HANDED = 'handed',
  Sent = 'sent',
  NotSent = 'not-sent',
}

@Pipe({ name: 'transactionStatus' })
export class TransactionStatusPipe extends TranslatePipe implements PipeTransform {
  constructor(translate: TranslateService, ref: ChangeDetectorRef) {
    super(translate, ref);
  }

  transform(value: string): string {
    return super.transform(this._label(value));
  }

  private _label(key: string): string {
    switch (key) {
      case OrderStatus.ACCEPTED:
        return 'STUDIO.ORDERS.STATUS.ACCEPTED';
      case OrderStatus.REFUSED:
        return 'STUDIO.ORDERS.STATUS.REFUSED';
      case OrderStatus.CANCEL:
        return 'STUDIO.ORDERS.STATUS.CANCEL';
      case OrderStatus.CANCEL_SAME_USER:
        return 'STUDIO.ORDERS.STATUS.CANCEL_SAME_USER';
      case OrderStatus.CANCEL_FROM_POS:
        return 'STUDIO.ORDERS.STATUS.CANCEL_FROM_POS';
      case OrderStatus.ALL:
        return 'STUDIO.ORDERS.STATUS.ALL';
      case OrderStatus.NEW:
        return 'STUDIO.ORDERS.STATUS.NEW';
      case OrderStatus.SEND:
        return 'STUDIO.ORDERS.STATUS.SEND';
      case OrderStatus.PAID:
        return 'STUDIO.ORDERS.STATUS.PAID';
      case OrderStatus.READY:
        return 'STUDIO.ORDERS.STATUS.READY';
      case OrderStatus.DELIVERED:
        return 'STUDIO.ORDERS.STATUS.DELIVERED';
      case OrderStatus.CLOSED:
        return 'STUDIO.ORDERS.STATUS.CLOSED';
      case OrderStatus.PARTIALLY_READY:
        return 'STUDIO.ORDERS.STATUS.PARTIALLY_READY';
      case OrderStatus.HANDED:
        return 'STUDIO.ORDERS.STATUS.HANDED';
      case OrderStatus.Sent:
        return 'STUDIO.ORDERS.STATUS.SENT';
      case OrderStatus.NotSent:
        return 'STUDIO.ORDERS.STATUS.NOT_SENT';

      default:
        return 'STUDIO.ORDERS.STATUS.UNKNOWN';
    }
  }
}
