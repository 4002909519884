<div class="feedback-upload-image">
  <button
    type="button"
    class="btn--upload"
    [style.borderColor]="mainColor"
    (click)="onChooseFile(fileInputBtn)"
    tabindex="-1"
    [disabled]="isUploadButtonDisabled || isEditMode"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 50 50"
        version="1.1"
        width="25"
        height="25"
        style="fill: rgb(0, 0, 0)"
      >
        <g id="surface1">
          <path
            [style.fill]="mainColor"
            d="M 20 8 C 13.539063 8 8.367188 13.109375 8.09375 19.5 C 3.417969 20.777344 0 24.996094 0 30 C 0 36.046875 4.953125 41 11 41 L 41 41 C 45.945313 41 50 36.945313 50 32 C 50 28.101563 47.402344 24.792969 43.90625 23.625 C 43.695313 17.761719 38.910156 13 33 13 C 31.960938 13 30.992188 13.257813 30.03125 13.53125 C 27.882813 10.261719 24.21875 8 20 8 Z M 20 10 C 23.726563 10 26.992188 12.09375 28.71875 15.09375 L 29.15625 15.8125 L 29.9375 15.53125 C 30.9375 15.167969 31.910156 15 33 15 C 37.953125 15 42 19.046875 42 24 L 42 25.09375 L 42.78125 25.28125 C 45.714844 25.972656 48 28.769531 48 32 C 48 35.855469 44.855469 39 41 39 L 11 39 C 6.046875 39 2 34.953125 2 30 C 2 25.671875 5.058594 21.996094 9.1875 21.1875 L 10 21.03125 L 10 20 C 10 14.433594 14.433594 10 20 10 Z M 25 18.59375 L 24.28125 19.28125 L 18.28125 25.28125 L 19.71875 26.71875 L 24 22.4375 L 24 34 L 26 34 L 26 22.4375 L 30.28125 26.71875 L 31.71875 25.28125 L 25.71875 19.28125 Z "
          />
        </g>
      </svg>
    </span>
    <span [style.color]="mainColor">{{ labels.UPLOAD_PICTURE || 'FEEDBACK.UPLOAD_PICTURE' | translate }}</span>
  </button>
  <input id="fileInput" class="input--hidden" type="file" (change)="onFileChange($event)" #fileInputBtn />
</div>

<div *ngIf="displayProgressBar && !showErrorMessage" class="progress-bar-wrapper">
  <div class="progress-bar" [style.width.%]="uploadedSizePercent"></div>
</div>

<span *ngIf="showErrorMessage" class="error-msg">
  {{ labels.UPLOAD_FAILED || 'FEEDBACK.UPLOAD_FAILED' | translate }}
</span>

<div *ngIf="!displayProgressBar && !showErrorMessage" [hidden]="!file?.url">
  <div class="image-preview-card">
    <img *ngIf="file?.url" [src]="file?.url" alt="image preview" class="image-preview-card__img" />
    <div class="image-preview-card__details">
      <span class="file-name">{{ file.name + '.' + file.type }}</span>
      <div class="delete-section">
        <button type="button" class="delete-icon-btn" (click)="deleteLoadedImage()">
          <svg xmlns="http://www.w3.org/2000/svg" height="20" width="15.556">
            <path
              d="M1.11 17.778c0 1.228.996 2.222 2.223 2.222h8.89a2.22 2.22 0 0 0 2.22-2.222V4.444H1.112zM15.557 1.11h-3.89L10.557 0H5L3.89 1.11H0v2.223h15.556z"
              fill="#bebebf"
              fill-opacity=".941"
              class="svg-element-hoverable"
            />
            <path d="M-4.167 2.5h20v20h-20z" fill="none" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
