export const hourScheduledTemplate = (scheduledTime: Date): string => {
  const currentTime = new Date();

  const difference = scheduledTime.getTime() - currentTime.getTime();

  // 36e5 is the scientific notation for 60*60*1000
  const hours = Math.floor(difference / 36e5);
  // 6e4 is the scientific notation for 60*1000
  const minutes = Math.floor((difference % 36e5) / 6e4);

  const hoursLabel = hours ? `${hours}h ` : '';
  const minutesLabel = `${minutes}min`;

  return `${hoursLabel}${minutesLabel}`;
};
