import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { delay, filter, take, tap } from 'rxjs/operators';

import { FileType, ImageGalleryService } from '@bend/store/src/lib/image-gallery';
import { ImageEvent } from '@bend/store/src/lib/shared/types/image-gallery.type';

@Component({
  selector: 'app-gallery-upload-dialog',
  templateUrl: './gallery-upload-dialog.component.html',
  styleUrls: ['./gallery-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryUploadDialogComponent {
  readonly MAX_FILES_UPLOAD = 50;
  isValidFiles = true;
  uploadLimit = false;

  images$: Observable<ImageEvent[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { files: FileList; fileTypes: FileType[] },
    public dialogRef: MatDialogRef<GalleryUploadDialogComponent>,
    private _imageGalleryService: ImageGalleryService,
  ) {
    if (data.files.length <= this.MAX_FILES_UPLOAD) {
      if (this.isValidFilesCheck(data.files, data.fileTypes)) {
        this.images$ = this._imageGalleryService.imagesForUpload;
        this._imageGalleryService.uploadImages(Array.from(data.files));
        this._closeAfterUpload();
      } else {
        this.isValidFiles = false;
      }
    } else {
      this.uploadLimit = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private isValidFilesCheck(files: FileList, fileTypes: FileType[]): boolean {
    return Array.from(files).every(file => this.validityCheck(file.type, fileTypes));
  }

  private validityCheck(ext: string, fileTypes: FileType[]): boolean {
    return (fileTypes.includes('image') && this.isImage(ext)) || (fileTypes.includes('video') && this.isVideo(ext));
  }

  private isVideo(ext: string): boolean {
    return /(mp4|webm)/gi.test(ext);
  }

  private isImage(ext: string): boolean {
    return /(png|jpg|jpeg|gif|svg|bmp|webp)/gi.test(ext);
  }

  private _closeAfterUpload(): void {
    this.images$
      .pipe(
        filter(imageEvent => imageEvent.filter(item => item.image).length === this.data.files.length),
        take(1),
        delay(700),
        tap(() => this.dialogRef.close()),
      )
      .subscribe();
  }
}
