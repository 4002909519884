import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';

import { createType, Page } from '../shared';
import { storeKey } from './pages.config';

const type = createType(storeKey);

export const actions = {
  getById: createAction(type('get page by id'), props<{ id: string; params?: Params }>()),
  getByIdIsLoaded: createAction(type('get page by id is loaded'), props<{ id: string }>()),
  getByIdSuccess: createAction(type('get page by id success'), props<{ page: Page }>()),
  getByIdError: createAction(type('get page by id error'), props<{ id: string; error: string }>()),
  resetPageById: createAction(type('reset page by id'), props<{ id: string }>()),
  reset: createAction(type('reset')),
};
