import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { OrderService } from '@bend/order';
import { ParamsService } from '@bend/store';
import { ApiVisitorService } from '@bend/store-shared';

const READY_NOFITICATION_KEY = 'wasReadyNotificationShown';
const WORKING_HOURS_REDIRECT_DONE_KEY = 'wasWorkingHoursRedirectDone';
const SESSION_CLOSED_NOFITICATION_KEY = 'wasSessionClosedNotificationShown';

@Injectable()
export class SessionService {
  private _closeSessionSubject = new Subject<void>();
  private _disableAddingItemsToCartSubject = new Subject<void>();
  private _createSessionSubject = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private _api: ApiVisitorService,
    private _order: OrderService,
    private _params: ParamsService,
  ) {}

  onSessionClosed(): Observable<any> {
    return this._closeSessionSubject.asObservable();
  }

  onSessionCreated(): Observable<any> {
    return this._createSessionSubject.asObservable();
  }

  wasReadyNotificationShown(): boolean {
    return sessionStorage.getItem(READY_NOFITICATION_KEY) ? true : false;
  }

  wasSessionClosedNotificationShown(): boolean {
    return sessionStorage.getItem(SESSION_CLOSED_NOFITICATION_KEY) ? true : false;
  }

  wasWorkingHoursRedirectDone(): boolean {
    return sessionStorage.getItem(WORKING_HOURS_REDIRECT_DONE_KEY) ? true : false;
  }

  onDisabledAddingItemsToCart(): Observable<any> {
    return this._disableAddingItemsToCartSubject.asObservable();
  }
}
