import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { CartWidget } from './cart';

@Component({
  selector: 'pop-widget-edit-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditCartComponent extends WidgetBaseComponent<CartWidget> implements OnInit {
  static widgetName = 'cart';
  @Input() appHost: string;
  @Input() pages: Page[];
  finalUrl: string;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    this.onChangeLink();
  }

  onChangeLink(): void {
    this.finalUrl = WidgetHelper.generateFinalUrl(this.form.get('productCatalogUrl').value, this.appHost);
  }

  onPageChange(page: Page): void {
    this.link.setValue(page._id);
    this.onChangeLink();
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        isExpanded: [this.widget.attributes.isExpanded],
        productCatalogUrl: [this.widget.attributes.productCatalogUrl],
        style: {
          stickToBottom: true,
        },
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  onSubmitCart(): void {
    this.onSubmit();
  }

  onSaveAndPublishCart(): void {
    this.onSaveAndPublish();
  }

  get link(): UntypedFormControl {
    return this.form.get('productCatalogUrl') as UntypedFormControl;
  }
}
