import { Margin } from '../../shared/interfaces';
import { Widget } from '../../shared/models';

export interface WifiWidget extends Widget {
  attributes: {
    name: string;
    pass: string;
    style: {
      showBorders: boolean;
      backgroundColor: string;
      fullWidth: boolean;
      fontSize: string;
      displayType: WifiDisplayType;
      margin: Margin;
      iconUrl: string;
    };
  };
}

export enum WifiDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}
