import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PageService {
  readonly options: Observable<{ title: string; params?: Record<string, string | number> }>;
  readonly sidenav: Observable<boolean>;
  readonly outlet: Observable<boolean>;
  readonly scrollWidth: Observable<number>;

  private _options: BehaviorSubject<{ title: string; params?: Record<string, string | number> }>;
  private _sidenav: BehaviorSubject<boolean>;
  private _overflow: BehaviorSubject<boolean>;
  private _scrollWidth: BehaviorSubject<number>;

  constructor() {
    this._options = new BehaviorSubject(null);
    this._sidenav = new BehaviorSubject<boolean>(false);
    this._overflow = new BehaviorSubject<boolean>(false);
    this._scrollWidth = new BehaviorSubject(0);

    this.options = this._options.asObservable();
    this.sidenav = this._sidenav.asObservable();
    this.outlet = this._overflow.asObservable();
    this.scrollWidth = this._scrollWidth.asObservable();
  }

  setTitle(title: string, params?: Record<string, string | number>): void {
    this._options.next({ title, params });
  }

  setEmptyTitle(): void {
    this._options.next({ title: null, params: null });
  }

  sidenavOpen(): void {
    this.overflowHide();
    this._sidenav.next(true);
  }

  sidenavClose(): void {
    this.overflowAuto();
    this._sidenav.next(false);
  }

  sidenavToggle(): void {
    this.overflowToggle();
    this._sidenav.next(!this._sidenav.value);
  }

  overflowToggle(): void {
    if (this._overflow.value) this.overflowAuto();
    else this.overflowHide();
  }

  overflowHide(): void {
    /**
     * get body width scroll visible
     */
    const widthWithScroll = document.body.offsetWidth;
    /**
     * hide scroll bar for body
     */
    document.body.style.overflow = 'hidden';
    /**
     * get body width scroll hidden
     */
    const widthWithoutScroll = document.body.offsetWidth;
    /**
     * calculate scroll width
     */
    const scrollWidth = widthWithoutScroll - widthWithScroll;
    /**
     * set body right padding to prevent vibration
     */
    document.body.style.paddingRight = `${scrollWidth}px`;
    /**
     * set scroll width to use in other components
     */
    this._scrollWidth.next(scrollWidth);
    /**
     * set page outlet when user open custom outlet `page outlet`
     */
    this._overflow.next(true);
  }

  overflowAuto(): void {
    /**
     * show the scroll bar
     */
    document.body.style.overflow = 'auto';
    /**
     * remove padding from body
     */
    document.body.style.paddingRight = '0';
    /**
     * set scroll width with 0 because we have scroll bar
     */
    this._scrollWidth.next(0);
    /**
     * set page outlet false when user close custom outlet `page outlet`
     */
    this._overflow.next(false);
  }
}
