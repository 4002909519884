<div [formGroup]="form">
  <div formArrayName="rules">
    <div *ngFor="let day of weekdays" class="day">
      <div class="form-field day__checkbox">
        <input
          (change)="changeWeekdayValue(day.weekday)"
          [checked]="day.value"
          id="conditional-time-{{ day.weekday }}"
          type="checkbox"
        />
        <label for="conditional-time-{{ day.weekday }}" class="form-field__label">{{ day.dayName | translate }}</label>
      </div>

      <div *ngIf="day.value" class="day__content">
        <ng-container *ngFor="let intervalDay of form['controls'].rules['controls']; index as i">
          <div *ngIf="intervalDay.value.weekday === day.weekday" [formGroupName]="i" class="day__interval">
            <div class="form-field day__field">
              <label for="day-from-{{ day.weekday }}" class="form-field__label">
                {{ 'WIDGETS.SHARED.FROM' | translate }}
              </label>
              <div class="form-field__content">
                <input
                  id="day-from-{{ day.weekday }}"
                  type="text"
                  formControlName="from"
                  [matAutocomplete]="from"
                  class="form-field__input"
                />
                <mat-autocomplete #from="matAutocomplete">
                  <mat-option [value]="'00:00'">00:00</mat-option>
                </mat-autocomplete>
                <span
                  *ngIf="intervalDay['controls'].from.touched && intervalDay['controls'].from.invalid"
                  class="form-field__error"
                >
                  {{ 'WIDGETS.SHARED.TIME_INFO' | translate }}
                </span>
              </div>
            </div>
            <div class="form-field day__field">
              <label for="day-to-{{ day.weekday }}" class="form-field__label">
                {{ 'WIDGETS.SHARED.TO' | translate }}
              </label>
              <div class="form-field__content">
                <input
                  id="day-to-{{ day.weekday }}"
                  type="text"
                  formControlName="to"
                  [matAutocomplete]="to"
                  class="form-field__input"
                />
                <mat-autocomplete #to="matAutocomplete">
                  <mat-option [value]="'24:00'">24:00</mat-option>
                </mat-autocomplete>
                <span
                  *ngIf="intervalDay['controls'].to.touched && intervalDay['controls'].to.invalid"
                  class="form-field__error"
                >
                  {{ 'WIDGETS.SHARED.TIME_INFO' | translate }}
                </span>
              </div>
            </div>
            <div class="day__controls">
              <button (click)="addIntervalTime(day.weekday, i)" class="svg-button">
                <svg class="svg-button__icon">
                  <use href="../../../../../assets/images/icons.svg#add"></use>
                </svg>
              </button>
              <button *ngIf="showIntervalButtonRemove(day.weekday)" (click)="removeIntervalTime(i)" class="svg-button">
                <svg class="svg-button__icon">
                  <use xlink:href="../../../../../assets/images/icons.svg#remove"></use>
                </svg>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
