import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import { AppSettingsService } from '../settings-designer/settings-app.service';
import { RecommendationsGroupDesigner, StoreService } from '../shared';
import { actions } from './recommendations-groups-designer.actions';
import { RecommendationsGroupsDesignerHttpService } from './recommendations-groups-designer.http.service';
import * as selectors from './recommendations-groups-designer.selectors';
import { State } from './recommendations-groups-designer.type';

@Injectable()
export class RecommendationsGroupsDesignerService extends StoreService<State> {
  readonly currentRecommendationsGroup: Observable<RecommendationsGroupDesigner>;
  readonly recommendationsGroups: Observable<RecommendationsGroupDesigner[]>;
  readonly isLoaded: Observable<boolean>;
  readonly isLoading: Observable<boolean>;
  readonly error: Observable<string>;

  constructor(
    protected _store: Store<State>,
    protected _actions: Actions,
    protected _designerRouter: DesignerRouterService,
    protected _http: RecommendationsGroupsDesignerHttpService,
    protected _settings: AppSettingsService,
  ) {
    super(_store, _actions, selectors);

    this.currentRecommendationsGroup = this._currentRecommendationsGroup;
    this.recommendationsGroups = this._recommendationsGroups;
    this.isLoaded = this._isLoaded;
    this.isLoading = this._isLoading;
    this.error = this._error;
  }

  getRecommendationsGroups(appSlug: string): Observable<boolean> {
    this._store.dispatch(actions.getRecommendationsGroups({ appSlug }));

    return this._finishedAction(actions.getRecommendationsGroupsSuccess, actions.getRecommendationsGroupsError);
  }

  addRecommendationsGroup(group: RecommendationsGroupDesigner): Observable<boolean> {
    this._store.dispatch(actions.addRecommendationsGroup({ group }));

    return this._finishedAction(actions.addRecommendationsGroupSuccess, actions.addRecommendationsGroupError);
  }

  saveRecommendationsGroup(group: RecommendationsGroupDesigner): Observable<boolean> {
    this._store.dispatch(actions.saveRecommendationsGroup({ group }));

    return this._finishedAction(actions.saveRecommendationsGroupSuccess, actions.saveRecommendationsGroupError);
  }

  deleteRecommendationsGroup(groupId: number): Observable<boolean> {
    this._store.dispatch(actions.deleteRecommendationsGroup({ groupId }));

    return this._finishedAction(actions.deleteRecommendationsGroupSuccess, actions.deleteRecommendationsGroupError);
  }

  private get _currentRecommendationsGroup(): Observable<RecommendationsGroupDesigner> {
    return combineLatest([
      this._store.select(selectors.entities),
      this._designerRouter.paramsChanges(['recommendationId']),
    ]).pipe(
      map(([entities, [recommendationId]]) => entities[recommendationId]),
      filter<RecommendationsGroupDesigner>(Boolean),
    );
  }

  private get _recommendationsGroups(): Observable<RecommendationsGroupDesigner[]> {
    return this._store.select(selectors.total);
  }

  private get _isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoaded);
  }

  private get _isLoading(): Observable<boolean> {
    return this._store.select(selectors.isLoading);
  }

  private get _error(): Observable<string> {
    return this._store.select(selectors.error);
  }
}
