import { createAction, props } from '@ngrx/store';

import { Catalog, createType } from '../shared';
import { storeKey } from './catalog.config';

const type = createType(storeKey);

class Actions {
  getCatalog = createAction(type('get by id'), props<{ by: number }>());
  getCatalogIsLoaded = createAction(type('get by id is loaded'));
  getCatalogSuccess = createAction(type('get by id success'), props<{ catalog: Partial<Catalog>; by: number }>());
  getCatalogError = createAction(type('get by id error'), props<{ error: string }>());

  reset = createAction(type('reset'));
}

export const actions = new Actions();
