import { createAction, props } from '@ngrx/store';

import { createType, SettingsArea } from '../shared';
import { storeKey } from './settings.config';

const type = createType(storeKey);
class Actions {
  getAreaSettings = createAction(type('get settings'), props<{ by: number; locationId: number }>());
  getAreaSettingsIsLoaded = createAction(type('get settings is loaded'), props<{ by: number }>());
  getAreaSettingsSuccess = createAction(type('get settings success'), props<{ settings: SettingsArea; by: number }>());
  getAreaSettingsError = createAction(type('get settings error'), props<{ error: string }>());

  patchAreaSettings = createAction(
    type('patch settings'),
    props<{ by: number; locationId: number; settings: Partial<SettingsArea> }>(),
  );

  patchAreaSettingsSuccess = createAction(
    type('patch settings success'),
    props<{ settings: SettingsArea; by: number }>(),
  );

  patchAreaSettingsError = createAction(type('patch settings error'), props<{ error: string }>());
}

export const actions = new Actions();
