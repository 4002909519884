import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { ItemUnavailabilityDisplay } from '@bend/store';
import { SettingsService } from '@bend/store';

import { PopCurrency } from '../../../../widget';
import { PopMenu, PopProduct } from '../../../product-catalog';

@Component({
  selector: 'pop-extended-item',
  templateUrl: './extended-item.component.html',
  styleUrls: ['./extended-item.component.scss'],
})
export class ExtendedItemComponent {
  @Input() product: PopProduct | PopMenu;
  @Input() currency: PopCurrency;
  @Input() isReadOnly: boolean;
  @Input() isConsultingMode: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  @Input() loadingProductId: number;
  @Input() secondColor: string;
  @Input() isMenu: boolean;
  @Output() clicked: EventEmitter<any>;
  primaryColor$: Observable<string>;
  secondColor$: Observable<string>;

  constructor(private _settings: SettingsService) {
    this.clicked = new EventEmitter<boolean>();
    this.primaryColor$ = this._settings.colors.primary;
    this.secondColor$ = this._settings.colors.primaryForeground;
  }

  click(showDetails: boolean, forceDetailsForMenu?: boolean): void {
    this.clicked.emit({ showDetails, forceDetailsForMenu });
  }
}
