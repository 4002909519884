import { PopWidget } from './';
export interface PopPage {
  _id: string;
  cover: {
    url: string;
    alt: string;
  };
  isLive: boolean;
  name: string;
  widgets: PopWidget[];
  appSettings: { phone: { prefix: string; mask: string } };
  settings: PageSettings;
}

export class PageSettings {
  showBackButton: boolean;
  backgroundColor: string;
  pageFont: PageFont;
  tabsStyle: number;
  tabsScrollingBehavior: number;
}

export interface PageFont {
  name: string;
  url: string;
}
export interface Page {
  _id?: string;
  name: string;
  type: string;
  directLinkSettings?: DirectLinkSetting[];
}

export interface DirectLinkSetting {
  name: string;
  value: string;
  url: string;
  language: string;
  isDefault: boolean;
}
