<div *ngIf="isValid; else elseBlock" class="widget-twitter-profile">
  <div id="{{ 'profile-' + uuid }}" class="profile">
    <div class="profile-img-wrapper">
      <img class="profile-img" [src]="attributes.image || placeholderImage" [alt]="attributes.name + ' photo'" />
    </div>
    <div>
      <h2 class="profile-name">{{ attributes.name }}</h2>
      <p class="profile-twitter-id">{{ attributes.twitterId }}</p>
      <button class="btn-timeline" (click)="openTimeline()" [style.backgroundColor]="attributes.mainColor">
        <span>{{ 'TWITTER_PROFILE.TIMELINE' | translate }}</span>
        <img class="chevron-right" alt="" src="../../../assets/img/twitter/chevron-right-white.svg" />
      </button>
    </div>
  </div>
  <div id="{{ 'twitter-timeline-' + uuid }}" class="twitter-timeline-container hidden">
    <a class="twitter-timeline" data-width="100%" data-height="400" [href]="attributes.url">Tweets</a>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-twitter-profile no-valid-msg">{{ noValidMessage }}</div>
</ng-template>
