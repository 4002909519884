import { createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { OrderItemOption,sortByKey } from '../shared';
import { actions } from './order-options.actions';
import { State } from './order-options.type';

export const adapter = createEntityAdapter<OrderItemOption>({
  sortComparer: sortByKey<OrderItemOption>()('id'),
  selectId: (option: OrderItemOption) => `${option.parentId}/${option.sku || option.id}/${option.orderUserId}`,
});

export const initialState: State = adapter.getInitialState({});

const orderItemOptionsReducer = createReducer(
  initialState,
  on(actions.addOrderItemOptions, (state, { orderItemOptions }) => adapter.addMany(orderItemOptions, state)),
  on(actions.upsertOrderItemOptions, (state, { orderItemOptions }) => adapter.upsertMany(orderItemOptions, state)),
  on(actions.updateOrderItemOptions, (state, { orderItemOptions }) => adapter.setAll(orderItemOptions, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return orderItemOptionsReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
