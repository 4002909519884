import { PopSettings } from '../widget';

export interface WidgetFeedback {
  mainColor: string;
  isEditMode: boolean;
  title: string;
  icon: string;
  showName: boolean;
  showComment: boolean;
  showUpload: boolean;
  showPreviousFeedbacks: boolean;
  showStars: boolean;
  style: {
    margin: {
      top: number;
      bottom: number;
    };
    borderRadius: number;
    hasBounds: boolean;
    showBackground: boolean;
  };
  labels: FeedbackLabels;
  pop: PopSettings;
}

export interface Feedback {
  review: number;
  name?: string;
  comment?: string;
  image?: string;
}

export interface FeedbackResponse {
  review: number;
  name?: string;
  comment?: string;
  image?: string;
  createdAt?: string;
}

export interface FeedbackFileUpload {
  url?: string;
  name?: string;
  type?: string;
}

export class FeedbackLabels {
  COMMENT = '';
  FEEDBACK_ADDED = '';
  NAME = '';
  NAME_REQUIRED = '';
  REVIEWS_TITLE = '';
  SUBMIT_BUTTON = '';
  TITLE = '';
  UPLOAD_FAILED = '';
  UPLOAD_PICTURE = '';
  COMMENT_REQUIRED = '';
  IMAGE_REQUIRED = '';
  COMMENT_OR_IMAGE_REQUIRED = '';
}

export enum UploadStatusEnum {
  START = 'start',
  FINISH = 'finish',
}
