import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import { ifPluck, Smile, StoreService } from '../shared';
import { actions } from './smiles.actions';
import * as selectors from './smiles.selectors';
import { GenerateSmiles, State, UpdateSmilesPageId } from './smiles.type';

@Injectable()
export class SmilesService extends StoreService<State> {
  constructor(private _designerRouter: DesignerRouterService, _store: Store<State>, _actions: Actions) {
    super(_store, _actions, selectors);
  }

  get byPage(): Observable<Smile[]> {
    return this._store.pipe(select(selectors.smiles));
  }

  get total(): Observable<number> {
    return this._store.pipe(select(selectors.total));
  }

  get isLoaded(): Observable<boolean> {
    return this._store.pipe(select(selectors.isLoaded));
  }

  get currentIsLoading(): Observable<boolean> {
    return this.current.pipe(map(smile => smile?.isLoading));
  }

  get error(): Observable<string> {
    return this._store.pipe(select(selectors.error));
  }

  get current(): Observable<Smile> {
    return this._designerRouter.paramsChanges(['smileId']).pipe(
      filter(([smileId]) => !!smileId),
      switchMap(([smileId]) => this._store.pipe(select(selectors.entities), ifPluck(smileId))),
    );
  }

  getSmiles(limit: number, skip: number, search: string): Observable<boolean> {
    this._store.dispatch(actions.getSmiles({ limit, skip, search }));

    return this._finishedAction(actions.getSmilesSuccess);
  }

  getSmile(id: string): void {
    this._store.dispatch(actions.getSmile({ id }));
  }

  getSmilesBySearch(limit: number, search: string): Observable<boolean> {
    this._store.dispatch(actions.getSmilesBySearch({ limit, search, skip: 0 }));

    return this._finishedAction(actions.getSmilesBySearchSuccess);
  }

  byIdIsLoading(id: number): Observable<boolean> {
    return this.byId(id).pipe(map(smile => smile?.isLoading));
  }

  byId(id: number): Observable<Smile> {
    return this._store.pipe(
      select(selectors.entities),
      map(smile => smile?.[id]),
    );
  }

  editSmile(smile: Smile): Observable<boolean> {
    this._store.dispatch(actions.editSmile({ smile }));

    return this._finishedAction(actions.editSmileSuccess, actions.editSmileError);
  }

  generateSmiles(generateSmiles: GenerateSmiles): void {
    this._store.dispatch(actions.generateSmiles(generateSmiles));
  }

  updateSmilesPageId(params: UpdateSmilesPageId): Observable<boolean> {
    this._store.dispatch(actions.updateSmilesPageId({ params }));

    return this._finishedAction(actions.updateSmilesPageIdSuccess, actions.updateSmilesPageIdError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.error.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }
}
