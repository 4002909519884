import { Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { PopinComponent } from '../components';

export class PopinBuilder<C, I extends object, R> {
  private _title: string;
  private _component: Type<C>;
  private _componentInputs: I;

  constructor(private readonly _dialog: MatDialog) {}

  setTitle(title: string): PopinBuilder<C, I, R> {
    this._title = title;

    return this;
  }

  setComponent(component: Type<C>): PopinBuilder<C, I, R> {
    this._component = component;

    return this;
  }

  setComponentInputs(componentInputs: I): PopinBuilder<C, I, R> {
    this._componentInputs = componentInputs;

    return this;
  }

  build(): MatDialogRef<PopinComponent, R> {
    return this._dialog.open<PopinComponent, I & { title: string; component: Type<C> }, R>(PopinComponent, {
      data: {
        ...this._componentInputs,
        title: this._title,
        component: this._component,
      },
      panelClass: 'popin-dialog-container',
      minWidth: '600px',
      maxWidth: '1200px',
      maxHeight: '85vh',
    });
  }
}
