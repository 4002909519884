import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DesignerRouterService } from '@designer-store/router';

import {
  AppConfig,
  BottomBar,
  CapacityManagement,
  DeliverySettings,
  DictionaryWorkingHoursIntervals,
  Gdpr,
  Languages,
  PromoCodeSettings,
  SettingsApp,
  SettingsBehavior,
  SettingsCart,
  SettingsCatalog,
  SettingsGeneralApp,
  SettingsGoogleAnalytics,
  SettingsHotjarAnalytics,
  SettingsPayment,
  SettingsPop,
  SettingsPos,
  SettingsTips,
  SmartWifiSettings,
  StoreService,
  TopBar,
  WorkingHours,
} from '../shared';
import { DictionaryHappyHoursIntervals, HappyHours } from '../shared/types/happy-hours.type';
import { actions } from './settings.actions';
import * as selectors from './settings.selectors';
import { State } from './settings.type';

@Injectable()
export class AppSettingsService extends StoreService<State> {
  constructor(
    protected override _store: Store<State>,
    protected override _actions: Actions,
    protected _designerRouter: DesignerRouterService,
  ) {
    super(_store, _actions, selectors);
    this._getSettings.subscribe();
  }

  get isLoaded(): Observable<boolean> {
    return combineLatest([this._store.select(selectors.selectedId), this._store.select(selectors.isLoadedBy)]).pipe(
      map(([selectedId, isLoadedBy]) => isLoadedBy.includes(selectedId)),
    );
  }

  get currencySymbol(): Observable<string> {
    return this.general.pipe(map(generalAppSettings => generalAppSettings?.currency?.symbol));
  }

  get mainColor(): Observable<string> {
    return this.general.pipe(map(generalAppSettings => generalAppSettings?.colors?.primary));
  }

  get appId(): Observable<string> {
    return this.general.pipe(map(generalAppSettings => generalAppSettings?.id));
  }

  get general(): Observable<SettingsGeneralApp> {
    return this._store.select(selectors.general);
  }

  get behavior(): Observable<SettingsBehavior> {
    return this._store.select(selectors.behavior);
  }

  get pop(): Observable<SettingsPop> {
    return this._store.select(selectors.pop);
  }

  get tips(): Observable<SettingsTips> {
    return this._store.select(selectors.tips);
  }

  get workingHours(): Observable<WorkingHours<string, DictionaryWorkingHoursIntervals>> {
    return this._store.select(selectors.workingHours);
  }

  get happyHours(): Observable<HappyHours<string, DictionaryHappyHoursIntervals>> {
    return this._store.select(selectors.happyHours);
  }

  get googleAnalytics(): Observable<SettingsGoogleAnalytics> {
    return this._store.select(selectors.googleAnalytics);
  }

  get hotjarAnalytics(): Observable<SettingsHotjarAnalytics> {
    return this._store.select(selectors.hotjarAnalytics);
  }

  get topBar(): Observable<TopBar> {
    return this._store.select(selectors.topBar);
  }

  get bottomBar(): Observable<BottomBar> {
    return this._store.select(selectors.bottomBar);
  }

  get cart(): Observable<SettingsCart> {
    return this._store.select(selectors.cart);
  }

  get promoCodes(): Observable<PromoCodeSettings> {
    return this._store.select(selectors.promoCodes);
  }

  get productCatalog(): Observable<SettingsCatalog> {
    return this._store.select(selectors.productCatalog);
  }

  get gdpr(): Observable<Gdpr> {
    return this._store.select(selectors.gdpr);
  }

  get capacity(): Observable<CapacityManagement> {
    return this._store.select(selectors.capacity);
  }

  get pos(): Observable<SettingsPos> {
    return this._store.select(selectors.pos);
  }

  get sw(): Observable<SmartWifiSettings> {
    return this._store.select(selectors.sw);
  }

  get languages(): Observable<Languages> {
    return this._store.select(selectors.languages);
  }

  get delivery(): Observable<DeliverySettings> {
    return this._store.select(selectors.delivery);
  }

  get paymentMethods(): Observable<SettingsPayment> {
    return this._store.select(selectors.paymentMethods);
  }

  get config(): Observable<AppConfig> {
    return this._store.select(selectors.config);
  }

  get error(): Observable<string> {
    return this._store.select(selectors.error);
  }

  saveSettings(settings: Partial<SettingsApp>): Observable<boolean> {
    this._designerRouter.appSlug
      .pipe(tap(appSlug => this._store.dispatch(actions.patchAppSettings({ settings, by: appSlug }))))
      .subscribe();

    return this._finishedAction(actions.patchAppSettingsSuccess, actions.patchAppSettingsError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.error.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  deleteApplication(): Observable<boolean> {
    this._store.dispatch(actions.deleteApplication());

    return this._finishedActionStatus(this.error, actions.deleteApplicationSuccess, actions.deleteApplicationError);
  }

  private get _getSettings(): Observable<string> {
    return this._designerRouter.appSlugChanges.pipe(
      tap(appSlug => appSlug && this._store.dispatch(actions.getAppSettings({ by: appSlug }))),
    );
  }
}
