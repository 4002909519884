import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Margin, Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { ShopTeaserDisplayType, ShopTeaserWidget } from './shop-teaser';

@Component({
  selector: 'pop-widget-edit-shop-teaser',
  templateUrl: './shop-teaser.component.html',
  styleUrls: ['./shop-teaser.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditShopTeaserComponent extends WidgetBaseComponent<ShopTeaserWidget> implements OnInit {
  static widgetName = 'shop_teaser';
  @Input() appHost: string;
  @Input() pages: Page[];
  finalUrl: string;
  displayTypes: string[];

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this._initForm();
    this.displayTypes = Object.values(ShopTeaserDisplayType);
    this.onChangeLink();
  }

  onChangeLink(): void {
    this.finalUrl = WidgetHelper.generateFinalUrl(this.form.get('link').value, this.appHost);
  }

  onPageChange(page: Page): void {
    this.link.setValue(page._id);
    this.onChangeLink();
  }

  private _addMissingAttributes(widget: ShopTeaserWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        margin: new Margin(),
        displayType: ShopTeaserDisplayType.STYLE1,
        borderRadius: 10,
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new Margin();
    }

    if (!attributes.style.displayType) {
      attributes.style.displayType = ShopTeaserDisplayType.STYLE1;
    }
  }

  get icon(): UntypedFormControl {
    return this.form.get('icon') as UntypedFormControl;
  }

  get link(): UntypedFormControl {
    return this.form.get('link') as UntypedFormControl;
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        icon: [this.widget.attributes.icon],
        title: [this.widget.attributes.title],
        link: [this.widget.attributes.link, Validators.required],
        subtitle: [this.widget.attributes.subtitle],
        openInNewTab: [this.widget.attributes.openInNewTab],
        style: this.formBuilder.group({
          margin: this.formBuilder.group({
            bottom: [this.widget.attributes.style.margin.bottom],
            top: [this.widget.attributes.style.margin.top],
          }),
          borderRadius: [this.widget.attributes.style.borderRadius],
          displayType: [this.widget.attributes.style.displayType],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
