<div
  *ngIf="isValid; else elseBlock"
  class="widget-welcome"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.marginRight.px]="attributes.style?.fullWidth ? 0 : ''"
  [style.marginLeft.px]="attributes.style?.fullWidth ? 0 : ''"
  [style.color]="attributes.style?.color"
  [style.backgroundColor]="attributes.style?.backgroundColor"
>
  <h2 class="title" [style.color]="attributes.style?.textColor">
    {{ title }}
    <span class="underline" [style.backgroundColor]="attributes.style?.textColor"></span>
  </h2>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-welcome">{{ noValidMessage }}</div>
</ng-template>
