import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Margin, Page, TextAlignWidget } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { fontSizeValidator } from '../../shared/validators/font-size.validator';
import { heightValidator } from '../../shared/validators/height.validator';
import { widthValidator } from '../../shared/validators/width.validator';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { LinkDisplayType, LinkWidget } from './link';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-edit-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditLinkComponent extends WidgetBaseComponent<LinkWidget> implements OnInit {
  static widgetName = 'link';
  @Input() appHost: string;
  @Input() pages: Page[];
  finalUrl: string;
  textAligns: string[];
  typesName: Record<string, string>;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.textAligns = Object.values(TextAlignWidget);
    this._initForm();
    this.onChangeLink();

    const { STYLE1, STYLE2 } = LinkDisplayType;
    this.typesName = {
      [STYLE1]: 'Classic',
      [STYLE2]: 'Elegant',
    };
  }

  private _addMissingAttributes(widget: LinkWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        fontSize: DEFAULT,
        textAlign: TextAlignWidget.CENTER,
        textColor: '',
        height: DEFAULT,
        backgroundColor: '',
        width: DEFAULT,
        borderColor: '',
        borderWidth: 0,
        borderRadius: 15,
        margin: new Margin(),
        iconUrl: '',
        showArrow: false,
        displayType: LinkDisplayType.STYLE1,
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new Margin();
    }

    if (!attributes.style.textAlign) {
      attributes.style.textAlign = TextAlignWidget.CENTER;
    }

    if (!attributes.style.displayType) {
      attributes.style.displayType = LinkDisplayType.STYLE1;
    }
  }

  onFileChange(event: FileUploadDetails): void {
    const urlValue = event.url;
    this.icon.setValue(urlValue);
  }

  onChangeLink(): void {
    this.finalUrl = WidgetHelper.generateFinalUrl(this.form.get('link').value, this.appHost);
  }

  onPageChange(page: Page, formControl: UntypedFormControl): void {
    formControl.setValue(page['_id']);
    this.onChangeLink();
  }

  get icon(): UntypedFormControl {
    return this.form.get('style.iconUrl') as UntypedFormControl;
  }

  get link(): UntypedFormControl {
    return this.form.get('link') as UntypedFormControl;
  }

  get title(): UntypedFormControl {
    return this.form.get('title') as UntypedFormControl;
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }

  get borderColor(): UntypedFormControl {
    return this.form.get('style.borderColor') as UntypedFormControl;
  }

  get borderColorValue(): string {
    return this.borderColor.value;
  }

  set borderColorValue(color: string) {
    this.borderColor.setValue(color);
  }

  get width(): UntypedFormGroup {
    return this.form.get('style.width') as UntypedFormGroup;
  }

  get borderWidth(): UntypedFormControl {
    return this.form.get('style.borderWidth') as UntypedFormControl;
  }

  get borderRadius(): UntypedFormControl {
    return this.form.get('style.borderRadius') as UntypedFormControl;
  }

  get height(): UntypedFormControl {
    return this.form.get('style.height') as UntypedFormControl;
  }

  get textColor(): UntypedFormControl {
    return this.form.get('style.textColor') as UntypedFormControl;
  }

  get textColorValue(): string {
    return this.textColor.value;
  }

  set textColorValue(color: string) {
    this.textColor.setValue(color);
  }

  get fontSize(): UntypedFormControl {
    return this.form.get('style.fontSize') as UntypedFormControl;
  }

  private _initForm(): void {
    const { title, link, openInNewTab, style } = this.widget.attributes;

    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        title: [title],
        link: [link],
        openInNewTab: [openInNewTab],
        style: this.formBuilder.group({
          fontSize: [style.fontSize || DEFAULT, fontSizeValidator()],
          textAlign: [style.textAlign],
          textColor: [WidgetHelper.parseColor(style.textColor)],
          height: [style.height, heightValidator()],
          backgroundColor: [style.backgroundColor],
          borderColor: [style.borderColor],
          borderRadius: [style.borderRadius],
          borderWidth: [style.borderWidth],
          iconUrl: [style.iconUrl],
          showArrow: [style.showArrow],
          width: [style.width, widthValidator()],
          margin: this.formBuilder.group({
            top: [style.margin.top],
            bottom: [style.margin.bottom],
          }),
          displayType: [style.displayType],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
