<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="videoInput" class="form-label">{{ 'WIDGETS.VIDEO.URL' | translate }}</label>
      <div
        *ngIf="url.invalid && (((url.dirty || url.touched) && url.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.URL_ENTER' | translate }}
      </div>
      <div
        *ngIf="url.invalid && (((url.dirty || url.touched) && url.errors.pattern) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.URL_INVALID' | translate }}
      </div>
      <input id="videoInput" type="url" class="form-input form-input--url" placeholder="URL" formControlName="url" />
      <small>Ex: https://www.youtube.com/watch?v=91oxu5pPUZs</small>
    </div>
  </form>
</pop-widget-edit-widget>
