import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ItemUnavailabilityDisplay, ProductRecommendationGroup } from '@bend/store';
import { RecommendationGroup } from '@bend/store-shared';

import { RecommendationsService } from '../../services';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsComponent implements OnInit {
  @Input() productId: number;
  @Input() parentItemAvailability: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;

  groups$: Observable<ProductRecommendationGroup[]>;

  constructor(private recommendationsService: RecommendationsService) {}

  ngOnInit(): void {
    this.groups$ = this.recommendationsService.getRecommendations(this.productId);
  }

  trackByFn(_: number, { id }: RecommendationGroup): number {
    return id;
  }
}
