import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-navigator-progress',
  templateUrl: './navigator-progress.component.html',
  styleUrls: ['./navigator-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigatorProgressComponent {
  isLoading$: Observable<boolean>;

  constructor(private _router: Router) {
    this.isLoading$ = this._router.events.pipe(
      filter(
        event =>
          event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel,
      ),
      map(event => {
        if (event instanceof NavigationEnd || event instanceof NavigationCancel) return false;

        return true;
      }),
    );
  }
}
