import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './widgets.config';
import { adapter } from './widgets.reducer';
import { State } from './widgets.type';

const stateSelector = createFeatureSelector<State>(storeKey);
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);
export const error = createSelector(stateSelector, state => state.error);
export const selectedPageId = createSelector(stateSelector, state => state.selectedPageId);

export const widgets = (widgetIds: string[]) =>
  createSelector(entities, selectedPageId, (widgetsEntities, pageId) =>
    widgetIds.map(id => widgetsEntities[`${pageId}-${id}`]),
  );

export const pageWidgets = createSelector(all, selectedPageId, (widgetsArray, pageId) =>
  widgetsArray.filter(widget => widget.pageId === pageId),
);

export const selectedNextPageId = createSelector(stateSelector, state => state.selectedNextPageId);

export const isLoading = createSelector(
  stateSelector,
  selectedNextPageId,
  (state, pageId) => state.isLoadingByPageId[pageId],
);

export const isLoaded = createSelector(
  stateSelector,
  selectedNextPageId,
  (state, pageId) => state.isLoadedByPageId[pageId],
);

export const selectProductCatalog = createSelector(stateSelector, selectedPageId, (state, pageId) => {
  const all = Object.values(state.entities);

  return all.find(widget => widget.pageId === pageId && widget.type === 'WidgetProductCatalogComponent');
});
