import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { DialogService } from '@bend/dialog';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetDocumentViewerDialogComponent } from './dialog/document-viewer-dialog.component';
import { WidgetDocumentViewer } from './document-viewer';
import { WidgetDocumentViewerService } from './document-viewer.service';

@Component({
  selector: 'pop-widget-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class WidgetDocumentViewerComponent implements OnInit, WidgetComponent {
  static widgetName = 'document_viewer';
  @Input() attributes: WidgetDocumentViewer;

  text: SafeHtml;
  noValidMessage: string;
  isValid: boolean;

  constructor(
    private _warningService: WarningService,
    private _sanitizer: DomSanitizer,
    private _documentViewerService: WidgetDocumentViewerService,
    private _matDialog: MatDialog,
    private _dialog: DialogService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || this.attributes.uuid === undefined) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetDocumentViewerComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this.isValid = true;
    this.text = this.attributes.text ? this._sanitizer.bypassSecurityTrustHtml(this.attributes.text) : '';
  }

  saveViewCount(): void {
    if (this.attributes.isEditMode) {
      return;
    }

    this._documentViewerService.saveViewCount(this.attributes.id).subscribe({
      next: () => {
        this._showDialog();
        this._cdr.detectChanges();
      },
      error: (error: HttpErrorResponse) => {
        const message = error ? error.message : error.message;
        this._dialog.error({ message });
        this._cdr.detectChanges();
      },
    });
  }

  private _showDialog(): void {
    this._matDialog.open(WidgetDocumentViewerDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      data: this.attributes,
      panelClass: 'document-viewer-dialog-overlay',
    });
  }
}
