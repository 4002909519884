import { Injectable } from '@angular/core';

export type TextDirectionType = 'rtl' | 'ltr';

@Injectable({ providedIn: 'root' })
export class TextDirectionService {
  getTextDirection(codeLang: string): TextDirectionType {
    return ['he'].includes(codeLang) ? 'rtl' : 'ltr';
  }
}
