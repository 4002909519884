import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core';

import { OrderItemOption, OrderMenuItem } from '@bend/store-shared';

@Component({
  selector: 'lib-board-order-menu [menu]',
  templateUrl: './board-order-menu.component.html',
  styleUrls: ['./board-order-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrderMenuComponent {
  @Input() menu: OrderMenuItem;

  trackByFn: TrackByFunction<OrderItemOption> = index => index;
}
