import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiVisitorService, Page, PageApi, Widget } from '../shared';

@Injectable()
export class PagesHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  getById(pageId: string, params?: Params): Observable<{ widgets: Widget<any>[]; page: Page }> {
    return this._api.page('v2', pageId).pipe(
      switchMap(api =>
        this._http.get<PageApi>(api, { params: { ...(params && params) } }).pipe(
          map(({ widgets, ...rest }) => ({
            widgets: this._addWidgetIdToAttributes(widgets),
            page: { ...rest, widgets: this._getWidgetsIds(widgets) },
          })),
        ),
      ),
    );
  }

  private _getWidgetsIds(widgets: Widget<any>[]): string[] {
    return widgets.map(({ id }) => id);
  }

  private _addWidgetIdToAttributes(widgets: Widget<any>[]): Widget<any>[] {
    return widgets.map(widget => ({ ...widget, attributes: { ...widget.attributes, idWidget: widget.id } }));
  }
}
