<div class="shared-cart-content dialog-content">
  <button mat-dialog-close class="close-dialog-btn" title="close" [style.backgroundColor]="attributes.mainColor">
    <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" fill="white">
      <path
        d="M11.67 10.08L7.59 6l4.08-4.08c.44-.44.44-1.15 0-1.59-.44-.44-1.15-.44-1.59 0L6 4.41 1.92.33C1.48-.11.77-.11.33.33c-.44.44-.44 1.15 0 1.59L4.41 6 .33 10.08c-.44.44-.44 1.15 0 1.59.44.44 1.15.44 1.59 0L6 7.59l4.08 4.08c.44.44 1.15.44 1.59 0a1.127 1.127 0 0 0 0-1.59z"
      />
    </svg>
  </button>
  <h3 class="product-name">{{ data.product.name }}</h3>
  <div class="title" [style.color]="attributes.mainColor">
    {{ 'PRODUCT_CATALOG.ITS_FOR' | translate }}
  </div>
  <app-spinner *ngIf="isLoading; else content" class="loading" [stroke]="attributes.mainColor"></app-spinner>

  <ng-template #content>
    <div class="buttons-wrapper">
      <div class="person-btn-wrapper" [ngClass]="personButtonClass">
        <button class="person-btn" (click)="selectMe()" data-cy="wrapperBtn">
          <svg
            class="person-img"
            [style.fill]="attributes.mainColor"
            xmlns="http://www.w3.org/2000/svg"
            width="44.988"
            height="46"
            viewBox="0 0 44.988 46"
            clip-rule="evenodd"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="1.414"
          >
            <path
              d="M38.392 39.256c4.397-4.496 6.596-9.898 6.596-16.273 0-6.341-2.199-11.743-6.596-16.239C34.028 2.248 28.712 0 22.477 0 16.276 0 10.993 2.248 6.596 6.744 2.198 11.24 0 16.642 0 22.984c0 6.374 2.198 11.776 6.596 16.272C10.993 43.752 16.276 46 22.477 46c6.235 0 11.55-2.248 15.915-6.744zM22.477 3.892c5.185 0 9.615 1.879 13.224 5.57 3.675 3.758 5.513 8.254 5.513 13.521 0 5.301-1.838 9.831-5.513 13.555-3.61 3.725-8.039 5.604-13.224 5.604-5.119 0-9.548-1.88-13.19-5.604-3.676-3.724-5.48-8.254-5.48-13.555 0-5.267 1.804-9.763 5.48-13.521 3.642-3.691 8.071-5.57 13.19-5.57zm-12.83 23.52c.033.436.197.772.525 1.04 8.17 7.315 16.341 7.315 24.545 0 .295-.268.46-.604.492-1.04.033-.402-.098-.772-.36-1.107a1.493 1.493 0 0 0-1.018-.503c-.427 0-.755.1-1.083.369-6.858 6.14-13.749 6.14-20.673 0-.295-.269-.623-.37-1.017-.37a1.429 1.429 0 0 0-1.017.504c-.295.335-.427.705-.394 1.107zm.263-8.421c0 .838.295 1.576.853 2.18.623.638 1.345.94 2.198.94.854 0 1.575-.302 2.166-.94a2.929 2.929 0 0 0 .919-2.18c0-.906-.295-1.645-.919-2.248-.59-.604-1.312-.873-2.166-.873-.853 0-1.575.269-2.198.873-.558.603-.853 1.342-.853 2.248zm22.083 3.12c.854 0 1.543-.302 2.133-.94a2.929 2.929 0 0 0 .92-2.18c0-.906-.296-1.645-.92-2.248-.59-.604-1.28-.873-2.133-.873s-1.575.269-2.198.873c-.558.603-.853 1.342-.853 2.248 0 .838.295 1.576.853 2.18.623.638 1.345.94 2.198.94z"
            />
          </svg>
          <span>{{ 'CART.MY_ORDER' | translate }}</span>
          <span *ngIf="me">{{ me.name }}</span>
        </button>
      </div>
      <ng-container *ngFor="let orderUser of others">
        <div *ngIf="orderUser.name" class="person-btn-wrapper" [ngClass]="personButtonClass">
          <button class="person-btn" (click)="selectVisitor(orderUser)" data-cy="wrapperBtn">
            <svg
              class="person-img"
              [style.fill]="attributes.mainColor"
              xmlns="http://www.w3.org/2000/svg"
              width="46.034"
              height="46"
              viewBox="0 0 46.034 46"
              clip-rule="evenodd"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="1.414"
            >
              <path
                d="M39.285 39.251c4.499-4.466 6.749-9.905 6.749-16.251 0-6.346-2.25-11.785-6.75-16.285C34.786 2.216 29.38 0 23 0 16.654 0 11.215 2.216 6.715 6.715 2.25 11.215 0 16.654 0 23s2.25 11.785 6.715 16.251C11.215 43.751 16.655 46 23 46c6.38 0 11.785-2.25 16.285-6.749zM23 3.861c5.305 0 9.804 1.88 13.531 5.608C40.258 13.196 42.14 17.695 42.14 23c0 5.272-1.88 9.804-5.608 13.531C32.804 40.292 28.305 42.14 23 42.14c-5.271 0-9.804-1.847-13.531-5.608C5.742 32.804 3.895 28.271 3.895 23c0-5.305 1.847-9.804 5.574-13.531C13.196 5.742 17.729 3.86 23 3.86zm10.476 22.295c.436 0 .806.135 1.108.437.302.302.436.671.436 1.108 0 3.559-1.141 6.279-3.39 8.192-2.049 1.814-4.87 2.72-8.462 2.72-3.76 0-6.648-.873-8.663-2.619l-.033-.033c-2.25-1.88-3.358-4.634-3.392-8.26 0-.437.168-.806.437-1.108a1.47 1.47 0 0 1 1.074-.437zm-1.612 3.022H14.203c.269 1.88 1.007 3.358 2.216 4.466 1.612 1.31 3.828 1.947 6.749 1.947 2.72 0 4.869-.671 6.48-1.98v.033c1.243-1.142 1.981-2.62 2.216-4.466zm3.022-8.025a2.83 2.83 0 0 0 .974-2.182c0-.907-.336-1.645-.974-2.25-.57-.57-1.276-.873-2.149-.873s-1.645.302-2.25.873c-.604.605-.906 1.343-.906 2.25 0 .873.302 1.611.907 2.182.604.638 1.376.94 2.25.94.872 0 1.577-.302 2.148-.94zm-24.343-2.92c-.269.301-.37.67-.302 1.107.033.403.201.739.537 1.007.302.27.672.37 1.075.336l3.894-.403c.403-.033.74-.235 1.008-.57.268-.303.37-.672.336-1.109-.034-.402-.235-.738-.538-1.04a1.682 1.682 0 0 0-1.141-.303l-3.862.437c-.403.033-.738.235-1.007.537z"
              />
            </svg>
            <span>{{ orderUser.name }}</span>
          </button>
        </div>
      </ng-container>
      <div class="person-btn-wrapper" [ngClass]="personButtonClass">
        <button class="person-btn" (click)="selectOtherPeople()" data-cy="wrapperBtn">
          <svg
            class="person-img"
            [style.fill]="attributes.mainColor"
            xmlns="http://www.w3.org/2000/svg"
            width="46.034"
            height="46"
            viewBox="0 0 46.034 46"
            clip-rule="evenodd"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="1.414"
          >
            <path
              d="M39.285 39.251c4.499-4.466 6.749-9.905 6.749-16.251 0-6.346-2.25-11.785-6.75-16.285C34.786 2.216 29.38 0 23 0 16.654 0 11.215 2.216 6.715 6.715 2.25 11.215 0 16.654 0 23s2.25 11.785 6.715 16.251C11.215 43.751 16.655 46 23 46c6.38 0 11.785-2.25 16.285-6.749zM23 3.861c5.305 0 9.804 1.88 13.531 5.608C40.258 13.196 42.14 17.695 42.14 23c0 5.272-1.88 9.804-5.608 13.531C32.804 40.292 28.305 42.14 23 42.14c-5.271 0-9.804-1.847-13.531-5.608C5.742 32.804 3.895 28.271 3.895 23c0-5.305 1.847-9.804 5.574-13.531C13.196 5.742 17.729 3.86 23 3.86zm10.476 22.295c.436 0 .806.135 1.108.437.302.302.436.671.436 1.108 0 3.559-1.141 6.279-3.39 8.192-2.049 1.814-4.87 2.72-8.462 2.72-3.76 0-6.648-.873-8.663-2.619l-.033-.033c-2.25-1.88-3.358-4.634-3.392-8.26 0-.437.168-.806.437-1.108a1.47 1.47 0 0 1 1.074-.437zm-1.612 3.022H14.203c.269 1.88 1.007 3.358 2.216 4.466 1.612 1.31 3.828 1.947 6.749 1.947 2.72 0 4.869-.671 6.48-1.98v.033c1.243-1.142 1.981-2.62 2.216-4.466zm3.022-8.025a2.83 2.83 0 0 0 .974-2.182c0-.907-.336-1.645-.974-2.25-.57-.57-1.276-.873-2.149-.873s-1.645.302-2.25.873c-.604.605-.906 1.343-.906 2.25 0 .873.302 1.611.907 2.182.604.638 1.376.94 2.25.94.872 0 1.577-.302 2.148-.94zm-24.343-2.92c-.269.301-.37.67-.302 1.107.033.403.201.739.537 1.007.302.27.672.37 1.075.336l3.894-.403c.403-.033.74-.235 1.008-.57.268-.303.37-.672.336-1.109-.034-.402-.235-.738-.538-1.04a1.682 1.682 0 0 0-1.141-.303l-3.862.437c-.403.033-.738.235-1.007.537z"
            />
          </svg>
          <span>{{ 'PRODUCT_CATALOG.OTHER_PEOPLE' | translate }}</span>
        </button>
      </div>
    </div>
  </ng-template>
</div>
