import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '../shared';
import { Application, Image, ImageEvent, SortableImage } from '../shared/types/image-gallery.type';
import { FileType } from './image-gallery.type';

@Injectable()
export class ImageGalleryHttpService {
  constructor(
    private _api: ApiDesignerService,
    private _http: HttpClient,
  ) {}

  getApplicationsList(types: FileType[]): Observable<Application[]> {
    return combineLatest([this._getMyApps(types), this._getStudioApps]).pipe(
      map(([myAppsList, studioAppsList]) =>
        myAppsList
          .filter(myApp => studioAppsList.map(studioApp => studioApp.slug).includes(myApp.appSlug))
          .map(myApp => ({
            appSlug: myApp.appSlug,
            countFiles: myApp.countFiles,
            name: studioAppsList.find(studioApp => studioApp.slug === myApp.appSlug).name,
          })),
      ),
    );
  }

  getAppImages(app: string, types: FileType[]): Observable<SortableImage[]> {
    return this._api
      .getImages('v1', app)
      .pipe(
        switchMap(
          (url: string): Observable<SortableImage[]> =>
            this._http
              .get<Image[]>(url, { params: { ['types[]']: types } })
              .pipe(map(images => images.map(img => ({ ...img, relatedToApp: app })))),
        ),
      );
  }

  uploadImage(file: File): Observable<Image> {
    return this._api
      .uploadImage('v1')
      .pipe(
        switchMap(url =>
          this._http.post<Image[]>(url, this._fileToFormData(file)).pipe(map(imageArray => imageArray[0])),
        ),
      );
  }

  uploadCroppedImage(base64: string, fileName: string): Observable<Image> {
    return this._api
      .uploadImage('v1')
      .pipe(
        switchMap(url =>
          this._http
            .post<Image[]>(url, this._base64ToFormData(base64, fileName))
            .pipe(map(imageArray => imageArray[0])),
        ),
      );
  }

  uploadMultipleImages(files: File[]): Observable<ImageEvent> {
    return merge(
      ...files.map(file =>
        this.uploadImage(file).pipe(
          map(img => ({ image: { ...img, relatedToApp: '' }, id: String(file.lastModified) })),
        ),
      ),
    );
  }

  uploadImageByUrl(fileUrl: string): Observable<Image> {
    return this._api.uploadByUrl('v1').pipe(
      switchMap(path => {
        const formData = new FormData();
        formData.append('fileUrl', fileUrl);
        return this._http.post<Image[]>(path, formData).pipe(map(imageArray => imageArray[0]));
      }),
    );
  }

  deleteImage(id: number): Observable<unknown> {
    return this._api.deleteImage(id).pipe(switchMap(path => this._http.delete<unknown>(path)));
  }

  private _getMyApps(types: FileType[]): Observable<Application[]> {
    return this._api
      .getApplications('v1')
      .pipe(
        switchMap(url =>
          this._http
            .get(url, { params: { ['types[]']: types } })
            .pipe(
              map(appList =>
                Object.entries(appList).map(([appSlug, countFiles]) => ({ appSlug, countFiles, name: '' })),
              ),
            ),
        ),
      );
  }

  private get _getStudioApps(): Observable<any[]> {
    return this._api.apps('v1').pipe(switchMap(url => this._http.get<any[]>(url)));
  }

  private _fileToFormData(file: File): FormData {
    const fd = new FormData();
    fd.append('file', file, file.name);
    return fd;
  }

  private _base64ToFormData(dataUrl: string, filename: string): FormData {
    const arr = dataUrl.split(',');
    const mime = /:(.*?);/.exec(arr[0])[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return this._fileToFormData(new File([u8arr], filename, { type: mime }));
  }
}
