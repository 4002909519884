import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiIlluminatiInternService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api.smilein.fr/illuminati/api/v1/admin/${appSlug}/actions
   */
  actions(version: Version): Observable<string> {
    return this.illuminati(version).pipe(this._patch(`/actions`));
  }

  /**
   * @returns https://api.smilein.fr/illuminati/api/v1/admin/${appSlug}
   */
  illuminati(version: Version): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/illuminati/api/${version}/${this._env.userType}/${appSlug}`),
    );
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
