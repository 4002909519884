import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './category.config';
import { adapter } from './category.reducer';
import { State } from './category.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const allEntities = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);

export const error = createSelector(stateSelector, state => state.errorCode);
export const loading = createSelector(stateSelector, state => state.loading);
export const selectedTabId = createSelector(stateSelector, state => state.selectedTabId);
export const catalogId = createSelector(stateSelector, state => state.catalogId);
export const isLoading = createSelector(stateSelector, selectedTabId, (state, tabId) => state.loading[tabId]);

export const loadedTabs = createSelector(stateSelector, state => state.loadedCategoryIds);
