<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row-groups">
      <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
      <designer-image-gallery formControlName="url"></designer-image-gallery>
    </div>
    <div class="form-row">
      <label for="altInput" class="form-label">{{ 'WIDGETS.SHARED.IMAGE_ALT' | translate }}</label>
      <input
        id="altInput"
        class="form-input form-input--alt"
        type="text"
        [placeholder]="'WIDGETS.SHARED.IMAGE_ALT_TEXT' | translate"
        formControlName="alt"
      />
    </div>
    <div class="form-row">
      <label for="linkInput" class="form-label">{{ 'WIDGETS.SHARED.LINK' | translate }}</label>
      <input
        id="linkInput"
        class="form-input form-input--url"
        type="text"
        [placeholder]="'WIDGETS.SHARED.LINK_URL' | translate"
        formControlName="link"
        (keyup)="onChangeUrl()"
      />
      <pop-widget-edit-page-selector
        *ngIf="pages"
        (pageChangeEmmiter)="onPageChange($event, link)"
        [selectedPageId]="link.value"
        [pages]="pages"
      ></pop-widget-edit-page-selector>
      <div class="final-url">
        <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
        <span class="final-url__value">{{ finalUrl }}</span>
      </div>
      <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
    </div>
    <div class="form-row">
      <input id="openInNewTabInput" class="form-input-checkbox" type="checkbox" formControlName="openInNewTab" />
      <label for="openInNewTabInput" class="form-label form-label--checkbox">
        {{ 'WIDGETS.SHARED.EXTERNAL_TAB' | translate }}
      </label>
    </div>
    <div class="form-row-groups-wrapper" formGroupName="text">
      <div class="form-row-groups form-row-groups--distanced">
        <div class="form-row-group form-row-group--distanced">
          <label class="form-label">{{ 'WIDGETS.STYLE.TEXT_MARGIN' | translate }}</label>
          <input type="number" formControlName="margin" min="0" class="form-input form-input--number" />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label class="form-label">{{ 'WIDGETS.STYLE.TEXT_VERTICAL' | translate }}</label>
          <select class="form-input form-input--select" formControlName="verticalAlign">
            <option *ngFor="let alignType of verticalAlignTypes" [value]="alignType">
              {{ alignType | titlecase }}
            </option>
          </select>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="textBackgroundColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.TEXT_BACKGROUND' | translate }}
          </label>
          <span
            id="textBackgroundColorInput"
            [(colorPicker)]="textBackgroundColorValue"
            [cpOutputFormat]="'rgba'"
            class="form-colorpicker"
            [style.background]="textBackgroundColorValue"
          ></span>
        </div>
      </div>
    </div>
    <div formGroupName="text" class="form-row-groups-wrapper">
      <label for="textContentInput" class="form-label">{{ 'WIDGETS.SHARED.TEXT' | translate }}</label>
      <ckeditor id="textContentInput" formControlName="content" [editor]="editor" [config]="editorConfig"></ckeditor>
    </div>
    <div formGroupName="style" class="form-row-groups-wrapper">
      <div class="form-row-groups form-row-groups--distanced">
        <div class="form-row-group form-row-group--distanced" formGroupName="margin">
          <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
          <input
            id="marginTopInput"
            type="number"
            formControlName="top"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced" formGroupName="margin">
          <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
          <input
            id="marginBottomInput"
            type="number"
            formControlName="bottom"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
          <input
            id="borderRadiusInput"
            class="form-input form-input--number"
            type="number"
            formControlName="borderRadius"
            min="0"
          />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="backgroundColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
          </label>
          <span
            id="backgroundColorInput"
            [(colorPicker)]="backgroundColorValue"
            class="form-colorpicker"
            [style.background]="backgroundColorValue"
          ></span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="widthInput" class="form-label">{{ 'WIDGETS.STYLE.WIDTH' | translate }}</label>
          <input
            id="widthInput"
            type="text"
            formControlName="width"
            matInput
            [matAutocomplete]="widthAuto"
            class="form-input form-input--number"
          />
          <mat-autocomplete #widthAuto="matAutocomplete">
            <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
            <mat-option [value]="'full'">{{ 'WIDGETS.STYLE.FULL' | translate }}</mat-option>
          </mat-autocomplete>
          <span class="form-input-number-units">px</span>
          <div class="input-error" *ngIf="width.invalid && width.errors.width">
            {{ 'WIDGETS.SHARED.INTEGER' | translate }}
          </div>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="heightInput" class="form-label">{{ 'WIDGETS.STYLE.HEIGHT' | translate }}</label>
          <input
            id="heightInput"
            type="text"
            formControlName="height"
            matInput
            [matAutocomplete]="heightAuto"
            class="form-input form-input--number"
          />
          <mat-autocomplete #heightAuto="matAutocomplete">
            <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
          </mat-autocomplete>
          <span class="form-input-number-units">px</span>
          <div class="input-error" *ngIf="height.invalid && height.errors.height">
            {{ 'WIDGETS.SHARED.INTEGER' | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
