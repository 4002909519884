export interface RecommendationsGroupDesigner {
  name: string;
  displayLabel: string;
  id?: number;
  recommendations?: RecommendationsItemsDesigner[];
}

export interface RecommendationsDesigner {
  id: string;
  recommendations: RecommendationsItemsDesigner[];
}

export interface RecommendationsItemsDesigner {
  id: number;
  itemId: number;
  order: number;
  recommendationGroupId?: number;
}
