export const enum ButtonType {
  Continue = 'continue',
  Pay = 'pay',
  PayCash = 'pay-cash',
  Send = 'send',
  New = 'new',
  None = 'none',
  Tips = 'tips',
  Collect = 'collect',
}
