import { Injectable } from '@angular/core';
import { PrinterType } from 'projects/store/src/lib/settings/settings-deprecated.type';

import { KioskPrinterService } from './kiosk-printer.service';
import { PLVPrinterService } from './plv-printer.service';

export interface StoreData {
  Lib_Site: string; // store name
  Rue_Site: string; // addresse 1
  Ville_Site: string; // city
  Cp_Site: string; // zip code
  N_Cde: number; // ??? (ex: 32)
  Siret: string;
  N_TVA: string;
  NAF: string;
  Capital_Social: string;
  RCS: string;
  Forme_Juridique: string;
}

export interface KioskOrderDetail {
  commande: {
    numeroCommande: string; // order number
    status: string; // 'Reglé / A reglé'
    mode: string; // 'Sur place / A emporter'
    amount?: number;
  };
  ligne: OrderDetail[];
}

export interface OrderDetail {
  qt: string; // quantity
  Libelle_Article: string; // item label
  Prix_Ligne: number; // item price
  detail?: OrderDetail[];
}

export interface IKioskPrinterService {
  printReceipt(storeData: StoreData, orderDetail: KioskOrderDetail): void;
}

@Injectable()
export class KioskPrinterFactoryService {
  private readonly printerServices: Record<PrinterType, IKioskPrinterService> = {
    [PrinterType.KIOSK]: new KioskPrinterService(),
    [PrinterType.PLV_BROKER]: new PLVPrinterService(),
    [PrinterType.NONE]: undefined,
    [PrinterType.EPSON]: undefined,
    [PrinterType.NOOP_PRINTER]: undefined,
  };

  public getPrinterService(printerType: PrinterType): IKioskPrinterService {
    if (!this.printerServices[printerType]) {
      throw new Error(`Printer type ${printerType} is not  supported for kiosk usage`);
    }
    return this.printerServices[printerType];
  }
}
