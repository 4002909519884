<h1 md-dialog-title>
  <span>Duplicate Page</span>
</h1>
<form (submit)="onSubmit()" [formGroup]="form" #htmlForm="ngForm" class="form">
  <div md-dialog-content>
    <div class="form-row-cols">
      <div class="form-row-cols__col flex-grow-1 form-row-cols__col--max-50-chars">
        <div class="form-row-cols__col__content" [class.input--error]="htmlForm.submitted && form.get('name').invalid">
          <label for="name" class="form-label">
            New Page Name
            <span class="form-label-required">(required)</span>
          </label>
          <input id="name" type="text" formControlName="name" class="form-input" maxlength="50" />
          <ng-container *ngIf="htmlForm.submitted">
            <span *ngIf="form.get('name').hasError('required')" class="form-error-msg">
              Please enter a name for page
            </span>
            <span *ngIf="form.get('name').hasError('maxlength')" class="form-error-msg">
              Page name must not be longer than 50 characters
            </span>
          </ng-container>
          <span class="form-field-info">Limited to 50 characters</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer" md-dialog-actions>
    <button class="btn app-button-color" type="submit">
      Duplicate
      <pop-widget-spinner *ngIf="isSaving" [width]="25" [height]="13"></pop-widget-spinner>
    </button>
    <button class="btn app-button-color" mat-dialog-close>Cancel</button>
  </div>
</form>
