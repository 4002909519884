import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderItemOption } from '../shared';
import * as selectors from './order-options.selectors';
import { State } from './order-options.type';

@Injectable({ providedIn: 'root' })
export class OrderItemOptionsService {
  constructor(private _store: Store<State>) {}

  byIds(orderUserId: number, ...params: number[]): Observable<OrderItemOption[]> {
    const id = params.join('/');

    return this._store.pipe(
      select(selectors.all),
      map(orderItemOptions =>
        orderItemOptions.filter(
          orderItemOption => orderItemOption.parentId === id && orderItemOption.orderUserId === orderUserId,
        ),
      ),
      map(orderItemOptions => orderItemOptions.sort((a, b) => a.order - b.order)),
    );
  }
}
