import { NgModule } from '@angular/core';

import { EPosService } from './e-pos.service';
import { ParserService } from './parser.service';
import { TemplateService } from './template.service';

@NgModule({
  providers: [TemplateService, ParserService, EPosService],
})
export class EPosModule {}
