import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PaymentConfig } from '../shared';
import { actions } from './payment.actions';
import { State } from './payment.type';

export const adapter: EntityAdapter<PaymentConfig> = createEntityAdapter<PaymentConfig>();

export const initialState: State = adapter.getInitialState({
  errorCode: null,
  isLoading: false,
  isLoadingById: null,
  isLoadedByIds: [],
  isLoadedByIdsTruly: [],
  isLoadedByAppSlug: null,
  currentAppSlug: null,
});

const userReducer = createReducer(
  initialState,
  on(actions.getPaymentConfigList, (state, { appSlug }) => ({ ...state, isLoading: true, currentAppSlug: appSlug })),

  on(actions.getPaymentConfigListSuccess, (state, { paymentConfigList, appSlug }) =>
    adapter.setAll(paymentConfigList, {
      ...state,
      isLoading: false,
      isLoadedByAppSlug: appSlug,
    }),
  ),

  on(actions.getPaymentConfigListIsLoaded, actions.getPaymentConfigIsLoaded, state => ({ ...state, isLoading: false })),

  on(
    actions.getPaymentConfigListError,
    actions.getPaymentConfigError,
    actions.addPaymentConfigError,
    actions.updatePaymentConfigError,
    actions.deletePaymentConfigError,
    (state, { errorCode }) => ({ ...state, errorCode, isLoading: false }),
  ),

  on(actions.getPaymentConfig, (state, { id }) => ({ ...state, isLoading: true, isLoadingById: id })),

  on(actions.addPaymentConfigSuccess, (state, { paymentConfig }) =>
    adapter.setOne(paymentConfig, {
      ...state,
      isLoading: false,
    }),
  ),

  on(actions.getPaymentConfigSuccess, (state, { paymentConfig }) =>
    adapter.updateOne(
      { id: paymentConfig.id, changes: paymentConfig },
      {
        ...state,
        isLoading: false,
        isLoadedByIds: [...state.isLoadedByIds, paymentConfig.id],
      },
    ),
  ),

  on(actions.addPaymentConfig, actions.updatePaymentConfig, actions.deletePaymentConfig, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.updatePaymentConfigSuccess, (state, { paymentConfig }) =>
    adapter.updateOne(
      { id: paymentConfig.id, changes: paymentConfig },
      {
        ...state,
        isLoading: false,
      },
    ),
  ),

  on(actions.deletePaymentConfigSuccess, (state, { id }) =>
    adapter.removeOne(id, {
      ...state,
      isLoading: false,
      isLoadedByIds: [...state.isLoadedByIds.filter(element => element !== id)],
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return userReducer(state, action);
}
