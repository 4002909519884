import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import { SettingsService } from '../../../settings'; // TODO: wich Setting service ?
import { ApiVisitorService } from '../../api';
import { OrderItem } from '../../types';

@Injectable({ providedIn: 'root' })
export class PromoCodeService {
  constructor(private _api: ApiVisitorService, private _http: HttpClient, private _settings: SettingsService) {}

  setManual(code: string): Observable<any> {
    return this._api.promoCode('v1').pipe(
      switchMap(api => this._http.post<OrderItem>(api, { code })),
      first(),
    );
  }

  setAuto(): Observable<any> {
    return combineLatest([this._api.promoCode('v1'), this._settings.promoCode.code]).pipe(
      switchMap(([api, code]) => this._http.post<OrderItem>(api, { code })),
      first(),
    );
  }

  remove(id: number): Observable<any> {
    return this._api.orderItem('v2', id).pipe(
      switchMap(api => this._http.delete(api)),
      first(),
    );
  }
}
