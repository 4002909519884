<button type="button" class="show-picker-button" (mouseenter)="openPicker()" (mouseleave)="closePicker()">
  {{ titleEmoji }}
</button>
<div
  [class]="'picker-wrapper ' + 'picker-wrapper__' + pickerPosition"
  [class.rtl]="isRtl$ | async"
  *ngIf="showPicker"
  (mouseenter)="openPicker()"
  (mouseleave)="closePicker()"
  @ngIf
>
  <emoji-mart [darkMode]="false" (emojiClick)="onPickEmoji($event)" @appear @itemHide></emoji-mart>
</div>
