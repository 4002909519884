import { PopMargin } from '../widget';

export interface WidgetImage {
  url: string;
  alt: string;
  link: string;
  openInNewTab: boolean;
  isEditMode: boolean;
  text: {
    content: string;
    margin: number;
    verticalAlign: VerticalAlignType;
    backgroundColor: string;
  };
  style: {
    borderRadius: number;
    backgroundColor: string;
    width: string;
    height: string;
    margin: PopMargin;
  };
}

export enum VerticalAlignType {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}
