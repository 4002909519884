import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FileType } from '@bend/store';

import { isVideoFile } from '@designer-modules/image-gallery/helpers/is-video.helper';

import { GalleryContainerComponent } from '../index';

@Component({
  selector: 'designer-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageGalleryComponent implements OnInit, ControlValueAccessor {
  @Input() imageUrl: string;
  @Input() fileTypes: FileType[] = ['image'];

  private _subscription: Subscription;
  protected readonly isVideoFile = isVideoFile;

  constructor(
    @Self() private ngControl: NgControl,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
  ) {
    this._subscription = new Subscription();
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.imageUrl = this.ngControl.control.value;
  }

  openDialog(): void {
    const dialogModalRef = this._dialog.open(GalleryContainerComponent, {
      width: '70%',
      height: '85%',
      data: { fileTypes: this.fileTypes },
    });

    this._subscription.add(this._imageGalleryAfterClose(dialogModalRef));
  }

  deleteImage(): void {
    this.ngControl.control.patchValue('');
  }

  registerOnChange(): void {
    /* empty */
  }

  registerOnTouched(): void {
    /* empty */
  }

  writeValue(value: string): void {
    this.imageUrl = value;
  }

  private _imageGalleryAfterClose(dialogRef: MatDialogRef<GalleryContainerComponent>): Subscription {
    return dialogRef
      .afterClosed()
      .pipe(
        filter<string>(Boolean),
        tap((url: string) => {
          this.imageUrl = url;
          this.ngControl.control.patchValue(url);
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }
}
