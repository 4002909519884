import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './users.config';
import { adapter } from './users.reducer';
import { State } from './users.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, state => state);
export const users = createSelector(stateSelector, selectAll);

export const error = createSelector(stateSelector, state => state.error);
export const isLoading = createSelector(stateSelector, user => user.isLoading);
export const isLoadedBy = createSelector(stateSelector, state => state.isLoadedBy);
export const total = createSelector(stateSelector, state => state.total);
