import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { VideoWidget } from './video';

@Component({
  selector: 'pop-widget-edit-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditVideoComponent extends WidgetBaseComponent<VideoWidget> implements OnInit {
  static widgetName = 'video';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  get url(): UntypedFormControl {
    return this.form.get('url') as UntypedFormControl;
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        url: [
          this.widget.attributes.url,
          [Validators.required, Validators.pattern('^(https://)(www.youtube.com|youtu.be)/.+$')],
        ],
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
