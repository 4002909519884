import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CatalogParams, CatalogQueryParams } from '../shared';

const paramsSelector = createFeatureSelector<RouterReducerState<any>>('params');

export const queryParams = createSelector(paramsSelector, ({ state }) => state.queryParams);
export const params = createSelector(paramsSelector, ({ state }) => state.params);
// TODO: date?.state exist because in other case we get error by load of page
export const appSlug = createSelector(paramsSelector, data => data?.state.params.appSlug);
export const boothId = createSelector(paramsSelector, ({ state }) => state.params.boothId);
export const pageId = createSelector(paramsSelector, ({ state }) => state.params.pageId);
export const mapId = createSelector(paramsSelector, ({ state }) => state.params.mapId);
export const roomName = createSelector(paramsSelector, ({ state }) => state.params.roomName);
export const date = createSelector(paramsSelector, ({ state }) => state.params.date);
export const locationId = createSelector(paramsSelector, ({ state }) => state.params.locationId);
export const areaId = createSelector(paramsSelector, ({ state }) => state.params.areaId);
export const userId = createSelector(paramsSelector, ({ state }) => state.params.userId);
export const smileId = createSelector(paramsSelector, ({ state }) => state.params.smileId);
export const addItem = createSelector(paramsSelector, ({ state }) => state.queryParams[CatalogQueryParams.AddItem]);
export const addMenu = createSelector(paramsSelector, ({ state }) => state.queryParams[CatalogQueryParams.AddMenu]);
export const tabId = createSelector(paramsSelector, ({ state }) => state.params[CatalogParams.TabId]);
export const paymentConfigId = createSelector(paramsSelector, ({ state }) => state.params.paymentConfigId);
export const language = createSelector(paramsSelector, ({ state }) => state.queryParams.language);
