<dialog-background class="background"></dialog-background>
<div class="info" data-cy="dialogInfo">
  <dialog-icon-wrap>
    <lib-icon icon="dialogInfo" class="info__icon"></lib-icon>
  </dialog-icon-wrap>
  <dialog-content-wrap>
    <dialog-title *ngIf="title$ | async" [innerHTML]="title$ | async"></dialog-title>
    <dialog-description [innerHTML]="description$ | async"></dialog-description>
  </dialog-content-wrap>
  <dialog-button *ngIf="showCloseButton" (click)="close()" class="info__button" data-cy="closeDialogInfoBtn">
    {{ 'OKEY' | translate }}
  </dialog-button>
</div>
