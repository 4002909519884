import { createAction, props } from '@ngrx/store';

import { createType, SubCategory } from '../shared';
import { storeKey } from './sub-category.config';

const type = createType(storeKey);

export const actions = {
  addSubCategories: createAction(type('add subcategories'), props<{ entities: SubCategory[] }>()),
};
