import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-label-lang-confirmation',
  templateUrl: './label-lang-confirmation.component.html',
  styleUrls: ['./label-lang-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelLangConfirmationComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { lang: string }) {}
}
