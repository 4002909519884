import { Injectable } from '@angular/core';

import { StorageService } from '@bend/storage';

import { DialogKey } from '../../../../types';

@Injectable()
export class OrderDialogDisplayedService {
  constructor(private _storage: StorageService) {}

  check(updatedAt: Date, id: number, key: DialogKey): boolean {
    // key name for set in localStorage
    const storageKey = `updatedAt-${key}-${id}`;
    // get updatedAt from localStorage
    const localUpdatedAt = new Date(this._storage.getItem(storageKey));
    // check updatedAt is changed
    const check = updatedAt.getTime() === localUpdatedAt.getTime();

    if (!check)
      // set new updatedAt in localStorage
      this._storage.setItem(storageKey, updatedAt.toISOString());

    return check;
  }
}
