<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="urlInput" class="form-label">{{ 'WIDGETS.ACTION_BAR.WEB_SITE' | translate }}</label>
      <input
        id="urlInput"
        type="url"
        placeholder="URL"
        formControlName="url"
        class="form-input form-input--url"
        autofocus
        (keyup)="onChangeUrl()"
      />
      <div class="final-url">
        <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
        <span class="final-url__value">{{ finalUrl }}</span>
      </div>
      <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
    </div>
    <div class="form-row">
      <label for="emailInput" class="form-label">{{ 'WIDGETS.ACTION_BAR.CONTACT_INFO' | translate }}</label>
      <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email" class="form-error-msg">
        {{ 'WIDGETS.SHARED.EMAIL_VALID' | translate }}
      </div>
      <input
        id="emailInput"
        type="email"
        placeholder="Email"
        formControlName="email"
        class="form-input form-input--email"
      />
    </div>
    <div class="form-row-groups">
      <span class="form-row-groups__caption">{{ 'WIDGETS.ACTION_BAR.FLYER' | translate }}</span>
      <designer-image-gallery formControlName="flyer"></designer-image-gallery>
    </div>
  </form>
</pop-widget-edit-widget>
