import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService, Tag } from '../shared';

@Injectable()
export class TagsHttpService {
  constructor(private readonly _api: ApiDesignerService, private readonly _http: HttpClient) {}

  getAll(): Observable<Tag[]> {
    return this._api.tags('v1').pipe(switchMap(api => this._http.get<Tag[]>(api)));
  }

  getOne(id: number): Observable<Tag> {
    return this._api.tag('v1', id).pipe(switchMap(api => this._http.get<Tag>(api)));
  }

  add(tag: Partial<Tag>): Observable<Tag> {
    return this._api.tags('v1').pipe(switchMap(api => this._http.post<Tag>(api, tag)));
  }

  update(tag: Tag): Observable<Tag> {
    return this._api.tag('v1', tag.id).pipe(switchMap(api => this._http.patch<Tag>(api, tag)));
  }

  delete(id: number): Observable<unknown> {
    return this._api.tag('v1', id).pipe(switchMap(api => this._http.delete(api)));
  }
}
