import { Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopMargin } from '../widget';
import { WidgetComponent } from '../widget.component';
import { WidgetWifi, WifiDisplayType } from './wifi';

@Component({
  selector: 'pop-widget-wifi',
  templateUrl: './wifi.component.html',
  styleUrls: ['./wifi.component.scss'],
})
export class WidgetWifiComponent implements OnInit, WidgetComponent {
  static widgetName = 'wifi';
  @Input() attributes: WidgetWifi;

  isValid: boolean;
  noValidMessage: string;
  displayTypes = WifiDisplayType;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.name) {
      this.noValidMessage = this._warningService.showWarn(WidgetWifiComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }
    this._addMissingAttributes(this.attributes);
    this.isValid = true;
  }

  copy(): void {
    const element: any = document.querySelector('.js-copyinput');
    element.select();

    try {
      navigator.clipboard.writeText(element.value);
    } catch (err) {}
  }

  private _addMissingAttributes(attributes: WidgetWifi): void {
    if (!attributes.style) {
      attributes.style = {
        showBorders: true,
        backgroundColor: '',
        fullWidth: false,
        fontSize: 20,
        displayType: WifiDisplayType.STYLE1,
        margin: new PopMargin(),
        iconUrl: '',
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new PopMargin();
    }
  }
}
