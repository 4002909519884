import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { WidgetService } from '../widgets/widget.service';
import { FileUploadDetails } from './file-upload';

@Component({
  selector: 'pop-widget-edit-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Input() title: string;
  @Input() fileUrl: string;
  @Input() fileName?: string;
  @Input() fileSize?: number;
  @Input() fileType?: string;
  @Input() resize?: string[];
  @Input() minWidth?: number;
  @Input() minHeight?: number;

  @Output() fileChangeEmitter: EventEmitter<FileUploadDetails> = new EventEmitter();

  @ViewChild('fileInputBtn')
  fileInputBtn: any;

  file: FileUploadDetails = {};
  uploadedSizePercent: number;
  displayProgressBar: boolean;
  url: string;
  constructor(
    private widgetService: WidgetService,
    public dialog: MatDialog,
  ) {}

  ngOnChanges(): void {
    this.setFileDetails(this.fileUrl, this.fileSize, this.fileName, this.fileType);
  }

  onChooseFile(fileInputBtn: any): void {
    fileInputBtn.click();
  }

  onFileChange(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length) {
      const file: File = fileList[0];

      const formData = new FormData();
      this.displayProgressBar = true;
      if (this.resize) {
        this.resize.forEach(element => {
          formData.append('actions', element);
        });
      }
      formData.append('file', file);
      this.widgetService.uploadFile(formData).subscribe({
        next: progressEvent => {
          if (progressEvent.type === HttpEventType.UploadProgress) {
            this.uploadedSizePercent = this.calculateUploadedPercent(progressEvent.loaded, progressEvent.total);
          }
          if (progressEvent.type === HttpEventType.Response) {
            const body = progressEvent.body;
            if (body.length > 1) {
              this.setFileDetails(`${body[0].domain}${body[0].relativePath}`, body[0].size, body[0].fileName, body);
            } else if (body.length === 1) {
              this.setFileDetails(`${body[0].domain}${body[0].relativePath}`, body[0].size, body[0].fileName, body);
            } else {
              this.setFileDetails(`${body.domain}${body.relativePath}`, body.size, body.fileName, body);
            }
            this.fileChangeEmitter.emit(this.file);
            this.displayProgressBar = false;
            this.uploadedSizePercent = 0;
          }
        },
        error: () => {
          this.displayProgressBar = false;
        },
      });
    }
  }

  onChangeUrl(event: any): void {
    this.setFileDetails(event.target.value, null, null, null, event.target.value);
    this.fileChangeEmitter.emit(this.file);
  }

  deleteLoadedImage(): void {
    const emptyValue = '';
    (document.getElementById('fileInput') as HTMLInputElement).value = emptyValue;
    this.setFileDetails(emptyValue);
    this.fileChangeEmitter.emit(this.file);
  }

  setFileDetails(url: string, fileSize = 0, fileName?: string, body?: string, resized?: string): void {
    this.file.url = url;
    this.file.name = fileName || this.getFileNameFromUrl(url);
    this.file.size = fileSize;
    this.file.body = body;
    this.file.resized = resized;
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const fileNameWithExtension = url.substring(url.lastIndexOf('/') + 1);
      return fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));
    }

    return '';
  }

  getFileSizeWithUnits(size: number): string {
    if (size) {
      const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
      const UNIT_SCALE = 1024;
      let unit = 0;

      while (size >= UNIT_SCALE) {
        size /= UNIT_SCALE;
        unit++;
      }

      const fixedSize = size.toFixed(2);
      const pointPosition = fixedSize.indexOf('.');
      const computedSize = size.toString() === fixedSize.substring(0, pointPosition) ? size.toString() : fixedSize;

      return computedSize + units[unit];
    }

    return '';
  }

  calculateUploadedPercent(size: number, total: number): number {
    return Math.round((100 * size) / total);
  }
}
