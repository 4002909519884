import {
  DictionaryHappyHoursIntervals,
  ItemUnavailabilityDisplay,
  KioskStylesConfig,
  RecommendationsCartSettings,
  SmartWifiSettings,
} from '@bend/store';

import {
  BusinessLocation,
  CategoriesDisplayType,
  CategoryAlignType,
  CategoryStyleType,
  CollectType,
  Delivery,
  LanguagesConfig,
  MenuItemStyleType,
  MenuStyleType,
  MenuType,
  PlaceType,
  PromoCode,
  TakeAwaySettings,
  UpgradeMethods,
  WorkingHours,
} from '../shared';
import { HappyHours } from '../shared/types/happy-hours.type';

export enum PrinterType {
  NONE = 'none',
  EPSON = 'epson',
  PLV_BROKER = 'plv_broker',
  KIOSK = 'kiosk',
  NOOP_PRINTER = 'noop_printer',
}

export type Printer = {
  type: PrinterType;
  data: {
    printerIp?: string;
    printerPort?: string;
    printerTemplateMeta?: string | null;
  } | null;
};

export interface SettingsDeprecated {
  appType: string;
  description: string;
  language: LanguagesConfig;
  url: string;
  tags: string[];
  _id: string;
  name: string;
  cover: {
    url: string;
    alt: string;
  };
  colors: {
    secondary: string;
    primary: string;
  };
  credit: {
    enabled: boolean;
  };
  period: {
    startAt: null;
    endAt: null;
  };
  social: {
    facebook: string;
    linkedin: string;
    twitter: string;
    instagram: string;
  };
  slug: string;
  page: string;
  gdpr: {
    title: string;
    description: string;
    isSwitchable: boolean;
    children: {
      title: string;
      isSwitchable: boolean;
    }[];
  };
  settings: {
    config: {
      mode: string;
      cartCanBeClosedByCron: boolean;
      orderDisplayIdMax: number;
    };
    orderNotifications: {
      active: boolean;
      emailRecipients: string;
      smsRecipients: string;
    };
    catalog: {
      isReadOnly: boolean;
      allowOrderForOthers: boolean;
      allowCommonPot: boolean;
    };
    capacityManagement: {
      type: string;
      enabled: boolean;
      timeSlot: number;
      capacity: number;
    };
    behaviors: {
      disableAditionsAfterOrder: boolean;
    };
    general: {
      country: string;
    };
    tips: {
      allowed: boolean;
      sku: string;
      options: {
        type: string;
        value: number;
        label: string;
        default: boolean;
      }[];
      restrictions: [
        {
          type: string;
          value: number;
          key: string;
        },
      ];
    };
    cart: {
      isShared: boolean;
      noPayment: boolean;
      askForPickupName: boolean;
      tableAsName: boolean;
      displayNameAsOrderIdPrefix: boolean;
      askCollectTypeOnInitPage: boolean;
      orderIdPrefix: string;
      payBefore: boolean;
      allowPickup: boolean;
      closeAfterMin: number;
      closeAfterFullyPayMin: number;
      showTableName: boolean;
      showMoveMessage: boolean;
      moveBehavior: string;
      autoClosePayBefore: boolean;
      autoClosePayAfter: boolean;
      autoCloseCashPayment: boolean;
      allowSplitBill: boolean;
      allowCashPayment: boolean;
      cashPaymentOnly: boolean;
      allowUserCreateNewSesssion: boolean;
      confirmPayment: boolean;
      multiKitchen: boolean;
      askEmail: boolean;
      askName: boolean;
      askGender: boolean;
      askDateOfBirth: boolean;
      askBirthPlace: boolean;
      askFirstName: boolean;
      askLastName: boolean;
      askPhone: boolean;
      askForShopIdentifier: boolean;
      askForNrOfPeopleAtTable: boolean;
      saveCreditCard: boolean;
      isPaymentRequired: boolean;
      placeType: PlaceType;
      collectTypes: CollectType[];
      recommendations: RecommendationsCartSettings;
      showNrCutlery: boolean;
      showOrderDetails: boolean;
      showOrderPreparationTime: boolean;
      minOrderPreparationTime: number;
      minOrderDeliveryTime: number;
      showOrderHistory: boolean;
      orderForAnotherDay: boolean;
      resetSessionAfterSec: number;
      confirmPaymentAsAPopup: boolean;
      redirectUrl: string;
      verifyTodayOrder: boolean;
      kioskMode: boolean;
      orderMinAmount: number;
      payOnly: boolean;
    };
    defaultPage: null;
    order: {
      enableSound: boolean;
    };
    receipt: {
      footer: string;
      header: string;
    };
    printer: Printer;
    timezone: string;
    currency: {
      code: string;
      symbol: string;
    };
    workingHours: WorkingHours<string>;
    happyHours: HappyHours<string, DictionaryHappyHoursIntervals>;
    promoCode: PromoCode;
    analytics: {
      trackingId: string;
      hotjarId: string;
    };
    pop: {
      tokenRequired: boolean;
      allowReadOnly: boolean;
      readOnlyDisplayMsg: string;
      noAccessDisplayMsg: string;
      tokenExpiresIn: string;
      upgradeMethods: UpgradeMethods;
    };
    delivery: Delivery;
    takeAway: TakeAwaySettings;
    gdpr: {
      showGdpr: boolean;
      url: string;
      labels: {
        RGPD_MESSAGE: string;
        RGPD_SEE_LINK: string;
        RGPD_ACCEPT: string;
        RGPD_REFUSE_ALL: string;
      };
    };
    phone: {
      prefix: string;
    };
    pwa: {
      enabled: boolean;
      manifest: {
        name: string;
        short_name: string;
        theme_color: string;
        background_color: string;
        display: string;
        Scope: string;
        start_url: string;
        icons: [
          {
            src: string;
            sizes: string;
            type: string;
          },
        ];
        splash_pages: null;
      };
      icon: string;
      themeColor: string;
      backgroundColor: string;
      showAddAfterLaunch: boolean;
      showAddAfterPayment: boolean;
      showAddButtonInTopBar: boolean;
      labels: {
        INSTALL: string;
        MESSAGE1_ANDROID: string;
        MESSAGE2_ANDROID: string;
        MESSAGE1_IOS: string;
        MESSAGE2_IOS: string;
        TITLE: string;
        CART_TITLE: string;
        CART_SUBTITLE: string;
        CART_INSTALL: string;
        CART_NOT_NOW: string;
      };
    };
    ui: {
      labels: {
        GENERIC_ERROR: string;
      };
      topBar: {
        display: boolean;
        icons: {
          home: string;
          brand: string;
        };
        allowUserProfile: boolean;
      };
      bottomBar: {
        display: boolean;
        cart: {
          display: boolean;
          attributes: {
            labels: {
              PAY: string;
              TIP: string;
              CART: string;
              CASH: string;
              MENU: string;
              PAID: string;
              SEND: string;
              TIPS: string;
              APPLY: string;
              I_PAY: string;
              ORDER: string;
              PARTS: string;
              READY: string;
              SPLIT: string;
              TOTAL: string;
              AMOUNT: string;
              DELETE: string;
              TO_PAY: string;
              ADD_TIP: string;
              MY_CART: string;
              OR_SCAN: string;
              PAYMENT: {
                AMOUNT: string;
                OR_SCAN: string;
                PURCHASE: string;
                WILL_PAY: string;
                ERROR_CVV: string;
                ERROR_DATE: string;
                CARD_NUMBER: string;
                CREDIT_CARD: string;
                MAESTRO_CVV: string;
                SECURE_TEXT: string;
                CLIENT_ERROR: string;
                ERROR_NUMBER: string;
                CANCEL_PAYMENT: string;
                EMAIL_OPTIONAL: string;
                EXPIRATION_DATE: string;
                PAYMENT_DECLINED: string;
                REDIRECTION_PAYPAL: string;
                PAYMENT_UNAVAILABLE: string;
                REDIRECTION_GOOGLEPAY: string;
                REDIRECTION_BANCONTACT: string;
                REDIRECTION_VISACHECKOUT: string;
              };
              SUMMARY: string;
              WAITING: string;
              PURCHASE: string;
              SPLIT_IN: string;
              WILL_PAY: string;
              CASH_TEXT: string;
              ERROR_CVV: string;
              MIN_ORDER: string;
              NEW_ORDER: string;
              NO_THANKS: string;
              PAY_ITEMS: string;
              PREPARING: string;
              REDUCTION: string;
              REMAINING: string;
              SEE_CHART: string;
              SHOW_CART: string;
              THANK_YOU: string;
              TIP_ERROR: string;
              VIA_PHONE: string;
              YOUR_NAME: string;
              CANCEL_TIP: string;
              CHANGE_TIP: string;
              COMMON_POT: string;
              ERROR_DATE: string;
              I_WILL_PAY: string;
              MIN_AMOUNT: string;
              SPLIT_BILL: string;
              SUBVENTION: string;
              TABLE_CART: string;
              TOTAL_CART: string;
              YOUR_ORDER: string;
              CARD_NUMBER: string;
              CHANGE_CART: string;
              CREDIT_CARD: string;
              INFORMATION: string;
              MAESTRO_CVV: string;
              MOVE_MYSELF: string;
              MOVE_OTHERS: string;
              PAY_MY_CART: string;
              SECURE_TEXT: string;
              TOP_PAYMENT: string;
              ALREADY_CODE: string;
              APPLY_COUPON: string;
              BACK_TO_CART: string;
              CLIENT_ERROR: string;
              COUPON_LABEL: string;
              ERROR_NUMBER: string;
              FIXED_AMOUNT: string;
              HIDE_DETAILS: string;
              IS_PREPARING: string;
              POS_NO_STOCK: string;
              PRICE_TO_PAY: string;
              SELECT_A_TIP: string;
              SEND_MY_CART: string;
              TOTAL_TO_PAY: string;
              CALL_A_WAITER: string;
              CODE_IS_EMPTY: string;
              FLOATING_SEND: string;
              PAY_TO_WAITER: string;
              REMOVE_CHANGE: string;
              SEND_ALL_CART: string;
              TIP_ERROR_AND: string;
              TOP_SPLITBILL: string;
              TOTAL_ORDERED: string;
              CANCEL_PAYMENT: string;
              CODE_INCORRECT: string;
              DISCOUNT_LABEL: string;
              EMAIL_IS_EMPTY: string;
              EMAIL_OPTIONAL: string;
              SELECT_PAYMENT: string;
              VIA_PHONE_TEXT: string;
              WAITER_CONFIRM: string;
              BACK_SUBVENTION: string;
              ORDER_USER_MOVE_M_TO_S: string;
              ORDER_USER_MOVE_S_TO_M: string;
              EMAIL_INCORRECT: string;
              ENJOY_YOUR_MEAL: string;
              EXPIRATION_DATE: string;
              FINISH_MY_ORDER: string;
              ORDER_VALIDATED: string;
              PROMO_NOT_FOUND: string;
              SECURED_PAYMENT: string;
              WAITING_PAYMENT: string;
              YOUR_SUBVENTION: string;
              CAPACITY_MESSAGE: string;
              COMPLETE_MY_CART: string;
              ORDER_USER_PLACE_CLOSED: string;
              MIN_AMOUNT_ERROR: string;
              PAYMENT_DECLINED: string;
              POS_FROZEN_ITEMS: string;
              POS_REFUND_ERROR: string;
              PROMO_CODE_LABEL: string;
              PROMO_CODE_TITLE: string;
              PROMO_MIN_AMOUNT: string;
              SPLIT_COMMON_POT: string;
              ALREADY_REDUCTION: string;
              CLICK_FOR_DETAILS: string;
              CONTINUE_SHOPPING: string;
              GO_TO_THE_COUNTER: string;
              PAYMENT_FORBIDDEN: string;
              POS_GENERIC_ERROR: string;
              POS_SENDING_ITEMS: string;
              TABLE_NAME_PREFIX: string;
              CHART_NON_ACCEPTED: string;
              ENTER_COUPON_LABEL: string;
              REDIRECTION_PAYPAL: string;
              SPLIT_AMONG_GUESTS: string;
              YOUR_CART_IS_EMPTY: string;
              AMOUNT_ITEM_GREATER: string;
              EMAIL_ADDRESS_LABEL: string;
              PAYMENT_IN_PROGRESS: string;
              PAYMENT_SUCCESSFULL: string;
              PAYMENT_UNAVAILABLE: string;
              YOUR_ORDER_IS_READY: string;
              CALL_A_WAITER_BOTTOM: string;
              CAPACITY_MESSAGE_NOW: string;
              CHART_REQUIRED_LABEL: string;
              POS_RESTAURANT_CLOSE: string;
              POS_SEND_ORDER_ERROR: string;
              POS_UNKNOWN_PRODUCTS: string;
              WE_WILL_LET_YOU_KNOW: string;
              YOUR_ITEMS_WERE_SENT: string;
              ORDER_USER_MOVE_BEHAVIOR_NEW: string;
              REDIRECTION_GOOGLEPAY: string;
              A_NAME_FOR_IDENTIFYING: string;
              ENTER_PROMO_CODE_LABEL: string;
              NAME_USAGE_EXPLANATION: string;
              PLEASE_ASSIGN_AT_LEAST: string;
              REDIRECTION_BANCONTACT: string;
              ORDER_USER_MOVE_OTHER_BUSINESS_LOCATION: string;
              YOU_CAN_CHECK_THE_ORDER: string;
              REDIRECTION_VISACHECKOUT: string;
              ENTER_EMAIL_ADDRESS_LABEL: string;
              PAYMENT_CASH_NO_NEW_ITEMS: string;
              PAYMENT_SUCCESS_PAY_AFTER: string;
              PROMO_MAXIM_USAGE_REACHED: string;
              ORDER_USER_MOVE_DISABLED_SETTINGS: string;
              PAYMENT_SUCCESS_PAY_BEFORE: string;
              POS_SEND_ORDER_IN_PROGRESS: string;
              POS_SEND_TRANSACTION_ERROR: string;
              POS_SEND_TRANSACTION_IN_PROGRESS: string;
              SESSION_EXPIRED_DUE_TO_INACTIVITY: string;
              PROMO_MULTIPLE_PROMO_SAME_CART_USER: string;
              USER_REOPEN_SESSION_BAD_APP_SETTINGS: string;
              USER_REOPEN_SESSION_BAD_SESSION_STATUS: string;
              PAYMENT_AMOUNT_EXCEEDED_TOTAL_AMOUNT_TO_PAY: string;
            };
          };
        };
      };
      styles: {
        general: {
          productCatalogStyle: number;
        };
        widgets: {
          product_teaser: {
            productDescriptionStyle: number;
          };
          product_catalog: {
            style: number;
            productDescriptionStyle: number;
            hasSearch: boolean;
            stepByStepCategories: boolean;
            stepByStepMenus: boolean;
            menuType: MenuType;
            menuItemStyleType: MenuItemStyleType;
            itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
            menuStyleType: MenuStyleType;
            categoriesStyle: CategoriesDisplayType;
            categoryStyleType: CategoryStyleType;
            categoryAlignType: CategoryAlignType;
          };
        };
      };
    };
    integrationType: number;
    businessLocation: BusinessLocation;
    signIn: {
      enabled: boolean;
    };
    sw: SmartWifiSettings;
    kiosk: KioskStylesConfig;
    newsletters: {
      enabled: boolean;
    };
  };
}
