<button mat-mini-fab color="accent" [matMenuTriggerFor]="menu" class="mat-elevation-z0">
  {{ langSelect.value | titlecase }}
</button>
<mat-menu #menu>
  <button
    mat-menu-item
    *ngFor="let lang of languages"
    (click)="changeLang(lang)"
    [class.active]="lang === langSelect.value"
  >
    {{ languagesLabels[lang] }} ({{ lang | titlecase }})
  </button>
</mat-menu>
