import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { widgetActions } from './widget.actions';
import { WidgetModel } from './widget.models';
import { WidgetState } from './widget.state';

export const widgetAdapter: EntityAdapter<WidgetModel> = createEntityAdapter<WidgetModel>({
  selectId: ({ _id }) => _id,
  sortComparer: (a, b) => a.order - b.order,
});

const initialState: WidgetState = widgetAdapter.getInitialState({
  isLoadingWidgetsList: false,
  isDuplicatingWidgetById: {},
  isPublishingWidgetById: {},
  isDeletingWidgetById: {},
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(widgetActions.getWidgets, state => ({ ...state, isLoadingWidgetsList: true })),
  on(widgetActions.getWidgetsSuccess, (state, { widgets }) =>
    widgetAdapter.setAll(widgets, { ...state, isLoadingWidgetsList: false }),
  ),
  on(widgetActions.getWidgetsError, (state, { error }) => ({ ...state, isLoadingWidgetsList: false, error })),

  on(widgetActions.duplicateWidget, (state, { widgetId }) => ({
    ...state,
    isDuplicatingWidgetById: { ...state.isDuplicatingWidgetById, [widgetId]: true },
  })),
  on(widgetActions.duplicateWidgetSuccess, (state, { widgetId, widget }) =>
    widgetAdapter.addOne(widget, {
      ...state,
      isDuplicatingWidgetById: { ...state.isDuplicatingWidgetById, [widgetId]: false },
    }),
  ),
  on(widgetActions.duplicateWidgetError, (state, { widgetId, error }) => ({
    ...state,
    isDuplicatingWidgetById: { ...state.isDuplicatingWidgetById, [widgetId]: false },
    error,
  })),

  on(widgetActions.publishWidget, (state, { widgetId }) => ({
    ...state,
    isPublishingWidgetById: { ...state.isPublishingWidgetById, [widgetId]: true },
  })),
  on(widgetActions.publishWidgetSuccess, (state, { widgetId, published }) =>
    widgetAdapter.updateOne(
      { id: widgetId, changes: { published } },
      {
        ...state,
        isPublishingWidgetById: { ...state.isPublishingWidgetById, [widgetId]: false },
      },
    ),
  ),
  on(widgetActions.publishWidgetError, (state, { widgetId, error }) => ({
    ...state,
    isPublishingWidgetById: { ...state.isPublishingWidgetById, [widgetId]: false },
    error,
  })),

  on(widgetActions.deleteWidget, (state, { widgetId }) => ({
    ...state,
    isDeletingWidgetById: { ...state.isDeletingWidgetById, [widgetId]: true },
  })),
  on(widgetActions.deleteWidgetSuccess, (state, { widgetId }) =>
    widgetAdapter.removeOne(widgetId, {
      ...state,
      isDeletingWidgetById: { ...state.isDeletingWidgetById, [widgetId]: false },
    }),
  ),
  on(widgetActions.deleteWidgetError, (state, { widgetId, error }) => ({
    ...state,
    isDeletingWidgetById: { ...state.isDeletingWidgetById, [widgetId]: false },
    error,
  })),

  on(widgetActions.changeWidgetOrder, state => state),
  on(widgetActions.changeWidgetOrderSuccess, (state, { widgets }) => widgetAdapter.upsertMany(widgets, state)),
  on(widgetActions.changeWidgetOrderError, (state, { widgets, error }) =>
    widgetAdapter.upsertMany(widgets, { ...state, error }),
  ),

  on(widgetActions.reset, state => widgetAdapter.removeAll(state)),
);
