import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HistoryService } from '@bend/history';

import {
  ArrayStore,
  ifPluckArray,
  ItemError,
  ItemMeta,
  ItemMetaPromoCode,
  OrderItem,
  OrderItemCreationMode,
  OrderItemStatus,
  OrderItemType,
} from '../shared';
import * as selectors from './order-items.selectors';
import { State } from './order-items.type';

@Injectable({ providedIn: 'root' })
export class OrderItemsService implements ArrayStore<OrderItem> {
  all: Observable<OrderItem[]>;
  id: Observable<number[]>;
  status: Observable<OrderItemStatus[]>;
  sku: Observable<string[]>;
  type: Observable<OrderItemType[]>;
  quantity: Observable<number[]>;
  price: Observable<number[]>;
  discountedAmount: Observable<number[]>;
  comment: Observable<string[]>;
  orderUserId: Observable<number[]>;
  orderLocationId: Observable<number[]>;
  itemMeta: Observable<(ItemMeta | ItemMetaPromoCode)[]>;
  updatedAt: Observable<Date[]>;
  creationMode: Observable<OrderItemCreationMode[]>;
  errorCode: Observable<ItemError[]>;
  orderId: Observable<number[]>;
  wasSentToPos: Observable<boolean[]>;
  wasRemovedFromPos: Observable<boolean[]>;

  constructor(private _store: Store<State>, private _history: HistoryService) {
    this.all = this._all;
    this.id = this._all.pipe(ifPluckArray('id'));
    this.status = this._all.pipe(ifPluckArray('status'));
    this.sku = this._all.pipe(ifPluckArray('sku'));
    this.type = this._all.pipe(ifPluckArray('type'));
    this.quantity = this._all.pipe(ifPluckArray('quantity'));
    this.price = this._all.pipe(ifPluckArray('price'));
    this.discountedAmount = this._all.pipe(ifPluckArray('discountedAmount'));
    this.comment = this._all.pipe(ifPluckArray('comment'));
    this.orderUserId = this._all.pipe(ifPluckArray('orderUserId'));
    this.orderLocationId = this._all.pipe(ifPluckArray('orderLocationId'));
    this.itemMeta = this._all.pipe(ifPluckArray('itemMeta'));
    this.updatedAt = this._all.pipe(ifPluckArray('updatedAt'));
    this.creationMode = this._all.pipe(ifPluckArray('creationMode'));
    this.errorCode = this._all.pipe(ifPluckArray('errorCode'));
    this.orderId = this._all.pipe(ifPluckArray('orderId'));
    this.wasSentToPos = this._all.pipe(ifPluckArray('wasSentToPos'));
    this.wasRemovedFromPos = this._all.pipe(ifPluckArray('wasRemovedFromPos'));
  }

  /**
   * @description get all items by type single
   */
  get allSingle(): Observable<OrderItem[]> {
    return this._all.pipe(map(this._filterByItemType));
  }

  /**
   * @description get all items for one location
   */
  byLocation(userId: number, locationId: number): Observable<OrderItem[]> {
    return this.userSingleItems(userId).pipe(
      // filter items by locationId
      map(items => items.filter(item => item.orderLocationId === locationId)),
    );
  }

  /**
   * @description get total for all items
   */
  allTotal(orderId: number): Observable<number> {
    return this._all.pipe(
      map(items => items.filter(({ orderId: currentOrderId }) => orderId === currentOrderId)),
      map(this._calculatePrice),
    );
  }

  discountedAmounts(orderId: number): Observable<number> {
    return this._all.pipe(
      map(items => items.filter(({ orderId: currentOrderId }) => orderId === currentOrderId)),
      map(this._calculateDiscountedAmounts),
    );
  }

  /**
   * @description get total for multiple users
   */
  usersTotal(usersId: number[]): Observable<number> {
    return combineLatest(usersId.map(id => this.userTotal(id))).pipe(
      map(prices => prices.reduce((acc: number, price) => acc + price, 0)),
    );
  }

  /**
   * @description get total for multiple users
   */
  usersTotalAll(usersId: number[]): Observable<number> {
    return combineLatest(usersId.map(id => this.userTotalAll(id))).pipe(
      map(prices => prices.reduce((acc: number, price) => acc + price, 0)),
    );
  }

  /**
   * @description get user total
   */
  userTotal(userId: number): Observable<number> {
    return this.userSingleItems(userId).pipe(map(this._calculatePrice));
  }

  /**
   * @description get user total with all types of items (promo, fee)
   */
  userTotalAll(userId: number): Observable<number> {
    return this.userItems(userId).pipe(map(this._calculatePrice));
  }

  selectedTotal(itemIds: number[]): Observable<number> {
    return this._all.pipe(
      map(items => items.filter(({ id }) => itemIds.includes(id))),
      map(this._calculatePrice),
    );
  }

  fee(userId: number): Observable<OrderItem | undefined> {
    return this.userItems(userId).pipe(map(items => items.find(({ type }) => type === OrderItemType.DeliveryFee)));
  }

  promoCode(userId: number): Observable<OrderItem<OrderItemType.Promo> | undefined> {
    return this.userItems(userId).pipe(map(items => items.find(this._isPromoCode)));
  }

  /**
   * @description get all single items for one user
   */
  userSingleItems(userId: number): Observable<OrderItem[]> {
    return this.userItems(userId).pipe(
      // get only product items
      map(this._filterByItemType),
    );
  }

  /**
   * @description get all items for one user
   */
  userItems(userId: number): Observable<OrderItem[]> {
    return this._all.pipe(
      // filter items by userId
      map(items => items.filter(item => item.orderUserId === userId)),
    );
  }

  /**
   * @description get all items by order id
   */
  byOrderId(orderId: number): Observable<OrderItem[]> {
    return this.all.pipe(map(items => items.filter(({ orderId: orderVisitorId }) => orderId === orderVisitorId)));
  }

  private get _all(): Observable<OrderItem[]> {
    return this._store.pipe(select(selectors.all), this._filterByFee());
  }

  /**
   * @return only product items
   * @description remove Type.Fee, Type.Promo and Status.NextForPreparing items
   */
  private _filterByItemType(items: OrderItem[]): OrderItem[] {
    /**
     * allow only items by type single
     */
    return items.filter(({ type }) => type === OrderItemType.Single);
  }

  /**
   * @description remove Type.Fee if is not in details page or is not paid
   */
  private _filterByFee(): OperatorFunction<OrderItem[], OrderItem[]> {
    /**
     * remove fee is order is new and user is not in details page
     */
    return switchMap(items =>
      this._history.currentUrlChanged.pipe(
        map(url =>
          items.filter(({ type, status }) => {
            if (type !== OrderItemType.DeliveryFee) return true;

            if (status !== OrderItemStatus.New) return true;

            return /(cart\/)/.test(url);
          }),
        ),
      ),
    );
  }

  /**
   * @description calculate total price for items
   */
  private _calculatePrice(items: OrderItem[]): number {
    return (
      items
        // all products have a quantity
        // default quantity is 1
        // multiply the item price by item quantity and sum all the products
        .reduce((acc: number, { price, quantity }) => price * quantity + acc, 0)
    );
  }

  private _calculateDiscountedAmounts(items: OrderItem[]): number {
    return (
      items
        // sum of all discounts
        .reduce((acc, { quantity, discountedAmount }) => discountedAmount * quantity + acc, 0)
    );
  }

  private _isPromoCode(item: OrderItem): item is OrderItem<OrderItemType.Promo> {
    return item.type === OrderItemType.Promo;
  }
}
