import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-toggle',
  templateUrl: './arrow-toggle.component.html',
  styleUrls: ['./arrow-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowToggleComponent {
  @Input() isExpanded = false;
  @Input() color = '#000';
}
