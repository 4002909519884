import { createAction, props } from '@ngrx/store';

import { createType, Smile } from '../shared';
import { storeKey } from './smiles.config';
import { GenerateSmiles, GetSmiles, GetSmilesSuccess, UpdateSmilesPageId } from './smiles.type';

const type = createType(storeKey);

export const actions = {
  getSmile: createAction(type('get smile'), props<{ id: string }>()),
  getSmileSuccess: createAction(type('get smile success'), props<Smile>()),
  getSmileError: createAction(type('get smile error')),

  getSmiles: createAction(type('get smiles'), props<GetSmiles>()),
  getSmilesSuccess: createAction(type('get smiles success'), props<GetSmilesSuccess>()),
  getSmilesError: createAction(type('get smiles error')),

  getSmilesBySearch: createAction(type('get smiles by search'), props<GetSmiles>()),
  getSmilesBySearchSuccess: createAction(type('get smiles by search success'), props<GetSmilesSuccess>()),
  getSmilesBySearchError: createAction(type('get smiles by search error')),

  editSmile: createAction(type('edit smile'), props<{ smile: Smile }>()),
  editSmileSuccess: createAction(type('edit smile success'), props<{ id: string; smile: Smile }>()),
  editSmileError: createAction(type('edit smile error')),

  getNewSmiles: createAction(type('get new smiles'), props<GetSmiles>()),
  getNewSmilesSuccess: createAction(type('get new smiles success'), props<GetSmilesSuccess>()),
  getNewSmilesError: createAction(type('get new smiles error')),

  generateSmiles: createAction(type('generate smiles'), props<GenerateSmiles>()),
  generateSmilesSuccess: createAction(type('generate smiles success')),
  generateSmilesError: createAction(type('generate smiles error')),

  updateSmilesPageId: createAction(type('update smiles page id'), props<{ params: UpdateSmilesPageId }>()),
  updateSmilesPageIdSuccess: createAction(type('update smiles page id success')),
  updateSmilesPageIdError: createAction(type('update smiles page id error')),
};
