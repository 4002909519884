import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './order-items.config';
import { metaReducers, reducer } from './order-items.reducer';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer, { metaReducers })],
})
export class OrderItemsStoreModule {}
