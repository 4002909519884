import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Application } from '@bend/store/src/lib/shared/types/image-gallery.type';

@Component({
  selector: 'designer-gallery-menu-presenter [appSlug]',
  templateUrl: './gallery-menu-presenter.component.html',
  styleUrls: ['./gallery-menu-presenter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryMenuPresenterComponent {
  /*
   * List of applications that the user has access to
   * */
  @Input() list: Application[];
  /*
   * Current application (selected by user)
   * */
  @Input() currentApp: string;
  /*
   * AppSlug from params (current application)
   * */
  @Input() appSlug: string;
  /*
   * Emmit value on app change
   * */
  @Output() appChange: EventEmitter<string> = new EventEmitter<string>();

  changeApplication(app: string): void {
    this.appChange.emit(app);
  }
}
