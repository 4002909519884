import { CapacityManagement } from '@bend/store-shared';

export interface PopCatalog {
  id: number;
  name: string;
  applicationId?: number;
  businessLocationId?: number;
  posMenuId?: string;
  isPosImportable: boolean;
}

export interface PopCatalogForDuplicate {
  catalogs: { id: number; name: string }[];
  name: string;
  copyTvas: boolean;
  copyTags: boolean;
}

export interface Location {
  id: number;
  name: string;
  slug: string;
  capacityManagement: CapacityManagement;
  isReadOnly: boolean;
}
