<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="htagInput" class="form-label">{{ 'WIDGETS.TWITTER.HTAG' | translate }}</label>
      <div
        *ngIf="htag.invalid && (((htag.dirty || htag.touched) && htag.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.TWITTER.HTAG_ENTER' | translate }}
      </div>
      <input
        id="htagInput"
        type="text"
        class="form-input form-input--twitter-htag"
        placeholder="#Hashtag"
        formControlName="htag"
      />
      <span class="form-field-info">Ex: #WatsonSummitFR</span>
    </div>
    <div class="form-row">
      <label for="twitterWidgetIdInput" class="form-label">{{ 'WIDGETS.TWITTER.ID' | translate }}</label>
      <div
        *ngIf="
          twitterWidgetId.invalid &&
          (((twitterWidgetId.dirty || twitterWidgetId.touched) && twitterWidgetId.errors.required) || showErrors)
        "
        class="form-error-msg"
      >
        {{ 'WIDGETS.TWITTER.ID_ENTER' | translate }}
      </div>
      <input
        id="twitterWidgetIdInput"
        type="text"
        class="form-input form-input--twitter-id"
        [placeholder]="'WIDGETS.TWITTER.ID' | translate"
        formControlName="twitterWidgetId"
      />
      <span class="form-field-info">{{ 'WIDGETS.TWITTER.WIDGET_INFO' | translate }}</span>
    </div>
  </form>
</pop-widget-edit-widget>
