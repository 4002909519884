import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RecommendationStoreModule } from '../recommendation';
import { storeKey } from './recommendation-group.config';
import { RecommendationGroupEffects } from './recommendation-group.effects';
import { RecommendationGroupHttpService } from './recommendation-group.http.service';
import { reducer } from './recommendation-group.reducer';
import { RecommendationGroupService } from './recommendation-group.service';

@NgModule({
  imports: [
    StoreModule.forFeature(storeKey, reducer),
    EffectsModule.forFeature([RecommendationGroupEffects]),
    RecommendationStoreModule,
  ],
  providers: [RecommendationGroupHttpService, RecommendationGroupService],
})
export class RecommendationGroupStoreModule {}
