import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService,GenericPromoCode } from '../shared';
import { GetPromoCodes } from './promo-codes.type';

@Injectable()
export class PromoCodesHttpService {
  constructor(private _api: ApiDesignerService, private _http: HttpClient) {}

  getPromoCodes(limit: number, offset: number): Observable<GetPromoCodes> {
    return this._api.promoCodes('v1').pipe(
      switchMap(api =>
        this._http.get<GetPromoCodes>(api, {
          params: {
            limit: limit.toString(),
            offset: offset.toString(),
          },
        }),
      ),
    );
  }

  addPromoCode(promoCode: GenericPromoCode): Observable<GenericPromoCode> {
    return this._api.promoCodes('v1').pipe(switchMap(api => this._http.post<GenericPromoCode>(api, promoCode)));
  }

  savePromoCode(promoCode: GenericPromoCode, promoCodeId: number): Observable<GenericPromoCode> {
    return this._api
      .promoCode('v1', promoCodeId)
      .pipe(switchMap(api => this._http.patch<GenericPromoCode>(api, promoCode)));
  }

  deletePromoCode(promoCodeId: number): Observable<GenericPromoCode> {
    return this._api.promoCode('v1', promoCodeId).pipe(switchMap(api => this._http.delete<GenericPromoCode>(api)));
  }
}
