<div class="scanner">
  <div class="scanner__spinner" *ngIf="!isCameraFound || !hasPermission">
    <app-spinner [stroke]="color$ | async" class="spinner"></app-spinner>
  </div>
  <zxing-scanner
    [previewFitMode]="'cover'"
    (scanSuccess)="scanSuccessHandler($event)"
    (hasDevices)="onCameraFound($event)"
    (permissionResponse)="permissionResponse($event)"
    class="scanner__zxing"
  ></zxing-scanner>
</div>

<div class="button-container">
  <app-button-small (click)="closeDialog()">
    <span [innerHTML]="'QR_READER.STOP_SCAN' | translate"></span>
  </app-button-small>
</div>
