import { Injectable, Type } from '@angular/core';

import { PopWidget } from './types';

@Injectable()
export class WidgetHelperService {
  findFactoryClass(factories: any, widget: PopWidget): any {
    const factoryClass = factories.find((x: any) => {
      return (
        (typeof x.widgetName === 'string' ? [x.widgetName] : x.widgetName).includes(widget.type) ||
        (x.widgetName === 'custom' && widget.type === 'custom_neonomade')
      );
    }) as Type<any>;

    return factoryClass;
  }
}
