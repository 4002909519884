import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { IConfig } from 'ngx-mask';
import { BehaviorSubject, iif, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { SettingsService } from '@bend/store';

import { InputGeneric } from '../../../../../../../shared-widgets/src/lib/helpers';
import { Country } from '../../../../../../../shared-widgets/src/lib/types';
import { Countries } from '../../../../config';
import { ModalService } from '../../services';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhoneComponent extends InputGeneric implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() placeHolder = '';
  @ViewChild('input') input: ElementRef;
  color$: Observable<string>;
  country$: Observable<Country>;

  control: UntypedFormControl;
  customPatterns: IConfig['patterns'];
  isDisabled: boolean;

  private _phone: string;
  private _prefix: string;
  private _selectCountry: Subject<Country>;
  private _preCompletedCountry: BehaviorSubject<Country | undefined>;
  private _invalidWriteCountry: boolean;
  private _checkValidation: boolean;
  private _subscription: Subscription;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _cdr: ChangeDetectorRef,
    private _settings: SettingsService,
    private _modal: ModalService,
  ) {
    super();

    if (this.ngControl) this.ngControl.valueAccessor = this;

    this.control = new UntypedFormControl();
    this.customPatterns = { x: { pattern: /[0-9]/ }, z: { pattern: /[1-9]/ } };

    this._selectCountry = new Subject();
    this._preCompletedCountry = new BehaviorSubject(undefined);

    this.color$ = this._settings.colors.primary;
    this.country$ = merge(this._defaultCountry, this._selectedCountry);

    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    // update control value when ngx-mask is changed
    const controlSubscription = this.control.valueChanges.subscribe(value => this._changePhone(value));
    this._subscription.add(controlSubscription);

    if (this.notExistControl) return;

    const ngControlSubscription = this.ngControl.control.valueChanges.subscribe(() => {
      if (this.control.invalid) this.ngControl.control.setErrors({ 'phone-invalid': true }, { emitEvent: false });

      this._cdr.detectChanges();
    });

    this._subscription.add(ngControlSubscription);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  select(): void {
    if (this.isDisabled) return;

    const options = Countries.map(country => ({ value: country, label: country.name }));

    const modalSubscription = this._modal
      .select(options, 0)
      .pipe(
        // TODO: remove any in generic
        filter<any>(Boolean),
        tap(({ value: { prefix } }) => this._changePrefix(prefix)),
        tap(({ value }) => this._selectCountry.next(value)),
        tap(() => this.input.nativeElement.focus()),
      )
      .subscribe();

    this._subscription.add(modalSubscription);
  }

  enable(): void {
    if (this.isExistControl) this.ngControl.control.enable();
  }

  /**
   * @description ControlValueAccessor
   */
  writeValue(phone: string): void {
    if (!phone) return;

    const country = this._findCountry(phone);

    if (!country) {
      this._invalidWriteCountry = true;
      return;
    }

    this._preCompletedCountry.next(country);

    const { prefix } = country;

    // remove prefix from phone
    const phoneWithoutPrefix = phone.replace(prefix, '');
    this.control.setValue(phoneWithoutPrefix);
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.notExistControl) return;

    // to validate an input, it must be removed from disabled.
    // When it becomes disabled comes a false event  and you have to skip this event
    if (!isDisabled && this._checkValidation) return;

    if (isDisabled) {
      this._disableState();
    } else {
      this._enableState();
    }

    this._cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private _enableState(): void {
    this.control.enable({ emitEvent: false });

    // focuses only when the previous state was true
    if (this.isDisabled) {
      this.input.nativeElement.focus();
    }

    this.isDisabled = false;
  }

  private _disableState(): void {
    if (this._checkValidation) {
      this._checkValidation = false;
      return;
    }

    // https://github.com/angular/angular/issues/11447
    // enable for check validation
    this._checkValidation = true;
    this.ngControl.control.enable({ emitEvent: false });

    const { invalid } = this.ngControl.control;

    // invalidWriteCountry is set when the mask is not valid when the controller is initialized
    if (invalid || this._invalidWriteCountry) {
      // if the item is invalid make input enabled
      this.ngControl.control.reset();

      this._invalidWriteCountry = false;
    } else {
      this.ngControl.control.disable({ emitEvent: false });
      this.control.disable({ emitEvent: false });

      this.isDisabled = true;
    }
  }

  private get _defaultCountry(): Observable<Country> {
    return this._preCompletedCountry.pipe(
      switchMap(preCompletedCountry =>
        iif(
          () => !!preCompletedCountry,
          // use country from precomputed input
          of(preCompletedCountry),
          // use country from settings app
          this._settings.phone.prefix.pipe(map(prefix => Countries.find(country => country.prefix === prefix))),
        ),
      ),
      filter<Country>(Boolean),
      tap(({ prefix }) => (this._prefix = prefix)),
    );
  }

  private get _selectedCountry(): Observable<Country> {
    return this._selectCountry.pipe(tap(({ prefix }) => (this._prefix = prefix)));
  }

  private _changePhone(phone: string): void {
    if (phone === undefined || this._phone === phone) return;

    this._phone = phone;

    this._changeNgControl();
  }

  private _changePrefix(prefix: string): void {
    if (this._phone === undefined || this._prefix === prefix) return;

    this._prefix = prefix;

    this._changeNgControl();
  }

  private _changeNgControl(): void {
    const phone = this._phone ? `${this._prefix}${this._phone}` : null;

    this.onChange(phone);
    this.onTouched(phone);
  }

  /**
   * @param phone string (+37360000000)
   * @returns Country or undefined
   */
  private _findCountry(phone: string): Country | undefined {
    const country = Countries.find(({ prefix, mask }) => {
      if (phone.includes(prefix)) {
        const number = phone.replace(prefix, '');

        const maskStrExp = mask
          // remove all characters from mask
          .replace(/[., -]/g, '')
          // replace x for use in regex 0-9
          .replace(/x/g, '[0-9]')
          // replace x for use in regex 1-9
          .replace(/z/g, '[1-9]')
          // only allow the exact length
          .concat('$');

        const maskRegExp = new RegExp(maskStrExp);

        return maskRegExp.test(number);
      }

      return false;
    });

    return country;
  }
}
