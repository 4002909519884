import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, Subscription, tap } from 'rxjs';

import { LabelLangConfirmationComponent } from '../label-lang-confirmation/label-lang-confirmation.component';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnDestroy {
  @Output() selected: EventEmitter<string>;

  languages = ['en', 'fr', 'de', 'dk', 'he'];

  private _subscription: Subscription;

  constructor(private _dialog: MatDialog) {
    this.selected = new EventEmitter();
    this._subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  select(lang: string): void {
    const dialogRef = this._dialog.open(LabelLangConfirmationComponent, {
      minWidth: '300px',
      maxWidth: '600px',
      data: {
        lang,
      },
    });

    this._subscription.add(
      dialogRef
        .afterClosed()
        .pipe(
          filter(Boolean),
          tap(() => this.selected.emit(lang)),
        )
        .subscribe(),
    );
  }
}
