import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService,SettingsArea } from '../shared';

@Injectable()
export class SettingsHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getAreas(locationId: number): Observable<{ id: number; businessLocationId: number; name: string }[]> {
    return this._api
      .allAreas('v1')
      .pipe(
        switchMap(api =>
          this._http.get<{ id: number; businessLocationId: number; name: string }[]>(api, {
            params: { businessLocationId: locationId.toString() },
          }),
        ),
      );
  }

  getArea(areaId: number): Observable<SettingsArea> {
    return this._api.areaSettings('v1', areaId).pipe(switchMap(url => this._http.get<SettingsArea>(url)));
  }

  patchArea(data: Partial<SettingsArea>, areaId: number, businessLocationId: number): Observable<SettingsArea> {
    return this._api
      .areaSettings('v1', areaId)
      .pipe(
        switchMap(url =>
          this._http.patch<SettingsArea>(url, data, { params: { businessLocationId: businessLocationId.toString() } }),
        ),
      );
  }
}
