import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'designer-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() color = '';
  @Input() icon = '';
  @Input() buttonLabel = '';
  @Output() closeSnackbar = new EventEmitter<void>();

  @HostBinding('style.--var-background-color')
  get backgroundColor(): string {
    return this.color;
  }
}
