import { createReducer, on } from '@ngrx/store';

import { appStateError, appStateSuccess, loadAppState } from './actions';
import { AppState } from './state';

const appState: Readonly<AppState> = {
  isPayOnlyMode: false,
  isConsultingMode: false,
  isHappyHours: false,
  loading: false,
  error: null,
};

export const appStateReducer = createReducer(
  appState,

  on(loadAppState, state => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(appStateSuccess, (state, props) => {
    return {
      ...state,
      isConsultingMode: props.isConsultingMode,
      isPayOnlyMode: props.isPayOnlyMode,
      loading: false,
    };
  }),

  on(appStateError, (state, props) => {
    return {
      ...state,
      error: props.errorCode,
      loading: false,
    };
  }),
);
