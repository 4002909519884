import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { iif, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { selectAppSlugParam } from '@designer-store/router';

import { actions } from './settings.actions';
import { SettingsHttpService } from './settings.http.service';
import * as selectors from './settings.selectors';
import { State } from './settings.type';

@Injectable()
export class SettingsEffects {
  loadAppSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getAppSettings),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedBy))),
      mergeMap(([{ by }, isLoadedBy]) =>
        iif(
          () => isLoadedBy.includes(by),
          of(actions.getAppSettingsIsLoaded()),
          this._settingsHttp.getApp().pipe(
            map(settings => actions.getAppSettingsSuccess({ by, settings: { ...settings, id: by } })),
            catchError(({ message }) => of(actions.getAppSettingsError({ error: message }))),
          ),
        ),
      ),
    ),
  );

  patchAppSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.patchAppSettings),
      mergeMap(({ by, settings }) =>
        this._settingsHttp.patchApp(settings).pipe(
          map(patchedSettings => actions.patchAppSettingsSuccess({ by, settings: { ...patchedSettings, id: by } })),
          catchError(({ message }) => of(actions.patchAppSettingsError({ error: message }))),
        ),
      ),
    ),
  );

  deleteApplication$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.deleteApplication),
      mergeMap(() =>
        this._settingsHttp.deleteApplication().pipe(
          concatLatestFrom(() => this._store.select(selectAppSlugParam)),
          map(([, appSlug]) => actions.deleteApplicationSuccess({ appSlug })),
          catchError(({ message }) => of(actions.deleteApplicationError({ error: message }))),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _settingsHttp: SettingsHttpService, private _store: Store<State>) {}
}
