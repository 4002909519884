export const enum PaymentProviderStatus {
  PaymentNotAllowed = 'PAYMENT_NOT_ALLOWED',
  PaymentSuccess = 'SUCCESS',
  PaymentCanceled = 'CANCELED',
}

export const enum PaymentProviderErrorLabels {
  ApplePayNotAllowed = `CART.DIALOG.PAYMENT.APPLE_PAY.PAYMENT_NOT_ALLOWED`,
  GooglePayNotAllowed = `CART.DIALOG.PAYMENT.GOOGLE_PAY.PAYMENT_NOT_ALLOWED`,
}
