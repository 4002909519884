import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'showHoursFromDate' })
export class ShowHoursPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const date = new Date(value);
      return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
    }
    return value;
  }
}
