import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'designer-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {
  /**
   * @description
   * Define text size, font-weight and underscore style
   */
  @Input() type: 'title' | 'subtitle' = 'title';
  /**
   * Set divider visibility
   * */
  @Input() divider = true;
}
