import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isNumeric } from '../../helpers';

export function widthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = control.value === 'default' || control.value === 'full' || isNumeric(control.value as string);
    return valid ? null : { width: { value: control.value } };
  };
}
