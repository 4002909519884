import { weekDaysConfig } from '@designer-config';
import { WorkingHoursDisplay, WorkingHoursItem } from '@designer-shared';

const DAY_START = 'startOfDay';
const DISPLAY_DAY_START = 'Day start';

const DAY_END = 'endOfDay';
const DISPLAY_DAY_END = 'Day end';

export function SetWorkingHoursDisplay(items: WorkingHoursItem[]): WorkingHoursDisplay[] {
  if (!items) {
    return [];
  }

  return weekDaysConfig.map(({ dayNr, dayName }) => {
    const itemsDay = items.filter(item => item.weekDay === dayNr);

    const workInterval = itemsDay.map(({ id, open, close }) => ({
      id,
      open: open === DAY_START ? DISPLAY_DAY_START : open,
      close: close === DAY_END ? DISPLAY_DAY_END : close,
    }));

    return { dayNr, dayName, workInterval };
  });
}
