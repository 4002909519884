<section class="action-bar">
  <h2 class="action-bar__title">{{ data.title }}</h2>

  <button mat-icon-button class="action-bar__close" (click)="onCloseClicked()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</section>

<section class="content">
  <ng-template designerPopinContent></ng-template>
</section>
