import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { LanguageSelectComponent } from './language-select.component';

@NgModule({
  declarations: [LanguageSelectComponent],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatSelectModule, MatButtonModule, MatMenuModule],
  exports: [LanguageSelectComponent],
})
export class LanguageSelectModule {}
