export interface SettingsTips {
  allowed: boolean;
  options: TipsOption[];
  restrictions: TipsRestriction[];
}

export interface TipsOption {
  type: TipsType;
  value: number;
  label: string;
  default: boolean;
}

export interface TipsRestriction {
  type: TipsType;
  key: string;
  value: number;
}

export enum TipsType {
  Absolute = 'absolute',
  Percentage = 'percent',
}

export enum TipsAmountType {
  Percent5 = 5,
  Percent10 = 10,
}
