import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './working-hours.config';
import { WorkingHoursEffectsService } from './working-hours.effects';
import { WorkingHoursHttpService } from './working-hours.http.service';
import { reducer } from './working-hours.reducer';
import { WorkingHoursStoreService } from './working-hours.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([WorkingHoursEffectsService])],
  providers: [WorkingHoursHttpService, WorkingHoursStoreService],
})
export class WorkingHoursStoreModule {}
