import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './smile-history.config';
import { SmileHistoryEffects } from './smile-history.effects';
import { SmileHistoryHttpService } from './smile-history.http.service';
import { reducer } from './smile-history.reducer';
import { SmileHistoryService } from './smile-history.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([SmileHistoryEffects])],
  providers: [SmileHistoryService, SmileHistoryHttpService],
})
export class SmileHistoryStoreModule {}
