import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload/file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import {
  Action,
  ActionCallDisplayType,
  ActionCallWidget,
  ActionCallWidgetAttributes,
  ActionChecked,
  ActionType,
  TransmitionType,
} from './action-call';
import { ActionCallService } from './action-call.service';

export enum ActionTypeDisplay {
  BREAD = 'Bread',
  WATER = 'Water',
  CHECK = 'Check',
  WAITER = 'Waiter',
  CUSTOM = 'Custom',
}

export enum TransmitionTypeDisplay {
  TELEPHONE = 'Phone',
  SMS = 'SMS',
  EMAIL = 'Email',
  POS = 'POS',
  DESIGNER = 'Designer',
}

@Component({
  selector: 'pop-widget-edit-action-call',
  templateUrl: './action-call.component.html',
  styleUrls: ['./action-call.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditActionCallComponent extends WidgetBaseComponent<ActionCallWidget> implements OnInit {
  static widgetName = 'action_call';

  @Input() designerUrl: string;

  showErrors: boolean;
  displayTypes: string[];
  actionTypes: string[];
  transmitionTypes: string[];
  actionTypesDisplay: string[];
  transmitionTypesDisplay: string[];

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
    private _actionCallService: ActionCallService,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    this.displayTypes = Object.values(ActionCallDisplayType);
    this.actionTypes = Object.values(ActionType);
    this.transmitionTypes = Object.values(TransmitionType);
    this.actionTypesDisplay = Object.values(ActionTypeDisplay);
    this.transmitionTypesDisplay = Object.values(TransmitionTypeDisplay);
  }

  addAction(): void {
    const control = this.form.controls['actionButtons'] as UntypedFormArray;
    control.push(
      this._formBuilder.group({
        title: [null, Validators.required],
        checked: [false],
        actionMessage: [null],
        icon: [null],
        description: [null],
        telephone: [null],
        sms: [null],
        email: [null],
        pos: [null],
        body: [null],
        addTableName: [false],
        directMessage: [false],
        from: [null],
        subject: [null],
        type: [ActionType.CUSTOM],
        transmitionType: [TransmitionType.TELEPHONE],
      }),
    );
  }

  changeActionType(event: any, index: number): void {
    const actionType = event.value as ActionType;

    const formGroup = (this.form.controls['actionButtons'] as UntypedFormArray).controls[index] as UntypedFormGroup;
    if (actionType === ActionType.CUSTOM) {
      formGroup.patchValue({
        title: null,
        checked: false,
        actionMessage: null,
        icon: null,
        description: null,
        telephone: null,
        sms: null,
        email: null,
        body: null,
        addTableName: false,
        directMessage: false,
        from: null,
        subject: null,
        type: ActionType.CUSTOM,
      });
    } else {
      const actionButton = this.createActionTypeObject(actionType);
      formGroup.patchValue(actionButton);
    }
  }

  createActionTypeObject(type: ActionType): Action {
    const actionButton = this._actionCallService
      .getDefaultActions()
      .find((actionType: Action) => actionType.type === type);
    return actionButton;
  }

  changeTransmitionType(event: any, index: number): void {
    const transmitionType = event.value as TransmitionType;

    const formGroup = (this.form.controls['actionButtons'] as UntypedFormArray).controls[index] as UntypedFormGroup;
    const actionButton = this.createTransmitionTypeObject(transmitionType);
    formGroup.patchValue(actionButton);
  }

  createTransmitionTypeObject(transmitionType: TransmitionType): Action {
    const actionButton = this._actionCallService
      .getDefaultActions()
      .find((actionType: Action) => actionType.transmitionType === transmitionType);
    return actionButton;
  }

  deleteAction(index: number): void {
    const control = this.form.controls['actionButtons'] as UntypedFormArray;
    control.removeAt(index);
  }

  onFileChange(fileDetails: FileUploadDetails, formControl: UntypedFormControl): void {
    formControl.setValue(fileDetails.url);
  }

  private _initForm(): void {
    const selectedActions = this.widget.attributes.actionButtons;
    const customActions = this._getCustomActions(selectedActions);
    const customControls = this._generateFormGroups(customActions, this._formBuilder);
    const form = this._generateForm(customControls, this._formBuilder, this.widget.attributes);

    this._widgetFormServiceChild.initAttributes(form);
    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _getCustomActions(selectedActions: Action[]): ActionChecked[] {
    const customActions: ActionChecked[] = selectedActions.map(customAction => {
      return {
        ...customAction,
        checked: true,
      };
    });

    return customActions;
  }

  private _generateForm(
    customActions: UntypedFormGroup[],
    formBuilder: UntypedFormBuilder,
    attributes: ActionCallWidgetAttributes,
  ): UntypedFormGroup {
    const form = formBuilder.group({
      actionButtons: formBuilder.array(customActions),
      icon: [attributes.icon],
      title: [attributes.title, Validators.required],
      popupHeader: [attributes.popupHeader],
      style: formBuilder.group({
        margin: formBuilder.group({
          bottom: [attributes.style.margin.bottom],
          top: [attributes.style.margin.top],
        }),
        borderRadius: [attributes.style.borderRadius],
        displayType: [attributes.style.displayType],
      }),
    });

    return form;
  }

  private _generateFormGroups(actions: ActionChecked[], formBuilder: UntypedFormBuilder): UntypedFormGroup[] {
    const groups = actions.map(action =>
      formBuilder.group({
        title: [action.title, Validators.required],
        checked: [action.checked],
        actionMessage: [action.actionMessage],
        icon: [action.icon],
        description: [action.description],
        type: [action.type],
        transmitionType: [action.transmitionType],
        telephone: [action.telephone],
        sms: [action.sms],
        email: [action.email],
        pos: [action.pos],
        body: [action.body],
        addTableName: [action.addTableName],
        directMessage: [action.directMessage],
        from: [action.from],
        subject: [action.subject],
      }),
    );

    return groups;
  }

  get actionButtons(): UntypedFormControl[] {
    return (this.form.get('actionButtons') as UntypedFormArray).controls as UntypedFormControl[];
  }

  get icon(): UntypedFormControl {
    return this.form.get('icon') as UntypedFormControl;
  }

  get title(): UntypedFormControl {
    return this.form.get('title') as UntypedFormControl;
  }

  getIconValue(i: number): string {
    return this.form.get('actionButtons').value[i].icon;
  }

  getButtonStatus(index: number): UntypedFormControl {
    const buttonGroup = this.form.controls['actionButtons'] as UntypedFormGroup;
    const buttonArray = buttonGroup.controls[index] as UntypedFormArray;
    const buttonTitle = buttonArray.controls['title'];
    return buttonTitle;
  }
}
