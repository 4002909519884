import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiNotificationsService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api-test.smilein.io/notifications/api/v1/admin/${appSlug}/default-settings
   */
  defaultSettings(version: Version): Observable<string> {
    return this.notifications(version).pipe(this._patch(`/default-settings`));
  }

  /**
   * @returns https://api-test.smilein.io/notifications/api/v1/admin/${appSlug}/settings
   */
  settings(version: Version): Observable<string> {
    return this.notifications(version).pipe(this._patch(`/settings`));
  }

  /**
   * @returns https://api-test.smilein.io/notifications/api/v1/admin/${appSlug}/settings/${notificationId}
   */
  notification(version: Version, notificationId: number): Observable<string> {
    return this.notifications(version).pipe(this._patch(`/settings/${notificationId}`));
  }

  /**
   * @returns https://api-test.smilein.io/notifications/api/v1/admin/${appSlug}/settings
   */
  notificationType(version: Version): Observable<string> {
    return this.notifications(version).pipe(this._patch(`/settings`));
  }

  /**
   * @returns https://api-test.smilein.io/notifications/api/v1/admin/${appSlug}/settings/${notificationId}
   */
  addNotification(version: Version): Observable<string> {
    return this.notifications(version).pipe(this._patch(`/settings`));
  }

  /**
   * @returns https://api-test.smilein.io/notifications/api/v1/admin/${appSlug}
   */
  notifications(version: Version): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/notifications/api/${version}/${this._env.userType}/${appSlug}`),
    );
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
