import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { toggleX } from '@bend/animations';
import { SettingsService } from '@bend/store';

@Component({
  selector: 'app-button-small',
  templateUrl: './button-small.component.html',
  styleUrls: ['./button-small.component.scss'],
  animations: [toggleX],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSmallComponent {
  @Input() type = 'button';
  @Input() loading = false;
  @Output() clicked: EventEmitter<any>;
  bgPrimary$: Observable<string>;
  fgPrimary$: Observable<string>;

  constructor(private _settings: SettingsService) {
    this.bgPrimary$ = this._settings.colors.primary;
    this.fgPrimary$ = this._settings.colors.primaryForeground;
    this.clicked = new EventEmitter<any>();
  }

  click(): void {
    this.clicked.emit();
  }
}
