import { FormControl } from '@angular/forms';

import { PaymentType } from './payment.type';

export enum TpaModel {
  PLV_BROKER = 'plv_broker',
  KIOSK = 'kiosk',
}

export interface GenericPaymentConfigForm {
  id?: FormControl<number>;
  applicationId?: FormControl<number>;
  mode: FormControl<PaymentMode>;
  name: FormControl<string>;
  type: FormControl<PaymentType>;
}

export interface GenericPaymentConfig {
  id?: number;
  applicationId?: number;
  mode?: PaymentMode;
  name: string;
  type: PaymentType;
}

export interface EpayConfig extends GenericPaymentConfig {
  merchantId: string;
  pwd: string;
}

export interface NeptingConfig extends GenericPaymentConfig {
  merchantId: string;
  key: string;
}

export interface PaygreenConfig extends GenericPaymentConfig {
  shopId: string;
  publicKey: string;
  privateKey: string;
  enableApplePay: boolean;
}

export interface StripeConfig extends GenericPaymentConfig {
  publishableKey: string;
  stripeUserId: string;
  isLinked: boolean;
  enableGPay: boolean;
  enableApplePay: boolean;
}

export interface AdyenConfig extends GenericPaymentConfig {
  apiKey: string;
  clientKey: string;
  merchantAccount: string;
  webhookHmacKey: string;
  apiPrefix: string;
  enableGPay: boolean;
  enableApplePay: boolean;
}

export interface LyfPayConfig extends GenericPaymentConfig {
  posIdentifier: string;
  secretKey: string;
  multibox: boolean;
}

export interface YavinConfig extends GenericPaymentConfig {
  apiKey: string;
}

export interface YavinConfigForm extends GenericPaymentConfigForm {
  apiKey: FormControl<string>;
}

export interface TpaConfig extends GenericPaymentConfig {
  model: TpaModel;
}

export enum PaymentMode {
  DEV = 'dev',
  PROD = 'prod',
}

export type PaymentConfig =
  | StripeConfig
  | NeptingConfig
  | EpayConfig
  | PaygreenConfig
  | AdyenConfig
  | LyfPayConfig
  | TpaConfig
  | YavinConfig;
