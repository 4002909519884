import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { Item } from '../shared';
import { actions } from './item.actions';
import { ItemHttpService } from './item.http.service';
import * as selectors from './item.selectors';
import { State } from './item.type';

@Injectable()
export class ItemEffects {
  loadMenus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getMenus),
      withLatestFrom(this._store.select(selectors.loadedCatalogIds)),
      switchMap(([{ catalogId }, loadedCatalogIds]) =>
        loadedCatalogIds.includes(catalogId)
          ? of(actions.getMenusIsLoaded())
          : this._http.getMenus(catalogId).pipe(
              switchMap(menus => of(actions.getMenusSuccess({ catalogId, menus: this._getMenus(menus, catalogId) }))),
              catchError(({ errorCode }) => of(actions.getMenusError({ errorCode }))),
            ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _store: Store<State>, private _http: ItemHttpService) {}

  private _getMenus(menus: Item[], catalogId: number): Item[] {
    return menus.map(menu => ({ ...menu, catalogId, isMenu: true }));
  }
}
