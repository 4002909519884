import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { actions } from './auth.actions';
import { State } from './auth.type';

const initialState: State = {
  isAuthenticated: false,
  isLoading: false,
  token: null,
  refreshToken: null,
  errorCode: null,
  isNew: false,
  isRegistered: false,
  phone: null,
};

export const authReducer = createReducer(
  initialState,

  on(
    actions.initEffects,
    actions.login,
    actions.loginPos,
    actions.loginWifi,
    actions.loginGps,
    actions.confirmCode,
    actions.signIn,
    state => ({ ...state, isLoading: true }),
  ),

  on(actions.initStateError, state => ({ ...state, isLoading: false })),

  on(actions.confirmCodeSuccess, (state, { token, refreshToken, isNew }) => ({
    ...state,
    token,
    refreshToken,
    isNew,
    isLoading: false,
    isRegistered: true,
    errorCode: null,
  })),

  on(actions.signInSuccess, (state, { phone }) => ({
    ...state,
    phone,
    isLoading: false,
    errorCode: null,
  })),

  on(actions.initState, (state, { token, refreshToken, isRegistered, phone }) => ({
    ...state,
    token,
    refreshToken,
    isRegistered,
    phone,
    isLoading: false,
    isAuthenticated: true,
    errorCode: null,
  })),

  on(
    actions.loginSuccess,
    actions.loginPosSuccess,
    actions.loginWifiSuccess,
    actions.loginGpsSuccess,
    (state, { token, refreshToken, isRegistered }) => ({
      ...state,
      token,
      refreshToken,
      isRegistered,
      isLoading: false,
      isAuthenticated: true,
      errorCode: null,
    }),
  ),

  on(
    actions.loginError,
    actions.loginPosError,
    actions.loginWifiError,
    actions.loginGpsError,
    actions.confirmCodeError,
    actions.signInError,
    actions.resendCodeError,
    (state, { errorCode }) => ({ ...state, errorCode, isLoading: false }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.logOutSuccess.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
