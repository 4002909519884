import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RecommendationsItemsDesigner } from '../shared';
import { actions } from './recommendations-designer.actions';
import { State } from './recommendations-designer.type';

export const adapter: EntityAdapter<RecommendationsItemsDesigner> = createEntityAdapter<RecommendationsItemsDesigner>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoadedGroups: [],
  catalogId: null,
  error: null,
});

const recommendationsDesignerReducer = createReducer(
  initialState,
  on(actions.getRecommendations, state => ({ ...state, isLoading: true })),

  // Success actions

  on(actions.getRecommendationsSuccess, (state, { recommendations, recommendationGroupId }) =>
    adapter.addMany(recommendations, {
      ...state,
      isLoadedGroups: [...state.isLoadedGroups, recommendationGroupId],
      isLoading: false,
    }),
  ),

  on(actions.removeAllRecommendations, state => adapter.removeAll({ ...state, isLoading: true, isLoadedGroups: [] })),

  on(actions.saveRecommendations, (state, { recommendations, groupId }) =>
    adapter.setAll(saveEntitiesById(state, recommendations, groupId), state),
  ),

  on(actions.deleteRecommendations, (state, { groupId }) =>
    adapter.removeMany(deleteEntitiesById(state, groupId), {
      ...state,
      isLoadedGroups: [...state.isLoadedGroups].filter(id => id !== groupId),
    }),
  ),

  on(actions.getRecommendationsSuccess, actions.getRecommendationsIsLoaded, state => ({ ...state, isLoading: false })),

  // Error actions

  on(actions.getRecommendationsError, (state, { errorCode }) => ({ ...state, isLoading: false, error: errorCode })),
);

export function reducer(state: State | undefined, action: Action): State {
  return recommendationsDesignerReducer(state, action);
}

function saveEntitiesById(
  state: State,
  recommendations: RecommendationsItemsDesigner[],
  groupId: number,
): RecommendationsItemsDesigner[] {
  return [
    ...recommendations,
    ...Object.values(state.entities).filter(({ recommendationGroupId }) => recommendationGroupId !== groupId),
  ];
}

function deleteEntitiesById(state: State, groupId: number): number[] {
  return Object.values(state.entities).reduce((acc, { id, recommendationGroupId }) => {
    if (recommendationGroupId === groupId) {
      acc.push(id);
    }

    return acc;
  }, []);
}
