export const enum PageTitle {
  Home = 'PAGE.HOME.TITLE',
  Cart = 'PAGE.CART.TITLE',
  Collect = 'PAGE.COLLECT.TITLE',
  Details = 'PAGE.DETAILS.TITLE',
  Payment = 'PAGE.PAYMENT.TITLE',
  OrderHistory = 'PAGE.ORDER_HISTORY.TITLE',
  CreditHistory = 'PAGE.CREDIT_HISTORY.TITLE',
  Login = 'PAGE.LOGIN.TITLE',
  Profile = 'PAGE.PROFILE.TITLE',
  SplitBill = 'PAGE.SPLIT_BILL.TITLE',
  Tips = 'PAGE.TIPS.TITLE',
}
