import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { Widget } from '../shared';
import { actions } from './widgets.actions';
import { State } from './widgets.type';

export const adapter: EntityAdapter<Widget<any>> = createEntityAdapter<Widget<any>>({
  selectId: (widget: Widget<any>) => `${widget.pageId}-${widget.id}`,
});

export const initialState: State = adapter.getInitialState({
  error: null,
  isLoadingByPageId: {},
  isLoadedByPageId: {},
  selectedNextPageId: null,
  selectedPageId: null,
});

const widgetReducer = createReducer(
  initialState,

  on(actions.changeSelectedPageId, (state, { pageId }) => ({
    ...state,
    error: null,
    selectedNextPageId: pageId,
    selectedPageId: pageId,
  })),

  on(actions.addWidgets, (state, { pageId }) => ({
    ...state,
    error: null,
    isLoadingByPageId: { ...state.isLoadingByPageId, [pageId]: true },
    isLoadedByPageId: { ...state.isLoadedByPageId, [pageId]: false },
  })),
  on(actions.addWidgetsError, (state, { pageId, error }) => ({
    ...state,
    error,
    isLoadingByPageId: { ...state.isLoadingByPageId, [pageId]: false },
  })),

  on(actions.addWidgetsWithAttributes, (state, { widgets, pageId }) =>
    adapter.upsertMany(widgets, {
      ...state,
      isLoadingByPageId: { ...state.isLoadingByPageId, [pageId]: false },
      isLoadedByPageId: { ...state.isLoadedByPageId, [pageId]: true },
      selectedPageId: pageId,
    }),
  ),

  on(actions.resetWidgetsByPage, (state, { pageId }) => ({
    ...state,
    isLoadingByPageId: { ...state.isLoadingByPageId, [pageId]: false },
    isLoadedByPageId: { ...state.isLoadedByPageId, [pageId]: false },
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return widgetReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(
        {
          selectedNextPageId: null,
          selectedPageId: null,
          entities: {},
          ids: [],
          error: null,
          isLoadingByPageId: {},
          isLoadedByPageId: {},
        },
        action,
      );
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
