import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { SettingsService } from '@bend/store';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { TextAlignType, WidgetTitle, WidgetTitleMargin } from './title';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class WidgetTitleComponent implements OnInit, WidgetComponent {
  static widgetName = 'title';
  @Input() attributes: WidgetTitle;

  isValid: boolean;
  noValidMessage: string;
  marginLeft: number = null;
  marginRight: number = null;
  width = '';
  title$: Observable<string>;

  constructor(private _warningService: WarningService, private _settings: SettingsService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.title) {
      this.noValidMessage = this._warningService.showWarn(WidgetTitleComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this._addMissingAttributes(this.attributes);
    this._initWidthStyle();
    this.title$ = this._title;
    this.isValid = true;
  }

  private _addMissingAttributes(attributes: WidgetTitle): void {
    if (!attributes.style) {
      attributes.style = {
        height: DEFAULT,
        backgroundColor: '',
        width: DEFAULT,
        margin: new WidgetTitleMargin(),
        fontSize: '',
        textAlign: TextAlignType.CENTER,
        textColor: '',
      };
    }

    if (!attributes.style.height) {
      attributes.style.height = DEFAULT;
    }

    if (!attributes.style.width) {
      attributes.style.width = DEFAULT;
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new WidgetTitleMargin();
    }

    if (!attributes.style.textAlign) {
      attributes.style.textAlign = TextAlignType.CENTER;
    }
  }

  private _initWidthStyle(): void {
    const { width } = this.attributes.style;

    if (width === 'full') {
      this.marginLeft = 0;
      this.marginRight = 0;
      return;
    }

    if (/^\d+$/.exec(width)) {
      this.width = width;
    }
  }

  private get _title(): Observable<string> {
    return of(this.attributes.title).pipe(
      /**
       * @description check if widget is edited now
       * if not, replace placeName
       */
      withLatestFrom(this.attributes.isEditMode ? of('') : this._settings.placeName),
      map(([title, placeName]) =>
        title.includes('<$table_name>') ? title.replace('<$table_name>', placeName || '') : title,
      ),
    );
  }
}
