import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './option.config';
import { reducer } from './option.reducer';
import { OptionService } from './option.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer)],
  providers: [OptionService],
})
export class OptionStoreModule {}
