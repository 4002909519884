<div [formGroup]="form" *ngIf="isLoaded">
  <div class="form-row-cols-wrapper">
    <div class="form-row-cols">
      <div class="form-row-cols__col col--no-shrink">
        <div class="form-row-cols__col__content">
          <label class="form-label" for="name">{{ 'WIDGETS.PRODUCT.CATALOG_LABEL' | translate }}</label>
          <input
            id="name"
            type="text"
            formControlName="name"
            class="form-input"
            [class.show-error]="showErrors && name.invalid"
          />
          <span class="form-error-msg" *ngIf="showErrors && name.invalid">
            {{ 'WIDGETS.PRODUCT.CATALOG_ENTER' | translate }}
          </span>
        </div>
      </div>
      <div class="form-row-cols__col col--no-shrink">
        <div class="form-row-cols__col__content">
          <label class="form-label" for="catalogOption">{{ 'WIDGETS.PRODUCT.CATALOG' | translate }}</label>
          <select
            id="catalogOption"
            (change)="onCatalogSelected($event)"
            class="form-input form-input--select"
            [class.show-error]="showErrors && catalogId.invalid"
          >
            <option value="" class="hidden"></option>
            <option
              *ngFor="let catalogChoice of catalogChoicesClone; index as j"
              [value]="j"
              [selected]="catalogChoice.isSelected"
            >
              {{ catalogChoice.label }}
            </option>
          </select>
          <span class="form-error-msg" *ngIf="showErrors && catalogId.invalid">
            {{ 'WIDGETS.PRODUCT.SELECT_CATALOG' | translate }}
          </span>
        </div>
      </div>

      <ng-container [ngSwitch]="catalogType">
        <div *ngSwitchCase="'a_la_carte'" class="form-row-cols__col">
          <div class="form-row-cols__col__content">
            <label for="visibleCategories" class="form-label">
              {{ 'WIDGETS.PRODUCT.VISIBLE_CATEGORIES' | translate }}
            </label>
            <ng-select
              id="visibleCategories"
              [closeOnSelect]="false"
              [multiple]="true"
              [placeholder]="'WIDGETS.PRODUCT.VISIBLE_CATEGORIES_SELECT' | translate"
              [searchable]="false"
              class="categories-ng-select"
              formControlName="visibleCategories"
              [items]="categories"
              bindLabel="name"
              bindValue="id"
            ></ng-select>
            <span class="form-field-info">{{ 'WIDGETS.PRODUCT.CATEGORIES_INFO' | translate }}</span>
          </div>
        </div>
        <ng-container *ngSwitchCase="'menu'">
          <div *ngIf="!isMenuLoading" class="form-row-cols__col">
            <div class="form-row-cols__col__content">
              <label for="visibleMenus" class="form-label">{{ 'WIDGETS.PRODUCT.VISIBLE_MENUS' | translate }}</label>
              <ng-select
                id="visibleMenus"
                [closeOnSelect]="false"
                [multiple]="true"
                [placeholder]="'WIDGETS.PRODUCT.VISIBLE_MENUS_SELECT' | translate"
                [searchable]="false"
                class="categories-ng-select"
                formControlName="visibleMenus"
                [items]="packages"
                bindLabel="name"
                bindValue="id"
              ></ng-select>
              <span class="form-field-info">{{ 'WIDGETS.PRODUCT.MENUS_INFO' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <div class="form-row-cols__col">
          <div class="form-row-cols__col__content">
            <label for="imageMeta" class="form-label">{{ 'WIDGETS.PRODUCT.CATEGORY_IMAGE' | translate }}</label>
            <ng-select
              id="imageMeta"
              [placeholder]="'WIDGETS.PRODUCT.SELECT_IMAGE' | translate"
              [searchable]="false"
              class="img-ng-select"
              [items]="categoryImages"
              [formControl]="imageMetaControl"
            >
              <ng-template ng-label-tmp let-item="item">
                <img alt="" [src]="item.url" />
                <span class="image-label">{{ item.label }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img alt="" [src]="item.url" />
                <span class="image-label">{{ item.label }}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div formGroupName="imageMeta" *ngIf="catalogType === 'menu'">
          <h3 class="additional-image_title">{{ 'WIDGETS.PRODUCT.KIOSK_CATEGORY_IMAGE' | translate }}</h3>
          <designer-image-gallery class="additional-image" formControlName="additionalUrl"></designer-image-gallery>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-row">
    <input id="isDefault" type="checkbox" formControlName="isDefault" class="form-input-checkbox" />
    <label class="form-label form-label--checkbox">{{ 'WIDGETS.PRODUCT.IS_DEFAULT' | translate }}</label>
  </div>

  <div class="form-row clearfix">
    <button class="btn-flat btn-flat--remove" type="button" (click)="delete()">
      <span class="hyphen">-</span>
      {{ 'WIDGETS.PRODUCT.REMOVE_CATALOG' | translate }}
    </button>
  </div>
</div>
