<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row-groups">
      <div formGroupName="profile_image">
        <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
        <designer-image-gallery formControlName="url"></designer-image-gallery>
      </div>

      <span class="form-field-info">{{ 'WIDGETS.PROFILE.IMAGE_INFO' | translate }}</span>
    </div>
    <div class="form-row">
      <label for="nameInput" class="form-label">{{ 'WIDGETS.SHARED.NAME' | translate }}</label>
      <div
        *ngIf="name.invalid && (((name.dirty || name.touched) && name.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.NAME_ENTER' | translate }}
      </div>
      <input
        id="nameInput"
        type="text"
        class="form-input form-input--name"
        [placeholder]="'WIDGETS.SHARED.NAME' | translate"
        formControlName="name"
      />
    </div>
    <div class="form-row">
      <label for="descriptionInput" class="form-label">{{ 'WIDGETS.SHARED.DESCRIPTION' | translate }}</label>
      <textarea
        id="descriptionInput"
        class="form-input form-input--textarea"
        [placeholder]="'WIDGETS.SHARED.DESCRIPTION' | translate"
        formControlName="description"
        rows="7"
      ></textarea>
    </div>
    <div class="form-row" formGroupName="social">
      <label for="twitterInput" class="form-label">{{ 'WIDGETS.FOLLOW.TWITTER' | translate }}</label>
      <input
        id="twitterInput"
        type="url"
        class="form-input form-input--url"
        [placeholder]="'WIDGETS.PROFILE.TWITTER' | translate"
        formControlName="twitter"
      />
    </div>
    <div class="form-row" formGroupName="social">
      <label for="facebookInput" class="form-label">{{ 'WIDGETS.FOLLOW.FACEBOOK' | translate }}</label>
      <input
        id="facebookInput"
        type="url"
        class="form-input form-input--url"
        [placeholder]="'WIDGETS.PROFILE.FACEBOOK' | translate"
        formControlName="facebook"
      />
    </div>
    <div class="form-row" formGroupName="social">
      <label for="instagramInput" class="form-label">{{ 'WIDGETS.FOLLOW.INSTAGRAM' | translate }}</label>
      <input
        id="instagramInput"
        type="url"
        class="form-input form-input--url"
        [placeholder]="'WIDGETS.PROFILE.INSTAGRAM' | translate"
        formControlName="instagram"
      />
    </div>
    <div class="form-row" formGroupName="social">
      <label for="linkedinInput" class="form-label">{{ 'WIDGETS.FOLLOW.LINKEDIN' | translate }}</label>
      <input
        id="linkedinInput"
        type="url"
        class="form-input form-input--url"
        [placeholder]="'WIDGETS.PROFILE.LINKEDIN' | translate"
        formControlName="linkedin"
      />
    </div>
  </form>
</pop-widget-edit-widget>
