import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EnvModule } from '@bend/env';

import { storeKey } from './notifications.config';
import { NotificationsEffects } from './notifications.effects';
import { NotificationsHttpService } from './notifications.http.service';
import { reducer } from './notifications.reducer';
import { NotificationsService } from './notifications.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([NotificationsEffects]), EnvModule],
  providers: [NotificationsHttpService, NotificationsService],
})
export class NotificationsStoreModule {}
