import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { menuCategories } from '../../../mock';
import { ParamsService } from '../params';
import { ApiVisitorService, Category } from '../shared';

@Injectable()
export class CategoryHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService, private _params: ParamsService) {}

  getCategories(catalogId: number): Observable<Category[]> {
    return combineLatest([this._api.itemCategories('v2'), this._params.queryParams(['place'])]).pipe(
      switchMap(([api, place]) => this._http.get<Category[]>(api, { params: { catalogId, place } })),
    );
  }

  getMenuCategories(menuId: number): Observable<Category[]> {
    // TODO remove mocking data
    return this._api.catalogMenuCategories('v2', menuId).pipe(
      // switchMap(api => this._http.get(api)),
      map(() => menuCategories[menuId]),
    );
  }
}
