import { Injectable } from '@angular/core';

import { Action, ActionType } from './action-call';

@Injectable()
export class WidgetActionCallHelperService {
  getSingleActionUrl(action: Action, place: string): string {
    let url: string;
    let body: string;
    const tableName = '<$table_name>';

    if (action.body || action.addTableName) {
      body = 'body=';
      if (action.body) {
        body = `${body}${action.body}`;
        if (body.includes(tableName)) {
          body = body.replace(tableName, place);
        } else if (action.addTableName && place) {
          body = `${body} ${place}`;
        }
      } else if (action.addTableName && place) {
        body = `${body} ${place}`;
      }
    }

    switch (action.type) {
      case ActionType.TELEPHONE:
        url = `tel:${action.telephone}`;
        break;
      case ActionType.SMS:
        body = body ? `;?&${body}` : '';
        url = `sms:${action.sms}${body}`;
        break;
      case ActionType.EMAIL:
        body = body ? `?${body}` : '';
        url = `mailto:${action.email}${body}`;
        break;
      default:
        url = '';
    }

    return url;
  }
}
