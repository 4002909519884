import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Transaction } from '../shared';
import { actions } from './credit-history.actions';
import * as selectors from './credit-history.selectors';
import { State } from './credit-history.type';

@Injectable()
export class CreditHistoryService {
  constructor(private _store: Store<State>) {}

  get byPage(): Observable<Transaction[]> {
    return this._store.pipe(select(selectors.all));
  }

  get total(): Observable<number> {
    return this._store.pipe(select(selectors.total));
  }

  get isLoading(): Observable<boolean> {
    return this._store.pipe(select(selectors.isLoading));
  }

  getUserTransactions(limit: number, offset: number, userId: number): void {
    this._store.dispatch(actions.getUserTransactions({ userId, limit, offset }));
  }
}
