import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogGeneric } from '../../helpers';
import { DialogConfigComponent } from '../../types';

@Component({
  selector: 'dialog-success',
  templateUrl: './dialog-success.component.html',
  styleUrls: ['./dialog-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSuccessComponent extends DialogGeneric<DialogSuccessComponent> {
  constructor(@Inject(MAT_DIALOG_DATA) data: DialogConfigComponent, dialogRef: MatDialogRef<DialogSuccessComponent>) {
    super(data, dialogRef);
  }
}
