import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService, Ticket } from '../shared';
import { GetTicketsSuccess, GetUserTickets } from './tickets.type';

@Injectable()
export class TicketsHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getTickets({ userId, limit, offset }: GetUserTickets): Observable<GetTicketsSuccess> {
    return this._api.tickets('v1').pipe(
      switchMap(api =>
        this._http.get<GetTicketsSuccess>(api, {
          params: {
            userId: userId.toString(),
            limit: limit.toString(),
            offset: offset.toString(),
          },
        }),
      ),
    );
  }

  editTicket(id: number, ticket: Partial<Ticket>): Observable<Ticket> {
    return this._api.updateTicket('v1', id.toString()).pipe(switchMap(api => this._http.patch<Ticket>(api, ticket)));
  }
}
