<app-recommendation
  [item]="item"
  [parentItemAvailability]="parentItemAvailability"
  [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
>
  <div class="quantity-modifier-container">
    <ng-container *ngIf="showMenu$ | async; else buttons">
      <mat-icon @appear>checklist</mat-icon>
    </ng-container>
    <ng-template #buttons>
      <app-button-quantity [negative]="true" (click)="removeItem()" @appear></app-button-quantity>
      <span class="count" @appear>{{ item | recommendationDetailsQuantity }}</span>
      <app-button-quantity (click)="addItem($event)" @appear></app-button-quantity>
    </ng-template>
  </div>

  <ng-container *ngIf="showMenu$ | async">
    <product-catalog-recommendation-menu
      @appear
      tabindex="0"
      is-loaded
      [recommendation]="item"
      [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
      [style.border]="menuBorder"
      (loaded)="menuLoaded($event)"
      (clickOutside)="clickedOutside()"
    ></product-catalog-recommendation-menu>
  </ng-container>
</app-recommendation>
