import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { WelcomeWidget } from './welcome';

@Component({
  selector: 'pop-widget-edit-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditWelcomeComponent extends WidgetBaseComponent<WelcomeWidget> implements OnInit {
  static widgetName = 'welcome';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  get text(): UntypedFormControl {
    return this.form.get('text') as UntypedFormControl;
  }

  get textColor(): UntypedFormControl {
    return this.form.get('style.textColor') as UntypedFormControl;
  }

  get textColorValue(): string {
    return this.textColor.value;
  }

  set textColorValue(color: string) {
    this.textColor.setValue(color);
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }

  private _initForm(): void {
    const textColor = this.widget.attributes.style.textColor;

    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        text: [this.widget.attributes.text, Validators.required],
        style: this.formBuilder.group({
          textColor: [WidgetHelper.parseColor(textColor ? textColor : '#000000')],
          backgroundColor: [WidgetHelper.parseColor(this.widget.attributes.style.backgroundColor)],
          fullWidth: [this.widget.attributes.style.fullWidth],
          margin: this.formBuilder.group({
            top: [this.widget.attributes.style.margin.top],
            bottom: [this.widget.attributes.style.margin.bottom],
          }),
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
