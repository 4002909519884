<ng-container *ngIf="recommendation">
  <ng-container *ngIf="recommendation.children && recommendation.children.length">
    <div class="options-wrapper" data-cy="productOptionsWrappers">
      <ng-container *ngFor="let child of recommendation.children">
        <button
          *ngIf="child.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
          class="option"
          type="button"
          [class.option--selected]="child.isSelected"
          (click)="selectChild(child.id)"
          [disabled]="!child.available"
        >
          <span class="option__details-wrapper">
            <div class="option__details">
              <span class="option__details-name">{{ child.name }}</span>
              <app-product-status-checkout
                [product]="child"
                [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                class="option-status"
              ></app-product-status-checkout>
            </div>
          </span>
          <span *ngIf="child.priceFinalValue" class="option__price">
            {{ child.priceFinalValue | currency }}
          </span>
        </button>
      </ng-container>
    </div>
  </ng-container>
  <div *ngFor="let item of recommendation.itemOptionGroups; let optionGroupIndex = index" class="option-group">
    <ng-container *ngIf="item.optionGroup.optionGroupSubitems.length">
      <h4 class="option-group__name">
        {{ item.optionGroup.nameToDisplay }}
        <span *ngIf="item.optionGroup.required" class="option-group-required-msg">
          +
          {{ 'PRODUCT_CATALOG.REQUIRED' | translate }}
        </span>
        <div class="option-group-quantity" *ngIf="item.optionGroup.multiple">
          <ng-container
            *ngIf="
              item.optionGroup.minAllowedOptions > 0 &&
                item.optionGroup.maxAllowedOptions > 0 &&
                item.optionGroup.minAllowedOptions === item.optionGroup.maxAllowedOptions;
              else elseBlock
            "
          >
            <span>
              {{
                item.optionGroup.minAllowedOptions === 1
                  ? ('PRODUCT_CATALOG.SELECT_OPTION' | translate)
                  : ('PRODUCT_CATALOG.SELECT_OPTIONS' | translate: { count: item.optionGroup.minAllowedOptions })
              }}
            </span>
          </ng-container>
          <ng-template #elseBlock>
            <span
              *ngIf="item.optionGroup.minAllowedOptions && item.optionGroup.minAllowedOptions > 0"
              class="option-group-quantity__limit"
            >
              {{ item.optionGroup.minAllowedOptions }} min
            </span>
            <span
              *ngIf="item.optionGroup.maxAllowedOptions && item.optionGroup.maxAllowedOptions > 0"
              class="option-group-quantity__limit"
            >
              {{ item.optionGroup.maxAllowedOptions }} max
            </span>
          </ng-template>
        </div>
      </h4>

      <div class="error-message-wrapper" data-cy="errorMessagesWrapper">
        <span *ngIf="item.optionGroup.required && errorMessages.indexOf(item.optionGroup.id) >= 0" class="error-msg">
          {{ 'PRODUCT_CATALOG.PLEASE_SELECT_THE_REQUIRED_OPTIONS' | translate }}
        </span>
        <span *ngIf="showErrors && optionGroupQuantityError.includes(item.id)" class="error-msg">
          {{
            item.optionGroup.maxAllowedOptions !== item.optionGroup.minAllowedOptions
              ? item.optionGroup.minAllowedOptions > 0 && item.optionGroup.maxAllowedOptions > 0
                ? ('PRODUCT_CATALOG.OPTION_QUANTITY_ERROR'
                  | translate: { min: item.optionGroup.minAllowedOptions, max: item.optionGroup.maxAllowedOptions })
                : item.optionGroup.minAllowedOptions > 0
                  ? ('PRODUCT_CATALOG.OPTION_MIN_QUANTITY_ERROR'
                    | translate: { min: item.optionGroup.minAllowedOptions })
                  : ('PRODUCT_CATALOG.OPTION_MAX_QUANTITY_ERROR'
                    | translate: { max: item.optionGroup.maxAllowedOptions })
              : ('PRODUCT_CATALOG.OPTION_FIXED_QUANTITY_ERROR'
                | translate: { count: item.optionGroup.minAllowedOptions })
          }}
        </span>
      </div>

      <div class="options-wrapper" data-cy="productOptionsWrappers">
        <ng-container *ngIf="!item.optionGroup.allowMultipleSameOptions; else enabledQuantity">
          <ng-container *ngFor="let option of item.optionGroup.optionGroupSubitems; let optionIndex = index">
            <button
              *ngIf="option.item.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
              class="option"
              type="button"
              [class.option--selected]="option.item.isSelected"
              (click)="recommendation.available && selectOption(option.item, item.optionGroup)"
              [disabled]="!option.item.available"
              [attr.data-cy]="'optionGroupOption-' + optionGroupIndex + '-' + optionIndex"
            >
              <span class="option__name">
                <div>
                  <div>{{ option.item.name }}</div>
                  <app-product-status-checkout
                    [product]="option.item"
                    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                    class="option-status"
                  ></app-product-status-checkout>
                </div>
              </span>
              <span class="option__price">
                {{ option.item.priceFinalValue ? '+' + (option.item.priceFinalValue | currency) : '' }}
              </span>
            </button>
          </ng-container>
        </ng-container>

        <ng-template #enabledQuantity>
          <ng-container *ngFor="let option of item.optionGroup.optionGroupSubitems; let optionIndex = index">
            <div
              *ngIf="option.item.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
              class="option"
              [attr.data-cy]="'optionGroupOption-' + optionGroupIndex + '-' + optionIndex"
            >
              <div class="option__details">
                <span class="option__details-name">{{ option.item.name }}</span>
                <app-product-status-checkout
                  [product]="option.item"
                  [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                ></app-product-status-checkout>
              </div>

              <div class="option__modify-quantity">
                <span class="option__price option__price--margin-right">
                  {{ option.item.priceFinalValue ? '+' + (option.item.priceFinalValue | currency) : '' }}
                </span>

                <app-product-quantity-buttons *ngIf="recommendation.available" [product]="option.item">
                  <div class="option__quantity-buttons">
                    <app-button-quantity
                      (click)="decreasesOptionQuantity(option.item)"
                      [negative]="true"
                    ></app-button-quantity>
                    <span class="option__quantity">{{ option.item.quantityMultiplier }}</span>
                    <app-button-quantity
                      (click)="addOptionQuantity(option.item, item.optionGroup)"
                      [attr.data-cy]="'optionGroupOptionIncrease-' + optionGroupIndex + '-' + optionIndex"
                    ></app-button-quantity>
                  </div>
                </app-product-quantity-buttons>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <div class="buttons options-wrapper">
    <button mat-raised-button color="primary" (click)="saveRecommendation()">{{ 'OK' | translate }}</button>
    <button mat-raised-button color="warn" (click)="cancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</ng-container>
