import { NgModule } from '@angular/core';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';

import { TranslateWrapperService } from './services';

@NgModule({
  imports: [NgxTranslateModule],
  providers: [TranslateWrapperService],
  exports: [NgxTranslateModule],
})
export class TranslateModule {}
