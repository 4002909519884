import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppsState } from '@designer-store/apps/apps.state';

export const DESIGNER_APPS_STATE_KEY = 'apps';

export const selectAppsState = createFeatureSelector<AppsState>(DESIGNER_APPS_STATE_KEY);

export const all = createSelector(selectAppsState, state => state);
export const isLoading = createSelector(selectAppsState, state => state.isLoading);
export const apps = createSelector(selectAppsState, state => state.applications);
export const isLoaded = createSelector(selectAppsState, state => state.isLoaded);
export const errorCode = createSelector(selectAppsState, state => state.error);
