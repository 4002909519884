import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RecommendationMenuBackdropService {
  showBackdrop$ = new Subject<boolean>();

  setBackdrop(): void {
    this.showBackdrop$.next(true);
  }

  removeBackdrop(): void {
    this.showBackdrop$.next(false);
  }
}
