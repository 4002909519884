import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { actions } from './widgets.actions';
import { WidgetsHttpService } from './widgets.http.service';

@Injectable()
export class WidgetsEffects {
  addWidgets$ = createEffect(() =>
    this._actions$.pipe(
      // check action
      ofType(actions.addWidgets),
      mergeMap(({ widgets: widgetsSimple, pageId }) =>
        this._widgetService.injectAttributes(widgetsSimple, pageId).pipe(
          map(widgets => actions.addWidgetsWithAttributes({ widgets, pageId })),
          catchError(({ error: message, status: code }: HttpErrorResponse) =>
            of(actions.addWidgetsError({ pageId, error: { message, code } })),
          ),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _widgetService: WidgetsHttpService) {}
}
