import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { OrderControllerService } from '@bend/shared-widgets/src/lib/shared/cart-services';

import {
  PopALaCarteOrderItem,
  PopCartOption,
  PopCartVisitor,
  PopMenu,
  PopMenuCategory,
  PopMenuOrderItem,
  PopOptionGroup,
  PopOptionStatus,
  PopOrderItemRequestData,
  PopProduct,
} from './product-catalog';

@Injectable()
export class WidgetProductCatalogHelperService {
  constructor(private _orderController: OrderControllerService) {}

  private static _selectMenuOptions(currentProduct: PopProduct, newProduct: PopProduct): void {
    if (!newProduct.itemOptionGroups) {
      return;
    }

    for (let i = 0; i < newProduct.itemOptionGroups.length; i++) {
      const optionGroup = newProduct.itemOptionGroups[i];
      for (let j = 0; j < optionGroup.optionGroup.optionGroupSubitems.length; j++) {
        const subitem = optionGroup.optionGroup.optionGroupSubitems[j];
        if (subitem.item.isSelected) {
          currentProduct.itemOptionGroups[i].optionGroup.optionGroupSubitems[j] = subitem;
        }
      }
    }
  }

  /**
   * @description if order was created return true else return false
   * @param error http error status
   */
  handleOrderClose(error: any): Observable<boolean> {
    const allowReopenOrder = new Set(['DISABLE_ADDITIONS_AFTER_ORDER_SESSION_ORDERED']);

    const allowCreateNewSession = new Set(['NO_ACTIVE_ORDER_FOR_USER']);

    if (allowReopenOrder.has(error.errorCode)) return this._orderController.reopenOrder();
    if (allowCreateNewSession.has(error.errorCode)) return this._orderController.createNewSession();
    return of(false);
  }

  generateOrderItemData(
    orderItem: PopALaCarteOrderItem | PopMenuOrderItem,
    user: PopCartVisitor,
  ): PopOrderItemRequestData {
    return {
      orderItem,
      userId: user ? user.id || null : null,
    };
  }

  generateALaCarteItem(
    product: PopProduct,
    quantity: number,
    comment: string,
    user: PopCartVisitor,
  ): PopOrderItemRequestData {
    return this.generateOrderItemData(this._generateOrderItem(product, quantity, comment), user);
  }

  generateMenuOrderItem(
    itemCategories: PopMenuCategory[],
    comment: string,
    menuId: number,
    menuName: string,
    menuPrice: number,
    user: PopCartVisitor,
  ): PopOrderItemRequestData {
    const selectedItems = itemCategories.reduce(
      (items, category) =>
        items.concat(
          category.menuCategoryItems
            .filter(item => item.item.isSelected)
            .map(item => this._generateOrderItem(item.item, item.item.quantity || 1, null, category.id)),
        ),
      [],
    );

    const orderItem: PopMenuOrderItem = {
      comment,
      id: menuId,
      name: menuName,
      quantity: 1,
      items: selectedItems,
      price: menuPrice,
    };

    return this.generateOrderItemData(orderItem, user);
  }

  calculateProductTotal(product: PopProduct, quantity: number): number {
    return this.calculateMenuProductTotal(product, product.priceFinalValue, quantity);
  }

  calculateMenuProductTotal(product: PopProduct, price: number, quantity: number): number {
    let total = product.children.length ? 0 : price;
    product.itemOptionGroups.forEach(group => {
      group.optionGroup.optionGroupSubitems.forEach(subitem => {
        if (subitem.item.isSelected) {
          total += subitem.item.priceFinalValue * subitem.item.quantityMultiplier;
        }
      });
    });

    const subProduct = product.children.find(item => item.isSelected);
    if (subProduct) {
      total += subProduct.priceFinalValue;
    }

    total = total * quantity;

    return total;
  }

  calculateMenuTotal(menu: PopMenu, itemCategories: PopMenuCategory[]): number {
    let total = menu.priceFinalValue;
    itemCategories.forEach(category => {
      category.menuCategoryItems.forEach(categoryItem => {
        if (categoryItem.item.isSelected) {
          total += categoryItem.item.price;
        }
      });
    });

    return total;
  }

  getAllRequiredOptionsNotSelected(product: PopProduct): number[] {
    const noSelectionOptionGroup = [];
    for (let i = 0; i < product.itemOptionGroups.length; i++) {
      const optionGroup = product.itemOptionGroups[i].optionGroup;
      if (optionGroup.required) {
        const found = optionGroup.optionGroupSubitems.some(subitem => subitem.item.isSelected);
        if (!found) {
          noSelectionOptionGroup.push(optionGroup.id);
        }
      }
    }

    return noSelectionOptionGroup;
  }

  getOptionGroupsWithQuantityError(product: PopProduct, kiosk = false): number[] {
    const optionGroupQuantityErrorItems: number[] = [];

    product.itemOptionGroups.forEach(optionGroup => {
      const isError =
        optionGroup.optionGroup.multiple &&
        ((optionGroup.optionGroup.maxAllowedOptions &&
          this.selectedOptionsCount(optionGroup.optionGroup) > optionGroup.optionGroup.maxAllowedOptions) ||
          (optionGroup.optionGroup.minAllowedOptions &&
            this.selectedOptionsCount(optionGroup.optionGroup) < optionGroup.optionGroup.minAllowedOptions));
      if (isError) {
        // Temporary solution for handling optionGroup errors for new kiosk
        optionGroupQuantityErrorItems.push(!kiosk ? optionGroup.id : optionGroup.optionGroup.id);
      }
    });

    return optionGroupQuantityErrorItems;
  }

  selectDefaultOptions(product: PopProduct): void {
    product.itemOptionGroups.forEach(group => {
      group.optionGroup.optionGroupSubitems.forEach(subitem => {
        if (subitem.isDefault) {
          subitem.item.isSelected = true;
          subitem.item.quantityMultiplier = 1;
        } else if (group.optionGroup.allowMultipleSameOptions) {
          subitem.item.quantityMultiplier = 0;
        }
      });
    });
  }

  selectDefaultSubProduct(product: PopProduct): void {
    if (!product.children.length) return;

    const defaultSubProduct = product.children.find(item => item.id === product.defaultChildId);

    if (!this._isExistValidSubProducts(product)) {
      product.available = false;
    } else if (!defaultSubProduct || !defaultSubProduct.available) {
      this._setDefaultSubProduct(product);
    } else {
      product.children.forEach(subProduct => {
        subProduct.isSelected = product.defaultChildId === subProduct.id;
      });
    }
  }

  selectOption(option: PopProduct, optionGroup: PopOptionGroup): void {
    if (!optionGroup.multiple) {
      optionGroup.optionGroupSubitems.forEach(subitem => {
        subitem.item.isSelected = subitem.item.isSelected ? false : subitem.item.id === option.id;
      });
    } else {
      if (optionGroup.maxAllowedOptions && optionGroup.maxAllowedOptions > 0) {
        if (this.selectedOptionsCount(optionGroup) < optionGroup.maxAllowedOptions) {
          option.isSelected = !option.isSelected;
        } else {
          option.isSelected = false;
        }
      } else {
        option.isSelected = !option.isSelected;
      }
    }
  }

  selectedOptionsCount(optionGroup: PopOptionGroup): number {
    return optionGroup.optionGroupSubitems.reduce(
      (acc, currentValue) => (currentValue.item.isSelected ? acc + (currentValue.item.quantityMultiplier || 1) : acc),
      0,
    );
  }

  selectedMenuItemsCount(menuCategory: PopMenuCategory): number {
    return menuCategory.menuCategoryItems.reduce(
      (acc, currentValue) => (currentValue.item.isSelected ? acc + (currentValue.item.quantity || 1) : acc),
      0,
    );
  }

  toggleSubProduct(subProductId: number, product: PopProduct, select: boolean = true): void {
    product.children.forEach(item => {
      item.isSelected = subProductId === item.id ? select : false;
    });
  }

  selectMenuItem(itemCategories: PopMenuCategory[], selectedProduct: PopProduct, select: boolean): void {
    const category = itemCategories.find(item => item.id === selectedProduct.categoryId);

    if (!category) {
      return;
    }

    const product = category.menuCategoryItems.find(item => item.item.id === selectedProduct.id);

    if (!product) {
      return;
    }

    if (category.max === 1 || !category.allowMultiple) {
      this.unselectProductsFromMenuCategory(category);
    }
    product.item.isSelected = select;
    WidgetProductCatalogHelperService._selectMenuOptions(product.item, selectedProduct);
    const subitem = selectedProduct?.children.find(({ isSelected }) => isSelected);
    if (subitem) {
      this.toggleSubProduct(subitem.id, product.item, select);
    }
    const quantity = selectedProduct.quantity || 1;
    product.item.quantity = quantity;
    product.item.total = this.calculateMenuProductTotal(product.item, product.priceFinalValue, quantity);
    product.item.selectedMenuItems = product.item.itemOptionGroups
      .map(option => {
        return option.optionGroup.optionGroupSubitems.filter(item => item.item.isSelected).map(item => item.item);
      })
      .reduce((acc, val) => acc.concat(val), []);
  }

  resetSelectedMenuItems(itemCategories: PopMenuCategory[]): void {
    itemCategories.forEach(category => {
      category.menuCategoryItems.forEach(item => {
        if (item.item.isSelected) {
          if (item.item.itemOptionGroups) {
            item.item.itemOptionGroups.forEach(optionGroup => {
              optionGroup.optionGroup.optionGroupSubitems.forEach(subitem => {
                subitem.item.isSelected = false;
              });
            });
          }
          item.item.isSelected = false;
          item.item.total = item.priceFinalValue;
          item.item.children?.forEach(subitem => (subitem.isSelected = false));
        }
      });
    });
  }

  calculateMenuItemsTotal(itemCategories: PopMenuCategory[]): void {
    const quantity = 1;
    itemCategories.forEach(category => {
      category.menuCategoryItems.forEach(categoryItem => {
        categoryItem.item.total = this.calculateMenuProductTotal(
          categoryItem.item,
          categoryItem.priceFinalValue,
          quantity,
        );
      });
    });
  }

  getAllRequiredMenuItemsNotSelected(itemCategories: PopMenuCategory[]): number[] {
    return itemCategories
      .filter(category => category.min > 0)
      .filter(category => !category.menuCategoryItems.some(item => item.item.isSelected))
      .map(category => category.id);
  }

  unselectProductsFromMenuCategory(category: PopMenuCategory): void {
    category.menuCategoryItems.forEach(item => {
      item.item.isSelected = false;
      item.item.total = item.priceFinalValue;
      item.item.quantity = 0;
      this._unselectOptionsFromProduct(item.item);
    });
  }

  sortByOrder(a: any, b: any): number {
    return a.order - b.order;
  }

  sortOptionsByOrder(product: PopProduct): void {
    if (!product.itemOptionGroups || !product.itemOptionGroups.length) return;

    product.itemOptionGroups.sort(this.sortByOrder);

    product.itemOptionGroups.forEach(group => {
      group.optionGroup.optionGroupSubitems.sort((a, b) => a.item.order - b.item.order);
    });
  }

  sortProductsAndOptionsByOrder(products: PopProduct[]): void {
    // Sort products by order
    products.sort(this.sortByOrder);

    // Sort option groups by order
    products.forEach(item => {
      item.itemOptionGroups.sort(this.sortByOrder);
    });

    // Sort options by order
    products.forEach(item => {
      item.itemOptionGroups.forEach(group => {
        group.optionGroup.optionGroupSubitems.sort((a, b) => a.item.order - b.item.order);
      });
    });
  }

  private _unselectOptionsFromProduct(product: PopProduct): void {
    if (!product.itemOptionGroups) {
      return;
    }
    product.itemOptionGroups.forEach(optionGroup => {
      optionGroup.optionGroup.optionGroupSubitems.forEach(subitem => {
        subitem.item.isSelected = false;
      });
    });
  }

  private _generateOrderItem(
    product: PopProduct,
    quantity: number,
    comment: string,
    categoryId: number = null,
  ): (PopALaCarteOrderItem | PopMenuOrderItem) & { categoryId: number; price: number } {
    const { id = product.id } = product.children.find(child => child.isSelected) || {};
    // selecting correct price (for cases, when product have subproducts)
    const price = id === product.id ? product.price : product.children.find(child => child.isSelected).price;
    return {
      id,
      quantity,
      comment,
      categoryId,
      price,
      name: product.name,
      options: this._getSelectedOptions(product),
    };
  }

  private _getSelectedOptions(product: PopProduct): PopCartOption[] {
    const options: PopCartOption[] = [];

    if (product.itemOptionGroups) {
      product.itemOptionGroups.forEach(group => {
        group.optionGroup.optionGroupSubitems.forEach(subitem => {
          if (subitem.item.isSelected) {
            options.push({
              id: subitem.item.id,
              name: subitem.item.name,
              quantity: subitem.item.quantityMultiplier || 1,
              price: subitem.item.price,
              status:
                !group.optionGroup.multiple || !subitem.isDefault ? PopOptionStatus.Included : PopOptionStatus.Excluded,
            });
          }
        });
      });
    }

    return options;
  }

  private _isExistValidSubProducts(product: PopProduct): boolean {
    return product.children.some(subProduct => subProduct.available);
  }

  private _setDefaultSubProduct(product: PopProduct): void {
    const child = product.children.find(subProduct => subProduct.available);
    if (child) {
      child.isSelected = true;
    }
  }

  isSelectedSubProduct(product: PopProduct): boolean {
    return product.children.some(child => child.isSelected);
  }
}
