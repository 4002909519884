import { WeekDay } from './week-day.type';

export interface HappyHoursInterval<T = Date> {
  open: T;
  close: T;
  id?: number;
}

export interface HappyHours<T = Date, U = HappyHoursInterval<T>[]> {
  enabled: boolean;
  redirectUrl: string;
  closeMessage: string;
  aboutToCloseMessage: string;
  intervals: U extends DictionaryHappyHoursIntervals<T> ? U : HappyHoursInterval<T>[];
}

export type DictionaryHappyHoursIntervals<T = string> = Record<WeekDay, HappyHoursInterval<T>[]>;
