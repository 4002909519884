import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { WorkingHoursStoreModule } from '@bend/store/src/lib/working-hours';

import { TOP_NAV_HEIGHT } from '../../helpers';
import { CatalogIsReadOnlyService } from './services';

@NgModule({
  imports: [CommonModule, WorkingHoursStoreModule],
  providers: [
    CatalogIsReadOnlyService,
    {
      provide: TOP_NAV_HEIGHT,
      useValue: new BehaviorSubject(0),
    },
  ],
})
export class CatalogServicesModule {}
