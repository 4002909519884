import { createAction, props } from '@ngrx/store';

import { createType, StoreError, Widget } from '../shared';
import { storeKey } from './widgets.config';

const type = createType(storeKey);

export const actions = {
  changeSelectedPageId: createAction(type('change selected page id'), props<{ pageId: string }>()),

  addWidgets: createAction(type('add widgets'), props<{ widgets: Widget<any>[]; pageId: string }>()),
  addWidgetsWithAttributes: createAction(
    type('add widgets with attributes'),
    props<{ widgets: Widget<any>[]; pageId: string }>(),
  ),
  addWidgetsError: createAction(type('add widgets error'), props<{ pageId: string; error: StoreError }>()),
  resetWidgetsByPage: createAction(type('reset widgets by page'), props<{ pageId: string }>()),
  reset: createAction(type('reset')),
};
