import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Catalog } from '../shared';
// TODO: what is this service ??????????
@Injectable()
export class CatalogHttpService {
  get(): Observable<Catalog> {
    return of();
    // {
    //   id: 1,
    //   name: 'catalog 1',
    //   categories: [
    //     {
    //       id: 2,
    //       name: 'category 2',
    //       parentId: 1,
    //     },
    //   ],
    //   subCategories: [
    //     {
    //       id: 3,
    //       name: 'sub category 1',
    //       parentId: 2,
    //     },
    //   ],
    //   items: [
    //     {
    //       id: 4,
    //       name: 'item 1',
    //       parentId: 2,
    //     },
    //   ],
    //   subItems: [
    //     {
    //       id: 5,
    //       name: 'sub item 1',
    //       parentId: 4,
    //     },
    //   ],
    //   menus: [
    //     {
    //       id: 6,
    //       name: 'menu 1',
    //       parentId: 1,
    //     },
    //   ],
    //   menuCategories: [
    //     {
    //       id: 7,
    //       name: 'menu category 1',
    //       parentId: 6,
    //     },
    //   ],
    //   menuItems: [
    //     {
    //       id: 8,
    //       name: 'menu item 1',
    //       parentId: 7,
    //     },
    //   ],
    //   optionGroups: [
    //     {
    //       id: 9,
    //       name: 'option group 1',
    //       parentsIds: [4],
    //     },
    //   ],
    //   options: [
    //     {
    //       id: 10,
    //       name: 'option 1',
    //       parentId: 9,
    //     },
    //   ],
    // },
  }
}
