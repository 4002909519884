import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonAbstractComponent } from '../../abstract';

@Component({
  selector: 'designer-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['../../styles/common-button.scss', './text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextButtonComponent extends ButtonAbstractComponent {
  constructor() {
    super();
  }
}
