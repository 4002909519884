import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './users.config';
import { UsersEffects } from './users.effects';
import { UsersHttpService } from './users.http.service';
import { reducer } from './users.reducer';
import { UsersService } from './users.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([UsersEffects])],
  providers: [UsersService, UsersHttpService],
})
export class UsersStoreModule {}
