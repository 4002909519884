import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { EnvModule } from '@bend/env';

import { SocketService } from './socket.service';
import { SocketConfig } from './socket.type';

@NgModule({
  imports: [CommonModule, EnvModule],
})
export class SocketModule {
  static forRoot(config: SocketConfig): ModuleWithProviders<any> {
    return {
      ngModule: SocketModule,
      providers: [{ provide: SocketService, useClass: SocketService, deps: config.deps }],
    };
  }
}
