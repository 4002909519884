import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LanguagesButtonsListModule } from '../languages-buttons-list/languages-buttons-list.module';
import { LanguagesButtonsListContainerComponent } from './languages-buttons-list-container.component';

@NgModule({
  declarations: [LanguagesButtonsListContainerComponent],
  imports: [CommonModule, LanguagesButtonsListModule],
  exports: [LanguagesButtonsListContainerComponent],
})
export class LanguagesButtonsListContainerModule {}
