import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { ApiDesignerService } from '@bend/store';

import { User } from '@designer-store/users/users.models';

@Injectable()
export class UsersHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getUsers(
    offset?: number,
    limit?: number,
    search?: string,
    appSlug?: string,
  ): Observable<{ count: number; docs: User[] }> {
    return this._api.admins('v1').pipe(
      switchMap(url =>
        this._http.get<{ count: number; docs: User[] }>(url, {
          params: { offset, limit, ...(search && { search }), ...(appSlug && { appSlug }) },
        }),
      ),
    );
  }

  addUser(user: User, appSlug?: string): Observable<{ _id: string }> {
    return this._api
      .admins('v1')
      .pipe(switchMap(url => this._http.post<{ _id: string }>(url, user, { params: { ...(appSlug && { appSlug }) } })));
  }

  deleteAdminUser(id: string, appSlug: string): Observable<void> {
    return this._api.admins('v1', id).pipe(switchMap(url => this._http.delete<void>(url, { params: { appSlug } })));
  }

  updateUser({ applications, _id, ...rest }: User, appSlug: string): Observable<void> {
    const backendUser = {
      ...rest,
      allowedAppsSlugs: applications.map(({ slug }) => slug),
    };

    return this._api
      .admins('v1', _id)
      .pipe(switchMap(url => this._http.patch<void>(url, backendUser, { params: { appSlug } })));
  }

  getUser(id: string, appSlug: string): Observable<User> {
    return this._api.admins('v1', id).pipe(switchMap(url => this._http.get<User>(url, { params: { appSlug } })));
  }
}
