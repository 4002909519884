import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { OrderMenuItem } from '../shared';
import { actions } from './order-menu-items.actions';
import { State } from './order-menu-items.type';

const initialState: State = {
  entities: [],
};

const updateOrderMenuItems = (state: State, orderMenuItems: OrderMenuItem[]): State => {
  return {
    ...state,
    // looks like hard reload array
    // not normal..
    entities: [...orderMenuItems],
  };
};

const orderMenuItemsReducer = createReducer(
  initialState,
  on(actions.addOrderMenuItems, (state, { orderMenuItems }) => ({
    ...state,
    entities: [...state.entities, ...orderMenuItems],
  })),
  on(actions.upsertOrderMenuItems, (state, { orderMenuItems }) => updateOrderMenuItems(state, orderMenuItems)),
  on(actions.updateOrderMenuItems, (state, { orderMenuItems }) => updateOrderMenuItems(state, orderMenuItems)),
);

export function reducer(state: State | undefined, action: Action): State {
  return orderMenuItemsReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
