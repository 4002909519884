<app-emoji-picker
  *ngIf="emoji"
  [inputControl]="control"
  [pickerPosition]="emoji"
  class="element__emoji-picker"
  [class.element__emoji-picker-rtl]="isRtl$ | async"
></app-emoji-picker>
<div class="element">
  <div
    [class.element__border--error]="isShowError"
    [class.element__border--disabled]="isDisabled"
    class="element__border"
  ></div>
  <input
    #input
    [formControl]="control"
    [placeholder]="placeHolder"
    [name]="name"
    [type]="type"
    [class.element__input--disabled]="isDisabled"
    class="element__input"
  />
</div>
<app-input-errors [ngControl]="ngControl" [showError]="isShowError"></app-input-errors>
