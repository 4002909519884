export interface Widget {
  type: string;
  attributes: object;
}

export interface PopQueryParam {
  key: string;
  value: string;
}

export interface PopDirectLink {
  link: string;
  linkWithoutQueryParams: string;
  queryParams: {
    [key: string]: any;
  };
}

export interface OrderSettings {
  allowPickup: boolean;
  isShared: boolean;
  payBefore: boolean;
  showTableName: boolean;
  isPaymentRequired: boolean;
}

export interface OrderThingMeta {
  name: string;
  slug: string;
  restaurantName: string;
}

export interface WorkingHoursStatus {
  scheduleType: WorkingHoursScheduleType;
  closeIn: number;
}

export class PopCurrency {
  code = 'EUR';
  symbol = '€';
}

export class PopMargin {
  top = 10;
  bottom = 10;
}

export class PopWidthStyle {
  marginLeft: number = null;
  marginRight: number = null;
  width = '';
}

export enum WorkingHoursScheduleType {
  ALWAYS_ONLINE = 'alwaysOnline',
  CUSTOM = 'custom',
}

export enum OrderStatusEnum {
  NEW = 'new',
  PAID = 'paid',
  READY = 'ready',
  DELIVERED = 'delivered',
  ACCOUNTED = 'accounted',
  CLOSED = 'closed',
  SENT = 'sent',
  SEND = 'send',
  PICK_UP_READY = 'pick_up_ready',
  PARTIALLY_READY = 'partially-ready',
}

export enum PopAccessType {
  READONLY = 'readonly',
  BLUR = 'blur',
}

export enum PopValidationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  FAILED = 'failed',
}

export class PopSettings {
  type = PopAccessType.READONLY;
  blurMessage = 'Read only';
  validationStatus = PopValidationStatus.FAILED;
  readOnlyDisplayMsg = 'Read only';
}
