import { createAction, props } from '@ngrx/store';

import { createType, OrderItemOption } from '../shared';
import { storeKey } from './order-options.config';

const type = createType(storeKey);

export const actions = {
  addOrderItemOptions: createAction(type('add order items options'), props<{ orderItemOptions: OrderItemOption[] }>()),
  upsertOrderItemOptions: createAction(
    type('upsert order items options'),
    props<{ orderItemOptions: OrderItemOption[] }>(),
  ),
  updateOrderItemOptions: createAction(
    type('update order items options'),
    props<{ orderItemOptions: OrderItemOption[] }>(),
  ),
  reset: createAction(type('reset')),
};
