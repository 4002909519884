import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

@Injectable()
export class DocumentViewerService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  hashDocument(url: string): Observable<any> {
    return this._api.hashify('v1').pipe(switchMap(api => this._http.post<any>(api, { url })));
  }
}
