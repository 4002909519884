<div class="tabs">
  <div class="tabs__item" *ngFor="let label of tabs.labels; index as i" [class.active]="i === index">
    <button class="button app-button-color" (click)="select(i)">
      <span>{{ label | translate }}</span>
      <div class="delete" *ngIf="allowToDelete">
        <span class="icon" (click)="delete(i, $event)">x</span>
      </div>
    </button>
    <div class="underline app-button-color"></div>
  </div>
</div>
