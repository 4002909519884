import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { WorkingHoursInterval, WorkingScheduleType } from '@bend/store-shared';

@Injectable()
export class WorkingHoursService {
  constructor(private _settings: SettingsService) {}

  get isClose(): Observable<boolean> {
    return this.isOpen.pipe(map(isOpen => !isOpen));
  }

  get isOpen(): Observable<boolean> {
    return timer(0, 1000).pipe(
      switchMap(() => this.isInIntervals(new Date())),
      share(),
    );
  }

  isInIntervals(time: Date): Observable<boolean> {
    return this._settings.workingHours.pipe(
      map(({ scheduleType, intervals }) => {
        if (scheduleType === WorkingScheduleType.AlwaysOpen) return true;

        return this._isInIntervals(time, intervals);
      }),
    );
  }

  private _isInIntervals(time: Date, intervals: WorkingHoursInterval[]): boolean {
    return intervals.some(this._isInInterval(time));
  }

  private _isInInterval(time: Date): ({ open, close }: { open: Date; close: Date }) => boolean {
    return ({ open, close }) => open.getTime() <= time.getTime() && close.getTime() >= time.getTime();
  }
}
