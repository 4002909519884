import { ItemUnavailabilityDisplay, Prices } from '@bend/store';
import { OrderUser, Recommendation } from '@bend/store-shared';

import { PopCurrency, PopSettings } from '../widget';

export interface WidgetProductCatalog {
  catalogs: PopCatalog[];
  isReadOnly: boolean;
  isConsultingMode: boolean;
  itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  allowOrderForOthers: boolean;
  allowCommonPot: boolean;
  isEditMode: boolean;
  mainColor: string;
  textColor: string;
  secondColor: string;
  showKitchenComment: boolean;
  isCommentToKitchenRequired: boolean;
  disableAditionsAfterOrder: boolean;
  forceShowProductDescription: boolean;
  returnUrl: string;
  currency: PopCurrency;
  style: {
    margin: {
      top: number;
      bottom: number;
    };
  };
  styleConfig: ProductCatalogStyleConfig;
  page: PageAnalytics;
  pop: PopSettings;
  topBar: {
    display: boolean;
  };
  workingHours: string;
}

export interface ProductCatalogStyleConfig {
  hasSearch: true;
  productDescriptionStyle: number;
  stepByStepCategories: boolean;
  stepByStepMenus: boolean;
  style: ProductCatalogDisplayType;
  categoriesStyle: number;
  menuType: number;
  categoryStyleType: number;
  fullWidth: string;
  menuStyleType: number;
  categoryAlignType: number;
  itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
}

export interface PageAnalytics {
  name: string;
}

export interface PopCatalog {
  name: string;
  catalogId: string;
  catalogType: PopCatalogType;
  isDefault: boolean;
  uniqueId?: number;
  visibleCategories: number[];
  visibleMenus: number[];
  imageUrl: string;
  imageMeta: {
    url: string;
    additionalUrl?: string;
  };
}

export interface PopCatalogFront extends PopCatalog {
  link: string;
}

export enum PopCatalogType {
  Menu = 'menu',
  ALaCarte = 'a_la_carte',
}

export interface PopProductCategory {
  id: number;
  catalogId: number;
  name: string;
  order: number;
  parentId: number;
  imageUrl: string;
  description: string;
  isDefault: boolean;
}

export interface PopProductSubcategory extends PopProductCategory {
  items: PopProduct[];
}

export interface PopProductSubcategoryResponse {
  itemCategories: PopProductSubcategory[];
  items: PopProduct[];
}

export interface PopProduct {
  id: number;
  calories: number;
  catalogId: number;
  groupId?: number;
  categoryId: number;
  description: string;
  imageUrl: string;
  name: string;
  order: number;
  subProductDisplayText: string;
  defaultChildId: number;
  priceFinalValue?: number;
  priceInitialValue?: number;
  price?: number;
  prices: Prices;
  sku: string;
  thumbnailImageUrl: string;
  title: string;
  tvaId: number;
  type: string;
  isReadOnly: boolean;
  available: boolean;
  snoozeBeginsAt: string;
  snoozeEndsAt: string;
  hasRecommendations: boolean;
  itemTags?: [
    {
      tag: PopProductTag;
    },
  ];
  children: PopProduct[];
  itemOptionGroups: ItemOptionGroup[];
  isSelected: boolean;
  total: number;
  quantityMultiplier: number;
  selectedMenuItems: this[];
  quantity: number;
}

export interface PopOptionGroup {
  id: number;
  name: string;
  nameToDisplay: string;
  multiple: boolean;
  maxAllowedOptions: number;
  minAllowedOptions: number;
  allowMultipleSameOptions: boolean;
  required: boolean;
  priceDisplayMode?: PRICE_DISPLAY_MODE;
  optionGroupSubitems: PopOptionGroupSubitem[];
}

export interface PopOptionGroupSubitem {
  id: number;
  isDefault: boolean;
  item: PopProduct;
}

export interface PopProductDetailsDialogParams {
  product: PopProduct;
  attributes: WidgetProductCatalog;
  isMenu: boolean;
  pageAnalytics: PageAnalytics;
  isReadOnlyMenu?: boolean;
  category: PopMenuCategory;
}

// TD Strict Template (Serghei)
// Extend form PopProduct resolve the problem
export interface PopMenu {
  id: number;
  description: string;
  imageUrl: string;
  name: string;
  order: number;
  menuCategories: PopMenuCategory[];
  price: number;
  priceFinalValue: number;
  priceInitialValue: number;
  prices: Prices;
  thumbnailImageUrl: string;
  title: string;
  type: number;
  available: boolean;
  snoozeBeginsAt: string;
  snoozeEndsAt: string;
  isSelected: boolean;
  itemTags: [
    {
      tag: PopProductTag;
    },
  ];
  isReadOnly: boolean;
}

export interface ItemOptionGroup {
  id: number;
  itemId?: number;
  optionGroupId?: 12056;
  optionGroup: PopOptionGroup;
  order: number;
}

export interface PopMenuCategory {
  id: number;
  allowMultiple: boolean;
  allowQuantity: boolean;
  name: string;
  menuCategoryItems: MenuItem[];
  folded?: boolean;
  max: number;
  min: number;
  description: string;
}

export interface MenuItem {
  item: PopProduct;
  quantity: number;
  priceFinalValue: number;
  priceInitialValue: number;
  prices: Prices;
  order: number;
}

export interface PopProductTag {
  id: number;
  name: string;
  imageUrl: string;
}

export interface PopOrderItem {
  id: number;
  quantity: number;
  comment: string;
  name: string;
  price: number;
}

export interface PopALaCarteOrderItem extends PopOrderItem {
  options: PopCartOption[];
  recommendations?: Recommendation[];
}

export interface PopMenuOrderItem extends PopOrderItem {
  items: PopOrderMenuItem[];
}
export interface PopOrderItemRequestData {
  userId: string;
  orderItem: PopALaCarteOrderItem | PopMenuOrderItem;
  type?: string;
}

export interface PopOrderMenuItem {
  id: number;
  name: string;
  quantity: number;
  price: number;
  options: PopCartOption[];
}

export interface PopCartOption {
  id: number;
  name: string;
  quantity: number;
  price: number;
  status: PopOptionStatus;
}

export enum PopOptionStatus {
  Included = 'included',
  Excluded = 'excluded',
}

export type PopCartVisitor = Omit<OrderUser, 'orderId' | 'id'> & { id: string };

export interface PopSharedCartDialogParams {
  product: PopProduct;
  attributes: WidgetProductCatalog;
}

export interface PopCreateVisitorDialogParams {
  attributes: WidgetProductCatalog;
  isOtherVisitor: boolean;
}

export enum CART_ITEM_TYPE {
  SINGLE = 'single',
  COMMON_POT = 'common_pot',
  COMMON_POT_ORDERED = 'common_pot_ordered',
  SPLITTED = 'splitted',
}

export enum PRICE_DISPLAY_MODE {
  FULL = 'full',
  ADDITIONAL = 'additional',
}

export enum ProductCatalogDisplayType {
  STYLE1 = 1,
  STYLE2 = 2,
}
