import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './cart.config';
import { CartEffects } from './cart.effects';
import { CartHttpService } from './cart.http.service';
import { reducer } from './cart.reducer';
import { CartService } from './cart.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([CartEffects])],
  providers: [CartHttpService, CartService],
})
export class CartStoreModule {}
