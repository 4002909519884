<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="subjectInput" class="form-label">{{ 'WIDGETS.SHARE.SUBJECT' | translate }}</label>
      <input
        id="subjectInput"
        type="text"
        class="form-input form-input--long-text"
        placeholder="Subject"
        formControlName="subject"
      />
      <span class="form-field-info">{{ 'WIDGETS.SHARE.AUTOCOMPLETE_SUBJECT' | translate }}</span>
    </div>
    <div class="form-row">
      <label for="bodyTextarea" class="form-label">{{ 'WIDGETS.SHARE.BODY' | translate }}</label>
      <textarea
        id="bodyTextarea"
        [placeholder]="'WIDGETS.SHARE.text' | translate"
        class="form-input form-input--long-textarea"
        formControlName="body"
        rows="10"
      ></textarea>
      <span class="form-field-info">{{ 'WIDGETS.SHARE.AUTOCOMPLETE_BODY' | translate }}</span>
    </div>
  </form>
</pop-widget-edit-widget>
