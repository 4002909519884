import { Category } from './category.type';
import { Item } from './item.type';
import { Menu } from './menu.type';
import { MenuCategory } from './menu-category.type';
import { MenuItem } from './menu-item.type';
import { Option } from './option.type';
import { OptionGroup } from './option-group.type';
import { SubCategory } from './sub-category.type';
import { SubItem } from './sub-item.type';

export interface Catalog {
  id: number;
  name: string;
  categories: Category[];
  subCategories: SubCategory[];
  items: Item[];
  subItems: SubItem[];
  menus: Menu[];
  menuCategories: MenuCategory[];
  menuItems: MenuItem[];
  optionGroups: OptionGroup[];
  options: Option[];
}

export enum CatalogItemType {
  Menu = 'menu',
  ALaCarte = 'a_la_carte',
}

export enum ItemUnavailabilityDisplay {
  Invisible = 'invisible',
  OutOfStock = 'out-of-stock',
}

/*
 * studio catalog settings
 */
export interface SettingsCatalog {
  categoryStyleType: CategoryStyleType;
  categoriesStyle: CategoriesDisplayType;
  style: ProductCatalogDisplayType;
  productDescriptionStyle: ProductCatalogTeaserDisplayType;
  itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  fullWidth: boolean;
  menuStyleType: MenuStyleType;
  menuType: MenuType;
  menuItemStyleType: MenuItemStyleType;
  CategoryAlignType: CategoryAlignType;
}

export enum ProductCatalogDisplayType {
  Classic = 1,
  Compact = 2,
  Extended = 3,
  SideBySide = 4,
}

export enum ProductCatalogTeaserDisplayType {
  Style1 = 1,
  Style2 = 2,
}

export enum MenuType {
  SinglePage = 0,
  StepByStep = 1,
}

export enum MenuStyleType {
  Classic = 1,
  Extended = 2,
  Compact = 3,
  SideBySide = 4,
}

export enum MenuItemStyleType {
  Classic = 1,
  Extended = 3,
  SideBySide = 4,
}

export enum CategoriesDisplayType {
  Style1 = 1,
  Style3 = 3,
}

export enum CategoryStyleType {
  Style1 = 1,
  Style2 = 2,
}

export enum CategoryAlignType {
  Style1 = 1,
  Style2 = 2,
}

export interface CatalogPopulated {
  id: number;
  name: string;
  items: ItemsCatalogPopulated[];
}

export interface ItemsCatalogPopulated {
  id: number;
  name: string;
}
