<div
  *ngIf="isValid; else elseBlock"
  class="widget-title"
  [style.marginTop.px]="attributes.style.margin.top"
  [style.marginBottom.px]="attributes.style.margin.bottom"
  [style.marginLeft.px]="marginLeft"
  [style.marginRight.px]="marginRight"
>
  <div
    class="widget-title-content"
    [style.width.px]="width"
    [style.height.px]="attributes.style.height || ''"
    [style.backgroundColor]="attributes.style.backgroundColor"
  >
    <h2
      class="title"
      [style.fontSize.px]="attributes.style.fontSize"
      [style.color]="attributes.style.textColor"
      [style.textAlign]="attributes.style.textAlign"
    >
      {{ title$ | async }}
    </h2>
  </div>
</div>

<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-title">{{ noValidMessage }}</div>
</ng-template>
