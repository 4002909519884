<ng-container [ngSwitch]="styleNumber">
  <pop-classic-item
    *ngSwitchCase="1"
    [product]="product"
    [currency]="currency"
    [loadingProductId]="loadingProductId"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="secondColor"
    (clicked)="emitLoadProductDetails($event)"
    [isMenu]="isMenu"
  ></pop-classic-item>
  <pop-compact-item
    *ngSwitchCase="2"
    [product]="product"
    [currency]="currency"
    [loadingProductId]="loadingProductId"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="secondColor"
    (clicked)="emitLoadProductDetails($event)"
    [isMenu]="isMenu"
  ></pop-compact-item>
  <pop-extended-item
    *ngSwitchCase="3"
    [product]="product"
    [currency]="currency"
    [loadingProductId]="loadingProductId"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="secondColor"
    (clicked)="emitLoadProductDetails($event)"
    [isMenu]="isMenu"
  ></pop-extended-item>
  <pop-side-item
    *ngSwitchCase="4"
    [product]="product"
    [currency]="currency"
    [loadingProductId]="loadingProductId"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="secondColor"
    (clicked)="emitLoadProductDetails($event)"
    [isMenu]="isMenu"
  ></pop-side-item>
</ng-container>
