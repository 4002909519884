<div *ngIf="isValid; else elseBlock" class="widget-profile">
  <div class="img-wrapper" *ngIf="attributes.profile_image?.url" [style.borderColor]="attributes.mainColor">
    <img
      class="profile-img"
      [src]="attributes.profile_image?.url"
      [alt]="attributes.profile_image?.alt"
      width="80"
      height="80"
    />
  </div>
  <div class="description">
    <h3 [style.color]="attributes.mainColor" class="profile-name">{{ attributes.name }}</h3>
    <p *ngIf="!isShowMore" class="profile-description">{{ shortDescription }}</p>
    <p *ngIf="isShowMore" class="profile-description">{{ longDescription }}</p>
    <a
      *ngIf="longDescription && !isShowMore"
      (click)="showMore()"
      [style.color]="attributes.mainColor"
      class="show-more-or-less"
    >
      {{ 'PROFILE.MORE' | translate }}
    </a>
    <a
      *ngIf="longDescription && isShowMore"
      (click)="showMore()"
      [style.color]="attributes.mainColor"
      class="show-more-or-less"
    >
      {{ 'PROFILE.LESS' | translate }}
    </a>
  </div>
  <div *ngIf="attributes.social" class="social-networks">
    <a
      *ngIf="attributes.social?.twitter"
      [href]="attributes.social?.twitter"
      target="_blank"
      class="social-network-link"
    >
      <svg
        height="30"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.41"
      >
        <g transform="matrix(1,0,0,1,-5546.94,-1524.42)">
          <g transform="matrix(0.0638909,0,0,0.0638909,5546.49,1523.97)">
            <path
              d="M256,7.025C118.494,7.025 7.025,118.494 7.025,256C7.025,393.506 118.494,504.975 256,504.975C393.506,504.975 504.976,393.506 504.976,256C504.975,118.494 393.504,7.025 256,7.025Z"
              style="fill-rule: nonzero"
              [style.fill]="attributes.mainColor"
            />
          </g>
          <g transform="matrix(0.65783,0,0,0.65783,5552.38,1531.77)">
            <path
              d="M20.877,2C22.519,2 24.382,2.652 25.426,3.738C26.724,3.486 27.949,3.025 29.05,2.386C28.625,3.687 27.718,4.779 26.54,5.469C27.693,5.332 28.797,5.035 29.82,4.59C29.054,5.707 28.087,6.69 26.971,7.477C26.981,7.715 26.987,7.955 26.987,8.195C26.987,15.562 21.445,24 10.939,24C7.715,24 4.507,23.112 1.982,21.53C2.428,21.584 2.883,21.631 3.343,21.631C6.019,21.631 8.482,20.74 10.439,19.242C7.937,19.199 5.827,17.586 5.103,15.373C5.45,15.437 5.81,15.473 6.178,15.473C6.696,15.473 7.203,15.406 7.681,15.277C5.068,14.768 3.1,12.514 3.1,9.813C3.1,9.787 3.1,9.764 3.1,9.74C3.871,10.158 4.75,10.41 5.687,10.44C4.154,9.437 3.147,7.734 3.147,5.799C3.147,4.777 3.428,3.818 3.919,2.998C6.735,6.367 10.945,8.588 15.693,8.822C15.594,8.414 15.543,7.984 15.543,7.553C15.543,4.473 17.721,2 20.877,2M29.82,4.59L29.825,4.59"
              style="fill: #fff"
            />
          </g>
        </g>
      </svg>
    </a>
    <a
      *ngIf="attributes.social?.facebook"
      [href]="attributes.social?.facebook"
      target="_blank"
      class="social-network-link"
    >
      <svg
        height="30"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.41"
      >
        <g transform="matrix(1,0,0,1,-5601.89,-1524.25)">
          <g transform="matrix(0.0638909,0,0,0.0638909,5601.44,1523.8)">
            <path
              d="M256,7.025C118.494,7.025 7.025,118.494 7.025,256C7.025,393.506 118.494,504.975 256,504.975C393.506,504.975 504.976,393.506 504.976,256C504.975,118.494 393.504,7.025 256,7.025Z"
              style="fill-rule: nonzero"
              [style.fill]="attributes.mainColor"
            />
          </g>
          <g transform=" matrix(0.420361,0,0,0.420361,5565.78,302.003)">
            <path
              d="M140.995,2926.41C142.155,2926.41 143.096,2927.35 143.096,2928.51L143.096,2962.37C143.096,2963.53
                  142.155,2964.47 140.995,2964.47L131.296,2964.47L131.296,2949.73L136.243,2949.73L136.984,2943.99L131.296,2943.99L131.296,2940.32C131.296,2938.66
                  131.758,2937.53 134.143,2937.53L137.185,2937.53L137.185,2932.39C136.658,2932.32 134.853,2932.16 132.752,2932.16C128.366,2932.16
                  125.364,2934.84 125.364,2939.75L125.364,2943.99L120.404,2943.99L120.404,2949.73L125.364,2949.73L125.364,2964.47L107.134,2964.47C105.974,2964.47
                  105.034,2963.53 105.034,2962.37L105.034,2928.51C105.034,2927.35 105.974,2926.41 107.134,2926.41L140.995,2926.41Z"
              style="fill: #fff"
            />
          </g>
        </g>
      </svg>
    </a>
    <a
      *ngIf="attributes.social?.instagram"
      [href]="attributes.social?.instagram"
      target="_blank"
      class="social-network-link"
    >
      <svg
        height="30"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.41"
      >
        <g transform="matrix(1,0,0,1,-5497.52,-1525.21)">
          <g transform="matrix(0.0638909,0,0,0.0638909,5497.07,1524.76)">
            <path
              d="M256,7.025C118.494,7.025 7.025,118.494 7.025,256C7.025,393.506 118.494,504.975 256,504.975C393.506,504.975
                  504.976,393.506 504.976,256C504.975,118.494 393.504,7.025 256,7.025Z"
              style="fill-rule: nonzero"
              [style.fill]="attributes.mainColor"
            />
          </g>
          <g transform="matrix(0.372093,0,0,0.372093,5443.66,445.138)">
            <path
              d="M187.5,2923.94C181.661,2923.94 180.929,2923.97 178.636,2924.07C176.347,2924.18 174.784,2924.54
                  173.417,2925.07C172.003,2925.62 170.804,2926.36 169.609,2927.55C168.413,2928.75 167.678,2929.95 167.129,2931.36C166.597,2932.73
                  166.234,2934.29 166.129,2936.58C166.025,2938.87 166,2939.6 166,2945.44C166,2951.28 166.025,2952.01 166.129,2954.31C166.234,2956.6
                  166.597,2958.16 167.129,2959.53C167.678,2960.94 168.413,2962.14 169.609,2963.33C170.804,2964.53 172.003,2965.26
                  173.417,2965.81C174.784,2966.35 176.347,2966.71 178.636,2966.81C180.929,2966.92 181.661,2966.94 187.5,2966.94C193.339,2966.94
                  194.071,2966.92 196.364,2966.81C198.653,2966.71 200.216,2966.35 201.583,2965.81C202.997,2965.26 204.196,2964.53
                  205.391,2963.33C206.587,2962.14 207.322,2960.94 207.871,2959.53C208.403,2958.16 208.766,2956.6 208.871,2954.31C208.975,2952.01
                  209,2951.28 209,2945.44C209,2939.6 208.975,2938.87 208.871,2936.58C208.766,2934.29 208.403,2932.73 207.871,2931.36C207.322,2929.95
                  206.587,2928.75 205.391,2927.55C204.196,2926.36 202.997,2925.62 201.583,2925.07C200.216,2924.54 198.653,2924.18
                  196.364,2924.07C194.071,2923.97 193.339,2923.94 187.5,2923.94ZM187.5,2927.82C193.241,2927.82 193.921,2927.84
                  196.188,2927.94C198.284,2928.04 199.422,2928.39 200.18,2928.68C201.184,2929.07 201.9,2929.54 202.652,2930.29C203.405,2931.04
                  203.87,2931.76 204.26,2932.76C204.555,2933.52 204.905,2934.66 205.001,2936.76C205.104,2939.02 205.126,2939.7
                  205.126,2945.44C205.126,2951.18 205.104,2951.86 205.001,2954.13C204.905,2956.23 204.555,2957.37 204.26,2958.12C203.87,2959.13
                  203.405,2959.84 202.652,2960.6C201.9,2961.35 201.184,2961.81 200.18,2962.2C199.422,2962.5 198.284,2962.85
                  196.188,2962.94C193.921,2963.05 193.241,2963.07 187.5,2963.07C181.759,2963.07 181.079,2963.05 178.812,2962.94C176.716,2962.85
                  175.578,2962.5 174.82,2962.2C173.816,2961.81 173.1,2961.35 172.348,2960.6C171.595,2959.84 171.129,2959.13
                  170.74,2958.12C170.445,2957.37 170.095,2956.23 169.999,2954.13C169.896,2951.86 169.874,2951.18 169.874,2945.44C169.874,2939.7
                  169.896,2939.02 169.999,2936.76C170.095,2934.66 170.445,2933.52 170.74,2932.76C171.129,2931.76 171.595,2931.04
                  172.348,2930.29C173.1,2929.54 173.816,2929.07 174.82,2928.68C175.578,2928.39 176.716,2928.04 178.812,2927.94C181.079,2927.84
                  181.759,2927.82 187.5,2927.82ZM187.5,2934.4C181.402,2934.4 176.459,2939.35 176.459,2945.44C176.459,2951.54
                  181.402,2956.48 187.5,2956.48C193.598,2956.48 198.541,2951.54 198.541,2945.44C198.541,2939.35 193.598,2934.4
                  187.5,2934.4ZM187.5,2952.61C183.542,2952.61 180.333,2949.4 180.333,2945.44C180.333,2941.49 183.542,2938.28
                  187.5,2938.28C191.458,2938.28 194.667,2941.49 194.667,2945.44C194.667,2949.4 191.458,2952.61 187.5,2952.61ZM201.557,2933.97C201.557,2935.39
                  200.402,2936.55 198.977,2936.55C197.552,2936.55 196.397,2935.39 196.397,2933.97C196.397,2932.54 197.552,2931.39
                  198.977,2931.39C200.402,2931.39 201.557,2932.54 201.557,2933.97Z"
              style="fill: #fff"
            />
          </g>
        </g>
      </svg>
    </a>
    <a
      *ngIf="attributes.social?.linkedin"
      [href]="attributes.social?.linkedin"
      target="_blank"
      class="social-network-link"
    >
      <svg
        height="30"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.41"
      >
        <g transform="matrix(1,0,0,1,-5452.53,-1525.81)">
          <g transform="matrix(0.0638909,0,0,0.0638909,5452.08,1525.36)">
            <path
              d="M256,7.025C118.494,7.025 7.025,118.494 7.025,256C7.025,393.506 118.494,504.975 256,504.975C393.506,504.975
                  504.976,393.506 504.976,256C504.975,118.494 393.504,7.025 256,7.025Z"
              style="fill-rule: nonzero"
              [style.fill]="attributes.mainColor"
            />
          </g>
          <g id="post.-linkedin" transform="matrix(0.0313725,0,0,0.0313725,5460.44,1533.71)">
            <path
              d="M459,0L51,0C22.95,0 0,22.95 0,51L0,459C0,487.05 22.95,510 51,510L459,510C487.05,510 510,487.05
                  510,459L510,51C510,22.95 487.05,0 459,0ZM153,433.5L76.5,433.5L76.5,204L153,204L153,433.5ZM114.75,160.65C89.25,160.65
                  68.85,140.25 68.85,114.75C68.85,89.25 89.25,68.85 114.75,68.85C140.25,68.85 160.65,89.25 160.65,114.75C160.65,140.25
                  140.25,160.65 114.75,160.65ZM433.5,433.5L357,433.5L357,298.35C357,277.951 339.15,260.1 318.75,260.1C298.35,260.1
                  280.5,277.951 280.5,298.35L280.5,433.5L204,433.5L204,204L280.5,204L280.5,234.6C293.25,214.2 321.3,198.9 344.25,198.9C392.7,198.9
                  433.5,239.7 433.5,288.15L433.5,433.5Z"
              style="fill: #fff; fill-rule: nonzero"
            />
          </g>
        </g>
      </svg>
    </a>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-profile">{{ noValidMessage }}</div>
</ng-template>
