import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxMaskModule } from 'ngx-mask';

import { IconModule } from '@bend/icon';
import { TranslateModule } from '@bend/translate';

import {
  ArrowToggleComponent,
  BottomButtonComponent,
  ButtonComponent,
  ButtonIconComponent,
  ButtonModalSelectComponent,
  ButtonQuantityComponent,
  ButtonRadioComponent,
  ButtonSmallComponent,
  ButtonXComponent,
  CheckboxComponent,
  InputAutocompleteAddressComponent,
  InputComponent,
  InputDateComponent,
  InputErrorsComponent,
  InputPhoneComponent,
  ModalSelectRadioComponent,
  QuantityModifierComponent,
  RadioComponent,
  ResendCodeComponent,
  SpinnerComponent,
  TextareaComponent,
  WrapTitleComponent,
} from './components';
import { AbsPipe, CurrencyPipe } from './pipes';
import { ModalService, TimerService } from './services';

@NgModule({
  declarations: [
    ArrowToggleComponent,
    ButtonQuantityComponent,
    BottomButtonComponent,
    ButtonRadioComponent,
    ButtonSmallComponent,
    ModalSelectRadioComponent,
    ButtonModalSelectComponent,
    TextareaComponent,
    ButtonComponent,
    SpinnerComponent,
    InputAutocompleteAddressComponent,
    InputComponent,
    InputPhoneComponent,
    InputDateComponent,
    InputErrorsComponent,
    ButtonXComponent,
    AbsPipe,
    RadioComponent,
    CheckboxComponent,
    WrapTitleComponent,
    ResendCodeComponent,
    QuantityModifierComponent,
  ],
  imports: [
    CommonModule,
    CurrencyPipe,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    MatDialogModule,
    NgxMaskModule.forChild(),
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ButtonIconComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    ArrowToggleComponent,
    ButtonQuantityComponent,
    BottomButtonComponent,
    CurrencyPipe,
    AbsPipe,
    ButtonRadioComponent,
    ButtonSmallComponent,
    ButtonModalSelectComponent,
    TextareaComponent,
    ButtonComponent,
    SpinnerComponent,
    InputAutocompleteAddressComponent,
    InputComponent,
    InputPhoneComponent,
    InputDateComponent,
    ButtonXComponent,
    RadioComponent,
    CheckboxComponent,
    WrapTitleComponent,
    ResendCodeComponent,
    QuantityModifierComponent,
  ],
  providers: [ModalService, TimerService],
})
export class SharedComponentsModule {}
