import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { LinkService } from '../link.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { Carousel, Slide, WidgetCarousel } from './carousel';
import { WidgetCarouselService } from './carousel.service';

@Component({
  selector: 'pop-widget-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class WidgetCarouselComponent implements WidgetComponent, OnInit {
  static widgetName = 'carousel';
  @Input() attributes: WidgetCarousel;

  carousel: Carousel;
  isValid: boolean;
  noValidMessage: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _widgetCarouselService: WidgetCarouselService,
    private _warningService: WarningService,
    private _linkService: LinkService,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.carouselId) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetCarouselComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this._widgetCarouselService.getCarouselData(this.attributes.carouselId).subscribe((carouselData: Carousel) => {
      this.carousel = { ...carouselData };
      if (this.carousel.slides && this.carousel.slides.length) {
        this.carousel.slides.sort((a, b) => a.order - b.order);
        this.isValid = true;
      } else {
        this.noValidMessage = this._warningService.showWarn(WidgetCarouselComponent.widgetName, WarnTypeEnum.NoData);
      }

      this._cdr.detectChanges();
    });
  }

  navigate(slide: Slide): void {
    const { openInNewTab, isEditMode } = this.attributes;
    this._linkService.navigate(slide.link, openInNewTab, isEditMode);
  }
}
