import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './smiles.config';
import { adapter } from './smiles.reducer';
import { State } from './smiles.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const entities = createSelector(stateSelector, selectEntities);
export const smiles = createSelector(stateSelector, selectAll);
export const ids = createSelector(stateSelector, selectIds);
export const error = createSelector(stateSelector, state => state.error);
export const isLoading = createSelector(stateSelector, smile => smile.isLoading);
export const isLoadedBy = createSelector(stateSelector, state => state.isLoadedBy);
export const total = createSelector(stateSelector, state => state.total);
export const isLoaded = createSelector(stateSelector, state => state.isLoaded);
export const limit = createSelector(stateSelector, state => state.limit);
export const skip = createSelector(stateSelector, state => state.skip);
