<div
  *ngIf="isValid; else elseBlock"
  class="widget-buttons"
  [style.marginTop.px]="attributes.style.margin.top"
  [style.marginBottom.px]="attributes.style.margin.bottom"
  [style.marginLeft.px]="attributes.style.showBackground ? '' : '0'"
  [style.marginRight.px]="attributes.style.showBackground ? '' : '0'"
  [style.backgroundColor]="attributes.style.showBackground ? '' : 'transparent'"
  [style.boxShadow]="attributes.style.showBackground ? '' : 'none'"
>
  <h3 *ngIf="attributes.title" class="buttons-header">{{ attributes.title }}</h3>
  <div class="buttons-container">
    <ng-container *ngFor="let button of attributes.buttons">
      <ng-container *ngIf="button.link && (button.text || button.icon)">
        <ng-container>
          <div
            [ngClass]="{
              'desktop': !(isMobile$ | async) && !attributes.isEditMode,
              'button': attributes.style.displayType === 'style1',
              'button-v2': attributes.style.displayType === 'style2',
              'button-v3': attributes.style.displayType === 'style3'
            }"
            [style.backgroundColor]="attributes.style.button.backgroundColor"
            [style.borderRadius.px]="attributes.style.button.borderRadius"
            [style.borderWidth.px]="attributes.style.button.borderWidth"
            [style.borderColor]="attributes.style.button.borderColor"
            [style.borderStyle]="attributes.style.button.borderWidth ? 'solid' : ''"
            [style.boxShadow]="attributes.style.showBackground ? '' : 'none'"
            [style.fontSize.px]="attributes.style.button.fontSize"
            [style.height.px]="attributes.style.button.height"
            (click)="navigate(button)"
            [class.hoverable]="button.link"
            data-cy="widgetBtn"
          >
            <div class="button-content">
              <img
                *ngIf="button.icon"
                alt=""
                [src]="button.icon"
                height="25"
                [ngClass]="{
                  'button-img': !attributes.style.displayType,
                  'button-img-v2': attributes.style.displayType === 'style2',
                  'button-img-v3': attributes.style.displayType === 'style3'
                }"
              />
              <div
                *ngIf="button.text"
                [style.color]="attributes.style.button.textColor || attributes.mainColor"
                [style.fontSize.px]="attributes.style.button.fontSize || ''"
                [ngClass]="{
                  'button-text': !attributes.style.displayType,
                  'button-text-v2': attributes.style.displayType === 'style2',
                  'button-text-v3': attributes.style.displayType === 'style3'
                }"
              >
                <span class="ellipsis">{{ button.text }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes.isEditMode" class="widget-buttons">{{ noValidMessage }}</div>
</ng-template>
