export type PriceFlowKey = 'takeAway' | 'happyHours' | 'onSite';

export interface PriceFlow {
  id: PriceFlowKey;
  label: string;
}

export const priceFlows: PriceFlow[] = [
  { id: 'onSite', label: 'STUDIO.SETTINGS.BEHAVIOR.ON_SITE' },
  { id: 'takeAway', label: 'STUDIO.POS_INTEGRATIONS.TAKE_AWAY' },
  { id: 'happyHours', label: 'STUDIO.SHARED.HAPPY_HOURS' },
];
