<ng-container *ngIf="isRecommendationGroupAvailable(group)">
  <h4 class="title">{{ group.displayLabel }}</h4>
  <ng-container *ngFor="let recommendation of group.recommendations; trackBy: trackByFn">
    <product-catalog-recommendation-container
      *ngIf="recommendation.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
      [item]="recommendation"
      [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
      [parentItemAvailability]="parentItemAvailability"
      class="recommendation"
    ></product-catalog-recommendation-container>
  </ng-container>
</ng-container>
