import { Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetShare } from './share';

@Component({
  selector: 'pop-widget-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class WidgetShareComponent implements OnInit, WidgetComponent {
  static widgetName = 'share';
  @Input() attributes: WidgetShare;

  meta: string;
  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes) {
      this.noValidMessage = this._warningService.showWarn(WidgetShareComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this.meta = this.getQuery();
    this.isValid = true;
  }

  getQuery(): string {
    let query = '';
    if (this.attributes.subject && this.attributes.body) {
      query = `subject=${this.attributes.subject}&body=${this.attributes.body}`;
    } else if (this.attributes.subject) {
      query = `subject=${this.attributes.subject}`;
    } else if (this.attributes.body) {
      query = `body=${this.attributes.body}`;
    }

    return `mailto:?${query}`;
  }
}
