import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Category,sortByKey } from '../shared'; // TODO: review
import { actions } from './category.actions';
import { State } from './category.type';

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  sortComparer: sortByKey<Category>()('order'),
});

export const initialState: State = adapter.getInitialState({
  loading: {},
  loadingByMenu: {},
  selectedTabId: null,
  catalogId: null,
  loadedCategoryIds: {},
  loadedMenuCategoryIds: {},
  errorCode: null,
});

const categoryReducer = createReducer(
  initialState,
  on(actions.addCategories, (state, { entities }) => adapter.addMany(entities, state)),
  on(actions.getCategories, actions.getCategoriesSingle, (state, { tabId }) => ({
    ...state,
    loading: { ...state.loading, [tabId]: true },
  })),

  on(actions.getCategoriesSuccess, (state, { entities, tabId }) =>
    adapter.addMany(entities, {
      ...state,
      loading: { ...state.loading, [tabId]: false },
      loadedCategoryIds: { ...state.loadedCategoryIds, [tabId]: entities.map(({ id }) => id) },
    }),
  ),

  on(actions.getCategoriesSingleSuccess, (state, { entities, tabId }) =>
    adapter.addMany(entities, {
      ...state,
      loading: { ...state.loading, [tabId]: false },
      loadedCategoryIds: { ...state.loadedCategoryIds, [tabId]: entities.map(({ id }) => id) },
    }),
  ),

  on(actions.getCategoriesIsLoaded, (state, { tabId }) => ({
    ...state,
    loading: { ...state.loading, [tabId]: false },
  })),

  on(actions.getCategoriesSingleIsLoaded, (state, { tabId }) => ({
    ...state,
    loading: { ...state.loading, [tabId]: false },
  })),

  on(actions.getCategoriesError, (state, { errorCode, tabId }) => ({
    ...state,
    errorCode,
    loading: { ...state.loading, [tabId]: true },
  })),

  on(actions.getCategoriesSingleError, (state, { errorCode, tabId }) => ({
    ...state,
    errorCode,
    loading: { ...state.loading, [tabId]: true },
  })),

  on(actions.getMenuCategories, (state, { menuId }) => ({
    ...state,
    loadingByMenu: { ...state.loadingByMenu, [menuId]: true },
  })),

  on(actions.getMenuCategoriesSuccess, (state, { entities, menuId }) =>
    adapter.addMany(entities, {
      ...state,
      loadingByMenu: { ...state.loadingByMenu, [menuId]: false },
      loadedMenuCategoryIds: { ...state.loadedMenuCategoryIds, [menuId]: entities.map(({ id }) => id) },
    }),
  ),

  on(actions.getMenuCategoriesIsLoaded, (state, { menuId }) => ({
    ...state,
    loadingByMenu: { ...state.loadingByMenu, [menuId]: false },
  })),

  on(actions.getMenuCategoriesError, (state, { errorCode, menuId }) => ({
    ...state,
    errorCode,
    loadingByMenu: { ...state.loadingByMenu, [menuId]: false },
  })),

  on(actions.reloadCategories, state => ({
    ...state,
    loadedCategoryIds: {},
    loadedMenuCategoryIds: {},
    loadingByMenu: {},
    loading: {},
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return categoryReducer(state, action);
}
