export enum ErrorCode {
  APPLICATION_NOT_FOUND = 'STUDIO.SNACK.ERROR.APPLICATION_NOT_FOUND',
  MONGO_APP_NOT_FOUND_BY_ID = 'STUDIO.SNACK.ERROR.MONGO_APP_NOT_FOUND_BY_ID',
  ENTITY_NOT_FOUND = 'STUDIO.SNACK.ERROR.ENTITY_NOT_FOUND',
  PHONE_NUMBER_INVALID = 'STUDIO.SNACK.ERROR.PHONE_NUMBER_INVALID',
  PHONE_NUMBER_ALREADY_USED = 'STUDIO.SNACK.ERROR.PHONE_NUMBER_ALREADY_USED',
  TEMPLATE_APP_NOT_FOUND = 'STUDIO.SNACK.ERROR.TEMPLATE_APP_NOT_FOUND',
  APP_SLUG_ALREADY_USED = 'STUDIO.SNACK.ERROR.APP_SLUG_ALREADY_USED',
  APP_SLUG_ALREADY_TAKEN = 'STUDIO.SNACK.ERROR.APP_SLUG_ALREADY_USED',
  PAYMENT_PROVIDER_NOT_FOUND = 'STUDIO.SNACK.ERROR.PAYMENT_PROVIDER_NOT_FOUND',
  PAYMENT_PROVIDER_NOT_SUPPORTED = 'STUDIO.SNACK.ERROR.PAYMENT_PROVIDER_NOT_SUPPORTED',
  SKU_IS_ALREADY_IN_USE = 'STUDIO.SNACK.ERROR.SKU_IS_ALREADY_IN_USE',
  SLUG_IS_ALREADY_IN_USE = 'STUDIO.SNACK.ERROR.SLUG_IS_ALREADY_IN_USE',
  INCORRECT_OLD_PASSWORD = 'STUDIO.SNACK.ERROR.INCORRECT_OLD_PASSWORD',
  REMOVE_BUSINESS_LOCATION_FAILED = 'STUDIO.SNACK.ERROR.REMOVE_BUSINESS_LOCATION_FAILED',
  REMOVE_ITEM_IS_PART_OF_MENU = 'STUDIO.SNACK.ERROR.REMOVE_ITEM_IS_PART_OF_MENU',
}
