import { createAction, props } from '@ngrx/store';

import { createType, Option } from '../shared'; // TODO: review
import { storeKey } from './option.config';

const type = createType(storeKey);

export const actions = {
  addOptions: createAction(type('add'), props<{ entities: Option[] }>()),

  changeQuantity: createAction(type('change quantity'), props<{ by: number; quantity: number }>()),

  resetQuantity: createAction(type('reset quantity')),

  reset: createAction(type('reset')),
};
