<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.MAP.DISPLAY_CONFIG' | translate }}</span>
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups" formGroupName="style">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="heightInput" class="form-label">{{ 'WIDGETS.STYLE.HEIGHT' | translate }}</label>
            <input
              id="heightInput"
              type="number"
              formControlName="height"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
        </div>
        <div formGroupName="style" class="form-row">
          <input id="fullWidthCheckBox" class="form-input-checkbox" type="checkbox" formControlName="fullWidth" />
          <label for="fullWidthCheckBox" class="form-label form-label--checkbox">
            {{ 'WIDGETS.STYLE.FULL_WIDTH' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.MAP.PINS' | translate }}</span>
      <div formArrayName="pins">
        <div *ngFor="let pin of pinsControls; let i = index" class="form-row-groups-wrapper">
          <div [formGroupName]="i" class="form-row-groups">
            <div class="form-row-group form-row-group--distanced" formGroupName="coords">
              <label for="latInput" class="form-label">{{ 'WIDGETS.MAP.LATITUDE' | translate }}</label>
              <input id="latInput" type="number" formControlName="lat" min="0" class="form-input form-input--coords" />
            </div>
            <div class="form-row-group form-row-group--distanced" formGroupName="coords">
              <label for="longInput" class="form-label">{{ 'WIDGETS.MAP.LONGITUDE' | translate }}</label>
              <input id="longInput" type="number" formControlName="lng" min="0" class="form-input form-input--coords" />
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="titleInput" class="form-label">{{ 'WIDGETS.SHARED.TITLE' | translate }}</label>
              <input id="titleInput" type="text" formControlName="title" class="form-input form-input--text" />
            </div>
            <div class="form-row-group form-row-group--distanced">
              <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.ICON' | translate }}</span>
              <designer-image-gallery formControlName="icon"></designer-image-gallery>
            </div>
            <div class="form-row-group">
              <button
                *ngIf="pinsControls.length > 1 && i !== 0"
                class="btn-flat btn-flat--remove"
                type="button"
                (click)="removePins(i)"
              >
                <span class="hyphen">-</span>
                {{ 'WIDGETS.MAP.REMOVE_PIN' | translate }}
              </button>
            </div>
          </div>
        </div>
        <button class="btn-flat btn-flat--add-new-item" type="button" (click)="addPins()">
          {{ 'WIDGETS.MAP.ADD_PIN' | translate }}
        </button>
      </div>
    </div>

    <div class="form-row-groups-wrapper">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.MAP.ROUTE' | translate }}</span>
      <div class="error-message">Route File temporarily not working</div>
      <!--<div class="form-row-groups form-row-groups&#45;&#45;distanced">
            <div class="form-row-group form-row-group&#45;&#45;distanced">
              <pop-widget-edit-file-upload
                [title]="'WIDGETS.MAP.ROUTE_FILE' | translate"
                [fileUrl]="routeFileControl"
                (fileChangeEmitter)="onUploadRoute($event)"
              ></pop-widget-edit-file-upload>
              <span class="form-success-msg" *ngIf="routeSuccess">{{ 'WIDGETS.MAP.ROUTE_UPLOADED' | translate }}</span>
              <span class="form-success-msg" *ngIf="this.widget.attributes.route.coords.length > 0">
                {{ 'WIDGETS.MAP.ROUTE_ALREADY_UPLOADED' | translate }}
              </span>
              <span class="form-input-number-units">
                {{ 'WIDGETS.MAP.UPLOAD_CSV' | translate }}
                <br />
                lat,lng
                <br />
                45.7215,5.0769
                <br />
                45.7216,5.0769
              </span>
            </div>
            <div class="form-row-group form-row-group&#45;&#45;distanced" formGroupName="route">
              <label for="textColorInput" class="form-label">{{ 'WIDGETS.STYLE.COLOR' | translate }}</label>
              <span [(colorPicker)]="colorValue" [style.backgroundColor]="colorValue" class="form-colorpicker"></span>
            </div>
          </div>-->
    </div>

    <div class="form-row">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.MAP.DISPLAY' | translate }}</span>
      <div formGroupName="zoom" class="form-row-groups-wrapper">
        <div class="form-row">
          <input
            id="zoomViewCheckBox"
            *ngIf="firstPinLong && firstPinLat"
            class="form-input-checkbox"
            type="checkbox"
            formControlName="fitBounds"
          />
          <label for="zoomViewCheckBox" class="form-label form-label--checkbox">
            {{ 'WIDGETS.MAP.ZOOM_TO_VIEW' | translate }}
          </label>
          <span *ngIf="!firstPinLong || !firstPinLat" class="form-error-msg">
            {{ 'WIDGETS.MAP.PIN_ENTER' | translate }}
          </span>
        </div>
        <div class="form-row-groups" formGroupName="focus">
          <div class="form-row-group form-row-group--distanced" formGroupName="coords">
            <label for="latInput" class="form-label">{{ 'WIDGETS.MAP.LATITUDE' | translate }}</label>
            <input id="latInput" type="number" formControlName="lat" min="0" class="form-input form-input--coords" />
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="coords">
            <label for="longInput" class="form-label">{{ 'WIDGETS.MAP.LONGITUDE' | translate }}</label>
            <input id="longInput" type="number" formControlName="lng" min="0" class="form-input form-input--coords" />
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="zoomLevelInput" class="form-label">{{ 'WIDGETS.MAP.ZOOM_LEVEL' | translate }}</label>
            <input
              id="zoomLevelInput"
              type="number"
              formControlName="zoom"
              min="0"
              class="form-input form-input--number"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
