<lib-icon class="flag" [style.fill]="mainColor" icon="email"></lib-icon>
<input
  class="input"
  type="text"
  [formControl]="emailControl"
  [class.input--invalid]="emailControl.invalid && emailControl.touched"
  name="email"
/>
<button *ngIf="isCompleted" mat-icon-button class="button" (click)="editInput()">
  <lib-icon class="button__icon" icon="pencil"></lib-icon>
</button>
