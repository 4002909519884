import { createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { OrderUser,sortByKey } from '../shared';
import { actions } from './order-users.actions';
import { State } from './order-users.type';

export const adapter = createEntityAdapter<OrderUser>({
  sortComparer: sortByKey<OrderUser>()('id'),
});

export const initialState: State = adapter.getInitialState({});

const orderItemsReducer = createReducer(
  initialState,
  on(actions.addOrderUsers, (state, { orderUsers }) => adapter.addMany(orderUsers, state)),
  on(actions.updateOrderUsers, (state, { orderUsers }) => adapter.upsertMany(orderUsers, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return orderItemsReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
