import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA,MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss', './snack.component.scss-theme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string, private _snackRef: MatSnackBarRef<SnackComponent>) {}

  close(): void {
    this._snackRef.dismiss();
  }
}
