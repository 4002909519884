type LastKey<T> = SubType<T, string | number>;

interface Overload<T> {
  <K1 extends keyof LastKey<T>>(k1: K1): (a: T, b: T) => number;
  <K1 extends keyof SubType<T, Dictionary<any>>, K2 extends keyof LastKey<T[K1]>>(k1: K1, k2: K2): (
    a: T,
    b: T,
  ) => number;
  <
    K1 extends keyof SubType<T, Dictionary<any>>,
    K2 extends keyof SubType<T[K1], Dictionary<any>>,
    K3 extends keyof LastKey<T[K1][K2]>,
  >(
    k1: K1,
    k2: K2,
    k3: K3,
  ): (a: T, b: T) => number;
  <
    K1 extends keyof SubType<T, Dictionary<any>>,
    K2 extends keyof SubType<T[K1], Dictionary<any>>,
    K3 extends keyof SubType<T[K1][K2], Dictionary<any>>,
    K4 extends keyof LastKey<T[K1][K2][K3]>,
  >(
    k1: K1,
    k2: K2,
    k3: K3,
    k4: K4,
  ): (a: T, b: T) => number;
}

export function sortByKey<T>() {
  const typeFun: Overload<T> = (...keys: any[]) => {
    return (a: T, b: T): number => {
      const getValue = (obj: T): string | number => keys.reduce((acc, key) => acc[key], obj);

      const valueA = String(getValue(a));
      const valueB = String(getValue(b));

      return valueA.localeCompare(valueB, 'en-US', { numeric: true });
    };
  };

  return typeFun;
}
