<div class="recommendation">
  <img *ngIf="item.imageUrl" [src]="item.imageUrl" [alt]="item.name" class="recommendation__img" />
  <div class="recommendation__info">
    <span class="text">{{ item.name }}</span>
    <span class="text">{{ item.priceFinalValue | currency }}</span>
    <app-product-status-checkout
      [product]="item"
      [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    ></app-product-status-checkout>
  </div>
</div>

<ng-content></ng-content>
