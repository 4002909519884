import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { isTemporarilyUnavailable } from '../helpers';
import { PopProduct } from '../product-catalog';

@Component({
  selector: 'app-product-quantity-buttons',
  templateUrl: './product-quantity-buttons.component.html',
  styleUrls: ['./product-quantity-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductQuantityButtonsComponent implements OnChanges {
  @Input() product: PopProduct;
  show: boolean;

  ngOnChanges(): void {
    this.show = this._show;
  }

  private get _show(): boolean {
    return this._available && !this._isTemporarilyUnavailable;
  }

  private get _available(): boolean {
    return this.product.available;
  }

  private get _isTemporarilyUnavailable(): boolean {
    return isTemporarilyUnavailable(this.product);
  }
}
