import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RecommendationGroup, sortByKey } from '../shared';
import { actions } from './recommendation-group.actions';
import { State } from './recommendation-group.type';

export const adapter: EntityAdapter<RecommendationGroup> = createEntityAdapter<RecommendationGroup>({
  selectId: ({ productId, id }) => `${productId}-${id}`,
  sortComparer: sortByKey<RecommendationGroup>()('order'),
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoadedBy: [],
  errorCode: null,
  selectedProductId: null,

  recommendationsByPlace: [],
  isLoadingByPlace: [],
  recommendationItemsIds: [],
});

const optionGroupReducer = createReducer(
  initialState,
  on(actions.getRecommendationGroups, (state, { by }) => ({
    ...state,
    isLoading: true,
    selectedProductId: by,
  })),

  on(actions.getRecommendationGroupsIsLoaded, state => ({
    ...state,
    isLoading: false,
  })),

  on(actions.getRecommendationGroupsSuccess, (state, { entities, by }) =>
    adapter.addMany(entities, {
      ...state,
      errorCode: null,
      isLoading: false,
      isLoadedBy: [...state.isLoadedBy, by],
    }),
  ),

  on(actions.getRecommendationGroupsError, actions.getPlaceRecommendationsError, (state, { errorCode }) => ({
    ...state,
    errorCode,
    isLoading: false,
  })),

  on(actions.reset, state =>
    adapter.removeAll({
      ...state,
      errorCode: null,
      isLoading: false,
      isLoadedBy: [],
    }),
  ),

  on(actions.getPlaceRecommendations, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.getPlaceRecommendationsSuccess, (state, { recommendations }) => ({
    ...state,
    isLoading: false,
    recommendationsByPlace: [...recommendations],
  })),

  on(actions.addPlaceRecommendationToCart, (state, { itemId }) => ({
    ...state,
    isLoadingByPlace: [...state.isLoadingByPlace, itemId],
  })),

  on(actions.addPlaceRecommendationToCartSuccess, (state, { itemId, groupId }) => ({
    ...state,
    isLoadingByPlace: state.isLoadingByPlace.filter(id => id !== itemId),
    recommendationsByPlace: state.recommendationsByPlace
      .map(group =>
        group.id !== groupId
          ? group
          : {
              ...group,
              recommendations: group.recommendations.filter(({ id }) => id !== itemId),
            },
      )
      /* remove groups without recommendations */
      .filter(group => group.recommendations.length !== 0),
  })),

  on(actions.addPlaceRecommendationToCartError, (state, { itemId, errorCode }) => ({
    ...state,
    errorCode,
    isLoadingByPlace: state.isLoadingByPlace.filter(id => id !== itemId),
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return optionGroupReducer(state, action);
}
