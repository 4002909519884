import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './pos-settings.config';
import { adapter } from './pos-settings.reducer';
import { State } from './pos-settings.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const total = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const isLoadedByAppSlug = createSelector(stateSelector, state => state.isLoadedByAppSlug);
export const isLoadedBy = createSelector(stateSelector, state => state.isLoadedBy);
export const selectedId = createSelector(stateSelector, state => state.selectedId);
export const errorCode = createSelector(stateSelector, state => state.errorCode);
export const isLoadingByAppSlug = createSelector(stateSelector, state => state.isLoadingByAppSlug);

export const isLoaded = createSelector(
  stateSelector,
  isLoadingByAppSlug,
  (state, appSlug) => !!appSlug && state.isLoadedByAppSlug === appSlug,
);

export const currentPosSettings = createSelector(entities, selectedId, (allEntities, id) => allEntities[id]);

export const lastAdded = createSelector(ids, ([allIds]) => allIds);
