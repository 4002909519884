/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { OrderService } from 'projects/store/src/lib/order/order.service';
import { combineLatest, EMPTY, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderUsersService, SettingsService } from '@bend/store';
import { ItemMeta, OrderItem, OrderItemStatus, OrderLocation } from '@bend/store-shared';
import { TranslateWrapperService } from '@bend/translate';

import { ORDER_DIALOG_ERROR_LABELS } from '../../../../config';
import { AppType, DialogKey, KitchenType, LocationCount } from '../../../../types';
import { CurrencyPipe } from '../../../shared-components/pipes';
import { CatalogReloadService } from '../catalog-reload/catalog-reload.service';
import { OrderDialogDisplayedService } from '../order-dialog-displayed/order-dialog-displayed.service';
import { TotalService } from '../total/total.service';

@Injectable()
export class OrderDialogErrorService {
  constructor(
    private _dialog: DialogService,
    private _translateWrapper: TranslateWrapperService,
    private _orderDialogDisplayed: OrderDialogDisplayedService,
    private _settings: SettingsService,
    private _total: TotalService,
    private _reloadCatalog: CatalogReloadService,
    private _orders: OrderUsersService,
    private _orderId: OrderService,
  ) {}

  behavior(): MonoTypeOperatorFunction<
    [(OrderLocation & { items: OrderItem<ItemMeta>[] })[], AppType, KitchenType, string]
  > {
    const allowStatuses = new Set([
      OrderItemStatus.FailedPreCheck,
      OrderItemStatus.Reimbursed,
      OrderItemStatus.OrderNotSent,
      OrderItemStatus.NoStock,
      OrderItemStatus.UnknownProduct,
      OrderItemStatus.TemporarilyUnavailable,
    ]);

    const notAllowedStatutes = new Set([OrderItemStatus.OrderedInProgress]);

    const allowedAllStatutes = new Set([
      OrderItemStatus.NoStock,
      OrderItemStatus.UnknownProduct,
      OrderItemStatus.TemporarilyUnavailable,
    ]);

    return tap(([locations, appType, kitchenType, orderNumber]) => {
      /**
       * @description show pop-up for no OrderedInProgress items
       * need this for prevent to show 2 pop-up simultaneous
       */
      if (locations.some(({ items }) => items.some(({ status }) => notAllowedStatutes.has(status)))) return;

      // tslint error
      const locationsError = locations
        .map(({ items, ...rest }) => ({
          ...rest,
          // get all error items
          items: items.filter(
            ({ status, errorCode, itemMeta: { options = [], items: menuItems = [] } }) =>
              (allowStatuses.has(status) && !!errorCode) ||
              options.some(
                ({ status: optionStatus, errorCode: optionErrorCode }) =>
                  allowStatuses.has(optionStatus) && !!optionErrorCode,
              ) ||
              menuItems.some(
                ({ status: menuItemStatus, errorCode: menuItemErrorCode }) =>
                  allowStatuses.has(menuItemStatus) && !!menuItemErrorCode,
              ) ||
              menuItems.some(({ options: menuItemOptions = [] }) =>
                menuItemOptions.some(
                  ({ status: menuItemOptionStatus, errorCode: menuItemOptionErrorCode }) =>
                    allowStatuses.has(menuItemOptionStatus) && !!menuItemOptionErrorCode,
                ),
              ),
          ),
        }))
        .filter(({ items }) => items.length);

      // show only in status allowed
      if (!locationsError.length) return;

      if (this._checkUpdatedAtItem(locationsError)) return;

      /**
       * @description reload catalogs if have no stock items
       * reload catalogs will remove no stock items from page
       */
      if (
        locations.some(({ items }) =>
          items.some(
            ({ status, itemMeta: { options = [], items: menuItems = [] } }) =>
              allowedAllStatutes.has(status) ||
              options.some(({ status: optionStatus }) => allowStatuses.has(optionStatus)) ||
              menuItems.some(({ status: menuItemStatus }) => allowStatuses.has(menuItemStatus)) ||
              menuItems.some(({ options: menuItemOptions }) =>
                menuItemOptions.some(({ status: menuItemOptionStatus }) => allowStatuses.has(menuItemOptionStatus)),
              ),
          ),
        )
      ) {
        this._reloadCatalog.reloadCatalog();
      }

      const locationCount = locationsError.length < 2 ? LocationCount.One : LocationCount.More;

      this._dialog.error({
        message: ORDER_DIALOG_ERROR_LABELS[appType][kitchenType][locationCount].status,
        interpolateData: this._itemsStatuses(locationsError, orderNumber, appType, kitchenType),
      });
    });
  }

  private _itemsStatuses(
    locationsError: (OrderLocation & { items: OrderItem<ItemMeta>[] })[],
    orderNumber: string,
    appType: AppType,
    kitchenType: KitchenType,
  ): Observable<{ errors: string } | never> {
    // get all error items from all locations
    const itemsError = locationsError.flatMap(({ items }) => items);
    if (appType === AppType.PayAfter) {
      return this._total.currentRemainToPayWithTips.pipe(
        map(amount => this._convertToCurrency(amount)),
        switchMap(amount => this._payAfter(itemsError).pipe(map(errors => ({ orderNumber, errors, amount })))),
      );
    }

    if (appType === AppType.PayBefore && kitchenType === KitchenType.Mono)
      return this._total.currentRemainToPayWithTips.pipe(
        map(amount => this._convertToCurrency(amount)),
        switchMap(amount =>
          this._payBeforeMono(itemsError, orderNumber, amount).pipe(map(errors => ({ orderNumber, errors, amount }))),
        ),
      );

    if (appType === AppType.PayBefore && kitchenType === KitchenType.Multi)
      return this._orderId.currentOrderUserId.pipe(
        switchMap(orderUserId =>
          this._orders.entities.pipe(
            map(orderUsers => {
              const orderUser = orderUsers[orderUserId];
              const lastTransaction = orderUser.transactions
                .filter(transaction => transaction.amount > 0)
                .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                .shift();
              return lastTransaction ? lastTransaction.amount : null;
            }),
            switchMap(amount => {
              if (amount) {
                return of(this._convertToCurrency(amount));
              } else {
                return this._translateWrapper.stream(
                  ORDER_DIALOG_ERROR_LABELS[AppType.PayBefore][KitchenType.Multi].amount,
                  {},
                );
              }
            }),
            switchMap(amount => {
              return this._payBeforeMulti(locationsError, orderNumber, amount).pipe(
                map(errors => ({ orderNumber, errors, amount })),
              );
            }),
          ),
        ),
      );

    return EMPTY;
  }

  private _payAfter(items: OrderItem<ItemMeta>[]): Observable<string> {
    /**
     * @description get error code from item and option for show error message by code
     */
    const [
      {
        errorCode,
        itemMeta: { options = [], items: menuItems = [{} as any] },
      },
    ] = items;
    const [{ options: menuItemOptions = [] }] = menuItems;
    const { errorCode: optionErrorCode = null } = options.find(option => option.errorCode) || {};
    const { errorCode: menuItemErrorCode = null } = menuItems.find(menuItem => menuItem.errorCode) || {};
    const { errorCode: menuItemOptionErrorCode = null } =
      menuItemOptions.find(menuItemOption => menuItemOption.errorCode) || {};
    /**
     * @description get name of error items and options and convert to string
     */
    const productName = this._getErrorItemsName(items).join('\n');

    const label = this._translateWrapper.stream(
      ORDER_DIALOG_ERROR_LABELS[AppType.PayAfter][KitchenType.Mono][LocationCount.One][
        errorCode || optionErrorCode || menuItemErrorCode || menuItemOptionErrorCode
      ] as string,
      { productName },
    );

    return label;
  }

  private _payBeforeMono(items: OrderItem<ItemMeta>[], orderNumber: string, amount: string): Observable<string> {
    /**
     * @description get error code from item and option for show error message by code
     */
    const [
      {
        errorCode,
        itemMeta: { options = [], items: menuItems = [{} as any] },
      },
    ] = items;
    const [{ options: menuItemOptions = [] }] = menuItems;
    const { errorCode: optionErrorCode = null } = options.find(option => option.errorCode) || {};
    const { errorCode: menuItemErrorCode = null } = menuItems.find(menuItem => menuItem.errorCode) || {};
    const { errorCode: menuItemOptionErrorCode = null } =
      menuItemOptions.find(menuItemOption => menuItemOption.errorCode) || {};
    /**
     * @description get name of error items and options and convert to string
     */
    const productName = this._getErrorItemsName(items).join('\n');

    const label = this._translateWrapper.stream(
      ORDER_DIALOG_ERROR_LABELS[AppType.PayBefore][KitchenType.Mono][LocationCount.One][
        errorCode || optionErrorCode || menuItemErrorCode || menuItemOptionErrorCode
      ] as string,
      { productName, orderNumber, amount },
    );

    return label;
  }

  private _payBeforeMulti(
    locationsError: (OrderLocation & { items: OrderItem<ItemMeta>[] })[],
    orderNumber: string,
    amount: string,
  ): Observable<string> {
    const locationCount = locationsError.length < 2 ? LocationCount.One : LocationCount.More;

    const errors: Observable<string>[] = [];

    locationsError.forEach(({ name: locationName, items }) => {
      /**
       * @description get error code from item and option for show error message by code
       */
      const [
        {
          errorCode,
          itemMeta: { options = [], items: menuItems = [{} as any] },
        },
      ] = items;
      const [{ options: menuItemOptions = [] }] = menuItems;
      const { errorCode: optionErrorCode = null } = options.find(option => option.errorCode) || {};
      const { errorCode: menuItemErrorCode = null } = menuItems.find(menuItem => menuItem.errorCode) || {};
      const { errorCode: menuItemOptionErrorCode = null } =
        menuItemOptions.find(menuItemOption => menuItemOption.errorCode) || {};
      /**
       * @description get name of error items and options and convert to string
       */
      const productName = this._getErrorItemsName(items).join('\n');

      const locationLabel = this._translateWrapper.stream(
        ORDER_DIALOG_ERROR_LABELS[AppType.PayBefore][KitchenType.Multi][locationCount][
          errorCode || optionErrorCode || menuItemErrorCode || menuItemOptionErrorCode
        ] as string,
        { locationName, productName, orderNumber, amount },
      );

      errors.push(locationLabel);
    });

    // combine all errors in a string separate by new line
    const label = combineLatest(errors).pipe(map(labels => labels.join('\n')));

    return label;
  }

  /**
   * @description check in dialog is showed
   */
  private _checkUpdatedAtItem(locationsError: (OrderLocation & { items: OrderItem<ItemMeta>[] })[]): boolean {
    let updatedAt = new Date(null);

    locationsError.forEach(({ items }) =>
      items.forEach(item => {
        if (item.updatedAt.getTime() > updatedAt.getTime()) updatedAt = item.updatedAt;
      }),
    );

    return this._orderDialogDisplayed.check(updatedAt, 0, DialogKey.Item);
  }

  private _getErrorItemsName(items: OrderItem<ItemMeta>[]): string[] {
    return [
      ...items.reduce(
        (accumulator, current) => (current.errorCode ? [...accumulator, current.itemMeta.name] : accumulator),
        [],
      ),
      ...items.flatMap(({ itemMeta: { options = [], items: menuItems = [] } }) => [
        ...options.reduce(
          (accumulator, current) => (current.errorCode ? [...accumulator, current.name] : accumulator),
          [],
        ),
        ...menuItems.reduce(
          (accumulator, current) => (current.errorCode ? [...accumulator, current.name] : accumulator),
          [],
        ),
        ...menuItems.flatMap(({ options: menuItemOptions = [] }) => [
          ...menuItemOptions.reduce(
            (accumulator, current) => (current.errorCode ? [...accumulator, current.name] : accumulator),
            [],
          ),
        ]),
      ]),
    ];
  }

  private _convertToCurrency(currency: number): string {
    /**
     * @description create currency pipe
     */
    const pipe = new CurrencyPipe(this._settings);
    /**
     * @description convert amount number in human readable
     */
    return pipe.transform(currency);
  }
}
