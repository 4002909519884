export interface UrlAll {
  url: UrlPaths;
  queryParams: UrlQueryParams;
}

export type UrlPaths = string[];

export interface UrlQueryParams {
  [key: string]: string;
}

export enum QueryParams {
  AddItem = 'addItem',
}
