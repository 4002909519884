import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService, OptionGroup } from '../shared';

@Injectable()
export class OptionGroupHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  getOptionGroups(itemId: number): Observable<OptionGroup[]> {
    return this._api
      .optionGroups('v1')
      .pipe(switchMap(api => this._http.get<OptionGroup[]>(api, { params: { itemId } })));
  }
}
