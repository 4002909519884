import { createAction, props } from '@ngrx/store';

import { createType } from '../shared';
import { Application, ImageEvent, SortableImage } from '../shared/types/image-gallery.type';
import { storeKey } from './image-gallery.config';
import { FileType } from './image-gallery.type';

const type = createType(storeKey);
const errorProps = props<{ errorCode: string }>();

export const actions = {
  getApplications: createAction(type('get - applications'), props<{ currentApp: string; fileTypes: FileType[] }>()),
  getApplicationsLoaded: createAction(type('get - applications is loaded')),
  getApplicationsSuccess: createAction(type('get - applications success'), props<{ apps: Application[] }>()),
  getApplicationsError: createAction(type('get - applications error'), errorProps),

  getImagesByApp: createAction(type('get - images'), props<{ fileTypes: FileType[] }>()),
  getImagesByAppLoaded: createAction(type('get - images loaded')),
  getImagesByAppSuccess: createAction(type('get - images success'), props<{ images: SortableImage[] }>()),
  getImagesByAppError: createAction(type('get - images error'), errorProps),

  uploadCroppedImage: createAction(type('post - cropped image'), props<{ base64: string; fileName: string }>()),
  uploadCroppedImageSuccess: createAction(type('post - cropped image success'), props<{ image: SortableImage }>()),
  uploadCroppedImageError: createAction(type('post - cropped image error'), errorProps),

  uploadImages: createAction(type('post - image'), props<{ files: File[] }>()),
  uploadImageSuccess: createAction(type('post - image success'), props<{ imageEvent: ImageEvent }>()),
  uploadImageError: createAction(type('post - image error'), errorProps),

  uploadImageByUrl: createAction(type('post - image by url'), props<{ url: string }>()),
  uploadImageByUrlSuccess: createAction(type('post - image by url success'), props<{ image: SortableImage }>()),
  uploadImageByUrlError: createAction(type('post - image by url error'), errorProps),

  deleteImage: createAction(type('delete - image'), props<{ id: number }>()),
  deleteImageSuccess: createAction(type('delete - image success'), props<{ id: number }>()),
  deleteImageError: createAction(type('delete - image error'), errorProps),

  changeApp: createAction(type('change application'), props<{ app: string }>()),
};
