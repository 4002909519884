export const enum AppType {
  PayBefore = 'pay_before',
  PayAfter = 'pay_after',
}

export const enum AppBehavior {
  PayBeforePickUp = 'pay_before_pick_up',
  PayBeforeDelivery = 'pay_before_delivery',
  PayBeforeTakeAway = 'pay_before_take_away',
}

export type AppTypeBehavior = AppType | AppBehavior;

// # pay after
// new
// orderedInProgress
// orderNotSend
// ordered
// paymentInProgress
// reimbursed
// paid

// # pay before pickup
// orderedInProgress
// reimbursed
// scheduledForPreparing
// preparing
// pickUpReady

// # cash payment
// new
// orderedInProgress
// orderNotSend
// waitingForPayment
// preparing
// pickUpReady

// # pay before delivery
// scheduledForPreparing
// reimbursed
// preparing
// orderedInProgress
// inDelivery
// pickUpReady
