import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { message: string }) {
    this.message = data?.message || 'STUDIO.SHARED.DEFAULT_CONFIRMATION_MESSAGE';
  }
}
