<ng-container *ngIf="(isLoading$ | async) === false; else preloader">
  <h2 [style.text-align]="'center'">{{ 'STUDIO.IMAGE_GALLERY.ENTER_URL' | translate }}</h2>

  <mat-form-field appearance="outline">
    <mat-label>Image URL</mat-label>
    <input matInput [formControl]="url" placeholder="https://..." />
    <mat-error designerInputErrors></mat-error>
  </mat-form-field>

  <designer-image [imageUrl]="imageUrl" [date]="date | date" [name]="name"></designer-image>

  <div class="controls">
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="url.invalid">
      {{ 'STUDIO.SHARED.SAVE' | translate }}
    </button>

    <button mat-raised-button (click)="closeModal()" color="warn">
      {{ 'STUDIO.SHARED.CANCEL' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #preloader>
  <designer-spinner [diameter]="50"></designer-spinner>
</ng-template>
