<div class="top-bar">
  <div class="back-button" (click)="onClick()">
    <div class="svg-wrapper">
      <svg
        width="20px"
        height="30px"
        viewBox="0 0 25 20"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>lnr-chevron-left</title>
        <g id="Page-1" stroke="black" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="cart" transform="translate(-15.000000, -15.000000)" fill="#000000">
            <g id="lnr-chevron-left" transform="translate(15.000000, 15.000000)">
              <path
                d="M9,17.1 C9.1152,17.1 9.2304,17.0559 9.3186,16.9686 C9.4941,16.7931 9.4941,16.5078 9.3186,16.3323 L1.5372,8.5509 L9.3186,0.7695 C9.4941,0.594 9.4941,0.3087 9.3186,0.1332 C9.1431,-0.0423 8.8578,-0.0423 8.6823,0.1332 L0.5823,8.2332 C0.4068,8.4087 0.4068,8.694 0.5823,8.8695 L8.6823,16.9695 C8.7705,17.0577 8.8857,17.1009 9.0009,17.1009 L9,17.1 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
  <div class="title">{{ title }}</div>
</div>
