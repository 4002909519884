import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scrolledTo]',
  exportAs: 'scrolledTo',
})
export class ScrolledToDirective {
  reached = false;
  passed = false;

  constructor(public el: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    const elementPosition = this.el.nativeElement.offsetTop;
    const elementHeight = this.el.nativeElement.clientHeight;
    const scrollPosition = window.scrollY;

    this.reached = scrollPosition >= elementPosition;

    this.passed = scrollPosition >= elementPosition + elementHeight;
  }
}
