import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './resolver.config';
import { reducer } from './resolver.reducer';
import { ResolverService } from './resolver.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer)],
  providers: [ResolverService],
})
export class ResolverStoreModule {}
