import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AnimationsService {
  enabled: Observable<boolean>;
  disabled: Observable<boolean>;

  private _enabled: BehaviorSubject<boolean>;

  constructor() {
    this._enabled = new BehaviorSubject<boolean>(true);
    this.enabled = this._enabled.asObservable();
    this.disabled = this._enabled.pipe(map(isEnabled => !isEnabled));
  }

  enable(): void {
    this._enabled.next(true);
  }

  disable(): void {
    this._enabled.next(false);
  }
}
