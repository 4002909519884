import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, retry, share, switchMap, tap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import {
  IMPORT_FULL_CATALOG,
  ImportPosProvider,
  PosCatalog,
  PosCatalogSync,
  PosCatalogSyncStatus,
  PosCatalogWithCategoriesAndMenus,
  PosMenuToSelect,
  PosProduct,
} from '@designer-shared/types';

@Injectable()
export class PosCatalogsService {
  public lastPosCatalogSync$ = new Subject<PosCatalogSync>();
  public posCatalogs$ = new BehaviorSubject<PosCatalog[]>([]);
  public importPosProviders$ = new BehaviorSubject<ImportPosProvider[]>([]);
  public importPosProvidersMap$ = new BehaviorSubject<Map<ImportPosProvider['id'], ImportPosProvider>>(new Map());

  public activityTimer$ = timer(0, 60e3).pipe(
    tap(this._resetCachedPosCatalogs.bind(this)),
    tap(this._resetCachedImportPosProviders.bind(this)),
    share(),
  );

  constructor(
    private _http: HttpClient,
    private _api: ApiDesignerService,
  ) {}

  syncPosCatalog({
    changeCatalogStructure,
    posProviderId,
    changeAvailability,
    changePrices,
    changeNames,
    pricing,
    menuId,
  }: {
    posProviderId: number;
    menuId?: string;
    pricing?: string;
    changePrices?: boolean;
    changeNames?: boolean;
    changeAvailability?: boolean;
    changeCatalogStructure?: boolean;
  }): Observable<PosCatalogSync> {
    return this._api.posCatalogSyncs('v1').pipe(
      switchMap(api =>
        this._http.post<string>(api, {
          posProviderId,
          changePrices,
          changeNames,
          changeAvailability,
          changeCatalogStructure,
          ...(menuId && menuId !== IMPORT_FULL_CATALOG && { menuId }),
          ...(pricing && { pricing }),
        }),
      ),
      switchMap(catalogSyncId =>
        this.getPosCatalogSyncById(catalogSyncId).pipe(
          map(catalogSync => {
            if (catalogSync.status === PosCatalogSyncStatus.FAILED) {
              throw new PosCatalogSyncFailedError();
            }

            if (catalogSync.status !== PosCatalogSyncStatus.SUCCEEDED) {
              throw new PosCatalogSyncStillPendingError();
            }

            return catalogSync;
          }),
          retry({
            delay: (error: Error, count: number) => {
              // NOTE(roman): bigger catalogs, like those from cashpad, can take a while
              if (count < 12 && error instanceof PosCatalogSyncStillPendingError) {
                return timer(1e4);
              }

              throw error;
            },
          }),
        ),
      ),
      tap(catalogSync => this.lastPosCatalogSync$.next(catalogSync)),
      tap(this._resetCachedPosCatalogs.bind(this)),
    );
  }

  getPosCatalogSyncById(id: string): Observable<PosCatalogSync> {
    return this._api.posCatalogSyncs('v1', id).pipe(switchMap(api => this._http.get<PosCatalogSync>(api)));
  }

  getIkentooCatalogs(posProviderId: number): Observable<PosMenuToSelect[]> {
    return this._api
      .ikentooCatalogs('v1')
      .pipe(switchMap(api => this._http.get<PosMenuToSelect[]>(api, { params: { posProviderId } })));
  }

  getZeltyCatalogs(posProviderId: number): Observable<PosMenuToSelect[]> {
    return this._api
      .zeltyCatalogs('v1')
      .pipe(switchMap(api => this._http.get<PosMenuToSelect[]>(api, { params: { posProviderId } })));
  }

  getCashpadCatalogs(posProviderId: number): Observable<PosMenuToSelect[]> {
    return this._api
      .cashpadCatalogs('v1')
      .pipe(switchMap(api => this._http.get<PosMenuToSelect[]>(api, { params: { posProviderId } })));
  }

  deletePosCatalog(catalogId: string): Observable<void> {
    return this._api.posCatalogs('v1', catalogId).pipe(
      switchMap(api => this._http.delete<void>(api)),
      tap(this._resetCachedPosCatalogs.bind(this)),
    );
  }

  getPosCategories(catalogId: string): Observable<PosCatalogWithCategoriesAndMenus> {
    return this._api
      .posCatalogs('v1', catalogId)
      .pipe(switchMap(api => this._http.get<PosCatalogWithCategoriesAndMenus>(api)));
  }

  getPosProduct(productId: string): Observable<PosProduct> {
    return this._api.posItemsWithOptionGroups('v1', productId).pipe(switchMap(api => this._http.get<PosProduct>(api)));
  }

  private _resetCachedPosCatalogs(): void {
    this._api
      .posCatalogs('v1')
      .pipe(
        switchMap(api => this._http.get<PosCatalog[]>(api)),
        tap(this.posCatalogs$.next.bind(this.posCatalogs$)),
      )
      .subscribe();
  }

  private _resetCachedImportPosProviders(): void {
    this._api
      .posSettings('v1')
      .pipe(
        switchMap(api => this._http.get<ImportPosProvider[]>(api, { params: { catalogPullSyncOnly: true } })),
        map(posProviders => posProviders.sort((l, r) => l.name.localeCompare(r.name, 'en', { numeric: true }))),
        tap(posProviders => {
          this.importPosProviders$.next(posProviders);
          this.importPosProvidersMap$.next(new Map(posProviders.map(it => [it.id, it])));
        }),
      )
      .subscribe();
  }
}

class PosCatalogSyncStillPendingError extends Error {
  constructor() {
    super('POS_CATALOG_SYNC_STILL_PENDING');
  }
}

class PosCatalogSyncFailedError extends Error {
  constructor() {
    super('POS_CATALOG_SYNC_FAILED');
  }
}
