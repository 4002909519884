import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pop-feedback-rating',
  templateUrl: './feedback-rating.component.html',
  styleUrls: ['./feedback-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidgetFeedbackRatingComponent),
      multi: true,
    },
  ],
})
export class WidgetFeedbackRatingComponent implements ControlValueAccessor, OnInit {
  @Input() maxRating = 5;
  @Input() mainColor: string;
  numbers: number[];
  // TODO(Serghei) add proprety for fix strict templates
  public data: any;

  ngOnInit(): void {
    this.numbers = Array(this.maxRating)
      .fill(0)
      .map((_, i) => i + 1);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  writeValue(value: any): void {
    this.data = value || 0;
  }

  onChange(event: any): void {
    this.data = event.target.value;
    this.propagateChange(this.data);
  }

  private propagateChange = (_: any) => {};
}
