import { Params } from '@angular/router';

import { CatalogParams, CatalogQueryParams, GuardQueryParams, LoyaltyParams, TagParams } from '../shared';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export type Param =
  | 'appSlug'
  | 'locationId'
  | 'boothId'
  | 'pageId'
  | 'mapId'
  | 'roomName'
  | 'date'
  | 'areaId'
  | 'userId'
  | 'smileId'
  | 'smilePage'
  | 'insightId'
  | 'paymentConfigId'
  | 'promoCodesPage'
  | 'promoCodeId'
  | 'catalogId'
  | 'posId'
  | 'recommendationId'
  | 'optionId'
  | 'posId'
  | 'recommendationId'
  | CatalogParams.TabId
  | TagParams.TagId;

export type QueryParam =
  | 'place'
  | 'product'
  | CatalogQueryParams.AddItem
  | CatalogQueryParams.AddMenu
  | CatalogQueryParams.CatalogId
  | 'reference'
  | GuardQueryParams.HasOrdersToday
  | LoyaltyParams.Share
  | LoyaltyParams.Invited
  | LoyaltyParams.ShareCode
  | 'limit'
  | 'offset'
  | 'isProductionLocation'
  | 'boardOrderFinished'
  | 'search'
  | '_sw_return_url'
  | '_preview';
