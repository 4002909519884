<div
  *ngIf="isValid; else elseBlock"
  class="widget-feedback"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.marginLeft.px]="attributes.style?.showBackground ? '' : '0'"
  [style.marginRight.px]="attributes.style?.showBackground ? '' : '0'"
  [style.borderRadius.px]="attributes.style?.borderRadius"
  [style.boxShadow]="attributes.style?.showBackground && attributes.style?.hasBounds ? '' : 'none'"
  [style.padding]="attributes.style?.hasBounds ? '' : '0'"
  [style.background-color]="attributes.style?.showBackground ? '' : 'transparent'"
>
  <h2 class="title">{{ labels.TITLE || 'FEEDBACK.TITLE' | translate }}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="attributes?.showStars" class="field">
      <pop-feedback-rating formControlName="review" [mainColor]="attributes.mainColor"></pop-feedback-rating>
    </div>
    <div *ngIf="attributes?.showName" class="field">
      <label for="feedback-name">{{ labels.NAME || 'FEEDBACK.NAME' | translate }}</label>
      <input type="text" id="feedback-name" formControlName="name" maxlength="50" />
      <div *ngIf="showErrors && form.errors?.nameRequired" class="error-msg">
        {{ labels.NAME_REQUIRED || 'FEEDBACK.NAME_REQUIRED' | translate }}
      </div>
    </div>
    <div *ngIf="attributes?.showComment" class="field">
      <label for="comment">{{ labels.COMMENT || 'FEEDBACK.COMMENT' | translate }}</label>
      <textarea id="comment" formControlName="comment" resize="false" maxlength="200" rows="3"></textarea>
      <div *ngIf="showErrors && form.errors?.commentRequired" class="error-msg">
        {{ labels.COMMENT_REQUIRED || 'FEEDBACK.COMMENT_REQUIRED' | translate }}
      </div>
      <div *ngIf="showErrors && form.errors?.commentOrImageRequired" class="error-msg">
        {{ labels.COMMENT_OR_IMAGE_REQUIRED || 'FEEDBACK.COMMENT_OR_IMAGE_REQUIRED' | translate }}
      </div>
    </div>
    <div *ngIf="attributes?.showUpload" class="field">
      <pop-feedback-upload-image
        formControlName="image"
        [mainColor]="attributes.mainColor"
        [isEditMode]="isReadOnly"
        [labels]="attributes.labels"
        (uploadStatus)="onUploadChangeStatus($event)"
      ></pop-feedback-upload-image>
      <div *ngIf="showErrors && form.errors?.imageRequired" class="error-msg">
        {{ labels.IMAGE_REQUIRED || 'FEEDBACK.IMAGE_REQUIRED' | translate }}
      </div>
    </div>
    <button
      [type]="isReadOnly ? 'button' : 'submit'"
      [style.backgroundColor]="attributes.mainColor"
      class="submit-btn"
      [class.submit-btn--disabled]="isReadOnly || isSubmitBtnDisabled"
      [disabled]="isReadOnly || isSubmitBtnDisabled"
    >
      {{ labels.SUBMIT_BUTTON || 'FEEDBACK.SUBMIT_BUTTON' | translate }}
    </button>
  </form>
  <ng-container *ngIf="attributes.showPreviousFeedbacks && feedbacks?.length">
    <pop-feedback-reviews
      [feedbacks]="feedbacks"
      [mainColor]="attributes.mainColor"
      [labels]="attributes.labels"
    ></pop-feedback-reviews>
  </ng-container>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-feedback">{{ noValidMessage }}</div>
</ng-template>
