export interface DesignerApplication {
  _id: string;
  name: string;
  cover: ApplicationCoverData;
  slug: string;
  applicationType: ApplicationType;
}

export interface Application {
  _id: string;
  name: string;
  slug: string;
  cover?: ApplicationCoverData;
}

export enum ApplicationType {
  All = 'all',
  Demo = 'demo',
  Production = 'production',
  Development = 'development',
  Template = 'template',
  Internal = 'internal',
}

interface ApplicationCoverData {
  alt: string;
  url: string;
  storageType: string;
}
