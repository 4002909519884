import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private _refreshTokenInProgress = false;
  private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private _auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqestWithToken = this._addAuthenticationToken(request);

    return next.handle(reqestWithToken).pipe(catchError(error => this._handleUnauthorized(error, request, next)));
  }

  private _addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this._auth.getAccessTokenDeprecated();

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private _handleUnauthorized(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const errorFactory = (): Error => (typeof error.error === 'object' ? error.error : error);

    if (
      request.url.includes('refresh-login-token') ||
      request.url.includes('login') ||
      request.url.includes('logout')
    ) {
      if (request.url.includes('refresh-login-token')) {
        this._auth.removeTokens();
        this._auth.redirectToLogin();
      }

      return throwError(errorFactory);
    }

    if (error.status !== 401 && error.status !== 403) {
      return throwError(errorFactory);
    }

    if (this._refreshTokenInProgress) {
      return this._refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this._addAuthenticationToken(request))),
      );
    }

    this._refreshTokenInProgress = true;
    this._refreshTokenSubject.next(null);

    return this._auth.extendToken().pipe(
      switchMap(accessToken => {
        this._refreshTokenInProgress = false;
        this._refreshTokenSubject.next(accessToken);
        const reqestWithToken = this._addAuthenticationToken(request);

        return next.handle(reqestWithToken);
      }),
      catchError(err => {
        this._refreshTokenInProgress = false;

        return throwError(() => new Error(err));
      }),
    );
  }
}
