import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { ScriptLoaderService } from '@bend/script-loader';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetPost } from './post';

@Component({
  selector: 'pop-widget-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetPostComponent implements OnInit, WidgetComponent {
  static widgetName = 'facebook_post';

  @Input() attributes: WidgetPost;
  @ViewChild('facebook') facebook: ElementRef;
  noValidMessage: string;
  loaded: boolean;

  constructor(
    private _warningService: WarningService,
    private _scriptLoader: ScriptLoaderService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.url) {
      this.noValidMessage = this._warningService.showWarn(WidgetPostComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this._scriptLoader.load(`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0`).then(() => {
      this.loaded = true;
      this._cdr.detectChanges();
    });
  }
}
