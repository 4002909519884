import { Application } from '@designer-store/apps/apps.models';

export interface User {
  _id?: string;
  name: string;
  email: string;
  password?: string;
  allowedAppsSlugs?: string[];
  applications?: Application[];
}
