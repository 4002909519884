import { AfterViewInit, Component, Injector, OnDestroy } from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { startWith, Subscription } from 'rxjs';

interface ErrorMessage {
  message: string;
  param?: string;
}

interface ErrorMessagesConfig {
  [key: string]: ErrorMessage;
}

const ERRORS_CONFIG: ErrorMessagesConfig = {
  required: { message: 'WIDGETS.SHARED.REQUIRED' },
  email: { message: 'WIDGETS.SHARED.EMAIL_VALID' },
  min: { message: 'WIDGETS.SHARED.MIN', param: 'min' },
  max: { message: 'WIDGETS.SHARED.MAX', param: 'max' },
  minlength: {
    message: 'WIDGETS.SHARED.MIN_LENGTH',
    param: 'requiredLength',
  },
  maxlength: {
    message: 'WIDGETS.SHARED.MAX_LENGTH',
    param: 'requiredLength',
  },
  hasCapitalLetter: { message: 'WIDGETS.SHARED.HAS_CAPITAL_LETTER' },
  noCharacter: { message: 'WIDGETS.SHARED.NO_CHARACTER' },
  duplicate: { message: 'Duplicate' },
  isNotInteger: { message: 'WIDGETS.SHARED.INTEGER_ERROR' },
  wrongIp: { message: 'WIDGETS.SHARED.IP_ADDRESS_ERROR' },
  wrongPort: { message: 'WIDGETS.SHARED.PORT_ERROR' },
  isNotUrl: { message: 'WIDGETS.SHARED.URL_ERROR' },
  invalidSelection: { message: 'WIDGETS.SHARED.INVALID_SELECTION_ERROR' },
};

// TODO: Remove on Angular 15 / Material 15, change to component with matError directive
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[designerInputErrors]',
  template: '{{ error | translate:param }}',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InputErrorsDirective implements AfterViewInit, OnDestroy {
  error = '';
  param = { value: '' };
  inputRef: MatFormFieldControl<MatInput>;

  private _subscription = new Subscription();

  constructor(private _inj: Injector) {}

  ngAfterViewInit(): void {
    const container = this._inj.get(MatFormField);
    this.inputRef = container._control;

    this._subscription.add(
      this.inputRef.ngControl.control.valueChanges
        .pipe(startWith(this.inputRef.ngControl.control.value))
        .subscribe(this.updateErrors),
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private updateErrors = (): void => {
    const controlErrors = this.inputRef.ngControl.errors;

    if (!controlErrors) return;
    const errorKey = Object.keys(controlErrors)[0];

    if (!ERRORS_CONFIG[errorKey]) return;

    this.error = ERRORS_CONFIG[errorKey].message;

    this.param.value =
      ERRORS_CONFIG[Object.keys(controlErrors)[0]]?.param &&
      this.inputRef.ngControl.getError(errorKey)[ERRORS_CONFIG[errorKey].param];
  };
}
