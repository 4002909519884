import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Injector,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ScriptLoaderService } from '@bend/script-loader';
import { ViewportService } from '@bend/viewport';

import { BarTopPageBoardComponent } from './views/bar-top-page-board';
import { BarTopPageDesktopComponent } from './views/bar-top-page-desktop';
import { BarTopPageMobileComponent } from './views/bar-top-page-mobile';

@Component({
  selector: 'app-bar-top-page',
  templateUrl: './bar-top-page.component.html',
  styleUrls: ['./bar-top-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarTopPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  private _subscription: Subscription;

  constructor(
    private _viewport: ViewportService,
    private _cdr: ChangeDetectorRef,
    private _injector: Injector,
    private _scriptLoader: ScriptLoaderService,
    private readonly _vcr: ViewContainerRef,
  ) {
    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    this._subscription.add(this._mobileBar());
    this._subscription.add(this._desktopBar());
    this._subscription.add(this._boardBar());
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _mobileBar(): Subscription {
    return this._viewport.changeToMobile
      .pipe(
        tap(() => this.container.clear()),
        switchMap(() =>
          this._scriptLoader.loadModule<BarTopPageMobileComponent>(
            import('./views/bar-top-page-mobile'),
            'BarTopPageMobileModule',
            'BarTopPageMobileComponent',
            this._injector,
            this._vcr,
          ),
        ),
        tap(componentRef => this._putInContainer(componentRef)),
      )
      .subscribe();
  }

  private _desktopBar(): Subscription {
    return this._viewport.changeToDesktop
      .pipe(
        tap(() => this.container.clear()),
        switchMap(() =>
          this._scriptLoader.loadModule<BarTopPageDesktopComponent>(
            import('./views/bar-top-page-desktop'),
            'BarTopPageDesktopModule',
            'BarTopPageDesktopComponent',
            this._injector,
            this._vcr,
          ),
        ),
        tap(componentRef => this._putInContainer(componentRef)),
      )
      .subscribe();
  }

  private _boardBar(): Subscription {
    return this._viewport.changeToBoard
      .pipe(
        tap(() => this.container.clear()),
        switchMap(() =>
          this._scriptLoader.loadModule<BarTopPageBoardComponent>(
            import('./views/bar-top-page-board'),
            'BarTopPageBoardModule',
            'BarTopPageBoardComponent',
            this._injector,
            this._vcr,
          ),
        ),
        tap(componentRef => this._putInContainer(componentRef)),
      )
      .subscribe();
  }

  private _putInContainer<T>(component: ComponentRef<T>): void {
    this.container.insert(component.hostView);

    this._cdr.detectChanges();
  }
}
