import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ArrayStore, Option, OptionGroup } from '../shared';
import { actions } from './option-group.actions';
import * as selectors from './option-group.selectors';
import { State } from './option-group.type';

@Injectable()
export class OptionGroupService implements ArrayStore<OptionGroup> {
  readonly all: Observable<OptionGroup[]>;
  readonly id!: Observable<number[]>;
  readonly name!: Observable<string[]>;
  readonly displayLabel!: Observable<string[]>;
  readonly order!: Observable<number[]>;
  readonly productId!: Observable<number[]>;
  readonly allowMultipleSameOptions!: Observable<boolean[]>;
  readonly multiple!: Observable<boolean[]>;
  readonly minAllowedOptions!: Observable<number[]>;
  readonly maxAllowedOptions!: Observable<number[]>;
  readonly required!: Observable<boolean[]>;
  readonly options!: Observable<Option[][]>;

  readonly selectedProductId: Observable<number>;
  readonly allByProduct: Observable<OptionGroup[]>;

  constructor(protected _store: Store<State>, protected _actions: Actions) {
    this.all = this._all;
    this.allByProduct = this._allByProduct;
    this.selectedProductId = this._selectedProductId;
  }

  getOptions(productId: number): void {
    this._store.dispatch(actions.getOptionGroups({ productId }));
  }

  getById(groupId: number): Observable<OptionGroup> {
    return this._all.pipe(map(all => all.find(({ id }) => groupId === id)));
  }

  private get _allByProduct(): Observable<OptionGroup[]> {
    return this._all.pipe(
      withLatestFrom(this._idsByProduct),
      map(([items, ids]) => items.filter(({ id }) => ids.includes(id))),
    );
  }

  private get _selectedProductId(): Observable<number> {
    return this._store.select(selectors.selectedProductId);
  }

  private get _all(): Observable<OptionGroup[]> {
    return this._store.select(selectors.all);
  }

  private get _idsByProduct(): Observable<number[]> {
    return this._store.select(selectors.idsByProduct);
  }
}
