<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form">
    <div class="group-label">{{ 'WIDGETS.SHARED.DISPLAY_PARAMS' | translate }}</div>
    <div class="row">
      <label class="input-label">
        <app-checkbox formControlName="showName"></app-checkbox>
        {{ 'WIDGETS.CONTACT.SHOW_NAME' | translate }}
      </label>
    </div>

    <div class="row">
      <label class="input-label">
        <app-checkbox formControlName="showEmail"></app-checkbox>
        {{ 'WIDGETS.CONTACT.SHOW_EMAIL' | translate }}
      </label>
    </div>

    <div class="row">
      <label class="input-label">
        <app-checkbox formControlName="showPhone"></app-checkbox>
        {{ 'WIDGETS.CONTACT.SHOW_PHONE' | translate }}
      </label>
    </div>

    <div class="row">
      <label class="input-label">
        <app-checkbox formControlName="mandatoryFields"></app-checkbox>
        {{ 'WIDGETS.CONTACT.MANDATORY_FIELDS' | translate }}
      </label>
    </div>

    <pop-widget-edit-link-selector
      class="page-selector"
      [host]="host"
      [pages]="pages"
      [control]="redirectUrl"
    ></pop-widget-edit-link-selector>

    <div class="group-label">{{ 'WIDGETS.STYLE.STYLES' | translate }}</div>
    <div formGroupName="style" class="row">
      <div class="row__col" formGroupName="margin">
        <label class="input-label">
          {{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}
          <div class="style">
            <app-input class="style__input" type="number" min="0" formControlName="top"></app-input>
            <span class="px">px</span>
          </div>
        </label>
      </div>

      <div class="row__col" formGroupName="margin">
        <label class="input-label">
          {{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}
          <div class="style">
            <app-input class="style__input" type="number" min="0" formControlName="bottom"></app-input>
            <span class="px">px</span>
          </div>
        </label>
      </div>

      <div class="row__col">
        <label class="input-label">
          {{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}
          <div class="style">
            <app-input class="style__input" type="number" min="0" formControlName="borderRadius"></app-input>
            <span class="px">px</span>
          </div>
        </label>
      </div>

      <div class="row__col">
        <label class="input-label">
          <app-checkbox formControlName="showBackground"></app-checkbox>
          {{ 'WIDGETS.STYLE.BACKGROUND_SHOW' | translate }}
        </label>
      </div>
    </div>

    <div class="group-label">{{ 'WIDGETS.SHARED.LABELS' | translate }}</div>
    <div formGroupName="labels">
      <label class="row-input input-label">
        <span>{{ 'WIDGETS.SHARED.TITLE' | translate }}</span>
        <app-input class="row-input__input" type="text" formControlName="title"></app-input>
      </label>

      <label class="row-input input-label">
        <span>{{ 'WIDGETS.SHARED.NAME' | translate }}</span>
        <app-input class="row-input__input" type="text" formControlName="name"></app-input>
      </label>

      <label class="row-input input-label">
        <span>{{ 'WIDGETS.SHARED.EMAIL' | translate }}</span>
        <app-input class="row-input__input" type="text" formControlName="email"></app-input>
      </label>

      <label class="row-input input-label">
        <span>{{ 'WIDGETS.SHARED.PHONE' | translate }}</span>
        <app-input class="row-input__input" type="text" formControlName="phone"></app-input>
      </label>

      <label class="row-input input-label">
        <span>{{ 'WIDGETS.CONTACT.SUBMIT' | translate }}</span>
        <app-input class="row-input__input" type="text" formControlName="sendButton"></app-input>
      </label>

      <label class="row-input input-label">
        <span>{{ 'WIDGETS.CONTACT.CONFIRM_MESSAGE' | translate }}</span>
        <app-input class="row-input__input" type="text" formControlName="confirmationMessage"></app-input>
      </label>
    </div>
  </form>
</pop-widget-edit-widget>
