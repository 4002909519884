export type LanguagesConfig = 'en' | 'fr' | 'dk' | 'de' | 'he';
export const supportLanguages: LanguagesConfig[] = ['en', 'fr', 'dk', 'de', 'he'];
export interface LanguageConfigData {
  fallbackLanguage: LanguagesConfig;
  allowedLanguages: LanguagesConfig[];
}

export type LanguageLabelType = {
  readonly [key in LanguagesConfig]: string;
};

export const languagesLabels: LanguageLabelType = {
  en: 'English',
  fr: 'French',
  dk: 'Danish',
  de: 'Deutsch',
  he: 'Hebrew',
} as const;
