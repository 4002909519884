<div *ngIf="isValid; else elseBlock" class="widget-twitter-htag">
  <div id="{{ 'htag-' + attributes.twitterWidgetId }}" class="htag">
    <h2 class="htag">{{ attributes.htag }}</h2>
    <button class="btn-link timeline" (click)="openTimeline()" [style.backgroundColor]="attributes.mainColor">
      <span>{{ 'TWITTER_HTAG.VIEW_TWEET' | translate }}</span>
      <img class="chevron-right" alt="" src="../../../assets/img/twitter/chevron-right-white.svg" />
    </button>
  </div>
  <div id="{{ 'htag-timeline-' + attributes.twitterWidgetId }}" class="twitter-content hidden">
    <a
      id="{{ 'twitter-timeline-' + attributes.twitterWidgetId }}"
      class="twitter-timeline"
      href="https://twitter.com/hashtag/"
      data-widget-id="0"
      data-chrome="noheader"
      style="display: block"
    ></a>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-twitter-htag no-valid-msg">{{ noValidMessage }}</div>
</ng-template>
