import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subject, take } from 'rxjs';

import { SettingsService } from '@bend/store';
import { ItemUnavailabilityDisplay } from '@bend/store-shared';

import { RecommendationDetailsQuantityPipe } from '../../pipes';
import { PopProduct } from '../../product-catalog';
import { RecommendationsMenuService } from '../../services';

@Component({
  selector: 'product-catalog-recommendation-container',
  templateUrl: './recommendation-container.component.html',
  styleUrls: ['./recommendation-container.component.scss'],
  animations: [
    trigger('appear', [
      transition(':enter', [style({ opacity: '0' }), animate('.2s ease-out', style({ opacity: '1' }))]),
    ]),
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationContainerComponent implements OnDestroy, OnInit {
  @Input() item: PopProduct;
  @Input() parentItemAvailability: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;

  showMenu$: Observable<boolean>;

  protected primaryColor = '#fff'; // Fallback color;
  protected menuBorder: string;

  private destroy$ = new Subject<void>();

  constructor(
    private settings: SettingsService,
    private recommendationMenu: RecommendationsMenuService,
    private cdr: ChangeDetectorRef,
    private recommendationsQuantityPipe: RecommendationDetailsQuantityPipe,
  ) {}

  ngOnInit(): void {
    this.initColors();
    this.initMenuFlow();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addItem(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.recommendationMenu.addRecommendationClick(this.item);
    this.cdr.detectChanges();
  }

  removeItem(): void {
    if (this.recommendationsQuantityPipe.transform(this.item) < 1) {
      return;
    }
    this.recommendationMenu.removeRecommendationClick(this.item);
  }

  protected menuLoaded({ element }: { element: ElementRef<HTMLElement> }): void {
    element.nativeElement.focus();
  }

  protected clickedOutside(): void {
    this.recommendationMenu.hideMenu();
  }

  private initMenuFlow(): void {
    this.showMenu$ = this.recommendationMenu.showMenu(this.item);
  }

  private initColors(): void {
    this.settings.colors.primary.pipe(take(1)).subscribe(color => {
      this.primaryColor = color;
      this.menuBorder = `2px solid ${color}`;
      this.cdr.detectChanges();
    });
  }
}
