import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { GalleryWidget } from './gallery';

@Component({
  selector: 'pop-widget-edit-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditGalleryComponent extends WidgetBaseComponent<GalleryWidget> implements OnInit {
  static widgetName = 'gallery';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    if (this.widget.attributes.images && this.widget.attributes.images.length) {
      for (const image of this.widget.attributes.images) {
        const data = {
          alt: image['alt'],
          url: image['url'],
        };
        this.addImage(data);
      }
    } else {
      this.initImage();
    }
  }

  initImage(value = {}): UntypedFormGroup {
    return this._formBuilder.group({
      alt: [value['alt']],
      url: [value['url'], Validators.required],
    });
  }

  addImage(value = {}): void {
    const control = this.form.controls['images'] as UntypedFormArray;
    control.push(this.initImage(value));
  }

  removeImage(index: number): void {
    const control = this.form.controls['images'] as UntypedFormArray;
    control.removeAt(index);
  }

  getImage(index: number): UntypedFormControl {
    return this.imagesControls[index].get('url') as UntypedFormControl;
  }

  get imagesControls(): UntypedFormControl[] {
    return (this.form.get('images') as UntypedFormArray).controls as UntypedFormControl[];
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        images: this._formBuilder.array([], Validators.minLength(1)),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
