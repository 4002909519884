import { OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { filterUndefined } from './filter-undefined.helper';

export const ifPluck = <T, K extends keyof T>(key: K): OperatorFunction<T, T[K]> => {
  /* TODO: Change pluck to map  ( map(data => data?.[key]) ) ? */
  return source =>
    source.pipe(
      filter<T>(Boolean),
      map(data => data?.[key]),
      filterUndefined(),
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ifPluckArray = <T extends any[], K extends keyof T[number]>(
  key: K,
): OperatorFunction<T, T[number][K][]> => {
  return source =>
    source.pipe(
      // check exist state
      filter<T>(Boolean),
      // TODO: remove as T
      map(items => items.map(item => item[key]) as T),
      // check exist key in object
      filter<T>(([value]) => !!value),
    );
};
