import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogGeneric } from '../../helpers';
import { DialogConfigComponent } from '../../types';

@Component({
  selector: 'dialog-progress',
  templateUrl: './dialog-progress.component.html',
  styleUrls: ['./dialog-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogProgressComponent extends DialogGeneric<DialogProgressComponent> {
  constructor(@Inject(MAT_DIALOG_DATA) data: DialogConfigComponent, dialogRef: MatDialogRef<DialogProgressComponent>) {
    super(data, dialogRef);
  }
}
