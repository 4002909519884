import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { CatalogReloadService, OptimisticService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { CurrencyPipe } from '@bend/shared-widgets/src/lib/shared/shared-components/pipes';
import { ItemUnavailabilityDisplay } from '@bend/store';
import { SettingsService } from '@bend/store';
import { MenuItemStyleType } from '@bend/store-shared';
import { ViewportService } from '@bend/viewport';
import { PricesService } from '@bend/widgets-old/product-catalog/services/prices.service';

import { LinkService } from '../../../link.service';
import { ErrorHandlerService } from '../../../shared/error-handler/error-handler.service';
import { PopCurrency } from '../../../widget';
import { PageAnalytics, PopMenu, ProductCatalogStyleConfig } from '../../product-catalog';
import { WidgetProductCatalogService } from '../../product-catalog.service';
import { WidgetProductCatalogHelperService } from '../../product-catalog-helper.service';
import { WidgetProductCatalogMenuComponent } from '../product-catalog-menu.component';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'pop-step-by-step-menu',
  templateUrl: './step-by-step-menu.component.html',
  styleUrls: ['./step-by-step-menu.component.scss'],
})
export class StepByStepMenuComponent extends WidgetProductCatalogMenuComponent implements OnInit, OnDestroy {
  @Input() menu: PopMenu;
  @Input() currency: PopCurrency;
  @Input() mainColor: string;
  @Input() secondaryColor: string;
  @Input() styleType: string;
  @Input() styleConfig: ProductCatalogStyleConfig;
  @Input() pageAnalytics: PageAnalytics;
  @Input() menuItemAdded: Subject<any>;
  @Input() isReadOnly: boolean;
  @Input() isConsultingMode: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  @Input() forceShowProductDescription: boolean;
  @Output() closeStepper = new EventEmitter<boolean>();

  @ViewChildren('menuItem') menuItems!: QueryList<ElementRef>;
  @ViewChildren('tabContent') tabs: QueryList<ElementRef>;

  quantity: number;
  selectedTab = 0;
  showBackButton: boolean;
  activeIndex = 0;
  errorCategories: any[] = [];
  categoryError: boolean;
  topBarTitle: string;
  startingPoint: number;
  fgColor$: Observable<string>;
  menuItemStyle$: Observable<MenuItemStyleType>;

  constructor(
    productCatalogService: WidgetProductCatalogService,
    helperService: WidgetProductCatalogHelperService,
    dialog: MatDialog,
    linkService: LinkService,
    errorHandlerService: ErrorHandlerService,
    optimistic: OptimisticService,
    menuService: MenuService,
    cdr: ChangeDetectorRef,
    private _settings: SettingsService,
    protected _reloadCatalog: CatalogReloadService,
    private _elem: ElementRef,
    protected _viewport: ViewportService,
    protected _dialog: DialogService,
    protected pricesService: PricesService,
  ) {
    super(
      productCatalogService,
      helperService,
      dialog,
      linkService,
      errorHandlerService,
      optimistic,
      menuService,
      cdr,
      _reloadCatalog,
      _viewport,
      _dialog,
      pricesService,
    );

    this.fgColor$ = this._settings.colors.secondaryForeground;
    this.menuItemStyle$ = this._settings.widgetProductCatalog.menuItemStyleType;
  }

  ngOnInit(): void {
    this._subscription.add(this.menuItemAdded.subscribe(isSelected => this.handleItemAdded(isSelected)));
    const pipe = new CurrencyPipe(this._settings);
    this.topBarTitle = `${this.menu.name} - ${pipe.transform(this.menu.priceFinalValue)}`;
    this.loadMenuCategories(this.menu);
    this.checkErrors();
  }

  changeTab(): void {
    const index = this.menuCategories.findIndex(category =>
      category.menuCategoryItems.every(item => !item.item.isSelected),
    );
    if (index < 0) return;
    this.selectedTab = index;

    this.checkErrors();
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.activeIndex = event.index;
    this.activeIndex !== 0 ? (this.showBackButton = true) : (this.showBackButton = false);
    this._setTabsLineColor();
    this.checkErrors();
    this.scrollIfSelected();
  }

  private _setTabsLineColor(): void {
    const elements = this._elem.nativeElement.querySelectorAll('.display-line');

    elements.forEach(elem => elem.style.setProperty('--var-check-primary', this.mainColor));
  }

  close(): void {
    this.menuService.closeStepper();
  }

  checkErrors(): void {
    this.errorCategories = this.helperService.getAllRequiredMenuItemsNotSelected(this.menuCategories);
    if (this.errorCategories.length && this.menuCategories[this.activeIndex].id === this.errorCategories[0]) {
      this.categoryError = true;
      return;
    }
    this.categoryError = false;
  }

  handleItemAdded(isSelected: boolean): void {
    this.checkErrors();
    if (isSelected) {
      this.changeTab();
    }
    this._cdr.detectChanges();
  }

  scrollIfSelected(): void {
    const activeItems = this.menuItems.filter(
      (_, index) => index < this.menuCategories[this.activeIndex].menuCategoryItems.length,
    );

    const scrollTo = activeItems.find(item => item.nativeElement.getAttribute('isselected'));

    if (scrollTo) {
      scrollTo.nativeElement.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  get menuItemsRowStyle(): Observable<boolean> {
    const allowRow = new Set([MenuItemStyleType.SideBySide]);

    return this.menuItemStyle$.pipe(map(type => allowRow.has(type)));
  }

  get menuItemBgColor(): Observable<boolean> {
    const allowWhite = new Set([MenuItemStyleType.Extended]);

    return this.menuItemStyle$.pipe(map(type => allowWhite.has(type)));
  }

  get isMenuItemStyleExtended(): Observable<boolean> {
    return this.menuItemStyle$.pipe(map(type => type === MenuItemStyleType.Extended));
  }
}
