import { Injectable } from '@angular/core';
import { combineLatest, fromEvent, merge, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { delay, filter, first, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { SocketType } from '@bend/socket';
import { OrderService } from '@bend/store/src/lib/order';
import { OrderItemsService } from '@bend/store/src/lib/order-items';
import { OrderItemStatus } from '@bend/store-shared';

import { tapError } from '../../../../../../../shared-widgets/src/lib/helpers';
import { OrderDialogLabels } from '../../../../config';
import { ErrorCodes, PriorityStatus, SocketOrder, SocketOrderType } from '../../../../types';
import { OptimisticService } from '../optimistic/optimistic.service';
import { OrderSocketService } from '../order-socket/order-socket.service';
import { OrderStatusServices } from '../order-status/order-status.service';

@Injectable()
export class OrderCheckUpdateService {
  private _allowStatuses: Set<OrderItemStatus>;
  private _subscription: Subscription;

  orderClosed$ = new Subject<void>();

  constructor(
    private _order: OrderService,
    private _orderStatus: OrderStatusServices,
    private _orderItems: OrderItemsService,
    private _socketOrder: OrderSocketService,
    private _optimistic: OptimisticService,
    private _dialog: DialogService,
  ) {
    /**
     * all allowed statuses to check orderUpdateAt in timer
     */
    this._allowStatuses = new Set<Partial<OrderItemStatus>>([
      OrderItemStatus.OrderedInProgress,
      OrderItemStatus.PaymentInProgress,
      OrderItemStatus.ScheduledForPreparing,
      OrderItemStatus.Preparing,
      OrderItemStatus.ToBePrepared,
      /**
       *  preparing when is pay after flow
       */
      OrderItemStatus.Ordered,
    ]);
  }

  init(): void {
    if (this._subscription) return;

    this._subscription = this._socketOrder.message
      .pipe(
        tap(event => event.type === SocketOrderType.OrderClosed && this.close()),
        /**
         *  if the socket does not work it starts with a default message
         */
        startWith({ type: SocketType.Error }),
        switchMap(event =>
          merge(
            this._checkOptimistic(),
            /**
             * when the socket is connected, we get order-update as fallback to prevent delayed connection of the socket
             */
            this._checkOpen(event),
            /**
             * if socket have an error we get order-update as fallback
             */
            this._checkError(event),
            /**
             * check order update by timer
             */
            this._checkTimer(),
            /**
             * check order update by browser visibility
             */
            this._checkBrowserVisibility(),
          ),
        ),
        /**
         *  get updateAt from api and from store
         */
        switchMap(() =>
          combineLatest([
            /**
             * get from api 'check order update'
             */
            this._order.orderLastUpdate.pipe(first()),
            /**
             * get current value from store
             */
            this._order.updatedAt.pipe(first()),
          ]),
        ),
        /**
         * check is new update for order
         */
        filter(([{ updatedAt }, currentUpdatedAt]) => updatedAt.getTime() > currentUpdatedAt.getTime()),
        /**
         * send store new action to get new order
         */
        tap(([orderUpdate]) => this._order.update(orderUpdate)),
        /**
         *  if an error occurs, stop the timer completely
         */
        tapError(({ errorCode }) => {
          const allowCloseOrderPopUp = new Set<string>([ErrorCodes.OrderInactive]);

          if (allowCloseOrderPopUp.has(errorCode)) {
            this._dialog.info({ message: OrderDialogLabels.Close });
          }

          // Emmit event to subscribers that order is closed
          this.orderClosed$.next();

          this.close();
          this._socketOrder.close();
          this._order.reset();
        }),
      )
      .subscribe();
  }

  close(): void {
    if (!this._subscription) return;

    this._subscription.unsubscribe();
    this._subscription = undefined;
  }

  private _checkOptimistic(): Observable<PriorityStatus> {
    return this._optimistic.status.pipe(
      /**
       * wait 1sec to see if a message is coming from the socket
       * if a message comes from the socket then this message will be canceled
       */
      delay(1000),
    );
  }

  private _checkOpen(event: Pick<SocketOrder, 'type'>): Observable<Pick<SocketOrder, 'type'>> {
    return of(event).pipe(filter(({ type }) => type === SocketType.Open));
  }

  private _checkError(event: Pick<SocketOrder, 'type'>): Observable<number> {
    /**
     * timer to check updatedAt
     */
    const delayTime = 5e3;

    return of(event).pipe(
      filter(({ type }) => type === SocketType.Error),
      /**
       * check if order is loaded
       */
      switchMap(() => this._orderItems.all),
      /**
       * if user have order we check status
       */
      switchMap(items => (items.length ? this._orderStatus.orderStatusChanged : of({ status: OrderItemStatus.New }))),
      /**
       * get only first event for order items
       */
      take(1),
      /**
       * if status is allowed skip status because is verify by checkTimer
       */
      filter(({ status }) => !this._allowStatuses.has(status)),
      /**
       * start the timer until a new event comes out of socket
       */
      switchMap(() => timer(0, delayTime)),
    );
  }

  private _checkTimer(): Observable<[number, PriorityStatus]> {
    /**
     * timer to check updatedAt
     */
    const delayTime = 5e3;

    return timer(delayTime, delayTime).pipe(
      /**
       *  get only one event for order status
       */
      withLatestFrom(this._orderStatus.orderStatusChanged),
      /**
       * check if status is allowed to verify order
       */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, { status }]) => this._allowStatuses.has(status)),
    );
  }

  private _checkBrowserVisibility(): Observable<boolean> {
    /**
     * check when browser visibility is changed
     */
    return fromEvent(window, 'visibilitychange').pipe(
      /**
       * check and return if document is visible
       */
      map(() => !document.hidden),
      /**
       * allow only visible events
       */
      filter<boolean>(Boolean),
    );
  }
}
