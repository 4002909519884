import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { actions } from './tickets.actions';
import { TicketsHttpService } from './tickets.http.service';

@Injectable()
export class TicketsEffects {
  constructor(private _actions$: Actions, private _ticketService: TicketsHttpService) {}

  getListOfTickets$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getTickets),
      mergeMap(({ userId, limit, offset }) =>
        this._ticketService.getTickets({ userId, limit, offset }).pipe(
          map(data => actions.getTicketsSuccess({ ...data, userId })),
          catchError(() => of(actions.getTicketsError())),
        ),
      ),
    ),
  );

  editTicket$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.editTicket),
      mergeMap(({ id, ticket }) =>
        this._ticketService.editTicket(id, ticket).pipe(
          map(data => actions.editTicketSuccess({ ticket: data })),
          catchError(() => of(actions.editTicketError())),
        ),
      ),
    ),
  );
}
