import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ImageGalleryService } from '@bend/store';
import { Image } from '@bend/store/src/lib/shared/types/image-gallery.type';

import { isVideoFile } from '@designer-modules/image-gallery/helpers/is-video.helper';
import { PopinBuilderService } from '@designer-modules/popin-new';

@Component({
  selector: 'designer-gallery-grid-presenter',
  templateUrl: './gallery-grid-presenter.component.html',
  styleUrls: ['./gallery-grid-presenter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryGridPresenterComponent {
  readonly NO_IMAGE = 'assets/images/no-image.png';
  /*
   * List of images
   * */
  @Input() images: Image[];
  /*
   * Selected image id
   * */
  @Input() selectedImageId: number;

  @Input() isLoading = false;
  /*
   * Emmit image id on click
   * */
  @Output() selected: EventEmitter<number> = new EventEmitter<number>();

  protected readonly isVideoFile = isVideoFile;

  constructor(
    private _popinBuilder: PopinBuilderService,
    private _imageGalleryService: ImageGalleryService,
    private readonly _translate: TranslateService,
  ) {}

  @HostBinding('style.grid-template-columns')
  private get _setGridColumns(): string {
    return this.images && this.images.length ? 'repeat(auto-fill, minmax(200px, 1fr))' : 'none';
  }

  selectImage(imageId: number): void {
    this.selected.emit(imageId);
  }

  trackByFn: TrackByFunction<Image> = (_, item) => item.id;

  onDeleteClicked(imageId: number): void {
    const dialog = this._popinBuilder
      .getConfirmBuilder()
      .setIcon('warning-icon')
      .setTitle(this._translate.instant('STUDIO.IMAGE_GALLERY.DELETE_IMAGE') as string)
      .setSubTitle(this._translate.instant('STUDIO.SHARED.CONFIRM_QUESTION') as string)
      .setCancelLabel(this._translate.instant('STUDIO.SHARED.CANCEL') as string)
      .setConfirmLabel(this._translate.instant('STUDIO.SHARED.CONFIRM') as string)
      .build();

    dialog.afterClosed().subscribe(confirm => {
      if (!confirm) return;

      this._imageGalleryService.deleteImage(imageId);
    });
  }
}
