import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiVisitorService, CreditResponse, DictionaryPaymentMethods, WorkingHoursInterval } from '../shared';

@Injectable()
export class CartHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  paymentMethods(): Observable<DictionaryPaymentMethods> {
    return this._api.paymentMethods('v2').pipe(switchMap(api => this._http.get<DictionaryPaymentMethods>(api)));
  }

  deletePaymentMethod(methodId: string): Observable<void> {
    return this._api.paymentMethod('v2', methodId).pipe(switchMap(api => this._http.delete<void>(api)));
  }

  credit(): Observable<CreditResponse> {
    return this._api.credit('v1').pipe(switchMap(api => this._http.get<CreditResponse>(api)));
  }

  workingHours(): Observable<WorkingHoursInterval[]> {
    return this._api.orderWorkingHours('v1').pipe(
      switchMap(api => this._http.get<WorkingHoursInterval<string>[]>(api)),
      /**
       * @description cast string to date
       */
      map(intervals => intervals.map(({ id, open, close }) => ({ id, open: new Date(open), close: new Date(close) }))),
    );
  }
}
