import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { ISnackbarData } from './snackbar-data.model';

@Component({
  selector: 'designer-snackbar-container',
  template: `
    <designer-snackbar
      [title]="data.title"
      [subtitle]="data.subtitle"
      [icon]="data.icon"
      [color]="data.color"
      [buttonLabel]="data.buttonLabel"
      (closeSnackbar)="closeSnackbar()"
    ></designer-snackbar>
  `,
})
export class SnackbarContainerComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: ISnackbarData,
    private readonly snackbarRef: MatSnackBarRef<SnackbarContainerComponent>,
  ) {}

  closeSnackbar(): void {
    this.snackbarRef.dismiss();
  }
}
