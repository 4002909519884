import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './tickets.config';
import { adapter } from './tickets.reducer';
import { State } from './tickets.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const entities = createSelector(stateSelector, selectEntities);
export const tickets = createSelector(stateSelector, selectAll);
export const ids = createSelector(stateSelector, selectIds);
export const isLoading = createSelector(stateSelector, ticket => ticket.isLoading);
export const isLoaded = createSelector(stateSelector, ticket => ticket.isLoaded);
export const total = createSelector(stateSelector, ticket => ticket.total);
export const userId = createSelector(stateSelector, ticket => ticket.userId);
