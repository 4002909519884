import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { actions } from './smile-history.actions';
import { SmileHistoryHttpService } from './smile-history.http.service';

@Injectable()
export class SmileHistoryEffects {
  constructor(private _actions$: Actions, private _smileHistoryHttpService: SmileHistoryHttpService) {}

  getSmileHistory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getSmileHistory),
      switchMap(({ smileId, limit, skip }) =>
        this._smileHistoryHttpService.getSmileHistory({ limit, skip, smileId }).pipe(
          map(response => actions.getSmileHistorySuccess(response)),
          catchError(() => of(actions.getSmileHistoryError())),
        ),
      ),
    ),
  );
}
