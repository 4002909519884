import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Menu } from '../shared';
import { actions } from './menu.actions';
import { State } from './menu.type';

export const adapter: EntityAdapter<Menu> = createEntityAdapter<Menu>();

export const initialState: State = adapter.getInitialState({});

const menuReducer = createReducer(
  initialState,
  on(actions.addMenus, (state, { entities }) => adapter.addMany(entities, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return menuReducer(state, action);
}
