import { Injectable } from '@angular/core';
import { combineLatest, Observable, timer } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';

import { WorkingHoursStoreService } from '@bend/store/src/lib/working-hours';
import { WorkingHoursInterval } from '@bend/store-shared';

import { OrderTypeService } from '../../../../shared/cart-services';

@Injectable({ providedIn: 'root' })
export class CatalogIsReadOnlyService {
  constructor(private _orderType: OrderTypeService, private _workingHoursStore: WorkingHoursStoreService) {}

  /**
   * Can make an order on a catalog if this catalog is not in readonly mode and the service is not close.
   */
  canOrder(catalogId: number): Observable<boolean> {
    return combineLatest([this.getIsReadOnly(catalogId), this.getIsClose(catalogId)]).pipe(
      share(),
      map(([isReadOnly, isClose]) => !isReadOnly && !isClose),
    );
  }

  getIsReadOnly(catalogId: number): Observable<boolean> {
    return combineLatest([this.getIsClose(catalogId), this._orderType.isReadOnly]).pipe(
      map(values => values.some(Boolean)),
    );
  }

  getIsClose(catalogId: number): Observable<boolean> {
    return this.getIsOpen(catalogId).pipe(map(isOpen => !isOpen));
  }

  getIsOpen(catalogId: number): Observable<boolean> {
    return timer(0, 1000).pipe(
      switchMap(() => this.isInIntervals(catalogId, new Date())),
      // share(),
    );
  }

  isInIntervals(catalogId: number, time: Date): Observable<boolean> {
    return this._workingHoursStore.getBy(catalogId).pipe(
      map(intervals => {
        return this._isInIntervals(time, intervals);
      }),
    );
  }

  private _isInIntervals(time: Date, intervals: WorkingHoursInterval[]): boolean {
    return intervals.some(this._isInInterval(time));
  }

  private _isInInterval(time: Date): ({ open, close }: { open: Date; close: Date }) => boolean {
    return ({ open, close }) => open.getTime() <= time.getTime() && close.getTime() >= time.getTime();
  }
}
