import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IConfirmPopinData } from './confirm-popin.data.model';

@Component({
  selector: 'designer-confirm-popin-container',
  template: `
    <designer-confirm-popin
      [icon]="data.icon"
      [title]="data.title"
      [subTitle]="data.subTitle"
      [cancelLabel]="data.cancelLabel"
      [confirmLabel]="data.confirmLabel"
      (closeConfirmPopin)="closeConfirmPopin($event)"
    ></designer-confirm-popin>
  `,
})
export class ConfirmPopinContainerComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: IConfirmPopinData,
    private readonly confirmPopinRef: MatDialogRef<ConfirmPopinContainerComponent>,
  ) {}

  @HostListener('keydown.esc')
  closeConfirmPopin(confirmed = false): void {
    this.confirmPopinRef.close(confirmed);
  }
}
