export interface ItemSpecialPriceTimeRules {
  time: {
    from: number;
    to: number;
  }; // from 0 to 86400 -1
  weekDay: number; // from 0 to 6
}

interface SpecialPriceDBO {
  price: number;
  tvaId: number;
}

export interface Prices {
  onSite: SpecialPriceDBO;
  takeAway?: SpecialPriceDBO;
  happyHours?: SpecialPriceDBO;
}
