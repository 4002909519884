import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pop-widget-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetSpinnerComponent {
  @Input() color: string;
  @Input() width: number;
  @Input() height: number;
}
