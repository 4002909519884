import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmPopinBuilder } from './confirm-popin.builder';
import { PopinBuilder } from './popin-builder';

@Injectable()
export class PopinBuilderService {
  constructor(private readonly dialog: MatDialog) {}

  getBuilder<C, I extends object, R>(): PopinBuilder<C, I, R> {
    return new PopinBuilder<C, I, R>(this.dialog);
  }

  getConfirmBuilder(): ConfirmPopinBuilder {
    return new ConfirmPopinBuilder(this.dialog);
  }
}
