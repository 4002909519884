<div class="product-item">
  <div class="product-item__content">
    <div class="product-item__main-details">
      <p class="product__title" (click)="click(true, true)">{{ product.name }}</p>
      <p class="product__description" *ngIf="product.description" (click)="click(true, true)">
        {{ product.description }}
      </p>
      <div class="product-item__price price" (click)="click(true, true)">
        <span *ngIf="product.priceInitialValue; else elsePrice" class="product-item__initial-price price__board">
          {{ product.priceFinalValue | currency }}
          <span>
            {{ product.priceInitialValue | currency }}
          </span>
        </span>
        <ng-template #elsePrice>
          <span *ngIf="product.priceFinalValue" class="price__board">{{ product.priceFinalValue | currency }}</span>
        </ng-template>
      </div>

      <ng-container *ngIf="!isConsultingMode && !isReadOnly && !product.isReadOnly">
        <app-product-status-checkout
          [product]="product"
          class="product__status"
          [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
        >
          <button
            data-cy="productBtn"
            (click)="click(false, false)"
            class="add-to-cart__btn"
            [style.color]="secondColor$ | async"
            [style.background-color]="primaryColor$ | async"
          >
            <span *ngIf="loadingProductId !== product.id">
              {{ 'PRODUCT_CATALOG.ADD_TO_CART' | translate }}
            </span>
            <pop-widget-spinner
              *ngIf="loadingProductId === product.id"
              class="add-to-cart__loader"
              [width]="17"
              [height]="17"
              [color]="secondColor"
            ></pop-widget-spinner>
          </button>
        </app-product-status-checkout>
      </ng-container>

      <div class="product__footer">
        <div *ngIf="product.itemTags?.length" class="tags">
          <widgets-old-tag-list [tags]="product.itemTags"></widgets-old-tag-list>
        </div>
      </div>
    </div>
    <div class="product-item__right" [class.product-item--no-image]="!product.imageUrl">
      <div class="image" *ngIf="product.imageUrl" (click)="click(true, true)">
        <img [src]="product.imageUrl" alt="product photo" height="100" />
      </div>
    </div>
  </div>
</div>
