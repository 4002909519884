import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { iif, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions } from './settings.actions';
import { SettingsHttpService } from './settings.http.service';
import * as selectors from './settings.selectors';
import { State } from './settings.type';

@Injectable()
export class SettingsEffects {
  loadAreaSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getAreaSettings),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedBy))),
      mergeMap(([{ by }, isLoadedBy]) =>
        iif(
          () => isLoadedBy.includes(by),
          of(actions.getAreaSettingsIsLoaded({ by })),
          this._settingsHttp.getArea(by).pipe(
            map(settings => actions.getAreaSettingsSuccess({ by, settings })),
            catchError(({ message }) => of(actions.getAreaSettingsError({ error: message }))),
          ),
        ),
      ),
    ),
  );

  patchAreaSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.patchAreaSettings),
      switchMap(({ by, locationId, settings }) =>
        this._settingsHttp.patchArea(settings, by, locationId).pipe(
          map(patchedSettings => actions.patchAreaSettingsSuccess({ by, settings: patchedSettings })),
          catchError(({ message }) => of(actions.patchAreaSettingsError({ error: message }))),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _settingsHttp: SettingsHttpService, private _store: Store<State>) {}
}
