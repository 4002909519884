import { createAction, props } from '@ngrx/store';

import { createType, MenuItem } from '../shared';
import { storeKey } from './menu-item.config';

const type = createType(storeKey);

export const actions = {
  addMenuItems: createAction(type('add menu item'), props<{ entities: MenuItem[] }>()),
};
