import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { StorageModule } from '@bend/storage';

import { storeKey } from './auth.config';
import { AuthEffects } from './auth.effects';
import { AuthHttpService } from './auth.http.service';
import { metaReducers, reducer } from './auth.reducer';
import { AuthService } from './auth.service';

@NgModule({
  imports: [
    StoreModule.forFeature(storeKey, reducer, { metaReducers }),
    EffectsModule.forFeature([AuthEffects]),
    StorageModule,
  ],
  providers: [AuthHttpService, AuthService],
})
export class AuthStoreModule {}
