import { Address } from '@bend/store-shared';

export const addressTemplate = (address: Address): string => {
  // need destructuring to be able to order the elements
  const { name, addressName, zipCode, city, additionalDetails } = address;
  // order items address
  const addressItems = [name, addressName, zipCode, city, additionalDetails];

  return (
    addressItems
      // remove empty item address
      .filter(Boolean)
      // concat all item address in a string
      .join(', ')
  );
};
