import { Component, HostBinding, Input } from '@angular/core';

import { PopProductTag } from '../../product-catalog';

@Component({
  selector: 'widgets-old-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent {
  /*
   * The list of tags
   * */
  @Input() tags: { tag: PopProductTag }[];
  /*
   * By default shows only first line of tags
   * In true show all tags
   * */
  @Input() showAll = false;

  @HostBinding('style.height')
  private get hostHeight(): string {
    return this.showAll ? 'auto' : '20px';
  }
}
