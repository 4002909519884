export interface SmsActionCall {
  actionMessage: string;
  addTableName: boolean;
  body: string;
  checked: boolean;
  description: string;
  directMessage: boolean;
  sms: string;
  title: string;
  transmitionType: TransmitionType.SMS;
  type: ActionType;
  icon: string;
  id?: number;
}

export interface TelephoneActionCall {
  addTableName: boolean;
  checked: boolean;
  directMessage: boolean;
  telephone: string;
  title: string;
  transmitionType: TransmitionType.TELEPHONE;
  type: ActionType;
  icon: string;
  id?: number;
}

export interface EmailActionCall {
  actionMessage: string;
  addTableName: boolean;
  body: string;
  checked: boolean;
  description: string;
  directMessage: boolean;
  email: string;
  from: string;
  subject: string;
  title: string;
  transmitionType: TransmitionType.EMAIL;
  type: ActionType;
  icon: string;
  id?: number;
}

export interface PosActionCall {
  actionMessage: string;
  addTableName: boolean;
  body: string;
  checked: boolean;
  description: string;
  directMessage: boolean;
  pos: string;
  title: string;
  transmitionType: TransmitionType.POS;
  type: ActionType;
  icon: string;
  id?: number;
}

export interface DesignerActionCall {
  actionMessage: string;
  addTableName: boolean;
  checked: boolean;
  description: string;
  directMessage: boolean;
  title: string;
  transmitionType: TransmitionType.DESIGNER;
  type: ActionType;
  icon: string;
  id?: number;
}

export class BottomBarActionCall {
  display = false;
  title: string = null;
  popupHeader: string = null;
  icon: string = null;
  actionButtons: ActionCall[] = [];
}

export enum TransmitionType {
  TELEPHONE = 'telephone',
  SMS = 'sms',
  EMAIL = 'email',
  POS = 'pos',
  DESIGNER = 'designer',
}

export enum ActionType {
  BREAD = 'bread',
  WATER = 'water',
  CHECK = 'check',
  WAITER = 'waiter',
  CUSTOM = 'custom',
}

export type ActionCall = SmsActionCall | TelephoneActionCall | EmailActionCall | PosActionCall | DesignerActionCall;
