import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';

import { actions as itemsActions } from '../order-items/order-items.actions';
import { actions as locationsActions } from '../order-locations/order-locations.actions';
import { actions as menuItemsActions } from '../order-menu-items/order-menu-items.actions';
import { actions as itemsOptionsActions } from '../order-options/order-options.actions';
import { actions as usersActions } from '../order-users/order-users.actions';
import {
  Address,
  ArrayStore,
  CustomerInfo,
  DictionaryStore,
  ifPluck,
  Order,
  OrderDetails,
  OrderUpdated,
  StoreService,
  TotalInfo,
} from '../shared';
import { actions } from './order.actions';
import { OrderHttpService } from './order.http.service';
import * as selectors from './order.selectors';
import { State } from './order.type';

@Injectable()
export class OrderService extends StoreService<State> implements DictionaryStore<State> {
  isLoading: Observable<boolean>;
  currentIsLoading: Observable<boolean>;
  isLoaded: Observable<boolean>;
  isLoadingHistory: Observable<boolean>;
  errorCode: Observable<null | string>;
  currentErrorCode: Observable<null | string>;
  currentId: Observable<Order['id']>;

  ids: ArrayStore<Order['id']>;
  entities: DictionaryStore<Dictionary<Order>>;

  order: Observable<Order>;
  orders: Observable<Order[]>;

  id: Observable<number>;
  orderDisplayId: Observable<number>;
  currentOrderUserId: Observable<number>;
  status: Observable<string>;
  updatedAt: Observable<Date>;
  wasOrdered: Observable<boolean>;
  totalInfo: DictionaryStore<TotalInfo>;
  currentAddress: DictionaryStore<Address>;
  currentCustomerInfo: DictionaryStore<CustomerInfo>;
  currentDetails: DictionaryStore<OrderDetails>;

  constructor(_store: Store<State>, _actions: Actions, private _orderHttp: OrderHttpService) {
    super(_store, _actions, selectors);

    this.currentId = this._store.pipe(select(selectors.currentId));
    this.orders = this._store.pipe(select(selectors.orders));
    this.isLoadingHistory = this._store.pipe(select(selectors.isLoadingHistory));

    this.order = this._store.pipe(select(selectors.currentOrder));

    this.id = this.order.pipe(ifPluck('id'));
    this.orderDisplayId = this.order.pipe(ifPluck('orderDisplayId'));
    this.currentOrderUserId = this.order.pipe(ifPluck('currentOrderUserId'));
    this.status = this.order.pipe(ifPluck('status'));
    this.updatedAt = this.order.pipe(ifPluck('updatedAt'));
    this.wasOrdered = this.order.pipe(ifPluck('wasOrdered'));
    this.totalInfo = this._totalInfo;
    this.currentAddress = this._currentAddress;
    this.currentCustomerInfo = this._currentCustomerInfo;
    this.currentDetails = this._currentDetails;

    this.isLoading = this._store.pipe(select(selectors.stateSelector), ifPluck('isLoading'));

    this.currentIsLoading = this._store.pipe(select(selectors.currentIsLoading));

    this.isLoaded = this._store.pipe(select(selectors.isLoaded));

    this.errorCode = this._store.pipe(select(selectors.stateSelector), ifPluck('errorCode'));

    this.currentErrorCode = this._store.pipe(select(selectors.currentErrorCode));
  }

  get orderLastUpdate(): Observable<OrderUpdated> {
    return this.currentId.pipe(
      filter(id => !!id),
      switchMap(orderId => this._orderHttp.orderLastUpdated(orderId)),
    );
  }

  getOrders(): Observable<boolean> {
    this._store.dispatch(actions.getOrders());

    return this._finishedAction(actions.getOrdersSuccess, actions.getOrderError);
  }

  getOrder(): Observable<boolean> {
    this._store.dispatch(actions.getOrder());

    return this._finishedAction(actions.getOrderSuccess, actions.getOrderIsLoaded, actions.getOrderError);
  }

  orderById(orderId: number): Observable<Order> {
    return this._store.pipe(select(selectors.entities), ifPluck(orderId));
  }

  update({ updatedAt }: OrderUpdated): void {
    this._callByOrderId(id => this._store.dispatch(actions.updateOrder({ id, updatedAt })));
  }

  updateOrderItem(itemId: number, quantity: number): Observable<boolean> {
    this._callByOrderId(id => this._store.dispatch(actions.updateOrderItem({ id, itemId, quantity })));

    return this._finishedAction(actions.updateOrderItemSuccess, actions.updateOrderItemError);
  }

  addOrderDetails(details: OrderDetails): void {
    this._callByOrderId(id => this._store.dispatch(actions.updateOrderDetails({ id, details })));
  }

  addOrderUserDetails(customerInfo: CustomerInfo): void {
    this._callByOrderId(id => this._store.dispatch(actions.updateOrderUserDetails({ id, customerInfo })));
  }

  removeOrderItem(orderItemId: number, orderUserId: number): Observable<boolean> {
    this._callByOrderId(id => this._store.dispatch(actions.removeOrderItem({ id, orderUserId, itemId: orderItemId })));

    return this._finishedAction(actions.removeOrderItemSuccess, actions.removeOrderItemError);
  }

  newOrder(): void {
    this.reset();
    this._store.dispatch(actions.newOrder());
  }

  reset(): void {
    this._store.dispatch(actions.reset());
    this._store.dispatch(itemsActions.reset());
    this._store.dispatch(locationsActions.reset());
    this._store.dispatch(menuItemsActions.reset());
    this._store.dispatch(itemsOptionsActions.reset());
    this._store.dispatch(usersActions.reset());
  }

  orderTotalInfo(orderId: number): DictionaryStore<TotalInfo> {
    const totalInfo = this.orderById(orderId).pipe(ifPluck('totalInfo'));

    return {
      all: totalInfo,
      total: totalInfo.pipe(ifPluck('total')),
      totalPaid: totalInfo.pipe(ifPluck('totalPaid')),
      totalWithTips: totalInfo.pipe(ifPluck('totalWithTips')),
      totalTips: totalInfo.pipe(ifPluck('totalTips')),
    };
  }

  details(orderId: number): DictionaryStore<OrderDetails> {
    const details = this.orderById(orderId).pipe(ifPluck('details'));

    return {
      all: details,
      collectType: details.pipe(ifPluck('collectType')),
      placeType: details.pipe(ifPluck('placeType')),
      nrCutlery: details.pipe(ifPluck('nrCutlery')),
      additionalDetails: details.pipe(ifPluck('additionalDetails')),
      orderTime: details.pipe(ifPluck('orderTime')),
    };
  }

  customerInfo(orderId: number): DictionaryStore<CustomerInfo> {
    const customerInfo = this.orderById(orderId).pipe(ifPluck('customerInfo'));

    return {
      all: customerInfo,
      name: customerInfo.pipe(ifPluck('name')),
      email: customerInfo.pipe(ifPluck('email')),
      phone: customerInfo.pipe(ifPluck('phone')),
      gender: customerInfo.pipe(ifPluck('gender')),
      dateOfBirth: customerInfo.pipe(ifPluck('dateOfBirth')),
      birthPlace: customerInfo.pipe(ifPluck('birthPlace')),
      firstName: customerInfo.pipe(ifPluck('firstName')),
      lastName: customerInfo.pipe(ifPluck('lastName')),
      shopIdentifier: customerInfo.pipe(ifPluck('shopIdentifier')),
    };
  }

  address(orderId: number): DictionaryStore<Address> {
    const address = this.orderById(orderId).pipe(ifPluck('address'));

    return {
      all: address,
      name: address.pipe(ifPluck('name')),
      additionalDetails: address.pipe(ifPluck('additionalDetails')),
      addressName: address.pipe(ifPluck('addressName')),
      city: address.pipe(ifPluck('city')),
      street: address.pipe(ifPluck('street')),
      streetNumber: address.pipe(ifPluck('streetNumber')),
      zipCode: address.pipe(ifPluck('zipCode')),
    };
  }

  get _currentDetails(): DictionaryStore<OrderDetails> {
    const details = this._store.pipe(select(selectors.currentOrder), ifPluck('details'));

    return {
      all: details,
      collectType: details.pipe(ifPluck('collectType')),
      placeType: details.pipe(ifPluck('placeType')),
      nrCutlery: details.pipe(ifPluck('nrCutlery')),
      additionalDetails: details.pipe(ifPluck('additionalDetails')),
      orderTime: details.pipe(ifPluck('orderTime')),
    };
  }

  private get _totalInfo(): DictionaryStore<TotalInfo> {
    const totalInfo = this._store.pipe(select(selectors.currentOrder), ifPluck('totalInfo'));

    return {
      all: totalInfo,
      total: totalInfo.pipe(ifPluck('total')),
      totalPaid: totalInfo.pipe(ifPluck('totalPaid')),
      totalWithTips: totalInfo.pipe(ifPluck('totalWithTips')),
      totalTips: totalInfo.pipe(ifPluck('totalTips')),
    };
  }

  private get _currentAddress(): DictionaryStore<Address> {
    const address = this._store.pipe(select(selectors.currentOrder), ifPluck('address'));

    return {
      all: address,
      name: address.pipe(ifPluck('name')),
      additionalDetails: address.pipe(ifPluck('additionalDetails')),
      addressName: address.pipe(ifPluck('addressName')),
      city: address.pipe(ifPluck('city')),
      street: address.pipe(ifPluck('street')),
      streetNumber: address.pipe(ifPluck('streetNumber')),
      zipCode: address.pipe(ifPluck('zipCode')),
    };
  }

  private get _currentCustomerInfo(): DictionaryStore<CustomerInfo> {
    const customerInfo = this._store.pipe(select(selectors.currentOrder), ifPluck('customerInfo'));

    return {
      all: customerInfo,
      name: customerInfo.pipe(ifPluck('name')),
      email: customerInfo.pipe(ifPluck('email')),
      phone: customerInfo.pipe(ifPluck('phone')),
      gender: customerInfo.pipe(ifPluck('gender')),
      dateOfBirth: customerInfo.pipe(ifPluck('dateOfBirth')),
      birthPlace: customerInfo.pipe(ifPluck('birthPlace')),
      firstName: customerInfo.pipe(ifPluck('firstName')),
      lastName: customerInfo.pipe(ifPluck('lastName')),
      shopIdentifier: customerInfo.pipe(ifPluck('shopIdentifier')),
    };
  }

  private _callByOrderId(fn: (orderId: Order['id']) => void): void {
    this.currentId.pipe(first(), tap(fn)).subscribe();
  }
}
