import { Injectable } from '@angular/core';

import { PopWidthStyle } from '../../widget';

@Injectable()
export class StyleService {
  getWidthStyle(width: string): PopWidthStyle {
    const widthStyle = new PopWidthStyle();

    if (width === 'full') {
      widthStyle.marginLeft = 0;
      widthStyle.marginRight = 0;
    } else if (/^\d+$/.exec(width)) {
      widthStyle.width = width;
    }

    return widthStyle;
  }
}
