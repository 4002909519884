import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { IconModule } from '@bend/icon';

import { EmailInputComponent, NameInputComponent, PhoneNumberComponent } from './components';
import { CountriesDialogComponent } from './components/countries-dialog/countries-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    IconModule,
    TranslateModule.forChild(),
  ],
  declarations: [EmailInputComponent, NameInputComponent, PhoneNumberComponent, CountriesDialogComponent],
  exports: [EmailInputComponent, NameInputComponent, PhoneNumberComponent],
})
export class CustomInputsModule {}
