import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { PostWidget } from './post';

@Component({
  selector: 'pop-widget-edit-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditPostComponent extends WidgetBaseComponent<PostWidget> implements OnInit {
  static widgetName = 'facebook_post';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  get url(): UntypedFormControl {
    return this.form.get('url') as UntypedFormControl;
  }

  markAsTouchedFieldsToValidate(): void {
    this.url.markAsTouched();
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        url: [this.widget.attributes.url, Validators.required],
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
