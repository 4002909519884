<div class="dialog-content">
  <div class="actions-title">{{ data.popupHeader }}</div>
  <div class="actions-container">
    <ng-container *ngFor="let action of data.actionButtons">
      <ng-container *ngIf="action?.title || action?.icon">
        <a class="action" *ngIf="action?.transmitionType === 'telephone'" [href]="'tel:' + action?.telephone">
          <div class="action-content">
            <img *ngIf="action?.icon" class="action-img" alt="" [src]="action?.icon" height="70" />
            <small *ngIf="action?.title" class="action-label" [style.color]="action?.mainColor">
              {{ action?.title }}
            </small>
          </div>
        </a>
        <div class="action" (click)="handleClick(action)" *ngIf="action?.transmitionType !== 'telephone'">
          <div class="action-content">
            <img *ngIf="action?.icon" class="action-img" alt="" [src]="action?.icon" height="70" />
            <small *ngIf="action?.title" class="action-label" [style.color]="action?.mainColor">
              {{ action?.title }}
            </small>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button mat-dialog-close class="close-dialog-btn" title="close" [style.color]="data.mainColor">
    <svg [style.fill]="data.mainColor" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16" width="16">
      <path
        d="M15.56 13.44L10.12 8l5.44-5.44a1.496 1.496 0 0 0 0-2.12 1.496 1.496 0 0 0-2.12 0L8 5.88 2.56.44a1.496 1.496 0 0 0-2.12 0 1.496 1.496 0 0 0 0 2.12L5.88 8 .44 13.44a1.496 1.496 0 0 0 0 2.12 1.496 1.496 0 0 0 2.12 0L8 10.12l5.44 5.44a1.496 1.496 0 0 0 2.12 0 1.503 1.503 0 0 0 0-2.12z"
      />
    </svg>
  </button>
</div>
