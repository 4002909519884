import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { FollowWidget } from './follow';

@Component({
  selector: 'pop-widget-edit-follow',
  templateUrl: './follow.component.html',
  styleUrls: ['./follow.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditFollowComponent extends WidgetBaseComponent<FollowWidget> implements OnInit {
  static widgetName = 'follow';

  // Temporary solution
  private readonly _primaryColor = '#3296b1';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    const { facebook, instagram, linkedin, twitter, tripadvisor, title } = this.widget.attributes.social;
    const { backgroundColor, textColor } = this.widget.attributes.style;
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        social: this.formBuilder.group({
          facebook: this.formBuilder.group({ icon: facebook.icon, link: facebook.link }),
          instagram: this.formBuilder.group({ icon: instagram.icon, link: instagram.link }),
          linkedin: this.formBuilder.group({ icon: linkedin.icon, link: linkedin.link }),
          twitter: this.formBuilder.group({ icon: twitter.icon, link: twitter.link }),
          tripadvisor: this.formBuilder.group({ icon: tripadvisor.icon, link: tripadvisor.link }),
          title: title,
        }),
        style: this.formBuilder.group({
          backgroundColor: backgroundColor ?? this._primaryColor,
          textColor: textColor,
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  onBackgroundColorChange($event: string): void {
    this.form.get('style.backgroundColor').patchValue($event);
  }

  onTextColorChange($event: string): void {
    this.form.get('style.textColor').patchValue($event);
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get textColor(): UntypedFormControl {
    return this.form.get('style.textColor') as UntypedFormControl;
  }
}
