<ng-container *ngTemplateOutlet="href ? link : button"></ng-container>

<ng-template #link>
  <a
    mat-flat-button
    class="button"
    [ngClass]="'button--' + size"
    [color]="color"
    [disableRipple]="disableRipple"
    [disabled]="disabled"
    [href]="href"
    [target]="target"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #button>
  <button
    mat-flat-button
    class="button"
    [ngClass]="'button--' + size"
    [color]="color"
    [disabled]="disabled"
    [type]="type"
    [disableRipple]="disableRipple"
    [type]="type"
    (click)="onClick()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-template>

<ng-template #content>
  <mat-icon @appear *ngIf="isLoading" class="button__icon--margin" [inline]="true">
    <mat-progress-spinner mode="indeterminate" [diameter]="15"></mat-progress-spinner>
  </mat-icon>

  <mat-icon @appear *ngIf="icon && !isLoading" class="button__icon--margin" [inline]="true" style="font-size: 18px">
    {{ icon }}
  </mat-icon>

  <b class="button__text">
    <ng-content></ng-content>
  </b>
</ng-template>
