import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NavigationService } from '../../services';

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBackComponent {
  constructor(private _navigation: NavigationService) {}

  back(): void {
    this._navigation.back();
  }
}
