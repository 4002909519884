import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { actions } from './credit-history.actions';
import { CreditHistoryHttpService } from './credit-history.http.service';

@Injectable()
export class CreditHistoryEffects {
  constructor(private _actions$: Actions, private _userHttpService: CreditHistoryHttpService) {}

  getUserTransaction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getUserTransactions),
      switchMap(({ userId, limit, offset }) =>
        this._userHttpService.getUsersTransactions({ limit, offset, userId }).pipe(
          map(response => actions.getUserTransactionsSuccess(response)),
          catchError(() => of(actions.getUserTransactionsError())),
        ),
      ),
    ),
  );
}
