import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItem, OrderItemType, PromoCodeType } from '@bend/store-shared';

import { DialogKey } from '../../../../types';
import { OrderDialogDisplayedService } from '../order-dialog-displayed/order-dialog-displayed.service';

@Injectable()
export class OrderDialogPromoCodeService {
  constructor(private _dialog: DialogService, private _orderDialogDisplayed: OrderDialogDisplayedService) {}

  behavior(): MonoTypeOperatorFunction<[OrderItem<OrderItemType.Promo>, number]> {
    return tap(
      ([
        {
          itemMeta: { promoStatus, promoType },
        },
        orderId,
      ]) => {
        if (promoStatus !== 'success') return;

        // disable showing dialog if promotype is pos and board view
        if (promoType === PromoCodeType.Pos) return;

        if (this._checkUpdatedAtItem(new Date(orderId), DialogKey.PromoSuccess)) return;

        this._dialog.info({ message: `CART.PROMO.SUCCESS` });
      },
    );
  }

  errorBehavior(): MonoTypeOperatorFunction<[OrderItem<OrderItemType.Promo>, number]> {
    return tap(
      ([
        {
          updatedAt,
          itemMeta: { promoStatus, errorCode, promoType },
        },
      ]) => {
        if (promoStatus !== 'failure') return;

        // disable showing dialog if promotype is pos and board view
        if (promoType === PromoCodeType.Pos) return;

        if (this._checkUpdatedAtItem(updatedAt, DialogKey.PromoError)) return;

        this._dialog.error({ message: `CART.PROMO.ERROR.${errorCode}` });
      },
    );
  }

  /**
   * @description check in dialog is showed
   */
  private _checkUpdatedAtItem(updatedAt: Date, key: DialogKey): boolean {
    return this._orderDialogDisplayed.check(updatedAt, 0, key);
  }
}
