import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { LoyaltyShareWidget } from './types';

@Component({
  selector: 'pop-widget-loyalty-share',
  templateUrl: './loyalty-share.component.html',
  styleUrls: ['./loyalty-share.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditLoyaltyShareComponent extends WidgetBaseComponent<LoyaltyShareWidget> implements OnInit {
  static widgetName = 'loyalty_share';

  host: string;
  pages$: Observable<Page[]>;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
    this.pages$ = widgetService.getPages().pipe(map(({ pages: { single } }) => single));

    this.host = widgetService.appHost;
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        message: [this.widget.attributes.message],
        text: [this.widget.attributes.text],
        url: [this.widget.attributes.url],
        style: this.formBuilder.group({
          margin: this.formBuilder.group({
            top: [this.widget.attributes.style.margin.top],
            bottom: [this.widget.attributes.style.margin.bottom],
          }),
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  get url(): UntypedFormControl {
    return this.form.get('url') as UntypedFormControl;
  }
}
