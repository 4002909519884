import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageService } from '@bend/page';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {
  title$: Observable<string>;
  params$: Observable<Record<string, string | number>>;

  constructor(private _page: PageService) {
    this.title$ = this._page.options.pipe(map(options => options.title));
    this.params$ = this._page.options.pipe(map(options => options.params));
  }
}
