import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '../shared';
import { GetSmileHistory, GetSmileHistorySuccess } from './smile-history.type';

@Injectable()
export class SmileHistoryHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getSmileHistory({ limit, skip, smileId }: GetSmileHistory): Observable<GetSmileHistorySuccess> {
    return this._api.smileHistory('v1').pipe(
      switchMap(api =>
        this._http.get<GetSmileHistorySuccess>(api, {
          params: {
            smileId: smileId.toString(),
            $limit: limit.toString(),
            $skip: skip.toString(),
          },
        }),
      ),
    );
  }
}
