export interface CustomCondition {
  key: string;
  rules: string[];
  type: QueryParamConditionType;
}

export interface TimeCondition {
  type: TimeConditionType;
  rules: TimeInterval[];
}

type TimeConditionType = 'time';
type QueryParamConditionType = 'queryParam';

export enum ConditionTypes {
  TIME = 'time',
  QUERY_PARAM = 'queryParam',
}

export interface TimeInterval {
  weekday: number;
  from: string;
  to: string;
}

export interface IntervalsOnDay {
  day: number;
  values: TimeInterval[];
}
