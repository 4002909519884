<div *ngIf="isValid; else elseBlock" class="widget-video">
  <img *ngIf="attributes?.isEditMode; else elseBlock1" alt="" [src]="thumbnailUrl" width="100%" class="thumbnail" />
  <ng-template #elseBlock1>
    <div class="video-content">
      <iframe
        class="content-iframe"
        id="iframe"
        #iframe
        width="560"
        height="349"
        alt=""
        [src]="safeUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </ng-template>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-video no-valid-msg">{{ noValidMessage }}</div>
</ng-template>
