import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, tap } from 'rxjs/operators';

import { HistoryService, parseUrl } from '@bend/history';
import { PageService } from '@bend/page';
import { ScrollService } from '@bend/scroll';
import { SettingsService } from '@bend/store';

@Component({ template: '' })
export abstract class BarTopGenericComponent implements AfterViewInit, OnDestroy {
  @ViewChild('bar') bar: ElementRef<HTMLElement>;

  sidenav$: Observable<boolean>;
  isShowBurger$: Observable<boolean>;
  scrollWidth$: Observable<string>;

  protected _height: number;
  protected _target: number;
  protected _current: number;
  protected _last: number;
  protected _rafId: number;
  protected _subscription: Subscription;

  constructor(
    protected _page: PageService,
    protected _settings: SettingsService,
    protected _history: HistoryService,
    protected _scroll: ScrollService,
    protected _topBarHeaderHeight: BehaviorSubject<number>,
    protected _elementRef: ElementRef,
  ) {
    this.sidenav$ = this._page.sidenav;
    this.isShowBurger$ = this._isShowBurger;
    this.scrollWidth$ = this._page.scrollWidth.pipe(map(scroll => `${scroll}px`));

    this._height = 0;
    this._target = 0;
    this._current = 0;
    this._last = 0;

    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    this._height = this.bar?.nativeElement.offsetHeight;

    this._subscription.add(this._showTopBarBySidenav());
    this._topBarHeaderHeight.next(this._elementRef.nativeElement.clientHeight);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  burgerChanged(): void {
    this._page.sidenavToggle();
  }

  protected get _isShowBurger(): Observable<boolean> {
    return this._settings.widgetTopBar.pipe(map(topBar => topBar?.allowUserProfile));
  }

  protected get _isHome(): Observable<boolean> {
    return this._history.currentUrlChanged.pipe(
      map(url => {
        const currentPaths = parseUrl(url);
        const homePaths = parseUrl(this._history.home || '');

        if (currentPaths.length !== homePaths.length) return false;

        const [, currentPageId] = currentPaths;
        const [, homePageId] = homePaths;
        /**
         * if is not equal if home url show the home button
         */
        return currentPageId === homePageId;
      }),
    );
  }

  protected get _haveBack(): Observable<boolean> {
    return this._history.stackSizeChanged.pipe(
      /**
       * if the user has only one item in the stack,
       * this means that it is on the main page
       */
      map(size => size > 1),
    );
  }

  protected _hideWhenIsScrolled(element: Window | HTMLElement): Subscription {
    /**
     * listen page scroll event
     */
    return fromEvent(element, 'scroll')
      .pipe(
        /**
         * filter when bar is not showed
         */
        filter(() => !!this.bar?.nativeElement),
        /**
         * get position for current scroll
         */
        map(() => (element instanceof Window ? element.scrollY : element.scrollTop)),
        /**
         * skip the same position for scroll
         */
        distinctUntilChanged(),
        /**
         * pairwise needs the first two events to be able to emit events
         * that's why we use startWith
         */
        startWith(0),
        /**
         * we use pairwise to know the previous position
         */
        pairwise(),
        tap(([prev, current]) => {
          /**
           * if on the previous page the bar was not completely hidden
           * then we display it on the current page as well
           *
           * prev < bar height = scroll < bar height
           *
           * if the difference between the current position and the previous one is greater than height,
           * then it means that you have navigated from one page to another
           *
           * prev = 0
           * current = 999
           * page is changed
           */
          if (prev < this._height && current - prev > this._height) return;
          /**
           * scroll on safari go in negative values
           * we need to skip this to prevent to hide bar
           */
          if (current < 0) return;
          /**
           * calculate new position for bar
           */
          const newTopByScroll = this._target - (current - prev);
          /**
           * tests if the scroll direction is down
           */
          const scrollDown = current > prev;

          const newTopByOffset = scrollDown
            ? /**
               * calculate position when scroll go down
               */
              this._topByDown(newTopByScroll, this._height)
            : /**
               * calculate position when scroll go up
               */
              this._topByUp(newTopByScroll);
          /**
           * set new target
           */
          this._target = newTopByOffset;
          /**
           * check if requestAnimationFrame is not running
           */
          if (!this._rafId) this._updateAnimation();
        }),
      )
      .subscribe();
  }

  protected _showTopBarBySidenav(): Subscription {
    return this._page.sidenav
      .pipe(
        /**
         * when user open sidenav
         */
        filter(Boolean),
        tap(() => {
          /**
           * show top bar when bar is not show entire
           */
          this._target = 0;
          this._updateAnimation();
        }),
      )
      .subscribe();
  }

  protected _topByDown(position: number, height: number): number {
    /**
     * if position is heights that height
     * return negative height
     *
     * example: position > height
     * height = 50
     * scroll = 80
     * to = -50
     *
     * example: position < height
     * height = 50
     * scroll = 30
     * to = -30
     */
    return Math.abs(position) > height ? -height : position;
  }

  protected _topByUp(position: number): number {
    /**
     * if position is heights that 0 we return 0
     */
    return position > 0 ? 0 : position;
  }

  protected _updateAnimation(): void {
    /**
     * difference between `target` and `current` scroll position
     */
    const diff = this._target - this._current;
    /**
     * ease or speed for moving from `current` to `target`
     */
    const ease = 0.25;
    /**
     * `delta` is the value for adding to the `current` scroll position
     * if `diff < 0.1`, make `delta = 0`, so the animation would not be endless
     */
    const delta = Math.abs(diff) < 0.1 ? 0 : diff * ease;
    if (delta) {
      /**
       * if `delta !== 0`
       * update `current` scroll position
       */
      this._current += delta;
      /**
       * round value for better performance
       */
      this._current = parseFloat(this._current.toFixed(2));
      /**
       * call `_updateAnimation` again, using `requestAnimationFrame`
       */
      this._rafId = requestAnimationFrame(this._updateAnimation.bind(this));
    } else {
      /**
       * if `delta === 0`
       */
      this._current = this._target;
      /**
       * cancel requestAnimationFrame to remove memory leak
       */
      cancelAnimationFrame(this._rafId);
      /**
       * set rafId with null to now allow to request _updateAnimation from scroll event
       */
      this._rafId = null;
    }
    /**
     * round value to increase performance
     */
    const last = Math.round(this._current);
    /**
     * skip the same value to increase performance
     */
    if (this._last === last) return;

    this._last = last;
    /**
     *  Set the CSS `transform` corresponding to the custom scroll effect
     */
    this.bar.nativeElement.style.marginTop = `${this._last}px`;
  }
}
