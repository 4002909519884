import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

const COLORS = {
  primary: 'var(--var-color-primary)',
  accent: 'var(--var-color-accent)',
  warn: 'var(--var-color-warn)',
};

@Component({
  selector: 'app-input-spinner',
  templateUrl: './input-spinner.component.html',
  styleUrls: ['./input-spinner.component.scss'],
})
export class InputSpinnerComponent {
  @Input() color: ThemePalette = 'primary';
  @Input() diameter?: number;

  constructor() {}

  assignColor(): object {
    if (this.diameter) {
      return {
        'border-top': `2px solid ${COLORS[this.color]}`,
        'border-inline-end': `2px solid ${COLORS[this.color]}`,
        'width': `${this.diameter}px`,
        'height': `${this.diameter}px`,
      };
    }
    return { 'border-top': `2px solid ${COLORS[this.color]}`, 'border-inline-end': `2px solid ${COLORS[this.color]}` };
  }
}
