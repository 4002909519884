import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { map, tap } from 'rxjs';

import { ItemUnavailabilityDisplay } from '@bend/store';

import { PopOptionGroup, PopProduct } from '../../product-catalog';
import { WidgetProductCatalogHelperService } from '../../product-catalog-helper.service';
import { PricesService, RecommendationsMenuService } from '../../services';

@Component({
  selector: 'product-catalog-recommendation-menu',
  templateUrl: './recommendation-menu.component.html',
  styleUrls: ['./recommendation-menu.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationMenuComponent implements OnInit {
  @Input() recommendation: PopProduct;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  protected readonly itemUnavailabilityDisplayEnum = ItemUnavailabilityDisplay;

  /* From projects/widgets-old/src/lib/product-catalog/product-details-dialog/product-details-dialog.component.ts */
  showErrors = this.recommendationMenu.showErrors;
  errorMessages: number[] = this.recommendationMenu.errorMessages;
  optionGroupQuantityError: number[] = this.recommendationMenu.optionGroupQuantityError;

  constructor(
    private productCatalogHelper: WidgetProductCatalogHelperService,
    private recommendationMenu: RecommendationsMenuService,
    private cdr: ChangeDetectorRef,
    private prices: PricesService,
  ) {}

  ngOnInit(): void {
    this.prices.flow
      .pipe(
        map(flow => ({
          ...this.recommendation,
          children: this.recommendation.children.map(child => ({
            ...this.prices.getUpdatedProductByFlow(child, flow),
            isSelected: false,
            quantity: 0,
          })),
          itemOptionGroups: [
            ...this.recommendation.itemOptionGroups.map(itemOptionGroup => ({
              ...itemOptionGroup,
              optionGroup: {
                ...itemOptionGroup.optionGroup,
                optionGroupSubitems: [
                  ...itemOptionGroup.optionGroup.optionGroupSubitems.map(optionGroupSubitem => ({
                    ...optionGroupSubitem,
                    item: {
                      ...this.prices.getUpdatedProductByFlow(optionGroupSubitem.item, flow),
                      quantity: 0,
                      quantityMultiplier: 0,
                    },
                  })),
                ],
              },
            })),
          ],
        })),
        tap(updatedRec => (this.recommendation = updatedRec)),
        tap(() => this.cdr.detectChanges()),
      )
      .subscribe();
  }

  selectChild(id: number): void {
    this.productCatalogHelper.toggleSubProduct(id, this.recommendation);
    this.cdr.detectChanges();
  }

  protected saveRecommendation(): void {
    this.recommendationMenu.pick(this.recommendation);
    this.updateData();
    this.cdr.detectChanges();
  }

  addOptionQuantity(item: PopProduct, optionGroup: PopOptionGroup): void {
    if (
      optionGroup.maxAllowedOptions &&
      this.productCatalogHelper.selectedOptionsCount(optionGroup) >= optionGroup.maxAllowedOptions
    )
      return;

    item.quantityMultiplier += 1;
    if (item.quantityMultiplier) {
      item.isSelected = true;
    }
  }

  decreasesOptionQuantity(item: PopProduct): void {
    if (item.quantityMultiplier <= 0) return;

    if (item.quantityMultiplier === 1) {
      item.isSelected = false;
    }

    item.quantityMultiplier -= 1;
  }

  selectOption(item: PopProduct, optionGroup: PopOptionGroup): void {
    this.productCatalogHelper.selectOption(item, optionGroup);
  }

  protected cancel(): void {
    this.recommendationMenu.hideMenu();
  }

  // Temporarily added for validation
  private updateData(): void {
    this.errorMessages = this.recommendationMenu.errorMessages;
    this.showErrors = this.recommendationMenu.showErrors;
    this.optionGroupQuantityError = this.recommendationMenu.optionGroupQuantityError;
  }
}
