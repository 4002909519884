import { PopSettings } from '../widget';

export interface WidgetActionCall {
  actionButtons: Action[];
  icon: string;
  isEditMode: boolean;
  mainColor: string;
  title: string;
  pageId: string;
  popupHeader: string;
  style: {
    borderRadius: number;
    fullWidth: boolean;
    margin: {
      top: number;
      bottom: number;
    };
    displayType: ActionCallDisplayType;
  };
  id: string;
  pop: PopSettings;
}

export interface Action {
  title?: string;
  icon?: string;
  description?: string;
  actionMessage?: string;
  type: ActionType;
  telephone: string;
  sms: string;
  email: string;
  body: string;
  addTableName: boolean;
  directMessage: boolean;
  _id: string;
  transmitionType?: string;
  mainColor?: string;
}

export interface ActionCallRequest {
  action: string;
  placeSlug: string;
}

export enum ActionType {
  STANDARD = 'standard',
  TELEPHONE = 'telephone',
  SMS = 'sms',
  EMAIL = 'email',
  FALLBACK = '',
}

export enum ActionCallDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}

export interface WidgetActionCallDialogParams {
  actionButtons: Action[];
  mainColor: string;
  pageId: string;
  popupHeader: string;
  place: string;
  id: string;
}
