// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { menus } from '../../../mock';
import { ApiVisitorService } from '../shared';

@Injectable()
export class ItemHttpService {
  constructor(
    // private _http: HttpClient,
    private _api: ApiVisitorService,
  ) {}

  getMenus(catalogId: number): Observable<any> {
    // TODO remove mocking data
    return this._api.catalogMenus('v2').pipe(
      // switchMap(api => this._http.get(api, { params: { catalogId } })),
      map(() => menus),
    );
  }
}
