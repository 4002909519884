import { ChangeDetectorRef, Component, Input, OnInit, Optional } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from '@bend/dialog';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { Action, WidgetActionCall } from './action-call';
import { WidgetActionCallService } from './action-call.service';
import { WidgetActionCallHelperService } from './action-call-helper.service';
import { WidgetActionCallDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'pop-widget-action-call',
  templateUrl: './action-call.component.html',
  styleUrls: ['./action-call.component.scss'],
})
export class WidgetActionCallComponent implements OnInit, WidgetComponent {
  static widgetName = 'action_call';
  @Input() attributes: WidgetActionCall;

  isValid: boolean;
  noValidMessage: string;
  place: string;
  isReadOnly: boolean;
  buttonColor: string;

  constructor(
    @Optional() private _dialog: DialogService,
    private _matDialog: MatDialog,
    private _warningService: WarningService,
    private _actionCallService: WidgetActionCallService,
    private _route: ActivatedRoute,
    private _helperService: WidgetActionCallHelperService,
    private _translate: TranslateService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.title) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetActionCallComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this._addMissingAttributes();
    this.isReadOnly = this.attributes.isEditMode || this.attributes.pop.validationStatus === PopValidationStatus.FAILED;
    this.buttonColor = this.isReadOnly ? 'grey' : this.attributes.mainColor;
    this.isValid = true;
    this.place = this._route.snapshot.queryParamMap.get('place');
    if (!this.place) {
      this._translate.get('ACTION_CALL.NO_TABLE').subscribe(res => {
        this.place = res;
        this._cdr.detectChanges();
      });
    }
  }

  handleClick(): void {
    if (this.isReadOnly || !this.attributes.actionButtons || !this.attributes.actionButtons.length) {
      return;
    }

    if (this.attributes.actionButtons.length > 1) {
      this._openDialog();
      return;
    }

    const action = this.attributes.actionButtons[0];
    this._handleSingleAction(action);
  }

  private _openDialog(): void {
    this._matDialog.open(WidgetActionCallDialogComponent, {
      width: '95%',
      maxWidth: '95%',
      data: {
        actionButtons: this.attributes.actionButtons,
        mainColor: this.attributes.mainColor,
        pageId: this.attributes.pageId,
        popupHeader: this.attributes.popupHeader,
        place: this.place,
        id: this.attributes.id,
      },
    });
  }

  private _handleSingleAction(action: Action): void {
    if (action.transmitionType === 'telephone') {
      window.location.href = `tel:${action.telephone}`;
      return;
    }

    if (action.directMessage) {
      this._sendActionCall(action._id, action.actionMessage);
      return;
    }

    const url = this._helperService.getSingleActionUrl(action, this.place);
    if (url) {
      window.open(url, '_self');
    } else {
      this._sendActionCall(action._id, action.actionMessage);
    }
  }

  private _sendActionCall(actionId: string, message: string): void {
    const place = this.place;
    const { pageId, id } = this.attributes;

    this._actionCallService.callAction(pageId, id, actionId, place).subscribe(() => {
      this._dialog.success({ message });
      this._cdr.detectChanges();
    });
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }
  }
}
