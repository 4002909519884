import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiDesignerService, SettingsApp } from '../shared';

@Injectable()
export class SettingsHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getApp(): Observable<SettingsApp> {
    return this._api.appSettings('v1').pipe(
      switchMap(url => this._http.get<SettingsApp>(url)),
      this._filterPromoCodeSettings(),
    );
  }

  patchApp(data: Partial<SettingsApp>): Observable<SettingsApp> {
    return this._api.appSettings('v1').pipe(
      switchMap(url => this._http.patch<SettingsApp>(url, data)),
      this._filterPromoCodeSettings(),
    );
  }

  deleteApplication(): Observable<void> {
    return this._api.app('v1').pipe(switchMap(api => this._http.delete<void>(api)));
  }

  private _filterPromoCodeSettings(): OperatorFunction<SettingsApp, SettingsApp> {
    return map(settings => {
      const { promoCode, ...restSettings } = settings;
      const { type, code, enabled } = promoCode;

      return { ...restSettings, promoCode: { type, code, enabled } };
    });
  }
}
