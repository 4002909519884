import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ViewportModule } from '@bend/viewport';

import { BarTopPageComponent } from './bar-top-page.component';
import { BarTopPageViewsResolver } from './resolvers';

@NgModule({
  declarations: [BarTopPageComponent],
  imports: [CommonModule, ViewportModule],
  exports: [BarTopPageComponent],
  providers: [BarTopPageViewsResolver],
})
export class BarTopPageModule {}
