import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { GenericPromoCode, StoreService } from '../shared';
import { actions } from './promo-codes.actions';
import * as selectors from './promo-codes.selectors';
import { State } from './promo-codes.type';

@Injectable()
export class PromoCodesService extends StoreService<State> {
  constructor(protected override _store: Store<State>, protected override _actions: Actions) {
    super(_store, _actions, selectors);
  }

  get promoCodes(): Observable<GenericPromoCode[]> {
    return this._store.pipe(select(selectors.promoCodes));
  }

  get lastAddedPromoCodeId(): Observable<number> {
    return this._store.pipe(
      select(selectors.promoCodes),
      filter(promoCodes => !!promoCodes.length),
      map(([{ id }]) => id),
    );
  }

  get isLoadedByAppSlug(): Observable<string> {
    return this._store.pipe(select(selectors.isLoadedByAppSlug));
  }

  get total(): Observable<number> {
    return this._store.pipe(select(selectors.total));
  }

  get limit(): Observable<number> {
    return this._store.pipe(select(selectors.limit));
  }

  get error(): Observable<string> {
    return this._store.pipe(select(selectors.error));
  }

  getPromoCode(promoCodeId: number): Observable<GenericPromoCode> {
    return this._store.pipe(
      select(selectors.entities),
      map(entities => entities[promoCodeId]),
    );
  }

  getPromoCodes(appSlug: string, limit: number, offset: number): Observable<boolean> {
    this._store.dispatch(actions.getPromoCodes({ appSlug, limit, offset }));

    return this._finishedAction(actions.getPromoCodesSuccess, actions.getPromoCodesError);
  }

  addPromoCode(promoCode: GenericPromoCode): Observable<boolean> {
    this._store.dispatch(actions.addPromoCode({ promoCode }));

    return this._finishedAction(actions.addPromoCodeSuccess, actions.addPromoCodeError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.error.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  savePromoCode(promoCode: GenericPromoCode, promoCodeId: number): Observable<boolean> {
    this._store.dispatch(actions.savePromoCode({ promoCode, promoCodeId }));

    return this._finishedAction(actions.savePromoCodeSuccess, actions.savePromoCodeError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.error.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  deletePromoCode(promoCodeId: number): Observable<boolean> {
    this._store.dispatch(actions.deletePromoCode({ promoCodeId }));

    return this._finishedAction(actions.deletePromoCodeSuccess, actions.deletePromoCodeError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this.error.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }
}
