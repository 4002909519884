import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { LinkService } from '../link.service';
import { StyleService } from '../shared/services/style.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopMargin, PopWidthStyle } from '../widget';
import { WidgetComponent } from '../widget.component';
import { VerticalAlignType, WidgetImage } from './image';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class WidgetImageComponent implements OnInit, WidgetComponent {
  static widgetName = 'image';
  @Input() attributes: WidgetImage;

  text: SafeHtml;
  isValid: boolean;
  noValidMessage: string;
  textTop: number;
  textTransform: string;
  widthStyle: PopWidthStyle;

  constructor(
    private _warningService: WarningService,
    private _linkService: LinkService,
    private _sanitizer: DomSanitizer,
    private _styleService: StyleService,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.url) {
      this.noValidMessage = this._warningService.showWarn(WidgetImageComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this._addMissingAttributes(this.attributes);
    this.text = this.attributes?.text?.content
      ? this._sanitizer.bypassSecurityTrustHtml(this.attributes.text.content)
      : '';
    this.textTop = this._getTextTop(this.attributes.text.verticalAlign);
    this.textTransform = `translate(0%, -${this.textTop}%)`;
    this.widthStyle = this._styleService.getWidthStyle(this.attributes.style.width);
    this.isValid = true;
  }

  navigate(): void {
    const { link, openInNewTab, isEditMode } = this.attributes;
    this._linkService.navigate(link, openInNewTab, isEditMode);
  }

  private _getTextTop(align: VerticalAlignType): number {
    switch (align) {
      case VerticalAlignType.TOP:
        return 0;
      case VerticalAlignType.BOTTOM:
        return 100;
      case VerticalAlignType.CENTER:
      default:
        return 50;
    }
  }

  private _addMissingAttributes(attributes: WidgetImage): void {
    if (!attributes.text) {
      this.attributes.text = {
        content: '',
        margin: null,
        verticalAlign: VerticalAlignType.CENTER,
        backgroundColor: '',
      };
    }

    if (!attributes.style) {
      this.attributes.style = {
        borderRadius: 10,
        backgroundColor: '',
        width: DEFAULT,
        height: DEFAULT,
        margin: new PopMargin(),
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new PopMargin();
    }

    if (!attributes.style.width) {
      attributes.style.width = DEFAULT;
    }

    if (!attributes.style.height) {
      attributes.style.height = DEFAULT;
    }
  }
}
