import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionCreator, MemoizedSelector, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class StoreService<T> {
  all: Observable<T>;
  isLoading: Observable<boolean>;

  constructor(
    protected _store: Store<T>,
    protected _actions: Actions,
    protected _selectors?: {
      all: MemoizedSelector<T, T>;
      isLoading: MemoizedSelector<T, boolean>;
    },
  ) {
    this.all = this._store.select(this._selectors?.all);
    this.isLoading = this._store.select(this._selectors?.isLoading);
  }

  protected _finishedAction(
    ...finishedActions: ActionCreator<string, (props: any) => any & Action>[]
  ): Observable<boolean> {
    return this._actions.pipe(
      ofType(...finishedActions),
      map(({ type }) => type === finishedActions[0].type),
      first(),
    );
  }

  protected _finishedActionStatus(
    errorObs: Observable<any>,
    ...finishedActions: ActionCreator<string, (props: any) => any & Action>[]
  ): Observable<boolean> {
    return this._finishedAction(...finishedActions).pipe(
      switchMap(isSuccess => (isSuccess ? of(isSuccess) : errorObs.pipe(switchMap(error => throwError(() => error))))),
      first(),
    );
  }
}
