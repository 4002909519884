/*
 * Public API Surface of store
 */
export * from './lib/auth';
export * from './lib/cart';
export * from './lib/credit-history';
export * from './lib/global';
export * from './lib/image-gallery';
export * from './lib/notifications';
export * from './lib/params';
export * from './lib/payment';
export * from './lib/pos-settings';
export * from './lib/promocode';
export * from './lib/settings';
export * from './lib/settings-area';
export * from './lib/settings-designer';
export * from './lib/shared';
export * from './lib/store.module';
export * from './lib/tags';
export * from './lib/tickets';
export * from './lib/users';
export * from './lib/working-hours';
// export * from './lib/settings-location'; TODO: review

// TODO: regroup in same module
export * from './lib/smile-history';
export * from './lib/smiles';

// TODO: regroup in one module
export * from './lib/pages';
export * from './lib/resolver'; // ?
export * from './lib/widgets';

// TODO: regroup in one module
export * from './lib/catalog';
export * from './lib/category';
export * from './lib/item';
export * from './lib/menu';
export * from './lib/menu-category';
export * from './lib/menu-item';
export * from './lib/option';
export * from './lib/option-group';
export * from './lib/sub-category';
export * from './lib/sub-item';

// TODO: regroup in one module
// export * from './lib/order'; TODO: review
export * from './lib/order-items';
export * from './lib/order-locations';
export * from './lib/order-menu-items';
export * from './lib/order-options';
export * from './lib/order-users';

// TODO: regroup in one module
export * from './lib/recommendation';
export * from './lib/recommendation-group';
// TODO: same as reco ?
export * from './lib/recommendations-designer';
export * from './lib/recommendations-groups-designer';
export * from './lib/user-socket-update/user-socket-update.service';
