import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ImageGalleryStoreModule } from '@bend/store/src/lib/image-gallery';
import { SharedModule } from '@bend/widgets-editor';

import { InputErrorsModule } from '@designer-modules/directives/input-errors/input-errors.module';
import { PopinModule } from '@designer-modules/popin-new';
import { SpinnerModule } from '@designer-modules/spinner';
import { SharedComponentsModule } from '@designer-shared/shared-components';

import {
  CropperDialogComponent,
  GalleryContainerComponent,
  GalleryGridPresenterComponent,
  GalleryMenuPresenterComponent,
  GalleryUploadDialogComponent,
  GalleryUrlUploadDialogComponent,
  ImageComponent,
  ImageGalleryComponent,
} from './components';
import { SortAppListPipe } from './pipes/sort-app-list.pipe';

@NgModule({
  declarations: [
    GalleryContainerComponent,
    ImageComponent,
    ImageGalleryComponent,
    GalleryUrlUploadDialogComponent,
    GalleryMenuPresenterComponent,
    GalleryGridPresenterComponent,
    CropperDialogComponent,
    SortAppListPipe,
    GalleryUploadDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    ImageCropperModule,
    SharedComponentsModule,
    SharedModule,
    ImageGalleryStoreModule,
    MatGridListModule,
    TranslateModule,
    PopinModule,
    SpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    InputErrorsModule,
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }],
  exports: [ImageGalleryComponent, ImageComponent, SortAppListPipe],
})
export class ImageGalleryModule {}
