import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './order-users.config';
import { metaReducers, reducer } from './order-users.reducer';
import { OrderUsersService } from './order-users.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer, { metaReducers })],
  providers: [OrderUsersService],
})
export class OrderUsersStoreModule {}
