import { Injectable } from '@angular/core';

export enum WarnTypeEnum {
  NoAttributes = 0,
  NoData = 1,
}

@Injectable()
export class WarningService {
  constructor() {}

  showWarn(widgetName: string, warnType: WarnTypeEnum): string {
    let message: string;
    switch (warnType) {
      case WarnTypeEnum.NoAttributes: {
        message = 'Missing attributes';
        break;
      }
      case WarnTypeEnum.NoData: {
        message = 'No data from server';
        break;
      }
    }
    message = `Widget ${widgetName}: ${message}.`;

    console.warn(message);

    return message;
  }
}
