<ng-container [ngSwitch]="menuStyleType">
  <pop-classic-item
    *ngSwitchCase="styleTypes.Classic"
    [product]="menuItem"
    [currency]="currency"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="fgColor$ | async"
    [isMenu]="false"
  ></pop-classic-item>
  <pop-extended-item
    *ngSwitchCase="styleTypes.Extended"
    [product]="menuItem"
    [currency]="currency"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="fgColor$ | async"
    [isMenu]="false"
  ></pop-extended-item>
  <pop-compact-item
    *ngSwitchCase="styleTypes.Compact"
    [product]="menuItem"
    [currency]="currency"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="fgColor$ | async"
    [isMenu]="false"
  ></pop-compact-item>
  <pop-side-item
    *ngSwitchCase="styleTypes.SideBySide"
    [product]="menuItem"
    [currency]="currency"
    [isReadOnly]="isReadOnly"
    [isConsultingMode]="isConsultingMode"
    [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
    [secondColor]="fgColor$ | async"
    [isMenu]="false"
    [stepByStepMenu]="true"
  ></pop-side-item>
</ng-container>
