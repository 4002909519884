import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@bend/icon';
import { TranslateModule } from '@bend/translate';

import { CartServicesModule } from '../cart-services';
import { RoutingInternModule } from '../routing-intern';
import { SharedComponentsModule } from '../shared-components';
import {
  BoardOrderItemComponent,
  BoardOrderItemOptionComponent,
  BoardOrderItemQuantityComponent,
  BoardOrderLocationsComponent,
  BoardOrderMenuComponent,
  BoardOrderUserComponent,
  ButtonBackComponent,
  ButtonTotalComponent,
  ItemNameComponent,
  ItemsWrapperComponent,
  MenuButtonComponent,
  TitleComponent,
} from './components';
import { NavigationService } from './services';

@NgModule({
  declarations: [
    MenuButtonComponent,
    ButtonTotalComponent,
    ButtonBackComponent,
    TitleComponent,
    BoardOrderUserComponent,
    BoardOrderLocationsComponent,
    BoardOrderItemComponent,
    BoardOrderMenuComponent,
    ItemNameComponent,
    ItemsWrapperComponent,
    BoardOrderItemOptionComponent,
    BoardOrderItemQuantityComponent,
  ],
  imports: [CommonModule, RoutingInternModule, SharedComponentsModule, CartServicesModule, IconModule, TranslateModule],
  exports: [
    MenuButtonComponent,
    ButtonTotalComponent,
    ButtonBackComponent,
    TitleComponent,
    TranslateModule,
    RoutingInternModule,
    BoardOrderUserComponent,
  ],
  providers: [NavigationService],
})
export class CartComponentsModule {}
