import { createAction, props } from '@ngrx/store';

import { createType } from '../shared';
import { storeKey } from './auth.config';
import { LoginParams, LoginParamsGps, LoginResponse } from './auth.type';

const type = createType(storeKey);

class Actions {
  initEffects = createAction(type('init effects'));

  initState = createAction(type('init state'), props<LoginResponse>());
  initStateError = createAction(type('init state error'));

  login = createAction(type('login'), props<LoginParams>());
  loginSuccess = createAction(type('login success'), props<LoginResponse>());
  loginError = createAction(type('login error'), props<{ errorCode: string }>());

  signIn = createAction(type('sign in'), props<{ phone: string }>());
  signInSuccess = createAction(type('sign in success'), props<{ phone: string }>());
  signInError = createAction(type('sign in error'), props<{ errorCode: string }>());

  resendCode = createAction(type('resend code'));
  resendCodeSuccess = createAction(type('resend code success'));
  resendCodeError = createAction(type('resend code error'), props<{ errorCode: string }>());

  loginPos = createAction(type('login pos'));
  loginPosSuccess = createAction(type('login pos success'), props<LoginResponse>());
  loginPosError = createAction(type('login pos error'), props<{ errorCode: string }>());

  loginWifi = createAction(type('login wifi'));
  loginWifiSuccess = createAction(type('login wifi success'), props<LoginResponse>());
  loginWifiError = createAction(type('login wifi error'), props<{ errorCode: string }>());

  loginGps = createAction(type('login gps'), props<Omit<LoginParamsGps, 'place'>>());
  loginGpsSuccess = createAction(type('login gps success'), props<LoginResponse>());
  loginGpsError = createAction(type('login gps error'), props<{ errorCode: string }>());

  confirmCode = createAction(type('confirm code'), props<{ code: string }>());
  confirmCodeSuccess = createAction(type('confirm code success'), props<LoginResponse>());
  confirmCodeError = createAction(type('confirm code error'), props<{ errorCode: string }>());

  logOut = createAction(type('log out'));
  logOutSuccess = createAction(type('log out success'));
}

export const actions = new Actions();
