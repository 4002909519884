import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiDesignerService,RecommendationsDesigner, RecommendationsItemsDesigner } from '../shared';

@Injectable()
export class RecommendationsDesignerHttpService {
  constructor(private _api: ApiDesignerService, private _http: HttpClient) {}

  getRecommendations(recommendationId: number): Observable<RecommendationsItemsDesigner[]> {
    return this._api.recommendationGroup('v1', recommendationId).pipe(
      switchMap(api => this._http.get<RecommendationsDesigner>(api)),
      map(({ id, recommendations }) =>
        recommendations.map(recommendation => ({ recommendationGroupId: +id, ...recommendation })),
      ),
    );
  }
}
