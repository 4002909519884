import { HttpEventType } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FeedbackFileUpload, FeedbackLabels, UploadStatusEnum } from '../feedback';
import { WidgetFeedbackService } from '../feedback.service';

@Component({
  selector: 'pop-feedback-upload-image',
  templateUrl: './feedback-upload-image.component.html',
  styleUrls: ['./feedback-upload-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidgetFeedbackUploadImageComponent),
      multi: true,
    },
  ],
})
export class WidgetFeedbackUploadImageComponent implements ControlValueAccessor {
  @Input() mainColor: string;
  @Input() isEditMode: boolean;
  @Input() labels: FeedbackLabels;
  @Output() uploadStatus: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('fileInputBtn') fileInputBtnEl: ElementRef;
  file: FeedbackFileUpload = {};
  uploadedSizePercent: number;
  displayProgressBar: boolean;
  showErrorMessage: boolean;
  isUploadButtonDisabled: boolean;

  constructor(private _feedbackService: WidgetFeedbackService, private _cdr: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(): void {}

  writeValue(value: any): void {
    this.file = value || {};
  }

  onChooseFile(fileInputBtn: HTMLButtonElement): void {
    this.showErrorMessage = false;
    fileInputBtn.click();
  }

  onFileChange(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length) {
      this.uploadStatus.emit(UploadStatusEnum.START);
      const file: File = fileList[0];
      const formData = new FormData();

      this.isUploadButtonDisabled = true;
      formData.append('file', file);
      this._feedbackService.uploadFile(formData).subscribe({
        next: progressEvent => {
          if (progressEvent.error) {
            this._handleError();
            return;
          }

          if (progressEvent.type === HttpEventType.UploadProgress) {
            this.displayProgressBar = true;
            this.uploadedSizePercent = this.calculateUploadedPercent(progressEvent.loaded, progressEvent.total);
          }
          if (progressEvent.type === HttpEventType.Response) {
            const body = progressEvent.body;
            this._setFileDetails(body.fileName);
            this.displayProgressBar = false;
            this.isUploadButtonDisabled = false;
            this._propagateChange(this.file.url);
            this.uploadStatus.emit(UploadStatusEnum.FINISH);
            this.uploadedSizePercent = 0;
          }
          this._cdr.detectChanges();
        },
        error: () => {
          this._handleError();
          this._cdr.detectChanges();
        },
      });
      event.target.value = '';
    }
  }

  deleteLoadedImage(): void {
    const emptyValue = '';
    this.fileInputBtnEl.nativeElement.value = emptyValue;
    this._setFileDetails(emptyValue);
    this._propagateChange(this.file.url);
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const fileNameWithExtension = url.substring(url.lastIndexOf('/') + 1);

      return fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));
    }

    return '';
  }

  getFileTypeFromUrl(url: string): string {
    return url ? url.substring(url.lastIndexOf('.') + 1) : '';
  }

  calculateUploadedPercent(size: number, total: number): number {
    return Math.round((100 * size) / total);
  }

  private _propagateChange = (_: any) => {};

  private _setFileDetails(url: string): void {
    this.file.url = url;
    this.file.name = this.getFileNameFromUrl(url);
    this.file.type = this.getFileTypeFromUrl(url);
  }

  private _handleError(): void {
    this.showErrorMessage = true;
    this.displayProgressBar = false;
    this.isUploadButtonDisabled = false;
    this.uploadStatus.emit(UploadStatusEnum.FINISH);
  }
}
