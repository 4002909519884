import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ItemUnavailabilityDisplay } from '@bend/store-shared';

import { PopProduct } from '../../product-catalog';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationComponent {
  @Input() item: PopProduct;
  @Input() parentItemAvailability: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
}
