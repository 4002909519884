import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

@Injectable()
export class WidgetDocumentViewerService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  saveViewCount(widgetId: string): Observable<any> {
    return this._api.documentViewer('v1', widgetId).pipe(switchMap(api => this._http.post(api, null)));
  }
}
