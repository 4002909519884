import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { IconModule } from '@bend/icon';
import { SharedComponentsModule } from '@bend/shared-widgets/src/lib/shared/shared-components';

import { QrCodeButtonComponent, QrCodeReaderComponent } from './components';
import { QrCodeComponent } from './qr-code.component';

@NgModule({
  declarations: [QrCodeComponent, QrCodeReaderComponent, QrCodeButtonComponent],
  imports: [CommonModule, SharedComponentsModule, ZXingScannerModule, IconModule, TranslateModule.forChild()],
})
export class QrCodeModule {
  static widgetComponents = {
    QrCodeComponent,
  };
}
