import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { DialogService, TimeToClose } from '@bend/dialog';

import { TranslateHelperService } from '../translate-helper.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopAccessType, PopSettings, PopValidationStatus } from '../widget';
import { WidgetComponent } from '../widget.component';
import { FeedbackLabels, FeedbackResponse, UploadStatusEnum, WidgetFeedback } from './feedback';
import { WidgetFeedbackService } from './feedback.service';

@Component({
  selector: 'pop-widget-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class WidgetFeedbackComponent implements OnInit, WidgetComponent {
  static widgetName = 'feedback';
  @Input() attributes: WidgetFeedback;
  form: UntypedFormGroup;
  feedbacks: FeedbackResponse[] = [];
  isValid: boolean;
  noValidMessage: string;
  numbers: number[];
  pageId: string;
  showErrors: boolean;
  isSubmitBtnDisabled: boolean;
  defaultRating: number;
  labels: FeedbackLabels;
  isReadOnly: boolean;

  constructor(
    @Optional() private _dialog: DialogService,
    private _feedbackService: WidgetFeedbackService,
    private _formBuilder: UntypedFormBuilder,
    private _warningService: WarningService,
    private _route: ActivatedRoute,
    private _translateHelper: TranslateHelperService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.numbers = Array(5)
      .fill(0)
      .map((_, i) => i + 1);
  }

  ngOnInit(): void {
    if (
      !this.attributes ||
      (!this.attributes.showComment &&
        !this.attributes.showName &&
        !this.attributes.showStars &&
        !this.attributes.showUpload)
    ) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetFeedbackComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this._addMissingAttributes();
    this.pageId = this._route.snapshot.paramMap.get('pageId');
    this.labels = this.attributes.labels;
    this.isReadOnly = this.attributes.isEditMode || this.attributes.pop.validationStatus === PopValidationStatus.FAILED;
    this.defaultRating = this.attributes.showStars ? 5 : null;
    this._initForm();
    this.isValid = true;

    if (this.isReadOnly) {
      return;
    }

    this._downloadPreviousFeedback();
  }

  onSubmit(): void {
    if (this.isReadOnly) {
      return;
    }

    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    this.showErrors = false;
    this._feedbackService.sendFeedback(this.pageId, this.form.value).subscribe({
      next: (response: FeedbackResponse) => {
        this.feedbacks.push(response);
        this.form.reset({ review: this.defaultRating });

        this._cdr.detectChanges();

        this._translateHelper.translateLabel('FEEDBACK_ADDED', 'FEEDBACK', this.labels).subscribe(res => {
          this._dialog.success({ message: res, showCloseButton: false, timeToClose: TimeToClose.FiveSeconds });
          this._cdr.detectChanges();
        });
      },
      error: () => {
        this._dialog.error({ message: 'Failed to save the feedback. Please try again.' });
        this._cdr.detectChanges();
      },
    });
  }

  get feedbackRating(): string {
    return this.form.get('note').value;
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  onUploadChangeStatus(uploadStatus: string): void {
    switch (uploadStatus) {
      case UploadStatusEnum.START:
        this.isSubmitBtnDisabled = true;
        break;

      case UploadStatusEnum.FINISH:
        this.isSubmitBtnDisabled = false;
        break;
    }
  }

  private _initForm(): void {
    this.form = this._formBuilder.group(
      {
        review: [this.defaultRating],
        name: [''],
        comment: [''],
        image: [''],
      },
      { validators: this.feedbackValidator } as AbstractControlOptions,
    );
  }

  private _downloadPreviousFeedback(): void {
    if (!this.attributes.showPreviousFeedbacks) {
      return;
    }

    this._feedbackService.getFeedbacks(this.pageId).subscribe((feedbacks: FeedbackResponse[]) => {
      this.feedbacks = feedbacks;
      this._cdr.detectChanges();
    });
  }

  private feedbackValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const name = control.get('name');
    const comment = control.get('comment');
    const image = control.get('image');
    const { showStars, showName, showComment, showUpload } = this.attributes;

    if (showStars) {
      return null;
    }

    if (showName && !showComment && !showUpload) {
      return name.value ? null : { nameRequired: true };
    }

    if (showComment && !showUpload) {
      return comment.value ? null : { commentRequired: true };
    }

    if (!showComment && showUpload) {
      return image.value ? null : { imageRequired: true };
    }

    if (showComment && showUpload) {
      return comment.value || image.value ? null : { commentOrImageRequired: true };
    }

    return null;
  };

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }

    if (!this.attributes.pop.type) {
      this.attributes.pop.type = PopAccessType.READONLY;
    }

    if (!this.attributes.pop.blurMessage) {
      this.attributes.pop.blurMessage = 'Read only';
    }

    if (!this.attributes.pop.validationStatus) {
      this.attributes.pop.validationStatus = PopValidationStatus.FAILED;
    }

    if (!this.attributes.labels) {
      this.attributes.labels = new FeedbackLabels();
    }
  }
}
