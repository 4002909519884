import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { ApiDesignerService } from '@bend/store-shared';

import { WidgetModel } from './widget.models';

@Injectable()
export class WidgetHttpService {
  constructor(private readonly _api: ApiDesignerService, private readonly _http: HttpClient) {}

  getWidgets(pageId: string): Observable<WidgetModel[]> {
    return this._api.page('v1', pageId).pipe(
      switchMap(url => this._http.get<{ widgets: WidgetModel[] }>(url)),
      map(response => response.widgets),
    );
  }

  deleteWidget(pageId: string, widgetId: string): Observable<void> {
    return this._api.pageWidget('v1', pageId, widgetId).pipe(switchMap(url => this._http.delete<void>(url)));
  }

  duplicateWidget(pageId: string, widget: WidgetModel): Observable<WidgetModel> {
    const { attributes, type, validators } = widget;

    return this._api
      .pageWidgets('v1', pageId)
      .pipe(switchMap(url => this._http.post<WidgetModel>(url, { attributes, type, validators })));
  }

  publishWidget(pageId: string, widgetId: string, published: boolean): Observable<WidgetModel> {
    return this._api
      .pageWidget('v1', pageId, widgetId)
      .pipe(switchMap(url => this._http.patch<WidgetModel>(url, { published })));
  }

  changeOrderWidgets(pageId: string, widgets: WidgetModel[]): Observable<WidgetModel[]> {
    return this._api.widgetsOrder('v1', pageId).pipe(
      switchMap(api => this._http.patch<{ widgets: WidgetModel[] }>(api, widgets)),
      map(response => response.widgets),
    );
  }
}
