import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PosSettings } from '../shared';
import { actions } from './pos-settings.actions';
import { State } from './pos-settings.type';

export const adapter: EntityAdapter<PosSettings> = createEntityAdapter<PosSettings>({
  sortComparer: (a, b) => b.id - a.id,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoadedByAppSlug: null,
  isLoadingByAppSlug: null,
  isLoadedBy: [],
  selectedId: null,
  errorCode: null,
});

const posSettingsDesignerReducer = createReducer(
  initialState,
  on(actions.getPosSettingsList, (state, { appSlug }) => ({
    ...state,
    isLoadingByAppSlug: appSlug,
    isLoading: true,
  })),

  on(actions.removePosSettingsId, (state, { posSettingsId }) => ({
    ...state,
    isLoadedBy: state.isLoadedBy.filter(id => id !== posSettingsId),
  })),

  on(actions.getPosSettings, actions.addPosSettings, actions.deletePosSettings, actions.updatePosSettings, state => ({
    ...state,
    isLoading: true,
  })),

  // Success Actions

  on(actions.getPosSettingsListSuccess, (state, { posSettings, appSlug }) =>
    adapter.setAll(posSettings, {
      ...state,
      isLoadedByAppSlug: appSlug,
      isLoadedBy: [],
      isLoading: false,
    }),
  ),

  on(actions.getPosSettingsListIsLoaded, state => ({ ...state, isLoading: false })),

  on(actions.getPosSettingsSuccess, (state, { posSettings: { id, ...rest } }) =>
    adapter.updateOne(
      { id, changes: rest },
      {
        ...state,
        isLoadedBy: [...state.isLoadedBy, id],
        selectedId: id,
        isLoading: false,
      },
    ),
  ),

  on(actions.getPosSettingsIsLoaded, (state, { posSettingsId }) => ({
    ...state,
    selectedId: posSettingsId,
    isLoading: false,
  })),

  on(actions.addPosSettingsSuccess, (state, { posSettings }) =>
    adapter.upsertOne(posSettings, {
      ...state,
      isLoadedBy: [...state.isLoadedBy, posSettings.id],
      isLoading: false,
    }),
  ),

  on(actions.deletePosSettingsSuccess, (state, { posSettingsId }) =>
    adapter.removeOne(posSettingsId, {
      ...state,
      isLoadedBy: state.isLoadedBy.filter(id => id !== posSettingsId),
      isLoading: false,
      selectedId: null,
    }),
  ),

  on(actions.updatePosSettingsSuccess, (state, { posSettings: { id, ...rest } }) =>
    adapter.updateOne(
      { id, changes: rest },
      {
        ...state,
        isLoading: false,
      },
    ),
  ),

  // Error Actions

  on(
    actions.getPosSettingsListError,
    actions.getPosSettingsError,
    actions.addPosSettingsError,
    actions.deletePosSettingsError,
    actions.updatePosSettingsError,
    (state, { errorCode }) => ({ ...state, errorCode, isLoading: false }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return posSettingsDesignerReducer(state, action);
}
