import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { SettingsArea } from '../shared';
import { actions } from './settings.actions';
import { State } from './settings.type';

export const adapter: EntityAdapter<SettingsArea> = createEntityAdapter<SettingsArea>({
  selectId: (settings: SettingsArea) => settings.general.id,
});

export const initialState: State = adapter.getInitialState({
  error: null,
  isLoading: false,
  isLoadedBy: [],
  selectedId: null,
  isLoadedByLocation: [],
});

const settingsReducer = createReducer(
  initialState,

  on(actions.getAreaSettings, actions.patchAreaSettings, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.getAreaSettingsIsLoaded, (state, { by }) => ({
    ...state,
    isLoading: false,
    selectedId: by,
  })),

  on(actions.getAreaSettingsError, actions.patchAreaSettingsError, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(actions.getAreaSettingsSuccess, actions.patchAreaSettingsSuccess, (state, { settings, by }) =>
    adapter.upsertOne(settings, {
      ...state,
      isLoading: false,
      isLoadedBy: [...state.isLoadedBy, by],
      selectedId: by,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return settingsReducer(state, action);
}
