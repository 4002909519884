import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import { Page, PageGroup, PagesList } from '../types';

@Injectable()
export class PageSelectorService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getAllPages(): Observable<PagesList> {
    return this._api.pages('v1').pipe(switchMap(api => this._http.get<PagesList>(api)));
  }

  getPages(): Observable<any> {
    return this.getAllPages().pipe(
      map(groupsData => {
        const pages = groupsData;
        const groups = this.groupPagesByCategory(pages);
        return { pages, groups };
      }),
    );
  }

  groupPagesByCategory(pages: any): { title: string; data: any }[] {
    return [
      {
        title: PageGroup.SINGLE,
        data: this.sortGroupData(['single'], pages),
      },
    ];
  }

  getGroupData(type: string[], pages: any): Page[] {
    return type.length < 2 ? pages[type[0]] : pages[type[0]][type[1]];
  }

  sortGroupData(type: string[], pages: any): Page[] {
    return this.getGroupData(type, pages)
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
