import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'pop-comment-to-kitchen',
  templateUrl: './comment-to-kitchen.component.html',
  styleUrls: ['./comment-to-kitchen.component.scss'],
})
export class WidgetProductCatalogCommentToKitchenComponent implements AfterViewChecked, OnInit {
  @Input() mainColor: string;
  @Input() resetCommentAndCloseEmitter = new EventEmitter<any>();
  @Input() isCommentToKitchenRequired: boolean;
  @Input() styleConfig: any;
  @Output() onChanged = new EventEmitter<string>();
  @Output() scrollToEmitter = new EventEmitter<number>();
  @ViewChild('commentEl') commentEl: ElementRef;
  comment: string;
  isCommentVisible = false;
  scroll: boolean;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._resetCommentAndCollapse();
  }

  ngAfterViewChecked(): void {
    if (this.scroll && this.commentEl) {
      const commentNativeEl = this.commentEl.nativeElement;
      const commentBoundingClientRect = commentNativeEl.getBoundingClientRect();
      commentNativeEl.focus();
      if (commentBoundingClientRect) {
        this.scrollToEmitter.emit(commentBoundingClientRect.top);
      }
      this.scroll = false;
    }
  }

  toggleComment(): void {
    this.isCommentVisible = !this.isCommentVisible;
    this.scroll = this.isCommentVisible;
  }

  commentChanged(comment: string): void {
    this.comment = comment;
    this.onChanged.emit(comment);
  }

  private _resetCommentAndCollapse(): void {
    this.resetCommentAndCloseEmitter.subscribe(() => {
      this.comment = '';
      if (this.isCommentVisible) {
        this.isCommentVisible = false;
      }
      this._cdr.detectChanges();
    });
  }
}
