import { Dictionary, Update } from '@ngrx/entity';

type QuantityUpdateEntity<T = {}> = T & { id: any; quantity: number; groupId?: number };

export const resetEntitiesQuantity: <T>(
  entities: Dictionary<QuantityUpdateEntity<T>>,
) => Update<QuantityUpdateEntity<T>>[] = entities => {
  return Object.values(entities).reduce((acc, { id, quantity, groupId }) => {
    if (quantity) {
      acc.push({
        id: groupId ? `${groupId}-${id}` : id,
        changes: { quantity: 0 },
      });
    }

    return acc;
  }, []);
};
