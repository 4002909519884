<span *ngIf="showTimer$ | async; else resend" @toggleY class="delay-message">
  {{ 'AUTH.RESEND_CODE_DELAY_MESSAGE' | translate: { time: time$ | async } }}
</span>

<ng-template #resend>
  <button @toggleY class="return" (click)="onClick()" [disabled]="isLoading">
    <span [innerHtml]="'AUTH.TRY_AGAIN' | translate"></span>
    <app-spinner *ngIf="isLoading" @toggleX [stroke]="bgPrimary$ | async" class="loading"></app-spinner>
  </button>
</ng-template>
