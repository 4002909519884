import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from './params.types';

export class ParamsSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    let params: {} = {};
    while (route) {
      params = {
        ...params,
        ...route.params,
      };

      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    return { url, params, queryParams };
  }
}
