<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <label for="titleCkeditor" class="form-label">{{ 'WIDGETS.SHARED.TITLE' | translate }}</label>
    <textarea
      id="titleInput"
      [placeholder]="'WIDGETS.SHARED.TITLE' | translate"
      formControlName="title"
      class="form-input form-input--title"
    ></textarea>
    <span class="info-text">{{ 'WIDGETS.TEXT.ADD_TABLE' | translate }}</span>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <span class="form-fieldset-groups__caption">{{ 'WIDGETS.STYLE.STYLES' | translate }}</span>
        <div class="form-fieldset-groups__content">
          <div class="form-row-groups">
            <div class="form-row-group form-row-group--distanced">
              <label for="fontSizeInput" class="form-label">{{ 'WIDGETS.STYLE.FONT_SIZE' | translate }}</label>
              <input
                id="heightInput"
                type="text"
                formControlName="fontSize"
                matInput
                [matAutocomplete]="fontSizeAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #fontSizeAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="fontSize.invalid && fontSize.errors.fontSize">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>

            <div class="form-row-group form-row-group--distanced">
              <label for="textColorInput" class="form-label">{{ 'WIDGETS.STYLE.TEXT_COLOR' | translate }}</label>
              <span
                id="textColorInput"
                [(colorPicker)]="textColorValue"
                [style.background]="textColorValue"
                class="form-colorpicker"
              ></span>
            </div>

            <div class="form-row-group form-row-group--distanced">
              <label for="textAlignSelect" class="form-label">{{ 'WIDGETS.STYLE.TEXT_ALIGN' | translate }}</label>
              <select class="form-input form-input--select" formControlName="textAlign">
                <option *ngFor="let textAlign of textAligns" [value]="textAlign">{{ textAlign }}</option>
              </select>
            </div>

            <div class="form-row-group form-row-group--distanced" formGroupName="margin">
              <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
              <input
                id="marginTopInput"
                type="number"
                formControlName="top"
                min="0"
                class="form-input form-input--number"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced" formGroupName="margin">
              <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
              <input
                id="marginBottomInput"
                type="number"
                formControlName="bottom"
                min="0"
                class="form-input form-input--number"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="backgroundColorInput" class="form-label">
                {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
              </label>
              <span
                id="backgroundColorInput"
                [(colorPicker)]="backgroundColorValue"
                [cpOutputFormat]="'rgba'"
                [style.backgroundColor]="backgroundColorValue"
                class="form-colorpicker"
              ></span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="widthInput" class="form-label">{{ 'WIDGETS.STYLE.WIDTH' | translate }}</label>
              <input
                id="widthInput"
                type="text"
                formControlName="width"
                matInput
                [matAutocomplete]="widthAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #widthAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
                <mat-option [value]="'full'">{{ 'WIDGETS.STYLE.FULL' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="width.invalid && width.errors.width">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="heightInput" class="form-label">{{ 'WIDGETS.STYLE.HEIGHT' | translate }}</label>
              <input
                id="heightInput"
                type="text"
                formControlName="height"
                matInput
                [matAutocomplete]="heightAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #heightAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="height.invalid && height.errors.height">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
