import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { TwitterHtagWidget } from './twitter-htag';

@Component({
  selector: 'pop-widget-edit-twitter-htag',
  templateUrl: './twitter-htag.component.html',
  styleUrls: ['./twitter-htag.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditTwitterHtagComponent extends WidgetBaseComponent<TwitterHtagWidget> implements OnInit {
  static widgetName = 'twitter_htag';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  get htag(): UntypedFormControl {
    return this.form.get('htag') as UntypedFormControl;
  }

  get twitterWidgetId(): UntypedFormControl {
    return this.form.get('twitterWidgetId') as UntypedFormControl;
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        htag: [this.widget.attributes.htag, Validators.required],
        twitterWidgetId: [this.widget.attributes.twitterWidgetId, Validators.required],
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
