<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-fieldset-groups">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.GALLERY.IMAGES' | translate }}</span>
      <div class="form-fieldset-groups__content" formArrayName="images">
        <div *ngFor="let image of imagesControls; let i = index" class="form-fieldset-group">
          <div [formGroupName]="i">
            <div class="form-row-groups">
              <span class="form-row-groups__caption">{{ 'Image ' + (i + 1) }}</span>
              <designer-image-gallery [formControl]="getImage(i)"></designer-image-gallery>
            </div>
            <div class="form-row">
              <div
                *ngIf="
                  $any(image).controls.alt.invalid &&
                  ((($any(image).controls.alt.dirty || $any(image).controls.alt.touched) &&
                    $any(image).controls.alt.errors.required) ||
                    showErrors)
                "
                class="form-error-msg"
              >
                {{ 'WIDGETS.SHARED.IMAGE_ALT_ENTER' | translate }}
              </div>
              <label for="altInput" class="form-label">
                {{ 'WIDGETS.SHARED.IMAGE' | translate }} {{ i + 1 }} {{ 'WIDGETS.SHARED.ALT' | translate }}
              </label>
              <input
                id="altInput"
                class="form-input form-input--alt"
                type="text"
                [placeholder]="'WIDGETS.SHARED.ALT' | translate"
                formControlName="alt"
              />
            </div>
            <div class="form-row clearfix">
              <button
                *ngIf="imagesControls.length > 1"
                class="btn-flat btn-flat--remove"
                type="button"
                (click)="removeImage(i)"
              >
                <span class="hyphen">-</span>
                {{ 'WIDGETS.GALLERY.REMOVE_IMAGE' | translate }}
              </button>
            </div>
          </div>
        </div>
        <button
          *ngIf="imagesControls.length < 4"
          class="btn-flat btn-flat--add-new-item"
          type="button"
          (click)="addImage()"
        >
          {{ 'WIDGETS.GALLERY.ADD_IMAGE' | translate }}
        </button>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
