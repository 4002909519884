import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { SnackbarContainerComponent } from '../components';

export class SnackBarBuilder {
  private readonly SUCCESS_COLOR = '#87DEB3';
  private readonly INFORMATION_COLOR = '#89BEC9';
  private readonly WARNING_COLOR = '#FF8C8C';
  private readonly ALERT_COLOR = '#e79e00';

  private title = '';
  private subtitle = '';
  private color = '';
  private icon = '';
  private buttonLabel = '';
  private duration = 5000; // default duration of 5 seconds
  private verticalPosition: MatSnackBarVerticalPosition = 'top'; // Allowed: 'top' | 'bottom'
  private horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  // Allowed: 'start' | 'center' | 'end' | 'left' | 'right'

  constructor(private readonly snackBar: MatSnackBar) {}

  public setSuccessSnackbar(): SnackBarBuilder {
    this.color = this.SUCCESS_COLOR;
    this.icon = 'validation-icon';
    return this;
  }

  public setInformationSnackbar(): SnackBarBuilder {
    this.color = this.INFORMATION_COLOR;
    this.icon = 'information-icon';

    return this;
  }

  public setWarningSnackbar(): SnackBarBuilder {
    this.color = this.WARNING_COLOR;
    this.icon = 'error-icon';

    return this;
  }

  public setAlertSnackbar(): SnackBarBuilder {
    this.color = this.ALERT_COLOR;
    this.icon = 'alert-icon';

    return this;
  }

  public setTitle(title: string): SnackBarBuilder {
    this.title = title;
    return this;
  }

  public setSubtitle(subtitle: string): SnackBarBuilder {
    this.subtitle = subtitle;
    return this;
  }

  public setColor(color: string): SnackBarBuilder {
    this.color = color;
    return this;
  }

  public setIcon(icon: string): SnackBarBuilder {
    this.icon = icon;
    return this;
  }

  public setButtonLabel(buttonLabel: string): SnackBarBuilder {
    this.buttonLabel = buttonLabel;
    return this;
  }

  public setDuration(duration: number): SnackBarBuilder {
    this.duration = duration;
    return this;
  }

  public setverticalPosition(verticalPosition: MatSnackBarVerticalPosition): SnackBarBuilder {
    this.verticalPosition = verticalPosition;
    return this;
  }

  public sethorizontalPosition(horizontalPosition: MatSnackBarHorizontalPosition): SnackBarBuilder {
    this.horizontalPosition = horizontalPosition;
    return this;
  }

  build(): MatSnackBarRef<SnackbarContainerComponent> {
    return this.snackBar.openFromComponent(SnackbarContainerComponent, {
      data: {
        title: this.title,
        subtitle: this.subtitle,
        color: this.color,
        icon: this.icon,
        buttonLabel: this.buttonLabel,
      },
      duration: this.duration,
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
    });
  }
}
