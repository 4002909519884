import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TranslateHelperService {
  constructor(private _translate: TranslateService) {}

  translateLabel(key: string, prefix: string, labels?: object): Observable<string> {
    return labels?.[key] ? of(labels[key]) : this._translate.get(`${prefix}.${key}`);
  }
}
