import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageTransform } from 'ngx-image-cropper';
import { tap } from 'rxjs/operators';

import { ImageGalleryService } from '@bend/store';
import { Image } from '@bend/store/src/lib/shared/types/image-gallery.type';

@Component({
  selector: 'designer-cropper',
  templateUrl: 'cropper.component.html',
  styleUrls: ['cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropperDialogComponent {
  readonly RATIO_SQUARE = 1;
  readonly RATIO_4_3 = 4 / 3;
  readonly RATIO_16_9 = 16 / 9;
  readonly ZOOM_DEFAULT_VALUE = 1;
  readonly ZO0M_STEP = 0.5;

  ratio = this.RATIO_SQUARE;
  croppedImageData: string;
  dataURL: string;
  imageLoaded = false;
  loadError = false;
  maintainAspectRatio = true;

  imageTransformer: ImageTransform;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { image: Image },
    private _dialogRef: MatDialogRef<CropperDialogComponent>,
    private _imageGalleryService: ImageGalleryService,
  ) {
    // Small work around
    // Doesn't work:
    //  https://smilein.fra1.cdn.digitaloceanspaces.com/test...
    // Work:
    //  https://smilein.fra1.cdn.digitaloceanspaces.com//test...
    // ! For Digital Ocean Storage only
    this.dataURL = `${data.image.domain}${data.image.relativePath}`;
    this.imageTransformer = { scale: this.ZOOM_DEFAULT_VALUE };
  }

  submit(): void {
    // dataUrl = null - for hide cropper
    this.dataURL = null;
    this.imageLoaded = false;
    this._imageGalleryService
      .uploadCroppedImage(this.croppedImageData, this.data.image.fileName)
      .pipe(
        tap(status => status && this._dialogRef.close()),
        tap(() => (this.loadError = true)),
      )
      .subscribe();
  }

  cancel(): void {
    this._dialogRef.close();
  }

  zoomIn(): void {
    this.imageTransformer = {
      scale: this.imageTransformer.scale + this.ZO0M_STEP,
    };
  }

  zoomOut(): void {
    this.imageTransformer = {
      scale:
        this.imageTransformer.scale > this.ZOOM_DEFAULT_VALUE
          ? this.imageTransformer.scale - this.ZO0M_STEP
          : this.ZOOM_DEFAULT_VALUE,
    };
  }

  changeRatio(aspectRatio: number | null): void {
    if (aspectRatio) {
      this.maintainAspectRatio = true;
      this.ratio = aspectRatio;
    } else {
      this.maintainAspectRatio = false;
    }
  }

  onWheel(event: WheelEvent): void {
    event.deltaY > 0 ? this.zoomOut() : this.zoomIn();
  }
}
