<div
  *ngIf="isValid; else elseBlock"
  class="widget-document-viewer"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.marginRight.px]="attributes.style?.fullWidth ? 0 : ''"
  [style.marginLeft.px]="attributes.style?.fullWidth ? 0 : ''"
  [style.borderRadius.px]="attributes.style?.borderRadius"
  [style.backgroundColor]="attributes.style?.backgroundColor"
  (click)="saveViewCount()"
>
  <img *ngIf="attributes.imageUrl" [src]="attributes.imageUrl" [alt]="attributes.imageAlt" class="img" width="100%" />
  <div class="text-overlay" *ngIf="text" [innerHTML]="text"></div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-image">{{ noValidMessage }}</div>
</ng-template>
