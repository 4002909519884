import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { actions as recommendationActions } from '../recommendation/recommendation.actions';
import { ArrayStore, PlaceRecommendationGroup, Recommendation, RecommendationGroup } from '../shared';
import { actions } from './recommendation-group.actions';
import * as selectors from './recommendation-group.selectors';
import { State } from './recommendation-group.type';

@Injectable()
export class RecommendationGroupService implements ArrayStore<RecommendationGroup> {
  readonly all: Observable<RecommendationGroup[]>;
  readonly id: Observable<number[]>;
  readonly name: Observable<string[]>;
  readonly displayLabel: Observable<string[]>;
  readonly order: Observable<number[]>;
  readonly productId: Observable<number[]>;

  readonly selectedProductId: Observable<number>;
  readonly allByProduct: Observable<RecommendationGroup[]>;

  constructor(protected _store: Store<State>, protected _actions: Actions) {
    this.all = this._all;
    this.allByProduct = this._allByProduct;
    this.selectedProductId = this._selectedProductId;
  }

  getRecommendations(productId: number): void {
    this._store.dispatch(actions.getRecommendationGroups({ by: productId }));
  }

  reset(): void {
    this._store.dispatch(actions.reset());
    this._store.dispatch(recommendationActions.reset());
  }

  getRecommendationsByPlace(place: string): void {
    this._store.dispatch(actions.getPlaceRecommendations({ place }));
  }

  recommendationsByPlace(): Observable<PlaceRecommendationGroup[]> {
    return this._store.select(selectors.recommendationsByPlace);
  }

  placeRecommendation(itemId: number, groupId: number): Observable<Recommendation> {
    return this._store.select(selectors.getPlaceRecommendation(itemId, groupId));
  }

  addPlaceRecommendationToCart(itemId: number, groupId: number): void {
    this._store.dispatch(actions.addPlaceRecommendationToCart({ itemId, groupId }));
  }

  addPlaceRecommendationSuccess(itemId: number, groupId: number): void {
    this._store.dispatch(actions.addPlaceRecommendationToCartSuccess({ itemId, groupId }));
  }

  addPlaceRecommendationError(itemId: number, errorCode: string): void {
    this._store.dispatch(actions.addPlaceRecommendationToCartError({ itemId, errorCode }));
  }

  get placeRecommendationsInLoading(): Observable<number[]> {
    return this._store.select(selectors.placeRecommendationsInLoading);
  }

  // updatePlaceRecommendationQuantity(, quantity: number, place: string): void {
  //   this._store.dispatch(actions.updatePlaceRecommendationQuantity({ itemId, groupId, quantity, place }));

  // }

  private get _allByProduct(): Observable<RecommendationGroup[]> {
    return this._all.pipe(
      withLatestFrom(this._selectedProductId),
      map(([items, id]) => items.filter(({ productId }) => productId === id)),
    );
  }

  private get _selectedProductId(): Observable<number> {
    return this._store.select(selectors.selectedProductId);
  }

  private get _all(): Observable<RecommendationGroup[]> {
    return this._store.select(selectors.all);
  }
}
