<header class="header">
  <a class="icon-btn app-button-color" [routerLink]="['/']" title="All applications">
    <svg width="20" height="17">
      <use xlink:href="/assets/images/icons.svg#home"></use>
    </svg>
  </a>
  <div class="header__title">
    <span *ngIf="appName$ | async as appName">{{ appName }}</span>
  </div>
  <div class="header__account-panel">
    <span [matMenuTriggerFor]="menu" class="icon-btn app-button-color">
      <svg width="14.192" height="20">
        <use xlink:href="/assets/images/icons.svg#user"></use>
      </svg>
    </span>
    <designer-language-select></designer-language-select>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onChangePassword()">
        <span class="icon-btn mat-menu-item--margin app-button-color">
          <lib-icon class="mat-menu-item__icon" icon="changePasswordMain"></lib-icon>
        </span>
        <span>Change password</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <span class="icon-btn mat-menu-item--margin app-button-color">
          <lib-icon class="mat-menu-item__icon" icon="logoutMain"></lib-icon>
        </span>
        <span>Log out</span>
      </button>
    </mat-menu>
  </div>
</header>
