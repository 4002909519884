<div class="element">
  <div
    [class.element__border--error]="isShowError"
    [class.element__border--disabled]="isDisabled"
    class="element__border"
  ></div>
  <textarea
    #input
    class="element__input"
    [class.element__input--disabled]="isDisabled"
    [style.resize]="resize ? 'vertical' : 'none'"
    [formControl]="control"
    [name]="name"
    [placeholder]="placeHolder"
    [style.padding-inline-end]="emoji ? '40px' : '0'"
  ></textarea>
</div>
<app-emoji-picker
  *ngIf="emoji"
  [class.rtl]="isRtl$ | async"
  class="element__emoji-picker"
  [inputControl]="control"
  pickerPosition="bottom"
></app-emoji-picker>
<app-input-errors [ngControl]="ngControl" [showError]="isShowError"></app-input-errors>
