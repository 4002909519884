import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { IconModule } from '@bend/icon';

import { WidgetSpinnerComponent } from './components';
import { ScrolledToDirective } from './directives/scrolled-to.directive';

@NgModule({
  declarations: [WidgetSpinnerComponent, ScrolledToDirective],
  imports: [CommonModule, TranslateModule.forChild(), IconModule, MatTabsModule, MatDialogModule],
  exports: [TranslateModule, IconModule, MatDialogModule, MatTabsModule, WidgetSpinnerComponent, ScrolledToDirective],
  providers: [CurrencyPipe],
})
export class SharedModule {}
