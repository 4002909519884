import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import {
  Catalog,
  CatalogChoice,
  CatalogsCategoriesResponse,
  Category,
  categoryImages,
  PopCatalogType,
  PopPackage,
} from '../product-catalog-v2';
import { ProductCatalogV2Service } from '../product-catalog-v2.service';

@Component({
  selector: 'pop-widget-edit-product-catalog-v2-form',
  templateUrl: './catalog-v2-form.component.html',
  styleUrls: ['./catalog-v2-form.component.scss'],
})
export class PopWidgetEditProductCatalogV2FormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() catalog: Catalog;
  @Input() catalogChoices: CatalogChoice[];
  @Input() categoriesGroupedByCatalog: CatalogsCategoriesResponse;
  @Input() appSlug: string;
  @Input() showErrors: boolean;
  @Output() deleted = new EventEmitter();
  isLoaded: boolean;
  isMenuLoading: boolean;
  catalogChoicesClone: CatalogChoice[] = [];
  categories: Category[] = [];
  catalogType: PopCatalogType;
  packages: PopPackage[] = [];
  categoryImages: object[];

  constructor(private catalogService: ProductCatalogV2Service) {
    this.categoryImages = categoryImages;
  }

  ngOnInit(): void {
    this._markSelectedCatalogChoice(this.catalog, this.catalogChoices);
    if (!this.form.get('imageMeta').value.url) {
      this.form.get('imageMeta').reset();
    }
    this.isLoaded = true;
  }

  delete(): void {
    this.deleted.emit();
  }

  onCatalogSelected(event: any): void {
    const index = parseInt(event.currentTarget.value, 10);
    const selectedCatalogChoice = this.catalogChoicesClone[index];

    this.form.controls['catalogId'].setValue(selectedCatalogChoice.catalogId);
    this.form.controls['catalogType'].setValue(selectedCatalogChoice.catalogType);
    this.form.controls['visibleCategories'].reset();

    this._updateCategories(selectedCatalogChoice.catalogId);
    this._updateCatalogType(selectedCatalogChoice.catalogType);
    this._downloadPackages(selectedCatalogChoice);
  }

  private _markSelectedCatalogChoice(catalog: Catalog, choices: CatalogChoice[]): void {
    const catalogsClone: CatalogChoice[] = JSON.parse(JSON.stringify(choices));

    const choice = catalogsClone.find(
      item => item.catalogId.toString() === catalog.catalogId && item.catalogType === catalog.catalogType,
    );

    if (choice) {
      choice.isSelected = true;
      this._updateCategories(choice.catalogId);
      this._updateCatalogType(choice.catalogType);
      this._downloadPackages(choice);
    }

    this.catalogChoicesClone = catalogsClone;
  }

  private _updateCategories(catalogId: number): void {
    this.categories = this.categoriesGroupedByCatalog[catalogId];
  }

  private _updateCatalogType(catalogType: PopCatalogType): void {
    this.catalogType = catalogType;
  }

  private _downloadPackages(catalogChoice: CatalogChoice): void {
    if (catalogChoice.catalogType !== PopCatalogType.Menu) {
      return;
    }

    this.isMenuLoading = true;
    this.catalogService.getPackages(catalogChoice.catalogId).subscribe((packages: PopPackage[]) => {
      this.packages = packages;
      this.isMenuLoading = false;
    });
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get catalogId(): UntypedFormControl {
    return this.form.get('catalogId') as UntypedFormControl;
  }
}
