import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { settingsActions } from 'projects/store/src/lib/settings';
import { map } from 'rxjs';

import { loadAppState } from './actions';
import { AppService } from './app-state.service';

@Injectable()
export class AppEffects {
  constructor(private readonly actions$: Actions, private appStateService: AppService) {}

  loadAppState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(settingsActions.getSettingsByPlaceSuccess),
      map(() => loadAppState()),
    );
  });
}
