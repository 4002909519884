import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService,PlaceName, Smile } from '../shared';
import { GenerateSmiles, GetSmiles, GetSmilesSuccess, UpdateSmilesPageId } from './smiles.type';

@Injectable()
export class SmilesHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getSmiles({ limit, skip, search }: GetSmiles): Observable<GetSmilesSuccess> {
    return this._api.smiles('v1').pipe(
      switchMap(api =>
        this._http.get<GetSmilesSuccess>(api, {
          params: {
            $limit: limit.toString(),
            $skip: skip.toString(),
            ...(search && { $search: search }),
          },
        }),
      ),
    );
  }

  editSmiles({ id, name, placeId, placeName, url }: Partial<Smile>): Observable<Smile> {
    return this._api.editSmile('v1', id).pipe(
      switchMap(api =>
        this._http.patch<Smile>(api, {
          placeName,
          url,
          name,
          placeId,
        }),
      ),
    );
  }

  getSmileById(id: string): Observable<Smile> {
    return this._api.editSmile('v1', id).pipe(switchMap(api => this._http.get<Smile>(api)));
  }

  getSmilePlace(): Observable<PlaceName[]> {
    return this._api.smilePlace('v1').pipe(switchMap(api => this._http.get<PlaceName[]>(api)));
  }

  generateSmiles(generation: GenerateSmiles): Observable<GenerateSmiles> {
    return this._api.generateSmiles('v1').pipe(switchMap(api => this._http.post<GenerateSmiles>(api, generation)));
  }

  updateSmilesPageId(settings: UpdateSmilesPageId): Observable<any> {
    return this._api
      .updateSmilesPageId('v1')
      .pipe(switchMap(api => this._http.patch(api, {}, { params: { ...settings } })));
  }
}
