import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuCategory } from '../shared';
import * as selectors from './menu-category.selectors';
import { State } from './menu-category.type';

@Injectable({ providedIn: 'root' })
export class MenuCategoryService {
  constructor(private _store: Store<State>) {}

  menuCategoriesByMenu(menuId: number): Observable<MenuCategory[]> {
    return this._store
      .select(selectors.all)
      .pipe(map(categories => categories.filter(category => category.parentId === menuId)));
  }
}
