import { Address } from './address.type';
import { CustomerInfo } from './customer-info.type';
import { OrderDetails } from './order-details.type';
import { OrderUserApi } from './order-user.type';

export interface OrderApi<T = string> extends Omit<Order, 'updatedAt'> {
  orderUsers: OrderUserApi[];
  updatedAt: T;
}

export interface Order {
  id: number;
  orderDisplayId: number;
  currentOrderUserId: number;
  status: string;
  updatedAt: Date;
  createdAt: Date;
  wasOrdered: boolean;
  totalInfo: TotalInfo;
  details: OrderDetails;
  address?: Address;
  customerInfo?: CustomerInfo;
  isLoading: boolean;
  errorCode: null | string;
}

export interface OrderItemAdd {
  userId: string;
  comment?: string;
  orderItem: {
    id: number;
    quantity: number;
    categoryId: number;
    options: { id: number; quantity: number }[];
    recommendations: { id: number; quantity: number }[];
  };
  type?: string;
}

export interface TotalInfo {
  total: number;
  totalWithTips: number;
  totalPaid: number;
  totalTips: number;
}

export const enum OrderStatus {
  New = 'new',
  Send = 'send',
  Paid = 'paid',
  PartallyReady = 'partially-ready',
  Ready = 'ready',
  Delivered = 'delivered',
  PartaillyHanded = 'partially-handed', // for agent-view
  Handed = 'handed', // for agent-view
  Closed = 'closed',
}
