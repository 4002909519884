import { Injectable } from '@angular/core';

import { PopWidget } from '../types';

@Injectable()
export class WidgetListHelper {
  toggleWidgetCollapse(widget: PopWidget, uncollapsedWidgetSet: Set<string>): Set<string> {
    widget.isNotCollapsed = !widget.isNotCollapsed;
    return this.saveCollapsedState(widget, uncollapsedWidgetSet);
  }

  saveCollapsedState(widget: PopWidget, uncollapsedWidgetSet: Set<string>): Set<string> {
    const isSaved = uncollapsedWidgetSet.has(widget._id);
    if (widget.isNotCollapsed && !isSaved) {
      uncollapsedWidgetSet.add(widget._id);
      return uncollapsedWidgetSet;
    }
    if (!widget.isNotCollapsed && isSaved) {
      uncollapsedWidgetSet.delete(widget._id);
      return uncollapsedWidgetSet;
    }
  }

  computeWidgetName(widget: PopWidget): string {
    let description = '';
    switch (widget.type) {
      case 'image':
        description = widget.attributes.alt;
        break;
      case 'title':
        description = this.computeShortText(widget.attributes.title);
        break;
      case 'documents':
        const { documents } = widget.attributes;
        description = documents && documents.length > 0 ? documents[0].name : '';
        break;
      case 'wifi':
        description = widget.attributes.name;
        break;
      case 'share':
        description = widget.attributes.subject;
        break;
      case 'profile':
        description = widget.attributes.name;
        break;
      case 'twitter_htag':
        description = widget.attributes.htag;
        break;
      case 'twitter_profile':
        description = widget.attributes.name;
        break;
      case 'poll':
        description = this.computeShortText(widget.attributes.question);
        break;
      case 'buttons':
        description = widget.attributes.title;
        break;
      case 'carousel':
        description = widget.attributes.title;
        break;
      case 'welcome':
        description = widget.attributes.text;
        break;
      case 'link':
        description = widget.attributes.title;
        break;
      case 'text':
        const text = widget.attributes.text;
        if (text) {
          description = this.prepareHtmlTextDescription(text);
        }
        break;

      case 'shop_teaser':
        description = widget.attributes.title;
        break;
      case 'action_call':
        description = widget.attributes.title;
        break;
    }

    const result = description ? `${widget.name} - ${description}` : widget.name;
    return result;
  }

  computeShortText(longText: string): string {
    const MAX_TEXT_LENGTH = 20;
    let shortText = '';
    shortText = longText && longText.length > MAX_TEXT_LENGTH ? longText.substr(0, MAX_TEXT_LENGTH) : longText;

    return shortText;
  }

  setWidgetNameWhenNull(widgets: PopWidget[]): PopWidget[] {
    widgets.forEach((widget: PopWidget) => {
      if (!widget.name) {
        const firstLetter = widget.type.slice(0, 1);
        widget.name = firstLetter.toUpperCase() + widget.type.slice(1);
      }
    });

    return widgets;
  }

  replaceHtmlEntities(text: string): string {
    const htmlEntities = {
      '&nbsp;': '',
      '&amp;': '&',
      '&quot;': '"',
      '&lt;': '<',
      '&gt;': '>',
    };
    for (const key in htmlEntities) {
      if (htmlEntities.hasOwnProperty(key)) {
        text = text.replace(new RegExp(key, 'gi'), htmlEntities[key]);
      }
    }
    text = text.replace(/&#(\d+);/gi, (match, decimal) => String.fromCharCode(decimal));

    return text;
  }

  prepareHtmlTextDescription(text: string): string {
    const lengthToDisplay = 20;
    let description = '';

    description = text.replace(/<\/?[^>]+>/g, '');
    description = this.replaceHtmlEntities(description);
    const totalLength = description.length;
    description = description.substr(0, lengthToDisplay);
    if (totalLength > lengthToDisplay) {
      description += '...';
    }

    return description;
  }
}
