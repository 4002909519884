import { AfterViewInit, Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({ selector: '[is-loaded]' })
export class IsLoadedDirective implements OnInit, AfterViewInit {
  private readonly DEFAULT_OFFSET = 20;

  @Output() loaded = new EventEmitter<{ element: ElementRef }>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.loaded.emit({ element: this.el });
  }

  /**
   * Handle if the element is above the viewport, and fix bottom style if it is.
   * */
  ngAfterViewInit(): void {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const isElementAboveViewport = rect.top < 0;

    if (isElementAboveViewport) {
      this.renderer.setStyle(
        this.el.nativeElement,
        'bottom',
        `${this.bottom + Number(rect.top) - this.DEFAULT_OFFSET}px`,
      );
    }
  }

  private get bottom(): number {
    return Number((<HTMLElement>this.el.nativeElement).style.bottom);
  }
}
