import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { ShareWidget } from './share';

@Component({
  selector: 'pop-widget-edit-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditShareComponent extends WidgetBaseComponent<ShareWidget> implements OnInit {
  static widgetName = 'share';

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        body: [this.widget.attributes.body],
        subject: [this.widget.attributes.subject],
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
