import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, iif, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { settingsActions as actions } from './settings.actions';
import { SettingsHttpService } from './settings.http.service';
import * as selectors from './settings.selectors';
import { State } from './settings.type';

@Injectable()
export class SettingsEffects {
  loadSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getSettings),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedBy))),
      mergeMap(([{ by }, isLoadedBy]) =>
        iif(
          () => isLoadedBy,
          of(actions.getSettingsIsLoaded()),
          this._settingsHttp.get().pipe(
            map(settings => actions.getSettingsSuccess({ by, settings: { ...settings, id: by } })),
            catchError(({ errorCode = actions.getSettings.type }) =>
              of(actions.getSettingsError({ error: errorCode })),
            ),
          ),
        ),
      ),
    ),
  );

  updateSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateSettings),
      mergeMap(({ by }) =>
        this._settingsHttp.get().pipe(
          map(settings => actions.updateSettingsSuccess({ settings: { ...settings, id: by } })),
          catchError(({ errorCode = actions.getSettings.type }) =>
            of(actions.updateSettingsError({ error: errorCode })),
          ),
        ),
      ),
    ),
  );

  loadSettingsByPlace$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getSettingsByPlace),
      withLatestFrom(this._store.pipe(select(selectors.isLoadedBy))),
      mergeMap(([{ by }, isLoadedBy]) =>
        iif(
          () => isLoadedBy,
          of(actions.getSettingsByPlaceIsLoaded()),
          this._settingsHttp.get(by).pipe(
            map(settings => actions.getSettingsByPlaceSuccess({ by, settings: { ...settings, id: by } })),
            catchError(({ errorCode = actions.getSettings.type }) =>
              of(actions.getSettingsByPlaceError({ error: errorCode })),
            ),
          ),
        ),
      ),
    ),
  );

  updateSettingsByPlace$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.updateSettingsByPlace),
      mergeMap(({ by }) =>
        this._settingsHttp.get(by).pipe(
          map(settings => actions.updateSettingsByPlaceSuccess({ settings: { ...settings, id: by } })),
          catchError(({ errorCode = actions.getSettings.type }) =>
            of(actions.updateSettingsByPlaceError({ error: errorCode })),
          ),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _settingsHttp: SettingsHttpService, private _store: Store<State>) {}
}
