import { IKioskPrinterService, KioskOrderDetail, StoreData } from './printer-factory.service';

interface IEoapi {
  extInvoke(methodName: string, args: any[]): void;
}

declare global {
  // eslint-disable-next-line no-var
  var eoapi: IEoapi;

  /**
   * Overload the window object
   */
  interface Window {
    printReceipt: (storeData: string, orderDetail: string) => void;
  }
}

const printReceipt = (storeData: string, orderDetail: string): void => {
  eoapi.extInvoke('PRINT_RECEIPT', [storeData, orderDetail]);
};

window.printReceipt = printReceipt;

function _window(): Window {
  return window;
}

export class PLVPrinterService implements IKioskPrinterService {
  get nativeWindow(): Window {
    return _window();
  }

  printReceipt(storeData: StoreData, orderDetail: KioskOrderDetail): void {
    this.nativeWindow.printReceipt(JSON.stringify(storeData), JSON.stringify({ orderDetail }));
  }
}
