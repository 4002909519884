<h2 mat-dialog-title class="text">Reset the labels?</h2>
<mat-dialog-content class="text description">
  This will reset all the labels to their initial values for {{ data.lang.toUpperCase() }} language. By doing this, all
  changes made will be lost. Are you sure you want to continue?
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons">
    <designer-stroked-button color="warn" class="buttons_cancel" mat-dialog-close>
      <span>Cancel</span>
    </designer-stroked-button>
    <designer-stroked-button color="primary" [mat-dialog-close]="true">
      <span>Reset</span>
    </designer-stroked-button>
  </div>
</mat-dialog-actions>
