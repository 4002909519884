import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiVisitorService, WorkingHoursInterval } from '../shared';

@Injectable()
export class WorkingHoursHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  getCatalogWorkingHours(catalogId: number): Observable<WorkingHoursInterval[]> {
    return this._api.workingHours('v1').pipe(
      switchMap(api => this._http.get<WorkingHoursInterval<string>[]>(api, { params: { catalogId } })),
      map(intervals => intervals.map(({ open, close }) => ({ open: new Date(open), close: new Date(close) }))),
    );
  }
}
