import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectPageIdParam } from '@designer-store/router';

import { featureName } from './page.config';
import { pageAdapter } from './page.reducer';
import { PageState } from './page.state';

const selectPageState = createFeatureSelector<PageState>(featureName);

const { selectEntities, selectAll } = pageAdapter.getSelectors();

export const pageEntities = createSelector(selectPageState, selectEntities);
export const selectPages = createSelector(selectPageState, selectAll);
export const selectCurrentPage = createSelector(pageEntities, selectPageIdParam, (entities, id) => entities[id]);

export const selectIsLoadingPagesList = createSelector(selectPageState, state => state.isLoadingList);
export const selectIsLoadingPage = createSelector(selectPageState, state => state.isLoadingPage);
export const selectIsDeletingPageById = createSelector(selectPageState, state => state.isDeletingById);
export const selectPagePreviewVariable = createSelector(selectPageState, state => state.previewVariable);
