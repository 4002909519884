import { ModuleWithProviders, NgModule } from '@angular/core';

import { ScrollService } from './scroll.service';

@NgModule()
export class ScrollModule {
  // TODO: useless since no params passed to the module, use standard module definition instead
  static forRoot(): ModuleWithProviders<ScrollModule> {
    return {
      ngModule: ScrollModule,
      providers: [ScrollService],
    };
  }
}
