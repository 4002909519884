import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { CustomWidget } from './custom';

@Component({
  selector: 'pop-widget-edit-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditCustomComponent extends WidgetBaseComponent<CustomWidget> implements OnInit {
  static widgetName = 'custom';
  @Input() appHost: string;
  @Input() pages: Page[];
  finalUrl: string;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
  }

  get url(): UntypedFormControl {
    return this.form.get('url') as UntypedFormControl;
  }

  private _initForm(): void {
    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        url: [this.widget.attributes.url],
        style: this.formBuilder.group({
          margin: this.formBuilder.group({
            bottom: [this.widget.attributes.style.margin.bottom],
            top: [this.widget.attributes.style.margin.top],
          }),
          fullWidth: [this.widget.attributes.style.fullWidth],
          height: [this.widget.attributes.style.height],
          bounds: [this.widget.attributes.style.bounds],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
