import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { pageActions } from './page.actions';
import { PageModel } from './page.models';
import { PageState } from './page.state';

export const pageAdapter: EntityAdapter<PageModel> = createEntityAdapter<PageModel>({
  selectId: ({ _id }) => _id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: PageState = pageAdapter.getInitialState({
  isLoadingList: false,
  isLoadingPage: false,
  isDeletingById: {},
  previewVariable: new Date().getTime(),
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(pageActions.getPages, state => ({ ...state, isLoadingList: true })),
  on(pageActions.getPagesSuccess, (state, { pages }) => pageAdapter.setAll(pages, { ...state, isLoadingList: false })),
  on(pageActions.getPagesError, (state, { error }) => ({ ...state, isLoadingList: false, error })),

  on(pageActions.getPage, state => ({ ...state, isLoadingPage: true })),
  on(pageActions.getPageSuccess, (state, { page }) => pageAdapter.upsertOne(page, { ...state, isLoadingPage: false })),
  on(pageActions.getPageError, (state, { error }) => ({ ...state, isLoadingPage: false, error })),

  on(pageActions.createPage, state => state),
  on(pageActions.createPageSuccess, (state, { page }) => pageAdapter.addOne(page, state)),
  on(pageActions.createPageError, (state, { error }) => ({ ...state, error })),

  on(pageActions.updatePage, state => state),
  on(pageActions.updatePageSuccess, (state, { page: { _id, ...changes } }) =>
    pageAdapter.updateOne({ id: _id, changes }, state),
  ),
  on(pageActions.updatePageError, (state, { error }) => ({ ...state, error })),

  on(pageActions.deletePage, (state, { pageId }) => ({
    ...state,
    isDeletingById: { ...state.isDeletingById, [pageId]: true },
  })),
  on(pageActions.deletePageSuccess, (state, { pageId }) =>
    pageAdapter.removeOne(pageId, {
      ...state,
      isDeletingById: { ...state.isDeletingById, [pageId]: false },
    }),
  ),
  on(pageActions.deletePageError, (state, { pageId, error }) => ({
    ...state,
    isDeletingById: { ...state.isDeletingById, [pageId]: false },
    error,
  })),

  on(pageActions.duplicatePage, state => state),
  on(pageActions.duplicatePageSuccess, (state, { page }) => pageAdapter.addOne(page, state)),
  on(pageActions.duplicatePageError, (state, { error }) => ({ ...state, error })),

  on(pageActions.refreshPreview, state => ({ ...state, previewVariable: new Date().getTime() })),

  on(pageActions.reset, state => pageAdapter.removeAll(state)),
);
