import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { CkeditorConfigService } from '../../shared/services/ckeditor-config.service';
import { CatalogItem, ProductItem } from '../product-catalog/product-catalog';
import { ProductCatalogService } from '../product-catalog/product-catalog.service';
import { WidgetService } from '../widget.service';
import {
  ProductTeaserLabel,
  ProductTeaserLabelGroup,
  ProductTeaserLabels,
  ProductTeaserLabelsValues,
  ProductTeaserWidget,
  VerticalAlignType,
} from './product-teaser';

@Component({
  selector: 'pop-widget-edit-product-teaser',
  templateUrl: './product-teaser.component.html',
  styleUrls: ['./product-teaser.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditProductTeaserComponent extends WidgetBaseComponent<ProductTeaserWidget> implements OnInit {
  static widgetName = 'product_teaser';

  @Input() appSlug: string;
  options;
  catalogs: CatalogItem[];
  products: ProductItem[];
  verticalAlignTypes: string[];
  labels: ProductTeaserLabel[];
  labelGroups: ProductTeaserLabelGroup[];
  labelName = ProductTeaserLabelsValues;

  protected editor = this.ckeditorConfigService.Editor;
  protected editorConfig = this.ckeditorConfigService.EditorConfig;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
    private _catalogService: ProductCatalogService,
    private ckeditorConfigService: CkeditorConfigService,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.labels = this._getLabels();
    this.labelGroups = this._getLabelGroups(this.labels);
    this._initForm();
    this.verticalAlignTypes = Object.values(VerticalAlignType);

    this.options = {
      forcePasteAsPlainText: true,
    };

    this.getCatalog();

    if (this.catalogId.value) {
      this.selectCatalog();
    }

    if (this.useProductFromUrl) {
      document.getElementById('ckEditor').style.display = 'none';
    }

    this.useProductFromUrlControl.valueChanges.subscribe(val => {
      document.getElementById('ckEditor').style.display = val ? 'none' : 'block';
    });
  }

  getCatalog(): void {
    this._catalogService.getCatalogs().subscribe(catalogs => {
      this.catalogs = catalogs;
    });
  }

  selectCatalog(): void {
    if (this.catalogId.value) {
      this.products = [];
      this._catalogService.getProducts(this.catalogId.value).subscribe(products => {
        this.products = products;
      });
    }
  }

  private _getLabelGroup(labels: ProductTeaserLabel[], widget: ProductTeaserWidget): Record<string, string> {
    const group = {};

    labels.forEach(label => {
      group[label.key] = [widget.attributes.labels[label.key]];
    });

    return group;
  }

  private _getLabels(): ProductTeaserLabel[] {
    const productTeaserLabels = new ProductTeaserLabels();
    const labels = Object.keys(productTeaserLabels).map(key => {
      return {
        key,
        name: productTeaserLabels[key],
      };
    });

    return labels;
  }

  private _addMissingAttributes(widget: ProductTeaserWidget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = new ProductTeaserLabels();
    }
  }

  private _getLabelGroups(labels: ProductTeaserLabel[]): ProductTeaserLabelGroup[] {
    const groupNames = ['Product catalog', 'Product description', 'Is it for', 'Popups'];

    const groups: ProductTeaserLabelGroup[] = groupNames.map(name => {
      return {
        name,
        labels: [],
      };
    });

    labels.forEach(label => {
      let groupIndex: number;
      if (['PLEASE_SELECT_THE_REQUIRED_OPTIONS', 'COMMENT_TO_KITCHEN_REQUIRED', 'REQUIRED'].includes(label.key)) {
        groupIndex = 0;
      } else if (['ADD_COMMENT_TO_KITCHEN', 'ADD_TO_CART', 'YOUR_COMMENT_HERE'].includes(label.key)) {
        groupIndex = 1;
      } else if (
        [
          'ITS_FOR',
          'ME',
          'SHARE_WITH_TABLE',
          'YOUR_NAME',
          'OTHERS_NAME',
          'OTHER_PEOPLE',
          'PLEASE_ENTER_THE_NAME',
          'SAVE',
        ].includes(label.key)
      ) {
        groupIndex = 2;
      } else if (['PRODUCT_ADDED'].includes(label.key)) {
        groupIndex = 3;
      }
      groups[groupIndex].labels.push(label);
    });

    return groups;
  }

  get text(): UntypedFormControl {
    return this.form.get('text') as UntypedFormControl;
  }

  get imageUrl(): UntypedFormControl {
    return this.form.get('imageUrl') as UntypedFormControl;
  }

  get catalogId(): UntypedFormControl {
    return this.form.get('catalogId') as UntypedFormControl;
  }

  get useProductFromUrl(): string {
    return this.form.get('useProductFromUrl').value;
  }

  get useProductFromUrlControl(): UntypedFormControl {
    return this.form.get('useProductFromUrl') as UntypedFormControl;
  }

  get backgroundColor(): UntypedFormControl {
    return this.form.get('style.backgroundColor') as UntypedFormControl;
  }

  get backgroundColorValue(): string {
    return this.backgroundColor.value;
  }

  set backgroundColorValue(color: string) {
    this.backgroundColor.setValue(color);
  }

  get textBackgroundColor(): UntypedFormControl {
    return this.form.get('style.text.backgroundColor') as UntypedFormControl;
  }

  get textBackgroundColorValue(): string {
    return this.textBackgroundColor.value;
  }

  set textBackgroundColorValue(color: string) {
    this.textBackgroundColor.setValue(color);
  }

  private _initForm(): void {
    const labelGroup = this._getLabelGroup(this.labels, this.widget);

    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        text: [this.widget.attributes.text],
        catalogId: [this.widget.attributes.catalogId],
        imageUrl: [this.widget.attributes.imageUrl],
        productId: [this.widget.attributes.productId],
        useProductFromUrl: [this.widget.attributes.useProductFromUrl],
        showKitchenComment: [this.widget.attributes.showKitchenComment],
        isCommentToKitchenRequired: [this.widget.attributes.isCommentToKitchenRequired],
        isReadOnly: [this.widget.attributes.isReadOnly],
        style: this._formBuilder.group({
          margin: this._formBuilder.group({
            bottom: [this.widget.attributes.style.margin.bottom],
            top: [this.widget.attributes.style.margin.top],
          }),
          borderRadius: [this.widget.attributes.style.borderRadius],
          fullWidth: [this.widget.attributes.style.fullWidth],
          backgroundColor: [this.widget.attributes.style.backgroundColor],
          text: this._formBuilder.group({
            margin: [this.widget.attributes.style.text ? this.widget.attributes.style.text.margin : 0],
            verticalAlign: [
              this.widget.attributes.style.text
                ? this.widget.attributes.style.text.verticalAlign
                : VerticalAlignType.CENTER,
            ],
            backgroundColor: [
              this.widget.attributes.style.text ? this.widget.attributes.style.text.backgroundColor : '',
            ],
          }),
        }),
        labels: this._formBuilder.group(labelGroup),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
