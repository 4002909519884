<dialog-background class="background"></dialog-background>
<div class="progress">
  <dialog-icon-wrap>
    <lib-icon icon="dialogProgress" class="progress__icon"></lib-icon>
    <lib-icon icon="dialogProgressCutlery" class="progress__cutlery"></lib-icon>
  </dialog-icon-wrap>
  <dialog-content-wrap>
    <dialog-title *ngIf="title$ | async" [innerHTML]="title$ | async"></dialog-title>
    <dialog-description [innerHTML]="description$ | async"></dialog-description>
  </dialog-content-wrap>
  <dialog-button *ngIf="showCloseButton" (click)="close()" class="progress__button">
    {{ 'OKEY' | translate }}
  </dialog-button>
</div>
