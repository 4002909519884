import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StoreService } from '../shared';
import { Application, Image, ImageEvent } from '../shared/types/image-gallery.type';
import { actions } from './image-gallery.actions';
import * as selectors from './image-gallery.selectors';
import { FileType, State } from './image-gallery.type';

@Injectable()
export class ImageGalleryService extends StoreService<State> {
  constructor(_store: Store<State>, _actions: Actions) {
    super(_store, _actions, selectors);
  }

  getApplications(currentApp: string, fileTypes: FileType[]): Observable<boolean> {
    this._store.dispatch(actions.getApplications({ currentApp, fileTypes }));

    return this._finishedActionStatus(this._error, actions.getApplicationsSuccess, actions.getApplicationsError);
  }

  getImagesByApp(fileTypes: FileType[]): Observable<boolean> {
    this._store.dispatch(actions.getImagesByApp({ fileTypes }));

    return this._finishedActionStatus(this._error, actions.getImagesByAppSuccess, actions.getImagesByAppError);
  }

  uploadImages(files: File[]): Observable<boolean> {
    this._store.dispatch(actions.uploadImages({ files }));

    return this._finishedActionStatus(this._error, actions.uploadImageSuccess, actions.uploadImageError);
  }

  uploadByUrl(url: string): Observable<boolean> {
    this._store.dispatch(actions.uploadImageByUrl({ url }));

    return this._finishedActionStatus(this._error, actions.uploadImageByUrlSuccess, actions.uploadImageByUrlError);
  }

  deleteImage(id: number): Observable<boolean> {
    this._store.dispatch(actions.deleteImage({ id }));

    return this._finishedActionStatus(this._error, actions.deleteImageSuccess, actions.deleteImageError);
  }

  uploadCroppedImage(base64: string, fileName: string): Observable<boolean> {
    this._store.dispatch(actions.uploadCroppedImage({ base64, fileName }));

    return this._finishedActionStatus(this._error, actions.uploadCroppedImageSuccess, actions.uploadCroppedImageError);
  }

  changeApp(app: string): void {
    this._store.dispatch(actions.changeApp({ app }));
  }

  getImageById(id: number): Observable<Image> {
    return this._store.select(selectors.getImageById(id));
  }

  get imagesForUpload(): Observable<ImageEvent[]> {
    return this._store.select(selectors.imagesForUpload);
  }

  get currentApp(): Observable<string> {
    return this._store.select(selectors.currentApp);
  }

  get apps(): Observable<Application[]> {
    return this._store.select(selectors.apps);
  }

  get applicationImages(): Observable<Image[]> {
    return this._store.select(selectors.imagesByApp);
  }

  get isAppsLoaded(): Observable<boolean> {
    return this._store.select(selectors.isAppsLoaded);
  }

  private get _error(): Observable<string> {
    return this._store.select(selectors.errorCode);
  }
}
