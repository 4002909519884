<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="carousel" class="form-label">{{ 'WIDGETS.CAROUSEL.DATA_SOURCE' | translate }}</label>
      <div
        *ngIf="
          carouselId.invalid && (((carouselId.dirty || carouselId.touched) && carouselId.errors.required) || showErrors)
        "
        class="form-error-msg"
      >
        {{ 'WIDGETS.CAROUSEL.CHOOSE_CAROUSEL' | translate }}
      </div>
      <select id="carousel" formControlName="carouselId" class="form-input form-input--select">
        <option *ngFor="let carousel of carousels" [value]="carousel._id">{{ carousel.name }}</option>
      </select>
    </div>
    <div class="form-row">
      <label for="titleInput" class="form-label">{{ 'WIDGETS.SHARED.TITLE' | translate }}</label>
      <input
        id="titleInput"
        type="text"
        class="form-input form-input--text"
        [placeholder]="'WIDGETS.SHARED.TITLE' | translate"
        formControlName="title"
      />
    </div>
    <div class="form-row">
      <label for="subtitleInput" class="form-label">{{ 'WIDGETS.CAROUSEL.SUBTITLE' | translate }}</label>
      <input
        id="subtitleInput"
        type="text"
        class="form-input form-input--text"
        [placeholder]="'WIDGETS.CAROUSEL.SUBTITLE' | translate"
        formControlName="subtitle"
      />
    </div>
    <div class="form-row">
      <input id="openInNewTabInput" type="checkbox" formControlName="openInNewTab" class="form-input-checkbox" />
      <label for="openInNewTabInput" class="form-label form-label--checkbox">
        {{ 'WIDGETS.SHARED.EXTERNAL_TAB' | translate }}
      </label>
    </div>
    <div formGroupName="style">
      <input id="hasSlideBoundsInput" type="checkbox" formControlName="hasSlideBounds" class="form-input-checkbox" />
      <label for="hasSlideBoundsInput" class="form-label form-label--checkbox">
        {{ 'WIDGETS.CAROUSEL.SLIDE_BOUNDS' | translate }}
      </label>
    </div>
    <div formGroupName="style" class="form-row-groups-wrapper">
      <div class="form-row-groups">
        <div class="parameters">
        <div class="form-row-group form-row-group--distanced" formGroupName="margin">
          <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
          <input
            id="marginTopInput"
            type="number"
            formControlName="top"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced" formGroupName="margin">
          <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
          <input
            id="marginBottomInput"
            type="number"
            formControlName="bottom"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="imageBorderRadiusInput" class="form-label">
            {{ 'WIDGETS.STYLE.IMAGE_BORDER_RADIUS' | translate }}
          </label>
          <input
            id="imageBorderRadiusInput"
            type="number"
            formControlName="imageBorderRadius"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
      </div>
        <div class="carousel-picture-section">
      <div class="carousel-picture-title">Carousel Picture</div>
      <div class="carousel-picture">
        <div class="form-row-group form-row-group--distanced">
          <label for="fontSizeInput" class="form-label"> {{ 'WIDGETS.STYLE.TITLE_FONT_SIZE' | translate }}</label>
          <input
            id="fontSizeInput"
            type="text"
            formControlName="fontSize"
            matInput
            [matAutocomplete]="fontSizeAuto"
            class="form-input form-input--number"
          />
          <mat-autocomplete #fontSizeAuto="matAutocomplete">
            <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
          </mat-autocomplete>
          <span class="form-input-number-units">px</span>
          <div class="input-error" *ngIf="fontSize.invalid && fontSize.errors.fontSize">
            {{ 'WIDGETS.SHARED.INTEGER' | translate }}
          </div>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="CarouselfontColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.TITLE_FONT_COLOR' | translate }}
          </label>
          <span
            id="CarouselTextColorInput"
            [(colorPicker)]="carouselfontColorValue"
            [style.backgroundColor]="carouselfontColorValue"
            class="form-colorpicker"
          ></span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="subtitleSizeInput" class="form-label"> {{ 'WIDGETS.STYLE.SUBTITLE_FONT_SIZE' | translate }}</label>
          <input
            id="subtitleSizeInput"
            type="text"
            formControlName="subtitleSize"
            matInput
            [matAutocomplete]="subtitleSizeAuto"
            class="form-input form-input--number"
          />
          <mat-autocomplete #subtitleSizeAuto="matAutocomplete">
            <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
          </mat-autocomplete>
          <span class="form-input-number-units">px</span>
          <div class="input-error" *ngIf="subtitleSize.invalid && subtitleSize.errors.subtitleSize">
            {{ 'WIDGETS.SHARED.INTEGER' | translate }}
          </div>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="CarouselSubtitleColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.SUBTITLE_FONT_COLOR' | translate }}
          </label>
          <span
            id="CarouselSubtitleColorInput"
            [(colorPicker)]="carouselsubtitleColorValue"
            [style.backgroundColor]="carouselsubtitleColorValue"
            class="form-colorpicker"
          ></span>
        </div>
      </div>
      </div>
      <section class="carousel-widget-section">
        <div class="carousel-widget-title">Carousel widget</div>
        <div class="widget-carousel">
        <div class="form-row-group form-row-group--distanced">
          <label for="widgetFontSizeInput" class="form-label"> {{ 'WIDGETS.STYLE.TITLE_FONT_SIZE' | translate }}</label>
          <input
            id="widgetFontSizeInput"
            type="text"
            formControlName="widgetFontSize"
            matInput
            [matAutocomplete]="widgetFontSizeAuto"
            class="form-input form-input--number"
          />
          <mat-autocomplete #widgetFontSizeAuto="matAutocomplete">
            <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
          </mat-autocomplete>
          <span class="form-input-number-units">px</span>
          <div class="input-error" *ngIf="widgetFontSize.invalid && widgetFontSize.errors.widgetFontSize">
            {{ 'WIDGETS.SHARED.INTEGER' | translate }}
          </div>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="CarouselSubtitleColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.TITLE_FONT_COLOR' | translate }}
          </label>
          <span
            id="widgetFontColorInput"
            [(colorPicker)]="widgetFontColorValue"
            [style.backgroundColor]="widgetFontColorValue"
            class="form-colorpicker"
          ></span>
        </div>

        <div class="form-row-group form-row-group--distanced">
          <label for="widgetSubtitleSizeInput" class="form-label">
            {{ 'WIDGETS.STYLE.SUBTITLE_FONT_SIZE' | translate }}
          </label>
          <input
            id="widgetSubtitleSizeInput"
            type="text"
            formControlName="widgetSubtitleSize"
            matInput
            [matAutocomplete]="widgetSubtitleSizeAuto"
            class="form-input form-input--number"
          />
          <mat-autocomplete #widgetSubtitleSizeAuto="matAutocomplete">
            <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
          </mat-autocomplete>
          <span class="form-input-number-units">px</span>
          <div class="input-error" *ngIf="widgetSubtitleSize.invalid && widgetSubtitleSize.errors.widgetSubtitleSize">
            {{ 'WIDGETS.SHARED.INTEGER' | translate }}
          </div>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="widgetSubtitleColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.SUBTITLE_FONT_COLOR' | translate }}
          </label>
          <span
            id="widgetSubtitleColorInput"
            [(colorPicker)]="widgetSubtitleColorValue"
            [style.backgroundColor]="widgetSubtitleColorValue"
            class="form-colorpicker"
          ></span>
        </div>
      </div>
      </section>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
