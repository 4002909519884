import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './recommendation.config';
import { reducer } from './recommendation.reducer';
import { RecommendationService } from './recommendation.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer)],
  providers: [RecommendationService],
})
export class RecommendationStoreModule {}
