import { PopSettings } from '../widget';

export interface AskQuestion {
  question: string;
  name: string;
}

export interface WidgetAskQuestion {
  pageId: string;
  mainColor: string;
  isEditMode: boolean;
  hideQuestions: boolean;
  showOnlyMyQuestions: boolean;
  labels: AskQuestionLabels;
  pop: PopSettings;
}

export interface AskQuestionResponse {
  question: string;
  userName: string;
  user: Visitor;
}

export interface Visitor {
  _id: string;
  name: string;
}

export class AskQuestionLabels {
  BY = '';
  NAME = '';
  QUESTION = '';
  QUESTION_REQUIRED = '';
  SUBMIT_BUTTON = '';
  TITLE = '';
  THANK_YOU = '';
  CLOSE = '';
}

export interface QuestionDialog {
  mainColor: string;
  labels: AskQuestionLabels;
}
