import { UrlQueryParams } from '../types';

export const parseQueryParams = (url: string): UrlQueryParams => {
  /**
   * match ?query=value
   * we can't use lookbehind
   * issue https://stackoverflow.com/a/51568859/3149249
   *
   * with lookbehind
   * const queriesMatch = url.match(/(?<=\?)[^#]+/);
   */
  const queriesMatch = /(?:\?)([^#]+)/g.exec(url)?.slice(1) || [];
  /**
   * check exist queries
   */
  if (!queriesMatch.length) return {};
  /**
   * get first element from match, example query1=value&query2=value
   */
  const [queriesString] = queriesMatch;
  /**
   * convert string in query params
   */
  const queries = queriesString
    /**
     * split all params in ['query1=value', 'query2=value']
     */
    .split(/&/)
    .map(query => {
      /**
       * get key and value from 'query1=value'
       */
      const [key, value] = query.split(/=/);
      /**
       * check is valid query
       */
      if (!value) return null;
      /**
       * return key and value
       */
      return { key, value };
    })
    /**
     * remove null values
     */
    .filter(Boolean)
    /**
     * convert array in dictionary
     */
    .reduce((acc: { [key: string]: string }, { key, value }) => {
      acc[key] = value;

      return acc;
    }, {});

  return queries;
};
