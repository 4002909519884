import { createAction, props } from '@ngrx/store';

const appStateSuccessProps = props<{
  isConsultingMode: boolean;
  isPayOnlyMode: boolean;
}>();

export const loadAppState = createAction('[APP STATE] load');

export const appStateSuccess = createAction('[APP STATE] load success', appStateSuccessProps);

export const appStateError = createAction('[APP STATE] load error', props<{ errorCode: string }>());
