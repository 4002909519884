import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppSettingsService } from '@bend/settings-app';

import { ApplicationService, SnackBarService } from '../../services';
import { ErrorCode, PopAppForDuplicate, PopCatalog, SuccessCode } from '../../types';

@Component({
  templateUrl: './duplicate-app.component.html',
  styleUrls: ['./duplicate-app.component.scss'],
})
export class DuplicateAppComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  isSaving: boolean;
  catalogs: PopCatalog[];
  appId: string;
  private _subscriptions: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DuplicateAppComponent>,
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _appService: ApplicationService,
    private _snackBarService: SnackBarService,
    private _appSettings: AppSettingsService,
  ) {
    this._appSettings.appId.pipe(tap(id => (this.appId = id))).subscribe();
    this._subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      slug: ['', [Validators.required, Validators.pattern('[0-9a-z-]+')]],
      duplicateCatalogs: false,
    });
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;
    const appDuplicateOptions = this._getAppDuplicateOptions;

    this._subscriptions.add(
      this._appService.duplicateApplication(appDuplicateOptions).subscribe({
        next: ({ slug }) => {
          this._snackBarService.success(SuccessCode.DUPLICATED);
          this._router.navigate([`/dashboard/${slug}/pages`]);
          this._close();
        },
        error: ({ errorCode }) => {
          this._snackBarService.error(ErrorCode[errorCode]);
          this._close();
        },
      }),
    );
  }

  private _close(): void {
    this.isSaving = false;
    this.dialogRef.close();
  }

  private get _getAppDuplicateOptions(): PopAppForDuplicate {
    return {
      templateId: this.appId,
      copyTags: true,
      copyTvas: true,
      ...this.form.value,
    };
  }
}
