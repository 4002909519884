import { createAction, props } from '@ngrx/store';

import { Category, createType } from '../shared';
import { storeKey } from './category.config';

const type = createType(storeKey);

export const actions = {
  getCategories: createAction(type('get'), props<{ catalogId: number; tabId: string }>()),
  addCategories: createAction(type('add categories'), props<{ entities: Category[] }>()),
  getCategoriesSuccess: createAction(type('get - success'), props<{ entities: Category[]; tabId: string }>()),
  getCategoriesIsLoaded: createAction(type('get - is loaded'), props<{ catalogId: number; tabId: string }>()),
  getCategoriesError: createAction(type('get - error'), props<{ errorCode: string; tabId: string }>()),

  getCategoriesSingle: createAction(type('get single'), props<{ catalogId: number; tabId: string }>()),
  getCategoriesSingleSuccess: createAction(
    type('get single - success'),
    props<{ entities: Category[]; tabId: string }>(),
  ),
  getCategoriesSingleIsLoaded: createAction(type('get single - is loaded'), props<{ tabId: string }>()),
  getCategoriesSingleError: createAction(type('get single - error'), props<{ errorCode: string; tabId: string }>()),

  getMenuCategories: createAction(type('get menu categories'), props<{ menuId: number; catalogId: number }>()),
  getMenuCategoriesSuccess: createAction(
    type('get menu categories - success'),
    props<{ entities: Category[]; menuId: number }>(),
  ),
  getMenuCategoriesIsLoaded: createAction(type('get menu categories - is loaded'), props<{ menuId: number }>()),
  getMenuCategoriesError: createAction(
    type('get menu categories - error'),
    props<{ errorCode: string; menuId: number }>(),
  ),

  reloadCategories: createAction(type('reload categories')),
};
