import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PopProduct } from '@bend/widgets-old/product-catalog/product-catalog';

import { resetEntitiesQuantity, sortByKey } from '../shared';
import { actions } from './recommendation.actions';
import { State } from './recommendation.type';

export const adapter: EntityAdapter<PopProduct> = createEntityAdapter<PopProduct>({
  selectId: ({ id, groupId }) => `${groupId}-${id}`,
  sortComparer: sortByKey<PopProduct>()('order'),
});

export const initialState: State = adapter.getInitialState({});

const optionReducer = createReducer(
  initialState,
  on(actions.addRecommendations, (state, { entities }) => adapter.addMany(entities, state)),

  on(actions.reset, state => adapter.removeAll(state)),

  on(actions.changeQuantity, (state, { id, groupId, quantity }) =>
    adapter.updateOne(
      {
        id: `${groupId}-${id}`,
        changes: { quantity },
      },
      state,
    ),
  ),

  on(actions.resetQuantity, state => adapter.updateMany(resetEntitiesQuantity<PopProduct>(state.entities), state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return optionReducer(state, action);
}
