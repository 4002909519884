import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

import { State } from '../global';
import { LanguagesConfig } from '../shared/types';
import * as selectors from './params.selectors';
import { Param, QueryParam } from './params.types';

@Injectable({ providedIn: 'root' })
export class ParamsService {
  language: Observable<LanguagesConfig | undefined>;
  languageChanges: Observable<LanguagesConfig | undefined>;

  constructor(private _store: Store<State>) {
    this.language = this._language;
    this.languageChanges = this._languageChanges;
  }

  get addItem(): Observable<string | undefined> {
    return this.addItemChanges.pipe(first());
  }

  get addItemChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.addItem));
  }

  get addMenu(): Observable<string | undefined> {
    return this.addMenuChanges.pipe(first());
  }

  get addMenuChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.addMenu));
  }

  get tabId(): Observable<string | undefined> {
    return this.tabIdChanges.pipe(first());
  }

  get tabIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.tabId));
  }

  get areaId(): Observable<string | undefined> {
    return this.areaIdChanges.pipe(first());
  }

  get areaIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.areaId));
  }

  get appSlug(): Observable<string | undefined> {
    return this.appSlugChanges.pipe(first());
  }

  get appSlugChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.appSlug));
  }

  get locationId(): Observable<string | undefined> {
    return this.locationIdChanges.pipe(first());
  }

  get locationIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.locationId));
  }

  get boothId(): Observable<string | undefined> {
    return this.boothIdChanges.pipe(first());
  }

  get boothIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.boothId));
  }

  get pageId(): Observable<string | undefined> {
    return this.pageIdChanges.pipe(first());
  }

  get paymentConfigId(): Observable<string | undefined> {
    return this.paymentConfigIdChanges.pipe(first());
  }

  get paymentConfigIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.paymentConfigId));
  }

  get pageIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.pageId));
  }

  get mapId(): Observable<string | undefined> {
    return this.mapIdChanges.pipe(first());
  }

  get mapIdChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.mapId));
  }

  get roomName(): Observable<string | undefined> {
    return this.roomNameChanges.pipe(first());
  }

  get roomNameChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.roomName));
  }

  get date(): Observable<string | undefined> {
    return this.dateChanges.pipe(first());
  }

  get dateChanges(): Observable<string | undefined> {
    return this._store.pipe(select(selectors.date));
  }

  params(): Observable<Params>;
  params(params: Param[]): Observable<(string | undefined)[]>;
  params(params?: Param[]): Observable<Params | (string | undefined)[]> {
    return this.paramsChanges(params).pipe(first());
  }

  paramsChanges(): Observable<Params>;
  paramsChanges(params: Param[]): Observable<(string | undefined)[]>;
  paramsChanges(params?: Param[]): Observable<Params | (string | undefined)[]> {
    return this._genericParams(selectors.params, params);
  }

  queryParams(): Observable<Params>;
  queryParams<T = string>(params?: QueryParam[]): Observable<(T | undefined)[]>;
  queryParams<T = string>(params?: QueryParam[]): Observable<Params | (T | undefined)[]> {
    return this.queryParamsChanges(params).pipe(first());
  }

  queryParamsChanges(): Observable<Params>;
  queryParamsChanges<T = string>(params: QueryParam[]): Observable<(T | undefined)[]>;
  queryParamsChanges<T = string>(params?: QueryParam[]): Observable<Params | (T | undefined)[]> {
    return this._genericParams(selectors.queryParams, params);
  }

  private _genericParams(
    selector: MemoizedSelector<State, Params>,
    params?: (Param | QueryParam)[],
  ): Observable<Params | (string | undefined)[]> {
    return this._store.pipe(
      select(selector),
      map(allParams => (params ? params.map(param => allParams[param]) : allParams)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );
  }

  private get _language(): Observable<LanguagesConfig | undefined> {
    return this._languageChanges.pipe(first());
  }

  private get _languageChanges(): Observable<LanguagesConfig | undefined> {
    return this._store.pipe(select(selectors.language));
  }
}
