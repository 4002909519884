<image-cropper
  class="cropper"
  format="png"
  alignImage="center"
  [imageURL]="dataURL"
  [aspectRatio]="ratio"
  [maintainAspectRatio]="maintainAspectRatio"
  (cropperReady)="imageLoaded = true"
  (loadImageFailed)="loadError = true"
  (imageCropped)="croppedImageData = $event.base64"
  [allowMoveImage]="true"
  [(transform)]="imageTransformer"
  (wheel)="onWheel($event)"
></image-cropper>
<ng-container *ngIf="imageLoaded">
  <div class="controls">
    <div class="ratio">
      <button mat-raised-button (click)="changeRatio(RATIO_SQUARE)" color="primary">1:1</button>
      <button mat-raised-button (click)="changeRatio(RATIO_4_3)" color="primary">4:3</button>
      <button mat-raised-button (click)="changeRatio(RATIO_16_9)" color="primary">16:9</button>
      <button mat-raised-button (click)="changeRatio(null)" color="primary">
        <mat-icon>crop_free</mat-icon>
      </button>
    </div>
    <div class="zoom">
      <button mat-raised-button color="accent" (click)="zoomOut()">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button mat-raised-button color="accent" (click)="zoomIn()">
        <mat-icon>zoom_in</mat-icon>
      </button>
    </div>
    <div class="submit">
      <button mat-raised-button (click)="cancel()" color="warn">{{ 'STUDIO.SHARED.CANCEL' | translate }}</button>
      <button mat-raised-button (click)="submit()" color="primary">{{ 'STUDIO.SHARED.SAVE' | translate }}</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!imageLoaded && !loadError">
  <designer-spinner class="preloader" [diameter]="50"></designer-spinner>
</ng-container>

<div *ngIf="loadError" class="error-dialog">
  <h2>Loading error</h2>
  <button mat-raised-button (click)="cancel()" type="button" color="warn">
    {{ 'STUDIO.SHARED.CANCEL' | translate }}
  </button>
</div>
