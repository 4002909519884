import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItemStatus } from '@bend/store-shared';

import { OrderDialogLabels } from '../../../../config';
import { hourScheduledTemplate, is0Min } from '../../../../helpers';
import { BehaviorTapInput } from '../kiosk-mode-behavior/kiosk-mode-behavior.service';
import { OrderStatusServices } from '../order-status/order-status.service';

@Injectable()
export class OrderDialogPreparingService {
  constructor(private _orderStatus: OrderStatusServices, private _dialog: DialogService) {}

  behavior(): MonoTypeOperatorFunction<BehaviorTapInput> {
    const allowStatuses = new Set([
      OrderItemStatus.Preparing,
      OrderItemStatus.ToBePrepared,
      OrderItemStatus.NextForPreparing,
      OrderItemStatus.Ordered,
    ]);

    return tap(([status, , orderNumber, , , isKioskMode]) => {
      // show only in status allowed and !kioskMode
      if (!allowStatuses.has(status) || isKioskMode) return;

      // show in success modal
      this._dialog.success({
        message: this._orderStatus.locationsStatusChanged.pipe(
          first(),
          map(locations => (locations.length < 2 ? OrderDialogLabels.Preparing : OrderDialogLabels.PreparingPart)),
        ),
        interpolateData: { orderNumber },
      });
    });
  }

  scheduledBehavior(): MonoTypeOperatorFunction<BehaviorTapInput> {
    const allowStatuses = new Set([OrderItemStatus.ScheduledForPreparing]);

    return tap(([status, , orderNumber, time]) => {
      // show only in status allowed
      if (!allowStatuses.has(status)) return;

      // show in success modal
      this._dialog.success({
        message: is0Min(new Date(time))
          ? OrderDialogLabels.ScheduledForPreparing0Min
          : OrderDialogLabels.ScheduledForPreparing,
        interpolateData: { orderNumber, time: hourScheduledTemplate(new Date(time)) },
      });
    });
  }
}
