import { OrderItemStatus } from './order-item.type';
import { Prices } from './prices.type';

export interface Item extends Product {
  categoryId: number;
  catalogId: number;
  description: string;
  isMenu?: boolean;
}

export interface Product {
  id: number;
  parentId: number;
  name: string;
  order: number;
  priceFinalValue: number;
  priceInitialValue: number;
  price: number;
  imageUrl: string;
  quantityMultiplier: number;
  quantity: number;
  prices: Prices;
  available: boolean;
  status?: OrderItemStatus;
  isReadOnly?: boolean;
  snoozeBeginsAt?: string;
  snoozeEndsAt?: string;
  isDefault: boolean;
}
