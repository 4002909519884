import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { Catalog } from '../shared';
import { actions } from './catalog.actions';
import { State } from './catalog.type';

export const adapter: EntityAdapter<Partial<Catalog>> = createEntityAdapter<Partial<Catalog>>();

export const initialState: State = adapter.getInitialState({
  error: null,
  isLoading: false,
  isLoadedBy: [],
  selectedNextId: null,
  selectedId: null,
});

const catalogReducer = createReducer(
  initialState,

  on(actions.getCatalog, (state, { by }) => ({
    ...state,
    isLoading: true,
    selectedNextId: by,
  })),

  on(actions.getCatalogIsLoaded, state => ({
    ...state,
    isLoading: false,
    selectedNextId: null,
  })),

  on(actions.getCatalogError, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    selectedNextId: null,
  })),

  on(actions.getCatalogSuccess, (state, { catalog, by }) =>
    adapter.addOne(catalog, {
      ...state,
      isLoading: false,
      isLoadedBy: [...state.isLoadedBy, by],
      selectedId: by,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return catalogReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
