import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '../../api';
import {
  OrderItem,
  PaygreenResponseParams,
  Payment,
  PaymentRequest,
  StripeResponseParams,
  TPAResponseParams,
} from '../../types';

@Injectable({ providedIn: 'root' })
export class PaymentHttpService {
  constructor(private _api: ApiVisitorService, private _http: HttpClient) {}

  payment<T extends SubType<OrderItem, 'id'>>(params: PaymentRequest<T>): Observable<Payment> {
    return this._api.payment('v1').pipe(switchMap(api => this._http.post<Payment>(api, params)));
  }

  orderItemsCommand<T extends SubType<OrderItem, 'id'>>(orderItemsIds: T[]): Observable<void> {
    return this._api.orderItemsCommand('v1').pipe(switchMap(api => this._http.post<void>(api, { orderItemsIds })));
  }

  paymentCash(): Observable<void> {
    return this._api.paymentCash('v1').pipe(switchMap(api => this._http.post<void>(api, {})));
  }

  stripeResponse(params: StripeResponseParams): Observable<void> {
    return this._api.stripeResponse('v1').pipe(switchMap(api => this._http.post<void>(api, params)));
  }

  paygreenResponse(params: PaygreenResponseParams): Observable<void> {
    return this._api.paygreenResponse('v1').pipe(switchMap(api => this._http.post<void>(api, params)));
  }

  tpaResponse(params: TPAResponseParams): Observable<void> {
    return this._api.tpaResponse('v1').pipe(switchMap(api => this._http.post<void>(api, params)));
  }
}
