import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import {
  EmailNotification,
  GenericNotification,
  NotificationSettings,
  NotificationSettingsLabels,
  NotificationsRoutes,
  SmsNotification,
  StoreService,
} from '../shared';
import { actions } from './notifications.actions';
import { NotificationsHttpService } from './notifications.http.service';
import * as selectors from './notifications.selectors';
import { State } from './notifications.type';

@Injectable()
export class NotificationsService extends StoreService<State> {
  emailPosPdfNotifications: Observable<EmailNotification[]>;
  emailNotifications: Observable<EmailNotification[]>;
  smsNotifications: Observable<SmsNotification[]>;
  ids: Observable<string[] | number[]>;
  isLoaded: Observable<boolean>;
  currentAppSlug: Observable<string>;
  errorCode: Observable<string>;

  constructor(
    protected _store: Store<State>,
    protected _actions: Actions,
    protected _httpService: NotificationsHttpService,
  ) {
    super(_store, _actions, selectors);

    this.emailPosPdfNotifications = this._emailPosPdfNotifications;
    this.emailNotifications = this._emailNotifications;
    this.smsNotifications = this._smsNotifications;
    this.ids = this._ids;
    this.isLoaded = this._isLoaded;
    this.currentAppSlug = this._currentAppSlug;
    this.errorCode = this._errorCode;
  }

  updateNotification(notificationId: number, settings: GenericNotification): Observable<boolean> {
    this._store.dispatch(actions.updateNotification({ notificationId, settings }));

    return this._finishedAction(actions.updateNotificationSuccess, actions.updateNotificationError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this._errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  getNotificationByType(currentType: NotificationsRoutes, appSlug: string): Observable<boolean> {
    this._store.dispatch(actions.getNotificationByType({ currentType, appSlug }));

    return this._finishedAction(actions.getNotificationByTypeSuccess);
  }

  addCustomNotification(settings: Partial<GenericNotification>): Observable<boolean> {
    this._store.dispatch(actions.addCustomNotification({ settings }));

    return this._finishedAction(actions.addCustomNotificationSuccess, actions.addCustomNotificationError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this._errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  deleteCustomNotification(notification: Partial<GenericNotification>): Observable<boolean> {
    this._store.dispatch(actions.deleteCustomNotification({ notification }));

    return this._finishedAction(actions.deleteCustomNotificationSuccess, actions.deleteCustomNotificationError).pipe(
      switchMap(isSuccess =>
        isSuccess ? of(isSuccess) : this._errorCode.pipe(switchMap(errorCode => throwError(() => errorCode))),
      ),
    );
  }

  defaultSettings(): Observable<NotificationSettings> {
    return this._httpService.defaultSettings();
  }

  updateDefaults(settings: Partial<NotificationSettings>): Observable<NotificationSettingsLabels> {
    return this._httpService.updateDefaults(settings);
  }

  private get _emailPosPdfNotifications(): Observable<EmailNotification[]> {
    return this._store.select(selectors.selectEmailPosPdf).pipe(
      filter<EmailNotification[]>(Boolean),
      map(notifications =>
        [...notifications].sort(notification => (notification.businessLocationId === null ? -1 : 1)),
      ),
    );
  }

  private get _emailNotifications(): Observable<EmailNotification[]> {
    return this._store.select(selectors.selectEmail).pipe(
      filter<EmailNotification[]>(Boolean),
      map(notifications =>
        [...notifications].sort(notification => (notification.businessLocationId === null ? -1 : 1)),
      ),
    );
  }

  private get _smsNotifications(): Observable<SmsNotification[]> {
    return this._store.select(selectors.selectSms).pipe(
      filter<SmsNotification[]>(Boolean),
      map(notifications =>
        [...notifications].sort(notification => (notification.businessLocationId === null ? -1 : 1)),
      ),
    );
  }

  private get _ids(): Observable<string[] | number[]> {
    return this._store.select(selectors.ids);
  }

  private get _currentAppSlug(): Observable<string> {
    return this._store.select(selectors.currentAppSlug);
  }

  private get _errorCode(): Observable<string> {
    return this._store.select(selectors.errorCode);
  }

  private get _isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoaded);
  }
}
