import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Ticket } from '../shared';
import { actions } from './tickets.actions';
import { State } from './tickets.type';

export const adapter: EntityAdapter<Ticket> = createEntityAdapter<Ticket>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  limit: 20,
  offset: 0,
  total: 1,
  userId: null,
});

const userReducer = createReducer(
  initialState,
  on(actions.getTickets, state => ({ ...state, isLoading: true, isLoaded: false })),
  on(actions.getTicketsSuccess, (state, { tickets, userId }) =>
    adapter.setAll(tickets, {
      ...state,
      userId,
      isLoading: false,
      isLoaded: true,
    }),
  ),
  on(actions.editTicket, state => ({ ...state, isLoading: true })),
  on(actions.editTicketSuccess, (state, { ticket }) => adapter.updateOne({ id: ticket.id, changes: ticket }, state)),
  on(actions.editTicketError, state => ({ ...state, isLoading: false })),
);

export function reducer(state: State | undefined, action: Action): State {
  return userReducer(state, action);
}
