<h1 md-dialog-title>Confirmation</h1>
<div md-dialog-content>
  <p>
    Somebody has started to pay by credit card but has not finished. Are you sure you want to pay all the cart by cash ?
  </p>
</div>
<div md-dialog-actions>
  <button mat-button [mat-dialog-close]="true" tabindex="2">Yes</button>
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
</div>
