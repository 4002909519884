import { ModuleWithProviders, NgModule } from '@angular/core';

import { ENV } from './env.token';
import { EnvDesigner, EnvVisitor } from './env.type';

@NgModule()
export class EnvModule {
  static forRoot<T extends EnvVisitor | EnvDesigner>(env: T): ModuleWithProviders<any> {
    return {
      ngModule: EnvModule,
      providers: [
        {
          provide: ENV,
          useValue: env,
        },
      ],
    };
  }
}
