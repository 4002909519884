<div *ngIf="isValid; else elseBlock" class="widget-document">
  <h2 class="widget-document-title">{{ 'DOCUMENTS.GOOD_TO_READ' | translate }}</h2>
  <div *ngFor="let document of attributes.documents">
    <a
      *ngIf="document.url"
      class="link-document"
      [target]="document.type === 'pdf' ? '_blank' : '_self'"
      [href]="document.url"
      download
    >
      <div class="document-infos">
        <img class="img-file" alt="" src="../../../assets/img/documents/file.svg" width="40" />
        <div class="file-details">
          <h4 class="file-name">{{ document.name }}</h4>
          <p class="file-type-and-size">{{ getDocumentFooter(document) }}</p>
        </div>
      </div>
      <div class="download-icon">
        <svg
          width="50"
          viewBox="0 0 32 31"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          style="
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 1;
          "
        >
          <g transform="matrix(1,0,0,1,-5441.65,-1829.62)">
            <g transform="matrix(0.243201,0,0,1,5382.56,878.783)">
              <path
                d="M370.774,966.334C370.774,958.055 343.138,951.334 309.097,951.334L306.677,951.334C272.637,951.334 245,958.055 245,966.334C245,974.612 272.637,981.334 306.677,981.334L309.097,981.334C343.138,981.334 370.774,974.612 370.774,966.334Z"
                style="fill: none; stroke-width: 1.37px"
                [style.stroke]="attributes.mainColor"
              />
            </g>
            <g transform="matrix(3.95009e-17,0.645098,-0.645098,3.95009e-17,12694.7,211.288)">
              <path
                d="M2520,11223.8L2520,11232L2520,11219.3L2520,11206.6L2520,11214.9L2532.68,11214.9L2532.68,11206.6L2545.36,11219.3L2532.68,11232L2532.68,11223.8L2520,11223.8Z"
                [style.fill]="attributes.mainColor"
              />
            </g>
          </g>
        </svg>
      </div>
    </a>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-document">{{ noValidMessage }}</div>
</ng-template>
