import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, iif, Observable, of, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '../../api/api-visitor.service';
import { CapacityPreview } from '../../types';

@Injectable({ providedIn: 'root' })
export class CapacityService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  get getCapacity(): Observable<CapacityPreview> {
    return this._api.orderCapacity('v1').pipe(
      switchMap(api => this._http.get<CapacityPreview<string> | null>(api)),
      this._castToDate(),
      switchMap(capacity => iif(() => !!capacity, of(capacity), EMPTY)),
    );
  }

  private _castToDate(): OperatorFunction<CapacityPreview<string> | null, CapacityPreview | null> {
    return map(capacity => {
      if (!capacity) return null;

      const { time, ...rest } = capacity;

      return { ...rest, time: new Date(time) };
    });
  }
}
