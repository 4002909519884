import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class SplitBillItemService {
  private _selectedItems: BehaviorSubject<number[]>;

  constructor() {
    this._selectedItems = new BehaviorSubject(null);
  }

  setItems(items: number[]): void {
    this._selectedItems.next(items);
  }

  addItem(itemId: number): void {
    const updatedSelectedItems = [...this._selectedItems.value, itemId];

    this._selectedItems.next(updatedSelectedItems);
  }

  removeItem(itemId: number): void {
    const updatedSelectedItems = this._selectedItems.value.filter(id => id !== itemId);

    this._selectedItems.next(updatedSelectedItems);
  }

  get selectedItems(): Observable<number[]> {
    return this._selectedItems.asObservable().pipe(distinctUntilChanged());
  }
}
