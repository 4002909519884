import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { FileUploadDetails } from '../../file-upload/file-upload';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { MapWidget } from './map';

@Component({
  selector: 'pop-widget-edit-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditMapComponent extends WidgetBaseComponent<MapWidget> implements OnInit {
  static widgetName = 'map';
  routeFileControl: string;
  routeSuccess: boolean;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._initForm();
    const { pins } = this.widget.attributes;
    if (pins && pins.length) {
      for (const pin of pins) {
        const data = {
          icon: pin['icon'],
          coords: pin['coords'],
          title: pin['title'],
        };
        this.addPins(data);
      }
    } else {
      this.addPins();
    }

    this.routeFileControl = '';
    this.routeSuccess = false;
  }

  addPins(value: { coords: object } = { coords: {} }): void {
    const control = this.form.controls['pins'] as UntypedFormArray;
    control.push(this.initPins(value as { coords: Record<string, { lat: number; lng: number }> }));
  }

  initPins(value: { coords: Record<string, { lat: number; lng: number }> } = { coords: {} }): UntypedFormGroup {
    return this.formBuilder.group({
      coords: this.formBuilder.group({
        lat: [value['coords']['lat']],
        lng: [value['coords']['lng']],
      }),
      title: [value['title']],
      icon: [value['icon']],
    });
  }

  removePins(index: number): void {
    const control = this.form.controls['pins'] as UntypedFormArray;
    control.removeAt(index);
  }

  get pinsControls(): UntypedFormControl[] {
    return (this.form.get('pins') as UntypedFormArray).controls as UntypedFormControl[];
  }

  get routeCoordsControls(): UntypedFormArray {
    return this.form.controls['route']['controls']['coords'] as UntypedFormArray;
  }

  get firstPinLong(): UntypedFormArray {
    return this.form.get('pins').value[0].coords.lng as UntypedFormArray;
  }

  get firstPinLat(): UntypedFormArray {
    return this.form.get('pins').value[0].coords.lat as UntypedFormArray;
  }

  get color(): UntypedFormControl {
    return this.form.get('route.color') as UntypedFormControl;
  }

  get colorValue(): string {
    return this.color.value;
  }

  set colorValue(color: string) {
    this.color.setValue(color);
  }

  onFileChange(event: FileUploadDetails, control: UntypedFormControl): void {
    control.setValue(event.url);
  }

  onUploadRoute(event: FileUploadDetails): void {
    if (
      event.body &&
      event.body['attributes'] &&
      event.body['attributes']['route'] &&
      event.body['attributes']['route']['coords']
    ) {
      for (let i = 0; i < event.body['attributes']['route']['coords'].length; i++) {
        const coords = this.formBuilder.group({
          lat: [event.body['attributes']['route']['coords'][i]['lat']],
          lng: [event.body['attributes']['route']['coords'][i]['lng']],
        });
        this.routeCoordsControls.push(coords);
      }
      this.routeSuccess = true;
    }
  }

  private _initForm(): void {
    const { route, style, zoom } = this.widget.attributes;

    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        pins: this.formBuilder.array([]),
        route: this.formBuilder.group({
          coords: this.formBuilder.array([]),
          color: [route ? route.color : ''],
        }),
        style: this.formBuilder.group({
          borderRadius: [style ? style.borderRadius : ''],
          fullWidth: [style ? style.fullWidth : ''],
          height: [style ? style.height : ''],
          margin: this.formBuilder.group({
            bottom: [style && style.margin ? style.margin.bottom : ''],
            top: [style && style.margin ? style.margin.top : ''],
          }),
        }),
        zoom: this.formBuilder.group({
          fitBounds: [zoom ? zoom.fitBounds : ''],
          focus: this.formBuilder.group({
            zoom: [zoom && zoom.focus ? zoom.focus.zoom : ''],
            coords: this.formBuilder.group({
              lat: [zoom && zoom.focus && zoom.focus.coords ? zoom.focus.coords.lat : ''],
              lng: [zoom && zoom.focus && zoom.focus.coords ? zoom.focus.coords.lng : ''],
            }),
          }),
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
