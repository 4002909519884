import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'designer-confirm-popin',
  templateUrl: './confirm-popin.component.html',
  styleUrls: ['./confirm-popin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPopinComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() cancelLabel: string;
  @Input() confirmLabel: string;
  @Output() closeConfirmPopin = new EventEmitter<boolean>();
}
