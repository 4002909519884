import { Application } from '@designer-store/apps';
import { User } from '@designer-store/users';

export interface UsersState {
  users: User[];
  totalCount: number;
  currentApplication: Application;
  loadedUsersByApp: Application[];
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}
