import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslateWrapperService {
  private _rtlLangs = ['he'];

  constructor(private _translate: TranslateService) {}

  get isRtlTextDirection(): Observable<boolean> {
    return this._translate.onLangChange.pipe(
      startWith(this._translate.currentLang),
      map(() => this._rtlLangs.includes(this._translate.currentLang)),
    );
  }
}
