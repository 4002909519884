export interface Gdpr {
  labels: {
    RGPD_ACCEPT: string;
    RGPD_MESSAGE: string;
    RGPD_SEE_LINK: string;
    DENY_ALL: string;
  };
  showGdpr: boolean;
  url: string;
}
