import {
  Address,
  BottomBar,
  CapacityManagement,
  CurrencySettings,
  DeliverySettings,
  DictionaryWorkingHoursIntervals,
  Gdpr,
  PartialNullify,
  PromoCodeSettings,
  SettingsBehavior,
  SettingsCart,
  SettingsCatalog,
  SettingsGoogleAnalytics,
  SettingsHotjarAnalytics,
  SettingsPayment,
  SettingsPop,
  SettingsPos,
  SettingsPsp,
  SettingsTakeAway,
  SettingsTips,
  SmartWifiSettings,
  TopBar,
  WorkingHours,
} from '.';
import { DictionaryHappyHoursIntervals, HappyHours } from './happy-hours.type';

export interface SettingsLocation {
  general: PartialNullify<SettingsGeneralLocation>;
  behavior: PartialNullify<SettingsBehavior>;
  pop: PartialNullify<SettingsPop>;
  tips: PartialNullify<SettingsTips>;
  workingHours: PartialNullify<WorkingHours<string, DictionaryWorkingHoursIntervals>>;
  happyHours: PartialNullify<HappyHours<string, DictionaryHappyHoursIntervals>>;
  googleAnalytics: PartialNullify<SettingsGoogleAnalytics>;
  hotjarAnalytics: PartialNullify<SettingsHotjarAnalytics>;
  topBar: PartialNullify<TopBar>;
  bottomBar: PartialNullify<BottomBar>;
  cart: PartialNullify<SettingsCart>;
  productCatalog: PartialNullify<SettingsCatalog>;
  gdpr: PartialNullify<Gdpr>;
  capacity: PartialNullify<CapacityManagement>;
  companyAddress: PartialNullify<Address>;
  pos: PartialNullify<SettingsPos>;
  promoCode: PartialNullify<PromoCodeSettings>;
  delivery: PartialNullify<DeliverySettings>;
  takeAway: PartialNullify<SettingsTakeAway>;
  psp: PartialNullify<SettingsPsp>;
  sw: PartialNullify<SmartWifiSettings>;
  paymentMethods: PartialNullify<SettingsPayment>;
}

export interface CreatedBusinessLocation {
  id?: number;
  name: string;
  isKitchen?: boolean;
}

export interface SettingsGeneralLocation {
  currency: CurrencySettings;
  defaultPage: string;
  defaultPlace: string;
  id: number;
  name: string;
  phone: {
    prefix: string;
  };
  timezone: string;
  fiscalInfo: {
    restaurantAddress: string;
    taxRateNumber: string;
    siretNumber: string;
  };
}
