import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { Application } from '@designer-store/apps';
import * as appsSelectors from '@designer-store/apps/apps.selectors';
import { User, UsersHttpService } from '@designer-store/users';
import { usersActions } from '@designer-store/users/users.actions';

@Injectable({ providedIn: 'root' })
export class UsersEffects {
  constructor(private _actions$: Actions, private _store: Store, private _usersHttp: UsersHttpService) {}

  getUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(usersActions.getUsers),
      switchMap(({ pagination, search, appSlug }) =>
        this._usersHttp.getUsers(pagination.offset, pagination.limit, search, appSlug).pipe(
          map(({ docs, count }) => usersActions.getUsersSuccess({ docs, count })),
          catchError(({ errorCode }) => of(usersActions.getUsersError({ errorCode }))),
        ),
      ),
    ),
  );

  addUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(usersActions.addUser),
      switchMap(({ user: { applications, ...rest }, appSlug }) => {
        const backendUser = {
          ...rest,
          allowedAppsSlugs: appSlug ? [appSlug] : applications.map(({ slug }) => slug),
        };

        return this._usersHttp.addUser(backendUser, appSlug).pipe(
          switchMap(({ _id }) => of(usersActions.addUserSuccess({ user: { ...backendUser, applications, _id } }))),
          catchError(({ errorCode }) => of(usersActions.addUserError({ errorCode }))),
        );
      }),
    ),
  );

  deleteUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(usersActions.deleteUser),
      switchMap(({ id, appSlug }) =>
        this._usersHttp.deleteAdminUser(id, appSlug).pipe(
          switchMap(() => of(usersActions.deleteUserSuccess({ id }))),
          catchError(({ errorCode }) => of(usersActions.deleteUserError({ errorCode }))),
        ),
      ),
    ),
  );

  getUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(usersActions.getUser),
      switchMap(({ id, appSlug }) =>
        this._usersHttp.getUser(id, appSlug).pipe(
          switchMap(user =>
            this._store
              .select(appsSelectors.apps)
              .pipe(map(apps => usersActions.getUserSuccess({ user: this._transformUser(user, apps) }))),
          ),
          catchError(({ errorCode }) => of(usersActions.getUserError({ errorCode }))),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(usersActions.updateUser),
      switchMap(({ user, appSlug }) =>
        this._usersHttp.updateUser(user, appSlug).pipe(
          switchMap(() => {
            const { password, ...updatedUser } = user;
            return of(
              user.applications.find(e => e.slug === appSlug)
                ? usersActions.updateUserSuccess({ user: updatedUser })
                : usersActions.deleteUserSuccess({ id: user._id }),
            );
          }),
          catchError(({ errorCode }) => of(usersActions.updateUserError({ errorCode }))),
        ),
      ),
    ),
  );

  private _transformUser(user: User, apps: Application[]): User {
    return {
      ...user,
      name: user.name,
      email: user.email,
      applications: user.allowedAppsSlugs.map(slug => apps.find(app => app.slug === slug)),
    };
  }
}
