import { createAction, props } from '@ngrx/store';

import { createType, Menu } from '../shared';
import { storeKey } from './menu.config';

const type = createType(storeKey);

export const actions = {
  addMenus: createAction(type('add menu'), props<{ entities: Menu[] }>()),
};
