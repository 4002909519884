import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith, tap } from 'rxjs/operators';

import { SplitBillTypes } from '@bend/store-shared';

import { SplitBillService } from '../split-bill/split-bill.service';
import { SplitBillItemService } from '../split-bill-some-items/split-bill-some-items.service';

@Injectable()
export class SplitBillControlerService implements OnDestroy {
  private _splitBillControl: BehaviorSubject<UntypedFormControl>;
  private _subscription: Subscription;

  constructor(private _splitBill: SplitBillService, private _splitBillItem: SplitBillItemService) {
    this._splitBillControl = new BehaviorSubject(new UntypedFormControl(null));
    this._subscription = new Subscription();

    this._subscription.add(this._resetAmount());
    this._subscription.add(this._resetItems());
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  setControl(type: SplitBillTypes): void {
    this._splitBillControl.value.patchValue(type);
  }

  get control(): Observable<UntypedFormControl> {
    return this._splitBillControl.asObservable().pipe(distinctUntilChanged());
  }

  private _resetItems(): Subscription {
    return this._splitBillControl.value.valueChanges
      .pipe(
        startWith(this._splitBillControl.value),
        tap(() => this._splitBillItem.setItems([])),
      )
      .subscribe();
  }

  private _resetAmount(): Subscription {
    return this._splitBillControl.value.valueChanges
      .pipe(
        startWith(this._splitBillControl.value),
        tap(() => this._splitBill.setAmount(0)),
      )
      .subscribe();
  }
}
