import { ModuleWithProviders, NgModule } from '@angular/core';

import { PageService } from './page.service';

@NgModule()
export class PageModule {
  static forRoot(): ModuleWithProviders<PageModule> {
    return {
      ngModule: PageModule,
      providers: [PageService],
    };
  }
}
