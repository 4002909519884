import { DictionaryWorkingHoursIntervals, PartialNullify, WorkingHours, WorkingScheduleType } from '@bend/store-shared';

export function isDefinedWorkingHours(
  workingHours: PartialNullify<WorkingHours<string, DictionaryWorkingHoursIntervals>> | null,
): workingHours is WorkingHours<string, DictionaryWorkingHoursIntervals> {
  return Boolean(workingHours?.scheduleType);
}

export function defaultWorkingHours(): WorkingHours<string, DictionaryWorkingHoursIntervals> {
  return {
    aboutToCloseMessage: '',
    closeMessage: '',
    redirectUrl: '',
    scheduleType: WorkingScheduleType.AlwaysOpen,
    intervals: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
  };
}
