import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { QrCodeDialogComponent } from './qr-code-dialog.component';

@Injectable()
export class QrCodeService {
  constructor(private _dialog: MatDialog) {}

  showQrCode(url: string, title: string): void {
    this._dialog.open(QrCodeDialogComponent, {
      data: {
        url,
        title,
      },
    });
  }
}
