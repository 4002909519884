import { ChangeDetectionStrategy, Component, Input, OnInit, TrackByFunction } from '@angular/core';
import { Observable } from 'rxjs';

import { OrderMenuItemsService } from '@bend/store';
import { OrderItemOptionsService } from '@bend/store';
import { OrderItem, OrderItemOption, OrderItemType, OrderMenuItem } from '@bend/store-shared';

@Component({
  selector: 'lib-board-order-item [item]',
  templateUrl: './board-order-item.component.html',
  styleUrls: ['./board-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrderItemComponent implements OnInit {
  @Input() item: OrderItem<OrderItemType.Single>;

  options$: Observable<OrderItemOption[]>;
  menus$: Observable<OrderMenuItem[]>;

  trackByFnItems: TrackByFunction<OrderItemOption> = (_, item) => item.id;
  trackByFnMenus: TrackByFunction<OrderMenuItem> = (_, item) => item.id;

  constructor(private _orderItemOptions: OrderItemOptionsService, private _orderMenus: OrderMenuItemsService) {}

  ngOnInit(): void {
    this.options$ = this._orderItemOptions.byIds(this.item.orderUserId, this.item.id);
    this.menus$ = this._orderMenus.byOrderItemId(this.item.id, this.item.orderUserId);
  }
}
