<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form *ngIf="isValid; else notValidBlock" [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="urlInput" class="form-label">{{ 'WIDGETS.TWITTER_PROFILE.URL' | translate }}</label>
      <div
        *ngIf="url.invalid && (((url.dirty || url.touched) && url.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.URL_ENTER' | translate }}
      </div>
      <input id="urlInput" type="url" class="form-input form-input--url" placeholder="URL" formControlName="url" />
      <span class="form-field-info">Ex: https://twitter.com/OlivierRoussat</span>
    </div>
    <div class="form-row">
      <label for="nameInput" class="form-label">Name</label>
      <div
        *ngIf="name.invalid && (((name.dirty || name.touched) && name.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.NAME' | translate }}
      </div>
      <input
        id="nameInput"
        type="text"
        class="form-input form-input--name"
        [placeholder]="'WIDGETS.SHARED.NAME' | translate"
        formControlName="name"
      />
      <span class="form-field-info">Ex: Olivier Roussat</span>
    </div>
    <div class="form-row">
      <label for="twitterIdInput" class="form-label">{{ 'WIDGETS.TWITTER_PROFILE.ID' | translate }}</label>
      <div
        *ngIf="
          twitterId.invalid && (((twitterId.dirty || twitterId.touched) && twitterId.errors.required) || showErrors)
        "
        class="form-error-msg"
      >
        {{ 'WIDGETS.TWITTER_PROFILE.ID_ENTER' | translate }}
      </div>
      <input
        id="twitterIdInput"
        type="text"
        class="form-input form-input--name"
        placeholder="@ID"
        formControlName="twitterId"
      />
      <span class="form-field-info">Ex: @OlivierRoussat</span>
    </div>
    <div class="form-row-groups">
      <designer-image-gallery formControlName="image"></designer-image-gallery>
      <span class="form-field-info">{{ 'WIDGETS.PROFILE.IMAGE_INFO' | translate }}</span>
    </div>
  </form>
  <ng-template #notValidBlock>{{ 'WIDGETS.SHARED.MISSING_ATTRIBUTES' | translate }}</ng-template>
</pop-widget-edit-widget>
