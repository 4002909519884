import { createAction, props } from '@ngrx/store';

import { createType, Item } from '../shared';
import { storeKey } from './item.config';

const type = createType(storeKey);

export const actions = {
  addItems: createAction(type('add'), props<{ entities: Item[] }>()),

  changeQuantity: createAction(type('change quantity'), props<{ by: number; quantity: number }>()),

  resetQuantity: createAction(type('reset quantity')),

  getMenus: createAction(type('get menus'), props<{ catalogId: number }>()),
  getMenusIsLoaded: createAction(type('get menus - is loaded')),
  getMenusSuccess: createAction(type('get menus - success'), props<{ menus: Item[]; catalogId: number }>()),
  getMenusError: createAction(type('get menus - error'), props<{ errorCode: string }>()),
};
