import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { PrinterStatus } from '../types';
declare let epson: any;

@Injectable()
export class Printer2Service {
  private _printer: any;
  private _ePosDev: any;
  private _status = PrinterStatus.DISCONNECTED;
  private _changeStatusSubject = new BehaviorSubject<PrinterStatus>(PrinterStatus.DISCONNECTED);
  private _errorSubject = new Subject<string>();

  onStatusChanged(): Observable<PrinterStatus> {
    return this._changeStatusSubject.asObservable();
  }

  onError(): Observable<string> {
    return this._errorSubject.asObservable();
  }

  connect(ipAddress: string, port: string, printerName: string): void {
    if (this._status !== PrinterStatus.DISCONNECTED) {
      return;
    }

    this._setStatus(PrinterStatus.CONNECTING);

    if (!this._ePosDev) {
      this._ePosDev = new epson.ePOSDevice();
    }

    this._ePosDev.connect(ipAddress, port, result => this._callback_connect(result, printerName));
  }

  printReceipts(receipts: any[], copiesToPrint: number): void {
    if (this._status !== PrinterStatus.CONNECTED && this._status !== PrinterStatus.CHECKING_STATUS) {
      this._errorSubject.next('Printing failed: printer not connected');
      return;
    }

    this._setStatus(PrinterStatus.PRINTING);

    receipts.forEach(receipt => {
      for (let i = 0; i < copiesToPrint; i++) {
        receipt.forEach(command => {
          if (!Array.isArray(command.args)) {
            this._printer[command.fn](command.args);
          } else if (command.args.length > 0) {
            this._printer[command.fn](...command.args);
          } else {
            this._printer[command.fn]();
          }
        });

        this._printer.addFeed();
        this._printer.addCut(this._printer.CUT_FEED);
        this._printer.send();
      }
    });
  }

  printReceipt(receipt: any, copiesToPrint: number): void {
    this.printReceipts([receipt], copiesToPrint);
  }

  checkStatus(): void {
    if (this._status !== PrinterStatus.CONNECTED) {
      return;
    }

    this._setStatus(PrinterStatus.CHECKING_STATUS);
    this._printer.addCommand('');
    this._printer.send();
  }

  disconnect(): void {
    if (this._status !== PrinterStatus.CONNECTED) {
      return;
    }

    this._setStatus(PrinterStatus.DISCONNECTING);
    this._ePosDev.deleteDevice(this._printer, errorCode => this._callback_deleteDevice(errorCode));
  }

  private _callback_connect(resultConnect: string, deviceId: string): void {
    const options = { crypto: false, buffer: false };
    if (resultConnect === 'OK' || resultConnect === 'SSL_CONNECT_OK') {
      this._ePosDev.createDevice(deviceId, this._ePosDev.DEVICE_TYPE_PRINTER, options, (deviceObj, errorCode) =>
        this._callback_createDevice(deviceObj, errorCode),
      );
    } else {
      this._setStatus(PrinterStatus.DISCONNECTED);
    }
  }

  private _callback_createDevice(deviceObj: any, errorCode: string): void {
    if (deviceObj === null) {
      this._setStatus(PrinterStatus.DISCONNECTED);
      return;
    }

    this._printer = deviceObj;
    this._setStatus(PrinterStatus.CONNECTED);
    this._printer.onreceive = response => this._handleReceive(response);
    this._printer.onerror = response => this._handleError();

    this._printer.timeout = 10000;
  }

  private _handleReceive(response: any): void {
    this._setStatus(PrinterStatus.CONNECTED);

    if (response.code === 'EPTR_COVER_OPEN') {
      this._errorSubject.next('The printer cover is open. Please close it to continue printing.');
    }

    if (response.code === 'EPTR_REC_EMPTY') {
      this._errorSubject.next('Roll paper has run out. Please replace it to continue printing.');
    }
  }

  private _handleError(): void {
    this._setStatus(PrinterStatus.DISCONNECTED);
  }

  private _callback_deleteDevice(errorCode: string): void {
    if (errorCode !== 'OK') {
      this._setStatus(PrinterStatus.DISCONNECTED);
      this._errorSubject.next(`Printer disconnecting failed: ${errorCode}`);
      return;
    }

    this._ePosDev.disconnect();
    this._printer = null;
    this._setStatus(PrinterStatus.DISCONNECTED);
  }

  private _setStatus(status: PrinterStatus): void {
    this._status = status;
    this._changeStatusSubject.next(status);
  }
}
