<div
  *ngIf="isValid; else elseBlock"
  class="widget-image"
  [style.marginTop.px]="attributes.style.margin.top"
  [style.marginBottom.px]="attributes.style.margin.bottom"
  [style.marginRight.px]="widthStyle.marginRight"
  [style.marginLeft.px]="widthStyle.marginLeft"
  (click)="navigate()"
  [class.hoverable]="attributes.link"
>
  <div
    class="widget-image-content"
    [style.width.px]="widthStyle.width"
    [style.height.px]="attributes.style.height"
    [style.backgroundColor]="attributes.style.backgroundColor"
  >
    <img
      [src]="attributes.url"
      [alt]="attributes.alt"
      [style.maxHeight.px]="attributes.style.height"
      [style.borderRadius.px]="attributes.style.borderRadius"
      class="img"
    />
    <div
      class="text-overlay noselect"
      *ngIf="text"
      [style.padding.px]="attributes.text.margin"
      [style.top.%]="textTop"
      [style.transform]="textTransform"
      [style.background]="attributes.text.backgroundColor"
      [style.width.px]="widthStyle.width"
      [innerHTML]="text"
    ></div>
  </div>
</div>

<ng-template #elseBlock>
  <div *ngIf="attributes.isEditMode" class="widget-image">{{ noValidMessage }}</div>
</ng-template>
