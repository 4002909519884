export interface PopWidget {
  _id: string;
  attributes: any;
  class: string;
  name: string;
  order: number;
  published: boolean;
  type: string;
  pop: PopInfo;
  isNotCollapsed: boolean;
  computedName: string;
  tabId: string;
  validators?: any[];
}

export interface PopInfo {
  accessType: PopAccessLevel;
}

export enum PopAccessLevel {
  READ_ONLY = 'read-only',
}
