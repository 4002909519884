import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './recommendations-groups-designer.config';
import { RecommendationsGroupsDesignerEffects } from './recommendations-groups-designer.effects';
import { RecommendationsGroupsDesignerHttpService } from './recommendations-groups-designer.http.service';
import { reducer } from './recommendations-groups-designer.reducer';
import { RecommendationsGroupsDesignerService } from './recommendations-groups-designer.service';

@NgModule({
  imports: [
    StoreModule.forFeature(storeKey, reducer),
    EffectsModule.forFeature([RecommendationsGroupsDesignerEffects]),
  ],
  providers: [RecommendationsGroupsDesignerHttpService, RecommendationsGroupsDesignerService],
})
export class RecommendationsGroupsDesignerStoreModule {}
