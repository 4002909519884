export interface Application {
  appSlug: string;
  name: string;
  countFiles?: number;
}

export interface Image {
  id: number;
  fileName: string;
  domain: string;
  relativePath: string;
  action: string;
  adminId: string;
  hash: string;
  size: number;
  createdAt: string;
  updatedAt: string;
}

export interface SortableImage extends Image {
  relatedToApp: string;
}

export interface ImageEvent {
  id: string;
  image: SortableImage;
}

export enum ImageLoadingStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}
