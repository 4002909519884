import { ModuleWithProviders, NgModule } from '@angular/core';

import { WorkingHoursService } from './working-hours.service';

@NgModule()
export class WorkingHoursModule {
  static forRoot(): ModuleWithProviders<WorkingHoursModule> {
    return {
      ngModule: WorkingHoursModule,
      providers: [WorkingHoursService],
    };
  }
}
