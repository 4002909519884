import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './promo-codes.config';
import { adapter } from './promo-codes.reducer';
import { State } from './promo-codes.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const promoCodes = createSelector(stateSelector, selectAll);

export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const error = createSelector(stateSelector, state => state.error);
export const isLoadedByAppSlug = createSelector(stateSelector, state => state.isLoadedByAppSlug);

export const total = createSelector(stateSelector, state => state.total);
export const limit = createSelector(stateSelector, state => state.limit);
export const offset = createSelector(stateSelector, state => state.offset);
