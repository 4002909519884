import { IKioskPrinterService, KioskOrderDetail, StoreData } from './printer-factory.service';

declare global {
  interface Window {
    chrome?: {
      webview?: {
        postMessage: (message: string) => void;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        addEventListener: (event: string, callback: (event: { action: string; data: any }) => void) => void;
      };
    };
  }
}

function _window(): Window {
  return window;
}

interface SendMessage {
  action: 'PRINT_RECEIPT';
  data: {
    storeData: StoreData;
    orderDetail: KioskOrderDetail;
  };
}

export class KioskPrinterService implements IKioskPrinterService {
  get nativeWindow(): Window {
    return _window();
  }

  printReceipt(storeData: StoreData, orderDetail: KioskOrderDetail): void {
    const sendMessage: SendMessage = {
      action: 'PRINT_RECEIPT',
      data: {
        storeData,
        orderDetail,
      },
    };
    window.chrome.webview.postMessage(JSON.stringify(sendMessage));
  }
}
