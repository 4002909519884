import { createAction, props } from '@ngrx/store';

import { createType, PosSettings } from '../shared';
import { storeKey } from './pos-settings.config';

const type = createType(storeKey);

export const actions = {
  getPosSettingsList: createAction(type('get - pos list'), props<{ appSlug: string }>()),
  getPosSettingsListIsLoaded: createAction(type('get - pos list is loaded')),
  getPosSettingsListSuccess: createAction(
    type('get - pos list success'),
    props<{
      posSettings: PosSettings[];
      appSlug: string;
    }>(),
  ),
  getPosSettingsListError: createAction(type('get - pos list error'), props<{ errorCode: string }>()),

  getPosSettings: createAction(type('get - pos'), props<{ posSettingsId: number }>()),
  getPosSettingsIsLoaded: createAction(type('get - pos is loaded'), props<{ posSettingsId: number }>()),
  getPosSettingsSuccess: createAction(type('get - pos success'), props<{ posSettings: PosSettings }>()),
  getPosSettingsError: createAction(type('get - pos error'), props<{ errorCode: string }>()),

  addPosSettings: createAction(type('add - pos'), props<{ posSettings: Partial<PosSettings> }>()),
  addPosSettingsSuccess: createAction(type('add - pos success'), props<{ posSettings: PosSettings }>()),
  addPosSettingsError: createAction(type('add - pos error'), props<{ errorCode: string }>()),

  deletePosSettings: createAction(type('delete - pos'), props<{ posSettingsId: number }>()),
  deletePosSettingsSuccess: createAction(type('delete - pos success'), props<{ posSettingsId: number }>()),
  deletePosSettingsError: createAction(type('delete - pos error'), props<{ errorCode: string }>()),

  updatePosSettings: createAction(type('update - pos'), props<{ posSettings: PosSettings }>()),
  updatePosSettingsSuccess: createAction(type('update - pos success'), props<{ posSettings: PosSettings }>()),
  updatePosSettingsError: createAction(type('update - pos error'), props<{ errorCode: string }>()),

  removePosSettingsId: createAction(type('remove - pos'), props<{ posSettingsId: number }>()),
};
