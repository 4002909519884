import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './catalog.config';
import { CatalogEffects } from './catalog.effects';
import { CatalogHttpService } from './catalog.http.service';
import { metaReducers, reducer } from './catalog.reducer';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer, { metaReducers }), EffectsModule.forFeature([CatalogEffects])],
  providers: [CatalogHttpService],
})
export class CatalogStoreModule {}
