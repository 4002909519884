import { filter, MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';

import { EnvGeneric } from '@bend/env';

import { Auth, Socket as SocketInterface, SocketType } from './socket.type';
export class SocketInstance implements SocketInterface {
  private _socket: Socket;
  private _retryAttempts: number;
  private _message: Subject<any>;

  constructor(
    private _auth: Auth,
    private _env: EnvGeneric,
    private _removeInstance: () => void,
    private channelType: string,
    private channelId?: string,
  ) {
    this._retryAttempts = 0;
    this._message = new Subject();
    this._connect();
  }

  message<T>(): Observable<T> {
    return this._message.asObservable();
  }

  close(): void {
    if (this._socket) {
      this._socket.disconnect();
    }
    this._removeInstance();
  }

  private _onConnect(): void {
    this._retryAttempts = 0;
    this._message.next({ type: SocketType.Open });
  }

  private _onMessage(data: any): void {
    const message = JSON.parse(data);
    this._message.next(message);
  }

  private _onDisconnect(reason: string): void {
    if (reason === 'io server disconnect') {
      this._auth.extendToken();
    }
    if (reason !== 'io client disconnect') {
      this._retry();
      this._message.next({ type: SocketType.Error });
    }
  }

  private _retry(): void {
    this._retryAttempts += 1;
    this._connect(false);
  }

  private _handlingOverload(): MonoTypeOperatorFunction<string> {
    // https://landing.google.com/sre/sre-book/chapters/handling-overload/
    // prevent overloading when you retry connect to wws
    const time = this._retryAttempts * 1000; // 1s
    return delay(time);
  }

  private _onConnectError(): void {
    this._retry();
  }

  /**
   * fistConnect param:
   * first connection will try to connect to wss protocol.
   * if wss will return error, other connections will be through long polling
   * */
  private _connect(firstConnect: boolean = true): void {
    this._auth.socketToken.pipe(this._handlingOverload(), filter(Boolean)).subscribe(token => {
      // Creation of socket instance
      this._socket = this.socketIo(token, firstConnect);

      this._socket.on('connect_error', this._onConnectError.bind(this));
      this._socket.on('connect', this._onConnect.bind(this));
      this._socket.on('message', this._onMessage.bind(this));
      this._socket.on('disconnect', this._onDisconnect.bind(this));
    });
  }

  private socketIo(token: string, firstConnect: boolean): Socket {
    return io(`${this._env.wssHost}/socket/v1/socket.io`, {
      path: '/socket/v1/socket.io',
      multiplex: false,
      forceNew: true,
      addTrailingSlash: true,
      reconnection: false,
      transports: firstConnect ? ['websocket', 'polling'] : ['polling'],
      query: {
        token,
        userType: this._env.userType,
        channelType: this.channelType,
        channelId: this.channelId,
      },
    });
  }
}
