import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './settings.config';
import { SettingsEffects } from './settings.effects';
import { SettingsHttpService } from './settings.http.service';
import { reducer } from './settings.reducer';
import { AreaSettingsService } from './settings-area.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([SettingsEffects])],
  providers: [SettingsHttpService, AreaSettingsService],
})
export class SettingsAreaStoreModule {}
