import { createAction, props } from '@ngrx/store';

import { createType, Tag } from '../shared';
import { storeKey } from './tags.config';

const type = createType(storeKey);

export const actions = {
  getList: createAction(type('get - list'), props<{ appSlug: string }>()),
  getListIsLoaded: createAction(type('get - list is loaded')),
  getListSuccess: createAction(type('get - list success'), props<{ tags: Tag[]; appSlug: string }>()),
  getListError: createAction(type('get - list error'), props<{ errorCode: string }>()),

  get: createAction(type('get'), props<{ id: number }>()),
  getIsLoaded: createAction(type('get - is loaded'), props<{ id: number }>()),
  getSuccess: createAction(type('get - success'), props<{ tag: Tag }>()),
  getError: createAction(type('get - error'), props<{ errorCode: string }>()),

  add: createAction(type('add'), props<{ tag: Partial<Tag> }>()),
  addSuccess: createAction(type('add - success'), props<{ tag: Tag }>()),
  addError: createAction(type('add - error'), props<{ errorCode: string }>()),

  update: createAction(type('update'), props<{ tag: Tag }>()),
  updateSuccess: createAction(type('update - success'), props<{ tag: Tag }>()),
  updateError: createAction(type('update - error'), props<{ errorCode: string }>()),

  delete: createAction(type('delete'), props<{ id: number }>()),
  deleteSuccess: createAction(type('delete - success'), props<{ id: number }>()),
  deleteError: createAction(type('delete - error'), props<{ errorCode: string }>()),
};
