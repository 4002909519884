import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '../../api';
import { Profile } from '../../types';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  saveUserData(data: Partial<Profile>): Observable<Profile> {
    return this._api.me('v1').pipe(switchMap(api => this._http.patch<Profile>(api, data)));
  }

  getMe(): Observable<Profile> {
    return this._api.me('v1').pipe(switchMap(api => this._http.get<Profile>(api)));
  }

  meResendCode(): Observable<void> {
    return this._api.meResendCode('v1').pipe(switchMap(api => this._http.post<void>(api, {})));
  }

  meConfirmPhone(code: string): Observable<void> {
    return this._api.meConfirmPhone('v1').pipe(switchMap(api => this._http.post<void>(api, { code })));
  }
}
