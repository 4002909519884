export interface KioskStylesConfig {
  logo: {
    url: string;
    width: 'default' | 'full';
  };
  sidebar: {
    position: 'default' | 'full';
    backgroundColor: string | 'transparent';
  };
}
