import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { QueryParamsHandlingDirective } from './directives';

@NgModule({
  declarations: [QueryParamsHandlingDirective],
  exports: [QueryParamsHandlingDirective],
  imports: [CommonModule],
})
export class RoutingInternModule {}
