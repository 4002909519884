import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Transaction } from '../shared';
import { actions } from './credit-history.actions';
import { State } from './credit-history.type';

export const adapter: EntityAdapter<Transaction> = createEntityAdapter<Transaction>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
  total: 0,
  offset: 0,
  limit: 10,
});

const userTransactionsReducer = createReducer(
  initialState,
  on(actions.getUserTransactions, state => ({ ...state, isLoading: true })),

  on(actions.getUserTransactionsSuccess, (state, { total, offset, limit, items }) =>
    adapter.setAll(items, {
      ...state,
      offset,
      limit,
      total,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return userTransactionsReducer(state, action);
}
