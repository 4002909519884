import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { ReviewLabel, ReviewLabels, ReviewLabelsValues, ReviewWidget } from './review';

@Component({
  selector: 'pop-widget-edit-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditReviewComponent extends WidgetBaseComponent<ReviewWidget> implements OnInit {
  static widgetName = 'review';
  labels: ReviewLabel[];
  labelValues = ReviewLabelsValues;
  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.labels = this._getLabels();
    this._initForm();
  }

  private _getLabelGroup(labels: ReviewLabel[], widget: ReviewWidget): object {
    const group = {};

    labels.forEach(label => {
      group[label.key] = [widget.attributes.labels[label.key]];
    });

    return group;
  }

  private _getLabels(): ReviewLabel[] {
    const reviewLabels = new ReviewLabels();
    const labels = Object.keys(reviewLabels).map(key => {
      return {
        key,
        name: reviewLabels[key],
      };
    });

    return labels;
  }

  private _addMissingAttributes(widget: ReviewWidget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = new ReviewLabels();
    }
  }

  private _initForm(): void {
    const labelGroup = this._getLabelGroup(this.labels, this.widget);

    this._widgetFormServiceChild.initAttributes(
      this.formBuilder.group({
        labels: this.formBuilder.group(labelGroup),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }
}
