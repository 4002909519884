import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pop-widget-edit-query-param-validator',
  templateUrl: './query-param-validator.component.html',
  styleUrls: ['./query-param-validator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryParamValidatorComponent {
  @Input() form: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  addQueryParamRule(input: HTMLInputElement): void {
    this._createTag(input);
  }

  blurInputTag(input: HTMLInputElement): void {
    this.form.get('rules').markAsTouched();
    this._createTag(input);
  }

  removeQueryParamRule(index: number): void {
    const rules = this.form.get('rules') as UntypedFormArray;
    rules.removeAt(index);
  }

  private _createTag(input: HTMLInputElement): void {
    const rules = this.form.get('rules') as UntypedFormArray;
    const value = input.value.trim();

    if (!value) {
      return;
    }

    rules.push(this._formBuilder.control(value));
    input.value = '';
  }
}
