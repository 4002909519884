import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, withLatestFrom } from 'rxjs';

import { ApiVisitorService, ParamsService, ProductRecommendationGroup } from '@bend/store';

@Injectable()
export class RecommendationsService {
  constructor(
    private api: ApiVisitorService,
    private http: HttpClient,
    private params: ParamsService,
  ) {}

  getRecommendations(productId: number): Observable<ProductRecommendationGroup[]> {
    return this.api.itemRecommendations('v1', productId).pipe(
      withLatestFrom(this.params.queryParams(['place'])),
      switchMap(([api, place]) => this.http.get<ProductRecommendationGroup[]>(api, { params: { place } })),
    );
  }
}
