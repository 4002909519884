import { createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { Order } from '../shared';
import { actions } from './order.actions';
import { State } from './order.type';

export const adapter = createEntityAdapter<Order>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: null,
  isLoadingHistory: true,
  currentId: null,
  errorCode: null,
});

const orderReducer = createReducer(
  initialState,

  on(actions.getOrder, actions.getOrders, actions.newOrder, state => ({ ...state, isLoading: true, error: null })),

  on(actions.getOrders, state => ({ ...state, isLoadingHistory: true, error: null })),

  on(actions.getOrderIsLoaded, state => ({ ...state, isLoading: false })),

  on(actions.getOrderError, actions.getOrdersError, actions.newOrderError, (state, { errorCode }) => ({
    ...state,
    errorCode,
    isLoading: false,
    isLoadingHistory: false,
    isLoaded: false,
  })),

  on(actions.getOrderSuccess, (state, { order }) =>
    adapter.addOne(order, {
      ...state,
      isLoading: false,
      isLoaded: true,
      errorCode: null,
      currentId: order.id,
    }),
  ),

  on(actions.getOrdersSuccess, (state, { orders }) =>
    adapter.upsertMany(orders, {
      ...state,
      errorCode: null,
      isLoadingHistory: false,
    }),
  ),

  on(
    actions.updateOrder,
    actions.updateOrderItem,
    actions.updateOrderDetails,
    actions.updateOrderUserDetails,
    actions.removeOrderItem,
    (state, { id }) => adapter.updateOne({ id, changes: { errorCode: null, isLoading: true } }, state),
  ),

  on(actions.updateOrderIsUpdated, (state, { id }) => adapter.updateOne({ id, changes: { isLoading: false } }, state)),

  on(
    actions.updateOrderError,
    actions.updateOrderItemError,
    actions.updateOrderDetailsError,
    actions.updateOrderUserDetailsError,
    actions.removeOrderItemError,
    (state, { id, changes }) => adapter.updateOne({ id, changes: { ...changes, isLoading: false } }, state),
  ),

  on(
    actions.updateOrderSuccess,
    actions.updateOrderItemSuccess,
    actions.updateOrderDetailsSuccess,
    actions.updateOrderUserDetailsSuccess,
    actions.removeOrderItemSuccess,
    (state, { id, changes }) =>
      adapter.updateOne({ id, changes: { ...changes, errorCode: null, isLoading: false } }, state),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return orderReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
