import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { SettingsService } from '@bend/store';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() placeHolder = 'Place Holder';
  color$: Observable<string>;

  private _value: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private _settings: SettingsService) {
    this.color$ = this._settings.colors.primary;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    if (value === undefined || this._value === value) return;

    this._value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
