import { PopSettings } from '../widget';

export interface WidgetContact {
  pageId: string;
  mainColor: string;
  textColor: string;
  imageUrl?: string;
  alt?: string;
  isEditMode: boolean;
  labels: ContactLabels;
  context: string;
  pop: PopSettings;
}

export interface Contact {
  name: string;
  email: string;
  phone: string;
  context: string;
}

export class ContactLabels {
  CONTACT_BTN = '';
  TITLE = '';
  NAME = '';
  EMAIL = '';
  PHONE = '';
  SUCCESS_MESSAGE = '';
  SUBMIT = '';
  NAME_REQUIRED = '';
  EMAIL_INVALID = '';
}
