<div class="content">
  <span class="content__name">{{ item.itemMeta.name }}</span>

  <ng-container *ngIf="options$ | async as options">
    <lib-items-wrapper *ngIf="options.length">
      <lib-board-order-item-option
        *ngFor="let option of options; trackBy: trackByFnItems"
        [option]="option"
      ></lib-board-order-item-option>
    </lib-items-wrapper>
  </ng-container>

  <ng-container *ngIf="menus$ | async as menus">
    <lib-items-wrapper *ngIf="menus.length">
      <lib-board-order-menu *ngFor="let menu of menus; trackBy: trackByFnMenus" [menu]="menu"></lib-board-order-menu>
    </lib-items-wrapper>
  </ng-container>
</div>

<div class="footer">
  <lib-board-order-item-quantity [item]="item" [showQuantity]="true"></lib-board-order-item-quantity>

  <span>{{ item.price | currency }}</span>
</div>
