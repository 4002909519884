import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '@bend/store';

@Injectable()
export class TermsAndConditionsService {
  constructor(private _settings: SettingsService) {}

  /**
   * @description show T&C as checkbox
   */
  get isShowAsCheckbox(): Observable<boolean> {
    return combineLatest([this._settings.confirmPayment, this._settings.confirmPaymentAsAPopup]).pipe(
      map(([showTermsAndConditions, showAsPopup]) => showTermsAndConditions && !showAsPopup),
    );
  }

  /**
   * @description show T&C as popup
   */
  get isShowAsPopup(): Observable<boolean> {
    return combineLatest([this._settings.confirmPayment, this._settings.confirmPaymentAsAPopup]).pipe(
      map(([showTermsAndConditions, showAsPopup]) => showTermsAndConditions && showAsPopup),
    );
  }
}
