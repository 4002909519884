import { Injectable } from '@angular/core';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';

import { OrderDialogLabels } from '../../../../config';
import { WorkingHoursService } from '../../../working-hours';
import { OrderTypeService } from '../order-type/order-type.service';
import { OrderUserService } from '../order-user/order-user.service';

@Injectable()
export class OtherDialogService {
  constructor(
    private _orderUser: OrderUserService,
    private _dialog: DialogService,
    private _orderType: OrderTypeService,
    private _workingHours: WorkingHoursService,
  ) {}

  paymentInProgress(): void {
    const orderData = this._orderUser.number.pipe(map(orderNumber => ({ orderNumber })));

    this._dialog.progress({
      message: OrderDialogLabels.PaymentInProgress,
      interpolateData: orderData,
    });
  }

  orderClosed(): void {
    this._orderType.isReadOnly
      .pipe(
        first(),
        /**
         * show close dialog only when order is offline not online
         * only eat-in simple
         */
        filter(Boolean),
        /**
         * check working hours
         */
        switchMap(() => this._workingHours.isOpen),
        /**
         * check only first event
         */
        first(),
        /**
         * wee show close message when restaurant is opened
         */
        filter(Boolean),
        /**
         * open close dialog
         */
        tap(() => this._dialog.info({ message: OrderDialogLabels.Close })),
      )
      .subscribe();
  }
}
