import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { DesignerRouterState } from './router.types';

export class RouterSerializer implements RouterStateSerializer<DesignerRouterState> {
  serialize(routerState: RouterStateSnapshot): DesignerRouterState {
    let route = routerState.root;

    let params: Params = {};

    while (route) {
      params = {
        ...params,
        ...route.params,
      };

      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    return { url, params, queryParams };
  }
}
