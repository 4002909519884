import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './credit-history.config';
import { CreditHistoryEffects } from './credit-history.effects';
import { CreditHistoryHttpService } from './credit-history.http.service';
import { reducer } from './credit-history.reducer';
import { CreditHistoryService } from './credit-history.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([CreditHistoryEffects])],
  providers: [CreditHistoryService, CreditHistoryHttpService],
})
export class CreditHistoryStoreModule {}
