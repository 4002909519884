import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { IconModule } from '@bend/icon';
import { TranslateModule } from '@bend/translate';

import {
  BackgroundComponent,
  ButtonComponent,
  ContentWrapComponent,
  DescriptionComponent,
  DialogConfirmComponent,
  DialogErrorComponent,
  DialogInfoComponent,
  DialogProgressComponent,
  DialogSuccessComponent,
  IconWrapComponent,
  TitleComponent,
} from './components';
import { DialogService } from './services';

const commonComponents = [
  TitleComponent,
  ButtonComponent,
  DescriptionComponent,
  IconWrapComponent,
  ContentWrapComponent,
  BackgroundComponent,
];

@NgModule({
  declarations: [
    ...commonComponents,
    DialogErrorComponent,
    DialogInfoComponent,
    DialogSuccessComponent,
    ContentWrapComponent,
    DialogProgressComponent,
    DialogConfirmComponent,
  ],
  imports: [CommonModule, MatDialogModule, IconModule, TranslateModule],
  exports: commonComponents,
})
export class DialogModule {
  static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [DialogService],
    };
  }
}
