import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { NotificationEntity } from '../shared';
import { actions } from './notifications.actions';
import { State } from './notifications.type';

export const adapter: EntityAdapter<NotificationEntity> = createEntityAdapter<NotificationEntity>({
  selectId: ({ appSlug, type }) => `${appSlug}/${type}`,
});

export const initialState: State = adapter.getInitialState({
  errorCode: null,
  currentAppSlug: null,
  isLoading: false,
  currentType: null,
  isLoaded: false,
});

const notificationReducer = createReducer(
  initialState,
  on(
    actions.updateNotification,
    actions.getNotificationByType,
    actions.addCustomNotification,
    actions.deleteCustomNotification,
    state => ({ ...state, isLoading: true }),
  ),

  on(actions.getNotificationByType, (state, { appSlug }) => ({ ...state, currentAppSlug: appSlug, isLoaded: false })),

  /* Success */

  on(actions.updateNotificationSuccess, (state, { notification }) =>
    adapter.upsertOne(
      {
        ...state.entities[state.currentType],
        [notification.transportType]: [...state.entities[state.currentType][notification.transportType]].map(elem =>
          elem.id === notification.id ? notification : elem,
        ),
      },
      state,
    ),
  ),

  on(actions.getNotificationByTypeSuccess, (state, { settings }) =>
    adapter.upsertOne(
      { ...settings },
      { ...state, currentType: `${settings.appSlug}/${settings.type}`, isLoaded: true },
    ),
  ),

  on(actions.getNotificationByTypeIsLoaded, (state, { currentType, appSlug }) => ({
    ...state,
    currentType: `${appSlug}/${currentType}`,
    isLoaded: true,
  })),

  on(actions.addCustomNotificationSuccess, (state, { notification }) =>
    adapter.upsertOne(
      {
        ...state.entities[state.currentType],
        [notification.transportType]: [...state.entities[state.currentType][notification.transportType], notification],
      },
      state,
    ),
  ),

  on(actions.deleteCustomNotificationSuccess, (state, { notification }) =>
    adapter.upsertOne(
      {
        ...state.entities[state.currentType],
        [notification.transportType]: [...state.entities[state.currentType][notification.transportType]].filter(
          elem => elem.id !== notification.id,
        ),
      },
      state,
    ),
  ),

  on(
    actions.updateNotificationSuccess,
    actions.getNotificationByTypeSuccess,
    actions.addCustomNotificationSuccess,
    actions.deleteCustomNotificationSuccess,
    actions.getNotificationByTypeIsLoaded,
    state => ({ ...state, isLoading: false }),
  ),

  /* Error */

  on(
    actions.updateNotificationError,
    actions.getNotificationByTypeError,
    actions.addCustomNotificationError,
    actions.deleteCustomNotificationError,
    (state, { errorCode }) => ({ ...state, errorCode, isLoading: false }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return notificationReducer(state, action);
}
