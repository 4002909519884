import { Injectable } from '@angular/core';

import { EnvService } from '@bend/env';

import { Action, ActionType, TransmitionType } from './action-call';
@Injectable()
export class ActionCallService {
  storageHost: string;

  constructor(private _env: EnvService) {
    this.storageHost = this._env.storageHost;
  }

  getDefaultActions(): Action[] {
    return [
      {
        title: 'Bread',
        icon: '',
        actionMessage: 'Bread is on the way',
        description: 'bread',
        type: ActionType.BREAD,
        body: 'Table <$table_name> needs BREAD',
      },
      {
        title: 'Water',
        icon: '',
        actionMessage: 'Water is on the way',
        description: 'water',
        type: ActionType.WATER,
        body: 'Table <$table_name> needs WATER',
      },
      {
        title: 'Check',
        icon: '',
        actionMessage: 'Check is on the way',
        description: 'check',
        type: ActionType.CHECK,
        body: 'Table <$table_name> needs CHECK',
      },
      {
        title: 'Waiter',
        icon: '',
        actionMessage: 'Waiter called',
        description: 'waiter',
        type: ActionType.WAITER,
        body: 'Table <$table_name> needs WAITER',
      },
      {
        telephone: '',
        transmitionType: TransmitionType.TELEPHONE,
      },
      {
        sms: '',
        transmitionType: TransmitionType.SMS,
      },
      {
        email: '',
        transmitionType: TransmitionType.EMAIL,
      },
      {
        pos: '',
        transmitionType: TransmitionType.POS,
      },
      {
        designer: '',
        transmitionType: TransmitionType.DESIGNER,
      },
    ];
  }
}
