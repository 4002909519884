import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { EnvService } from '@bend/env';
import { Icons } from '@bend/icon';
import { ScriptLoaderService } from '@bend/script-loader';
import { SettingsService } from '@bend/store';

import { InputGeneric } from '../../../../../../../shared-widgets/src/lib/helpers';
import { AutocompleteAddress } from '../../../../../../../shared-widgets/src/lib/types';

interface GoogleAddress {
  street_number: string;
  route: string;
  locality: string;
  postal_code: string;
}

@Component({
  selector: 'app-input-autocomplete-address',
  templateUrl: './input-autocomplete-address.component.html',
  styleUrls: ['./input-autocomplete-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAutocompleteAddressComponent
  extends InputGeneric
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input() placeHolder = '';
  @Input() name = '';
  @Input() icon?: Icons;
  @Input() country?: string;
  @Output() changed: EventEmitter<AutocompleteAddress>;
  @ViewChild('input') input: ElementRef;
  color$: Observable<string>;
  isDisabled: boolean;

  control: UntypedFormControl;

  private _value: string;
  private _checkValidation: boolean;
  private _subscription: Subscription;

  private _componentForm: Dictionary<string>;
  private _autocomplete: google.maps.places.Autocomplete;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _cdr: ChangeDetectorRef,
    private _settings: SettingsService,
    private _script: ScriptLoaderService,
    private _env: EnvService,
    private _zone: NgZone,
  ) {
    super();

    if (this.ngControl) this.ngControl.valueAccessor = this;

    this.control = new UntypedFormControl();

    this.color$ = this._settings.colors.primary;

    this.changed = new EventEmitter();

    this._componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      postal_code: 'short_name',
    };

    this._zone.runOutsideAngular(() =>
      this._script
        .load(`https://maps.googleapis.com/maps/api/js?key=${this._env.googleApiKey}&libraries=places`)
        .then(() => {
          this._initAutocomplete();
        }),
    );

    this._subscription = new Subscription();
  }

  ngAfterViewInit(): void {
    if (this.isExistControl) {
      const subscriptionValueChanges = this.ngControl.control.valueChanges.subscribe(() => this._cdr.detectChanges());

      this._subscription.add(subscriptionValueChanges);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  enable(): void {
    if (this.isExistControl) this.ngControl.control.enable();
  }

  writeValue(value: string): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.notExistControl) return;

    // to validate an input, it must be removed from disabled.
    // When it becomes disabled comes a false event  and you have to skip this event
    if (!isDisabled && this._checkValidation) return;

    if (isDisabled) {
      this._disableState();
    } else {
      this._enableState();
    }

    this._cdr.detectChanges();
  }

  private _setValue(value: string): void {
    if (value === undefined || this._value === value) return;

    this._value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  private _enableState(): void {
    this.control.enable({ emitEvent: false });

    // focuses only when the previous state was true
    if (this.isDisabled) {
      this.input.nativeElement.focus();
    }

    this.isDisabled = false;
  }

  private _disableState(): void {
    if (this._checkValidation) {
      this._checkValidation = false;
      return;
    }

    // https://github.com/angular/angular/issues/11447
    // enable for check validation
    this._checkValidation = true;
    this.ngControl.control.enable({ emitEvent: false });

    const { invalid } = this.ngControl.control;

    if (invalid) {
      // if the item is invalid make input enabled
      this.ngControl.control.reset();
    } else {
      this.ngControl.control.disable({ emitEvent: false });
      this.control.disable({ emitEvent: false });

      this.isDisabled = true;
    }
  }

  private _initAutocomplete(): void {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    this._autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, { types: ['address'] });

    if (this.country) this._autocomplete.setComponentRestrictions({ country: [this.country] });
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this._autocomplete.setFields(['address_component']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this._autocomplete.addListener('place_changed', this._fillInAddress.bind(this));
  }

  private _fillInAddress(): void {
    // Get the place details from the autocomplete object.
    const { address_components } = this._autocomplete.getPlace();

    /**
     * when the user presses Enter and has no value in autocomplete
     * wee need to remove input value
     */
    if (!address_components) {
      this._zone.run(() => this.ngControl.control.reset());

      return;
    }

    const googleAddress: GoogleAddress = address_components.reduce(
      (acc, { types: [type], ...rest }) => {
        if (this._componentForm[type]) acc[type] = rest[this._componentForm[type]];

        return acc;
      },
      { street_number: null, route: null, locality: null, postal_code: null },
    );

    /**
     * set value for angular control from native input
     */
    const autoComplete: string = this.input.nativeElement.value;

    const address: AutocompleteAddress = {
      addressName: autoComplete,
      city: googleAddress.locality,
      street: googleAddress.route,
      streetNumber: googleAddress.street_number,
      zipCode: googleAddress.postal_code,
    };

    this._zone.run(() => {
      this._setValue(autoComplete);
      this.changed.emit(address);
    });
  }
}
