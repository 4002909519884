export interface Prefix {
  name: string;
  code: string;
  prefix: string;
  prefixSeparator: string;
  mask: string;
}

export const prefixesConfig: Prefix[] = [
  { name: 'Afghanistan', code: 'AF', prefix: '+93', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Albania', code: 'AL', prefix: '+355', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Algeria', code: 'DZ', prefix: '+213', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Andorra', code: 'AD', prefix: '+376', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Angola', code: 'AO', prefix: '+244', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Antarctica', code: 'AQ', prefix: '+672', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Argentina', code: 'AR', prefix: '+54', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Armenia', code: 'AM', prefix: '+374', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Aruba', code: 'AW', prefix: '+297', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Australia', code: 'AU', prefix: '+61', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Austria', code: 'AT', prefix: '+43', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Azerbaijan', code: 'AZ', prefix: '+994', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Bahrain', code: 'BH', prefix: '+973', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Bangladesh', code: 'BD', prefix: '+880', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Belarus', code: 'BY', prefix: '+375', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Belgium', code: 'BE', prefix: '+32', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Belize', code: 'BZ', prefix: '+501', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Benin', code: 'BJ', prefix: '+229', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Bhutan', code: 'BT', prefix: '+975', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Bolivia', code: 'BO', prefix: '+591', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Bosnia and Herzegovina', code: 'BA', prefix: '+387', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Botswana', code: 'BW', prefix: '+267', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Brazil', code: 'BR', prefix: '+55', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'British Indian Ocean Territory', code: 'IO', prefix: '+246', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Brunei', code: 'BN', prefix: '+673', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Bulgaria', code: 'BG', prefix: '+359', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Burkina Faso', code: 'BF', prefix: '+226', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Burundi', code: 'BI', prefix: '+257', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Cambodia', code: 'KH', prefix: '+855', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Cameroon', code: 'CM', prefix: '+237', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Cape Verde', code: 'CV', prefix: '+238', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Central African Republic', code: 'CF', prefix: '+236', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Chad', code: 'TD', prefix: '+235', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Chile', code: 'CL', prefix: '+56', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'China', code: 'CN', prefix: '+86', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Colombia', code: 'CO', prefix: '+57', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Comoros', code: 'KM', prefix: '+269', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Cook Islands', code: 'CK', prefix: '+682', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Costa Rica', code: 'CR', prefix: '+506', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Croatia', code: 'HR', prefix: '+385', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Cuba', code: 'CU', prefix: '+53', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Curacao', code: 'CW', prefix: '+599', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Cyprus', code: 'CY', prefix: '+357', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Czech Republic', code: 'CZ', prefix: '+420', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Democratic Republic of the Congo', code: 'CD', prefix: '+243', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Denmark', code: 'DK', prefix: '+45', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Djibouti', code: 'DJ', prefix: '+253', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'East Timor', code: 'TL', prefix: '+670', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Ecuador', code: 'EC', prefix: '+593', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Egypt', code: 'EG', prefix: '+20', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'El Salvador', code: 'SV', prefix: '+503', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Equatorial Guinea', code: 'GQ', prefix: '+240', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Eritrea', code: 'ER', prefix: '+291', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Estonia', code: 'EE', prefix: '+372', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Ethiopia', code: 'ET', prefix: '+251', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Falkland Islands', code: 'FK', prefix: '+500', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Faroe Islands', code: 'FO', prefix: '+298', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Fiji', code: 'FJ', prefix: '+679', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Finland', code: 'FI', prefix: '+358', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'France', code: 'FR', prefix: '+33', prefixSeparator: '-', mask: '0x-xx-xx-xx-xx' },
  { name: 'French Polynesia', code: 'PF', prefix: '+689', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Gabon', code: 'GA', prefix: '+241', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Gambia', code: 'GM', prefix: '+220', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Georgia', code: 'GE', prefix: '+995', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Germany', code: 'DE', prefix: '+49', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Ghana', code: 'GH', prefix: '+233', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Gibraltar', code: 'GI', prefix: '+350', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Greece', code: 'GR', prefix: '+30', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Greenland', code: 'GL', prefix: '+299', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Guatemala', code: 'GT', prefix: '+502', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Guinea', code: 'GN', prefix: '+224', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Guinea-Bissau', code: 'GW', prefix: '+245', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Guyana', code: 'GY', prefix: '+592', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Haiti', code: 'HT', prefix: '+509', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Honduras', code: 'HN', prefix: '+504', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Hong Kong', code: 'HK', prefix: '+852', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Hungary', code: 'HU', prefix: '+36', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Iceland', code: 'IS', prefix: '+354', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'India', code: 'IN', prefix: '+91', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Indonesia', code: 'ID', prefix: '+62', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Iran', code: 'IR', prefix: '+98', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Iraq', code: 'IQ', prefix: '+964', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Ireland', code: 'IE', prefix: '+353', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Israel', code: 'IL', prefix: '+972', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Italy', code: 'IT', prefix: '+39', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Ivory Coast', code: 'CI', prefix: '+225', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Japan', code: 'JP', prefix: '+81', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Jordan', code: 'JO', prefix: '+962', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Kenya', code: 'KE', prefix: '+254', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Kiribati', code: 'KI', prefix: '+686', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Kosovo', code: 'XK', prefix: '+383', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Kuwait', code: 'KW', prefix: '+965', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Kyrgyzstan', code: 'KG', prefix: '+996', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Laos', code: 'LA', prefix: '+856', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Latvia', code: 'LV', prefix: '+371', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Lebanon', code: 'LB', prefix: '+961', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Lesotho', code: 'LS', prefix: '+266', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Liberia', code: 'LR', prefix: '+231', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Libya', code: 'LY', prefix: '+218', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Liechtenstein', code: 'LI', prefix: '+423', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Lithuania', code: 'LT', prefix: '+370', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Luxembourg', code: 'LU', prefix: '+352', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Macau', code: 'MO', prefix: '+853', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Macedonia', code: 'MK', prefix: '+389', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Madagascar', code: 'MG', prefix: '+261', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Malawi', code: 'MW', prefix: '+265', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Malaysia', code: 'MY', prefix: '+60', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Maldives', code: 'MV', prefix: '+960', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mali', code: 'ML', prefix: '+223', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Malta', code: 'MT', prefix: '+356', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Marshall Islands', code: 'MH', prefix: '+692', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mauritania', code: 'MR', prefix: '+222', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mauritius', code: 'MU', prefix: '+230', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mayotte', code: 'YT', prefix: '+262', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mexico', code: 'MX', prefix: '+52', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Micronesia', code: 'FM', prefix: '+691', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Moldova', code: 'MD', prefix: '+373', prefixSeparator: ' ', mask: '0 xxx xx xxx' },
  { name: 'Monaco', code: 'MC', prefix: '+377', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mongolia', code: 'MN', prefix: '+976', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Montenegro', code: 'ME', prefix: '+382', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Morocco', code: 'MA', prefix: '+212', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Mozambique', code: 'MZ', prefix: '+258', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Myanmar', code: 'MM', prefix: '+95', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Namibia', code: 'NA', prefix: '+264', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Nauru', code: 'NR', prefix: '+674', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Nepal', code: 'NP', prefix: '+977', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Netherlands', code: 'NL', prefix: '+31', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'New Caledonia', code: 'NC', prefix: '+687', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'New Zealand', code: 'NZ', prefix: '+64', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Nicaragua', code: 'NI', prefix: '+505', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Niger', code: 'NE', prefix: '+227', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Nigeria', code: 'NG', prefix: '+234', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Niue', code: 'NU', prefix: '+683', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'North Korea', code: 'KP', prefix: '+850', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Norway', code: 'NO', prefix: '+47', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Oman', code: 'OM', prefix: '+968', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Pakistan', code: 'PK', prefix: '+92', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Palau', code: 'PW', prefix: '+680', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Palestine', code: 'PS', prefix: '+970', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Panama', code: 'PA', prefix: '+507', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Papua New Guinea', code: 'PG', prefix: '+675', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Paraguay', code: 'PY', prefix: '+595', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Peru', code: 'PE', prefix: '+51', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Philippines', code: 'PH', prefix: '+63', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Poland', code: 'PL', prefix: '+48', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Portugal', code: 'PT', prefix: '+351', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Qatar', code: 'QA', prefix: '+974', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Republic of the Congo', code: 'CG', prefix: '+242', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Romania', code: 'RO', prefix: '+40', prefixSeparator: ' ', mask: 'xxx xxx xxx' },
  { name: 'Russia', code: 'RU', prefix: '+7', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Rwanda', code: 'RW', prefix: '+250', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Saint Helena', code: 'SH', prefix: '+290', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Saint Martin', code: 'MF', prefix: '+590', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', prefix: '+508', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Samoa', code: 'WS', prefix: '+685', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'San Marino', code: 'SM', prefix: '+378', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Sao Tome and Principe', code: 'ST', prefix: '+239', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Saudi Arabia', code: 'SA', prefix: '+966', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Senegal', code: 'SN', prefix: '+221', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Serbia', code: 'RS', prefix: '+381', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Seychelles', code: 'SC', prefix: '+248', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Sierra Leone', code: 'SL', prefix: '+232', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Singapore', code: 'SG', prefix: '+65', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Slovakia', code: 'SK', prefix: '+421', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Slovenia', code: 'SI', prefix: '+386', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Solomon Islands', code: 'SB', prefix: '+677', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Somalia', code: 'SO', prefix: '+252', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'South Africa', code: 'ZA', prefix: '+27', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'South Korea', code: 'KR', prefix: '+82', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'South Sudan', code: 'SS', prefix: '+211', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Spain', code: 'ES', prefix: '+34', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Sri Lanka', code: 'LK', prefix: '+94', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Sudan', code: 'SD', prefix: '+249', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Suriname', code: 'SR', prefix: '+597', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Swaziland', code: 'SZ', prefix: '+268', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Sweden', code: 'SE', prefix: '+46', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Switzerland', code: 'CH', prefix: '+41', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Syria', code: 'SY', prefix: '+963', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Taiwan', code: 'TW', prefix: '+886', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Tajikistan', code: 'TJ', prefix: '+992', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Tanzania', code: 'TZ', prefix: '+255', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Thailand', code: 'TH', prefix: '+66', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Togo', code: 'TG', prefix: '+228', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Tokelau', code: 'TK', prefix: '+690', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Tonga', code: 'TO', prefix: '+676', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Tunisia', code: 'TN', prefix: '+216', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Turkey', code: 'TR', prefix: '+90', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Turkmenistan', code: 'TM', prefix: '+993', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Tuvalu', code: 'TV', prefix: '+688', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Uganda', code: 'UG', prefix: '+256', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Ukraine', code: 'UA', prefix: '+380', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'United Arab Emirates', code: 'AE', prefix: '+971', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'United Kingdom', code: 'GB', prefix: '+44', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'United States', code: 'US', prefix: '+1', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Uruguay', code: 'UY', prefix: '+598', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Uzbekistan', code: 'UZ', prefix: '+998', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Vanuatu', code: 'VU', prefix: '+678', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Vatican', code: 'VA', prefix: '+379', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Venezuela', code: 'VE', prefix: '+58', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Vietnam', code: 'VN', prefix: '+84', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Wallis and Futuna', code: 'WF', prefix: '+681', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Yemen', code: 'YE', prefix: '+967', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Zambia', code: 'ZM', prefix: '+260', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
  { name: 'Zimbabwe', code: 'ZW', prefix: '+263', prefixSeparator: ' ', mask: 'xxx-xx-xxx' },
];
