import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { iif, of } from 'rxjs';
import { catchError, concatMap, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { actions as widgetsActions } from '../widgets/widgets.actions';
import { actions } from './pages.actions';
import { PagesHttpService } from './pages.http.service';
import * as selectors from './pages.selectors';
import { State } from './pages.type';

@Injectable()
export class PagesEffects {
  loadByIds$ = createEffect(() =>
    this._actions$.pipe(
      // check action
      ofType(actions.getById),
      // add ids from loaded page
      withLatestFrom(this._store.pipe(select(selectors.isLoaded))),
      // check all page is loaded
      concatMap(([{ id, params }, isLoaded]) =>
        iif(
          () => isLoaded,
          // all pages is loaded
          of(actions.getByIdIsLoaded({ id })),
          // get pages is not loaded
          this._pageService.getById(id, params).pipe(
            mergeMap(({ widgets, page }) => [
              actions.getByIdSuccess({ page }),
              widgetsActions.addWidgets({ widgets, pageId: id }),
            ]),

            catchError(({ errorCode = actions.getById.type }) => [
              actions.getByIdError({ id, error: errorCode }),
              widgetsActions.addWidgetsError({ pageId: id, error: { code: 404, message: errorCode } }),
            ]),
          ),
        ),
      ),
    ),
  );

  /**
   * redirect to kiosk page if kiosk mode is enabled
   * and product catalog widget is exist on selected page
   */
  // getByIdSuccess$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(widgetsActions.addWidgetsWithAttributes),
  //       concatLatestFrom(() => [
  //         this.settings.kioskMode,
  //         this.widgets.productCatalogWidgtet$,
  //         this.params.appSlug,
  //         this.params.pageId,
  //         this.params.queryParams(['place']),
  //       ]),
  //       tap(([, kiosk, productCatalogWidget, appSlug, pageId, [place]]) => {
  //         if (kiosk && productCatalogWidget) {
  //           this.router.navigate([`${appSlug}/${pageId}/kiosk`], {
  //             queryParams: { place },
  //             queryParamsHandling: 'merge',
  //           });
  //         }
  //       }),
  //     ),
  //   { dispatch: false },
  // );

  resetPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.resetPageById),
      switchMap(({ id }) => of(widgetsActions.resetWidgetsByPage({ pageId: id }))),
    ),
  );

  reset$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.reset),
      switchMap(() => of(widgetsActions.reset())),
    ),
  );

  constructor(private _actions$: Actions, private _pageService: PagesHttpService, private _store: Store<State>) {}
}
