import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { iif, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SettingsService } from '@bend/store';

import { InputGeneric } from '../../../../../../../shared-widgets/src/lib/helpers';

@Component({
  selector: 'app-button-radio',
  templateUrl: './button-radio.component.html',
  styleUrls: ['./button-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonRadioComponent extends InputGeneric implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() option: string;
  value: string;
  colorBg$: Observable<string>;
  colorFg$: Observable<string>;

  private _subscription: Subscription;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _cdr: ChangeDetectorRef,
    private _settings: SettingsService,
  ) {
    super();

    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  ngAfterViewInit(): void {
    if (this.isExistControl) {
      this._subscription = this.ngControl.control.valueChanges.subscribe(value => {
        // Check to ensure the value wasn't already set (Template driven forms)
        this._setValue(value);

        this._cdr.detectChanges();
      });

      this.colorBg$ = this._colorBg;

      this.colorFg$ = this._colorFg;
    }
  }

  select(): void {
    if (this.value === this.option) return;

    this.value = this.option;
    this.onChange(this.option);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  writeValue(value: string): void {
    this._setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private _setValue(value: string): void {
    if (value === undefined || this.value === value) return;

    this.value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  private get _colorBg(): Observable<string> {
    return this.ngControl.control.valueChanges.pipe(
      switchMap(value => iif(() => value === this.option, this._settings.colors.primary, of('#fff'))),
    );
  }

  private get _colorFg(): Observable<string> {
    return this.ngControl.control.valueChanges.pipe(
      switchMap(value => iif(() => value === this.option, this._settings.colors.primaryForeground, of('#000'))),
    );
  }
}
