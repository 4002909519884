<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="titleInput" class="form-label">{{ 'WIDGETS.BUTTONS.TITLE' | translate }}</label>
      <input
        id="titleInput"
        type="text"
        placeholder="Title"
        formControlName="title"
        class="form-input form-input--title"
      />
    </div>
    <div class="form-fieldset-groups">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.BUTTONS.BUTTONS' | translate }}</span>
      <div class="form-fieldset-groups__content" formArrayName="buttons">
        <div *ngFor="let button of buttonsControls; let i = index" class="form-fieldset-group">
          <div [formGroupName]="i">
            <div class="form-row">
              <label for="textInput" class="form-label">{{ 'WIDGETS.BUTTONS.BUTTON_TEXT' | translate }}</label>
              <input
                id="textInput"
                type="text"
                placeholder="Text"
                formControlName="text"
                class="form-input form-input--text"
              />
            </div>
            <div class="form-row">
              <label for="linkInput" class="form-label">{{ 'WIDGETS.BUTTONS.BUTTON_LINK' | translate }}</label>
              <input
                id="linkInput"
                type="url"
                [placeholder]="'WIDGETS.SHARED.LINK' | translate"
                formControlName="link"
                class="form-input form-input--url"
                (keyup)="onChangeUrl($event, i)"
              />
              <pop-widget-edit-page-selector
                *ngIf="pages"
                [selectedPageId]="buttonsControls[i].value.link"
                (pageChangeEmmiter)="onPageChange($event, i)"
                [pages]="pages"
              ></pop-widget-edit-page-selector>
              <div class="final-url">
                <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
                <span class="final-url__value">{{ finalUrls[i] }}</span>
              </div>
              <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
            </div>
            <div class="form-row-groups">
              <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
              <designer-image-gallery [formControl]="getButtonIcon(i)"></designer-image-gallery>
            </div>
            <div class="form-row">
              <label class="form-label form-label--checkbox">
                <input type="checkbox" formControlName="openInNewTab" class="form-input-checkbox" />
                {{ 'WIDGETS.SHARED.EXTERNAL_TAB' | translate }}
              </label>
            </div>
            <div class="form-row clearfix">
              <button
                *ngIf="buttonsControls.length > 1"
                class="btn-flat btn-flat--remove"
                type="button"
                (click)="removeButton(i)"
              >
                <span class="hyphen">-</span>
                {{ 'WIDGETS.BUTTONS.REMOVE_BUTTON' | translate }}
              </button>
            </div>
          </div>
        </div>
        <button class="btn-flat btn-flat--add-new-item" type="button" (click)="addButton()">
          {{ 'WIDGETS.BUTTONS.ADD_BUTTON' | translate }}
        </button>
      </div>
    </div>
    <div formGroupName="style" class="form-row-groups-wrapper">
      <span class="form-fieldset-groups__caption">{{ 'WIDGETS.STYLE.STYLES' | translate }}</span>
      <div class="form-fieldset-groups__content">
        <div class="form-row-groups">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="displayTypeSelect" class="form-label">{{ 'WIDGETS.STYLE.DISPLAY_TYPE' | translate }}</label>
            <select class="form-input form-input--select" formControlName="displayType">
              <option *ngFor="let item of typesName | keyvalue" [value]="item.key">
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="form-row-group form-row-group--distanced show-background-input">
            <label for="showBackgroundInput" class="form-label">
              <input id="showBackgroundInput" type="checkbox" formControlName="showBackground" />
              {{ 'WIDGETS.STYLE.BACKGROUND_SHOW' | translate }}
            </label>
          </div>
        </div>
        <div class="styles-second-row"></div>
        <div class="form-row-groups">
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="heightInput" class="form-label">{{ 'WIDGETS.STYLE.HEIGHT' | translate }}</label>
            <input
              id="heightInput"
              type="text"
              formControlName="height"
              matInput
              [matAutocomplete]="heightAuto"
              class="form-input form-input--number"
            />
            <mat-autocomplete #heightAuto="matAutocomplete">
              <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
            </mat-autocomplete>
            <span class="form-input-number-units">px</span>
            <div class="input-error" *ngIf="height.invalid && height.errors.height">
              {{ 'WIDGETS.SHARED.INTEGER' | translate }}
            </div>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="borderWidthInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_WIDTH' | translate }}</label>
            <input
              id="borderWidthInput"
              type="number"
              formControlName="borderWidth"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="buttonBorderColorInput" class="form-label">
              {{ 'WIDGETS.STYLE.BORDER_COLOR' | translate }}
            </label>
            <span
              id="buttonBorderColorInput"
              [(colorPicker)]="buttonBorderColorValue"
              [style.backgroundColor]="buttonBorderColorValue"
              class="form-colorpicker"
            ></span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="buttonBackgroundColorInput" class="form-label">
              {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
            </label>
            <span
              id="buttonBackgroundColorInput"
              [(colorPicker)]="buttonBackgroundColorValue"
              [cpOutputFormat]="'rgba'"
              [style.backgroundColor]="buttonBackgroundColorValue"
              class="form-colorpicker"
            ></span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="fontSizeInput" class="form-label">{{ 'WIDGETS.STYLE.FONT_SIZE' | translate }}</label>
            <input
              id="fontSizeInput"
              type="text"
              formControlName="fontSize"
              matInput
              [matAutocomplete]="fontSizeAuto"
              class="form-input form-input--number"
            />
            <mat-autocomplete #fontSizeAuto="matAutocomplete">
              <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
            </mat-autocomplete>
            <span class="form-input-number-units">px</span>
            <div class="input-error" *ngIf="fontSize.invalid && fontSize.errors.fontSize">
              {{ 'WIDGETS.SHARED.INTEGER' | translate }}
            </div>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="button">
            <label for="buttonTextColorInput" class="form-label">{{ 'WIDGETS.STYLE.FONT_COLOR' | translate }}</label>
            <span
              id="buttonTextColorInput"
              [(colorPicker)]="buttonTextColorValue"
              [style.backgroundColor]="buttonTextColorValue"
              class="form-colorpicker"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
