import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { asapScheduler, Subject, Subscription } from 'rxjs';
import { buffer, filter, map, observeOn, tap } from 'rxjs/operators';

import { AnimationsService } from '@bend/animations';

@Injectable()
export class ScrollService {
  private _trigger: Subject<void>;
  private _positions: { [key: string]: [number, number] };
  private _popstate: boolean;
  private _element: HTMLElement | null;

  constructor(
    private _router: Router,
    private _viewportScroller: ViewportScroller,
    private _animations: AnimationsService,
  ) {
    this._trigger = new Subject();
    this._positions = {};
    this._popstate = false;
    this._element = null;
  }

  init(): Subscription {
    return this._router.events
      .pipe(
        /**
         * allow only start event and end event from routing
         */
        this._filter(),
        /**
         * set all data when event is start
         */
        tap(event => {
          /**
           * when event is not NavigationStart exit from this function
           */
          if (!(event instanceof NavigationStart)) return;
          /**
           * set popstate when event is trigger from browser back button
           */
          if ((event as NavigationStart).navigationTrigger === 'popstate') {
            this._popstate = true;
            /**
             * disable animations when navigation is triggered by back button
             */
            this._animations.disable();
          }

          if (this._popstate)
            /**
             * if event is triggered from browser back button remove this url from positions
             * because you don't need position for this page
             */
            delete this._positions[this._router.url];
          /**
           * get scroll position for this page and save in position
           * to use when user return in this page with back button action
           */ else if (this._element)
            this._positions[this._router.url] = [this._element.scrollLeft, this._element.scrollTop];
          else this._positions[this._router.url] = this._viewportScroller.getScrollPosition();
        }),
        /**
         * trigger scroll when navigation is completed
         */
        filter<NavigationEnd>(event => event instanceof NavigationEnd),
        /**
         * use only url from event
         */
        map(event => event?.url),
        /**
         * need buffer to wait trigger from component when render is finished
         */
        buffer(this._trigger.asObservable()),
        /**
         * buffer return array of event and we need only last event
         * (pop return only last event)
         */
        map(ids => ids.pop()),
        /**
         * await to remove old route
         */
        observeOn(asapScheduler),
      )
      .subscribe(idToRestore => {
        /**
         * save temporarily popstate to check is true
         */
        const tempPopstate = this._popstate;

        /**
         * disable pop state after navigation
         */
        this._popstate = false;
        /**
         * scroll on top on custom router outlet when navigation is not trigger by back button
         */
        if (
          /**
           * check is not back navigation
           */
          !tempPopstate &&
          /**
           * check is in custom outlet
           */
          this._element
        ) {
          this._element.scrollTo(0, 0);
        }
        /**
         * return when we don't have any position for this page
         */
        if (!this._positions[idToRestore]) return;
        /**
         * if navigation is not from popstate (navigation isn't trigger by back button from browser)
         * we don't need to restore scroll
         */
        if (!tempPopstate) return;
        /**
         * restore scroll position
         */
        if (this._element) this._element.scrollTo(...this._positions[idToRestore]);
        else this._viewportScroller.scrollToPosition(this._positions[idToRestore]);
        /**
         * enable animation after navigation
         */
        this._animations.enable();
      });
  }

  get element(): HTMLElement {
    return this._element;
  }

  addElement(element: HTMLElement): void {
    this._element = element;
  }

  removeElement(): void {
    this._element = null;
  }

  trigger(): void {
    this._trigger.next();
  }

  setPopstate(): void {
    this._popstate = true;
    this._animations.disable();
  }

  // Fix types any
  private _filter(): any {
    return filter<NavigationStart | NavigationEnd>(
      event => event instanceof NavigationStart || event instanceof NavigationEnd,
    );
  }
}
