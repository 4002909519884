<google-map
  *ngIf="isMapConnected"
  width="100%"
  [height]="attributes.style?.height"
  [options]="mapOptions"
  (mapInitialized)="onMapInitialization($event)"
>
  <ng-container *ngIf="attributes.pins.length">
    <map-marker
      *ngFor="let pin of attributes.pins"
      [position]="pin.coords"
      [label]="pin?.title"
      [icon]="pin?.icon"
    ></map-marker>
  </ng-container>
</google-map>
