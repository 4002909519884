export interface User {
  id: number;
  email: string;
  phone: string;
  name: string;
  credit: number;
  pendingTickets: number;
  isLoading?: boolean;
  allowNewsletters: boolean;
}
