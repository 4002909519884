<div class="spinner-wrapper" [style.width]="width + 'px'" [style.height]="height + 'px'">
  <svg
    class="spinner"
    xmlns="http://www.w3.org/2000/svg"
    [style.width]="width"
    [style.height]="height"
    viewBox="0 0 35 35"
  >
    <path
      d="M35 17.521c0-9.663-7.837-17.5-17.5-17.5S0 7.858 0 17.521m2.967 0C2.967 9.532 9.435 2.99 17.5 2.99S32.033 9.532 32.033 17.52"
      [style.fill]="color || '#f5f5f5'"
    />
  </svg>
</div>
