<button class="button app-button-color" mat-raised-button [disabled]="disabled" color="primary" (click)="onClick()">
  <span class="button__language">
    {{ language | uppercase }}
  </span>

  <ng-container *ngIf="showIcon">
    <ng-container *ngIf="error; then errorIcon; else checkIcon"></ng-container>
  </ng-container>

  <ng-template #errorIcon>
    <lib-icon class="button__icon button__icon--error" icon="languageError"></lib-icon>
  </ng-template>

  <ng-template #checkIcon>
    <lib-icon class="button__icon button__icon--check" icon="languageCheck"></lib-icon>
  </ng-template>
</button>
