import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { OrderService } from '@bend/store/src/lib/order';
import { CollectType, PlaceType } from '@bend/store-shared';

import { AppBehavior, AppType, AppTypeBehavior, KitchenType } from '../../../../types';

@Injectable()
export class TypeService {
  constructor(private _settings: SettingsService, private _order: OrderService) {}

  get app(): Observable<AppType> {
    return this._settings.widgetCart.pipe(
      map(({ payBefore }) => {
        if (payBefore) {
          return AppType.PayBefore;
        }

        return AppType.PayAfter;
      }),
    );
  }

  get appBehavior(): Observable<AppTypeBehavior> {
    return combineLatest([this._settings.widgetCart, this._order.currentDetails.collectType]).pipe(
      map(([{ payBefore, allowPickup }, collectType]) => {
        if (collectType === CollectType.Delivery) {
          return AppBehavior.PayBeforeDelivery;
        }

        if (collectType === CollectType.TakeAway) {
          return AppBehavior.PayBeforeTakeAway;
        }

        if (payBefore) {
          if (allowPickup) {
            return AppBehavior.PayBeforePickUp;
          }

          return AppType.PayBefore;
        }

        return AppType.PayAfter;
      }),
    );
  }

  get kitchen(): Observable<KitchenType> {
    return this._settings.widgetCart.pipe(
      map(({ multiKitchen }) => (multiKitchen ? KitchenType.Multi : KitchenType.Mono)),
    );
  }

  get orderIsCollect(): Observable<boolean> {
    const allowCollectTypes = [CollectType.TakeAway, CollectType.Delivery];

    return combineLatest([this._settings.placeType, this._settings.collectTypes]).pipe(
      map(([placeType, collectTypes]) => {
        // check if place type is remove
        if (placeType !== PlaceType.Remote) return false;

        const allowCollectType = collectTypes.some(collectType => allowCollectTypes.includes(collectType));
        // check is not in allow collect type
        if (!allowCollectType) return false;

        return true;
      }),
    );
  }
}
