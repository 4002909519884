import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

import { ImageGalleryService } from '@bend/store/src/lib/image-gallery';

@Component({
  selector: 'designer-gallery-url-upload-dialog',
  templateUrl: './gallery-url-upload-dialog.component.html',
  styleUrls: ['./gallery-url-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryUrlUploadDialogComponent {
  url: UntypedFormControl;
  date: Date;

  isLoading$: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<GalleryUrlUploadDialogComponent>,
    private _imageGalleryService: ImageGalleryService,
  ) {
    this.url = new UntypedFormControl('', [Validators.required, this._isUrlValidator()]);
    this.date = new Date();
    this.isLoading$ = this._imageGalleryService.isLoading;
    this.dialogRef
      .backdropClick()
      .pipe(first())
      .subscribe(() => {
        this.closeModal();
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this._imageGalleryService
      .uploadByUrl(this.url.value as string)
      .pipe(
        filter(Boolean),
        tap(() => {
          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  private _isUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const isUrl = new RegExp(
        '[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
      ).test(control.value as string);
      return isUrl ? null : { isNotUrl: true };
    };
  }

  get name(): string {
    return this.url.value.lastIndexOf('/') === -1
      ? 'Unnamed'
      : this.url.value.slice((<string>this.url.value).lastIndexOf('/') + 1);
  }

  get imageUrl(): string {
    return !this.url.invalid && this.url.value;
  }
}
