import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiPosInternService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api.smilein.io/pos/:version/admin/:appSlug/catalog-syncs
   */
  catalogSyncs(version: Version, id?: string): Observable<string> {
    return this._combine(version, 'admin', `catalog-syncs${id ? `/${id}` : ''}`);
  }

  /**
   * @returns https://api.smilein.io/pos/:version/admin/:appSlug/catalogs
   */
  catalogs(version: Version, id?: string): Observable<string> {
    return this._combine(version, 'admin', `catalogs${id ? `/${id}` : ''}`);
  }

  /**
   * @returns https://api.smilein.io/pos/:version/admin/:appSlug/ikentoo/catalogs
   */
  ikentooCatalogs(version: Version): Observable<string> {
    return this._combine(version, 'admin', 'ikentoo/catalogs');
  }

  /**
   * @returns https://api.smilein.io/pos/:version/admin/:appSlug/zelty/catalogs
   */
  zeltyCatalogs(version: Version): Observable<string> {
    return this._combine(version, 'admin', 'zelty/catalogs');
  }

  /**
   * @returns https://api.smilein.io/pos/:version/admin/:appSlug/cashpad/catalogs
   */
  cashpadCatalogs(version: Version): Observable<string> {
    return this._combine(version, 'admin', 'cashpad/catalogs');
  }

  private _combine(version: Version, tokenType: 'admin' | 'user', suffix?: string): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/pos/${version}/${tokenType}/${appSlug}${suffix ? `/${suffix}` : ''}`),
    );
  }
}
