import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { OrderItemStatus } from '@bend/store-shared';

import { AppType, NextAction } from '../../../../types';
import { OrderStatusServices } from '../order-status/order-status.service';
import { TotalService } from '../total/total.service';
import { TypeService } from '../type/type.service';

@Injectable()
export class NextActionService {
  constructor(
    private _type: TypeService,
    private _total: TotalService,
    private _orderStatus: OrderStatusServices,
    private _settings: SettingsService,
  ) {}

  get action(): Observable<NextAction> {
    return this._type.app.pipe(
      switchMap(appType => iif(() => appType === AppType.PayBefore, this._actionBefore, this._actionAfter)),
    );
  }

  private get _actionBefore(): Observable<NextAction> {
    return this._total.currentRemainToPay.pipe(
      switchMap(remainToPay =>
        iif(
          /**
           * check if user have unpaid items
           */
          () => !!remainToPay,
          /**
           * check order status is not WaitingForPayment
           * when user pay by waiter
           */
          this._orderStatus.orderStatusChanged.pipe(
            /**
             * check user select to pay by waiter
             */
            map(({ status }) => status === OrderItemStatus.WaitingForPayment),
            switchMap(waiting =>
              iif(
                () => waiting,
                /**
                 * when status is WaitingForPayment
                 * need to return action None
                 * because next action is not in phone
                 * next action is pay to the waiter
                 */
                of(NextAction.None),
                this._payByPop.pipe(
                  map(payByPop =>
                    payByPop
                      ? /**
                         * when is 'Pay by pop' or 'Pay by pop or pay by waiter'
                         * we show next action pay
                         */
                        NextAction.Pay
                      : /**
                         * when is only by waiter
                         * we return PayWaiter action
                         */
                        NextAction.PayWaiter,
                  ),
                ),
              ),
            ),
          ),
          /**
           * when user not have unpaid items
           * next action is none
           */
          of(NextAction.None),
        ),
      ),
    );
  }

  private get _actionAfter(): Observable<NextAction> {
    /**
     * all statuses to allow to send items to pos
     */
    const allowStatusToSend: Set<OrderItemStatus> = new Set([OrderItemStatus.New, OrderItemStatus.OrderNotSent]);
    /**
     * get current order status
     */
    return this._orderStatus.orderStatusChanged.pipe(
      /**
       * check if status is need to send order
       */
      map(({ status }) => allowStatusToSend.has(status)),
      switchMap(isNeedSend =>
        iif(
          () => isNeedSend,
          /**
           * return send action when we have statuses for send action
           */
          of(NextAction.Send),
          /**
           * when order is sent need to check if user need to pay
           */
          this._total.currentRemainToPay.pipe(
            switchMap(remainToPay =>
              iif(
                () => !!remainToPay,
                /**
                 * when user have unpaid items
                 * check payment behavior
                 */
                this._payByPop.pipe(
                  map(payByPop =>
                    payByPop
                      ? /**
                         * when is 'Pay by pop' or 'Pay by pop or pay by waiter'
                         * we show next action pay
                         */
                        NextAction.Pay
                      : /**
                         * when is only by waiter
                         * no action need
                         */
                        NextAction.None,
                  ),
                ),
                /**
                 * no action when all items are paid
                 */
                of(NextAction.None),
              ),
            ),
          ),
        ),
      ),
    );
  }

  private get _payByPop(): Observable<boolean> {
    return this._settings.noPayment.pipe(
      /**
       * need to reverse value because you need to return true when is pay by pop
       */
      map(noPayment => !noPayment),
    );
  }
}
