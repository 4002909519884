import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { OrderItemsService } from '@bend/store';
import { SettingsService } from '@bend/store';
import { OrderLocation } from '@bend/store-shared';

import { OrderUserCurrentService, OrderUserService } from '../../../cart-services';

@Component({
  selector: 'lib-board-order-user',
  templateUrl: './board-order-user.component.html',
  styleUrls: ['./board-order-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrderUserComponent {
  @ViewChild('location', { read: ElementRef }) locations: ElementRef;

  orderUserId$: Observable<number>;
  orderLocations$: Observable<OrderLocation[]>;
  primaryColor$: Observable<string>;
  showArrow$: Observable<boolean>;

  constructor(
    private _orderUserCurrent: OrderUserCurrentService,
    private _orderUser: OrderUserService,
    private _settings: SettingsService,
    private _orderItems: OrderItemsService,
  ) {
    this.orderUserId$ = this._orderUserId;
    this.orderLocations$ = this._orderLocations;
    this.primaryColor$ = this._settings.colors.primary;
    this.showArrow$ = this._showArrow;
  }

  scrollRight(): void {
    this.locations.nativeElement.scrollBy({ left: 260 });
  }

  scrollLeft(): void {
    this.locations.nativeElement.scrollBy({ left: -260 });
  }

  private get _orderUserId(): Observable<number> {
    return this._orderUserCurrent.current.pipe(map(current => current.id));
  }

  private get _orderLocations(): Observable<OrderLocation[]> {
    return this._orderUserCurrent.current.pipe(switchMap(orderUser => this._orderUser.locations(orderUser.id)));
  }

  private get _showArrow(): Observable<boolean> {
    return this._orderItems.all.pipe(map(items => items.length > 3));
  }
}
