import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { filterUndefined, Item } from '../shared';
import { actions } from './item.actions';
import * as selectors from './item.selectors';
import { State } from './item.type';

@Injectable()
export class ItemService {
  readonly all$: Observable<Item[]>;

  constructor(protected _store: Store<State>) {
    this.all$ = this._all;
  }

  allByCatalogId(catalogId: number): Observable<Item[]> {
    return this._all.pipe(map(items => items.filter(item => item.catalogId === catalogId && item.isMenu)));
  }

  allByCategoryId(categoryId: number): Observable<Item[]> {
    return this._all.pipe(map(items => items.filter(item => item.categoryId === categoryId)));
  }

  byId(id: number): Observable<Item> {
    return this._all.pipe(
      map(all => all.find(({ id: idItem }) => id === idItem)),
      filterUndefined(),
    );
  }

  changeQuantity(by: number, quantity: number): void {
    this._store.dispatch(actions.changeQuantity({ by, quantity }));
  }

  resetQuantity(): void {
    this._store.dispatch(actions.resetQuantity());
  }

  getMenus(catalogId: number): void {
    this._store.dispatch(actions.getMenus({ catalogId }));
  }

  private get _all(): Observable<Item[]> {
    return this._store.select(selectors.all);
  }
}
