<div class="create-visitor-content dialog-content">
  <button mat-dialog-close class="close-dialog-btn" title="close" [style.backgroundColor]="mainColor">
    <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" fill="white">
      <path
        d="M11.67 10.08L7.59 6l4.08-4.08c.44-.44.44-1.15 0-1.59-.44-.44-1.15-.44-1.59 0L6 4.41 1.92.33C1.48-.11.77-.11.33.33c-.44.44-.44 1.15 0 1.59L4.41 6 .33 10.08c-.44.44-.44 1.15 0 1.59.44.44 1.15.44 1.59 0L6 7.59l4.08 4.08c.44.44 1.15.44 1.59 0a1.127 1.127 0 0 0 0-1.59z"
      />
    </svg>
  </button>
  <form class="create-visitor-form" (ngSubmit)="onSubmit()">
    <label for="name" class="label-name" [style.color]="mainColor">
      {{ isOtherVisitor ? ('PRODUCT_CATALOG.OTHERS_NAME' | translate) : ('PRODUCT_CATALOG.YOUR_NAME' | translate) }}?
    </label>
    <span *ngIf="name.invalid && showError" class="error-msg">
      {{ 'PRODUCT_CATALOG.PLEASE_ENTER_THE_NAME' | translate }}
    </span>
    <input
      type="text"
      class="form-input"
      data-cy="inputFormName"
      [formControl]="name"
      [placeholder]="
        isOtherVisitor ? ('PRODUCT_CATALOG.OTHERS_NAME' | translate) : ('PRODUCT_CATALOG.YOUR_NAME' | translate)
      "
      cdk-focus-initial
      required
    />
    <button type="submit" class="save-btn" [style.backgroundColor]="mainColor" data-cy="saveBtn">
      {{ 'PRODUCT_CATALOG.SAVE' | translate }}
      <pop-widget-spinner *ngIf="isLoading" [width]="30" [height]="20"></pop-widget-spinner>
    </button>
  </form>
</div>
