import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, repeat, switchMap, take, tap } from 'rxjs/operators';

import { AppSettingsService } from '@bend/settings-app';
import { ApiDesignerService, CatalogPopulated, SettingsLocation } from '@bend/store-shared';

import { PosType } from '@designer-fsd-shared/types/pos';
import { GetPosImportResponse, PopCatalog, PopCatalogForDuplicate } from '@designer-shared/types';

import { PopRestaurant } from '../../../dashboard/components';

@Injectable()
export class CatalogsService {
  emptyCatalog: PopCatalog = {
    id: null,
    name: '',
    isPosImportable: false,
  };

  private catalog = new BehaviorSubject(this.emptyCatalog);
  currentCatalog = this.catalog.asObservable();

  private readonly _catalogPosType = new BehaviorSubject<PosType>(null);
  readonly currentCatalogPosType = this._catalogPosType.asObservable();

  catalogsPopulated: Observable<CatalogPopulated[]>;

  constructor(
    private _http: HttpClient,
    private _api: ApiDesignerService,
    private readonly _settings: AppSettingsService,
  ) {
    this.catalogsPopulated = this._catalogsPopulated;
  }

  importProducts(catalog: {
    name: string;
    businessLocationId: number;
    defaultTvaId: number;
    file: File;
  }): Observable<GetPosImportResponse> {
    const data: FormData = new FormData();
    data.append('file', catalog.file);
    data.append('name', catalog.name);
    data.append('defaultTvaId', catalog.defaultTvaId.toString());
    if (catalog.businessLocationId.toString() !== '') {
      data.append('businessLocationId', catalog.businessLocationId.toString());
    }
    return this.importProductsFromCsv(data).pipe(
      switchMap(importId =>
        this.importProductsFromCsvId(importId).pipe(
          repeat({ delay: 10000 }),
          filter(({ finished }) => finished),
          take(1),
        ),
      ),
    );
  }

  getImportById(importId: string): Observable<GetPosImportResponse> {
    return this._api
      .catalogsImportsPosId('v1', importId)
      .pipe(switchMap(api => this._http.get<GetPosImportResponse>(api)));
  }

  importProductsToSmileIn(catalog: {
    name: string;
    businessLocationId: number;
    defaultTvaId: number;
    id: string;
  }): Observable<GetPosImportResponse> {
    return this.catalogsImportsPos(catalog).pipe(
      switchMap(importId =>
        this.getImportById(importId).pipe(
          repeat({ delay: 10000 }),
          filter(({ finished }) => finished),
          take(1),
        ),
      ),
    );
  }

  catalogsImportsPos(catalog: {
    name: string;
    businessLocationId: number;
    defaultTvaId: number;
    id: string;
  }): Observable<string> {
    return this._api.catalogsImportsPos('v1').pipe(switchMap(api => this._http.post<string>(api, catalog)));
  }

  importProductsFromCsv(data: FormData): Observable<string> {
    return this._api.importProductsFromCsv('v1').pipe(switchMap(api => this._http.post<string>(api, data)));
  }

  importProductsFromCsvId(importId: string): Observable<GetPosImportResponse> {
    return this._api
      .importProductsFromCsvId('v1', importId)
      .pipe(switchMap(api => this._http.get<GetPosImportResponse>(api)));
  }

  getCatalogs(): Observable<PopCatalog[]> {
    return this._api.catalogs('v1').pipe(switchMap(api => this._http.get<PopCatalog[]>(api)));
  }

  getCatalog(catalogId: number): Observable<PopCatalog> {
    this._getCatalogPosType(catalogId);

    return this._api.catalog('v1', catalogId).pipe(switchMap(api => this._http.get<PopCatalog>(api)));
  }

  getCatalogDataForExport(catalogId: number): Observable<string> {
    return this._api
      .catalogsExport('v1')
      .pipe(
        switchMap(api => this._http.get(api, { params: { catalogId: catalogId.toString() }, responseType: 'text' })),
      );
  }

  newCatalog(catalog: PopCatalog): Observable<PopCatalog> {
    return this._api.catalogs('v1').pipe(switchMap(api => this._http.post<PopCatalog>(api, catalog)));
  }

  saveCatalog(catalog: PopCatalog): Observable<PopCatalog> {
    return this._api.catalog('v1', catalog.id).pipe(switchMap(api => this._http.patch<PopCatalog>(api, catalog)));
  }

  deleteCatalog(catalog: PopCatalog): Observable<any> {
    return this._api.catalog('v1', catalog.id).pipe(switchMap(api => this._http.delete(api)));
  }

  copyCatalogToAnotherApp(catalogs: PopCatalogForDuplicate): Observable<PopCatalogForDuplicate> {
    return this._api.exportCatalog('v1').pipe(switchMap(api => this._http.post<PopCatalogForDuplicate>(api, catalogs)));
  }

  duplicateCatalog(catalogs: PopCatalogForDuplicate): Observable<PopCatalog> {
    return this._api.duplicateCatalog('v1').pipe(switchMap(api => this._http.post<PopCatalog>(api, catalogs)));
  }

  getLocations(): Observable<PopRestaurant[]> {
    return this._api.businessLocations('v1').pipe(switchMap(api => this._http.get<PopRestaurant[]>(api)));
  }

  syncCatalog(catalog: PopCatalog): void {
    this.catalog.next(catalog);
  }

  private get _catalogsPopulated(): Observable<CatalogPopulated[]> {
    return this._api.catalogsPopulated('v1').pipe(switchMap(api => this._http.get<CatalogPopulated[]>(api)));
  }

  private _getCatalogPosType(catalogId: number): void {
    const catalog$ = this._api.catalog('v1', catalogId).pipe(switchMap(url => this._http.get<PopCatalog>(url)));

    const posList$ = this._api
      .posSettings('v1')
      .pipe(switchMap(url => this._http.get<{ id: number; name: string; type: PosType }[]>(url)));

    combineLatest([catalog$, posList$])
      .pipe(
        switchMap(([catalog, posList]) => {
          const { businessLocationId } = catalog;

          const locationPosSettings$ = this._api.locationSettings('v1', businessLocationId).pipe(
            switchMap(url => this._http.get<SettingsLocation>(url)),
            switchMap(({ pos }) => (pos.posSettingId ? of(pos) : this._settings.pos)),
          );

          /**
           * if catalog attached to location
           * then get id of pos, that connected to this location
           * (and if pos extended from application settings, return application settings)
           *
           * else return application settings
           */
          return (businessLocationId ? locationPosSettings$ : this._settings.pos).pipe(
            tap(({ posSettingId }) => {
              const posType = posList.find(({ id }) => id === posSettingId).type;

              this._catalogPosType.next(posType);
            }),
          );
        }),
      )
      .subscribe();
  }
}
