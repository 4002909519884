import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { OrderUser } from '../shared';
import * as selectors from './order-users.selectors';
import { State } from './order-users.type';

@Injectable()
export class OrderUsersService {
  constructor(private _store: Store<State>) {}

  // all users added by user with this user
  orderUserWithOthers(orderId: number, sessionUserId: number): Observable<OrderUser[]> {
    return combineLatest([this.orderUser(sessionUserId), this.others(orderId, sessionUserId)]).pipe(
      map(users => users.flat()),
    );
  }

  // all users added by user
  others(orderId: number, sessionUserId: number): Observable<OrderUser[]> {
    return this.orderUser(sessionUserId).pipe(
      switchMap(({ userId }) =>
        this.all.pipe(map(orderUsers => orderUsers.filter(it => it.createdBy === userId && it.orderId === orderId))),
      ),
    );
  }

  // all users from shared cart
  shares(orderId: number, currentOrderUserId: number): Observable<OrderUser[]> {
    return this.orderUser(currentOrderUserId).pipe(
      switchMap(({ userId }) =>
        this.all.pipe(
          map(sessionVisitors =>
            sessionVisitors.filter(
              it => it.id !== currentOrderUserId && it.createdBy !== userId && it.orderId === orderId,
            ),
          ),
        ),
      ),
    );
  }

  // the user who placed the order
  orderUser(currentOrderUserId: number): Observable<OrderUser> {
    return this.entities.pipe(
      map(orderUser => orderUser[currentOrderUserId]),
      filter<OrderUser>(Boolean),
    );
  }

  // all users without current user who placed the order
  allWithoutCurrent(orderId: number, currentOrderUserId: number): Observable<OrderUser[]> {
    return this.all.pipe(
      map(orderUsers =>
        orderUsers.filter(orderUser => orderUser.id !== currentOrderUserId && orderUser.orderId === orderId),
      ),
    );
  }

  get entities(): Observable<Dictionary<OrderUser>> {
    return this._store.pipe(select(selectors.entities));
  }

  get all(): Observable<OrderUser[]> {
    return this._store.pipe(select(selectors.all));
  }
}
