import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { isNumeric } from '../../helpers';
import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Margin, Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { heightValidator } from '../../shared/validators/height.validator';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import { ButtonsDisplayType, ButtonsWidget, ButtonsWidgetStyleButton } from './buttons';

@Component({
  selector: 'pop-widget-edit-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditButtonsComponent extends WidgetBaseComponent<ButtonsWidget> implements OnInit {
  static widgetName = 'buttons';
  @Input() pages: Page[];
  @Input() appHost: string;
  finalUrls: string[] = [];
  iconMinWidth: number;
  typesName: Record<string, string>;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this._initForm();
    this._handleDisplayTypeChange();

    const { buttons } = this.widget.attributes;
    if (buttons && buttons.length) {
      for (const button of buttons) {
        const data = {
          icon: button['icon'],
          link: button['link'],
          text: button['text'],
          openInNewTab: button['openInNewTab'],
        };
        this.addButton(data);
        // generate finalUrl values for each button
        this.finalUrls.push(WidgetHelper.generateFinalUrl(button['link'], this.appHost));
      }
    } else {
      this.addButton();
    }

    const { STYLE1, STYLE2, STYLE3 } = ButtonsDisplayType;
    this.typesName = {
      [STYLE1]: 'Small icons',
      [STYLE2]: 'Big icons',
      [STYLE3]: 'Elegant',
    };
    this._setIconMinWidth(this.widget.attributes.style.displayType);
  }

  initButton(value = {}): UntypedFormGroup {
    return this._formBuilder.group({
      icon: [value['icon']],
      link: [value['link']],
      text: [value['text']],
      openInNewTab: [value['openInNewTab']],
    });
  }

  addButton(value = {}): void {
    const control = this.form.controls['buttons'] as UntypedFormArray;
    control.push(this.initButton(value));
  }

  removeButton(index: number): void {
    const control = this.form.controls['buttons'] as UntypedFormArray;
    control.removeAt(index);
    // remove finalUrl entry from finalUrls
    this.finalUrls.splice(index, 1);
  }

  getButtonIcon(index: number): UntypedFormControl {
    return this.buttonsControls[index].get('icon');
  }

  onChangeUrl(event: any, index: number): void {
    this.finalUrls[index] = WidgetHelper.generateFinalUrl(event.target.value, this.appHost);
  }

  onPageChange(page: Page, index: number): void {
    this.form.controls.buttons['controls'][index]['controls'].link.setValue(page['_id']);
    this.finalUrls[index] = WidgetHelper.generateFinalUrl(page['_id'], this.appHost);
  }

  private _addMissingAttributes(widget: ButtonsWidget): void {
    const { attributes } = widget;
    if (!attributes.style) {
      attributes.style = {
        button: new ButtonsWidgetStyleButton(),
        margin: new Margin(),
        displayType: ButtonsDisplayType.STYLE1,
        showBackground: true,
      };
    }

    if (!attributes.style.button) {
      attributes.style.button = new ButtonsWidgetStyleButton();
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new Margin();
    }

    if (!attributes.style.displayType) {
      attributes.style.displayType = ButtonsDisplayType.STYLE1;
    }
  }

  private _initForm(): void {
    const { title, style } = this.widget.attributes;

    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        title: [title],
        buttons: this._formBuilder.array([], Validators.minLength(1)),
        style: this._formBuilder.group({
          button: this._formBuilder.group({
            height: [style.button.height || 'default', heightValidator()],
            backgroundColor: [WidgetHelper.parseColor(style.button.backgroundColor)],
            borderColor: [style.button.borderColor],
            borderWidth: [style.button.borderWidth],
            borderRadius: [style.button.borderRadius],
            fontSize: [style.button.fontSize, this._fontSizeValidator()],
            textColor: [style.button.textColor],
          }),
          margin: this._formBuilder.group({
            top: [style.margin.top],
            bottom: [style.margin.bottom],
          }),
          displayType: [style.displayType],
          showBackground: [style.showBackground],
        }),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _getIconMinWidth(displayType: ButtonsDisplayType): number {
    switch (displayType) {
      case ButtonsDisplayType.STYLE2:
        return 0;
      default:
        return 25;
    }
  }

  private _handleDisplayTypeChange(): void {
    this.form.get('style.displayType').valueChanges.forEach(value => this._setIconMinWidth(value));
  }

  private _setIconMinWidth(displayType: ButtonsDisplayType): void {
    this.iconMinWidth = this._getIconMinWidth(displayType);
  }

  private _fontSizeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = control.value === 'default' || isNumeric(control.value as string);
      return valid ? null : { fontSize: { value: control.value } };
    };
  }

  get buttonsControls(): any {
    return (this.form.get('buttons') as UntypedFormArray).controls;
  }

  get buttonBackgroundColor(): UntypedFormControl {
    return this.form.get('style.button.backgroundColor') as UntypedFormControl;
  }

  get buttonBackgroundColorValue(): string {
    return this.buttonBackgroundColor.value;
  }

  set buttonBackgroundColorValue(color: string) {
    this.buttonBackgroundColor.setValue(color);
  }

  get buttonTextColor(): UntypedFormControl {
    return this.form.get('style.button.textColor') as UntypedFormControl;
  }

  get buttonTextColorValue(): string {
    return this.buttonTextColor.value;
  }

  set buttonTextColorValue(color: string) {
    this.buttonTextColor.setValue(color);
  }

  get buttonBorderColor(): UntypedFormControl {
    return this.form.get('style.button.borderColor') as UntypedFormControl;
  }

  get buttonBorderColorValue(): string {
    return this.buttonBorderColor.value;
  }

  set buttonBorderColorValue(color: string) {
    this.buttonBorderColor.setValue(color);
  }

  get fontSize(): UntypedFormControl {
    return this.form.get('style.button.fontSize') as UntypedFormControl;
  }

  get height(): UntypedFormControl {
    return this.form.get('style.button.height') as UntypedFormControl;
  }
}
