import { Margin, TextAlignWidget } from '../../shared/interfaces';
import { Widget } from '../../shared/models';

export interface LinkWidget extends Widget {
  attributes: {
    title: string;
    link: string;
    openInNewTab: boolean;
    style: {
      fontSize: string;
      textAlign: TextAlignWidget;
      textColor: string;
      height: string;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      borderRadius: number;
      width: string;
      margin: Margin;
      iconUrl: string;
      showArrow: boolean;
      displayType: LinkDisplayType;
    };
  };
}

export enum LinkDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}
