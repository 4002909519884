import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import {
  Catalog,
  CatalogChoice,
  CatalogsCategoriesResponse,
  Category,
  categoryImages,
  PopCatalogType,
  PopPackage,
} from '../product-catalog';
import { ProductCatalogService } from '../product-catalog.service';

@Component({
  selector: 'pop-widget-edit-product-catalog-form',
  templateUrl: './catalog-form.component.html',
  styleUrls: ['./catalog-form.component.scss'],
})
export class PopWidgetEditProductCatalogFormComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() catalog: Catalog;
  @Input() catalogChoices: CatalogChoice[];
  @Input() categoriesGroupedByCatalog: CatalogsCategoriesResponse;
  @Input() appSlug: string;
  @Input() showErrors: boolean;
  @Output() deleted = new EventEmitter();
  @Output() isDefaultClick = new EventEmitter<boolean>();
  isLoaded: boolean;
  isMenuLoading: boolean;
  catalogChoicesClone: CatalogChoice[] = [];
  categories: Category[] = [];
  catalogType: PopCatalogType;
  packages: PopPackage[] = [];
  categoryImages: object[];

  destroy$ = new Subject<void>();

  protected imageMetaControl = new FormControl<{ url: string; label: string }>({ url: null, label: null });

  constructor(private catalogService: ProductCatalogService) {
    this.categoryImages = categoryImages;
  }

  ngOnInit(): void {
    this._markSelectedCatalogChoice(this.catalog, this.catalogChoices);

    this.imageMetaHandler();

    this.isLoaded = true;

    this.defaultCatalogHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  delete(): void {
    this.deleted.emit();
  }

  onCatalogSelected(event: any): void {
    const index = parseInt(event.currentTarget.value, 10);
    const selectedCatalogChoice = this.catalogChoicesClone[index];

    this.form.controls['catalogId'].setValue(selectedCatalogChoice.catalogId);
    this.form.controls['catalogType'].setValue(selectedCatalogChoice.catalogType);
    this.form.controls['visibleCategories'].reset();

    this._updateCategories(selectedCatalogChoice.catalogId);
    this._updateCatalogType(selectedCatalogChoice.catalogType);
    this._downloadPackages(selectedCatalogChoice);
  }

  private _markSelectedCatalogChoice(catalog: Catalog, choices: CatalogChoice[]): void {
    const catalogsClone: CatalogChoice[] = JSON.parse(JSON.stringify(choices));

    const choice = catalogsClone.find(
      item => item.catalogId.toString() === catalog.catalogId && item.catalogType === catalog.catalogType,
    );

    if (choice) {
      choice.isSelected = true;
      this._updateCategories(choice.catalogId);
      this._updateCatalogType(choice.catalogType);
      this._downloadPackages(choice);
    }

    this.catalogChoicesClone = catalogsClone;
  }

  private _updateCategories(catalogId: number): void {
    this.categories = this.categoriesGroupedByCatalog[catalogId];
  }

  private _updateCatalogType(catalogType: PopCatalogType): void {
    this.catalogType = catalogType;
  }

  private _downloadPackages(catalogChoice: CatalogChoice): void {
    if (catalogChoice.catalogType !== PopCatalogType.Menu) {
      return;
    }

    this.isMenuLoading = true;
    this.catalogService.getPackages(catalogChoice.catalogId).subscribe((packages: PopPackage[]) => {
      this.packages = packages;
      this.isMenuLoading = false;
    });
  }

  private imageMetaHandler(): void {
    const imageMetaFormGroup = this.form.get('imageMeta');
    this.imageMetaControl.patchValue({ url: imageMetaFormGroup.value.url, label: imageMetaFormGroup.value.label });

    this.imageMetaControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(imageMeta => {
      imageMetaFormGroup.patchValue({
        ...imageMetaFormGroup.value,
        url: imageMeta ? imageMeta.url : null,
        label: imageMeta ? imageMeta.label : null,
      });
    });
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get catalogId(): FormControl<number> {
    return this.form.get('catalogId') as FormControl<number>;
  }

  private get isDefault(): FormControl<boolean> {
    return this.form.get('isDefault') as FormControl<boolean>;
  }

  private defaultCatalogHandler(): void {
    this.isDefault.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.isDefaultClick.emit(value);
    });
  }
}
