import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RecommendationsGroupDesigner } from '../shared';
import { actions } from './recommendations-groups-designer.actions';
import { State } from './recommendations-groups-designer.type';

export const adapter: EntityAdapter<RecommendationsGroupDesigner> = createEntityAdapter<RecommendationsGroupDesigner>({
  sortComparer: (a, b) => b.id - a.id,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoadedByAppSlug: null,
  isLoadingByAppSlug: null,
  selectedId: null,
  error: null,
});

const recommendationsGroupsDesignerReducer = createReducer(
  initialState,
  on(actions.getRecommendationsGroups, (state, { appSlug }) => ({
    ...state,
    isLoadingByAppSlug: appSlug,
    isLoading: true,
  })),

  on(actions.addRecommendationsGroup, actions.saveRecommendationsGroup, actions.deleteRecommendationsGroup, state => ({
    ...state,
    isLoading: true,
  })),

  // Success actions
  on(actions.getRecommendationsGroupsSuccess, (state, { appSlug, groups }) =>
    adapter.setAll(groups, { ...state, isLoadedByAppSlug: appSlug }),
  ),

  on(actions.addRecommendationsGroupSuccess, (state, { recommendationsGroup }) =>
    adapter.addOne(recommendationsGroup, state),
  ),

  on(actions.saveRecommendationsGroupSuccess, (state, { recommendationsGroup: { id, name, displayLabel } }) =>
    adapter.updateOne({ id, changes: { name, displayLabel } }, state),
  ),

  on(actions.deleteRecommendationsGroupSuccess, (state, { groupId }) => adapter.removeOne(groupId, state)),

  on(
    actions.getRecommendationsGroupsSuccess,
    actions.getRecommendationsGroupsIsLoaded,
    actions.addRecommendationsGroupSuccess,
    actions.saveRecommendationsGroupSuccess,
    actions.deleteRecommendationsGroupSuccess,
    state => ({ ...state, isLoading: false }),
  ),

  // Error actions

  on(
    actions.getRecommendationsGroupsError,
    actions.addRecommendationsGroupError,
    actions.saveRecommendationsGroupError,
    actions.deleteRecommendationsGroupError,
    (state, { errorCode }) => ({ ...state, error: errorCode, isLoading: false }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return recommendationsGroupsDesignerReducer(state, action);
}
