import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetTwitterProfile } from './twitter-profile';

@Component({
  selector: 'pop-widget-twitter-profile',
  templateUrl: './twitter-profile.component.html',
  styleUrls: ['./twitter-profile.component.scss'],
})
export class WidgetTwitterProfileComponent implements OnInit, AfterViewInit, WidgetComponent {
  static widgetName = 'twitter_profile';
  @Input() attributes: WidgetTwitterProfile;

  uuid: string;
  isValid: boolean;
  noValidMessage: string;
  placeholderImage = '/assets/img/twitter/twitter-logo.png';
  constructor(private _warningService: WarningService) {}

  openTimeline(): void {
    const profile = document.getElementById(`profile-${this.uuid}`);
    const twitter = document.getElementById(`twitter-timeline-${this.uuid}`);

    profile.classList.toggle('active');
    profile.className = profile.classList.toString();
    twitter.classList.toggle('hidden');
    twitter.className = twitter.classList.toString();
  }

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.url || !this.attributes.name || !this.attributes.twitterId) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetTwitterProfileComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }
    this.isValid = true;

    (window as any).twttr = (function (d: Document, s: string, id: string): string {
      let js: any;
      const fjs = d.getElementsByTagName(s)[0];
      const t = (window as any).twttr || {};
      if (d.getElementById(id)) {
        return t;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any): void {
        t._e.push(f);
      };

      return t;
    })(document, 'script', 'twitter-wjs');

    this.uuid = this._getUUID();
  }

  ngAfterViewInit(): void {
    if (this.isValid && (window as any).twttr.ready()) {
      (window as any).twttr.widgets.load();
    }
  }

  /* This is a workaround to generate uuid  for some html elements. */
  private _getUUID(): string {
    const uuid = `uid${Math.ceil(Date.now() * Math.random())}`;

    return uuid;
  }
}
