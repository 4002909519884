import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SnackbarModule } from '@designer-modules/snackbar';

import { featureName } from './page.config';
import { PageEffects } from './page.effects';
import { reducer } from './page.reducer';
import { PageHttpService } from './page-http.service';

@NgModule({
  imports: [StoreModule.forFeature(featureName, reducer), EffectsModule.forFeature([PageEffects]), SnackbarModule],
  providers: [PageHttpService],
})
export class DesignerPageStoreModule {}
