import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { CategoriesDisplayType, CategoryAlignType, CategoryStyleType } from '@bend/store-shared';

@Injectable({
  providedIn: 'root',
})
export class StylesService {
  readonly categoryPrimaryBg$: Observable<string>;
  readonly categoryPrimaryFg$: Observable<string>;
  readonly isRowNav$: Observable<boolean>;
  readonly isOnTopNav$: Observable<boolean>;

  constructor(private _settings: SettingsService) {
    this.categoryPrimaryBg$ = this._categoryPrimaryBg;
    this.categoryPrimaryFg$ = this._categoryPrimaryFg;
    this.isRowNav$ = this._isRowNav;
    this.isOnTopNav$ = this._isOnTop;
  }

  private get _isRowNav(): Observable<boolean> {
    return this._settings.widgetProductCatalog.categoryAlignType.pipe(map(type => type === CategoryAlignType.Style1));
  }

  private get _isOnTop(): Observable<boolean> {
    return this._settings.widgetProductCatalog.categoriesStyle.pipe(
      map(style => CategoriesDisplayType.Style3 === style),
    );
  }

  private get _categoryPrimaryBg(): Observable<string> {
    return this._settings.widgetProductCatalog.categoryStyleType.pipe(
      switchMap(style => (style === CategoryStyleType.Style2 ? this._settings.colors.primary : of('#000'))),
    );
  }

  private get _categoryPrimaryFg(): Observable<string> {
    return this._settings.widgetProductCatalog.categoryStyleType.pipe(
      switchMap(style => (style === CategoryStyleType.Style2 ? this._settings.colors.primaryForeground : of('#fff'))),
    );
  }
}
