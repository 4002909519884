import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DesignerRouterState } from './router.types';

export const selectRouter = createFeatureSelector<RouterReducerState<DesignerRouterState>>('router');

/**
 * custom selectors from Router Store
 */
export const selectRouteParams = createSelector(selectRouter, ({ state }) => state.params);
export const selectQueryParams = createSelector(selectRouter, ({ state }) => state.queryParams);
/**
 * values from route params
 */
export const selectAppSlugParam = createSelector(selectRouteParams, params => params.appSlug);
export const selectLocationIdParam = createSelector(selectRouteParams, params => params.locationId);
export const selectAreaIdParam = createSelector(selectRouteParams, params => params.areaId);
export const selectPageIdParam = createSelector(selectRouteParams, params => params.pageId);
export const selectPaymentConfigIdParam = createSelector(selectRouteParams, params => params.paymentConfigId);
export const selectWidgetIdParam = createSelector(selectRouteParams, params => params.widgetId);
/**
 * values from query params
 */
export const selectLanguageQueryParam = createSelector(selectQueryParams, queryParams => queryParams.language);
