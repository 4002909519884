export enum TransactionReason {
  NewTicket = 'add-ticket',
  RefundCredit = 'refund-credit',
  UseCredit = 'use-credit',
  Custom = 'custom',
  RejectTicket = 'reject-ticket',
}

export interface Reason {
  type: TransactionReason | string;
  data: {
    orderId?: number;
    ticketCode?: string;
    message?: string;
  };
}

export enum TicketStatus {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  AdminNotFound = 'admin_not_found',
}

export interface Transaction {
  id: number;
  amount: number;
  paymentMethod: CreditPaymentMethods;
  reason: Reason;
  createdAt: Date;
}

export enum PaymentMethods {
  Lyf = 'lyf',
  CreditCard = 'credit_card',
  RestaurantTicket = 'restaurant_tickets',
  Swile = 'swile',
  Cash = 'cash',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
  Other = 'other',
  UserCredit = 'user_credit',
}

export enum CreditPaymentMethods {
  CreditCard = 'credit_card',
  Lyf = 'lyf',
  RestaurantTicket = 'restaurant_ticket',
  Cash = 'cash',
  Other = 'other',
  Swile = 'swile',
  CB = 'cb',
  CRT = 'crt',
  Check = 'check',
  Conecs = 'conecs',
  Accord = 'accord',
  Amex = 'amex',
  ANCV = 'ancv',
  Promotion = 'promotion',
  Bank = 'bank',
}

export interface Ticket {
  amount: number;
  code: string;
  createdAt: string;
  id: number;
  reviewedAt: string;
  status: TicketStatus;
}

export interface GenericTransactions {
  total: number;
  limit: number;
  offset: number;
}

export interface UserTransactions extends GenericTransactions {
  items: Transaction[];
}

export interface TicketTransactions extends GenericTransactions {
  tickets: Ticket[];
}

export interface UserCredit {
  amount: number;
  reason: string;
  paymentMethod: string;
}
