export const enum NextAction {
  /**
   * pay after first action
   */
  Send = 'SEND',
  /**
   * PayBefore
   * when is 'Pay by pop' or 'Pay by pop or Pay by waiter'
   *
   * PayAfter
   * after order is sent
   * when is 'Pay by pop' or 'Pay by pop or Pay by waiter'
   */
  Pay = 'PAY',
  /**
   * PayBefore
   * when is 'Pay by waiter'
   */
  PayWaiter = 'PAY_WAITER',
  /**
   * no required any action
   */
  None = 'NONE',
}
