import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Option } from '../shared';
import { actions } from './option.actions';
import * as selectors from './option.selectors';
import { State } from './option.type';

@Injectable()
export class OptionService {
  readonly all: Observable<Option[]>;
  readonly id!: Observable<number[]>;
  readonly name!: Observable<string[]>;
  readonly priceFinalValue!: Observable<number[]>;
  readonly order!: Observable<number[]>;
  readonly imageUrl!: Observable<string[]>;
  readonly quantityMultiplier!: Observable<number[]>;
  readonly groupId!: Observable<number[]>;
  readonly productId!: Observable<number[]>;
  readonly quantity!: Observable<number[]>;
  readonly visible!: Observable<boolean[]>;
  readonly isOutOfStock!: Observable<boolean[]>;
  readonly isReadOnly!: Observable<boolean[]>;

  readonly allSelected: Observable<Option[]>;

  constructor(protected _store: Store<State>) {
    this.all = this._all;
    this.allSelected = this._allSelected;
  }

  allBy(groupId: number): Observable<Option[]> {
    return this._all.pipe(map(items => items.filter(item => item.groupId === groupId)));
  }

  byId(id: number): Observable<Option> {
    return this._all.pipe(map(all => all.find(({ id: idItem }) => id === idItem)));
  }

  changeQuantity(by: number, quantity: number): void {
    this._store.dispatch(actions.changeQuantity({ by, quantity }));
  }

  resetQuantity(): void {
    this._store.dispatch(actions.resetQuantity());
  }

  getOptionsQuantityByGroup(groupId: number): Observable<number> {
    return this.allBy(groupId).pipe(map(options => this._sum(options)));
  }

  get allOptionQuantity(): Observable<number> {
    return this.all.pipe(map(options => this._sum(options)));
  }

  private _sum(options: Option[]): number {
    return options.reduce((acc, { quantity: optionQuantity }) => acc + optionQuantity, 0);
  }

  private get _allSelected(): Observable<Option[]> {
    return this._all.pipe(map(items => items.filter(({ quantity }) => quantity)));
  }

  private get _all(): Observable<Option[]> {
    return this._store.select(selectors.all);
  }
}
