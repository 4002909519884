import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs';

import { ItemUnavailabilityDisplay, ProductRecommendationGroup } from '@bend/store';

import { PopProduct } from '../../product-catalog';
import { PricesService } from '../../services';

@Component({
  selector: 'app-recommendation-group',
  templateUrl: './recommendation-group.component.html',
  styleUrls: ['./recommendation-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationGroupComponent implements OnInit {
  @Input() group: ProductRecommendationGroup;
  @Input() parentItemAvailability: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;

  itemUnavailabilityDisplayEnum = ItemUnavailabilityDisplay;

  constructor(
    private prices: PricesService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.prices.flow.pipe(take(1)).subscribe(flow => {
      this.group = {
        ...this.group,
        recommendations: this.group.recommendations.map(rec => this.prices.getUpdatedProductByFlow(rec, flow)),
      };
      this.cdr.detectChanges();
    });
  }

  trackByFn(_: number, { id }: PopProduct): number {
    return id;
  }

  isRecommendationGroupAvailable(group: ProductRecommendationGroup): boolean {
    return (
      group.recommendations.some(recommendation => recommendation.available) ||
      this.itemUnavailabilityDisplay === this.itemUnavailabilityDisplayEnum.OutOfStock
    );
  }
}
