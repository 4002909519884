import { Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export function getRoute<T>(route: ActivatedRoute, type: Type<T>): ActivatedRoute {
  if (route.snapshot.component === type) {
    return route;
  }

  return getRoute(route.firstChild, type);
}
