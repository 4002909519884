import { Widget } from '../../shared/models';

export interface ActionCallWidget extends Widget {
  published: boolean;
  attributes: ActionCallWidgetAttributes;
}

export interface ActionCallWidgetAttributes {
  actionButtons: Action[];
  icon: string;
  title: string;
  popupHeader: string;
  style: {
    margin: {
      bottom: number;
      top: number;
    };
    borderRadius: number;
    displayType: ActionCallDisplayType;
  };
}

export interface ActionCallWidgetForm extends ActionCallWidgetAttributes {
  baseActionsChecked: Action[];
}

export interface Action {
  type?: ActionType;
  transmitionType?: TransmitionType;
  title?: string;
  icon?: string;
  description?: string;
  actionMessage?: string;
  telephone?: string;
  sms?: string;
  email?: string;
  pos?: string;
  designer?: string;
  addTableName?: boolean;
  body?: string;
  directMessage?: boolean;
  from?: string;
  subject?: string;
}

export interface ActionChecked extends Action {
  checked: boolean;
}

export enum ActionType {
  BREAD = 'bread',
  WATER = 'water',
  CHECK = 'check',
  WAITER = 'waiter',
  CUSTOM = 'custom',
}

export enum TransmitionType {
  TELEPHONE = 'telephone',
  SMS = 'sms',
  EMAIL = 'email',
  POS = 'pos',
  DESIGNER = 'designer',
}

export enum ActionCallDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}
