import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[recommendation-menu-backdrop]' })
export class RecommendationMenuBackdropDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @Input('recommendation-menu-backdrop') set isBackdropVisible(value: boolean) {
    this.renderer.setStyle(this.el.nativeElement, 'overflow', value ? 'hidden' : 'auto');
  }
}
