import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-header',
  templateUrl: './group-header.component.html',
  styleUrls: ['./group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupHeaderComponent {
  @Input() title = '';
}
