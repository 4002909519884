import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { Recommendation } from '@bend/store';

import { storeKey } from './recommendation-group.config';
import { adapter } from './recommendation-group.reducer';
import { State } from './recommendation-group.type';

const stateSelector = createFeatureSelector<State>(storeKey);
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);

export const isLoadedBy = createSelector(stateSelector, state => state.isLoadedBy);
export const error = createSelector(stateSelector, state => state.errorCode);
export const isLoading = createSelector(stateSelector, state => state.isLoading);
export const selectedProductId = createSelector(stateSelector, state => state.selectedProductId);

export const getPlaceRecommendation = (itemId: number, groupId: number): MemoizedSelector<object, Recommendation> =>
  createSelector(stateSelector, state =>
    state.recommendationsByPlace.find(({ id }) => id === groupId).recommendations.find(({ id }) => id === itemId),
  );

export const recommendationsByPlace = createSelector(stateSelector, state => state.recommendationsByPlace);
export const recommendationItemsIds = createSelector(stateSelector, state => state.recommendationItemsIds);
export const placeRecommendationsInLoading = createSelector(stateSelector, state => state.isLoadingByPlace);
