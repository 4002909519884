import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Item,resetEntitiesQuantity } from '../shared';
import { actions } from './item.actions';
import { State } from './item.type';

export const adapter: EntityAdapter<Item> = createEntityAdapter<Item>();

export const initialState: State = adapter.getInitialState({
  loadedParentItemIds: [],
  loadedMenuIds: [],
  loadedCatalogIds: [],
  errorCode: null,
  isLoading: false,
});

const itemReducer = createReducer(
  initialState,
  on(actions.addItems, (state, { entities }) => adapter.addMany(entities, state)),

  on(actions.changeQuantity, (state, { by, quantity }) =>
    adapter.updateOne(
      {
        id: by,
        changes: { quantity },
      },
      state,
    ),
  ),

  on(actions.resetQuantity, state => adapter.updateMany(resetEntitiesQuantity<Item>(state.entities), state)),

  on(actions.getMenus, actions.getMenusIsLoaded, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.getMenusSuccess, (state, { menus, catalogId }) =>
    adapter.addMany(menus, {
      ...state,
      isLoading: false,
      loadedCatalogIds: [...state.loadedCatalogIds, catalogId],
    }),
  ),

  on(actions.getMenusError, (state, { errorCode }) => ({
    ...state,
    errorCode,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return itemReducer(state, action);
}
