import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EnvModule } from '@bend/env';

import { ApiDesignerService } from '../shared';
import { storeKey } from './promo-codes.config';
import { PromoCodesEffects } from './promo-codes.effects';
import { PromoCodesHttpService } from './promo-codes.http.service';
import { reducer } from './promo-codes.reducer';
import { PromoCodesService } from './promo-codes.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([PromoCodesEffects]), EnvModule],
  providers: [PromoCodesHttpService, PromoCodesService, ApiDesignerService],
})
export class PromoCodesStoreModule {}
