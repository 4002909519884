import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Tag } from '../shared';
import { actions } from './tags.actions';
import { State } from './tags.type';

export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>({
  sortComparer: (a, b) => b.id - a.id,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoadedByAppSlug: null,
  isLoadingByAppSlug: null,
  isLoadedBy: [],
  selectedId: null,
  errorCode: null,
});

const tagsReducer = createReducer(
  initialState,
  on(actions.getList, (state, { appSlug }) => ({
    ...state,
    isLoadingByAppSlug: appSlug,
    isLoading: true,
  })),

  on(actions.get, actions.add, actions.update, actions.delete, state => ({ ...state, isLoading: true })),

  // Success Actions

  on(actions.getListSuccess, (state, { tags, appSlug }) =>
    adapter.setAll(tags, {
      ...state,
      isLoadedByAppSlug: appSlug,
      isLoadedBy: [],
      isLoading: false,
    }),
  ),

  on(actions.getListIsLoaded, state => ({ ...state, isLoading: false })),

  on(actions.getIsLoaded, (state, { id }) => ({
    ...state,
    selectedId: id,
    isLoading: false,
  })),

  on(actions.getSuccess, (state, { tag: { id, ...rest } }) =>
    adapter.updateOne(
      { id, changes: rest },
      {
        ...state,
        isLoadedBy: [...state.isLoadedBy, id],
        selectedId: id,
        isLoading: false,
      },
    ),
  ),

  on(actions.addSuccess, (state, { tag }) =>
    adapter.upsertOne(tag, {
      ...state,
      isLoadedBy: [...state.isLoadedBy, tag.id],
      isLoading: false,
    }),
  ),

  on(actions.updateSuccess, (state, { tag: { id, ...rest } }) =>
    adapter.updateOne(
      { id, changes: rest },
      {
        ...state,
        isLoading: false,
      },
    ),
  ),

  on(actions.deleteSuccess, (state, { id }) =>
    adapter.removeOne(id, {
      ...state,
      isLoadedBy: state.isLoadedBy.filter(id => id !== id),
      isLoading: false,
      selectedId: null,
    }),
  ),

  // Error Actions

  on(
    actions.getListError,
    actions.getError,
    actions.addError,
    actions.updateError,
    actions.deleteError,
    (state, { errorCode }) => ({ ...state, errorCode, isLoading: false }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return tagsReducer(state, action);
}
