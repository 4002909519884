import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { ApiDesignerService } from '@bend/store-shared';

import { GetPagesListResponse, PageDTO, PageModel } from './page.models';

@Injectable()
export class PageHttpService {
  constructor(private readonly _api: ApiDesignerService, private readonly _http: HttpClient) {}

  getPages(): Observable<GetPagesListResponse> {
    return this._api.pages('v1').pipe(switchMap(url => this._http.get<GetPagesListResponse>(url)));
  }

  getPage(pageId: string): Observable<PageModel> {
    return this._api.page('v1', pageId).pipe(switchMap(url => this._http.get<PageModel>(url)));
  }

  createPage(page: PageDTO): Observable<PageModel> {
    return this._api.pages('v1').pipe(switchMap(url => this._http.post<PageModel>(url, page)));
  }

  updatePage(pageId: string, page: PageDTO): Observable<PageModel> {
    return this._api.page('v1', pageId).pipe(switchMap(url => this._http.patch<PageModel>(url, page)));
  }

  deletePage(pageId: string): Observable<void> {
    return this._api.page('v1', pageId).pipe(switchMap(url => this._http.delete<void>(url)));
  }

  duplicatePage(pageId: string, name: string): Observable<PageModel> {
    return this._api.duplicatePage('v1', pageId).pipe(switchMap(api => this._http.post<PageModel>(api, { name })));
  }
}
