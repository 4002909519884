import { Component, Input, OnInit } from '@angular/core';

import { LinkService } from '../link.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetShopTeaser } from './shop-teaser';

@Component({
  selector: 'pop-widget-shop-teaser',
  templateUrl: './shop-teaser.component.html',
  styleUrls: ['./shop-teaser.component.scss'],
})
export class WidgetShopTeaserComponent implements OnInit, WidgetComponent {
  static widgetName = 'shop_teaser';

  @Input() attributes: WidgetShopTeaser;
  isValid: boolean;
  noValidMessage: string;

  constructor(private _warningService: WarningService, private _linkService: LinkService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.title) {
      this.noValidMessage = this._warningService.showWarn(
        WidgetShopTeaserComponent.widgetName,
        WarnTypeEnum.NoAttributes,
      );

      return;
    }

    this.isValid = true;
  }

  navigate(): void {
    this._linkService.navigate(this.attributes.link, this.attributes.openInNewTab, this.attributes.isEditMode);
  }
}
