export const fonts = [
  'Georgia',
  'Palatino Linotype',
  'Times New Roman',
  'Arial',
  'Arial Black',
  'Comic Sans MS',
  'Impact',
  'Lucida Sans Unicode',
  'Tahoma',
  'Trebuchet MS',
  'Verdana',
  'Courier New',
  'Lucida Console',
  'BebasNeue',
];
