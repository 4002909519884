import { Injectable } from '@angular/core';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { TotalService, TypeService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { AppType } from '@bend/shared-widgets/src/lib/types';
import { AuthService } from '@bend/store';
import { CartService } from '@bend/store';
import { SettingsService } from '@bend/store';
import { CollectType, PlaceType } from '@bend/store-shared';

@Injectable()
export class CartDetailsService {
  constructor(
    private _settings: SettingsService,
    private _auth: AuthService,
    private _cart: CartService,
    private _total: TotalService,
    private _type: TypeService,
  ) {}

  get hasDetailsPage(): Observable<boolean> {
    return combineLatest([
      /**
       * check if is delivery or take away
       * because we show details page for delivery or takeaway every time for remote place
       */
      this._isDeliveryOrTakeAway,
      /**
       * if user is registered don't show contact details
       */
      this._auth.isRegistered.pipe(
        switchMap(isRegistered =>
          iif(
            () => isRegistered,
            of(false),
            /**
             * if user isn't registered check settings for user details
             */
            this._settings.widgetCart.pipe(
              /**
               * if one of the variables below is true then that field must be completed in details page
               */
              map(({ askName, askEmail, askPhone }) => askName || askEmail || askPhone),
            ),
          ),
        ),
      ),
      /**
       * if there is one of this fields in settings then show details page
       */
      this._settings.widgetCart.pipe(
        map(
          ({ askFirstName, askLastName, askGender, askDateOfBirth, askBirthPlace }) =>
            askFirstName || askLastName || askGender || askDateOfBirth || askBirthPlace,
        ),
      ),
      this._cart.isLoading.pipe(
        /**
         * check payment methods is loaded
         */
        filter(isLoading => !isLoading),
        switchMap(() =>
          this._type.app.pipe(
            withLatestFrom(this._isDeliveryOrTakeAway),
            /**
             * don't show details page if there is only 1 payment method
             * for pay after and collect flow
             */
            switchMap(([type, isDeliveryOrTakeAway]) =>
              type === AppType.PayAfter || isDeliveryOrTakeAway
                ? this._cart.paymentMethods.pipe(
                    switchMap(methods =>
                      methods.length > 1 ? this._total.currentRemainToPay.pipe(map(Boolean)) : of(false),
                    ),
                  )
                : of(false),
            ),
          ),
        ),
      ),
    ]).pipe(
      map(values => values.some(Boolean)),
      distinctUntilChanged(),
    );
  }

  private get _isDeliveryOrTakeAway(): Observable<boolean> {
    const allowCollectStatus = new Set([CollectType.Delivery, CollectType.TakeAway]);

    return this._settings.collectTypes.pipe(
      withLatestFrom(this._settings.placeType),
      map(
        ([collectTypes, placeType]) =>
          placeType === PlaceType.Remote && collectTypes.some(collect => allowCollectStatus.has(collect)),
      ),
    );
  }
}
