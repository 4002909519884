import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import { AskQuestionLabel, AskQuestionLabels, AskQuestionLabelsNames, AskQuestionWidget } from './ask-question';

@Component({
  selector: 'pop-widget-edit-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditAskQuestionComponent extends WidgetBaseComponent<AskQuestionWidget> implements OnInit {
  static widgetName = 'ask_question';
  labels: AskQuestionLabel[];
  labelsNames = AskQuestionLabelsNames;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormServiceChild: WidgetFormService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super(widgetService, _widgetFormServiceChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.labels = this._getLabels();
    this._initForm();
  }

  private _initForm(): void {
    const labelGroup = this._getLabelGroup(this.labels, this.widget);

    this._widgetFormServiceChild.initAttributes(
      this._formBuilder.group({
        hideQuestions: [this.widget.attributes.hideQuestions],
        showOnlyMyQuestions: [this.widget.attributes.showOnlyMyQuestions],
        labels: this._formBuilder.group(labelGroup),
      }),
    );

    this.form = this._widgetFormServiceChild.getAttributes as UntypedFormGroup;
  }

  private _getLabelGroup(labels: AskQuestionLabel[], widget: AskQuestionWidget): object {
    const group = {};

    labels.forEach(label => {
      group[label.key] = [widget.attributes.labels[label.key]];
    });

    return group;
  }

  private _getLabels(): AskQuestionLabel[] {
    const askQuestionLabels = new AskQuestionLabels();
    const labels = Object.keys(askQuestionLabels).map(key => {
      return {
        key,
        name: askQuestionLabels[key],
      };
    });

    return labels;
  }

  private _addMissingAttributes(widget: AskQuestionWidget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = new AskQuestionLabels();
    }
  }

  get showOnlyMyQuestions(): string {
    return this.form.get('showOnlyMyQuestions').value;
  }

  get hideQuestions(): string {
    return this.form.get('hideQuestions').value;
  }

  get attributes(): string {
    return this.form.get('attributes').value;
  }
}
