import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { EnvService } from '@bend/env';
import { ApiDesignerService } from '@bend/store-shared';

import { DesignerRouterService } from '@designer-store/router';

import { Page } from '../shared/interfaces';

@Injectable()
export class WidgetService {
  appHost: string;

  constructor(
    private _http: HttpClient,
    private _api: ApiDesignerService,
    private _designerRouter: DesignerRouterService,
    private _env: EnvService,
  ) {
    this.appHost = this._env.apiHost;
  }

  updateWidget(widgetId: string, body: string): Observable<any> {
    return this._designerRouter.pageId.pipe(
      switchMap(pageId => this._api.pageWidget('v1', pageId, widgetId)),
      switchMap(api => this._http.patch(api, body)),
    );
  }

  /**
   * File loader helper
   * @param file file data
   */
  uploadFile(file: FormData): Observable<any> {
    return this._api
      .upload('v1')
      .pipe(switchMap(api => this._http.request(new HttpRequest('POST', api, file, { reportProgress: true }))));
  }

  /**
   * This is a temporary function. It will be used until all widgets won't use file-upload component.
   */
  uploadFileNoReportProgress(file: FormData): Observable<any> {
    return this._api.upload('v1').pipe(switchMap(api => this._http.post(api, file)));
  }

  getAllPages(): Observable<{ single: Page[] }> {
    return this._api.pages('v1').pipe(switchMap(api => this._http.get<{ single: Page[] }>(api)));
  }

  getPages(): Observable<{ pages: { single: Page[] }; groups: { title: string; data: any }[] }> {
    return this.getAllPages().pipe(
      map(groupsData => {
        const pages = groupsData;
        const groups = this.groupPagesByCategory(pages);
        return { pages, groups };
      }),
    );
  }

  groupPagesByCategory(pages: any): { title: string; data: any }[] {
    return [
      {
        title: 'Single Pages',
        data: this.sortGroupData(['single'], pages),
      },
    ];
  }

  sortGroupData(type: string[], pages: any): Page[] {
    return this.getGroupData(type, pages)
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getGroupData(type: string[], pages: any): Page[] {
    return type.length < 2 ? pages[type[0]] : pages[type[0]][type[1]];
  }
}
