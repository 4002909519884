import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, tap, withLatestFrom } from 'rxjs';

import { BackgroundContentType } from '@designer-store/page/page.models';

import { ParamsService } from '../params';
import { createForeground, filterUndefined, ifPluck, Page, PageFont } from '../shared';
import { actions } from './pages.actions';
import * as selectors from './pages.selectors';
import { State } from './pages.type';

@Injectable()
export class PagesService {
  readonly color: Observable<string>;
  readonly background: Observable<{ content: string; type: BackgroundContentType }>;
  readonly secondaryForeground: Observable<string>;

  constructor(private _store: Store<State>, private _params: ParamsService) {
    this.color = this._color;
    this.background = this._background;
    this.secondaryForeground = this._secondaryForeground;
  }

  resetPage(pageId: string): void {
    this._store.dispatch(actions.resetPageById({ id: pageId }));
  }

  reset(): void {
    this._store.dispatch(actions.reset());
  }

  get pageWidgets(): Observable<string[]> {
    return this._store.select(selectors.widgets);
  }

  private get _color(): Observable<string | null> {
    return this._background.pipe(map(bg => (bg.type === 'color' ? bg.content : null)));
  }

  private get _background(): Observable<{ content: string; type: BackgroundContentType }> {
    return this.current.pipe(ifPluck('background'));
  }

  private get _secondaryForeground(): Observable<string> {
    return this._color.pipe(map(color => createForeground(color)));
  }

  get font(): Observable<PageFont> {
    return this.current.pipe(ifPluck('font'));
  }

  get current(): Observable<Page> {
    return this._store.select(selectors.page);
  }

  get error(): Observable<string | null> {
    return this._store.select(selectors.error);
  }

  get isLoading(): Observable<boolean> {
    return this._store.select(selectors.isLoading).pipe(filterUndefined());
  }

  get isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoaded).pipe(filterUndefined());
  }

  get getPage(): Observable<any> {
    return this._params.pageId.pipe(
      filter<string>(Boolean),
      withLatestFrom(this._params.queryParams()),
      tap(([id, params]) => this._store.dispatch(actions.getById({ id, params }))),
    );
  }
}
