import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonAbstractComponent } from '../../abstract';

@Component({
  selector: 'designer-stroked-button',
  templateUrl: './stroked-button.component.html',
  styleUrls: ['../../styles/common-button.scss', './stroked-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrokedButtonComponent extends ButtonAbstractComponent {
  constructor() {
    super();
  }
}
