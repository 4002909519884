import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService } from '@bend/store-shared';

import { Feedback } from './feedback';

@Injectable()
export class WidgetFeedbackService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  sendFeedback(pageId: string, feedback: Feedback): Observable<any> {
    return this._api.feedback('v1', pageId).pipe(switchMap(api => this._http.post(api, feedback)));
  }

  getFeedbacks(pageId: string): Observable<any> {
    return this._api.feedback('v1', pageId).pipe(switchMap(api => this._http.get(api)));
  }

  uploadFile(file: FormData): Observable<any> {
    return this._api.upload('v1').pipe(
      switchMap(api => {
        const httpRequest = new HttpRequest('POST', api, file, { reportProgress: true });
        return this._http.request(httpRequest);
      }),
    );
  }
}
