import { ItemUnavailabilityDisplay } from '@bend/store';

import { PopCurrency, PopSettings } from '../widget';

export interface WidgetProductTeaser {
  catalogId: number;
  productId: number;
  imageUrl: string;
  useProductFromUrl: boolean;
  allowCommonPot: boolean;
  allowOrderForOthers: boolean;
  isEditMode: boolean;
  isReadOnly: boolean;
  mainColor: string;
  text: string;
  showKitchenComment: boolean;
  isCommentToKitchenRequired: boolean;
  itemUnavailableDisplay: ItemUnavailabilityDisplay;
  currency: PopCurrency;
  style: {
    borderRadius: number;
    backgroundColor: string;
    fullWidth: boolean;
    margin: {
      top: number;
      bottom: number;
    };
    text: {
      margin: number;
      verticalAlign: VerticalAlignType;
      backgroundColor: string;
    };
  };
  styleConfig: ProductCatalogStyleConfig;

  page: {
    name: string;
  };
  labels: ProductTeaserLabels;
  pop: PopSettings;
}

export interface ProductCatalogStyleConfig {
  hasSearch: true;
  productDescriptionStyle: number;
  stepByStepCategories: boolean;
  stepByStepMenus: boolean;
  style: ProductCatalogDisplayType;
}

export class ProductTeaserLabels {
  PLEASE_SELECT_THE_REQUIRED_OPTIONS = '';
  ADD_COMMENT_TO_KITCHEN = '';
  ADD_TO_CART = '';
  YOUR_COMMENT_HERE = '';
  ITS_FOR = '';
  ME = '';
  SHARE_WITH_TABLE = '';
  YOUR_NAME = '';
  OTHERS_NAME = '';
  OTHER_PEOPLE = '';
  PLEASE_ENTER_THE_NAME = '';
  SAVE = '';
  PRODUCT_ADDED = '';
  COMMENT_TO_KITCHEN_REQUIRED = '';
}

export enum VerticalAlignType {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum ProductCatalogDisplayType {
  STYLE1 = 1,
  STYLE2 = 2,
}
