import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { Page } from '../../shared/interfaces';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetHelper } from '../widget.helper';
import { WidgetService } from '../widget.service';
import {
  Catalog,
  CatalogChoice,
  CatalogItem,
  CatalogsCategoriesResponse,
  PopCatalogType,
  PopCatalogTypeLabelEnum,
  ProductCatalogLabels,
  ProductCatalogV2Widget,
} from './product-catalog-v2';
import { ProductCatalogV2Service } from './product-catalog-v2.service';

@Component({
  selector: 'pop-widget-edit-product-catalog-v2',
  templateUrl: './product-catalog-v2.component.html',
  styleUrls: ['./product-catalog-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@StaticImplements<WidgetType>()
export class PopWidgetEditProductCatalogV2Component
  extends WidgetBaseComponent<ProductCatalogV2Widget>
  implements OnInit
{
  static widgetName = 'product_catalog_v2';
  @Input() appHost: string;
  @Input() appSlug: string;
  catalogChoices: CatalogChoice[] = [];
  isLoaded: boolean;
  showErrors: boolean;
  categoriesGroupedByCatalog: CatalogsCategoriesResponse;
  catalogs: Catalog[] = [];
  finalUrl: string;
  pages: Page[];

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormSettingsChild: WidgetFormService,
    private formBuilder: UntypedFormBuilder,
    private catalogService: ProductCatalogV2Service,
  ) {
    super(widgetService, _widgetFormSettingsChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this._initForm();
    this._initCatalogs();
    this.onChangeLink();
  }

  addNewCatalog(): void {
    const catalog: Catalog = {
      name: '',
      imageMeta: {
        url: '',
        label: '',
      },
      catalogId: null,
      catalogType: PopCatalogType.ALaCarte,
      isDefault: false,
      visibleCategories: [],
      visibleMenus: [],
    };

    this._addCatalog(catalog);
  }

  onCatalogDeleted(index: number): void {
    this.catalogs.splice(index, 1);
    this.catalogsFormArray.removeAt(index);
  }

  onChangeLink(): void {
    this.finalUrl = WidgetHelper.generateFinalUrl(this.form.get('returnUrl').value, this.appHost);
  }

  onPageChange(page: Page): void {
    this.link.setValue(page._id);
    this.onChangeLink();
  }

  private _initForm(): void {
    this._widgetFormSettingsChild.initAttributes(
      this.formBuilder.group({
        catalogs: this.formBuilder.array([]),
        showKitchenComment: [this.widget.attributes.showKitchenComment],
        forceShowProductDescription: [this.widget.attributes.forceShowProductDescription],
        isCommentToKitchenRequired: [this.widget.attributes.isCommentToKitchenRequired],
        returnUrl: [this.widget.attributes.returnUrl],
        isReadOnly: [this.widget.attributes.isReadOnly],
        style: this.formBuilder.group({
          margin: this.formBuilder.group({
            bottom: [this.widget.attributes.style.margin.bottom],
            top: [this.widget.attributes.style.margin.top],
          }),
        }),
      }),
    );

    this.form = this._widgetFormSettingsChild.getAttributes as UntypedFormGroup;
  }

  private _initCatalogs(): void {
    this.widget.attributes.catalogs.forEach(catalog => {
      this._addCatalog(catalog);
    });
    this._prepareCatalogChoices();
  }

  private _prepareCatalogChoices(): void {
    this.catalogService.getCatalogs().subscribe(catalogs => {
      catalogs.forEach((catalog: CatalogItem) => {
        Object.keys(PopCatalogTypeLabelEnum).forEach(key => {
          const catalogChoice: CatalogChoice = {
            label: `${catalog.name} - by ${PopCatalogTypeLabelEnum[key]}`,
            catalogId: Number(catalog.id),
            catalogType: PopCatalogType[key],
            isSelected: false,
          };
          this.catalogChoices.push(catalogChoice);
        });
      });
      this._prepareAllCategories();
    });
  }

  private _prepareAllCategories(): void {
    this.catalogService.getAppCategories().subscribe(response => {
      this.categoriesGroupedByCatalog = response;
      this.isLoaded = true;
    });
  }

  private _addCatalog(catalog: Catalog): void {
    const formGroup = this._convertCatalogToFormGroup(catalog);
    this.catalogsFormArray.push(formGroup);
    this.catalogs.push(catalog);
  }

  private _convertCatalogToFormGroup(catalog: Catalog): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      catalogId: [catalog.catalogId, Validators.required],
      catalogType: catalog['catalogType'],
      name: [catalog['name'], Validators.required],
      imageMeta: [catalog['imageMeta']],
      isDefault: catalog['isDefault'] || false,
      visibleCategories: [catalog['visibleCategories'] || []],
      visibleMenus: [catalog['visibleMenus'] || []],
    });
    return formGroup;
  }

  private _addMissingAttributes(widget: ProductCatalogV2Widget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = new ProductCatalogLabels();
    }
  }

  get catalogsFormArray(): UntypedFormArray {
    return this.form.get('catalogs') as UntypedFormArray;
  }

  get link(): UntypedFormControl {
    return this.form.get('returnUrl') as UntypedFormControl;
  }
}
