export const hexToRgb = (hexColor: string) => {
  // turn hex val to RGB
  const checkHex = /^\#((?=[a-z0-9]+)(?:.{6}|.{3}))$/i.exec(hexColor);

  if (!checkHex) throw new Error('this is not a hex valid');

  const [, hex] = checkHex;

  const size = hex.length / 3;

  // 1 ^ 3 = 2 || 2 ^ 3 = 1
  const getColor = (pos: number) => parseInt(hex.slice(size * pos, size * pos + size).repeat(size ^ 3), 16);

  const r = getColor(0);
  const g = getColor(1);
  const b = getColor(2);

  return [r, g, b];
};

export const createForeground = (color: string) => {
  // rgb(255,255,255)
  // rgba(255,255,255,0.2)
  const isRgb = /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i.exec(color);

  // check is rgb
  const [r, g, b] = isRgb ? isRgb.slice(1).map(Number) : hexToRgb(color);

  // W3C algorithm ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
  return (r * 299 + g * 587 + b * 114) / 1000 > 125 ? '#202124' : '#ffffff';
};
