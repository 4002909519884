import { PopMargin } from '../widget';

export interface WidgetLink {
  title: string;
  link: string;
  openInNewTab: boolean;
  mainColor: string;
  isEditMode: boolean;
  style: {
    backgroundColor: string;
    borderColor: string;
    borderRadius: number;
    borderWidth: number;
    height: string;
    iconUrl: string;
    margin: PopMargin;
    showArrow: boolean;
    width: string;
    fontSize: string;
    textAlign: TextAlignType;
    textColor: string;
    displayType: LinkDisplayTypes;
  };
}

export enum TextAlignType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum LinkDisplayTypes {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}
