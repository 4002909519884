import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CategoryService, RecommendationGroupService } from '@bend/store';

@Injectable()
export class CatalogReloadService {
  readonly onReload: Observable<boolean>;

  private _onReload: Subject<boolean>;

  constructor(private _category: CategoryService, private _recommendation: RecommendationGroupService) {
    this._onReload = new Subject();

    this.onReload = this._onReload.asObservable();
  }

  reloadCatalog(): void {
    this._category.reloadCategories();
    this._recommendation.reset();
    this._onReload.next(true);
  }
}
