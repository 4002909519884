import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { first, tap } from 'rxjs/operators';

import { OrderService } from '@bend/order';
import { OrderItem, OrderItemStatus, OrderItemType } from '@bend/store-shared';

import { OptimisticService } from '../../../cart-services';

@Component({
  selector: 'lib-board-order-item-quantity [item] [showQuantity]',
  templateUrl: './board-order-item-quantity.component.html',
  styleUrls: ['./board-order-item-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrderItemQuantityComponent implements OnChanges {
  @Input() item: OrderItem<OrderItemType.Single>;
  @Input() showQuantity: boolean;
  isShowQuantity: boolean;

  constructor(private _order: OrderService, private _optimistic: OptimisticService) {}

  ngOnChanges(): void {
    this._updateShowQuantity();
  }

  addItem(): void {
    this._changeQuantity(1);
  }

  removeItem(): void {
    if (this.item.quantity === this.item.itemMeta.quantityMultiplier) {
      this._order
        .removeOrderItem(this.item.id, this.item.orderUserId)
        .pipe(
          first(),
          tap(isSuccess => isSuccess && this._optimistic.checkNewItem()),
        )
        .subscribe();
    } else {
      this._changeQuantity(-1);
    }
  }

  private _changeQuantity(term: 1 | -1): void {
    this._order
      .updateOrderItem(this.item.id, this.item.quantity + term * this.item.itemMeta.quantityMultiplier)
      .pipe(
        first(),
        tap(isSuccess => isSuccess && this._optimistic.checkNewItem()),
      )
      .subscribe();
  }

  private _updateShowQuantity(): void {
    // all statuses for show quantity buttons
    const showQuantityForStatuses = [
      OrderItemStatus.New,
      OrderItemStatus.OrderNotSent,
      OrderItemStatus.Reimbursed,
      OrderItemStatus.FailedPreCheck,
      OrderItemStatus.NoStock,
      OrderItemStatus.UnknownProduct,
    ];

    this.isShowQuantity = this.showQuantity && showQuantityForStatuses.includes(this.item.status);
  }
}
