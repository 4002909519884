import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';

export const bounce = trigger('bounce', [
  transition('* => *', [
    style({ transform: 'scale3d(.8, .8, .8)' }),
    animate('.6s cubic-bezier(.67,-1.99,.23,2.95)', style({ transform: 'scale3d(1, 1, 1)' })),
  ]),
]);

export const toggleY = trigger('toggleY', [
  transition(':enter', [style({ opacity: 0, height: 0 }), animate('.2s', style({ opacity: 1, height: '*' }))]),
  transition(':leave', [animate('.2s', style({ opacity: 0, height: 0 }))]),
]);

export const toggleX = trigger('toggleX', [
  transition(':enter', [style({ opacity: 0, width: 0 }), animate('.2s', style({ opacity: 1, width: '*' }))]),
  transition(':leave', [animate('.2s', style({ opacity: 0, width: 0 }))]),
]);

export const toggleO = trigger('toggleO', [
  transition(':enter', [style({ opacity: 0 }), animate('.2s', style({ opacity: 1 }))]),
  transition(':leave', [animate('.2s', style({ opacity: 0 }))]),
]);

export const itemHide = trigger('itemHide', [
  transition(':leave', [
    animate(
      '.6s cubic-bezier(.6, -0.25, .4, 1.25)',
      style({ height: 0, opacity: 0, transform: 'translate3d(100%, 0, 0)' }),
    ),
  ]),
]);
export const appear = trigger('appear', [
  transition(':enter', [style({ opacity: '0' }), animate('.5s ease-out', style({ opacity: '1' }))]),
]);

export const ngIf = trigger('ngIf', [transition(':enter, :leave', [query('@*', animateChild())])]);
