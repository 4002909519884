import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { DialogService } from '@bend/dialog';
import { SettingsService } from '@bend/store';

@Component({
  selector: 'app-qr-code-reader',
  templateUrl: './qr-code-reader.component.html',
  styleUrls: ['./qr-code-reader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeReaderComponent {
  isCameraFound: boolean;
  hasPermission: boolean;
  color$: Observable<string>;

  constructor(
    private _dialogRef: MatDialogRef<QrCodeReaderComponent>,
    private _settings: SettingsService,
    private _dialog: DialogService,
  ) {
    this.color$ = this._settings.colors.primary;
    this.hasPermission = false;
    this.isCameraFound = false;
  }

  scanSuccessHandler(event: string): void {
    if (/((http|https):\/\/)(www.)?/.exec(event)) {
      window.location.href = event;
    } else {
      this._dialog.error({ message: 'QR_READER.ERROR.QR_CODE_INVALID' });
    }
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  permissionResponse(event: boolean): void {
    this.hasPermission = event;

    if (this.isCameraFound && !this.hasPermission) {
      this._dialog.error({ message: 'QR_READER.ERROR.NOT_HAVE_PERMISSION_TO_CAMERA' });
      this.closeDialog();
    }
  }

  onCameraFound(event: boolean): void {
    this.isCameraFound = event;

    if (!this.isCameraFound) {
      this._dialog.error({ message: 'QR_READER.ERROR.CAMERA_NOT_FOUNT' });
      this.closeDialog();
    }
  }
}
