import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { MenuCategory } from '../shared';
import { actions } from './menu-category.actions';
import { State } from './menu-category.type';

export const adapter: EntityAdapter<MenuCategory> = createEntityAdapter<MenuCategory>();

export const initialState: State = adapter.getInitialState({});

const menuCategoryReducer = createReducer(
  initialState,
  on(actions.addMenuCategories, (state, { entities }) => adapter.addMany(entities, state)),
);

export function reducer(state: State | undefined, action: Action): State {
  return menuCategoryReducer(state, action);
}
