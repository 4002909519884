import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanLoginGuard } from '@designer-core';
import { AuthGuard, isAppSelected } from '@designer-core/services';

import { NotFoundComponent } from './shared/not-found/not-found.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginPageModule),
    canActivate: [CanLoginGuard],
  },
  {
    path: 'apps',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agent/:appSlug',
    loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, isAppSelected],
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
