import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';

import { AskQuestionLabels, QuestionDialog } from '../ask-question';

@Component({
  selector: 'pop-widget-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetQuestionDialogComponent implements OnInit {
  mainColor: string;
  labels: AskQuestionLabels;

  constructor(
    public dialogRef: MatDialogRef<WidgetQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionDialog,
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      return;
    }

    this.mainColor = this.data.mainColor;
    this.labels = this.data.labels;
  }

  close(): void {
    this.dialogRef.close();
  }
}
