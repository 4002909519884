<div
  *ngIf="isValid; else elseBlock"
  class="widget-shop-teaser"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.borderRadius.px]="attributes.style?.borderRadius"
  (click)="navigate()"
>
  <div class="content">
    <h3 class="content_title">{{ attributes.title }}</h3>
    <h4 class="content_subtitle">{{ attributes.subtitle }}</h4>
  </div>
  <ng-container [ngSwitch]="attributes.style.displayType">
    <div *ngSwitchCase="'style2'">
      <img *ngIf="attributes.icon" [src]="attributes.icon" alt="shop teaser icon" class="icon-v2" />
      <svg
        *ngIf="!attributes.icon"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 13.229 13.229"
      >
        <g transform="translate(-53.225 -223.864)">
          <circle [style.fill]="attributes.mainColor" r="6.615" cy="230.479" cx="59.84" fill-rule="evenodd" />
          <path
            d="M63.333 227.01c-.099 0-2.296.014-3.632 1.097-1.337-1.083-3.534-1.096-3.633-1.096h-.336v6.726h.336c1.755 0 2.854.33 3.28.488h.702c.42-.158 1.511-.488 3.283-.488h.336v-6.726z"
            fill="#fff"
            fill-opacity=".941"
          />
          <path [style.fill]="attributes.mainColor" d="M59.436 227.91h.53v6.35h-.53z" fill-rule="evenodd" />
        </g>
      </svg>
    </div>

    <div *ngSwitchDefault>
      <img *ngIf="attributes.icon" [src]="attributes.icon" alt="shop teaser icon" class="icon" />
      <svg
        *ngIf="!attributes.icon"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 13.229 13.229"
      >
        <g transform="translate(-53.225 -223.864)">
          <circle [style.fill]="attributes.mainColor" r="6.615" cy="230.479" cx="59.84" fill-rule="evenodd" />
          <path
            d="M63.333 227.01c-.099 0-2.296.014-3.632 1.097-1.337-1.083-3.534-1.096-3.633-1.096h-.336v6.726h.336c1.755 0 2.854.33 3.28.488h.702c.42-.158 1.511-.488 3.283-.488h.336v-6.726z"
            fill="#fff"
            fill-opacity=".941"
          />
          <path [style.fill]="attributes.mainColor" d="M59.436 227.91h.53v6.35h-.53z" fill-rule="evenodd" />
        </g>
      </svg>
    </div>
  </ng-container>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-shop-teaser">{{ noValidMessage }}</div>
</ng-template>
