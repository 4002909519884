import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { QrCodeDialogParams } from './qr-code';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QrCodeDialogComponent implements OnInit {
  title: string;
  url: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: QrCodeDialogParams) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.url = encodeURI(this.data.url);
  }
}
