<mat-card appearance="outlined">
  <ng-container *ngIf="intervals.length">
    <h4 class="title">{{ 'STUDIO.SETTINGS.MENU.WH' | translate }}</h4>
    <div class="wrapper">
      <ng-container *ngFor="let day of intervals">
        <div class="day">
          <h5 class="day__name">{{ weekDays[day.day] | translate }}</h5>
          <div class="day__intervals">
            <div *ngFor="let interval of day.values" class="interval">
              <span>{{ interval.from }}</span>
              -
              <span>{{ interval.to }};</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="custom">
    <h4 class="title">{{ 'STUDIO.SHARED.CUSTOM' | translate }}</h4>
    <div>
      <span class="day__interval">
        {{ custom.key }}:
        <span *ngFor="let value of custom.rules; let index = index">
          {{ value }}
          <span *ngIf="index < custom.rules.length - 1">,</span>
        </span>
      </span>
    </div>
  </ng-container>
</mat-card>
