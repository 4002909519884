import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '@bend/store';
import { CollectType } from '@bend/store-shared';

@Injectable()
export class CartCollectService {
  hasCollectPage: Observable<boolean>;

  constructor(private _settings: SettingsService) {
    this.hasCollectPage = this._hasCollectPage;
  }

  private get _hasCollectPage(): Observable<boolean> {
    const allowCollectStatus = new Set([CollectType.Delivery, CollectType.TakeAway]);

    /**
     * check if is delivery or take away
     * we show collect page for delivery or takeaway every time
     */
    return this._settings.collectTypes.pipe(
      map(collectTypes => collectTypes.some(collect => allowCollectStatus.has(collect))),
    );
  }
}
