import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '@bend/store';

@Injectable()
export class CartTipsService {
  constructor(private _settings: SettingsService) {}

  get hasTipsPage(): Observable<boolean> {
    return combineLatest([this._settings.allowTips, this._settings.allowSplitBill]).pipe(
      map(conditions => conditions.every(Boolean)),
    );
  }
}
