import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './pages.config';
import { adapter } from './pages.reducer';
import { State } from './pages.type';

const stateSelector = createFeatureSelector<State>(storeKey);
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);
export const error = createSelector(stateSelector, state => state.error);
export const selectNextPageId = createSelector(stateSelector, state => state.selectedNextPageId);
export const selectPageId = createSelector(stateSelector, state => state.selectedPageId);
export const page = createSelector(entities, selectPageId, (pages, pageId) => pages[pageId]);
export const widgets = createSelector(page, pageEntity => pageEntity.widgets);
export const isLoading = createSelector(
  stateSelector,
  selectNextPageId,
  (state, pageId) => state.isLoadingById[pageId],
);
export const isLoaded = createSelector(stateSelector, selectNextPageId, (state, pageId) => state.isLoadedById[pageId]);
