import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { IconModule } from '@bend/icon';

import { LanguageButtonComponent } from './language-button.component';

@NgModule({
  declarations: [LanguageButtonComponent],
  imports: [CommonModule, MatButtonModule, IconModule],
  exports: [LanguageButtonComponent],
})
export class LanguageButtonModule {}
