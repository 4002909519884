import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { WarningService, WarnTypeEnum } from '../warning.service';
import { WidgetComponent } from '../widget.component';
import { WidgetVideo } from './video';

@Component({
  selector: 'pop-widget-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class WidgetVideoComponent implements OnInit, WidgetComponent {
  static widgetName = 'video';
  @Input() attributes: WidgetVideo;

  isValid: boolean;
  noValidMessage: string;
  safeUrl: SafeResourceUrl;
  thumbnailUrl: string;

  constructor(private _sanitizer: DomSanitizer, private _warningService: WarningService) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.url) {
      this.noValidMessage = this._warningService.showWarn(WidgetVideoComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    if (this.attributes.isEditMode) {
      this.thumbnailUrl = this.convertYoutubeUrlToThumbnailUrl(this.attributes.url);
    } else {
      const embedUrl = this.convertYoutubeUrlToEmbedded(this.attributes.url);
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }

    this.isValid = true;
  }

  convertYoutubeUrlToEmbedded(inputUrl: string): string {
    const longPrefix = 'https://www.youtube.com/watch?v=';
    const shortPrefix = 'https://youtu.be/';
    const embedPrefix = 'https://www.youtube.com/embed/';

    let result = '';

    if (inputUrl.startsWith(longPrefix)) {
      result = inputUrl.replace(longPrefix, embedPrefix);
    } else if (inputUrl.startsWith(shortPrefix)) {
      result = inputUrl.replace(shortPrefix, embedPrefix);
    } else {
      result = inputUrl;
    }

    return result;
  }

  convertYoutubeUrlToThumbnailUrl(inputUrl: string): string {
    const longPrefix = 'https://www.youtube.com/watch?v=';
    const shortPrefix = 'https://youtu.be/';
    const thumbnailPrefix = 'https://i.ytimg.com/vi/';
    const thumbnailSuffix = '/hqdefault.jpg';

    let result = '';

    if (inputUrl.startsWith(longPrefix)) {
      result = inputUrl.replace(longPrefix, thumbnailPrefix);
    } else if (inputUrl.startsWith(shortPrefix)) {
      result = inputUrl.replace(shortPrefix, thumbnailPrefix);
    } else {
      result = inputUrl;
    }

    result = result.trim() + thumbnailSuffix;

    return result;
  }
}
