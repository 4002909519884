import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

type FormType = UntypedFormGroup | UntypedFormArray | UntypedFormControl | AbstractControl;

export const markNestedTouched = (form: FormType): void => {
  form.markAsTouched();
  form.updateValueAndValidity();

  if (form instanceof UntypedFormGroup) {
    Object.values(form.controls).forEach((control: FormType) => {
      markNestedTouched(control);
    });
  } else if (form instanceof UntypedFormArray) {
    form.controls.forEach((control: FormType) => {
      markNestedTouched(control);
    });
  }
};
