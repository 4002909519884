import { tap } from 'rxjs/operators';

import { EMPTY_FN } from './empty-fn.helper';

export interface FeathersError<T = any, K = any> {
  readonly name: string;
  readonly message: string;
  readonly code: number;
  readonly className: string;
  readonly errorCode: string;
  readonly data: T;
  readonly errors: K;
}

export const tapError = <T>(fn: (e: FeathersError<T>) => void) =>
  tap<T>({
    next: EMPTY_FN,
    error: fn,
  });
