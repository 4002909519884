import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EnvModule } from '@bend/env';

import { storeKey } from './smiles.config';
import { SmilesEffects } from './smiles.effects';
import { SmilesHttpService } from './smiles.http.service';
import { reducer } from './smiles.reducer';
import { SmilesService } from './smiles.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([SmilesEffects]), EnvModule],
  providers: [SmilesService, SmilesHttpService],
})
export class SmilesStoreModule {}
