import { Component, Input, OnInit } from '@angular/core';
import { WEEK_DAYS } from '@designer-shared/types';

import { ConditionTypes, CustomCondition, IntervalsOnDay, TimeCondition, TimeInterval } from '../types';

@Component({
  selector: 'app-conditional-info',
  templateUrl: './conditional-info.component.html',
  styleUrls: ['./conditional-info.component.scss'],
})
export class ConditionalInfoComponent implements OnInit {
  @Input() conditions: (CustomCondition | TimeCondition)[];

  intervals: IntervalsOnDay[];
  custom: CustomCondition;
  weekDays = WEEK_DAYS;

  constructor() {
    this.intervals = [];
  }

  ngOnInit(): void {
    const time = this.conditions.find(({ type }) => type === ConditionTypes.TIME) as TimeCondition;
    if (time) {
      time.rules.sort((a, b) => a.weekday - b.weekday);
      this.intervals = this._getIntervalsOnDays([], time.rules, 0);
    }
    this.custom = this.conditions.find(({ type }) => type === ConditionTypes.QUERY_PARAM) as CustomCondition;
  }

  private _getIntervalsOnDays(
    newIntervals: IntervalsOnDay[],
    oldIntervals: TimeInterval[],
    index: number,
  ): IntervalsOnDay[] {
    if (index === oldIntervals.length) {
      return newIntervals;
    }

    if (!newIntervals.find(item => item.day === oldIntervals[index].weekday)) {
      newIntervals.push({
        day: oldIntervals[index].weekday,
        values: this._generateDayIntervals([oldIntervals[index]], oldIntervals, index + 1),
      });
    }
    return this._getIntervalsOnDays(newIntervals, oldIntervals, index + 1);
  }

  private _generateDayIntervals(
    newIntervals: TimeInterval[],
    oldIntervals: TimeInterval[],
    index: number,
  ): TimeInterval[] {
    if (index === oldIntervals.length) {
      return newIntervals;
    }
    if (oldIntervals[index].weekday === newIntervals[0].weekday) {
      newIntervals.push(oldIntervals[index]);
    }

    return this._generateDayIntervals(newIntervals, oldIntervals, index + 1);
  }
}
