import { Margin } from '../../shared/interfaces';
import { Widget } from '../../shared/models';

export interface ImageWidget extends Widget {
  attributes: {
    url: string;
    alt: string;
    link: string;
    style: ImageStyle;
    openInNewTab: boolean;
    text: ImageText;
  };
}

interface ImageText {
  content: string;
  margin: number;
  verticalAlign: VerticalAlignType;
  backgroundColor: string;
}

export enum VerticalAlignType {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export interface ImageStyle {
  backgroundColor: string;
  borderRadius: number;
  width: string;
  height: string;
  margin: Margin;
}
