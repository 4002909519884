import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { SnackBarService } from '@designer-shared/services';
import { PopCatalog, SuccessCode } from '@designer-shared/types';

import { CatalogsService } from '../../../catalogs/services';
import { ImportService } from '../../services/index';

@Component({
  selector: 'app-catalogs-list',
  templateUrl: './catalogs-list.component.html',
  styleUrls: ['./catalogs-list.component.scss'],
})
export class CatalogsListComponent implements OnInit, OnDestroy {
  static created: Subject<PopCatalog> = new Subject();
  static edited: Subject<PopCatalog> = new Subject();
  static deleted: Subject<number> = new Subject();
  static updated: Subject<void> = new Subject();

  catalogs: PopCatalog[];
  currentCatalog: PopCatalog | any = {};
  appSlug: string;
  locations$: Observable<any[]>;

  private _subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private catalogsService: CatalogsService,
    private router: Router,
    private snackBarService: SnackBarService,
    private _import: ImportService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._subscription = new Subscription();

    this.appSlug = this.route.parent.snapshot.parent.parent.paramMap.get('appSlug');

    this.subscribeToEvents();
    this.resetCatalog();

    this.locations$ = this.catalogsService.getLocations();

    this._subscription.add(this._import.success.subscribe(() => this.resetCatalog()));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  subscribeToEvents(): void {
    this._subscription.add(
      CatalogsListComponent.created.subscribe((catalog: PopCatalog) => this.onAddCatalog(catalog)),
    );
    this._subscription.add(
      CatalogsListComponent.edited.subscribe((catalog: PopCatalog) => this.onEditCatalog(catalog)),
    );
    this._subscription.add(
      CatalogsListComponent.deleted.subscribe((catalogId: number) => this.onDeleteCatalog(catalogId)),
    );
    this._subscription.add(CatalogsListComponent.updated.subscribe(() => this.resetCatalog()));
  }

  selectCatalog(catalog: PopCatalog): void {
    this.currentCatalog = catalog;
    this.router.navigate(['dashboard', this.appSlug, 'catalogs', 'edit', catalog.id]);
  }

  resetCatalog(): void {
    this._subscription.add(
      this.catalogsService
        .getCatalogs()
        .pipe(
          switchMap(catalogs =>
            this.catalogsService.getLocations().pipe(
              map(locations => locations.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))),
              map(locations => {
                const sortedLocations = locations.map(({ id }) => id);

                return catalogs.sort((a, b) => {
                  if (!a.businessLocationId) return 0;

                  return sortedLocations.indexOf(a.businessLocationId) - sortedLocations.indexOf(b.businessLocationId);
                });
              }),
              tap(sortedCatalogs => (this.catalogs = sortedCatalogs)),
            ),
          ),
        )
        .subscribe(),
    );
  }

  newCatalog(): void {
    this.currentCatalog = {};
    this.router.navigate(['dashboard', this.appSlug, 'catalogs', 'new']);
  }

  importProductsFromCSV(): void {
    this.router.navigate(['dashboard', this.appSlug, 'catalogs', 'import-products']);
  }

  onAddCatalog(catalog: PopCatalog): void {
    this.catalogs.push(catalog);
    this.currentCatalog = catalog;
    this.router.navigate(['edit', catalog.id], { relativeTo: this.route });
    this.snackBarService.success(SuccessCode.ADDED);

    this.resetCatalog();
  }

  onEditCatalog(editedCatalog: PopCatalog): void {
    this.currentCatalog = editedCatalog;
    this.catalogs = this.catalogs.map(catalog => (catalog.id === editedCatalog.id ? editedCatalog : catalog));
    this.snackBarService.success(SuccessCode.UPDATED);
  }

  onDeleteCatalog(deletedCatalogId: number): void {
    this.catalogs = this.catalogs.filter(item => item.id !== deletedCatalogId);
    this.router.navigate(['.'], { relativeTo: this.route });
    this.snackBarService.success(SuccessCode.DELETED);
  }

  import(): void {
    this._router.navigate(['import-products-pos'], { relativeTo: this._route });
  }
}
