import { Widget } from '../../shared/models';

export interface ReviewWidget extends Widget {
  attributes: {
    labels: ReviewLabels;
  };
}

export enum ReviewLabelsValues {
  AVERAGE = 'REVIEW.AVERAGE',
  TITLE = 'REVIEW.TITLE',
}
export class ReviewLabels {
  AVERAGE = 'Average';
  TITLE = 'Title';
}

export interface ReviewLabel {
  name: string;
  key: string;
}
