import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './payment.config';
import { adapter } from './payment.reducer';
import { State } from './payment.type';

const stateSelector = createFeatureSelector<State>(storeKey);

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const all = createSelector(stateSelector, state => state);

export const entities = createSelector(stateSelector, selectEntities);
export const paymentConfigList = createSelector(stateSelector, selectAll);
export const ids = createSelector(stateSelector, selectIds);
export const isLoading = createSelector(stateSelector, paymentConfig => paymentConfig.isLoading);
export const currentAppSlug = createSelector(stateSelector, paymentConfig => paymentConfig.currentAppSlug);
export const isLoaded = createSelector(
  stateSelector,
  currentAppSlug,
  (paymentConfig, appSlug) => !!appSlug && paymentConfig.isLoadedByAppSlug === appSlug,
);
export const isLoadingById = createSelector(stateSelector, paymentConfig => paymentConfig.isLoadingById);
export const errorCode = createSelector(stateSelector, paymentConfig => paymentConfig.errorCode);
export const selectIsLoadedByIds = createSelector(stateSelector, paymentConfig => paymentConfig.isLoadedByIds);
export const isLoadedByIds = createSelector(stateSelector, isLoadingById, (paymentConfig, id) =>
  paymentConfig.isLoadedByIds.includes(id),
);
export const currentPaymentConfig = createSelector(entities, isLoadingById, (allConfigs, id) => allConfigs[id]);
