import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  ApiDesignerService,
  GenericNotification,
  NotificationEntity,
  NotificationSettings,
  NotificationSettingsLabels,
  NotificationsRoutes,
  TypeNotification,
} from '../shared';

@Injectable()
export class NotificationsHttpService {
  constructor(private _api: ApiDesignerService, private _http: HttpClient) {}

  updateNotification(notificationId: number, settings: GenericNotification): Observable<TypeNotification> {
    return this._api
      .notification('v1', notificationId)
      .pipe(switchMap(api => this._http.patch<TypeNotification>(api, settings)));
  }

  defaultSettings(): Observable<NotificationSettings> {
    return this._api.defaultSettings('v1').pipe(switchMap(api => this._http.get<NotificationSettings>(api)));
  }

  updateDefaults(settings: Partial<NotificationSettings>): Observable<NotificationSettingsLabels> {
    return this._api
      .defaultSettings('v1')
      .pipe(switchMap(api => this._http.patch<NotificationSettingsLabels>(api, settings)));
  }

  notificationByType(type: NotificationsRoutes): Observable<Omit<NotificationEntity, 'appSlug'>> {
    return this._api.notificationType('v1').pipe(
      switchMap(api =>
        this._http.get<Omit<NotificationEntity, 'appSlug'>>(api, {
          params: { type },
        }),
      ),
      map(notification => ({ type, ...notification })),
    );
  }

  addNotification(settings: Partial<GenericNotification>): Observable<TypeNotification> {
    return this._api.addNotification('v1').pipe(
      switchMap(api =>
        this._http.post<TypeNotification>(api, {
          ...settings,
        }),
      ),
    );
  }

  deleteNotification(id: number): Observable<GenericNotification> {
    return this._api.deleteNotification('v1', id).pipe(switchMap(api => this._http.delete<GenericNotification>(api)));
  }
}
