export interface Smiles {
  data: Smile[];
  total: number;
  limit: number;
  skip: number;
}

export interface Smile {
  id: string;
  placeId: number;
  name: string;
  placeName: string;
  batchName: string;
  url: string;
  nrSmilesNfc: number;
  nrSmilesQr: number;
  lastScan: string;
  isLoading?: boolean;
}

export interface GenerateSmiles {
  nrOfSmiles: number;
  batchName: string;
  startIndex: number;
}
