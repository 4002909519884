import { Order } from './order.type';

export interface OrderUpdatedApi extends Omit<OrderUpdated, 'updatedAt'> {
  updatedAt: string;
}

export interface OrderUpdated {
  id: Order['id'];
  updatedAt: Date;
}
