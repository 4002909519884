import { SendToKitchen } from './cart.type';
import { CollectType } from './collect.type';
import { PlaceType } from './place.type';

export interface SettingsBehavior {
  allowAddCustomAddresses: boolean;
  disableAditionsAfterOrder: boolean;
  displayNameAsOrderIdPrefix: boolean;
  isReadOnly: boolean;
  payOnly: boolean;
  allowUserCreateNewSesssion: boolean;
  multiKitchen: boolean;
  payBefore: boolean;
  noPayment: boolean;
  allowCashPayment: boolean;
  cashPaymentOnly: boolean;
  orderIdPrefix: string;
  showOrderPreparationTime: boolean;
  minOrderPreparationTime: number;
  minOrderDeliveryTime: number;
  showNrCutlery: boolean;
  showOrderDetails: boolean;
  allowSmsNotification: boolean;
  askGender: boolean;
  askName: boolean;
  askFirstName: boolean;
  askLastName: boolean;
  askDateOfBirth: boolean;
  askPhone: boolean;
  askBirthPlace: boolean;
  askEmail: boolean;
  askForPickupName: boolean;
  askForNrOfPeopleAtTable: boolean;
  askForShopIdentifier: boolean;
  saveCreditCard: boolean;
  syncPricesWithPos: boolean;
  isShared: boolean;
  allowOrderForOthers: boolean;
  autoClose: boolean;
  closeAfterFullyPayMin: number;
  closeAfterMin: number;
  allowPickup: boolean;
  collectTypes: CollectType[];
  placeType: PlaceType;
  isPaymentRequired: boolean;
  moveBehavior: MoveBehavior;
  tableAsName: boolean;
  allowCredit: boolean;
  allowSignIn: boolean;
  userSendToKitchen: SendToKitchen;
  showOrderHistory: boolean;
  changeItemsVisibilityOnNoStock: boolean;
  orderForAnotherDay: boolean;
  verifyTodayOrder: boolean;
  allowNewsletters: boolean;
  allowSplitBill: boolean;
  allowTips: boolean;
  resetSessionAfterSec: number;
  kioskMode: boolean;
  orderMinAmount: number;
}

export enum MoveBehavior {
  New = 'new',
  Deny = 'deny',
}
