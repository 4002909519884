import { SocketType } from '@bend/socket';
import { OrderUpdated } from '@bend/store-shared';

export interface SocketOrder extends OrderUpdated {
  type: SocketOrderType | SocketType;
}

export const enum SocketOrderType {
  OrderUpdated = 'order-updated',
  OrderClosed = 'order-closed',
  CreditRefunded = 'credit-refunded',
}
