<mat-expansion-panel class="expansion-panel">
  <mat-expansion-panel-header [expandedHeight]="'40px'">
    <mat-panel-title>
      <label for="searchPage" class="form-label">{{ 'WIDGETS.SHARED.INTERNAL_PAGE' | translate }}</label>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <input
    id="searchPage"
    type="text"
    matInput
    [formControl]="myControl"
    [matAutocomplete]="auto"
    appAutofocus
    class="form-input form-input--stretched"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="autocomplete-panel">
    <mat-option
      *ngFor="let page of filteredPages | async"
      [value]="page"
      (onSelectionChange)="changePage($event, page)"
    >
      {{ page.name }}
    </mat-option>
  </mat-autocomplete>
</mat-expansion-panel>
