<div class="tab">
  <ul class="tab__menu">
    <li *ngFor="let tab of tabs; index as i" class="tab__item">
      <button (click)="changeTab(i)" class="tab__button" [class.tab__button--active]="i === tabPosition">
        {{ tab | translate }}
      </button>
    </li>
  </ul>
</div>
<div [ngSwitch]="tabPosition" class="content">
  <ng-content *ngSwitchCase="0"></ng-content>
  <pop-widget-edit-conditional-tab *ngSwitchCase="1" [widget]="widget"></pop-widget-edit-conditional-tab>
</div>
<div class="form-action-btns">
  <button type="button" (click)="submit.emit()" class="form-action-btn app-button-color">
    <span>{{ 'STUDIO.SHARED.SAVE' | translate }}</span>
  </button>
  <button type="button" (click)="onSaveAndPublish.emit()" class="form-action-btn app-button-color">
    <span>{{ 'WIDGETS.SHARED.SAVE_PUBLISH' | translate }}</span>
  </button>
</div>
