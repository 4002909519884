import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { storeKey } from './image-gallery.config';
import { ImageGalleryEffects } from './image-gallery.effects';
import { ImageGalleryHttpService } from './image-gallery.http.service';
import { reducer } from './image-gallery.reducer';
import { ImageGalleryService } from './image-gallery.service';

@NgModule({
  imports: [StoreModule.forFeature(storeKey, reducer), EffectsModule.forFeature([ImageGalleryEffects])],
  providers: [ImageGalleryHttpService, ImageGalleryService],
})
export class ImageGalleryStoreModule {}
