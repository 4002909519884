import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Page } from '../interfaces';

@Component({
  selector: 'pop-widget-edit-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss'],
})
export class PageSelectorComponent implements OnInit {
  @Input() pages: Page[];
  @Input() selectedPageId: string;
  @Output() pageChangeEmmiter = new EventEmitter<Page>();

  myControl = new UntypedFormControl();
  filteredPages: Observable<Page[]>;

  constructor() {}

  ngOnInit(): void {
    if (this.pages) {
      this.filteredPages = this.myControl.valueChanges.pipe(
        startWith({} as any as Page),
        map(page => (page && typeof page === 'object' ? page.name : page)),
        map(name => (name ? this.filter(name) : this.pages.slice())),
      );
      if (this.selectedPageId) {
        this.myControl.setValue(this.displayPageNameFromId(this.selectedPageId));
      }
    }
  }

  displayFn(page: Page): string {
    return page ? page.name : '';
  }

  displayPageNameFromId(selectedPageId: string): Page {
    const linkArray = selectedPageId.split('/');
    const pageId = linkArray[linkArray.length - 1];
    const selectedPage = this.pages.find(page => page._id === pageId);
    return selectedPage;
  }

  filter(val: string): Page[] {
    return this.pages.filter(page => page.name.toLowerCase().indexOf(val.toLowerCase()) >= 0);
  }

  changePage(evt: MatOptionSelectionChange, page: Page): void {
    if (evt.source.selected) {
      this.pageChangeEmmiter.emit(page);
    }
  }
}
