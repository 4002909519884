<div *ngIf="isValid; else elseBlock" class="widget-share">
  <a class="share" [href]="meta">
    <svg
      width="30"
      height="30"
      viewBox="0 0 23 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.41"
    >
      <g transform="matrix(1,0,0,1,-5448.46,-1783.88)">
        <g transform="matrix(0.34972,0,0,0.34972,5442.05,1781.55)">
          <path
            d="M78.666,34L60.125,34C58.468,34 57.125,35.343 57.125,37C57.125,38.657 58.468,40 60.125,40L75.666,40L75.666,87.334L24.334,87.334L24.334,40L39.875,40C41.532,40 42.875,38.657 42.875,37C42.875,35.343 41.532,34 39.875,34L21.334,34C19.677,34 18.334,35.343 18.334,37L18.334,90.334C18.334,91.991 19.677,93.334 21.334,93.334L78.666,93.334C80.323,93.334 81.666,91.991 81.666,90.334L81.666,37C81.666,35.343 80.323,34 78.666,34ZM38.722,25.188L47,16.909L47,65.416C47,67.073 48.343,68.416 50,68.416C51.657,68.416 53,67.073 53,65.416L53,16.909L61.278,25.187C61.864,25.773 62.632,26.066 63.399,26.066C64.166,26.066 64.934,25.773 65.52,25.187C66.692,24.016 66.692,22.117 65.52,20.945L52.125,7.549C51.582,7.004 50.83,6.666 50,6.666C49.17,6.666 48.418,7.004 47.875,7.549L34.479,20.945C33.307,22.117 33.307,24.016 34.479,25.187C35.651,26.359 37.55,26.359 38.722,25.188Z"
            style="fill-rule: nonzero"
            [style.fill]="attributes.mainColor"
          />
        </g>
      </g>
    </svg>
  </a>
  <div class="share-text" [style.color]="attributes.mainColor">Share</div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-share">{{ noValidMessage }}</div>
</ng-template>
