import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';

import { EnvService } from '@bend/env';
import { ScriptLoaderService } from '@bend/script-loader';

import { MapConfig, Pin } from './map.type';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
  @Input() attributes: MapConfig;
  isMapConnected = false;

  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
  };

  @HostBinding('style.margin-inline')
  private get _fullWidth(): string {
    return this.attributes.style?.fullWidth ? '0' : '10px';
  }

  @HostBinding('style.height')
  private get _mapHeight(): string {
    return `${this.attributes.style?.height || 300}px`;
  }

  @HostBinding('style.margin-block-start')
  private get _marginTop(): string {
    return `${this.attributes.style?.margin?.top || 0}px`;
  }

  @HostBinding('style.margin-block-end')
  private get _marginBottom(): string {
    return `${this.attributes.style?.margin?.bottom || 0}px`;
  }

  @HostBinding('style.border-radius')
  private get _borderRadius(): string {
    return `${this.attributes.style?.borderRadius || 0}px`;
  }

  constructor(private _scriptLoader: ScriptLoaderService, private _env: EnvService, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._initGoogleMaps();
  }

  onMapInitialization(map: google.maps.Map): void {
    if (this.attributes.zoom?.fitBounds) {
      map.fitBounds(this._findCenterBounds(this.attributes.pins));
    } else {
      map.setZoom(this.attributes.zoom?.focus?.zoom);
      map.setCenter({ lat: this.attributes.zoom?.focus?.coords?.lat, lng: this.attributes.zoom?.focus?.coords?.lng });
    }
  }

  private _findCenterBounds(points: Pin[]): google.maps.LatLngBoundsLiteral {
    const coords = points.map(point => point.coords);
    const mostEast = coords.reduce((acc, v) => (acc.lng < v.lng ? v : acc));
    const mostWest = coords.reduce((acc, v) => (acc.lng > v.lng ? v : acc));
    const mostNorth = coords.reduce((acc, v) => (acc.lat < v.lat ? v : acc));
    const mostSouth = coords.reduce((acc, v) => (acc.lat > v.lat ? v : acc));

    return { east: mostEast.lng, west: mostWest.lng, north: mostNorth.lat, south: mostSouth.lat };
  }

  private _initGoogleMaps(): void {
    this._scriptLoader.load(`https://maps.googleapis.com/maps/api/js?key=${this._env.googleApiKey}`).then(() => {
      this.isMapConnected = true;
      this._cdr.detectChanges();
    });
  }
}
