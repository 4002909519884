export interface WidgetButtons {
  buttons: PopButton[];
  title?: string;
  mainColor: string;
  isEditMode: boolean;
  style: {
    button: WidgetButtonsStyleButton;
    margin: {
      top: number;
      bottom: number;
    };
    displayType: ButtonsDisplayType;
    showBackground: boolean;
  };
}

export class WidgetButtonsStyleButton {
  height = 'default';
  backgroundColor = '';
  borderColor = '';
  borderWidth = 0;
  borderRadius = 10;
  fontSize = 'default';
  textColor = '';
}

export interface PopButton {
  link: string;
  text?: string;
  icon?: string;
  openInNewTab: boolean;
}

export enum ButtonsDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
  STYLE3 = 'style3',
}
