import { NgModule } from '@angular/core';

import { AppsStoreModule } from '@designer-store/apps/apps.module';
import { UsersStoreModule } from '@designer-store/users/users.module';

import { DesignerPageStoreModule } from './page';
import { DesignerWidgetStoreModule } from './widget';

@NgModule({
  imports: [UsersStoreModule, AppsStoreModule, DesignerPageStoreModule, DesignerWidgetStoreModule],
})
export class DesignerStoreModule {}
