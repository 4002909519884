import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import { LoginResponse } from '@designer-shared';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_NAME_KEY = 'user_name';
const IS_SUPER_ADMIN = 'is_super_admin';

@Injectable({ providedIn: 'root' })
export class AuthService {
  redirectUrl = '/apps';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _api: ApiDesignerService,
  ) {}

  get token(): Observable<string> {
    return of(localStorage.getItem(ACCESS_TOKEN_KEY));
  }

  /* Because designer and visitor use same Auth interface */
  get socketToken(): Observable<string> {
    return this.token;
  }

  get refreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  get superAdmin(): boolean {
    return JSON.parse(localStorage.getItem(IS_SUPER_ADMIN));
  }

  setRefreshToken({ refreshToken }: LoginResponse): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  setAccessToken({ token }: LoginResponse): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  setSuperAdmin({ isSuperAdmin }: LoginResponse): void {
    localStorage.setItem(IS_SUPER_ADMIN, JSON.stringify(isSuperAdmin));
  }

  removeTokens(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  redirectToLogin(): void {
    this._router.navigate(['/login']);
  }

  getUserName(): string {
    return localStorage.getItem(USER_NAME_KEY);
  }

  getAccessTokenDeprecated(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this._api.login('v1').pipe(
      switchMap(api => this._http.post<LoginResponse>(api, { email, password })),
      tap(response => this.setRefreshToken(response)),
      tap(response => this.setAccessToken(response)),
      tap(response => this.setSuperAdmin(response)),
    );
  }

  extendToken(): Observable<string> {
    const refreshToken = this.refreshToken;
    return this._api.refreshToken('v1').pipe(
      switchMap(api => this._http.post<LoginResponse>(api, { refreshToken })),
      map(accessToken => {
        this.setAccessToken(accessToken);
        return accessToken.token;
      }),
    );
  }

  logout(): void {
    const refreshToken = this.refreshToken;
    this._api
      .logout('v1')
      .pipe(switchMap(api => this._http.post(api, { refreshToken })))
      .subscribe({
        next: () => {
          this.removeTokens();
          this.redirectToLogin();
        },
        error: () => {
          this.removeTokens();
          this.redirectToLogin();
        },
      });
  }

  changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<unknown> {
    return this._api
      .changePassword('v1')
      .pipe(switchMap(api => this._http.post<unknown>(api, { oldPassword, newPassword, confirmPassword })));
  }
}
