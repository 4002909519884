<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form_row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups">
          <div class="form-row-group form-row-group--distanced">
            <label for="title" class="form-label">{{ 'WIDGETS.ACTION_CALL.TITLE' | translate }}</label>
            <input
              id="title"
              type="text"
              class="form-input"
              formControlName="title"
              [class.show-error]="showErrors && title.invalid && title.errors.required"
            />
            <span *ngIf="showErrors && title.invalid && title.errors.required" class="form-error-msg">
              {{ 'WIDGETS.ACTION_CALL.ENTER_TITLE' | translate }}
            </span>
          </div>

          <div class="form-row-group form-row-group--distanced">
            <label for="popupHeader" class="form-label">
              {{ 'WIDGETS.ACTION_CALL.ADD_ACTION_BTN' | translate }}
              <input id="popupHeader" type="text" class="form-input" formControlName="popupHeader" />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row-groups">
      <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.ICON' | translate }}</span>
      <designer-image-gallery formControlName="icon"></designer-image-gallery>
      <span class="form-field-info">{{ 'WIDGETS.ACTION_CALL.DEFAULT_ICON' | translate }}</span>
    </div>

    <div class="form-fieldset-groups form-fieldset-groups-mt-20">
      <div class="form-fieldset-groups__caption">{{ 'WIDGETS.ACTION_CALL.ACTION_BUTTONS' | translate }}</div>
      <div class="form-fieldset-groups__content form-fieldset-groups__content--no-pb" formArrayName="actionButtons">
        <div class="form-fieldset-group" *ngFor="let actionButton of actionButtons; let i = index" [formGroupName]="i">
          <div class="form-group-margin-top form_row">
            <div class="form-row-groups form-row-groups-wrapper form-group--mt10">
              <div class="form-row-group form-row-group--distanced col-width">
                <label for="displayTypeSelect" class="form-label">
                  {{ 'WIDGETS.ACTION_CALL.TRANSMISSION_TYPE' | translate }}
                </label>
                <select
                  class="form-input form-input--select type title-input"
                  formControlName="transmitionType"
                  (change)="changeTransmitionType($event.target, i)"
                >
                  <option *ngFor="let transmitionType of transmitionTypes; let i = index" [value]="transmitionType">
                    {{ transmitionTypesDisplay[i] }}
                  </option>
                </select>
              </div>
              <div class="form-row-group form-row-group--distanced col-width">
                <label for="displayTypeSelect" class="form-label">
                  {{ 'WIDGETS.ACTION_CALL.ACTION_TYPE' | translate }}
                </label>
                <select
                  class="form-input form-input--select input-width"
                  formControlName="type"
                  (change)="changeActionType($event.target, i)"
                >
                  <option *ngFor="let actionType of actionTypes; let i = index" [value]="actionType">
                    {{ actionTypesDisplay[i] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <ng-container [ngSwitch]="actionButton.value.transmitionType">
            <div class="form-row-group form-row-group--distanced col-width">
              <label class="form-label">{{ 'WIDGETS.ACTION_CALL.BUTTON_TITLE' | translate }}</label>
              <input type="text" formControlName="title" class="title-input form-input input-width" />
              <span
                *ngIf="
                  getButtonStatus(i).touched &&
                  getButtonStatus(i).status === 'INVALID' &&
                  getButtonStatus(i).errors &&
                  getButtonStatus(i).errors.required
                "
                class="form-error-msg"
              >
                {{ 'WIDGETS.ACTION_CALL.ENTER_TITLE' | translate }}
              </span>
            </div>
            <pop-widget-edit-action-call-telephone-form
              *ngSwitchCase="'telephone'"
              [form]="form"
              [arrayItem]="i"
              [actionButtonArray]="'actionButtons'"
            ></pop-widget-edit-action-call-telephone-form>
            <pop-widget-edit-action-call-sms-form
              *ngSwitchCase="'sms'"
              [form]="form"
              [arrayItem]="i"
              [actionButtonArray]="'actionButtons'"
            ></pop-widget-edit-action-call-sms-form>
            <pop-widget-edit-action-call-email-form
              *ngSwitchCase="'email'"
              [form]="form"
              [arrayItem]="i"
              [actionButtonArray]="'actionButtons'"
            ></pop-widget-edit-action-call-email-form>
            <pop-widget-edit-action-call-pos-form
              *ngSwitchCase="'pos'"
              [form]="form"
              [arrayItem]="i"
              [actionButtonArray]="'actionButtons'"
            ></pop-widget-edit-action-call-pos-form>
            <pop-widget-edit-action-call-designer-form
              *ngSwitchCase="'designer'"
              [form]="form"
              [arrayItem]="i"
              [actionButtonArray]="'actionButtons'"
            ></pop-widget-edit-action-call-designer-form>
          </ng-container>

          <div class="form-row">
            <designer-image-gallery formControlName="icon"></designer-image-gallery>
          </div>

          <div class="form-row clearfix">
            <button class="btn-flat btn-flat--remove" type="button" (click)="deleteAction(i)">
              <span class="hyphen">-</span>
              {{ 'WIDGETS.ACTION_CALL.REMOVE_ACTION_BTN' | translate }}
            </button>
          </div>
        </div>
        <button type="button" class="btn-flat btn-flat--add-new-item" (click)="addAction()">
          {{ 'WIDGETS.ACTION_CALL.ADD_ACTION_BTN' | translate }}
        </button>
      </div>
    </div>

    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="displayTypeSelect" class="form-label">{{ 'WIDGETS.STYLE.DISPLAY_TYPE' | translate }}</label>
            <select class="form-input form-input--select" formControlName="displayType">
              <option *ngFor="let displayType of displayTypes" [value]="displayType">
                {{ displayType }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
