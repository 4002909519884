import { Widget } from '../../shared/models';

export interface ProductTeaserWidget extends Widget {
  attributes: {
    text: string;
    catalogId: number;
    imageUrl: string;
    productId: number;
    useProductFromUrl: boolean;
    showKitchenComment: boolean;
    isCommentToKitchenRequired: boolean;
    isReadOnly: boolean;
    style: {
      margin: {
        bottom: number;
        top: number;
      };
      fullWidth: number;
      backgroundColor: string;
      borderRadius: number;
      text: {
        margin: number;
        verticalAlign: VerticalAlignType;
        backgroundColor: string;
      };
    };
    labels: ProductTeaserLabels;
  };
}

export enum ProductTeaserLabelsValues {
  PLEASE_SELECT_THE_REQUIRED_OPTIONS = 'PRODUCT_CATALOG.PLEASE_SELECT_THE_REQUIRED_OPTIONS',
  COMMENT_TO_KITCHEN_REQUIRED = 'PRODUCT_CATALOG.COMMENT_TO_KITCHEN_REQUIRED',
  REQUIRED = 'PRODUCT_CATALOG.REQUIRED',
  ADD_COMMENT_TO_KITCHEN = 'PRODUCT_CATALOG.ADD_COMMENT_TO_KITCHEN',
  ADD_TO_CART = 'PRODUCT_CATALOG.ADD_TO_CART',
  YOUR_COMMENT_HERE = 'PRODUCT_CATALOG.YOUR_COMMENT_HERE',
  ITS_FOR = 'PRODUCT_CATALOG.ITS_FOR',
  ME = 'PRODUCT_CATALOG.ME',
  SHARE_WITH_TABLE = 'PRODUCT_CATALOG.SHARE_WITH_TABLE',
  YOUR_NAME = 'PRODUCT_CATALOG.YOUR_NAME',
  OTHERS_NAME = 'PRODUCT_CATALOG.OTHERS_NAME',
  OTHER_PEOPLE = 'PRODUCT_CATALOG.OTHER_PEOPLE',
  PLEASE_ENTER_THE_NAME = 'PRODUCT_CATALOG.PLEASE_ENTER_THE_NAME',
  SAVE = 'PRODUCT_CATALOG.SAVE',
  PRODUCT_ADDED = 'PRODUCT_CATALOG.PRODUCT_ADDED',
}

export class ProductTeaserLabels {
  PLEASE_SELECT_THE_REQUIRED_OPTIONS = 'Please select the required options';
  COMMENT_TO_KITCHEN_REQUIRED = 'Error comment to kitchen';
  REQUIRED = 'Required';
  ADD_COMMENT_TO_KITCHEN = 'Add comment to kitchen';
  ADD_TO_CART = 'Button add to cart';
  YOUR_COMMENT_HERE = 'Your comment here';
  ITS_FOR = "It's for?";
  ME = 'Me';
  SHARE_WITH_TABLE = 'Share with table';
  YOUR_NAME = 'Your name';
  OTHERS_NAME = 'Others name';
  OTHER_PEOPLE = 'Other people';
  PLEASE_ENTER_THE_NAME = 'Please enter the name';
  SAVE = 'Button save';
  PRODUCT_ADDED = 'Product added';
}

export interface ProductTeaserLabel {
  name: string;
  key: string;
}

export interface ProductTeaserLabelGroup {
  name: string;
  labels: ProductTeaserLabel[];
}

export enum VerticalAlignType {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}
