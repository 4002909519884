import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StorageService } from '@bend/storage';

import { ErrorHandlerService } from '../../shared/error-handler/error-handler.service';
import { PopCartVisitor, PopCreateVisitorDialogParams } from '../product-catalog';
import { WidgetProductCatalogService } from '../product-catalog.service';

@Component({
  selector: 'pop-product-catalog-create-visitor-dialog',
  templateUrl: './create-visitor-dialog.component.html',
  styleUrls: ['./create-visitor-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetProductCatalogCreateVisitorDialogComponent implements OnInit {
  name: UntypedFormControl;
  mainColor: string;
  secondColor: string;
  showError: boolean;
  isOtherVisitor: boolean;
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopCreateVisitorDialogParams,
    public dialogRef: MatDialogRef<WidgetProductCatalogCreateVisitorDialogComponent>,
    private _productCatalogService: WidgetProductCatalogService,
    private _errorHandlerService: ErrorHandlerService,
    private _localStorage: StorageService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.name = new UntypedFormControl('', Validators.required);
  }

  ngOnInit(): void {
    this.mainColor = this.data.attributes.mainColor;
    this.secondColor = this.data.attributes.secondColor;
    this.isOtherVisitor = this.data.isOtherVisitor;
    if (!this.isOtherVisitor) {
      const name = this._localStorage.userName;
      this.name.setValue(name);
    }
  }

  onSubmit(): void {
    if (this.isLoading) {
      return;
    }

    if (!this.name.valid) {
      this.showError = true;

      return;
    }

    this.isLoading = true;
    if (this.isOtherVisitor) {
      this._productCatalogService.createVisitor(this.name.value).subscribe({
        next: user => this._handleVisitorCreated(user),
        error: error => this._handleHttpError(error),
      });
    } else {
      this._localStorage.userName = this.name.value;
      this._productCatalogService.setMyName(this.name.value).subscribe({
        next: user => this._handleVisitorCreated(user),
        error: error => this._handleHttpError(error),
      });
    }
  }

  private _handleVisitorCreated(user: PopCartVisitor): void {
    this.dialogRef.close(user);
    this._cdr.detectChanges();
  }

  private _handleHttpError(error: HttpErrorResponse): void {
    this._errorHandlerService.handleHttpError(error);
    this.isLoading = false;
    this._cdr.detectChanges();
  }
}
