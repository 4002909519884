import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'interpolate' })
export class Interpolate implements PipeTransform {
  private _templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;

  transform(expr: string, params: { [key: string]: string }): string {
    return this._interpolate(expr, params);
  }

  private _interpolate(expr: string, params: { [key: string]: string }): string {
    return expr.replace(this._templateMatcher, (substring: string, b: string) => {
      return params[b] || substring;
    });
  }
}
