<ng-container *ngTemplateOutlet="isLoading ? preloader : content"></ng-container>

<ng-template #content>
  <ng-container *ngIf="images && images.length; else noImages">
    <div
      (click)="selectImage(img.id)"
      [class.active]="selectedImageId === img.id"
      class="grid-item"
      *ngFor="let img of images; trackBy: trackByFn"
    >
      <designer-image
        [date]="img.createdAt"
        [name]="img.fileName"
        [imageUrl]="img.domain + img.relativePath"
        width="200"
        height="200"
        [active]="selectedImageId === img.id"
        [isGridImage]="true"
        [isVideo]="isVideoFile(img.fileName)"
        (deleteClicked)="onDeleteClicked(img.id)"
      ></designer-image>
    </div>
  </ng-container>
</ng-template>

<ng-template #preloader>
  <designer-spinner class="preloader" [diameter]="30"></designer-spinner>
</ng-template>

<ng-template #noImages>
  <div class="no-images">
    <div class="no-images-content">
      <img [src]="NO_IMAGE" alt="No Images Icon" />
      <p>{{ 'STUDIO.IMAGE_GALLERY.NO_IMAGES' | translate }}</p>
    </div>
  </div>
</ng-template>
