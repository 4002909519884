import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  Addresses,
  ApiVisitorService,
  Colors,
  createForeground,
  DictionaryHappyHoursIntervals,
  HappyHoursInterval,
  Settings,
  WorkingHoursInterval,
} from '../shared';
import { HappyHours } from '../shared';
import { convertTimeStringToIsoString } from '../shared/helpers/convert-to-iso-format.helper';
import { SettingsDeprecated } from './settings-deprecated.type';

@Injectable()
export class SettingsHttpService {
  constructor(private _http: HttpClient, private _api: ApiVisitorService) {}

  get(place?: string): Observable<Settings> {
    return zip(
      this._api.settings('v1'),
      this._api.address('v1'),
      this._api.workingHours('v1'),
      place ? this._api.placeBySlug('v1', place) : of(null),
    ).pipe(
      switchMap(([apiSettings, apiAddress, apiWorkingHours, apiPlace]) =>
        zip(
          this._http.get<SettingsDeprecated>(apiSettings, { params: { ...(place && { place }) } }),
          this._http.get<Addresses>(apiAddress, { params: { ...(place && { place }) } }),
          this._http.get<WorkingHoursInterval<string>[]>(apiWorkingHours, { params: { ...(place && { place }) } }),
          apiPlace ? this._http.get<{ name: string; status: string }>(apiPlace) : of({ name: '', status: '' }),
        ),
      ),
      map(([settings, addresses, workingHoursIntervals, { name, status }]) =>
        this._transformOldDataInNewData(settings, addresses, workingHoursIntervals, name, status),
      ),
    );
  }

  private _transformOldDataInNewData(
    deprecatedSettings: SettingsDeprecated,
    addresses: Addresses,
    workingHoursIntervals: WorkingHoursInterval<string>[],
    placeName: string,
    placeStatus: string,
  ): Settings {
    const {
      settings,
      gdpr: deprecatedGdpr,
      colors: { primary, secondary },
      ...rest
    } = deprecatedSettings;
    const {
      cart,
      catalog,
      printer,
      gdpr,
      pwa,
      ui,
      analytics,
      currency: { code, symbol },
      workingHours,
      happyHours,
      signIn,
      newsletters,
      ...restSettings
    } = settings;
    const { trackingId: googleTrackId, hotjarId: hotjarTrackId } = analytics;
    const { labels: labelsPwa, ...restPwa } = pwa;
    const {
      labels,
      topBar: top_bar,
      bottomBar: { ...bottom_bar },
      styles,
      ...restUi
    } = ui;
    const {
      widgets: { product_teaser, product_catalog },
      ...restStyle
    } = styles;
    const colors: Colors = {
      primary,
      secondary,
      primaryForeground: createForeground(primary),
      secondaryForeground: createForeground(secondary),
    };

    return {
      ...rest,
      ...restSettings,
      placeName,
      placeStatus,
      printer,
      signIn,
      newsletters,
      colors,
      addresses,
      gdpr: { showGdpr: gdpr.showGdpr, url: gdpr.url },
      pwa: restPwa,
      analytics: {
        googleTrackId,
        hotjarTrackId,
      },
      ui: {
        ...restUi,
        styles: restStyle,
      },
      widgets: {
        catalog,
        product_teaser,
        product_catalog,
        top_bar,
        bottom_bar,
        cart: {
          ...cart,
          allowOrderForOthers: catalog.allowOrderForOthers,
        },
      },
      currency: {
        currencyCode: code,
        display: symbol,
      },
      happyHours: {
        ...happyHours,
        intervals: this._getHappyHoursIntervals(happyHours),
      },
      workingHours: {
        ...workingHours,
        intervals: workingHoursIntervals.map(({ open, close }) => ({ open: new Date(open), close: new Date(close) })),
      },
    };
  }

  private _getHappyHoursIntervals(happyHours: HappyHours<string, DictionaryHappyHoursIntervals>): HappyHoursInterval[] {
    const { intervals } = happyHours;

    const convertedInterval = convertTimeStringToIsoString(
      intervals[new Date().getDay()] as HappyHoursInterval<string>[],
    );

    return convertedInterval.map(({ open, close }: { open: string; close: string }) => ({
      open: new Date(open),
      close: new Date(close),
    }));
  }
}
