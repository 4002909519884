import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { WidgetBaseComponent } from '../../shared/components';
import { StaticImplements } from '../../shared/decorators';
import { WidgetType } from '../../shared/models';
import { SnackBarService, WidgetFormService } from '../../shared/services';
import { WidgetService } from '../widget.service';
import {
  PollLabel,
  PollLabels,
  PollLabelsNames,
  PollWidget,
  PollWidgetForm,
  Question,
  QuestionForm,
  Response,
} from './poll';

@Component({
  selector: 'pop-widget-edit-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
@StaticImplements<WidgetType>()
export class PopWidgetEditPollComponent extends WidgetBaseComponent<PollWidget> implements OnInit {
  static widgetName = 'poll';

  private readonly EMPTY_QUESTION: Question = { question: '', required: false, responses: [] };
  private readonly EMPTY_RESPONSE: Response = { text: '' };

  labels: PollLabel[];
  labelsNames = PollLabelsNames;

  declare form: PollWidgetForm;

  constructor(
    widgetService: WidgetService,
    snackbarService: SnackBarService,
    private _widgetFormSettingsChild: WidgetFormService,
    private fb: FormBuilder,
  ) {
    super(widgetService, _widgetFormSettingsChild, snackbarService);
  }

  ngOnInit(): void {
    this._addMissingAttributes(this.widget);
    this.labels = this.getLabels;

    this.form = this.initForm(this.widget);
  }

  removeResponse(questionIndex: number, responseIndex: number): void {
    const question = this.form.controls.questions.controls[questionIndex];

    question.controls.responses.removeAt(responseIndex);
  }

  removeQuestion(questionIndex: number): void {
    this.form.controls.questions.removeAt(questionIndex);
  }

  addResponse(questionIndex: number): void {
    const question = this.form.controls.questions.controls[questionIndex];

    question.controls.responses.push(this.initResponse(this.EMPTY_RESPONSE));
  }

  addQuestion(): void {
    this.form.controls.questions.push(this.initQuestion(this.EMPTY_QUESTION));
  }

  private initResponse(response: Response): FormGroup<{ text: FormControl<string>; _id?: FormControl<string> }> {
    return this.fb.group({
      ...(response._id ? { _id: response._id } : {}),
      text: [response.text, Validators.required],
    });
  }

  private initQuestion(questionObject: Question): QuestionForm {
    const { question, required, responses } = questionObject;

    return this.fb.group({
      ...(questionObject._id ? { _id: this.fb.control(questionObject._id) } : {}),
      question: this.fb.control(question, Validators.required),
      required: this.fb.control(required),
      responses: this.fb.array(
        responses.map(res => this.initResponse(res)),
        Validators.required,
      ),
    });
  }

  protected isShowErrorForResponse(questionIndex: number, responseIndex: number): boolean {
    const c = this.getResponseTextControl(questionIndex, responseIndex);

    return c.invalid && (c.dirty || c.touched) && c.errors?.required;
  }

  protected get questionGroups(): QuestionForm[] {
    return this.form.controls.questions.controls;
  }

  protected responsesControls(questionIndex: number): QuestionForm['controls']['responses']['controls'] {
    return this.form.controls.questions.controls[questionIndex].controls.responses.controls;
  }

  private getResponseTextControl(questionIndex: number, responseIndex: number): FormControl<string> {
    return this.form.controls.questions.controls[questionIndex].controls.responses.controls[responseIndex].controls
      .text;
  }

  private getLabelGroup(pollLabels: PollLabel[], widgetLabels: PollWidget['attributes']['labels']): PollLabels {
    const group = {};

    pollLabels.forEach(label => {
      group[label.key] = [widgetLabels[label.key]];
    });

    return group as PollLabels;
  }

  private get getLabels(): PollLabel[] {
    const askQuestionLabels = new PollLabels();

    return Object.keys(askQuestionLabels).map(key => {
      return {
        key,
        label: askQuestionLabels[key],
      };
    });
  }

  private _addMissingAttributes(widget: PollWidget): void {
    if (!widget.attributes.labels) {
      widget.attributes.labels = new PollLabels();
    }
  }

  private initForm(widget: PollWidget): PollWidgetForm {
    const labelGroup = this.getLabelGroup(this.labels, this.widget.attributes.labels);

    const questionsFormArray: FormArray<QuestionForm> = this.fb.array(
      widget.attributes.questions.map(question => this.initQuestion(question)),
    );

    const pollWidgetForm = this.fb.group({
      questions: questionsFormArray,
      labels: this.fb.group(labelGroup),
      saveContact: this.fb.control(widget.attributes.saveContact),
      showResultsToUser: this.fb.control(widget.attributes.showResultsToUser),
    });

    this._widgetFormSettingsChild.initAttributes(pollWidgetForm);

    return this._widgetFormSettingsChild.getAttributes as PollWidgetForm;
  }
}
