import { createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { OrderItem,sortByKey } from '../shared';
import { actions } from './order-items.actions';
import { State } from './order-items.type';

export const adapter = createEntityAdapter<OrderItem>({
  sortComparer: sortByKey<OrderItem>()('id'),
  selectId: (item: OrderItem) => `${item.id}/${item.orderUserId}`,
});

export const initialState: State = adapter.getInitialState({});

const orderItemsReducer = createReducer(
  initialState,
  on(actions.addOrderItems, (state, { orderItems }) => adapter.addMany(orderItems, state)),
  on(actions.upsertOrderItems, (state, { orderItems }) => adapter.upsertMany(orderItems, state)),
  on(actions.updateOrderItems, (state, { orderItems }) => adapter.setAll(orderItems, state)),
  on(actions.updateOrderItem, (state, { orderItem }) => adapter.updateOne(orderItem, state)),
  on(actions.removeOrderItem, (state, { orderItemId, orderUserId }) =>
    adapter.removeOne(`${orderItemId}-${orderUserId}`, state),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return orderItemsReducer(state, action);
}

export function resetReducer(metaReducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === actions.reset.type) {
      return metaReducer(undefined, action);
    }

    return metaReducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetReducer];
