import { CountriesCodes } from '@bend/store-shared';
export interface Country {
  name: string;
  code: string;
}

export const countriesConfig: Country[] = [
  { code: CountriesCodes.FR, name: 'France' },
  { code: CountriesCodes.DE, name: 'Deutschland' },
  { code: CountriesCodes.DK, name: 'Dänemark' },
];
