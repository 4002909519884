import { OrderMenuItemApi } from './order-menu-item.type';

export interface ItemMetaPromoCode {
  name: string;
  minOrderAmount: number;
  amountType: string;
  phId: number;
  pId: number;
  promoStatus: string;
  promoAmount: number;
  promoType: string;
  errorCode: string;
}

export interface ItemMeta {
  id: number;
  sku: string;
  priceFinalValue: number;
  name: string;
  quantityMultiplier: number;
  productionOrder: number;
  options?: OrderItemOptionApi[];
  items?: OrderMenuItemApi[];
  option: {
    name: string;
  };
}

export interface OrderItem<T = OrderItemType> {
  id: number;
  orderId: number;
  status: OrderItemStatus;
  sku: string;
  type: T;
  quantity: number;
  price: number;
  comment: string;
  orderUserId: number;
  orderLocationId: number;
  itemMeta: T extends OrderItemType.Promo ? ItemMetaPromoCode : ItemMeta;
  updatedAt: Date;
  errorCode: ItemError;
  creationMode: OrderItemCreationMode;
  wasSentToPos: boolean;
  wasRemovedFromPos: boolean;
  discountedAmount: number;
}

export interface OrderItemApi<T = OrderItemType>
  extends Omit<OrderItem<T>, 'orderUserId' | 'orderLocationId' | 'updatedAt'> {
  updatedAt: string;
}

export const enum OrderItemType {
  Single = 'single',
  Promo = 'promo',
  Tips = 'tips',
  DeliveryFee = 'delivery_fee',
}

export const enum OrderItemStatus {
  Empty = 'empty',
  New = 'new',
  OrderedInProgress = 'ordered_in_progress',
  ToBePrepared = 'to_be_prepared',
  Ordered = 'ordered',
  OrderNotSent = 'order_not_sent',
  NextForPreparing = 'next_for_preparing',
  Preparing = 'preparing',
  ScheduledForPreparing = 'scheduled_for_preparing',
  WaitingForPayment = 'waiting_for_payment',
  Paid = 'paid',
  PaymentInProgress = 'payment_in_progress',
  PaymentNotSent = 'payment_not_sent',
  FailedPreCheck = 'failed_pre_check',
  Reimbursed = 'reimbursed',
  PickUpReady = 'pick_up_ready',
  InDelivery = 'in_delivery',
  Closed = 'closed',
  NoStock = 'no_stock',
  Handed = 'handed',
  UnknownProduct = 'unknown_product',
  TemporarilyUnavailable = 'temporarily_unavailable',
}

export interface OrderItemOption {
  skyOptionGroup?: string;
  sku: string;
  id?: number;
  name: string;
  priceFinalValue: number;
  quantity: number;
  status?: OrderItemStatus;
  orderId: number;
  orderUserId: number;
  parentId: string;
  order?: number;
  errorCode?: ItemError;
}

export type OrderItemOptionApi = Omit<OrderItemOption, 'parentIds'>;

export const enum ItemError {
  NoStock = 'POS_NO_STOCK',
  GenericError = 'POS_GENERIC_ERROR',
  NotificationTimeout = 'POS_NOTIFICATION_TIMEOUT',
  RestaurantClose = 'POS_RESTAURANT_CLOSE',
  UnknownProducts = 'POS_UNKNOWN_PRODUCTS',
  PosOrderRejected = 'POS_ORDER_REJECTED_ERROR',
  TemporarilyUnavailable = 'TEMPORARILY_UNAVAILABLE',
}

export const enum OrderItemCreationMode {
  Server = 'server',
  Cashpad = 'cashpad',
  Ikentoo = 'ikentoo',
}
