<h2
  [ngClass]="{
    'title': type === 'title',
    'subtitle': type === 'subtitle',
    'title-divider': divider && type === 'title',
    'subtitle-divider': divider && type === 'subtitle'
  }"
>
  <ng-content></ng-content>
</h2>
