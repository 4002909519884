<div [formGroup]="form">
  <div formArrayName="{{ actionButtonArray }}" class="form-group-margin-top form_row">
    <div [formGroupName]="arrayItem" class="form-row-groups form-row-groups-wrapper">
      <div class="form-row-group form-row-group--distanced col-width">
        <label class="form-label phone-label">{{ 'WIDGETS.ACTION_CALL.CONFIRMATION_MESSAGE' | translate }}</label>
        <input type="text" formControlName="actionMessage" class="title-input form-input input-width" />
      </div>
      <div class="form-row-group form-row-group--distanced col-width">
        <label
          class="form-label phone-label"
          mat-raised-button
          [matTooltip]="'WIDGETS.ACTION_CALL.TOOLTIP_SERVER' | translate"
        >
          {{ 'WIDGETS.ACTION_CALL.SERVER_MESSAGE' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              class="svg-main-color-fill"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            />
          </svg>
        </label>
        <input type="text" formControlName="description" class="title-input form-input input-width" />
      </div>
      <div class="form-row-group form-row-group--distanced col-width">
        <label class="form-label phone-label">{{ 'WIDGETS.ACTION_CALL.EMAIL_TO' | translate }}</label>
        <input type="email" formControlName="email" class="title-input form-input input-width" />
      </div>
      <div class="col-width form-row-group form-row-group--distanced">
        <label class="form-label phone-label">{{ 'WIDGETS.ACTION_CALL.EMAIL_FROM' | translate }}</label>
        <input type="text" formControlName="from" class="title-input form-input input-width" />
      </div>
      <div class="col-width form-row-group form-row-group--distanced">
        <label
          class="form-label phone-label"
          mat-raised-button
          [matTooltip]="'WIDGETS.ACTION_CALL.TOOLTIP_ADD_TABLE' | translate"
        >
          {{ 'WIDGETS.ACTION_CALL.SUBJECT' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              class="svg-main-color-fill"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            />
          </svg>
        </label>
        <input type="text" formControlName="subject" class="title-input form-input input-width" />
      </div>
      <div class="form-row-group form-row-group--distanced col-width">
        <label
          class="form-label phone-label"
          mat-raised-button
          [matTooltip]="'WIDGETS.ACTION_CALL.TOOLTIP_ADD_TABLE' | translate"
        >
          {{ 'WIDGETS.ACTION_CALL.BODY' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              class="svg-main-color-fill"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            />
          </svg>
        </label>
        <input type="text" formControlName="body" class="title-input form-input input-width" />
      </div>
      <div
        class="form-row-group form-row-group--distanced col-width"
        mat-raised-button
        [matTooltip]="'WIDGETS.ACTION_CALL.TOOLTIP_TABLE_END' | translate"
      >
        <input type="checkbox" formControlName="addTableName" class="form-input-checkbox checkbox--m" />
        <label class="form-label form-label--checkbox">
          {{ 'WIDGETS.ACTION_CALL.ADD_TABLE' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              class="svg-main-color-fill"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            />
          </svg>
        </label>
      </div>
      <div
        class="form-row-group form-row-group--distanced col-width"
        mat-raised-button
        [matTooltip]="'WIDGETS.ACTION_CALL.TOOLTIP_DIRECT_MESSAGE' | translate"
      >
        <input type="checkbox" checked formControlName="directMessage" class="form-input-checkbox checkbox--m" />
        <label class="form-label form-label--checkbox">
          {{ 'WIDGETS.ACTION_CALL.DIRECT_MESSAGE' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              class="svg-main-color-fill"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            />
          </svg>
        </label>
      </div>
    </div>
  </div>
</div>
