import { PaymentMethods } from '.';
import { PaymentMethod } from './payment.type';
import { WorkingHoursInterval } from './working-hours.type';

export interface Cart {
  paymentMethods: DictionaryPaymentMethods;
  credit?: CartCredit;
  workingHours?: WorkingHoursInterval[];
}

export interface DictionaryPaymentMethods {
  [PaymentMethods.CreditCard]?: { provider: string; savedCards: PaymentMethod[] };
  [PaymentMethods.RestaurantTicket]?: PaymentMethod;
  [PaymentMethods.Swile]?: PaymentMethod;
  [PaymentMethods.GooglePay]?: PaymentMethod;
  [PaymentMethods.ApplePay]?: PaymentMethod;
}

export interface CartCredit {
  selected: boolean;
  value: number;
}

export interface CreditResponse {
  credit: number;
}

export interface SettingsCart {
  confirmPayment: boolean;
  confirmPaymentAsAPopup: boolean;
  recommendations: RecommendationsCartSettings;
  redirectUrl: string;
}

export const enum SendToKitchen {
  Deny = 'deny',
  AllowNow = 'allow_now',
  AllowLater = 'allow_later',
}

export interface CreditHistory {
  amount: number;
  createdAt: Date;
  paymentMethod: string;
  reason: {
    type: string;
    data: Record<string, any>;
  };
}

export interface RecommendationsCartSettings {
  enabled: boolean;
  groups: number[];
}
