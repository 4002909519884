import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OrderItemOption } from '@bend/store-shared';

@Component({
  selector: 'lib-board-order-item-option [option]',
  templateUrl: './board-order-item-option.component.html',
  styleUrls: ['./board-order-item-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrderItemOptionComponent {
  @Input() option: OrderItemOption;
}
