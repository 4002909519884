<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form_row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced">
            <label for="title" class="form-label">
              {{ 'WIDGETS.SHARED.TITLE' | translate }}
              <input id="title" type="text" class="form-input" formControlName="title" />
            </label>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="title" class="form-label">
              {{ 'WIDGETS.SHARED.SUBTITLE' | translate }}
              <input id="subtitle" type="text" class="form-input" formControlName="subtitle" />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.IMAGE' | translate }}</span>
      <designer-image-gallery formControlName="icon"></designer-image-gallery>
      <span class="form-field-info">{{ 'WIDGETS.SHOP-TEASER.DEFAULT_ICON' | translate }}</span>
    </div>
    <div class="form-row">
      <div class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced form-row-groups--overflow-visible">
          <div class="form-row-group form-row-group--distanced">
            <label for="linkInput" class="form-label">{{ 'WIDGETS.SHARED.LINK' | translate }}</label>
            <span
              *ngIf="link.invalid && (((link.dirty || link.touched) && link.errors.required) || showErrors)"
              class="form-error-msg"
            >
              {{ 'WIDGETS.SHARED.LINK_ENTER' | translate }}
            </span>
            <input
              id="linkInput"
              class="form-input form-input--url"
              type="url"
              placeholder="URL"
              formControlName="link"
              (keyup)="onChangeLink()"
            />
            <pop-widget-edit-page-selector
              *ngIf="pages"
              (pageChangeEmmiter)="onPageChange($event)"
              [selectedPageId]="link.value"
              [pages]="pages"
            ></pop-widget-edit-page-selector>
            <div class="final-url">
              <span class="final-url__label">{{ 'WIDGETS.SHARED.FINAL_URL' | translate }}:</span>
              <span class="final-url__value">{{ finalUrl }}</span>
            </div>
            <span class="form-field-info">{{ 'WIDGETS.SHARED.LINK_INFO' | translate }}</span>
          </div>
          <div class="form-row-group form-row-group--distanced form-row-group--align-center">
            <input id="openInNewTabInput" class="form-input-checkbox" type="checkbox" formControlName="openInNewTab" />
            <label for="openInNewTabInput" class="form-label form-label--checkbox">
              {{ 'WIDGETS.SHARED.EXTERNAL_TAB' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <div class="form-row-groups form-row-groups--distanced">
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
            <input
              id="marginTopInput"
              type="number"
              formControlName="top"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced" formGroupName="margin">
            <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
            <input
              id="marginBottomInput"
              type="number"
              formControlName="bottom"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_RADIUS' | translate }}</label>
            <input
              id="borderRadiusInput"
              type="number"
              formControlName="borderRadius"
              min="0"
              class="form-input form-input--number"
            />
            <span class="form-input-number-units">px</span>
          </div>
          <div class="form-row-group form-row-group--distanced">
            <label for="displayTypeSelect" class="form-label">{{ 'WIDGETS.STYLE.DISPLAY_TYPE' | translate }}</label>
            <select class="form-input form-input--select" formControlName="displayType">
              <option *ngFor="let displayType of displayTypes" [value]="displayType">
                {{ displayType }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
