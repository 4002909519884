import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService, User } from '../shared';
import { GetUsers, GetUsersSuccess } from './users.type';

@Injectable()
export class UsersHttpService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getUsers({ limit, offset, onlyPendingTickets, search }: GetUsers): Observable<GetUsersSuccess> {
    return this._api.users('v1').pipe(
      switchMap(api =>
        this._http.get<GetUsersSuccess>(api, {
          params: {
            onlyPendingTickets: onlyPendingTickets.toString(),
            limit: limit.toString(),
            offset: offset.toString(),
            ...(search && { search }),
          },
        }),
      ),
    );
  }

  getUserInfoById(userId: User['id']): Observable<User> {
    return this._api.userInfo('v1', userId.toString()).pipe(switchMap(api => this._http.get<User>(api)));
  }

  updateUserInfo(userId: number, user: Partial<User>): Observable<User> {
    return this._api.userInfo('v1', userId.toString()).pipe(switchMap(api => this._http.patch<User>(api, user)));
  }

  addCredit(userId: User['id'], amount: number, reason: string, paymentMethod: string): Observable<{ credit: number }> {
    return this._api.addCredit('v1', userId.toString()).pipe(
      switchMap(api =>
        this._http.patch<{ credit: number }>(api, {
          amount,
          reason,
          paymentMethod,
        }),
      ),
    );
  }
}
