import { Widget } from '../../shared/models';

export enum ContactLabels {
  CONTACT_BTN = 'Button contact me',
  TITLE = 'Title',
  NAME = 'Name',
  EMAIL = 'Email',
  PHONE = 'Phone',
  SUCCESS_MESSAGE = 'Success message',
  SUBMIT = 'Button Send',
  NAME_REQUIRED = 'Error name required',
  EMAIL_INVALID = 'Error email invalid',
}

export enum ContactLabelsNames {
  CONTACT_BTN = 'CONTACT.CONTACT_BTN',
  TITLE = 'CONTACT.TITLE',
  NAME = 'CONTACT.NAME',
  EMAIL = 'CONTACT.EMAIL',
  PHONE = 'CONTACT.PHONE',
  SUCCESS_MESSAGE = 'CONTACT.SUCCESS_MESSAGE',
  SUBMIT = 'CONTACT.SUBMIT',
  NAME_REQUIRED = 'CONTACT.NAME_REQUIRED',
  EMAIL_INVALID = 'CONTACT.EMAIL_INVALID',
}

export interface ContactWidget extends Widget {
  attributes: {
    imageUrl: string;
    alt: string;
    context: string;
    labels: typeof ContactLabels;
  };
}

export interface ContactLabel {
  name: string;
  key: string;
}
