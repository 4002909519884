import { Inject, Injectable, Optional } from '@angular/core';
import { PLACE } from '@core/tokens';
import { distinctUntilChanged, filter, map, Observable, switchMap } from 'rxjs';

import { SocketOrder } from '@bend/shared-widgets/src/lib/types';
import { SocketService } from '@bend/socket';
import { ParamsService } from '@bend/store/src/lib/params';

enum PlaceUpdateEvents {
  SETTINGS_UPDATE = 'settings_update',
  TABLE_UPDATE = 'table_update',
}

type PlaceUpdateData =
  | {
      event: PlaceUpdateEvents.SETTINGS_UPDATE;
    }
  | {
      event: PlaceUpdateEvents.TABLE_UPDATE;
      isOpen: boolean;
    };

enum PageUpdateEvents {
  CATALOG_UPDATE = 'catalog_update',
  WIDGET_UPDATE = 'widget_update',
}

type PageUpdateData =
  | {
      event: PageUpdateEvents.CATALOG_UPDATE;
      catalogId: number;
    }
  | {
      event: PageUpdateEvents.WIDGET_UPDATE;
      widgetId?: string;
    };

@Injectable()
export class UserSocketUpdatesService {
  private placeSlug: string;

  constructor(
    private _params: ParamsService,
    private _socket: SocketService,
    @Optional() @Inject(PLACE) place: string,
  ) {
    this.placeSlug = place;
  }

  private page(): Observable<PageUpdateData> {
    return this._params.pageIdChanges.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      switchMap(pageId => {
        return this._socket.connect('page', pageId).message<PageUpdateData>();
      }),
    );
  }

  catalog(): Observable<{ event: PageUpdateEvents.CATALOG_UPDATE; catalogId: number }> {
    return this.page().pipe(
      filter(data => data.event === PageUpdateEvents.CATALOG_UPDATE),
      map(data => data as { event: PageUpdateEvents.CATALOG_UPDATE; catalogId: number }),
    );
  }

  widget(): Observable<PageUpdateData> {
    return this.page().pipe(filter(data => data.event === PageUpdateEvents.WIDGET_UPDATE));
  }

  private place(): Observable<PlaceUpdateData> {
    return this._socket.connect('place', this.placeSlug).message<PlaceUpdateData>();
  }

  settings(): Observable<PlaceUpdateData> {
    return this.place().pipe(filter(data => data.event === PlaceUpdateEvents.SETTINGS_UPDATE));
  }

  table(): Observable<Extract<PlaceUpdateData, { event: PlaceUpdateEvents.TABLE_UPDATE }>> {
    return this.place().pipe(filter(data => data.event === PlaceUpdateEvents.TABLE_UPDATE)) as Observable<
      Extract<PlaceUpdateData, { event: PlaceUpdateEvents.TABLE_UPDATE }>
    >;
  }

  order(): Observable<SocketOrder> {
    return this._socket.connect('order').message<SocketOrder>();
  }

  orderClose(): void {
    this._socket.close('order/');
  }
}
