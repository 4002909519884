import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { PrinterStatus } from '../types';
declare let epson: any;

@Injectable()
export class PrinterService {
  private _printer: any;
  private _ePosDev: any;
  private _retryCount = 0;
  private _isConnecting: boolean;
  private _changeStatusSubject = new Subject<PrinterStatus>();
  private _MAX_RETRY_COUNT = 5;

  onStatusChanged(): Observable<PrinterStatus> {
    return this._changeStatusSubject.asObservable();
  }

  connect(address: string, port: string, printerName: string): void {
    if (!address || !port || !printerName) {
      this._changeStatusSubject.next(PrinterStatus.ERROR);
      return;
    }
    if (this._ePosDev && this._ePosDev.isConnected()) {
      this._changeStatusSubject.next(PrinterStatus.CONNECTED);
      return;
    }
    if (this._isConnecting) {
      this._changeStatusSubject.next(PrinterStatus.CONNECTING);
      return;
    }

    this._changeStatusSubject.next(PrinterStatus.CONNECTING);
    this._isConnecting = true;

    this._ePosDev = null;
    this._ePosDev = new epson.ePOSDevice();
    this._ePosDev.ondisconnect = this._onDisconnect(this._changeStatusSubject);

    this._ePosDev.connect(address, port, data => this._onPrinterConnected(data, printerName, address, port));
  }

  printReceipt(data: any, copiesToPrint: number): void {
    if (!this._ePosDev || !this._ePosDev.isConnected() || !this._printer) {
      this._changeStatusSubject.next(PrinterStatus.DISCONNECTED);
      return;
    }

    for (let i = 0; i < copiesToPrint; i++) {
      data.forEach(command => {
        if (!Array.isArray(command.args)) {
          this._printer[command.fn](command.args);
        } else if (command.args.length > 0) {
          this._printer[command.fn](...command.args);
        } else {
          this._printer[command.fn]();
        }
      });

      this._printer.addFeed();
      this._printer.addCut(this._printer.CUT_FEED);
      this._printer.send();
    }
  }

  forceReconnection(address: string, port: string, printerName: string): string {
    if (this._isConnecting) {
      return;
    }
    this._changeStatusSubject.next(PrinterStatus.CONNECTING);
    this._isConnecting = true;
    this._ePosDev.connect(address, port, data => this._onPrinterConnected(data, printerName, address, port));
  }

  checkConnection(): boolean {
    return this._ePosDev ? this._ePosDev.isConnected() : false;
  }

  disconnect(): void {
    if (!this._ePosDev || !this._ePosDev.isConnected()) {
      return;
    }

    this._ePosDev.deleteDevice(this._printer, () => this._onDeviceDeleted());
  }

  private _onDisconnect(changeStatusSubject: Subject<PrinterStatus>): () => void {
    return function (): void {
      changeStatusSubject.next(PrinterStatus.DISCONNECTING);
    };
  }

  private _onPrinterConnected(data: string, printerName: string, address: string, port: string): void {
    this._retryCount++;
    if (data === 'OK' || data === 'SSL_CONNECT_OK') {
      this._isConnecting = false;
      this._retryCount = 0;
      this._ePosDev.createDevice(
        printerName,
        this._ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: false, buffer: false },
        (device, status) => this._onDeviceCreated(device, status),
      );
    } else {
      if (this._retryCount < this._MAX_RETRY_COUNT) {
        this._isConnecting = false;
        const that = this;
        setTimeout(() => {
          that.connect(address, port, printerName);
        }, 2000);
        this._changeStatusSubject.next(PrinterStatus.ERROR);
      } else {
        this._isConnecting = false;
        this._changeStatusSubject.next(PrinterStatus.DISCONNECTED);
      }
    }
  }

  private _onDeviceCreated(device: any, status: string): void {
    if (status !== 'OK') {
      this._changeStatusSubject.next(PrinterStatus.ERROR);
      return;
    }

    this._printer = device;
    this._printer.timeout = 60000;
    this._printer.addCut(this._printer.CUT_FEED);
    this._printer.send();
    this._changeStatusSubject.next(PrinterStatus.CONNECTED);
  }

  private _onDeviceDeleted(): void {
    this._ePosDev.disconnect();
    this._changeStatusSubject.next(PrinterStatus.DISCONNECTED);
  }
}
