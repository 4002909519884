export const WEEK_DAYS = [
  'STUDIO.WEEKDAYS.SUNDAY',
  'STUDIO.WEEKDAYS.MONDAY',
  'STUDIO.WEEKDAYS.TUESDAY',
  'STUDIO.WEEKDAYS.WEDNESDAY',
  'STUDIO.WEEKDAYS.THURSDAY',
  'STUDIO.WEEKDAYS.FRIDAY',
  'STUDIO.WEEKDAYS.SATURDAY',
];

export interface WeekDays {
  dayNr: number;
  dayName: string;
}
