import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiDesignerService } from '@bend/store-shared';

import { PopPage, PopWidget } from '../types';

@Injectable()
export class PageDesignerService {
  constructor(private _http: HttpClient, private _api: ApiDesignerService) {}

  getPage(pageId: string): Observable<PopPage> {
    return this._api.page('v1', pageId).pipe(switchMap(api => this._http.get<PopPage>(api)));
  }

  deleteWidget(pageId: string, widgetId: string): Observable<any> {
    return this._api.pageWidget('v1', pageId, widgetId).pipe(switchMap(api => this._http.delete(api)));
  }

  createWidget(pageId: string, widget: PopWidget): Observable<PopWidget> {
    return this._api.pageWidgets('v1', pageId).pipe(switchMap(api => this._http.post<PopWidget>(api, widget)));
  }

  publishWidget(pageId: string, widgetId: string, published: boolean): Observable<PopWidget> {
    return this._api
      .pageWidget('v1', pageId, widgetId)
      .pipe(switchMap(api => this._http.patch<PopWidget>(api, { published })));
  }

  saveWidgetOrder(pageId: string, widgets: PopWidget[]): Observable<any> {
    const body = widgets.map(widget => {
      return {
        _id: widget._id,
        order: widget.order,
      };
    });

    return this._api.widgetsOrder('v1', pageId).pipe(switchMap(api => this._http.patch(api, body)));
  }

  duplicatePage(name: string, pageId: string): Observable<any> {
    return this._api.duplicatePage('v1', pageId).pipe(switchMap(api => this._http.post<any>(api, { name })));
  }
}
