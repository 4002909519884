<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="nameInput" class="form-label">{{ 'WIDGETS.WI-FI.SSID' | translate }}</label>
      <div
        *ngIf="name.invalid && (((name.dirty || name.touched) && name.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.WI-FI.SSID_ENTER' | translate }}
      </div>
      <input id="nameInput" type="text" class="form-input" placeholder="SSID" formControlName="name" />
    </div>
    <div class="form-row">
      <label for="passInput" class="form-label">{{ 'WIDGETS.WI-FI.PASSWORD' | translate }}</label>
      <div
        *ngIf="pass.invalid && (((pass.dirty || pass.touched) && pass.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.WI-FI.PASSWORD_ENTER' | translate }}
      </div>
      <input
        id="passInput"
        type="text"
        class="form-input"
        [placeholder]="'WIDGETS.WI-FI.PASSWORD' | translate"
        formControlName="pass"
      />
    </div>
    <div class="form-row">
      <div class="form-row-groups">
        <div formGroupName="style">
          <span class="form-row-groups__caption">{{ 'WIDGETS.SHARED.ICON' | translate }}</span>
          <designer-image-gallery formControlName="iconUrl"></designer-image-gallery>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div formGroupName="style" class="form-row-groups-wrapper">
        <span class="form-fieldset-groups__caption">{{ 'WIDGETS.STYLE.STYLES' | translate }}</span>
        <div class="form-fieldset-groups__content">
          <div class="form-row-groups">
            <div class="form-row-group form-row-group--distanced" formGroupName="margin">
              <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
              <input
                id="marginTopInput"
                type="number"
                formControlName="top"
                min="0"
                class="form-input form-input--number"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced" formGroupName="margin">
              <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
              <input
                id="marginBottomInput"
                type="number"
                formControlName="bottom"
                min="0"
                class="form-input form-input--number"
              />
              <span class="form-input-number-units">px</span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="fontSizeInput" class="form-label">{{ 'WIDGETS.STYLE.FONT_SIZE' | translate }}</label>
              <input
                id="heightInput"
                type="text"
                formControlName="fontSize"
                matInput
                [matAutocomplete]="fontSizeAuto"
                class="form-input form-input--number"
              />
              <mat-autocomplete #fontSizeAuto="matAutocomplete">
                <mat-option [value]="'default'">{{ 'WIDGETS.STYLE.DEFAULT' | translate }}</mat-option>
              </mat-autocomplete>
              <span class="form-input-number-units">px</span>
              <div class="input-error" *ngIf="fontSize.invalid && fontSize.errors.fontSize">
                {{ 'WIDGETS.SHARED.INTEGER' | translate }}
              </div>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="backgroundColorInput" class="form-label">
                {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
              </label>
              <span
                id="backgroundColorInput"
                [(colorPicker)]="backgroundColorValue"
                [cpOutputFormat]="'rgba'"
                class="form-colorpicker"
                [style.backgroundColor]="backgroundColorValue"
              ></span>
            </div>
            <div class="form-row-group form-row-group--distanced">
              <label for="borderRadiusInput" class="form-label">{{ 'WIDGETS.STYLE.BORDER_SHOW' | translate }}</label>
              <input type="checkbox" id="borderRadiusInput" formControlName="showBorders" min="0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
