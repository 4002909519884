import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { SettingsService } from '@bend/store';

import { SelectItem } from '../../../../../../../shared-widgets/src/lib/types';
import { ModalService } from '../../services';

@Component({
  selector: 'app-button-modal-select',
  templateUrl: './button-modal-select.component.html',
  styleUrls: ['./button-modal-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonModalSelectComponent),
      multi: true,
    },
  ],
})
export class ButtonModalSelectComponent<T> implements ControlValueAccessor, OnChanges {
  @Input() options: SelectItem<any>[];
  @Input() placeHolder = '';
  @Input() fnCast: (value: any) => any;
  label: string;
  bg$: Observable<string>;

  private _value: T;
  private _index: number;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private _cdr: ChangeDetectorRef, private _settings: SettingsService, private _modal: ModalService) {
    this.fnCast = value => value.toString();
    this.bg$ = this._settings.colors.primary;
  }

  ngOnChanges(change: SimpleChanges): void {
    if (!change.options.firstChange) this.writeValue(this._value);
  }

  select(): void {
    this._modal
      .select<T>(this.options, this._index)
      .pipe(
        // TODO: remove any in generic
        filter<any>(Boolean),
        tap(({ index }) => (this._index = index)),
        tap(({ label }) => (this.label = label)),
        tap(({ value }) => (this.value = value)),
        tap(() => this._cdr.detectChanges()),
      )
      .subscribe();
  }

  set value(value: T) {
    if (this._value !== undefined && this.fnCast(this._value) === this.fnCast(value)) return;

    this._value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  writeValue(control: T): void {
    if (control === undefined || control === null) return;

    this.value = control;
    // set label from form control
    this._index = this.options.findIndex(({ value }) => this.fnCast(value) === this.fnCast(control));

    if (this._index === -1) {
      this._index = 0;
    }

    const { label } = this.options[this._index];

    this.label = label;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
