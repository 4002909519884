import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItemStatus } from '@bend/store-shared';

import { KitchenType } from '../../../../types';

@Injectable()
export class OrderDialogReimbursedService {
  constructor(private _dialog: DialogService) {}

  behavior(): MonoTypeOperatorFunction<[OrderItemStatus, KitchenType, string, string]> {
    const allowStatuses = new Set([OrderItemStatus.Reimbursed]);

    return tap(([status]) => {
      if (allowStatuses.has(status)) {
        this._dialog.closeAll();
      }
    });
  }
}
