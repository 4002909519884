import { Type } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvService } from '@bend/env';

export interface Auth {
  socketToken: Observable<string>;
  extendToken: () => void;
}

export interface SocketConfig {
  deps: [Type<Auth>, Type<EnvService>];
}

export interface Socket {
  message: <T>() => Observable<T>;
  close: () => void;
}

export const enum SocketType {
  Open = 'open',
  Error = 'error',
}
