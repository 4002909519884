<button *ngIf="showArrow$ | async" (click)="scrollLeft()">
  <lib-icon class="icon icon--reverted" [style.fill]="primaryColor$ | async" icon="boardArrowRight"></lib-icon>
</button>

<lib-board-order-locations
  #location
  [orderUserId]="orderUserId$ | async"
  [locations]="orderLocations$ | async"
></lib-board-order-locations>

<button *ngIf="showArrow$ | async" (click)="scrollRight()">
  <lib-icon class="icon" [style.fill]="primaryColor$ | async" icon="boardArrowRight"></lib-icon>
</button>
