<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <div class="form-row">
      <label for="textInput" class="form-label">{{ 'WIDGETS.SHARED.TEXT' | translate }}</label>
      <div
        *ngIf="text.invalid && (((text.dirty || text.touched) && text.errors.required) || showErrors)"
        class="form-error-msg"
      >
        {{ 'WIDGETS.SHARED.TEXT_ENTER' | translate }}
      </div>
      <input
        id="textInput"
        type="text"
        class="form-input form-input--text"
        [placeholder]="'WIDGETS.SHARED.TEXT' | translate"
        formControlName="text"
      />
    </div>
    <div formGroupName="style" class="form-row-groups-wrapper">
      <div class="form-row-groups form-row-groups--distanced">
        <div class="form-row-group form-row-group--distanced">
          <label for="textColorInput" class="form-label">{{ 'WIDGETS.STYLE.TEXT_COLOR' | translate }}</label>
          <span
            id="textColorInput"
            [(colorPicker)]="textColorValue"
            class="form-colorpicker"
            [style.backgroundColor]="textColorValue"
          ></span>
        </div>
        <div class="form-row-group form-row-group--distanced">
          <label for="backgroundColorInput" class="form-label">
            {{ 'WIDGETS.STYLE.BACKGROUND_COLOR' | translate }}
          </label>
          <span
            id="backgroundColorInput"
            class="form-colorpicker"
            [(colorPicker)]="backgroundColorValue"
            [style.backgroundColor]="backgroundColorValue"
          ></span>
        </div>
        <div class="form-row-group form-row-group--distanced" formGroupName="margin">
          <label for="marginTopInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_TOP' | translate }}</label>
          <input
            id="marginTopInput"
            type="number"
            formControlName="top"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
        <div class="form-row-group form-row-group--distanced" formGroupName="margin">
          <label for="marginBottomInput" class="form-label">{{ 'WIDGETS.STYLE.MARGIN_BOTTOM' | translate }}</label>
          <input
            id="marginBottomInput"
            type="number"
            formControlName="bottom"
            min="0"
            class="form-input form-input--number"
          />
          <span class="form-input-number-units">px</span>
        </div>
      </div>
    </div>
    <div class="form-row" formGroupName="style">
      <input id="fullWidthCheckBox" class="form-input-checkbox" type="checkbox" formControlName="fullWidth" />
      <label for="fullWidthCheckBox" class="form-label form-label--checkbox">
        {{ 'WIDGETS.STYLE.FULL_WIDTH' | translate }}
      </label>
    </div>
  </form>
</pop-widget-edit-widget>
