import { Printer } from '../../settings/settings-deprecated.type';
import {
  BottomBar,
  CapacityManagement,
  CurrencySettings,
  DictionaryWorkingHoursIntervals,
  Gdpr,
  PartialNullify,
  PromoCode,
  SettingsBehavior,
  SettingsCart,
  SettingsCatalog,
  SettingsGoogleAnalytics,
  SettingsHotjarAnalytics,
  SettingsPop,
  SettingsPos,
  SettingsTakeAway,
  SettingsTips,
  SmartWifiSettings,
  TopBar,
  WorkingHours,
} from '.';
import { DictionaryHappyHoursIntervals, HappyHours } from './happy-hours.type';

export interface SettingsArea {
  general: PartialNullify<SettingsGeneralArea>;
  behavior: PartialNullify<SettingsBehavior>;
  pop: PartialNullify<SettingsPop>;
  sw: PartialNullify<SmartWifiSettings>;
  tips: PartialNullify<SettingsTips>;
  workingHours: PartialNullify<WorkingHours<string, DictionaryWorkingHoursIntervals>>;
  happyHours: PartialNullify<HappyHours<string, DictionaryHappyHoursIntervals>>;
  googleAnalytics: PartialNullify<SettingsGoogleAnalytics>;
  hotjarAnalytics: PartialNullify<SettingsHotjarAnalytics>;
  topBar: PartialNullify<TopBar>;
  bottomBar: PartialNullify<BottomBar>;
  cart: PartialNullify<SettingsCart>;
  productCatalog: PartialNullify<SettingsCatalog>;
  gdpr: PartialNullify<Gdpr>;
  capacity: PartialNullify<CapacityManagement>;
  pos: PartialNullify<SettingsPos>;
  promoCode: PartialNullify<PromoCode>;
  takeAway: PartialNullify<SettingsTakeAway>;
}

export interface SettingsGeneralArea {
  currency: CurrencySettings;
  defaultPage: string;
  defaultPlace: string;
  id: number;
  name: string;
  phone: {
    prefix: string;
  };
  timezone: string;
  printer?: Printer;
}
