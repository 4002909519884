<div
  *ngIf="isValid; else elseBlock"
  class="widget-wifi"
  [class.widget-wifi__no-border]="!attributes.style.showBorders"
  [style.backgroundColor]="attributes.style.backgroundColor"
  [style.marginTop]="attributes.style.margin.top + 'px' || 0 + 'px'"
  [style.marginBottom]="attributes.style.margin.bottom + 'px' || 0 + 'px'"
>
  <img alt="" [src]="attributes.style.iconUrl || '/assets/images/wifi.svg'" width="40" height="40" class="wifi-icon" />
  <div class="wifi-title" [style.fontSize]="attributes.style.fontSize + 'px'">{{ attributes.name }}</div>
  <div class="wifi-pass" [style.fontSize]="attributes.style.fontSize + 'px'" [style.color]="attributes.mainColor">
    {{ attributes.pass }}
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-wifi">{{ noValidMessage }}</div>
</ng-template>
