import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { DialogModule } from '@bend/dialog';
import { EPosService, ParserService, TemplateService } from '@bend/e-pos';
import { SocketModule } from '@bend/socket';
import { RecommendationGroupStoreModule, SettingsStoreModule } from '@bend/store';
import { CartStoreModule } from '@bend/store/src/lib/cart';
import { CategoryStoreModule } from '@bend/store/src/lib/category';
import { OrderStoreModule } from '@bend/store/src/lib/order';
import { OrderItemsStoreModule } from '@bend/store/src/lib/order-items';
import { OrderLocationsStoreModule } from '@bend/store/src/lib/order-locations';
import { OrderMenuItemsStoreModule } from '@bend/store/src/lib/order-menu-items';
import { OrderItemOptionsStoreModule } from '@bend/store/src/lib/order-options';
import { OrderUsersStoreModule } from '@bend/store/src/lib/order-users';
import { KioskPrinterFactoryService, PrinterCheckService } from '@bend/widgets-new/cart/services';

import { CatalogServicesModule } from '../catalog-services';
import {
  CatalogReloadService,
  ErrorService,
  KioskModeBehaviorService,
  NextActionService,
  OptimisticService,
  OrderCheckUpdateService,
  OrderControllerService,
  OrderDialogDisplayedService,
  OrderDialogErrorService,
  OrderDialogPaymentService,
  OrderDialogPreparingService,
  OrderDialogProgressService,
  OrderDialogPromoCodeService,
  OrderDialogReadyService,
  OrderDialogReimbursedService,
  OrderDialogService,
  OrderDialogTransactionsStatusService,
  OrderSocketService,
  OrderStatusServices,
  OrderTypeService,
  OrderUserCurrentService,
  OrderUserService,
  OtherDialogService,
  SessionService,
  SplitBillControlerService,
  SplitBillItemService,
  SplitBillService,
  TipsService,
  TotalService,
  TypeService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    CartStoreModule,
    OrderStoreModule,
    OrderItemsStoreModule,
    OrderUsersStoreModule,
    SettingsStoreModule,
    DialogModule,
    SocketModule,
    OrderLocationsStoreModule,
    OrderItemOptionsStoreModule,
    OrderMenuItemsStoreModule,
    CatalogServicesModule,
    CategoryStoreModule,
    RecommendationGroupStoreModule,
  ],
})
export class CartServicesModule {
  static forRoot(): ModuleWithProviders<CartServicesModule> {
    return {
      ngModule: CartServicesModule,
      providers: [
        ErrorService,
        NextActionService,
        OptimisticService,
        OrderCheckUpdateService,
        OrderControllerService,
        OrderDialogDisplayedService,
        OrderDialogErrorService,
        OrderDialogPaymentService,
        OrderDialogPreparingService,
        OrderDialogProgressService,
        OrderDialogPromoCodeService,
        OrderDialogReadyService,
        OrderDialogReimbursedService,
        OrderDialogTransactionsStatusService,
        OrderDialogService,
        OrderSocketService,
        OrderStatusServices,
        OrderTypeService,
        OrderUserService,
        OrderUserCurrentService,
        OtherDialogService,
        KioskModeBehaviorService,
        SessionService,
        TotalService,
        TypeService,
        CatalogReloadService,
        SplitBillService,
        TipsService,
        SplitBillItemService,
        SplitBillControlerService,
        ParserService,
        TemplateService,
        EPosService,
        PrinterCheckService,
        KioskPrinterFactoryService,
      ],
    };
  }
}
