<a
  [style.color]="fgColor$ | async"
  [routerLink]="[{ outlets: { page: 'cart' } }]"
  [relativeTo]="route"
  class="button"
  data-cy="cartBtn"
>
  <!-- show icon when order is not shared -->
  <lib-icon
    *ngIf="!(isShowUserTotal$ | async)"
    [style.fill]="fgColor$ | async"
    icon="cart"
    class="button__icon"
  ></lib-icon>
  <div [class.total--column]="isShowUserTotal$ | async" class="total">
    <!-- show 'MY' cart when order is shared -->
    <div *ngIf="isShowUserTotal$ | async" class="total__item">
      <span [innerHTML]="'CART.MY_CART' | translate" class="total__title"></span>
      <span [@bounce]="total$ | async">{{ totalUser$ | async | currency }}</span>
    </div>
    <div class="total__item">
      <span *ngIf="isShowUserTotal$ | async" [innerHTML]="'CART.TABLE_CART' | translate" class="total__title"></span>
      <span [@bounce]="total$ | async">{{ total$ | async | currency }}</span>
    </div>
  </div>
</a>
