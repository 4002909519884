import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PopProduct } from '@bend/widgets-old/product-catalog/product-catalog';

import { actions } from './recommendation.actions';
import * as selectors from './recommendation.selectors';
import { State } from './recommendation.type';

@Injectable()
export class RecommendationService {
  readonly all: Observable<PopProduct[]>;
  readonly id: Observable<number[]>;
  readonly name: Observable<string[]>;
  readonly priceFinalValue: Observable<number[]>;
  readonly order: Observable<number[]>;
  readonly imageUrl: Observable<string[]>;
  readonly quantityMultiplier: Observable<number[]>;
  readonly groupId: Observable<number[]>;
  readonly productId: Observable<number[]>;
  readonly quantity: Observable<number[]>;
  readonly isOutOfStock: Observable<boolean[]>;
  readonly isReadOnly: Observable<boolean[]>;

  readonly allSelected: Observable<PopProduct[]>;

  constructor(protected _store: Store<State>) {
    this.all = this._all;
    this.allSelected = this._allSelected;
  }

  allBy(productId: number, groupId: number): Observable<PopProduct[]> {
    return this._all.pipe(map(items => items.filter(item => item.groupId === groupId && item.id !== productId)));
  }

  changeQuantity(id: number, groupId: number, quantity: number): void {
    this._store.dispatch(actions.changeQuantity({ id, groupId, quantity }));
  }

  resetQuantity(): void {
    this._store.dispatch(actions.resetQuantity());
  }

  private get _allSelected(): Observable<PopProduct[]> {
    return this._all.pipe(map(items => items.filter(({ quantity }) => quantity)));
  }

  private get _all(): Observable<PopProduct[]> {
    return this._store.select(selectors.all);
  }
}
