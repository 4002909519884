import { tap } from 'rxjs/operators';

import { EMPTY_FN } from './empty-fn.helper';

export const tapComplete = <T>(fn: () => any) =>
  tap<T>({
    next: EMPTY_FN,
    error: fn,
    complete: fn,
  });
