import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { Category,filterUndefined, StoreService } from '../shared';
import { actions } from './category.actions';
import * as selectors from './category.selectors';
import { State } from './category.type';

@Injectable()
export class CategoryService extends StoreService<State> {
  readonly all$: Observable<Category[]>;
  readonly tabId$: Observable<string>;
  readonly catalogId$: Observable<number>;

  constructor(protected _store: Store<State>, protected _actions: Actions) {
    super(_store, _actions, selectors);

    this.all$ = this._all;
    this.tabId$ = this._tabId;
    this.catalogId$ = this._catalogId;
  }

  loading(tabId: string): Observable<boolean> {
    return this._store.select(selectors.loading).pipe(
      map(loading => loading[tabId]),
      filterUndefined(),
    );
  }

  getCategories(catalogId: number, tabId: string): Observable<boolean> {
    this._store.dispatch(actions.getCategories({ catalogId, tabId }));

    return this._finishedAction(
      actions.getCategoriesSuccess,
      actions.getCategoriesIsLoaded,
      actions.getCategoriesError,
    );
  }

  getCategoriesMenu(catalogId: number, menuId: number): Observable<boolean> {
    this._store.dispatch(actions.getMenuCategories({ menuId, catalogId }));

    return this._finishedAction(
      actions.getMenuCategoriesSuccess,
      actions.getMenuCategoriesIsLoaded,
      actions.getMenuCategoriesError,
    );
  }

  getCategoriesSingle(catalogId: number, tabId: string): Observable<boolean> {
    this._store.dispatch(actions.getCategoriesSingle({ catalogId, tabId }));

    return this._finishedAction(
      actions.getCategoriesSingleSuccess,
      actions.getCategoriesSingleIsLoaded,
      actions.getCategoriesSingleError,
    );
  }

  reloadCategories(): void {
    this._store.dispatch(actions.reloadCategories());
  }

  getById(categoryId: number): Observable<Category> {
    return this._all.pipe(map(all => all.find(({ id }) => categoryId === id)));
  }

  getSubCategories(categoryId: number): Observable<Category[]> {
    return this._all.pipe(map(all => all.filter(category => category.categoryId === categoryId)));
  }

  getMenuCategories(menuId: number): Observable<Category[]> {
    return this._all.pipe(map(all => all.filter(category => category.menuId === menuId)));
  }

  allByTab(tabId: string): Observable<Category[]> {
    return this._idsByTab(tabId).pipe(
      withLatestFrom(this._all),
      map(([ids, all]) => all.filter(({ id, categoryId }) => ids.includes(id) && !categoryId)),
    );
  }

  private _idsByTab(tabId: string): Observable<number[]> {
    return this._store.select(selectors.loadedTabs).pipe(
      map(tabs => tabs[tabId]),
      filterUndefined(),
    );
  }

  private get _all(): Observable<Category[]> {
    return this._store.select(selectors.allEntities).pipe(filterUndefined());
  }

  private get _catalogId(): Observable<number> {
    return this._store.select(selectors.catalogId);
  }

  private get _tabId(): Observable<string> {
    return this._store.select(selectors.selectedTabId).pipe(
      withLatestFrom(this.isLoading),
      filter(([tabId, isLoading]) => tabId && !isLoading),
      map(([tabId]) => tabId),
    );
  }
}
