export interface PosMenuToSelect {
  id: string;
  name: string;
}

export const IMPORT_FULL_CATALOG = '__IMPORT_FULL_CATALOG_OPTION__';

export enum OrderStatus {
  ALL = 'all',
  NEW = 'new',
  SEND = 'send',
  PAID = 'paid',
  PARTIALLY_READY = 'partially-ready',
  READY = 'ready',
  DELIVERED = 'delivered',
  CLOSED = 'closed',
  HANDED = 'handed',
}

export const OrderStatusTranslations: { [key in OrderStatus]: string } = {
  [OrderStatus.ALL]: 'STUDIO.ORDERS.STATUS.ALL',
  [OrderStatus.NEW]: 'STUDIO.ORDERS.STATUS.NEW',
  [OrderStatus.SEND]: 'STUDIO.ORDERS.STATUS.SEND',
  [OrderStatus.PAID]: 'STUDIO.ORDERS.STATUS.PAID',
  [OrderStatus.PARTIALLY_READY]: 'STUDIO.ORDERS.STATUS.PARTIALLY_READY',
  [OrderStatus.READY]: 'STUDIO.ORDERS.STATUS.READY',
  [OrderStatus.DELIVERED]: 'STUDIO.ORDERS.STATUS.DELIVERED',
  [OrderStatus.CLOSED]: 'STUDIO.ORDERS.STATUS.CLOSED',
  [OrderStatus.HANDED]: 'STUDIO.ORDERS.STATUS.HANDED',
};

export const getTranslationKeyByOrderStatus = (orderStatus: OrderStatus): string => {
  return OrderStatusTranslations[orderStatus];
};

export enum DisplayStatuses {
  ALL = '',
  IN_PROGRESS = 'in progress',
  HISTORY = 'history',
}

export enum InProgressStatuses {
  NEW = 'new',
  PAID = 'paid',
  READY = 'ready',
  SEND = 'send',
}

export enum HistoryStatuses {
  DELIVERED = 'delivered',
  CLOSED = 'closed',
}

export type OrderStatusesType = OrderStatus | DisplayStatuses | InProgressStatuses | HistoryStatuses;

export enum OrderMode {
  DEV = 'STUDIO.ORDERS.DEMO',
  PROD = 'STUDIO.ORDERS.LIVE',
}
