import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isNumeric } from '../../helpers';

export function fontSizeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = control.value === 'default' || isNumeric(control.value as string);
    return valid ? null : { fontSize: { value: control.value } };
  };
}
