import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './item.config';
import { adapter } from './item.reducer';
import { State } from './item.type';

const stateSelector = createFeatureSelector<State>(storeKey);
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const ids = createSelector(stateSelector, selectIds);
export const entities = createSelector(stateSelector, selectEntities);
export const all = createSelector(stateSelector, selectAll);
export const total = createSelector(stateSelector, selectTotal);

export const loadedCatalogIds = createSelector(stateSelector, state => state.loadedCatalogIds);
export const loadedMenuIds = createSelector(stateSelector, state => state.loadedMenuIds);
export const loadedParentItemIds = createSelector(stateSelector, state => state.loadedParentItemIds);
