import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { tapComplete } from '@bend/shared-widgets/src/lib/helpers';
import { OrderUserCurrentService } from '@bend/shared-widgets/src/public-api';
import type { OrderUserLight, PartialProps } from '@bend/store-shared';

import { WidgetProductCatalogCreateVisitorDialogComponent } from '../create-visitor-dialog/create-visitor-dialog.component';
import { PopSharedCartDialogParams, WidgetProductCatalog } from '../product-catalog';

@Component({
  selector: 'pop-product-catalog-shared-cart-dialog',
  templateUrl: './shared-cart-dialog.component.html',
  styleUrls: ['./shared-cart-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetProductCatalogSharedCartDialogComponent implements OnInit {
  attributes: WidgetProductCatalog;
  isLoading: boolean;
  personButtonClass: string;
  me: PartialProps<OrderUserLight, 'name'>;
  others: OrderUserLight[] = [];

  private _subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopSharedCartDialogParams,
    private _dialogRef: MatDialogRef<WidgetProductCatalogSharedCartDialogComponent>,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _orderUserCurrentService: OrderUserCurrentService,
  ) {}

  ngOnInit(): void {
    this.attributes = this.data.attributes;
    this.personButtonClass = this._getPersonButtonClass();

    this._subscription.add(
      combineLatest([this._orderUserCurrentService.current, this._orderUserCurrentService.others])
        .pipe(
          map(([currentOrderUser, otherOrderUsers]) => {
            this.me = currentOrderUser;
            this.others = otherOrderUsers;
            this._cdr.detectChanges();
          }),
          tapComplete(() => (this.isLoading = false)),
        )
        .subscribe(),
    );
  }

  selectMe(): void {
    if (this.me && this.me.name) {
      this.selectVisitor(this.me as OrderUserLight);
      return;
    }

    this._showNameDialog(false);
  }

  selectOtherPeople(): void {
    this._showNameDialog(true);
  }

  selectVisitor(orderUser: OrderUserLight): void {
    this._dialogRef.close(orderUser);
  }

  private _getPersonButtonClass(): string {
    const buttonCount = this._getUiButtonsCount();
    const MAX_LAYOUNT_COLUMN_COUNT = 5;
    const buttonClass = buttonCount < MAX_LAYOUNT_COLUMN_COUNT ? `person-btn-wrapper--${buttonCount}` : '';

    return buttonClass;
  }

  private _getUiButtonsCount(): number {
    const ME_BUTTON = 1;
    const OTHER_PEOPLE_BUTTON = 1;
    let count = ME_BUTTON + OTHER_PEOPLE_BUTTON;

    if (this.attributes.allowCommonPot) {
      count++;
    }

    if (this.others) {
      count += this.others.length;
    }

    return count;
  }

  private _showNameDialog(isOtherVisitor: boolean): void {
    const dialogRef = this._dialog.open(WidgetProductCatalogCreateVisitorDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      panelClass: 'create-visitor-dialog-overlay',
      data: {
        isOtherVisitor,
        attributes: this.attributes,
      },
    });

    dialogRef.afterClosed().subscribe((orderUser: OrderUserLight) => {
      if (!orderUser) {
        return;
      }

      if (isOtherVisitor) {
        this.others.push(orderUser);
      } else {
        this.me = orderUser;
      }

      this.selectVisitor(orderUser);

      this._cdr.detectChanges();
    });
  }
}
