import { FileData } from '../types';

export function saveToFile(fileData: FileData): void {
  const { name, data, type } = fileData;

  const fileBlob = new Blob([data], { type });
  const action = document.createElement('a');

  action.download = name;
  action.href = window.URL.createObjectURL(fileBlob);
  action.click();
  window.URL.revokeObjectURL(action.href);
}
