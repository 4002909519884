import { ModuleWithProviders, NgModule } from '@angular/core';

import { ButtonBackGuard } from './guards';
import { HistoryService } from './services';

@NgModule()
export class HistoryModule {
  static forRoot(): ModuleWithProviders<HistoryModule> {
    return {
      ngModule: HistoryModule,
      providers: [HistoryService, ButtonBackGuard],
    };
  }
}
