import { createAction, props } from '@ngrx/store';

import { createType, RecommendationsGroupDesigner } from '../shared';
import { storeKey } from './recommendations-groups-designer.config';

const type = createType(storeKey);

export const actions = {
  getRecommendationsGroups: createAction(type('get'), props<{ appSlug: string }>()),
  getRecommendationsGroupsIsLoaded: createAction(type('is loaded')),
  getRecommendationsGroupsSuccess: createAction(
    type('get success'),
    props<{
      groups: RecommendationsGroupDesigner[];
      appSlug: string;
    }>(),
  ),
  getRecommendationsGroupsError: createAction(type('get error'), props<{ errorCode: string }>()),

  addRecommendationsGroup: createAction(
    type('add recommendations group'),
    props<{ group: RecommendationsGroupDesigner }>(),
  ),
  addRecommendationsGroupSuccess: createAction(
    type('add recommendations group success'),
    props<{
      recommendationsGroup: RecommendationsGroupDesigner;
    }>(),
  ),
  addRecommendationsGroupError: createAction(type('add recommendations group error'), props<{ errorCode: string }>()),

  saveRecommendationsGroup: createAction(
    type('save recommendations group'),
    props<{ group: RecommendationsGroupDesigner }>(),
  ),
  saveRecommendationsGroupSuccess: createAction(
    type('save recommendations group success'),
    props<{
      recommendationsGroup: RecommendationsGroupDesigner;
    }>(),
  ),
  saveRecommendationsGroupError: createAction(type('save recommendations group error'), props<{ errorCode: string }>()),

  deleteRecommendationsGroup: createAction(type('delete recommendations group'), props<{ groupId: number }>()),
  deleteRecommendationsGroupSuccess: createAction(
    type('delete recommendations group success'),
    props<{ groupId: number }>(),
  ),
  deleteRecommendationsGroupError: createAction(
    type('delete recommendations group error'),
    props<{ errorCode: string }>(),
  ),
};
