import { Injectable } from '@angular/core';
import {
  Alignment,
  AlignmentConfig,
  Autoformat,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  ClassicEditor,
  EditorConfig,
  Enter,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontColorConfig,
  FontFamily,
  FontSize,
  FontSizeConfig,
  GeneralHtmlSupport,
  GeneralHtmlSupportConfig,
  Heading,
  HeadingConfig,
  Highlight,
  HorizontalLine,
  HtmlEmbed,
  Image,
  ImageBlock,
  ImageBlockEditing,
  ImageCaption,
  ImageEditing,
  ImageInline,
  ImageInsert,
  Input,
  Italic,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  SelectAll,
  ShiftEnter,
  SimpleUploadAdapter,
  SourceEditing,
  Strikethrough,
  Table,
  ToolbarConfig,
  Typing,
  Underline,
  Undo,
} from 'ckeditor5';

@Injectable({ providedIn: 'root' })
export class CkeditorConfigService {
  private toolbarConfig: ToolbarConfig = {
    items: [
      'undo',
      'redo',
      '|',
      'sourceEditing',
      '|',
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '-',
      'fontSize',
      'fontFamily',
      'alignment',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'horizontalLine',
    ],
    shouldNotGroupWhenFull: true,
  };

  private headingConfig: HeadingConfig = {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
    ],
  };

  private menuBarConfig = {
    isVisible: true,
    items: [
      {
        menuId: 'edit',
        label: 'Edit',
        groups: [
          {
            groupId: 'undo',
            items: ['menuBar:undo', 'menuBar:redo'],
          },
          {
            groupId: 'selectAll',
            items: ['menuBar:selectAll'],
          },
          {
            groupId: 'findAndReplace',
            items: ['menuBar:findAndReplace'],
          },
        ],
      },
      {
        menuId: 'view',
        label: 'View',
        groups: [
          {
            groupId: 'sourceEditing',
            items: ['menuBar:sourceEditing'],
          },
        ],
      },
      {
        menuId: 'insert',
        label: 'Insert',
        groups: [
          {
            groupId: 'insertMainWidgets',
            items: ['menuBar:insertImage', 'menuBar:insertTable'],
          },
          {
            groupId: 'insertInline',
            items: ['menuBar:link'],
          },
          {
            groupId: 'insertMinorWidgets',
            items: ['menuBar:mediaEmbed', 'menuBar:blockQuote', 'menuBar:htmlEmbed'],
          },
          {
            groupId: 'insertStructureWidgets',
            items: ['menuBar:horizontalLine'],
          },
        ],
      },
      {
        menuId: 'format',
        label: 'Format',
        groups: [
          {
            groupId: 'textAndFont',
            items: [
              {
                menuId: 'text',
                label: 'Text',
                groups: [
                  {
                    groupId: 'basicStyles',
                    items: ['menuBar:bold', 'menuBar:italic', 'menuBar:underline', 'menuBar:strikethrough'],
                  },
                ],
              },
              {
                menuId: 'font',
                label: 'Font',
                groups: [
                  {
                    groupId: 'fontProperties',
                    items: ['menuBar:fontSize', 'menuBar:fontFamily'],
                  },
                  {
                    groupId: 'fontColors',
                    items: ['menuBar:fontColor', 'menuBar:fontBackgroundColor'],
                  },
                  {
                    groupId: 'highlight',
                    items: ['menuBar:highlight'],
                  },
                ],
              },
              'menuBar:heading',
            ],
          },
          {
            groupId: 'list',
            items: ['menuBar:bulletedList', 'menuBar:numberedList'],
          },
          {
            groupId: 'indent',
            items: ['menuBar:alignment'],
          },
        ],
      },
      {
        menuId: 'help',
        label: 'Help',
        groups: [
          {
            groupId: 'help',
            items: ['menuBar:accessibilityHelp'],
          },
        ],
      },
    ],
  };

  private fontFamilyConfig = {
    options: [
      'default',
      'Roboto, Arial, sans-serif',
      'Open Sans, Arial, sans-serif',
      'Lato, Arial, sans-serif',
      'Montserrat, Arial, sans-serif',
      'Ubuntu, Arial, sans-serif',
      'Ubuntu Mono, Courier New, Courier, monospace',
      'Arial, Helvetica, sans-serif',
      'Times New Roman, Times, serif',
      'Courier New, Courier, monospace',
      'Georgia, Times, serif',
      'Verdana, Geneva, sans-serif',
      'Tahoma, Geneva, sans-serif',
      'Trebuchet MS, Helvetica, sans-serif',
      'Impact, Charcoal, sans-serif',
    ],
  };

  private alignmentConfig: AlignmentConfig = {
    options: ['left', 'center', 'right', 'justify'],
  };

  private fontColorConfig: FontColorConfig = {
    colorPicker: {
      // Use 'hex' format for output instead of 'hsl'.
      format: 'hex',
    },
  };

  private htmlSupportConfig: GeneralHtmlSupportConfig = {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  };

  private fontSizeConfig: FontSizeConfig = {
    options: [10, 12, 'default', 16, 18, 20, 24, 28, 32, 36, 48],
  };

  private plugins = [
    Bold,
    Italic,
    Essentials,
    Mention,
    Paragraph,
    Heading,
    FontColor,
    FontFamily,
    FontSize,
    Link,
    List,
    BlockQuote,
    Underline,
    Strikethrough,
    HorizontalLine,
    GeneralHtmlSupport,
    SourceEditing,
    Undo,
    Alignment,
    FontBackgroundColor,
    MediaEmbed,
    HtmlEmbed,
    SelectAll,
    FindAndReplace,
    Table,
    ImageEditing,
    ImageBlock,
    ImageCaption,
    ImageBlockEditing,
    Highlight,
    Autoformat,
    Input,
    ImageBlock,
    ImageInline,
    Image,
    Base64UploadAdapter,
    SimpleUploadAdapter,
    Enter,
    ShiftEnter,
    Typing,
    ImageInsert,
  ];

  public EditorConfig: EditorConfig = {
    toolbar: this.toolbarConfig,
    heading: this.headingConfig,
    alignment: this.alignmentConfig,
    menuBar: this.menuBarConfig,
    fontFamily: this.fontFamilyConfig,
    fontColor: this.fontColorConfig,
    fontSize: this.fontSizeConfig,
    htmlSupport: this.htmlSupportConfig,
    plugins: this.plugins,
  };

  public Editor = ClassicEditor;
}
