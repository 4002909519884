<div class="step-by-step-container">
  <pop-widget-top-bar [title]="topBarTitle" (goBack)="close()" [mainColor]="fgColor$ | async"></pop-widget-top-bar>
  <div class="step-by-step-container__stepper">
    <mat-tab-group
      [disableRipple]="true"
      [mat-stretch-tabs]="false"
      [disablePagination]="true"
      class="step-by-step-container__tabs"
      [(selectedIndex)]="selectedTab"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab
        #tab
        *ngFor="let category of menuCategories; let i = index; let first = first; let menuCategoryIndex = index"
      >
        <ng-template mat-tab-label>
          <div
            #tabContent
            class="tab-label"
            [class.tab-completed]="!errorCategories.includes(menuCategories[i].id)"
            [class.display-line]="
              (!errorCategories.includes(menuCategories[i].id) || activeIndex === i) &&
              (activeIndex === i - 1 || (!first && !errorCategories.includes(menuCategories[i - 1].id)))
            "
          >
            <span class="tab-label-text" [style.color]="mainColor">{{ category.name }}</span>
            <div
              class="circle"
              [style.background-color]="
                tab.isActive || !errorCategories.includes(menuCategories[i].id) ? mainColor : null
              "
            >
              <lib-icon
                *ngIf="!errorCategories.includes(menuCategories[i].id)"
                class="check-mark"
                icon="check-mark"
              ></lib-icon>
            </div>
          </div>
        </ng-template>
        <div class="description">
          <p *ngIf="category.description" class="menu-description">{{ category.description }}</p>
        </div>
        <div
          class="itemCategories-container"
          [class.itemCategories-container--flex]="menuItemsRowStyle | async"
          [class.itemCategories-container--extended]="isMenuItemStyleExtended | async"
        >
          <ng-container
            *ngFor="let product of category.menuCategoryItems | sortByOrder; let menuCategoryItemIndex = index"
          >
            <div
              *ngIf="product.item.available || itemUnavailabilityDisplay === itemUnavailabilityDisplayEnum.OutOfStock"
              class="product-item-wrapper"
              [attr.isselected]="product.item.isSelected"
              #menuItem
              [attr.data-cy]="'menuCategoryItem-' + menuCategoryIndex + '-' + menuCategoryItemIndex"
            >
              <pop-widget-product-catalog-item
                [product]="product.item"
                [currency]="currency"
                [isMenu]="true"
                (loadProductDetailsEmitter)="
                  loadProductDetails(product.item, product.priceFinalValue, category.id, $event, category)
                "
                [mainColor]="mainColor"
                [secondColor]="secondColor"
                [isReadOnly]="isReadOnly"
                [isConsultingMode]="isConsultingMode"
                [itemUnavailabilityDisplay]="itemUnavailabilityDisplay"
                [styleType]="styleType"
                [styleConfig]="styleConfig"
                [styleNumber]="menuItemStyle$ | async"
                [forceShowProductDescription]="forceShowProductDescription"
              ></pop-widget-product-catalog-item>
            </div>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
    <pop-widget-spinner
      *ngIf="!menuCategories.length"
      [width]="65"
      [height]="50"
      [color]="mainColor"
      class="stepper-spinner"
    ></pop-widget-spinner>
  </div>

  <div class="step-by-step-container__buttons">
    <button
      *ngIf="!isConsultingMode && !isReadOnly"
      (click)="addToCart()"
      class="add-to-cart-btn"
      [disabled]="!canAddToCart"
      [style.backgroundColor]="canAddToCart ? mainColor : 'gray'"
    >
      <ng-container *ngIf="canAddToCart; else choose">
        {{ 'PRODUCT_CATALOG.ADD_TO_CART' | translate }} -
        {{ total | currency }}
      </ng-container>
      <ng-template #choose>{{ 'PRODUCT_CATALOG.CHOOSE_PRODUCT' | translate }}</ng-template>
      <pop-widget-spinner *ngIf="isLoading" [width]="35" [height]="20"></pop-widget-spinner>
    </button>
    <h3 *ngIf="!isConsultingMode && isReadOnly">{{ 'CART.ORDER_NOT_AVAILABLE' | translate }}</h3>
  </div>
</div>
