import { createAction, props } from '@ngrx/store';

import { PopProduct } from '@bend/widgets-old/product-catalog/product-catalog';

import { createType } from '../shared';
import { storeKey } from './recommendation.config';

const type = createType(storeKey);

export const actions = {
  addRecommendations: createAction(type('add'), props<{ entities: PopProduct[] }>()),

  changeQuantity: createAction(type('change quantity'), props<{ id: number; groupId: number; quantity: number }>()),

  resetQuantity: createAction(type('reset quantity')),

  reset: createAction(type('reset')),
};
