import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { Widget } from '../../shared/models';

export interface PollWidget extends Widget {
  attributes: {
    questions: Question[];
    labels: PollLabels;
    saveContact: boolean;
    showResultsToUser: boolean;
  };
}

export interface Question {
  _id?: string;
  question: string;
  required: boolean;
  responses: Response[];
}

export interface Response {
  text: string;
  _id?: string;
  count?: number;
}

export class PollLabels {
  VOTE = 'Vote';
  MESSAGE = 'Thank you for your vote!';
}

export enum PollLabelsNames {
  VOTE = 'POLL.VOTE',
  MESSAGE = 'POLL.MESSAGE',
}

export interface PollLabel {
  label: string;
  key: string;
}

export type QuestionForm = FormGroup<{
  _id?: FormControl<string>;
  question: FormControl<string>;
  required: FormControl<boolean>;
  responses: FormArray<ResponseForm>;
}>;

export type ResponseForm = FormGroup<{
  text: FormControl<string>;
  _id?: FormControl<string>;
}>;

export type PollWidgetForm = FormGroup<{
  questions: FormArray<QuestionForm>;
  labels: FormGroup<{ [key in keyof PollLabels]: FormControl<string> }>;
}>;
