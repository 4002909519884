import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterService } from 'projects/store/src/lib/shared/abstracts';

import { CoreModule } from '@designer-core';
import { environment } from '@designer-environment';
import { MatIconRegistryModule } from '@designer-shared/icons/material-icon-registry.module';
import { DesignerStoreModule } from '@designer-store';
import { DesignerRouterService, RouterSerializer } from '@designer-store/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { createTranslateLoader } from './shared/shared.module';
registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    MatSnackBarModule,
    MatIconRegistryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    DesignerStoreModule,
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: RouterSerializer,
    }),
    EffectsModule.forRoot([]),
    /**
     * since the designer uses modules from the visitor that need ParamStore
     * it is necessary to register params reducer
     *
     * TODO delete that reducer registration, when modules from the visitor will not require ParamStore
     */
    StoreModule.forFeature('params', routerReducer),
    environment.name !== 'prod' ? StoreDevtoolsModule.instrument({ maxAge: 100 }) : [],
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: { separatorKeyCodes: [13, 188] },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { position: 'above' },
    },
    {
      provide: RouterService,
      useClass: DesignerRouterService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
