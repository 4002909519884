import { ItemError, OrderItemStatus } from '@bend/store-shared';

import { AppType, KitchenType, LocationCount } from '../types';

export const enum OrderDialogLabels {
  InProgress = 'CART.DIALOG.IN_PROGRESS.STATUS',
  InProgressPart = 'CART.DIALOG.IN_PROGRESS.PART.STATUS',
  Preparing = 'CART.DIALOG.PREPARING.STATUS',
  PaymentSentToPos = 'CART.DIALOG.PAYMENT.PAYMENT_SENT_TO_POS',
  PreparingPart = 'CART.DIALOG.PREPARING.PART.STATUS',
  ScheduledForPreparing = 'CART.DIALOG.PREPARING.SCHEDULED.STATUS',
  ScheduledForPreparing0Min = 'CART.DIALOG.PREPARING.SCHEDULED.STATUS_0_MIN',
  Ready = 'CART.DIALOG.READY.STATUS',
  ReadyPart = 'CART.DIALOG.READY.PART.STATUS',
  Close = 'CART.DIALOG.READY.CLOSE.STATUS',
  GasStationCode = 'CART.DIALOG.GAS.STATION.CODE',

  PaymentWaitingFromAll = 'CART.DIALOG.PAYMENT.WAITING.ALL',
  PaymentWaitingFromWaiter = 'CART.DIALOG.PAYMENT.WAITING.WAITER',
  PaymentInProgress = 'CART.DIALOG.PAYMENT.IN_PROGRESS',
  PaymentSuccess = 'CART.DIALOG.PAYMENT.SUCCESS',
  PaymentError = 'CART.DIALOG.PAYMENT.ERROR',
}

export const ORDER_DIALOG_IN_PROGRESS_DELAY = {
  [AppType.PayBefore]: 'CART.TOP_STATUS_ALL_PROGRESS_DELAY',
  [AppType.PayAfter]: 'CART.TOP_STATUS_PAY_AFTER_ALL_PROGRESS_DELAY',
};

export const ORDER_DIALOG_ERROR_LABELS = {
  [AppType.PayAfter]: {
    [KitchenType.Mono]: {
      [LocationCount.One]: {
        status: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.STATUS',
        [ItemError.UnknownProducts]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.UNKNOWN_PRODUCTS',
        [ItemError.NoStock]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.NO_STOCK',
        [ItemError.RestaurantClose]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.RESTAURANT_CLOSE',
        [ItemError.NotificationTimeout]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.GENERIC_ERROR',
        [ItemError.PosOrderRejected]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.POS_ORDER_REJECTED_ERROR',
        [ItemError.GenericError]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.GENERIC_ERROR',
        [ItemError.TemporarilyUnavailable]: 'CART.DIALOG.ERROR.PAY_AFTER.MONO.NO_STOCK',
      },
    },
    [KitchenType.Multi]: {
      [LocationCount.One]: {
        status: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.STATUS',
        [ItemError.UnknownProducts]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.UNKNOWN_PRODUCTS',
        [ItemError.NoStock]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.NO_STOCK',
        [ItemError.RestaurantClose]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.RESTAURANT_CLOSE',
        [ItemError.NotificationTimeout]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.GENERIC_ERROR',
        [ItemError.PosOrderRejected]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.POS_ORDER_REJECTED_ERROR',
        [ItemError.GenericError]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.GENERIC_ERROR',
        [ItemError.TemporarilyUnavailable]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.ONE.NO_STOCK',
      },
      [LocationCount.More]: {
        status: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.STATUS',
        [ItemError.UnknownProducts]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.UNKNOWN_PRODUCTS',
        [ItemError.NoStock]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.NO_STOCK',
        [ItemError.RestaurantClose]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.RESTAURANT_CLOSE',
        [ItemError.NotificationTimeout]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.GENERIC_ERROR',
        [ItemError.PosOrderRejected]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.POS_ORDER_REJECTED_ERROR',
        [ItemError.GenericError]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.GENERIC_ERROR',
        [ItemError.TemporarilyUnavailable]: 'CART.DIALOG.ERROR.PAY_AFTER.MULTI.MORE.NO_STOCK',
      },
    },
  },
  [AppType.PayBefore]: {
    [KitchenType.Mono]: {
      [LocationCount.One]: {
        status: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.STATUS',
        [ItemError.UnknownProducts]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.UNKNOWN_PRODUCTS',
        [ItemError.NoStock]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.NO_STOCK',
        [ItemError.RestaurantClose]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.RESTAURANT_CLOSE',
        [ItemError.NotificationTimeout]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.GENERIC_ERROR',
        [ItemError.PosOrderRejected]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.POS_ORDER_REJECTED_ERROR',
        [ItemError.GenericError]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.GENERIC_ERROR',
        [ItemError.TemporarilyUnavailable]: 'CART.DIALOG.ERROR.PAY_BEFORE.MONO.NO_STOCK',
      },
    },
    [KitchenType.Multi]: {
      amount: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.AMOUNT',
      [LocationCount.One]: {
        status: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.STATUS',
        [ItemError.UnknownProducts]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.UNKNOWN_PRODUCTS',
        [ItemError.NoStock]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.NO_STOCK',
        [ItemError.RestaurantClose]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.RESTAURANT_CLOSE',
        [ItemError.NotificationTimeout]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.GENERIC_ERROR',
        [ItemError.PosOrderRejected]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.POS_ORDER_REJECTED_ERROR',
        [ItemError.GenericError]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.GENERIC_ERROR',
        [ItemError.TemporarilyUnavailable]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.ONE.NO_STOCK',
      },
      [LocationCount.More]: {
        status: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.STATUS',
        [ItemError.UnknownProducts]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.UNKNOWN_PRODUCTS',
        [ItemError.NoStock]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.NO_STOCK',
        [ItemError.RestaurantClose]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.RESTAURANT_CLOSE',
        [ItemError.NotificationTimeout]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.GENERIC_ERROR',
        [ItemError.PosOrderRejected]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.POS_ORDER_REJECTED_ERROR',
        [ItemError.GenericError]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.GENERIC_ERROR',
        [ItemError.TemporarilyUnavailable]: 'CART.DIALOG.ERROR.PAY_BEFORE.MULTI.MORE.NO_STOCK',
      },
    },
  },
};

export const OrderDialogLocationStatus = {
  // sending
  [OrderItemStatus.OrderedInProgress]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SENDING',
  [OrderItemStatus.WaitingForPayment]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SENDING',

  // sent
  [OrderItemStatus.Preparing]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.NextForPreparing]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.Ordered]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.ScheduledForPreparing]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.Handed]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.ToBePrepared]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.Paid]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.PickUpReady]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.InDelivery]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',
  [OrderItemStatus.Closed]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.SUCCESS',

  // error
  [OrderItemStatus.Reimbursed]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.ERROR',
  [OrderItemStatus.OrderNotSent]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.ERROR',
  [OrderItemStatus.FailedPreCheck]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.ERROR',
  [OrderItemStatus.NoStock]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.ERROR',
  [OrderItemStatus.UnknownProduct]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.ERROR',
  [OrderItemStatus.PaymentNotSent]: 'CART.DIALOG.IN_PROGRESS.PART.LOCATION.ERROR',
};
