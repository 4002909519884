import { UrlSegmentGroup } from '@angular/router';

export const allPaths = (urlSegmentGroup: UrlSegmentGroup, stack: any[] = []): any[] => {
  const { children, segments } = urlSegmentGroup;

  segments.forEach(({ path }) => stack.push(path));

  Object.values(children).forEach(child => {
    allPaths(child, stack);
  });

  return stack;
};
