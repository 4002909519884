import { OrderItemStatus } from '@bend/store-shared';

export const StatusPriority: { [key in OrderItemStatus]: number } = {
  [OrderItemStatus.Closed]: 1,
  [OrderItemStatus.NoStock]: 2,
  [OrderItemStatus.UnknownProduct]: 3,
  [OrderItemStatus.TemporarilyUnavailable]: 4,
  [OrderItemStatus.InDelivery]: 5,
  [OrderItemStatus.PickUpReady]: 6,
  [OrderItemStatus.Handed]: 7,
  [OrderItemStatus.Paid]: 8,
  [OrderItemStatus.WaitingForPayment]: 9,
  [OrderItemStatus.PaymentInProgress]: 10,
  [OrderItemStatus.Ordered]: 11,
  [OrderItemStatus.PaymentNotSent]: 12,
  [OrderItemStatus.NextForPreparing]: 13,
  [OrderItemStatus.Preparing]: 14,
  [OrderItemStatus.ToBePrepared]: 15,
  [OrderItemStatus.ScheduledForPreparing]: 16,
  [OrderItemStatus.OrderNotSent]: 17,
  [OrderItemStatus.Reimbursed]: 18,
  [OrderItemStatus.OrderedInProgress]: 19,
  [OrderItemStatus.New]: 20,
  [OrderItemStatus.Empty]: 21,
  [OrderItemStatus.FailedPreCheck]: 22,
};
