import { Component, Input, OnInit } from '@angular/core';

import { LinkService } from '../link.service';
import { StyleService } from '../shared/services/style.service';
import { WarningService, WarnTypeEnum } from '../warning.service';
import { PopMargin, PopWidthStyle } from '../widget';
import { WidgetComponent } from '../widget.component';
import { LinkDisplayTypes, TextAlignType, WidgetLink } from './link';

const DEFAULT = 'default';

@Component({
  selector: 'pop-widget-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class WidgetLinkComponent implements OnInit, WidgetComponent {
  static widgetName = 'link';
  @Input() attributes: WidgetLink;

  isValid: boolean;
  noValidMessage: string;
  widthStyle: PopWidthStyle;
  imageHeight: string;
  displayTypes = LinkDisplayTypes;

  constructor(
    private _linkService: LinkService,
    private _warningService: WarningService,
    private _styleService: StyleService,
  ) {}

  ngOnInit(): void {
    if (!this.attributes || !this.attributes.title || !this.attributes.link) {
      this.noValidMessage = this._warningService.showWarn(WidgetLinkComponent.widgetName, WarnTypeEnum.NoAttributes);

      return;
    }

    this._addMissingAttributes(this.attributes);
    this.widthStyle = this._styleService.getWidthStyle(this.attributes.style.width);
    this._initImageHeight();

    this.isValid = true;
  }

  navigate(): void {
    const { link, openInNewTab, isEditMode } = this.attributes;
    this._linkService.navigate(link, openInNewTab, isEditMode);
  }

  private _addMissingAttributes(attributes: WidgetLink): void {
    if (!attributes.style) {
      attributes.style = {
        backgroundColor: '',
        borderColor: '',
        borderRadius: 15,
        borderWidth: 0,
        height: DEFAULT,
        iconUrl: '',
        showArrow: false,
        width: DEFAULT,
        margin: new PopMargin(),
        fontSize: '',
        textAlign: TextAlignType.CENTER,
        textColor: '',
        displayType: LinkDisplayTypes.STYLE1,
      };
    }

    if (!attributes.style.margin) {
      attributes.style.margin = new PopMargin();
    }

    if (!attributes.style.textAlign) {
      attributes.style.textAlign = TextAlignType.CENTER;
    }
  }

  private _initImageHeight(): void {
    const { height, displayType } = this.attributes.style;
    if (displayType !== LinkDisplayTypes.STYLE2) {
      this.imageHeight = height === DEFAULT ? '30' : height;
    }
  }
}
