<pop-widget-edit-widget [widget]="widget" (submit)="onSubmit()" (onSaveAndPublish)="onSaveAndPublish()">
  <form [formGroup]="form" class="widget-edit-form">
    <span class="form-label section-label first-label">{{ 'STUDIO.SHARED.CONFIGURATION' | translate }}</span>
    <mat-checkbox formControlName="saveContact">{{ 'STUDIO.SETTINGS.BEHAVIOR.ASK_EMAIL' | translate }}</mat-checkbox>
    <mat-checkbox formControlName="showResultsToUser">{{ 'WIDGETS.POLL.SHOW_POLL_RESULTS' | translate }}</mat-checkbox>

    <span class="form-label section-label">{{ 'WIDGETS.POLL.QUESTIONS' | translate }}</span>
    <div formArrayName="questions" class="form-row">
      <div *ngFor="let question of questionGroups; let i = index; let isLast = last">
        <div class="form-row" [formGroupName]="i">
          <div class="question-container">
            <div>
              <label for="questionInput" class="form-label">
                {{ 'WIDGETS.POLL.QUESTION' | translate }} {{ questionGroups.length > 1 ? i + 1 : '' }}
              </label>
              <div
                *ngIf="
                  question.invalid &&
                  (((question.dirty || question.touched) && question.errors?.required) || showErrors)
                "
                class="form-error-msg"
              >
                {{ 'WIDGETS.POLL.QUESTION_ENTER' | translate }}
              </div>
              <input
                id="questionInput"
                type="text"
                class="form-input form-input--question"
                [placeholder]="'WIDGETS.POLL.QUESTION' | translate"
                formControlName="question"
              />
            </div>
            <div class="required">
              <input type="checkbox" formControlName="required" />
              <label>Required</label>
            </div>
          </div>
          <button
            *ngIf="form.controls.questions.length > 1"
            class="btn-flat btn-flat--remove"
            type="button"
            (click)="removeQuestion(i)"
          >
            <span class="hyphen">-</span>
            {{ 'WIDGETS.POLL.QUESTION_REMOVE' | translate }}
          </button>

          <div class="form-fieldset-groups">
            <span class="form-fieldset-groups__caption">{{ 'WIDGETS.POLL.RESPONSES' | translate }}</span>
            <div class="form-fieldset-groups__content" formArrayName="responses">
              <div *ngFor="let response of responsesControls(i); let j = index" class="form-fieldset-group">
                <div [formGroupName]="j">
                  <div class="form-row">
                    <label for="{{ 'responseInput' + (j + 1) }}" class="form-label">
                      {{ 'WIDGETS.POLL.RESPONSE' | translate }} {{ j + 1 }}
                    </label>
                    <div *ngIf="isShowErrorForResponse(i, j)" class="form-error-msg">
                      {{ 'WIDGETS.POLL.RESPONSE_ENTER' | translate }}
                    </div>
                    <input
                      id="{{ 'responseInput' + (j + 1) }}"
                      type="text"
                      class="form-input form-input--text"
                      [placeholder]="'WIDGETS.POLL.RESPONSE' | translate"
                      formControlName="text"
                    />
                  </div>
                  <div class="form-row clearfix">
                    <button
                      *ngIf="question.controls.responses.length > 1"
                      class="btn-flat btn-flat--remove remove-response"
                      type="button"
                      (click)="removeResponse(i, j)"
                    >
                      <span class="hyphen">-</span>
                      {{ 'WIDGETS.POLL.REMOVE_RESPONSE' | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <button class="btn-flat btn-flat--add-new-item" type="button" (click)="addResponse(i)">
                {{ 'WIDGETS.POLL.ADD_RESPONSE' | translate }}
              </button>
            </div>
          </div>
        </div>
        <hr *ngIf="!isLast" />
      </div>
      <button class="btn-flat btn-flat--add-new-item" type="button" (click)="addQuestion()">
        {{ 'WIDGETS.POLL.QUESTION_ADD' | translate }}
      </button>
    </div>
    <span class="form-label section-label">{{ 'WIDGETS.SHARED.LABELS' | translate }}</span>
    <div class="form-row-cols-wrapper settings-row" formGroupName="labels">
      <div class="form-col-line" *ngFor="let label of labels">
        <label for="{{ label.key }}" class="form-label form-label--print">
          {{ labelsNames[label.key] | translate }}
        </label>
        <input
          id="{{ label.key }}"
          formControlName="{{ label.key }}"
          type="text"
          class="form-input form-input--print"
        />
      </div>
    </div>
  </form>
</pop-widget-edit-widget>
