import { Component, Input, OnChanges } from '@angular/core';

import { ITEM_ERRORS } from '@bend/shared-widgets/src/lib/types';
import { ItemUnavailabilityDisplay } from '@bend/store';
import { OrderItemStatus, Recommendation } from '@bend/store-shared';

import { isTemporarilyUnavailable } from '../helpers';
import { PopMenu, PopProduct } from '../product-catalog';

@Component({
  selector: 'app-product-status-checkout',
  templateUrl: './product-status-checkout.component.html',
  styleUrls: ['./product-status-checkout.component.scss'],
})
export class ProductStatusCheckoutComponent implements OnChanges {
  @Input() product: PopProduct | Recommendation | PopMenu | Omit<Recommendation, 'productId'>;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay = null;
  itemStatusLabel: string;

  ngOnChanges(): void {
    this.itemStatusLabel = this._itemStatusLabel;
  }

  private get _itemStatusLabel(): string {
    if (this._isOutOfStock) return `PRODUCT_CATALOG.${ITEM_ERRORS[OrderItemStatus.NoStock]}`;
    if (this._isTemporarilyUnavailable) return 'PRODUCT_CATALOG.TEMPORARILY_UNAVAILABLE';
    return '';
  }

  private get _isOutOfStock(): boolean {
    return !this.product.available && this.itemUnavailabilityDisplay === ItemUnavailabilityDisplay.OutOfStock;
  }

  private get _isTemporarilyUnavailable(): boolean {
    return isTemporarilyUnavailable(this.product as PopProduct);
  }
}
