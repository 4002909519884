import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { ParamsService } from '../params';
import {
  Address,
  Addresses,
  AddressPlace,
  AnalyticsSettings,
  ArrayStore,
  BottomBar,
  BusinessLocation,
  Capacity,
  CartSettings,
  CollectType,
  Colors,
  Credit,
  Currency,
  Delivery,
  DictionaryStore,
  filterUndefined,
  GdprSettings,
  GeneralSettings,
  ifPluck,
  ifPluckArray,
  KioskStylesConfig,
  LanguagesConfig,
  NewslettersSettings,
  Phone,
  PlaceType,
  ProductCatalogSettings,
  PromoCode,
  PwaSettings,
  Settings,
  SignInSettings,
  SmartWifiSettings,
  TakeAwaySettings,
  TopBar,
  Ui,
  UpgradeMethods,
  WidgetsSettings,
  WorkingHours,
} from '../shared';
import { HappyHours } from '../shared/types/happy-hours.type';
import { UserSocketUpdatesService } from '../user-socket-update/user-socket-update.service';
import { settingsActions as actions } from './settings.actions';
import * as selectors from './settings.selectors';
import { State } from './settings.type';

@Injectable()
export class SettingsService {
  readonly allowTips: Observable<boolean>;
  readonly resetSessionAfterSec: Observable<number>;
  readonly kioskMode: Observable<boolean>;
  readonly page: Observable<string>;
  readonly defaultPage: Observable<string>;
  readonly isLoaded: Observable<boolean>;
  readonly happyHours: Observable<HappyHours>;
  readonly kioskStyles: Observable<KioskStylesConfig>;

  constructor(
    private _store: Store<State>,
    private _params: ParamsService,
    private _env: EnvService,
    private _userSocketUpdate: UserSocketUpdatesService,
  ) {
    if (this._env.userType === 'user') {
      this._getSettings.subscribe();
      this._updateSettings.subscribe();
      this._updateTableStatus.subscribe();
    }

    this.allowTips = this._allowTips;
    this.resetSessionAfterSec = this._resetSessionAfterSec;
    this.kioskMode = this._kioskMode;
    this.page = this._page;
    this.defaultPage = this._defaultPage;
    this.isLoaded = this._isLoaded;
    this.happyHours = this._happyHours;
    this.kioskStyles = this._kioskStyles;
  }

  get printer(): Observable<Settings['printer']> {
    return this._store.select(selectors.printer);
  }

  get error(): Observable<string> {
    return this._store.select(selectors.error);
  }

  get isLoading(): Observable<boolean> {
    return this._store.select(selectors.isLoading).pipe(filterUndefined());
  }

  get all(): Observable<Settings> {
    return this._store.select(selectors.settings);
  }

  get placeName(): Observable<string> {
    return this._store.select(selectors.placeName);
  }

  get placeStatus(): Observable<string> {
    return this._store.select(selectors.placeStatus);
  }

  get name(): Observable<string> {
    return this._store.select(selectors.name);
  }

  get language(): Observable<LanguagesConfig> {
    return this._store.select(selectors.language);
  }

  get analytics(): Observable<AnalyticsSettings> {
    return this._store.select(selectors.analytics);
  }

  get delivery(): Observable<Delivery> {
    return this._store.select(selectors.delivery);
  }

  get takeAway(): Observable<TakeAwaySettings> {
    return this._store.select(selectors.takeAway);
  }

  get gdpr(): Observable<GdprSettings> {
    return this._store.select(selectors.gdpr);
  }

  get orderMinAmount(): Observable<number> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.orderMinAmount));
  }

  get placeType(): Observable<PlaceType> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.placeType));
  }

  get collectTypes(): Observable<CollectType[]> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.collectTypes));
  }

  get allowCashPayment(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.allowCashPayment));
  }

  get allowSplitBill(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.allowSplitBill));
  }

  get cashPaymentOnly(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.cashPaymentOnly));
  }

  get noPayment(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.noPayment));
  }

  get allowCreateNewSession(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.allowUserCreateNewSesssion));
  }

  get showOrderHistory(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.showOrderHistory));
  }

  get orderForAnotherDay(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.orderForAnotherDay));
  }

  get confirmPaymentAsAPopup(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.confirmPaymentAsAPopup));
  }

  get confirmPayment(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.confirmPayment));
  }

  get redirectUrl(): Observable<string> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.redirectUrl));
  }

  get verifyTodayOrder(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.verifyTodayOrder));
  }

  get orderForOthers(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.allowOrderForOthers));
  }

  get widgetCart(): Observable<CartSettings> {
    return this.widgets.pipe(map(cartSettings => cartSettings?.cart));
  }

  get widgetProductCatalog(): DictionaryStore<ProductCatalogSettings> {
    const productCatalog: Observable<ProductCatalogSettings> = this.widgets.pipe(
      map(productCatalogSettings => productCatalogSettings?.product_catalog),
    );
    return {
      all: productCatalog,
      style: productCatalog.pipe(map(settings => settings?.style)),
      productDescriptionStyle: productCatalog.pipe(map(settings => settings?.productDescriptionStyle)),
      hasSearch: productCatalog.pipe(map(settings => settings?.hasSearch)),
      stepByStepCategories: productCatalog.pipe(map(settings => settings?.stepByStepCategories)),
      stepByStepMenus: productCatalog.pipe(map(settings => settings?.stepByStepMenus)),
      menuType: productCatalog.pipe(map(settings => settings?.menuType)),
      menuItemStyleType: productCatalog.pipe(map(settings => settings?.menuItemStyleType)),
      menuStyleType: productCatalog.pipe(map(settings => settings?.menuStyleType)),
      categoriesStyle: productCatalog.pipe(map(settings => settings?.categoriesStyle)),
      categoryStyleType: productCatalog.pipe(map(settings => settings?.categoryStyleType)),
      categoryAlignType: productCatalog.pipe(map(settings => settings?.categoryAlignType)),
      itemUnavailabilityDisplay: productCatalog.pipe(map(settings => settings?.itemUnavailabilityDisplay)),
    };
  }

  get widgetTopBar(): Observable<TopBar> {
    return this.widgets.pipe(map(widgetSettings => widgetSettings?.top_bar));
  }

  get widgetBottomBar(): Observable<BottomBar> {
    return this.widgets.pipe(map(widgetSettings => widgetSettings?.bottom_bar));
  }

  get widgets(): Observable<WidgetsSettings> {
    return this._store.select(selectors.widgets);
  }

  get phone(): DictionaryStore<Phone> {
    const phone = this._store.select(selectors.phone);

    return {
      all: phone,
      prefix: phone.pipe(map(phone => phone?.prefix)),
    };
  }

  get newsletters(): DictionaryStore<NewslettersSettings> {
    const newsletters = this._store.select(selectors.newsletters);

    return {
      all: newsletters,
      enabled: newsletters.pipe(map(newsletters => newsletters?.enabled)),
    };
  }

  get signIn(): DictionaryStore<SignInSettings> {
    const signIn = this._store.select(selectors.signIn);

    return {
      all: signIn,
      enabled: signIn.pipe(map(signIn => signIn?.enabled)),
    };
  }

  get smartWifiSettings(): Observable<SmartWifiSettings> {
    return this._store.select(selectors.smartWifiSettings);
  }

  get promoCode(): DictionaryStore<PromoCode> {
    const promoCode = this._store.select(selectors.promoCode);

    return {
      all: promoCode,
      type: promoCode.pipe(map(promoCode => promoCode?.type)),
      code: promoCode.pipe(map(promoCode => promoCode?.code)),
      enabled: promoCode.pipe(map(promoCode => promoCode?.enabled)),
      showAllPromoCodes: promoCode.pipe(map(promoCode => promoCode?.showAllPromoCodes)),
    };
  }

  get colors(): DictionaryStore<Colors> {
    const colors = this._store.select(selectors.colors);

    return {
      all: colors,
      primary: colors.pipe(map(color => color?.primary)),
      primaryForeground: colors.pipe(map(color => color?.primaryForeground)),
      secondary: colors.pipe(map(color => color?.secondary)),
      secondaryForeground: colors.pipe(map(color => color?.secondaryForeground)),
    };
  }

  get credit(): DictionaryStore<Credit> {
    const credit = this._store.select(selectors.credit);

    return {
      all: credit,
      enabled: credit.pipe(map(credit => credit?.enabled)),
    };
  }

  get currency(): Observable<Currency> {
    return this._store.select(selectors.currency);
  }

  get country(): Observable<string> {
    return this.general.pipe(map(generalSettings => generalSettings?.country));
  }

  get general(): Observable<GeneralSettings> {
    return this._store.select(selectors.general);
  }

  get addresses(): DictionaryStore<Addresses> {
    const addresses = this._store.select(selectors.addresses);

    return {
      all: addresses,
      place: this._place,
      user: this._user,
    };
  }

  get businessLocation(): Observable<BusinessLocation> {
    return this._store.select(selectors.businessLocation);
  }

  get workingHours(): Observable<WorkingHours> {
    return this._store.select(selectors.workingHours);
  }

  get capacity(): Observable<Capacity> {
    return this._store.select(selectors.capacityManagement);
  }

  get ui(): Observable<Ui> {
    return this._store.select(selectors.settings).pipe(map(settings => settings?.ui));
  }

  get pwaSettings(): Observable<PwaSettings> {
    return this._store.select(selectors.pwaSettings);
  }

  get upgradeMethods(): Observable<UpgradeMethods> {
    return this._store.select(selectors.upgradeMethods);
  }

  get tokenRequired(): Observable<boolean> {
    return this._store.select(selectors.tokenRequired);
  }

  private get _kioskStyles(): Observable<KioskStylesConfig> {
    return this._store.select(selectors.kioskStyles);
  }

  private get _happyHours(): Observable<HappyHours> {
    return this._store.select(selectors.happyHours);
  }

  private get _allowTips(): Observable<boolean> {
    return this.widgetCart.pipe(map(cartSettings => cartSettings?.['allowTips'])) as Observable<boolean>;
  }

  private get _resetSessionAfterSec(): Observable<number> {
    return this.widgetCart.pipe(map(cart => cart.resetSessionAfterSec));
  }

  private get _kioskMode(): Observable<boolean> {
    return this.widgetCart.pipe(map(cart => cart.kioskMode));
  }

  private get _page(): Observable<string> {
    return this._store.select(selectors.page);
  }

  private get _defaultPage(): Observable<string> {
    return this._store.select(selectors.defaultPage);
  }

  private get _isLoaded(): Observable<boolean> {
    return this._store.select(selectors.isLoadedBy).pipe(filterUndefined());
  }

  private get _getSettings(): Observable<unknown> {
    return this._params.appSlugChanges.pipe(
      switchMap(appSlug =>
        this._params.queryParamsChanges(['place']).pipe(
          filter(() => !!appSlug),
          tap(([place]) => place && this._store.dispatch(actions.getSettingsByPlace({ by: place }))),
          tap(([place]) => !place && this._store.dispatch(actions.getSettings({ by: appSlug }))),
        ),
      ),
    );
  }

  private get _updateTableStatus(): Observable<unknown> {
    return this._userSocketUpdate.table().pipe(
      switchMap(() => this._params.queryParams(['place'])),
      tap(([place]) => this._store.dispatch(actions.updateSettingsByPlace({ by: place }))),
    );
  }

  private get _updateSettings(): Observable<unknown> {
    return this._userSocketUpdate.settings().pipe(
      switchMap(() => combineLatest([this._params.appSlug, this._params.queryParams(['place'])])),
      tap(([appSlug, [place]]) =>
        place
          ? this._store.dispatch(actions.updateSettingsByPlace({ by: place }))
          : this._store.dispatch(actions.updateSettings({ by: appSlug })),
      ),
    );
  }

  private get _place(): DictionaryStore<AddressPlace> {
    const place = this._store.select(selectors.addresses).pipe(ifPluck('place'));

    return {
      all: place,
      address: this._placeAddress,
      deliveryFee: place.pipe(ifPluck('deliveryFee')),
      isForDelivery: place.pipe(ifPluck('isForDelivery')),
    };
  }

  private get _placeAddress(): DictionaryStore<Address> {
    const address = this._store.select(selectors.addresses).pipe(ifPluck('place'), ifPluck('address'));

    return {
      all: address,
      name: address.pipe(ifPluck('name')),
      addressName: address.pipe(ifPluck('addressName')),
      city: address.pipe(ifPluck('city')),
      street: address.pipe(ifPluck('street')),
      streetNumber: address.pipe(ifPluck('streetNumber')),
      zipCode: address.pipe(ifPluck('zipCode')),
      additionalDetails: address.pipe(ifPluck('additionalDetails')),
    };
  }

  private get _user(): ArrayStore<Address> {
    const user = this._store.select(selectors.addresses).pipe(ifPluck('user'));

    return {
      all: user,
      name: user.pipe(ifPluckArray('name')),
      addressName: user.pipe(ifPluckArray('addressName')),
      city: user.pipe(ifPluckArray('city')),
      street: user.pipe(ifPluckArray('street')),
      streetNumber: user.pipe(ifPluckArray('streetNumber')),
      zipCode: user.pipe(ifPluckArray('zipCode')),
      additionalDetails: user.pipe(ifPluckArray('additionalDetails')),
    };
  }
}
