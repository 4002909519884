<form
  *ngIf="attributes && form; else elseBlock"
  [formGroup]="form"
  class="widget-contact-info"
  [style.marginTop.px]="attributes.style?.margin?.top"
  [style.marginBottom.px]="attributes.style?.margin?.bottom"
  [style.marginLeft.px]="attributes.style?.showBackground ? '' : '0'"
  [style.marginRight.px]="attributes.style?.showBackground ? '' : '0'"
  [style.borderRadius.px]="attributes.style?.borderRadius"
  [style.background-color]="attributes.style?.showBackground ? '' : 'transparent'"
  (ngSubmit)="onSubmit()"
>
  <h2 class="widget-contact-info__title">{{ attributes.labels.title }}</h2>
  <div *ngIf="attributes.showName" class="widget-contact-info__input">
    <label>
      <span class="label">{{ attributes.labels.name }}</span>
      <pop-widget-person-name
        [mainColor]="attributes.mainColor"
        [me]="me"
        [nameControl]="name"
      ></pop-widget-person-name>
      <div *ngIf="form.controls.name.invalid && form.controls.name.touched" class="mat-error">
        <span>{{ 'CONTACT.NAME_REQUIRED' | translate }}</span>
      </div>
    </label>
  </div>

  <div *ngIf="attributes.showEmail" class="widget-contact-info__input">
    <label>
      <span class="label">{{ attributes.labels.email }}</span>
      <pop-widget-person-email
        [mainColor]="attributes.mainColor"
        [me]="me"
        [emailControl]="email"
      ></pop-widget-person-email>
      <div *ngIf="form.controls.email.invalid && form.controls.email.touched" class="mat-error">
        <span>{{ 'CONTACT.EMAIL_INVALID' | translate }}</span>
      </div>
    </label>
  </div>
  <div *ngIf="attributes.showPhone" class="widget-contact-info__input">
    <label>
      <span class="label">{{ attributes.labels.phone }}</span>
      <pop-widget-phone-number
        [phone]="phoneData"
        [mainColor]="attributes.mainColor"
        [data]="phone"
        [editable]="!attributes.isEditMode"
      ></pop-widget-phone-number>
    </label>
  </div>
  <button
    [type]="attributes.isEditMode ? 'button' : 'submit'"
    [style.backgroundColor]="attributes.mainColor"
    class="submit-btn"
    [class.submit-btn--disabled]="attributes.isEditMode"
    [disabled]="attributes.isEditMode"
  >
    {{ attributes.labels.sendButton }}
  </button>
</form>

<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-contact-info">{{ noValidMessage }}</div>
</ng-template>
