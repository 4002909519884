import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiCreditService {
  constructor(private _env: EnvService, private _params: RouterService) {}

  /**
   * @returns https://api-test.smilein.fr/credit/version/userType/appSlug/export/credit-history
   */
  creditHistory(version: Version): Observable<string> {
    return this.credit(version).pipe(this._patch(`/export/credit-history`));
  }

  /**
   * @returns https://api-test.smilein.fr/credit/version/userType/appSlug/export/ticket
   */
  ticket(version: Version): Observable<string> {
    return this.credit(version).pipe(this._patch(`/export/ticket`));
  }

  /**
   * @returns https://api-test.smilein.fr/credit/version/userType/appSlug/users/userID/balance
   */
  add(version: Version, userId: string): Observable<string> {
    return this.credit(version).pipe(this._patch(`/users/${userId}/balance`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug/users
   */
  users(version: Version): Observable<string> {
    return this.credit(version).pipe(this._patch(`/users`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug/users/userId
   */
  userInfo(version: Version, userId: string): Observable<string> {
    return this.credit(version).pipe(this._patch(`/users/${userId}`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug/transactions
   */
  transactions(version: Version): Observable<string> {
    return this.credit(version).pipe(this._patch(`/transactions`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug/tickets
   */
  tickets(version: Version): Observable<string> {
    return this.credit(version).pipe(this._patch(`/tickets`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug/tickets/ticketId
   */
  updateTicket(version: Version, ticketId: string): Observable<string> {
    return this.tickets(version).pipe(this._patch(`/${ticketId}`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug/history
   */
  history(version: Version): Observable<string> {
    return this.credit(version).pipe(this._patch(`/history`));
  }

  /**
   * @returns https://api.smilein.fr/credit/version/userType/appSlug
   */
  credit(version: Version): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/credit/${version}/${this._env.userType}/${appSlug}`),
    );
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
