import { Injectable } from '@angular/core';

import { DefaultTemplate } from './template';
import { TemplateConfig } from './type';

@Injectable()
export class TemplateService {
  generate(config?: TemplateConfig): DefaultTemplate {
    return new DefaultTemplate(config);
  }
}
