import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pop-widget-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class WidgetTopBarComponent {
  @Input() mainColor: string;
  @Input() title: string;
  @Output() goBack = new EventEmitter();

  onClick(): void {
    this.goBack.emit();
  }
}
