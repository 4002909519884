import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Smile } from '../shared';
import { actions } from './smiles.actions';
import { State } from './smiles.type';

export const adapter: EntityAdapter<Smile> = createEntityAdapter<Smile>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
  total: 0,
  skip: 0,
  limit: 10,
  isLoadedBy: '',
  isLoaded: false,
});

const userReducer = createReducer(
  initialState,
  on(actions.getSmiles, actions.getNewSmiles, actions.generateSmiles, actions.updateSmilesPageId, state => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(
    actions.getSmilesSuccess,
    actions.getSmilesBySearchSuccess,
    actions.getNewSmilesSuccess,
    (state, { total, skip, limit, data }) =>
      adapter.setAll(data, {
        ...state,
        skip,
        limit,
        total,
        isLoading: false,
        isLoaded: true,
      }),
  ),
  on(actions.getSmileSuccess, (state, smile) => adapter.addOne(smile, state)),
  on(
    actions.getSmilesError,
    actions.generateSmilesError,
    actions.getNewSmilesError,
    actions.updateSmilesPageIdError,
    state => ({ ...state, isLoading: false, isLoaded: false }),
  ),

  on(actions.editSmile, state => ({ ...state, isLoading: true })),
  on(actions.editSmileSuccess, (state, { id, smile }) =>
    adapter.updateOne({ id, changes: { ...smile, isLoading: false } }, state),
  ),
  on(actions.editSmileError, state => ({ ...state, isLoading: false })),
);

export function reducer(state: State | undefined, action: Action): State {
  return userReducer(state, action);
}
