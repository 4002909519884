import { Inject, Injectable } from '@angular/core';

import { ENV } from './env.token';
import { EnvGeneric } from './env.type';

@Injectable({ providedIn: 'root' })
export class EnvService implements EnvGeneric {
  name: string;
  release: string;
  production: boolean;
  apiHost: string;
  storageHost: string;
  wssHost: string;
  userType: string;
  googleApiKey: string;

  constructor(@Inject(ENV) private _config: EnvGeneric) {
    this.name = this._config.name;
    this.release = this._config.release;
    this.production = this._config.production;
    this.apiHost = this._config.apiHost;
    this.storageHost = this._config.storageHost;
    this.wssHost = this._config.wssHost;
    this.userType = this._config.userType;
    this.googleApiKey = this._config.googleApiKey;
  }
}
