import { FormArray, FormControl, FormGroup } from '@angular/forms';

export type CartSettingsFormGroup = FormGroup<{
  confirmPayment: FormControl<boolean>;
  confirmPaymentAsAPopup: FormControl<boolean>;
  redirectUrl: FormControl<string>;
  recommendations: FormGroup<{
    enabled: FormControl<boolean>;
    groups: FormArray<FormControl<number>>;
  }>;
}>;

export type RecommendationGroup = FormGroup<{
  id: FormControl<number>;
  name: FormControl<string>;
}>;

export type RecommendationGroupsFormArray = FormArray<RecommendationGroup>;
