import { PopMargin } from '../widget';

export interface WidgetWifi {
  name: string;
  pass: string;
  mainColor: string;
  isEditMode: boolean;
  style: {
    showBorders: boolean;
    backgroundColor: string;
    fullWidth: boolean;
    fontSize: number;
    displayType: WifiDisplayType;
    margin: PopMargin;
    iconUrl: string;
  };
}

export enum WifiDisplayType {
  STYLE1 = 'style1',
  STYLE2 = 'style2',
}
