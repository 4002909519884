import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DialogConfirmComponent,
  DialogErrorComponent,
  DialogInfoComponent,
  DialogProgressComponent,
  DialogSuccessComponent,
} from '../../components';
import { DialogConfig, DialogConfigComponent, DialogConfirmConfig } from '../../types';

@Injectable()
export class DialogService {
  private _modalConfig: MatDialogConfig;
  private _queueDialogs: Map<string, { dialog: MatDialogRef<any, void>; allowToCloseOutside: boolean }>;

  constructor(
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _parser: TranslateParser,
    private _sanitizer: DomSanitizer,
  ) {
    this._modalConfig = {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      hasBackdrop: false,
    };

    this._queueDialogs = new Map();
  }

  info(config: DialogConfig): Observable<void> {
    return this._openDialog(config, DialogInfoComponent, 'dialog-info');
  }

  progress(config: DialogConfig): Observable<void> {
    const { showCloseButton = false } = config;
    return this._openDialog({ ...config, showCloseButton }, DialogProgressComponent, 'dialog-progress');
  }

  success(config: DialogConfig): Observable<void> {
    return this._openDialog(config, DialogSuccessComponent, 'dialog-success');
  }

  error(config: DialogConfig): Observable<void> {
    return this._openDialog(config, DialogErrorComponent, 'dialog-error');
  }

  confirm(config: DialogConfirmConfig): Observable<boolean> {
    return this._openDialog(config, DialogConfirmComponent, 'dialog-confirm');
  }

  openComponent<T, C = unknown>(component: Type<C>, config: DialogConfirmConfig): Observable<T> {
    return this._openDialog(config, component, 'dialog-info');
  }

  closeAll({ force }: { force: boolean } = { force: false }): void {
    this._queueDialogs.forEach(({ dialog, allowToCloseOutside }, key) => {
      // when force is true close all dialog
      // when force is false and allowToCloseOutside is true close all notification
      if (!force && !allowToCloseOutside) return;

      dialog.close();
      this._queueDialogs.delete(key);
    });
  }

  private _openDialog<T, R>(config: DialogConfig, component: Type<T>, panelClass: string): Observable<R> {
    this._closePreviousDialogs(config);

    const dialogRef = this._dialog.open(component, {
      ...this._modalConfig,
      panelClass,
      data: this._interpolateData(config),
    });

    this._addDialogInQueue(dialogRef, config);

    return dialogRef.afterClosed();
  }

  private _addDialogInQueue<T>(
    dialog: MatDialogRef<T, void>,
    { timeToClose, allowToCloseOutside = true }: DialogConfig,
  ): void {
    this._queueDialogs.set(dialog.id, { dialog, allowToCloseOutside });

    if (timeToClose) setTimeout(() => dialog.close(), timeToClose);

    // delete dialog from queue after is closed
    dialog.afterClosed().subscribe(() => this._queueDialogs.delete(dialog.id));
  }

  private _closePreviousDialogs({ closePreviousDialogs = true }: DialogConfig): void {
    if (!closePreviousDialogs) return;

    this.closeAll();
  }

  private _interpolateData({ message, interpolateData = {}, ...rest }: DialogConfig): DialogConfigComponent {
    const observableMessage = isObservable(message) ? message : of(message);

    const observableInterpolateData = isObservable(interpolateData) ? interpolateData : of(interpolateData);

    const translateMessage = combineLatest([observableMessage, observableInterpolateData]).pipe(
      map(([msg, interpolate]) => this._translateMessage(msg, interpolate)),
    );

    const title = translateMessage.pipe(map(msg => this._getTitle(msg)));
    const description = translateMessage.pipe(map(msg => this._getDescription(msg)));

    return {
      ...rest,
      title,
      description,
      close: id => this._queueDialogs.delete(id),
    };
  }

  private _translateMessage(message: string, interpolateData: Record<string, string | number>): string {
    // uses interpolation separately so that message that is not in i18n can be interpolated
    // instant (instant(key, interpolateParams)) has interpolation but if it does not find key in i18n it does not interpolate the message
    // put default 'GENERIC_ERROR' to prevent errors for undefined message
    const translateMessage = this._translate.instant(message || 'GENERIC_ERROR');
    const interpolateMessage = this._parser.interpolate(translateMessage, interpolateData);

    return interpolateMessage;
  }

  private _getTitle(message: string): SafeHtml | undefined {
    // if there are several lines in the message then the first line is the title and the rest is the description
    const [title, ...rest] = message.split(/\r?\n/);

    if (rest.length) return this._sanitizer.bypassSecurityTrustHtml(title);

    return undefined;
  }

  private _getDescription(message: string): SafeHtml {
    // if there are several lines in the message then the first line is the title and the rest is the description
    const [title, ...rest] = message.split(/\r?\n/);

    // but if it is only a line then this will be the description
    const description = rest.length ? rest.join('\n') : title;

    return this._sanitizer.bypassSecurityTrustHtml(description);
  }
}
