import { createAction, props } from '@ngrx/store';

import { createType, OrderLocation } from '../shared';
import { storeKey } from './order-locations.config';

const type = createType(storeKey);

export const actions = {
  upsertOrderLocations: createAction(type('add order locations'), props<{ orderLocations: OrderLocation[] }>()),
  updateOrderLocations: createAction(type('update order locations'), props<{ orderLocations: OrderLocation[] }>()),
  reset: createAction(type('reset')),
};
