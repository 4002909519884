export interface Pwa {
  enabled: boolean;
  manifest: any;
  icon: string;
  themeColor: string;
  backgroundColor: string;
  showAddAfterLaunch: boolean;
  showAddAfterPayment: boolean;
  showAddButtonInTopBar: boolean;
  labels: {
    INSTALL: string;
    MESSAGE1_ANDROID: string;
    MESSAGE2_ANDROID: string;
    MESSAGE1_IOS: string;
    MESSAGE2_IOS: string;
    TITLE: string;
    CART_TITLE: string;
    CART_SUBTITLE: string;
    CART_INSTALL: string;
    CART_NOT_NOW: string;
  };
}
