import { createFeatureSelector, createSelector } from '@ngrx/store';

import { storeKey } from './cart.config';
import { State } from './cart.type';

const stateSelector = createFeatureSelector<State>(storeKey);

export const all = createSelector(stateSelector, state => state);
export const isLoading = createSelector(stateSelector, state => !!state.isLoading);
export const paymentMethods = createSelector(stateSelector, state => state.paymentMethods);
export const credit = createSelector(stateSelector, state => state.credit);
export const workingHours = createSelector(stateSelector, state => state.workingHours);
