import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ifPluck, StoreService, WorkingHoursInterval } from '../shared';
import { actions } from './working-hours.actions';
import * as selectors from './working-hours.selectors';
import { State } from './working-hours.type';

@Injectable()
export class WorkingHoursStoreService extends StoreService<State> {
  constructor(_store: Store<State>, _actions: Actions) {
    super(_store, _actions, selectors);
  }

  getWorkingHours(catalogId: number): void {
    this._store.dispatch(actions.getCatalogWorkingHours({ by: catalogId }));
  }

  getBy(catalogId: number): Observable<WorkingHoursInterval[]> {
    return this._store.select(selectors.entities).pipe(
      ifPluck(catalogId),
      map(workingHours => workingHours?.intervals),
    );
  }
}
