import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HistoryService } from '@bend/history';
import { ScrollService } from '@bend/scroll';

@Injectable()
export class NavigationService {
  constructor(private _router: Router, private _history: HistoryService, private _scroll: ScrollService) {}

  back(): void {
    this._scroll.setPopstate();

    const urlTree = this._router.parseUrl(this._history.backUrl);
    this._router.navigateByUrl(urlTree);
  }

  home(): void {
    const urlTree = this._router.parseUrl(this._history.home);
    this._router.navigateByUrl(urlTree);
  }
}
