import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { IQueryParamValidator, ITimeRuleValidator, ITimeValidator, IWidgetValidators, Widget } from '../../models';
import { WidgetFormService } from '../../services';

@Component({
  selector: 'pop-widget-edit-conditional-tab',
  templateUrl: './conditional-tab.component.html',
  styleUrls: ['./conditional-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalTabComponent implements OnInit {
  @Input() widget: Widget;
  form: UntypedFormArray;
  timeCheckBox = false;
  queryParamCheckBox = false;
  private _validatorRulesByType: {
    time: any;
    queryParam: any;
  };

  constructor(private _formBuilder: UntypedFormBuilder, private _widgetFormService: WidgetFormService) {
    this._validatorRulesByType = {
      time: (validator: ITimeValidator) => (this.timeCheckBox = true) && this._createTimeValidator(validator),
      queryParam: (validator: IQueryParamValidator) =>
        (this.queryParamCheckBox = true) && this._createQueryParam(validator),
    };
  }

  ngOnInit(): void {
    this._initForm();
  }

  get getFormValue(): IWidgetValidators[] {
    return [...this.form.value];
  }

  get getTimeRules(): UntypedFormArray {
    const indexValidatorTime = this._getLastIndexValidatorByType('time');
    return this.form.at(indexValidatorTime).get('rules') as UntypedFormArray;
  }

  get getTimeRulesValue(): ITimeRuleValidator[] {
    return [...this.getTimeRules.value];
  }

  get showQueryParamButtonRemove(): boolean {
    return this.getFormValue.filter((validator: IWidgetValidators) => validator.type === 'queryParam').length > 1;
  }

  changeTimeValue(): void {
    this.timeCheckBox = !this.timeCheckBox;

    if (this.timeCheckBox) {
      this._addTimeValidator();
    } else {
      this._removeAllValidator('time');
    }
  }

  changeQueryParamValue(): void {
    this.queryParamCheckBox = !this.queryParamCheckBox;

    if (this.queryParamCheckBox) {
      this._addQueryParamValidator(0);
    } else {
      this._removeAllValidator('queryParam');
    }
  }

  removeIntervalTime(weekday: number): void {
    const index = this._getLastIndexIntervalTimeOfWeekday(weekday);
    this.getTimeRules.removeAt(index);
  }

  addQueryParam(index: number): void {
    this._addQueryParamValidator(index + 1);
  }

  removeQueryParam(index: number): void {
    this._removeValidator(index);
  }

  addQueryParamRule(queryParamValidator: UntypedFormGroup, input: HTMLInputElement): void {
    const rules = queryParamValidator.get('rules') as UntypedFormArray;
    rules.push(this._formBuilder.control(input.value));
    input.value = '';
  }

  private _initForm(): void {
    this.form = this._formBuilder.array(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.widget.validators.map((validator: IWidgetValidators) =>
        this._validatorRulesByType[validator.type](validator),
      ),
    );

    this._widgetFormService.addControl('validators', this.form);
  }

  private _addTimeValidator(): void {
    this.form.push(this._createTimeValidator({ type: 'time', rules: [] }));
  }

  private _createTimeValidator(validator: ITimeValidator): UntypedFormGroup {
    return this._formBuilder.group({
      type: validator.type,
      rules: this._formBuilder.array(this._createWeekdays(validator.rules)),
    });
  }

  private _addQueryParamValidator(index: number): void {
    this.form.insert(index, this._createQueryParam({ type: 'queryParam', key: '', rules: [] }));
  }

  private _createQueryParam(validator: IQueryParamValidator): UntypedFormGroup {
    return this._formBuilder.group({
      type: validator.type,
      key: [validator.key, Validators.required],
      rules: this._formBuilder.array(validator.rules, Validators.required),
    });
  }

  private _createWeekdays(timeRules: ITimeRuleValidator[]): UntypedFormGroup[] {
    return timeRules.map(({ weekday, from, to }) => this._createIntervalTime(weekday, from, to));
  }

  private _createIntervalTime(weekday: number, from: string, to: string): UntypedFormGroup {
    return this._formBuilder.group({
      weekday,
      from: [from, [this._isWorkingHoursIntervalValid()]],
      to: [to, [this._isWorkingHoursIntervalValid()]],
    });
  }

  private _removeAllValidator(type: string): void {
    const allIndexesRemoved = this.getFormValue.reduce(
      (acc, validator, index) => [...acc, ...(validator.type === type ? [index] : [])],
      [],
    );

    allIndexesRemoved.forEach((removeIndex, index) => this._removeValidator(removeIndex - index));
  }

  private _removeValidator(index: number): void {
    this.form.removeAt(index);
  }

  private _getLastIndexIntervalTimeOfWeekday(weekday: number): number {
    const lastIndex = this.getTimeRulesValue.length - 1;
    return lastIndex - this.getTimeRulesValue.reverse().findIndex(rule => rule.weekday === weekday);
  }

  private _getLastIndexValidatorByType(type: string): number {
    const lastIndex = this.getFormValue.length - 1;
    return lastIndex - this.getFormValue.reverse().findIndex(validator => validator.type === type);
  }

  private _isWorkingHoursIntervalValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = !!control.value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) || control.value === '24:00';

      return result ? null : { timeInvalid: true };
    };
  }
}
